import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

import { StudentContactDetail, StudentSummary } from '../../../models';
import { StudentService } from '../../../services';

@Component({
  selector: 'kip-student-contact',
  templateUrl: './student-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentContactComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #canEdit = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  studentContact: StudentContactDetail | undefined;

  editing = false;

  studentContacts: StudentContactDetail[] = [];

  get canEdit() {
    return this.#canEdit;
  }

  @Input({ required: true }) studentDetail: StudentSummary | undefined;

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  edit(studentContact: StudentContactDetail) {
    if (this.canEdit) {
      this.studentContact = studentContact;
      this.editing = !this.editing;
    }
  }

  add() {
    this.editing = !this.editing;
  }

  reset() {
    this.studentContact = undefined;
    this.editing = false;
  }

  init() {
    this.reset();
    if (this.studentDetail) {
      this.#subscriptions.push(
        this.#studentService
          .getContactsByStudentId(this.studentDetail.id)
          .subscribe(studentContacts => {
            this.studentContacts = studentContacts;
            this.#changeDetectorRef.markForCheck();
          }));
    }

    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }
}
