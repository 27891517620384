/* eslint-disable angular-file-naming/service-filename-suffix */

import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { MergedRoute } from './merged-route';

// more info https://ngrx.io/guide/router-store/configuration

@Injectable()
export class MergedRouterStateSerializer implements RouterStateSerializer {
  serialize(routerState: RouterStateSnapshot): MergedRoute {

    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params, data } = route;

    return { url: url, params: params, queryParams: queryParams, data: data };
  }
}
