<ng-template #content>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.video" />
      {{ videoTitle() }} {{ size() }}
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body">
    @if (loading())
    {
    <kip-progress-indicator [percentage]="percentDone()" />
    }
    @else {
    @if (!error()) {
    <video class="w-100 h-100" controls>
      <source type="video/mp4" [src]="url()">
    </video>
    }
    @else {
    <div class="d-flex border-0 px-3 py-1 rounded-lg d-flex justify-content-center">
      <kip-monty-error style="width: 200px;" />
      <p class="ms-2">The video you are looking for does not exist. <br /><br />
        If you are trying to look at the video directly after the lesson has
        finished it may not have finished uploading yet.</p>
    </div>
    }
    }
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="close()">Cancel</button>
  </div>
</ng-template>