<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()">
  @if (label(); as label)
  {
  <label>{{ label }}</label>
  }
  <div class="kip-button-group btn-group btn-group-toggle">
    @for (themeValue of themeValues; track themeValue) {
    <input class="btn-check" name="theme" type="radio" [disabled]="disabled()" [formControl]="control()"
      [id]="'theme_' + themeValue.value" [value]="themeValue.value">
    <label class="kip-button-group__radio btn" [for]="'theme_' + themeValue.value">
      {{ themeValue.label }}
    </label>
    }
  </div>
</kip-form-control>