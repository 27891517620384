import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-thumbs-up-c',
  templateUrl: './thumbs-up-c.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconThumbsUpCComponent implements IconComponent {

}
