import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import {
  DeferEnrolmentSession, EnrolmentSessionSummary, ForfeitEnrolmentSession, MakeupEnrolmentSession,
  MoveEnrolmentSession, RescheduleEnrolmentSession
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class EnrolmentSessionService extends HttpService {

  getEnrolmentSessionsByEnrolmentId(enrolmentId: number): Observable<EnrolmentSessionSummary[]> {
    return this.get<EnrolmentSessionSummary[]>(`enrolment-sessions/${enrolmentId}`);
  }

  forfeit(enrolmentSessions: ForfeitEnrolmentSession[]): Observable<string> {
    return this.post<string>('enrolment-sessions/forfeit',
      {
        forfeitEnrolmentSessions: enrolmentSessions
      });
  }

  toMakeup(enrolmentSessions: MakeupEnrolmentSession[]): Observable<string> {
    return this.post<string>('enrolment-sessions/tomakeup',
      {
        makeupEnrolmentSessions: enrolmentSessions
      });
  }

  reschedule(enrolmentSessions: RescheduleEnrolmentSession[]): Observable<string> {
    return this.post<string>('enrolment-sessions/reschedule',
      {
        rescheduleEnrolmentSessions: enrolmentSessions
      });
  }

  defer(enrolmentSessions: DeferEnrolmentSession[]) {
    return this.post('enrolment-sessions/defer',
      {
        deferEnrolmentSessions: enrolmentSessions
      });
  }

  move(enrolmentSession: MoveEnrolmentSession): Observable<string> {
    return this.post<string>('enrolment-sessions/move', enrolmentSession);
  }
}
