import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { StudentStrength } from '../../../models';
import { StudentService } from '../../../services';

@Component({
  selector: 'kip-student-strength-history',
  templateUrl: './student-strength-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentStrengthHistoryComponent implements OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #studentService = inject(StudentService);

  #studentId: number | undefined;
  #enabled = false;
  #studentStrengths: StudentStrength[] = [];
  #subscriptions: Subscription[] = [];

  get hasResults() {
    return this.#studentStrengths.length > 0;
  }

  get studentStrengths() {
    return this.#studentStrengths;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.load();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) set enabled(value: boolean) {
    if (this.#enabled !== value) {
      this.#enabled = value;
      this.enabledChange.emit(value);
      if (value) {
        this.load();
      }
    }
  }

  get enabled() {
    return this.#enabled;
  }

  @Output() readonly enabledChange = new EventEmitter<boolean>();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggle() {
    this.enabled = !this.enabled;
  }

  /* eslint-disable kip/no-unused-public-members */

  load() {
    if (this.#studentId && this.#enabled) {
      this.#subscriptions.push(
        this.#studentService.getStrengthHistoryByStudentId(this.#studentId).subscribe(studentStrengths => {
          this.#studentStrengths = studentStrengths;
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  /* eslint-enable kip/no-unused-public-members */

}
