@if (contentHistory) {
<span class="ms-1 kip-badge kip-badge--outline kip-badge--small">{{contentHistory.date | dateTime: 'DD-MMM-YY'}}</span>
@if (contentHistory.ageResult && contentHistory.age)
{
<span [ngClass]="contentHistory.ageResult | ageResultTextClass">
  {{ contentHistory.ageResult | ageResult : (contentHistory.age | ageFromNumber) }}
</span>
}
@else {
<span [ngClass]="contentHistory.percentage | percentageResultTextClass">
  {{ contentHistory.percentage | percentage }}
</span>
}
}