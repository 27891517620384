<div class="kip-spelling-layout">
  <kip-question-text [question]="question" />
  <div class="kip-letters d-flex flex-row justify-content-center">
    @for (letter of letters$ | async; track letter) {
    <div>
      <span class="kip-letter">{{letter}}</span>
    </div>
    }
    @if (showInput) {
    <input #input autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false"
      [class]="'kip-input kip-bring-to-front form-control text-center rounded-sm ' + (valid | answerValidation: 'alert-success': 'alert-danger')"
      [style.width.px]="width" (change)="onChange()" (input)="onInput()" (keyup.enter)="submit()" />
    }
    @if (readonly) {
    <span [ngClass]="valid | answerValidation: 'alert-success':'alert-danger'">{{displayValue}}</span>
    }
  </div>
</div>