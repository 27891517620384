import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditCardPopupComponent } from 'ui-common-lib';

import { ClasslinkInstantLoginRedirectComponent } from './classlink-instant-login-redirect/classlink-instant-login-redirect.component';
import { CleverInstantLoginRedirectComponent } from './clever-instant-login-redirect/clever-instant-login-redirect.component';
import {
  CreateAdHocActivityPublicComponent
} from './create-ad-hoc-activity-public/create-ad-hoc-activity-public.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { CommonRoutes, StudentRoutes, TutorRoutes } from './shared';
import { UploadActivityFilesPublicComponent } from './upload-activity-files-public/upload-activity-files-public.component';
import { UploadPublicComponent } from './upload-public/upload-public.component';

const routes: Routes = [
  {
    path: CommonRoutes.Forbidden,
    component: ForbiddenComponent
  },
  {
    path: TutorRoutes.Tutor,
    loadChildren: () => import('./tutor/tutor.module').then(m => m.TutorModule)
  },
  {
    path: CommonRoutes.ReadyPlayerMe,
    loadChildren: () => import('./ready-player-me/ready-player-me.module').then(m => m.ReadyPlayerMeModule)
  },
  {
    path: CommonRoutes.Avatar,
    loadChildren: () => import('./avatar/avatar.module').then(m => m.AvatarModule)
  },
  {
    path: StudentRoutes.Student,
    loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
  },
  {
    path: CommonRoutes.Upload,
    component: UploadPublicComponent
  },
  {
    path: CommonRoutes.UploadActivityFiles,
    component: UploadActivityFilesPublicComponent
  },
  {
    path: CommonRoutes.CreateActivity,
    component: CreateAdHocActivityPublicComponent
  },
  {
    path: CommonRoutes.CreditCard,
    component: CreditCardPopupComponent
  },
  {
    path: CommonRoutes.CleverInstantLoginRedirect,
    component: CleverInstantLoginRedirectComponent
  },
  {
    path: CommonRoutes.ClasslinkInstantLoginRedirect,
    component: ClasslinkInstantLoginRedirectComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    // this is required for reloading the student screens
    // otherwise it can't read the lessonid which is part of the parent route

    paramsInheritanceStrategy: 'always'

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
