<div class="d-flex flex-wrap">
  @for (route of (routes() | sideBar : isLegacy()); track route) {
  @if (route.value.children)
  {
  @for (child of (route.value.children | sideBar : isLegacy()); track child) {
  <kip-feature-item [featureFlag]="child.value.feature"
    [route]="'/' + route.value.path + '/' + (child.value.pathOverride ?? child.value.path)"
    [text]="child.value.title" />
  }
  }
  }
</div>