import { createSelector } from '@ngrx/store';

import { selectTutorState, TutorData } from '../state';
import { PlanState } from './plan.reducers';

const selectPlanState = createSelector(selectTutorState, (state: TutorData) => state.plans);

export const selectLessonPlans = createSelector(selectPlanState, (state: PlanState) => state.entities);

export const selectDirtyPlans = createSelector(selectPlanState, (state: PlanState) => {
  let dirtyPlans: {
    readonly [lessonPlanId: number]: boolean;
  } = {};
  const keys = Object.keys(state.entities).map(k => (k as unknown) as number);
  for (const key of keys) {
    dirtyPlans = { ...dirtyPlans, [key]: state.entities[key].lessonPlan.markdownEdited ?? state.entities[key].isDirty };
  }

  return dirtyPlans;
});

export const selectPlanErrors = createSelector(selectPlanState, (state: PlanState) => state.errors);
