/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { Observable, of } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';

import { MergedRoute } from '../../../route-extensions/merged-route';
import { Feature, FeatureFlagService, SessionQueryParams, TutorRouteParams } from '../../../shared';
import { SelectedSession, SessionLesson } from '../../models';
import { DropInMessagingService, MessagingService } from '../../services';
import * as fromLesson from '../lesson';
import * as fromSession from '../session';
import { TutorState } from '../state';

interface RouteValues {
  [key: string]: any;
  [TutorRouteParams.SessionId]?: number;
  [SessionQueryParams.Observe]?: number;
  [SessionQueryParams.Teach]?: number;
}

@Injectable()
export class RouterEffects {

  readonly #actions$ = inject(Actions);
  readonly #authService = inject(AuthService);
  readonly #dropInMessagingService = inject(DropInMessagingService);
  readonly #store = inject(Store<TutorState>);
  readonly #messagingService = inject(MessagingService);
  readonly #featureFlagService = inject(FeatureFlagService);

  private isAuthenticated = false;

  route$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      ofType<RouterNavigationAction<MergedRoute>>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.#store.select(fromSession.selectSelectedSession),
        this.#store.select(fromLesson.selectTeachingLesson),
        this.#store.select(fromLesson.selectObservingLesson)
      ),
      switchMap(([action, session, teaching, observing]) => {
        const actions: Action[] = [];

        if (action.payload.routerState !== undefined && this.isAuthenticated) {
          // Resolve the entity ids from the route parameters
          const values: RouteValues = {};
          this.#resolveValues(values, action.payload.routerState);

          // Add the correct actions for the determined values

          const connect = this.#resolveSessionActions(session, values, actions);

          this.#resolveTeachingActions(teaching, values, actions);
          this.#resolveObservingActions(observing, values, actions);

          // The session action resolve will tell us if the messaging needs to be connected
          // A disconnect is performed elsewhere to ensure close actions are fulfilled beforehand
          if (connect) {
            return this.#messagingService.connect().pipe(
              switchMap(() => this.#featureFlagService.isFeatureEnabled(Feature.DropIn)),
              switchMap(isDropInEnabled => {
                return isDropInEnabled ? this.#dropInMessagingService.connect() : of(null);
              }),
              take(1),
              switchMap(() => actions)
            );
          }
        }

        return actions;
      })
    );
  });

  constructor() {
    this.#authService.isLoggedIn.subscribe(isLoggedIn => this.isAuthenticated = isLoggedIn);
  }

  #resolveValues(values: RouteValues, route: MergedRoute) {

    // Map the route params to the context
    const params = Object.keys(route.params);

    for (const param of params) {
      const value = +route.params[param];

      if (!Number.isNaN(value)) {
        values[param] = value;
      }
    }

    // Map the route query to the context
    const queryParams = Object.keys(route.queryParams);

    for (const queryParam of queryParams) {
      const value = +route.queryParams[queryParam];

      if (!Number.isNaN(value)) {
        values[queryParam] = value;
      }
    }
  }

  #resolveSessionActions(session: SelectedSession | undefined, values: RouteValues, actions: Action[]): boolean {
    let connect = false;

    const sessionId = values[TutorRouteParams.SessionId];

    // Check if the values has a lessonId defined
    if (sessionId) {

      // If found, check whether a lesson needs to be opened
      if (!session || session.sessionId !== sessionId) {
        actions.push(
          fromSession.openAction({ sessionId: sessionId })
        );

        // Also track that the messaging needs to be connected
        connect = true;
      }

    } else if (session) {

      // Otherwise if no lesson id on the route, but one is selected, close it
      actions.push(
        fromSession.closeAction({ sessionId: session.sessionId })
      );
    }

    return connect;
  }

  #resolveObservingActions(lesson: SessionLesson | undefined, values: RouteValues, actions: Action[]) {

    const observe = values[SessionQueryParams.Observe];
    // Check if the values has a observe defined
    if (observe) {

      // If found, check whether a lesson needs to be focused
      if (!lesson || lesson.lessonId !== observe) {

        // Ensure the previously focused lesson is unfocused
        if (lesson) {
          actions.push(
            fromLesson.stopObservingAction({ lessonGuid: lesson.lessonGuid })
          );
        }

        // Then focus the requested lesson
        actions.push(
          fromLesson.startObservingAction({ lessonId: observe })
        );
      }

    } else if (lesson) {

      // Otherwise if no focus id on the route, but a lesson is focused, unfocus it
      actions.push(
        fromLesson.stopObservingAction({ lessonGuid: lesson.lessonGuid })
      );
    }
  }

  #resolveTeachingActions(lesson: SessionLesson | undefined, values: RouteValues, actions: Action[]) {

    const teach = values[SessionQueryParams.Teach];

    // Check if the values has a focusId defined
    if (teach) {

      // If found, check whether a lesson needs to be focused
      if (!lesson || lesson.lessonId !== teach) {

        // Ensure the previously focused lesson is unfocused
        if (lesson) {
          actions.push(
            fromLesson.stopTeachingAction({ lessonGuid: lesson.lessonGuid })
          );
        }

        // Then focus the requested lesson
        actions.push(
          fromLesson.startTeachingAction({ lessonId: teach })
        );
      }

    } else if (lesson) {

      // Otherwise if no focus id on the route, but a lesson is focused, unfocus it
      actions.push(
        fromLesson.stopTeachingAction({ lessonGuid: lesson.lessonGuid })
      );
    }
  }
}
