import { UserName } from '../models';

export function uniqueStudentName(student: UserName, allStudents: UserName[]): string {
  const current = allStudents.find(s => s.familyName === student.familyName && s.givenName === student.givenName);
  const familyName = student.familyName.toUpperCase();
  const givenName = student.givenName.toUpperCase();

  const otherStudents = allStudents.filter(s => s !== current).map(s => (
    {
      givenName: s.givenName.toUpperCase(),
      familyName: s.familyName.toUpperCase()
    } as UserName
  ));
  const sameNameStudents = otherStudents.filter(s => s.givenName === givenName);
  if (sameNameStudents.length > 0) {
    const sameInitialStudents = sameNameStudents.filter(s => s.familyName.startsWith(familyName[0]));
    return sameInitialStudents.length > 0 ?
      `${student.givenName} ${student.familyName}` :
      `${student.givenName} ${familyName[0]}`;
  }

  return student.givenName;
}
