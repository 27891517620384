import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EmojiLibModule } from 'emoji-lib';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { BaseChartDirective } from 'ng2-charts';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { AdjustResultComponent } from './assessment-result/adjust-result.component';
import { AssessmentResultComponent } from './assessment-result/assessment-result.component';
import { ResultComponent } from './assessment-result/result.component';
import { ComprehensionAssessmentChartComponent } from './comprehension-assessment-chart/comprehension-assessment-chart.component';
import { ReadingAssessmentChartComponent } from './reading-assessment-chart/reading-assessment-chart.component';

@NgModule({
  declarations: [
    AdjustResultComponent,
    AssessmentResultComponent,
    ComprehensionAssessmentChartComponent,
    ReadingAssessmentChartComponent,
    ResultComponent
  ],
  imports: [
    BaseChartDirective,
    CommonModule,
    EmojiLibModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    NgbTooltipModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    AdjustResultComponent,
    AssessmentResultComponent,
    ComprehensionAssessmentChartComponent,
    ReadingAssessmentChartComponent,
    ResultComponent
  ]
})
export class AssessmentLibModule { }
