import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-1-1',
  templateUrl: './diy-set1-1.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet11Component implements IconComponent {

}
