import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { PageType, PdfPage } from 'pdf-lib';

@Component({
  selector: 'kip-whiteboard-pages',
  templateUrl: './pages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardPagesComponent implements AfterViewChecked {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #scrollable = false;
  #step = 1;
  #itemHeight = 0;
  #offsetIndex = 0;

  get #clientHeight(): number | undefined {
    return this.container?.nativeElement?.clientHeight;
  }

  get #scrollHeight(): number | undefined {
    return this.container?.nativeElement?.scrollHeight;
  }

  readonly icons = Icons;
  readonly pageType = PageType;

  get scrollable(): boolean {
    return this.#scrollable;
  }

  @Input({ required: true }) page = 1;
  @Input({ required: true }) pages: readonly PdfPage[] = [];
  @Input() soundFile: string | undefined;

  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef<HTMLUListElement> | undefined;

  @Output() readonly selectPage = new EventEmitter<number>();

  ngAfterViewChecked() {
    this.render();
    this.#changeDetectorRef.markForCheck();
  }

  @HostListener('window:resize')
  render() {
    if (this.#scrollHeight && this.#clientHeight) {
      this.#scrollable = this.#scrollHeight > this.#clientHeight;
      if (this.#scrollable && this.pages.length > 0 && this.container && this.container.nativeElement.children.length > 1) {
        const spacerHeight = this.container.nativeElement.children[0].clientHeight;
        const buttonHeight = this.container.nativeElement.children[1].clientHeight;
        this.#itemHeight = spacerHeight + buttonHeight;
        this.#step = Math.floor(this.#clientHeight / this.#itemHeight);
      }
    } else {
      this.#scrollable = false;
    }
    this.#changeDetectorRef.detectChanges();
  }

  scrollUp() {
    this.#scrollToIndex(this.#offsetIndex - this.#step);
  }

  scrollDown() {
    this.#scrollToIndex(this.#offsetIndex + this.#step);
  }

  click(page: number) {
    this.selectPage.emit(page);
    const whiteboardContainer = document.querySelector('.kip-whiteboard');

    if (whiteboardContainer) {
      whiteboardContainer.scrollTop = 0;
    }
  }

  #scrollToIndex(index: number) {
    let startIndex = index;
    if (startIndex < 0) {
      startIndex = 0;
    } else if (startIndex > this.pages.length - this.#step) {
      startIndex = this.pages.length - this.#step;
    }
    if (startIndex !== this.#offsetIndex) {
      this.#offsetIndex = startIndex;
      if (this.container) {
        this.container.nativeElement.scrollTop = startIndex * this.#itemHeight;
      }
    }
  }
}
