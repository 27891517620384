@if (label()) {
<label [class]="labelClass()??''">{{ label() }}</label>
}
<div class="kip-form-group">
  @for (deliveryMethod of deliveryMethods; track deliveryMethod) {
  <div class="kip-choice">
    <div class="form-check">
      <input class="form-check-input" type="radio" [formControl]="deliveryMethodId()"
        [id]="name() + '_' + deliveryMethod.id" [name]="name()" [value]="deliveryMethod.id">
      <label class="form-check-label form-check-label--append" [for]="name() + '_' + deliveryMethod.id">
        {{ deliveryMethod.name }}
      </label>
    </div>
  </div>
  }
</div>