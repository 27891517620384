import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { ServiceLibModule } from 'service-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { JiraComponent } from './jira/jira.component';
import { JiraModalComponent } from './jira/jira-modal.component';

@NgModule({
  declarations: [
    JiraComponent,
    JiraModalComponent
  ],
  imports: [
    CommonModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    ServiceLibModule,
    UiCommonLibModule
  ],
  exports: [
    JiraComponent,
    JiraModalComponent
  ]
})
export class JiraLibModule { }
