import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Icons } from 'icon-lib';
import { finalize, Subject, Subscription, tap } from 'rxjs';
import { fileUploadCompletionHandler, fileUploadingProgressHandler } from 'service-lib';

import { AdHocActivityService, AdHocQueryParamsEnum, AdHocUploadSummary } from '../shared';

enum UploadStatus {
  Waiting = 0,
  Uploading = 1,
  Processing = 2,
  Error = 3,
  Success = 4
}
@Component({
  selector: 'kip-upload-public',
  templateUrl: './upload-public.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadPublicComponent implements OnInit, OnDestroy {

  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #adHocActivityService = inject(AdHocActivityService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #adHocUploadSummary: AdHocUploadSummary | undefined;
  #sending = false;
  #result = '';
  #lessonGuid = '';
  #isLoading = false;
  readonly #progress$ = new Subject<number>();
  #isUploadingFinished = false;
  #hasError = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly uploadStatus = UploadStatus;

  currentUploadStatus = UploadStatus.Waiting;
  files: readonly File[] = [];
  activityName = '';

  get disabled() {
    return this.files.length === 0 || this.#sending;
  }

  get hasError() {
    return this.#hasError;
  }

  get result() {
    return this.#result;
  }

  get adHocUploadSummary() {
    return this.#adHocUploadSummary;
  }

  get isLoading() {
    return this.#isLoading;
  }

  get isUploadingFinished() {
    return this.#isUploadingFinished;
  }

  get progress$(): Subject<number> {
    return this.#progress$;
  }

  @Input({ required: true }) showQrCode = true;

  /* eslint-disable  kip/no-unused-public-members */

  @Input({ required: true }) set lessonGuid(value: string) {
    if (this.#lessonGuid !== value) {
      this.#lessonGuid = value;
      this.activityName = '';
      this.#subscriptions.push(
        this.#adHocActivityService.getAdHocUploadSummary(value).subscribe(result => {
          this.#adHocUploadSummary = result;
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  get lessonGuid() {
    return this.#lessonGuid;
  }

  @Input({ required: true }) isHomework = false;

  /* eslint-enable  kip/no-unused-public-members */

  @Output() readonly activityCreated = new EventEmitter();

  ngOnInit() {
    this.#subscriptions.push(
      this.#activatedRoute.queryParamMap.subscribe(queryParam => {
        const uId = queryParam.get(AdHocQueryParamsEnum.UId);
        const isHomework = queryParam.get(AdHocQueryParamsEnum.IsHomework);
        if (uId) {
          this.lessonGuid = uId;
        }
        if (isHomework) {
          this.isHomework = isHomework.toLocaleLowerCase() === 'true';
        }
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  send() {
    this.#result = 'Uploading please wait...';
    this.currentUploadStatus = UploadStatus.Uploading;
    this.#sending = true;
    this.#changeDetectorRef.markForCheck();
    this.#isLoading = true;
    this.#subscriptions.push(
      this.#adHocActivityService.createAdHocActivity(this.activityName, this.lessonGuid, this.files[0], this.isHomework)
        .pipe(
          finalize(() => {
            this.#isLoading = false;
            this.#changeDetectorRef.markForCheck();
          }),
          tap(response => this.#progress$.next(fileUploadingProgressHandler(response as HttpProgressEvent)))
        )
        .subscribe(
          {
            next: result => {
              this.#isUploadingFinished = fileUploadCompletionHandler(result as HttpProgressEvent, this.#isUploadingFinished);
              if (this.#isUploadingFinished) {
                this.currentUploadStatus = UploadStatus.Processing;
                this.#result = 'Processing please wait...';
              }

              if (result.type === HttpEventType.Response) {
                this.files = [];
                this.#result = 'Activity created';
                this.currentUploadStatus = UploadStatus.Success;
                this.#sending = false;
                this.activityCreated.emit();
                this.#changeDetectorRef.markForCheck();
              }
            }, error: () => {
              this.#result = 'Upload failed!';
              this.currentUploadStatus = UploadStatus.Error;
              this.#sending = false;
              this.#hasError = true;
              this.#isUploadingFinished = false;
              this.#changeDetectorRef.markForCheck();
            }
          }));
  }

  onNewUploading() {
    this.files = [];
    this.#result = '';
    this.#isUploadingFinished = false;
    this.#hasError = false;
    this.#sending = false;
    this.currentUploadStatus = UploadStatus.Waiting;
  }
}
