import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { DIYRule } from '../../models';

@Component({
  selector: 'kip-diy-rule',
  templateUrl: './diy-rule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DIYRuleComponent {

  icons = Icons;

  @Input({ required: true }) diyRule: DIYRule | undefined;
}
