<img #img [style.display]="avatarAvailable? '' : 'none'" [style.width.px]="avatarSize" />
@if (!avatarAvailable) {
<div class="kip-avatar d-inline-block rounded-circle text-center" [style.font-size.px]="size / 2"
  [style.height.px]="size" [style.line-height.px]="size" [style.width.px]="size">
  @if (initial) {
  <span class="kip-text">{{ initial }}</span>
  }
  @if (empty && !isLoading) {
  <fa-icon class="kip-empty" [icon]="icons.user.regular" />
  }
</div>
}