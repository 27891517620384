import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { SelectedGoal } from '../models';

@Component({
  selector: 'kip-organisation-goal-picker',
  templateUrl: './organisation-goal-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationGoalPickerComponent {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

  @Output() readonly selected = new EventEmitter<SelectedGoal>();

  choose(selectedGoal: SelectedGoal) {
    this.selected.emit(selectedGoal);
    this.close();
  }

  /* eslint-disable kip/no-unused-public-members */

  open() {
    this.#modalService.open(this.modalContent, { size: 'lg', centered: true });
  }

  /* eslint-enable kip/no-unused-public-members */

  close() {
    this.#modalService.dismissAll();
  }
}
