import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-2-2',
  templateUrl: './diy-set2-2.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet22Component implements IconComponent {

}
