import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AccountContactLoginComponent } from './account-contact-login.component';

@Component({
  selector: 'kip-account-contact-login-modal',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountContactLoginModalComponent implements OnDestroy {

  readonly #modalService = inject(NgbModal);

  #isOpen = false;
  readonly #subscription = new Subscription();

  @Input({ required: true }) userId: number | undefined;
  @Input() contactId: number | undefined;
  @Input({ required: true }) set isOpen(value: boolean | undefined) {
    this.#isOpen = !!value;
    if (this.isOpen) {
      this.#open();
    }
  }

  get isOpen() {
    return this.#isOpen;
  }

  @Output() readonly isOpenChange = new EventEmitter<boolean>();
  @Output() readonly loginCreated = new EventEmitter<boolean>();

  ngOnDestroy() {
    this.#subscription.unsubscribe();
    this.isOpenChange.emit(false);
    this.#modalService.dismissAll();
  }

  #open() {
    // This is the only way I could get drag and drop to work
    // The element ref needs to be created by opening the modal first
    const modalRef = this.#modalService.open(AccountContactLoginComponent, { centered: true });
    const close = () => { this.#close(); };
    const componentInstance = modalRef.componentInstance as AccountContactLoginComponent;
    componentInstance.userId = this.userId;
    componentInstance.contactId = this.contactId;
    modalRef.result.then(close, close);
    this.#subscription.add(componentInstance.loginCreated.subscribe(showAddLogin => this.loginCreated.emit(showAddLogin)));
  }

  #close() {
    this.isOpenChange.emit(false);
  }

}
