import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-santa-claus-c',
  templateUrl: './santa-claus-c.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSantaClausCComponent implements IconComponent {

}
