<label [class]="labelClass??''">{{ label }} @if (optional) {
  <span class="ms-2 small text-uppercase">Optional</span>
  }</label>
@for (multipleValue of multipleValues; track multipleValue) {
<div class="custom-control custom-checkbox kip-choice">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" [disabled]="disabled" [id]="name + '_' + multipleValue.id"
      [value]="multipleValue.id" [(ngModel)]="multipleValue.checked" (change)="onCheckChange()">
    <label class="custom-control-label form-check-label form-check-label--append" [for]="name + '_' + multipleValue.id">
      <div>{{ multipleValue.name }}</div>
      @if (multipleValue.subGroups)
      {
      @for (subGroup of multipleValue.subGroups; track subGroup; let last = $last) {
      <span class="small">{{ subGroup + (!last ? ' |': '') }}</span>
      }
      }
    </label>
  </div>
</div>
}