<div class="w-100 d-flex justify-content-between">
  <span class="me-2">{{ columnName() }}</span>
  <div class="p-0 d-inline-block" container="body" ngbDropdown placement="bottom-right">
    @if (sortedAsc()) {
    <fa-icon size="1x" [icon]="icons.sortAsc" />
    }
    @if (sortedDesc()) {
    <fa-icon size="1x" [icon]="icons.sortDesc" />
    }
    <div class="btn btn-link kip-calendar-event__menu" ngbDropdownToggle [id]="id()">
      <fa-icon size="1x" [icon]="filterActive()?icons.filter.regular: icons.moreActions" />
    </div>
    <div class="kip-dropdown__menu kip-dropdown__menu--top-right dropdown-menu me-n3" ngbDropdownMenu
      [attr.aria-labelledby]="id()">
      @if (sortColumnKey()) {
      <a class="kip-dropdown__entry" ngbDropdownItem (click)="changeSort(sortDirection.Ascending)">
        <fa-icon class="me-2" size="sm" [fixedWidth]="true" [icon]="icons.sortAsc" />
        <span>Sort ascending</span>
      </a>
      <a class="kip-dropdown__entry" ngbDropdownItem (click)="changeSort(sortDirection.Descending)">
        <fa-icon class="me-2" size="sm" [fixedWidth]="true" [icon]="icons.sortDesc" />
        <span>Sort descending</span>
      </a>
      }
      @if (values().length > 0) {
      <a class="kip-dropdown__entry" ngbDropdownItem (click)="outputValue.emit(null)">
        <fa-icon class="me-2" size="sm" [fixedWidth]="true"
          [icon]="value() === null ? icons.checked.regular : icons.unchecked.regular" />
        <span>Show All</span>
      </a>
      }
      @for (v of values(); track v) {
      <a class="kip-dropdown__entry" ngbDropdownItem (click)="outputValue.emit(v.id)">
        <fa-icon class="me-2" size="sm" [fixedWidth]="true"
          [icon]="value() === v.id ? icons.checked.regular : icons.unchecked.regular" />
        <span>{{ v.title }}</span>
      </a>
      }
    </div>
  </div>
</div>