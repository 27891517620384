import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WhiteboardDataService, WhiteboardService } from 'whiteboard-lib';

import { fromLesson } from '../';
import * as fromActivity from '../activity';
import * as fromQuestion from '../question';
import { selectQuestionEntityState, TutorState } from '../state';
import * as fromWhiteboard from '../whiteboard';

@Injectable()
export class WhiteboardEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<TutorState>);
  readonly #whiteboardDataService = inject(WhiteboardDataService);
  readonly #whiteboardService = inject(WhiteboardService);

  whiteboardReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveAction),
      switchMap(action => {
        this.#whiteboardService.applyMessage({
          whiteboardGuidKey: action.whiteboardGuidKey,
          event: action.event
        });
        return [
          fromActivity.whiteboardDrawnAction({ activityGuid: action.whiteboardGuidKey.activityGuid }),
          fromQuestion.whiteboardDrawnAction({ questionGuid: action.whiteboardGuidKey.pageGuid })
        ];
      })
    );
  });

  whiteboardGridTypeReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveGridTypeAction),
      switchMap(action => {
        this.#whiteboardService.applyGridType({
          whiteboardGuidKey: action.whiteboardGuidKey,
          gridType: action.gridType
        });
        return EMPTY;
      })
    );
  }, { dispatch: false });

  whiteboardOpenedReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.studentOpenAction),
      concatLatestFrom(
        () => [
          this.#store.select(selectQuestionEntityState),
          this.#store.select(fromLesson.selectTeachingLesson),
          this.#store.select(fromLesson.selectObservingLesson)
        ]),
      switchMap(([action, questionEntities, teachingLesson, observeLesson]) => {

        // Only open the whiteboard if the received action matches the current teach or observed lesson
        // question to prevent students opening the whiteboard from affecting the tutor whiteboard
        if (teachingLesson?.currentTutorQuestion?.questionGuid === action.whiteboardGuidKey.pageGuid || observeLesson?.currentTutorQuestion?.questionGuid === action.whiteboardGuidKey.pageGuid) {
          // this logic seems wrong as we don't have follow working but it better than it was
          // it used to load whiteboard images as if every whiteboard was a computer question
          // hence we were getting hundreds of 404's (because this was written before adhoc/manual?)
          // now at least it checks to see if the page guid corresponds to a question first
          if (questionEntities[action.whiteboardGuidKey.pageGuid]) {
            this.#whiteboardDataService.getWhiteboardImage(action.whiteboardGuidKey.pageGuid).then(dataUrl => {
              this.#whiteboardService.open({
                whiteboardGuidKey: action.whiteboardGuidKey,
                dataUrl: dataUrl
              });
            });
          } else {
            this.#whiteboardService.open({
              whiteboardGuidKey: action.whiteboardGuidKey
            });
          }
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  whiteboardClosedReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.studentCloseAction),
      concatLatestFrom(
        () => [
          this.#store.select(fromLesson.selectTeachingLesson),
          this.#store.select(fromLesson.selectObservingLesson)
        ]),
      switchMap(([action, teachingLesson, observeLesson]) => {
        if (teachingLesson?.lessonGuid === action.lessonGuid || observeLesson?.lessonGuid === action.lessonGuid) {
          this.#whiteboardService.close(action.whiteboardGuidKey);
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });
}
