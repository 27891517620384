<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()">
  @if (label(); as label)
  {
  <label>{{ label }}</label>
  }
  <div class="kip-button-group btn-group btn-group-toggle">
    @for (questionSpeedValue of questionSpeedValues; track questionSpeedValue) {
    <input class="btn-check" type="radio" [disabled]="disabled()" [formControl]="control()"
      [id]="'questionSpeed_' + questionSpeedValue.value" [name]="'questionSpeed'" [value]="questionSpeedValue.value">
    <label class="kip-button-group__radio btn" [for]="'questionSpeed_' + questionSpeedValue.value">
      <fa-icon class="me-2" [icon]="questionSpeedValue.icon" />
      {{ questionSpeedValue.label }}
    </label>
    }
  </div>
</kip-form-control>