import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { Icon, SkinTone } from '../../icons';
import { AwardGroupType, GroupIcon } from '../../models';

@Component({
  selector: 'kip-group-icons',
  templateUrl: './awards-group-icons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwardsGroupIconsComponent {

  #groupIcon: GroupIcon | undefined;

  readonly icons = Icons;

  readonly skinTone = SkinTone.SkinToneA;

  set groupIcon(icons: GroupIcon) {
    this.#groupIcon = icons;
  }

  get groupIcon() {
    return this.#groupIcon!;
  }

  @Input({ required: true }) collapse = false;

  @Input({ required: true })
  set selectedGroup(value: GroupIcon | undefined) {
    if (value !== undefined) {
      this.#groupIcon = value;
    }
  }

  get selectedGroup() {
    return this.#groupIcon;
  }

  @Output()
  readonly awardPicked = new EventEmitter<Icon>();

  @Output()
  readonly toggled = new EventEmitter<AwardGroupType | undefined>();

  toggle() {
    if (!this.collapse) {
      this.toggled.emit(this.groupIcon.groupTypeId);
    } else {
      this.collapse = false;
      this.toggled.emit(undefined);
    }
  }
}
