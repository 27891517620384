<div [ngClass]="{'w-100': isDocked, 'kip-keyboard-drag-boundary': !isDocked}">
  <div cdkDrag cdkDragBoundary=".kip-keyboard-drag-boundary"
    class="kip-keyboard kip-keyboard__container pointer-events-none" [class.kip-keyboard--draggable]="!isDocked"
    [hidden]="keyboardHidden" [style.display]="isTablet?'':'none'" (cdkDragStarted)="undockKeyboard()">
    <div [class]="'w-100 ' + keyboardClass">
      <div cdkDragHandle class="kip-keyboard__handle-container">
        <div class="kip-keyboard__handle">
          <fa-icon class="text-primary" size="lg" [icon]="icons.handle" />
        </div>
      </div>
    </div>
  </div>
</div>