// NOTE - order of the enum if important
// Should be alphabetical first
// The numberpad should be before other number keyboards
// This is because of how the best match logic works

export enum KeyboardType {
  AlphabeticalLowercase = 'default',
  AlphabeticalUppercase = 'shift',
  QwertyLowercase = 'qwerty',
  QwertyUppercase = 'QWERTY',
  NumberPad = 'numberpad', // this should be higher than other keyboards with numbers
  Symbols = 'symbols',
  Numbers = 'numbers',
  NumberRow = 'numberrow',
  Radio = 'radio'
}
