import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { QuestionSpeed } from 'questions-lib';
import { Subscription } from 'rxjs';
import { ProfileService, StudentSettings, Theme } from 'ui-common-lib';

import { StudentService } from '../../../services';

@Component({
  selector: 'kip-student-settings',
  templateUrl: './student-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentSettingsComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #canEdit = false;
  #studentId: number | undefined;
  #subscriptions: Subscription[] = [];

  get canEdit() {
    return this.#canEdit;
  }

  settingsForm: FormGroupTypeSafe<StudentSettings>;

  get playSounds() {
    return this.settingsForm.getSafe(x => x.playSounds);
  }

  get openDyslexic() {
    return this.settingsForm.getSafe(x => x.openDyslexic);
  }

  get hideScore() {
    return this.settingsForm.getSafe(x => x.hideScore);
  }

  get hideTimes() {
    return this.settingsForm.getSafe(x => x.hideTimes);
  }

  get useCustomKeyboard() {
    return this.settingsForm.getSafe(x => x.useCustomKeyboard);
  }

  get qwertyKeyboard() {
    return this.settingsForm.getSafe(x => x.qwertyKeyboard);
  }

  get questionSpeed() {
    return this.settingsForm.getSafe(x => x.questionSpeed);
  }

  get theme() {
    return this.settingsForm.getSafe(x => x.theme);
  }

  get skinTone() {
    return this.settingsForm.getSafe(x => x.skinTone);
  }

  get overlays() {
    return this.settingsForm.getSafe(x => x.overlays);
  }

  get backgrounds() {
    return this.settingsForm.getSafe(x => x.backgrounds);
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      if (value) {
        this.#subscriptions.push(
          this.#studentService.getStudentSettingsById(value).subscribe(
            result => {
              this.settingsForm.patchSafe(result);
              this.#changeDetectorRef.markForCheck();
            }
          ));
      }
    }
  }

  get studentId() {
    return this.#studentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */
    this.settingsForm = this.#fb.group<StudentSettings>({
      openDyslexic: new FormControl<boolean>(false, Validators.required),
      playSounds: new FormControl<boolean>(false, Validators.required),
      hideScore: new FormControl<boolean>(false, Validators.required),
      hideTimes: new FormControl<boolean>(false, Validators.required),
      useCustomKeyboard: new FormControl<boolean>(false, Validators.required),
      qwertyKeyboard: new FormControl<boolean>(false, Validators.required),
      skinTone: new FormControl<number>(0, Validators.required),
      theme: new FormControl<string>(Theme.Default, Validators.required),
      questionSpeed: new FormControl<QuestionSpeed>(QuestionSpeed.Normal, Validators.required),
      backgrounds: new FormGroup({
        color: new FormControl<string>(''),
        enabled: new FormControl<boolean>(false)
      }),
      overlays: new FormGroup({
        color: new FormControl<string>(''),
        enabled: new FormControl<boolean>(false)
      })
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (!this.settingsForm.invalid && this.#studentId && this.canEdit) {
      this.#subscriptions.push(
        this.#studentService.updateStudentSettings(this.#studentId, this.settingsForm.value).subscribe(
          {
            next: () => {
              // do nothing
            },
            error: (error: unknown) => this.settingsForm.handleServerErrors(error)
          }
        ));
    }
  }

  onToggleOverlays() {
    this.overlays.setValue({
      ...this.overlays.value,
      enabled: !this.overlays.value.enabled
    }, { emitEvent: false });

    if (this.overlays.value.enabled) {
      this.backgrounds.setValue({ ...this.backgrounds.value, enabled: false }, { emitEvent: false });
      this.backgrounds.updateValueAndValidity({ emitEvent: false });
    }

    this.overlays.updateValueAndValidity();
    this.#changeDetectorRef.detectChanges();
  }

  onChangeOverlaysColor(color: string) {
    this.overlays.setValue({
      ...this.overlays.value,
      color
    }, { emitEvent: false });
    this.overlays.updateValueAndValidity();
    this.#changeDetectorRef.detectChanges();
  }

  onToggleBackgrounds() {
    this.backgrounds.setValue({
      ...this.backgrounds.value,
      enabled: !this.backgrounds.value.enabled
    }, { emitEvent: false });

    if (this.backgrounds.value.enabled) {
      this.overlays.setValue({ ...this.overlays.value, enabled: false }, { emitEvent: false });
      this.overlays.updateValueAndValidity({ emitEvent: false });
    }
    this.backgrounds.updateValueAndValidity();
    this.#changeDetectorRef.detectChanges();
  }

  onChangeBackgroundsColor(color: string) {
    this.backgrounds.setValue({
      ...this.backgrounds.value,
      color
    }, { emitEvent: false });
    this.backgrounds.updateValueAndValidity();
    this.#changeDetectorRef.detectChanges();
  }
}
