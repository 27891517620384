@if (termsAndConditionsTemplateId) {
@if (label) {
<label [class]="labelClass??''" [for]="name">{{ label }}</label>
}
<div class="input-group">
  <select class="form-control" [formControl]="termsAndConditionsTemplateId" [id]="name"
    (change)="termsAndConditionsTemplateChange.emit(termsAndConditionsTemplateId.value ?? 0)">
    @for (termsAndConditionsTemplate of termsAndConditionsTemplates; track termsAndConditionsTemplate) {
    <option [ngValue]="termsAndConditionsTemplate.id">
      {{termsAndConditionsTemplate.name }}
    </option>
    }
  </select>
  <span class="input-group-btn">
    <button class="btn btn-default" type="button" (click)="display()"><fa-icon
        [icon]="icons.preview.regular" /></button>
  </span>
</div>
}

<kip-ok-dialog #ok okText="Close" [icon]="icons.preview.regular" [title]="title">
  <div class="overflow-auto" style="max-height:400px" [innerHTML]="html"></div>
</kip-ok-dialog>