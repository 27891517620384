import { BillingIntervalType } from '../../models';

interface BillingIntervalChoice {
  id: BillingIntervalType;
  title: string;
}

function convertBillingInterval(billingIntervalType: BillingIntervalType) {
  const value = BillingIntervalType[billingIntervalType];
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return '';
}

export const billingIntervalChoices: BillingIntervalChoice[] = [
  {
    id: BillingIntervalType.Weekly,
    title: convertBillingInterval(BillingIntervalType.Weekly)
  },
  {
    id: BillingIntervalType.Fortnightly,
    title: convertBillingInterval(BillingIntervalType.Fortnightly)
  },
  {
    id: BillingIntervalType.Monthly,
    title: convertBillingInterval(BillingIntervalType.Monthly)
  }
];
