@if (isLoading) {
<div class="kip-monty">
  @if (!isSlow) {
  <kip-spinner />
  }
  @if (isSlow) {
  <kip-monty-slow-request class="kip-monty__asset" />
  <p class="kip-monty__text">
    Things are a little slow right now
  </p>
  <p>
    Your request is taking some time...
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="onSlowRequestCancel()">Cancel</button>
  }
</div>
}
@if (isCancelled) {
<div class="kip-monty">
  <kip-monty-error class="kip-monty__asset" />
  <p class="kip-monty__text">You've cancelled the request!</p>
  <p>
    Would you like to try again?
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="retryPageLoad()">Retry</button>
</div>
}
@if (isError) {
<div class="kip-monty">
  <kip-monty-error class="kip-monty__asset" />
  <p class="kip-monty__text">
    Uh-oh! Something went wrong...
  </p>
  <p>
    We track these errors automatically, but if the problem persists, feel free to <a
      href="https://kipmcgrath.atlassian.net/servicedesk/customer/portal/21" rel="noopener" target="_blank">contact
      us</a>.<br />In the meantime, try reloading.
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="reloadPage()">Reload</button>
</div>
}