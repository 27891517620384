<label [class]="labelClass()??''" [for]="name()">{{ label() }}
  @if (description(); as description) {
  <span class="ms-2 small text-uppercase">{{ description }}</span>
  }</label>
<select class="form-control" [formControl]="regionId()" [id]="name()">
  @for (region of regions(); track region) {
  <option [ngValue]="region.id">
    {{ region.name }}
  </option>
  }
</select>