import { Pipe, PipeTransform } from '@angular/core';

import { PercentageResultPipe } from './percentage-result.pipe';

@Pipe({
  name: 'percentageResultAlertClass'
})
export class PercentageResultAlertClassPipe implements PipeTransform {

  transform(percentage: number | null | undefined) {
    return PercentageResultPipe.prototype.transform(percentage, 'alert-success', 'alert-warning', 'alert-danger');
  }
}
