import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-new',
  templateUrl: './monty-new.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyNewComponent implements MontyGraphic { }
