import { Pipe, PipeTransform } from '@angular/core';
import { LessonType } from 'ui-common-lib';

import { LessonSummary } from '../models';

@Pipe({
  name: 'lessonPlannedComment'
})
export class LessonPlannedCommentPipe implements PipeTransform {
  transform(lesson: LessonSummary): string {

    if (lesson.lessonType === LessonType.HomeMakeUp) {
      return (lesson.isDIYReady ?? false) || (lesson.accepted ?? false) && lesson.isDIYGenerated ? 'Lesson DIY Ready' : 'Lesson Not DIY Ready';
    }
    else {
      return !!lesson.lessonPlanId && (lesson.accepted ?? false) ? 'Lesson planned' : 'Lesson not planned';
    }
  }
}
