import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Role, UserProfile } from 'ui-common-lib';

import { RoutingState, selectRoutingState } from '../../store/';
import { setWindowUnloadingAction } from '../../store/routing-state';
import { CommonRoutes } from '../common-route-helper';
import { StudentRoutes } from '../student-route-helper';
import { TutorRoutes } from '../tutor-route-helper';

@Injectable({ providedIn: 'root' })
export class RouterService {

  readonly #store = inject(Store<RoutingState>);

  readonly router = inject(Router);

  setWindowUnloading() {
    this.#store.dispatch(setWindowUnloadingAction({ isWindowUnloading: true }));
  }

  isWindowUnloading(): Observable<RoutingState> {
    return this.#store.select(selectRoutingState);
  }

  navigateByProfile(profile: UserProfile) {

    // Check the roles based on most access first
    let route = '/';

    if (this.#hasRole(Role.Tutor, profile) || this.#hasRole(Role.CentreManager, profile)) {
      route = `/${TutorRoutes.Tutor}`;

    } else if (this.#hasRole(Role.Student, profile)) {
      route = `/${StudentRoutes.Student}`;
    } else if (this.#hasRole(Role.Parent, profile)) {
      route = `/${CommonRoutes.Forbidden}`;
    } else {
      console.error(`Roles '${profile.roles.join(', ')}' not supported`);
      route = `/${CommonRoutes.Forbidden}`;
    }

    this.router.navigateByUrl(route + window.location.search);
  }

  navigateByPath(profile: UserProfile) {
    const currentPath = window.location.pathname.toLowerCase() + window.location.search;
    if (currentPath.startsWith(`/${TutorRoutes.Tutor}/`) && (this.#hasRole(Role.Tutor, profile) || this.#hasRole(Role.CentreManager, profile)) ||
      currentPath.startsWith(`/${StudentRoutes.Student}/`) && this.#hasRole(Role.Student, profile)) {
      this.router.navigateByUrl(currentPath);
    } else {
      this.navigateByProfile(profile);
    }
  }

  #hasRole(role: string, profile: UserProfile): boolean {
    return profile.roles.includes(role);
  }
}
