import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

export class WordPickerValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {

    const validationResults = Array.from<ValidationResult>({ length: Math.max(studentAnswers.length, question.answers.length) }).fill(ValidationResult.Incorrect);

    // Word picker answers only have one value, check to see if matchs any of the answers
    // Given the answers have to be unique we don't have to do complex checks, just that it
    // exists in the answer list.

    for (let index = 0; index < studentAnswers.length; index++) {
      const studentAnswer = studentAnswers[index];
      const answer = question.answers.map(s => s.values[0]);

      if (answer.includes(studentAnswer)) {
        validationResults[index] = ValidationResult.Correct;
      }
    }

    return validationResults;
  }
}
