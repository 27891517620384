@for (sessionSchedule of sessionSchedules; track sessionSchedule) {
<div class="kip-session-list__session card mb-2 d-flex flex-lg-row"
  [class.kip-session-list__session--selected]="selectedSessionSchedule && sessionSchedule.id === selectedSessionSchedule.id"
  (click)="selectSession(sessionSchedule)">
  <div class="d-flex flex-column col-12 col-lg-2 px-3">
    <label class="kip-session-list__session-header mb-2 mb-lg-0" [for]="sessionSchedule.id">
      <input class="me-3" type="radio"
        [checked]=" selectedSessionSchedule && selectedSessionSchedule.id === sessionSchedule.id"
        [id]="sessionSchedule.id" [name]="sessionSchedule.id" (click)="selectSession(sessionSchedule)">
      <div class="h2">
        <span>{{ sessionSchedule.weekday + ' ' + (sessionSchedule.startTime | timeOfDay) }}</span>
      </div>
    </label>
  </div>
  <div class="kip-session-list__who-and-where col-12 col-lg-3">
    <div class="mb-2">
      <fa-icon class="text-secondary" [icon]="icons.tutor.solid" />
      <span class="ps-2">{{sessionSchedule.tutor | name }}</span>
    </div>
    <div class="mb-2 mb-lg-0">
      <fa-icon class="text-secondary" [icon]="icons.deliveryMethod.inCentre" />
      <span class="ps-2">{{sessionSchedule.centre.title}}</span>
    </div>
  </div>
  <div class="d-flex flex-wrap flex-fill align-self-center kip-session-list__spot-list w-100">
    @for (spot of sessionScheduleSpots(sessionSchedule); track spot) {
    <div class="col-12 col-lg-4 kip-session-list__spot-wrapper" [class.col-lg-6]="detailLevel !== detailLevels.Compact">
      <div class="kip-session-list__spot ps-2 pe-2" [class.alert-info]="spot.isReservation"
        [class.alert-warning]="spot.isOtherAllocation" [class.kip-session-list__spot--empty]="spot.isEmpty"
        [class.kip-session-list__spot--selected]="spot.isEnrollingStudent"
        [class.text-start]="detailLevel !== detailLevels.Compact">
        {{ spot.studentName }}
        @if (detailLevel !== detailLevels.Compact && spot.detail) {
        <span>({{ spot.detail.levelTitle }}{{
          spot.detail.subjectShortCode}})</span>
        }
        @if (spot.isReservation && spot.reservationDate) {
        <fa-icon class="ps-2" [icon]="icons.stopwatch.solid" [title]="'Reserved on: ' + spot.reservationDate" />
        }
        @if (detailLevel !== detailLevels.Compact && spot.detail) {
        <fa-icon class="ms-1" [icon]="spot.deliveryMethod | deliveryMethodIcon" />
        }
        @if (detailLevel === detailLevels.Full && spot.detail) {
        <div class="py-3 px-1">
          <table class="w-100">
            <tr>
              <td class="text-start small">Age</td>
              @if (spot.detail.dateOfBirth) {
              <td class="text-end small">{{spot.detail.dateOfBirth | age}}</td>
              }
              @if (!spot.detail.dateOfBirth) {
              <td class="text-end small">-</td>
              }
            </tr>
            <tr>
              <td class="text-start small">Reading</td>
              <td class="text-end small">{{ getAge(spot.detail, resultTypes.Reading) }}</td>
            </tr>
            <tr>
              <td class="text-start small">Maths EOG</td>
              <td class="text-end small">{{ getGrade(spot.detail, resultTypes.Maths) }}</td>
            </tr>
            <tr>
              <td class="text-start small">Comprehension</td>
              <td class="text-end small">{{ getAge(spot.detail, resultTypes.Comprehension) }}</td>
            </tr>
            <tr>
              <td class="text-start small">Spelling</td>
              <td class="text-end small">{{ getGrade(spot.detail, resultTypes.Spelling) }}</td>
            </tr>
          </table>
        </div>
        }
      </div>
    </div>
    }
  </div>
</div>
}