import { HolidayType } from 'ui-common-lib';

export function displayHolidayType(value: HolidayType, pluralize = false): string {
  const holiday = pluralize ? 'Holidays' : 'Holiday';
  switch (value) {
    case HolidayType.PublicHoliday:
      return `Public ${holiday}`;
    case HolidayType.SchoolHolidayStandard:
      return `School ${holiday} (Standard)`;
    case HolidayType.SchoolHolidaySummer:
      return `School ${holiday} (Summer)`;
    default:
      return '';
  }
}
