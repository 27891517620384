
import { OpenIdConfiguration } from 'angular-auth-oidc-client';

import { EnvironmentAuth } from './environment-auth';

const responseType = 'code'; // 'id_token token' Implicit Flow
const commonScope = 'openid profile email ikip';

export enum ConfigId {
  Default = 'default',
  Parent = 'parent',
  Clever = 'clever',
  ClassLink = 'classLink'
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable spellcheck/spell-checker */
export function getOpenIdConfiguration(environmentAuth?: EnvironmentAuth) {
  const config: OpenIdConfiguration[] = [{
    configId: ConfigId.Default,
    authority: environmentAuth?.stsServer ?? '',
    redirectUrl: window.origin,
    clientId: environmentAuth?.client?.clientID ?? '',
    responseType: responseType,
    scope: `${commonScope} offline_access`,

    postLogoutRedirectUri: window.origin,
    startCheckSession: false,
    silentRenew: true,
    useRefreshToken: true,
    forbiddenRoute: window.origin,
    // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
    // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
    maxIdTokenIatOffsetAllowedInSeconds: 300,

    // Tell oidc service we want to handle the authorization result event.
    // This stops oidc redirecting to 'post_login_route' as we figure this out based on the users roles.
    triggerAuthorizationResultEvent: true
  },
  // We need to have a seperate config stored so we can log in to LMS and Parent with 2 different accounts (this is duplicate of above with a different configId)
  {
    configId: ConfigId.Parent,
    authority: environmentAuth?.stsServer ?? '',
    redirectUrl: window.origin,
    clientId: environmentAuth?.client?.clientID ?? '',
    responseType: responseType,
    scope: `${commonScope} offline_access`,
    postLogoutRedirectUri: window.origin,
    startCheckSession: false,
    silentRenew: true,
    useRefreshToken: true,
    forbiddenRoute: window.origin,
    maxIdTokenIatOffsetAllowedInSeconds: 300,
    triggerAuthorizationResultEvent: true
  },
  {
    configId: ConfigId.Clever,
    authority: environmentAuth?.stsServer ?? '',
    redirectUrl: window.origin,
    clientId: environmentAuth?.client?.cleverClientID ?? '',
    responseType: responseType,
    scope: commonScope,
    customParamsAuthRequest: {
      acr_values: 'idp:Clever'
    },
    postLogoutRedirectUri: window.origin,
    startCheckSession: false,
    silentRenew: true,
    silentRenewUrl: `${window.origin}/silent-renew.html`,
    forbiddenRoute: window.origin,
    // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
    // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
    maxIdTokenIatOffsetAllowedInSeconds: 300,

    // Tell oidc service we want to handle the authorization result event.
    // This stops oidc redirecting to 'post_login_route' as we figure this out based on the users roles.
    triggerAuthorizationResultEvent: true
  },
  {
    configId: ConfigId.ClassLink,
    authority: environmentAuth?.stsServer ?? '',
    redirectUrl: window.origin,
    clientId: environmentAuth?.client?.classLinkClientID ?? '',
    responseType: responseType,
    scope: commonScope,
    customParamsAuthRequest: {
      acr_values: 'idp:ClassLink'
    },
    postLogoutRedirectUri: window.origin,
    startCheckSession: false,
    silentRenew: true,
    silentRenewUrl: `${window.origin}/silent-renew.html`,
    forbiddenRoute: window.origin,
    // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
    // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
    maxIdTokenIatOffsetAllowedInSeconds: 300,

    // Tell oidc service we want to handle the authorization result event.
    // This stops oidc redirecting to 'post_login_route' as we figure this out based on the users roles.
    triggerAuthorizationResultEvent: true
  }
  ];
  return config;
}
/* eslint-enable @typescript-eslint/naming-convention */
/* eslint-enable spellcheck/spell-checker */
