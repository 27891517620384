import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { AnswerValidatorResult, Question, QuestionData, QuestionNavigatorItem, QuestionNavigatorSelected, RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';

import { HelpActivityDetail } from '../models';
import { HelpService } from '../services';

@Component({
  selector: 'kip-help-quiz',
  templateUrl: './help-quiz.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpQuizComponent implements OnDestroy {

  readonly #helpService = inject(HelpService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #questionData: QuestionData | undefined;
  #question: Question | undefined;
  #navigation: QuestionNavigatorItem[] = [];
  #helpActivity: HelpActivityDetail | undefined;
  #subscriptions: Subscription[] = [];

  get questionData() {
    return this.#questionData;
  }

  get navigation(): QuestionNavigatorItem[] {
    return this.#navigation;
  }

  get question(): Question | undefined {
    return this.#question;
  }

  @Input({ required: true }) set helpActivity(value: HelpActivityDetail | undefined) {
    this.#helpActivity = value;
    if (value) {
      this.#gotoFirstNotCorrect();
    }
  }

  get helpActivity() {
    return this.#helpActivity;
  }

  @Input() regionId = RegionId.Australia;

  @Output() readonly helpActivityChange = new EventEmitter<HelpActivityDetail | undefined>();

  @Output() readonly quit = new EventEmitter();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  updated(result: AnswerValidatorResult) {
    if (this.#helpActivity) {
      const question = this.#helpActivity.questions.find(s => s.questionId === result.question.questionId);
      if (question) {
        const answer = JSON.stringify(result.answers);
        question.correct = result.correct;
        question.answers.push(answer);

        let percentage: number | null = null;

        const allCorrect = this.#helpActivity.questions.every(s => s.correct);
        const correctFirstTime = this.#helpActivity.questions.filter(s => s.answers.length === 1).length;

        if (allCorrect) {
          percentage = 100 * correctFirstTime / this.#helpActivity.questions.length;
        }

        this.#subscriptions.push(
          this.#helpService.answerQuestion(question.id, result.correct, answer, percentage).subscribe({
            next: () => {
              this.#gotoFirstNotCorrect();
              this.#updateNavigation();
              if (allCorrect) {
                this.quitActivity();
              }
              this.#changeDetectorRef.markForCheck();
            }
          }));
      }
    }
  }

  quitActivity() {
    this.#helpActivity = undefined;
    this.helpActivityChange.emit(undefined);
    this.quit.emit();
  }

  onNavigate(item: QuestionNavigatorSelected) {
    this.#questionData = item;
    this.#loadQuestion();
  }

  #gotoFirstNotCorrect() {
    if (this.#helpActivity) {
      const firstQuestionCorrect = this.#helpActivity.questions.find(q => !q.correct);
      let index = 0;
      if (firstQuestionCorrect) {
        index = this.#helpActivity.questions.indexOf(firstQuestionCorrect);
      }

      this.#questionData = { index: index, id: this.#helpActivity.questions[index].questionId };
      this.#loadQuestion();
    }
  }

  #loadQuestion() {
    if (this.#questionData?.id) {
      this.#subscriptions.push(
        this.#helpService
          .getQuestion(this.#questionData.id)
          .subscribe(question => {
            this.#question = question;
            this.#updateQuestion();
          }));
    }

    this.#updateNavigation();
  }

  #updateQuestion() {
    this.#changeDetectorRef.markForCheck();
  }

  #updateNavigation() {
    if (this.helpActivity) {
      this.#navigation = this.helpActivity.questions.map(current => {
        const result: QuestionNavigatorItem = {
          id: current.questionId,
          icon: this.#questionData?.id === current.questionId ? Icons.preview.regular : undefined,
          visited: false,
          correct: current.correct,
          hasWhiteboard: false,
          attempts: current.answers.length,
          skipped: false
        };

        return result;
      });
    }
  }
}
