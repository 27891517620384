import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-graph-whiz-boy-c',
  templateUrl: './graph-whiz-boy-c.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconGraphWhizBoyCComponent implements IconComponent {

}
