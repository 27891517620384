import { signal } from '@angular/core';
import { determineAgeComprehensionTest, determineAgeReadingTest, ScoringStatus, SimpleScore } from 'pipes-directives-lib';
import { Age, AgeResult, AssessmentResultTypeEnum, ComprehensionForm } from 'ui-common-lib';

import { AssessmentCalculations } from '../../tutor/models';
import { Scoring } from './scoring';
import { ScoringStrategy } from './scoring-strategy';
import { ScoringStrategyDefault } from './scoring-strategy-default';

export class ActivityScoring {

  #ageData: { age?: Age, result?: AgeResult } | undefined;

  readonly isManual: boolean;
  readonly lessonActivityPlanId: number;
  readonly activityId: number;
  readonly pdfFile: string;
  readonly pdfSolutionFile: string;
  readonly soundFile: string;
  readonly activityGuid: string;
  readonly contentGuid: string;
  readonly activityName: string;
  readonly fileGuids: (readonly string[]) | undefined;
  readonly whiteboardPages: (readonly string[]) | undefined;
  readonly scoring: Scoring;
  readonly completedOn: string;
  readonly videos: readonly string[];
  readonly assessmentFormName: ComprehensionForm | null;
  readonly assessmentResultTypeId: number | null;
  readonly assessmentCalculations: AssessmentCalculations | undefined;

  get ageDataCalculated() {
    return this.assessmentResultTypeId === AssessmentResultTypeEnum.Reading ||
      this.assessmentResultTypeId === AssessmentResultTypeEnum.Comprehension;
  }

  get ageData() {
    return this.#ageData;
  }

  readonly internalNote = signal('');
  readonly show = signal(false);
  readonly next = signal('');
  readonly title = signal('');
  readonly previous = signal('');

  constructor(assessmentCalculations: AssessmentCalculations | undefined, lessonActivityPlanId: number, activityId: number,
    pdfFile: string, pdfSolutionFile: string, soundFile: string, activityGuid: string, contentGuid: string,
    activityName: string, whiteboardPages: (readonly string[]) | undefined, completedOn: string,
    assessmentResultTypeId: number | null,
    assessmentFormName: ComprehensionForm | null, videos: readonly string[],
    internalNote: string, isSimpleScoring: boolean, correct: number, total: number, isManual: boolean,
    inProgress: boolean, simpleScore: SimpleScore, onChange: () => void, fileGuids: (readonly string[]) | undefined,
    status: ScoringStatus, scoringStrategy: ScoringStrategy = new ScoringStrategyDefault()
  ) {
    this.assessmentCalculations = assessmentCalculations;
    this.lessonActivityPlanId = lessonActivityPlanId;
    this.activityId = activityId;
    this.pdfFile = pdfFile;
    this.pdfSolutionFile = pdfSolutionFile;
    this.soundFile = soundFile;
    this.activityGuid = activityGuid;
    this.contentGuid = contentGuid;
    this.activityName = activityName;
    this.whiteboardPages = whiteboardPages;
    this.completedOn = completedOn;
    this.assessmentResultTypeId = assessmentResultTypeId;
    this.assessmentFormName = assessmentFormName;
    this.videos = videos;
    this.fileGuids = fileGuids;
    this.internalNote.set(internalNote);
    this.isManual = isManual;
    this.scoring = new Scoring(isSimpleScoring, correct, total, inProgress, simpleScore,
      () => {
        this.recalculateAge();
        onChange();
      },
      status,
      scoringStrategy
    );
    this.recalculateAge();
  }

  recalculateAge() {
    this.#ageData = undefined;
    if (this.assessmentCalculations) {
      if (this.assessmentResultTypeId === AssessmentResultTypeEnum.Reading) {
        this.#ageData = determineAgeReadingTest(this.scoring.correct, this.assessmentCalculations.readingTest);
      } else if (this.assessmentResultTypeId === AssessmentResultTypeEnum.Comprehension) {
        this.#ageData = determineAgeComprehensionTest(this.scoring.correct,
          this.assessmentFormName, this.assessmentCalculations.comprehensionTest);
      }
    }
  }
}
