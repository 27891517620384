import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconLibModule } from 'icon-lib';

import { KeyboardComponent } from './keyboard/keyboard.component';

@NgModule({
  declarations: [

    KeyboardComponent
  ],
  imports: [
    CommonModule,
    IconLibModule
  ],
  exports: [
    KeyboardComponent
  ]
})
export class KeyboardLibModule { }
