/* eslint-disable rxjs/no-ignored-subscription, unicorn/no-typeof-undefined */

import { HostListener, inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';

interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: string[];

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt: () => Promise<void>;

}

interface NavigatorStandalone extends Navigator {
  standalone: boolean;
}

declare let promptEvent: BeforeInstallPromptEvent | undefined;

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  readonly #swUpdate = inject(SwUpdate);

  disablePrompt = false;
  enableLogging = true;

  // Eventually we may have different install messages
  // For IOS as compared to others

  readonly installIosAvailable$ = new BehaviorSubject<boolean>(false);
  readonly installGeneralAvailable$ = new BehaviorSubject<boolean>(false);
  readonly updateDetected$ = new BehaviorSubject<boolean>(false);
  readonly updateAvailable$ = new BehaviorSubject<boolean>(false);
  readonly appInstalled$ = new BehaviorSubject<boolean>(false);

  get isStandalone() {
    return window.matchMedia('(display-mode: standalone)').matches || // chrome
      'standalone' in navigator && (navigator as NavigatorStandalone).standalone; // ios
  }

  constructor() {
    this.#swUpdate.versionUpdates.subscribe(value => {
      if (this.enableLogging) {
        console.log(typeof promptEvent !== 'undefined' ?
          `Version Update: ${value.type} - Disable Prompt: ${this.disablePrompt} - Prompt available - Standalone - ${this.isStandalone}` :
          `Version Update: ${value.type} - Disable Prompt: ${this.disablePrompt} - Prompt not available - Standalone - ${this.isStandalone}`);
      }
      if (!this.disablePrompt) {
        switch (value.type) {
          case 'VERSION_DETECTED':
            this.updateDetected$.next(true);
            break;
          case 'VERSION_READY':
            this.updateDetected$.next(false);
            this.updateAvailable$.next(true);
            break;
          case 'NO_NEW_VERSION_DETECTED':
            // do nothing
            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.updateDetected$.next(false);
            this.updateAvailable$.next(false);
            break;
        }
      }
      // hack to trigger install check
      this.installGeneralAvailable$.next(typeof promptEvent !== 'undefined');
    });

    // Detects if device is on iOS
    // We detect IOS as the install instructions we give the user would change from ios/android/desktop

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // Checks if should display install popup notification:
    if (isIos()) {
      this.installIosAvailable$.next(true);
    }
  }

  @HostListener('window:appinstalled', []) appInstalled() {
    // This allows us to build some toast notification once the app is install if we want to

    if (this.enableLogging) {
      console.log('App installed triggered');
    }

    this.appInstalled$.next(true);
  }

  checkForUpdateAvailable() {
    return this.#swUpdate.isEnabled;
  }

  checkForUpdate() {
    return this.#swUpdate.checkForUpdate();
  }

  updatePwa() {
    if (this.enableLogging) {
      console.log('Update Pwa triggered');
    }

    window.location.reload();
  }

  installPwa() {
    if (this.enableLogging) {
      console.log('Install Pwa triggered');
    }
    this.installGeneralAvailable$.next(false);
    if (typeof promptEvent !== 'undefined') {
      promptEvent.prompt();
      promptEvent.userChoice.then(choiceResult => {
        if (this.enableLogging) {
          console.log(`Install Pwa user choice - ${choiceResult.outcome} - ${choiceResult.platform}`);
        }
        promptEvent = undefined;
      });
    }
  }

}
