import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gradeDifference'
})
export class GradeDifferencePipe implements PipeTransform {
  transform(gradeId: number | null | undefined, studentGradeId: number | null | undefined): string {

    if (gradeId && studentGradeId) {
      const gradeDifference = gradeId - studentGradeId;
      if (gradeDifference === 0) {
        return 'On Track';
      } else if (gradeDifference > 0) {
        return `${gradeDifference} years ahead`;
      } else {
        return `${Math.abs(gradeDifference)} years behind`;
      }
    }

    return '';
  }
}
