import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { ageFromNumber, ageResult, Subjects } from 'pipes-directives-lib';

import {
  AssessmentResultTypeEnum, DeliveryMethodType,
  SessionDetailLevel, SessionScheduleStudent, SessionScheduleSummary,
  toName
} from '../../models';

interface SessionSpot {
  studentName: string;
  isReservation: boolean;
  reservationDate: string | undefined;
  isEnrollingStudent: boolean;
  isOtherAllocation: boolean;
  isEmpty: boolean;
  detail: SessionScheduleStudent | undefined;
  deliveryMethod: DeliveryMethodType;
}

@Component({
  selector: 'kip-session-picker-grid',
  templateUrl: './session-picker-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionPickerGridComponent {

  readonly icons = Icons;

  readonly recommendedNumberOfEnrolments = 6;
  readonly detailLevels = SessionDetailLevel;
  readonly resultTypes = AssessmentResultTypeEnum;

  @Input({ required: true }) sessionSchedules: readonly SessionScheduleSummary[] = [];
  @Input({ required: true }) selectedSessionSchedule: SessionScheduleSummary | undefined;
  @Input() studentDetail: SessionScheduleStudent | undefined;
  @Input({ required: true }) studentName = '';
  @Input() detailLevel = SessionDetailLevel.Compact;
  @Input() deliveryMethod = DeliveryMethodType.Online;

  @Output() readonly selectSessionSchedule = new EventEmitter<SessionScheduleSummary>();

  selectSession(sessionSchedule: SessionScheduleSummary) {
    this.selectSessionSchedule.emit(sessionSchedule);
  }

  sessionScheduleSpots(sessionSchedule: SessionScheduleSummary) {
    const numberOfSpots = Math.max(this.#calculateNumberOfSpots(sessionSchedule), sessionSchedule.enrolmentStudents.length);

    const spotStudentsCloned = sessionSchedule.enrolmentStudents.map<SessionSpot>(s => ({
      studentName: toName(s),
      isEnrollingStudent: false,
      isOtherAllocation: this.studentDetail ? this.studentDetail.userId === s.userId : false,
      isReservation: s.isReservation,
      reservationDate: moment(s.reservationDate?.dateTimeOffset).format('D MMM YYYY [at] h:mm a'),
      isEmpty: false,
      detail: s,
      deliveryMethod: s.deliveryMethod ?? DeliveryMethodType.Online
    }));

    if (this.selectedSessionSchedule && this.selectedSessionSchedule.id === sessionSchedule.id) {
      spotStudentsCloned.push({
        studentName: this.studentName,
        isEnrollingStudent: true,
        isOtherAllocation: false,
        isReservation: false,
        reservationDate: undefined,
        isEmpty: false,
        detail: this.studentDetail,
        deliveryMethod: this.deliveryMethod
      });
    }

    const filler = Array.from<SessionSpot>({ length: Math.max(0, numberOfSpots - spotStudentsCloned.length) }).fill({
      isEmpty: true,
      isEnrollingStudent: false,
      isOtherAllocation: false,
      isReservation: false,
      detail: undefined,
      studentName: '',
      deliveryMethod: this.deliveryMethod,
      reservationDate: undefined
    });

    return [...spotStudentsCloned, ...filler];
  }

  getAge(student: SessionScheduleStudent, resultType: AssessmentResultTypeEnum) {
    if (student?.assessmentResults) {
      const result = student.assessmentResults.find(x => x.assessmentResultTypeId === resultType);
      return ageResult(result?.ageResult, ageFromNumber(result?.age));
    }
    return undefined;
  }

  getGrade(student: SessionScheduleStudent, resultType: AssessmentResultTypeEnum) {
    if (student?.assessmentResults) {
      const result = student.assessmentResults.find(x => x.assessmentResultTypeId === resultType);
      if (result?.grade) {
        return result.grade.title;
      }
    }
    return '-';
  }

  #calculateNumberOfSpots(sessionSchedule: SessionScheduleSummary) {
    if (this.studentDetail && this.studentDetail.subjectId === Subjects.EarlyReading ||
      sessionSchedule.enrolmentStudents.some(student => student.subjectId === Subjects.EarlyReading)) {
      return 4;
    }
    return this.recommendedNumberOfEnrolments;
  }
}
