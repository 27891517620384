import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { QuestionSettings } from '../models';

@Injectable({ providedIn: 'root' })
export class QuestionsService {

  private readonly _settings$ = new Subject<QuestionSettings>();
  private readonly _replay$ = new Subject<void>();

  get settings$(): Observable<QuestionSettings> {
    return this._settings$;
  }

  get replay$(): Observable<void> {
    return this._replay$;
  }

  settings(settings: QuestionSettings) {
    this._settings$.next(settings || {});
  }

  replay() {
    this._replay$.next();
  }
}
