import { activityReducer } from './activity/activity.reducer';
import { alertsReducer } from './alerts/alerts.reducer';
import { chatReducer } from './chat/chat.reducer';
import { deviceReducer } from './device/device.reducer';
import { dropInReducer } from './drop-in/drop-in.reducer';
import { ActivityEffects } from './effects/activity.effects';
import { ChatEffects } from './effects/chat.effects';
import { DeviceEffects } from './effects/device.effects';
import { LessonEffects } from './effects/lesson.effects';
import { LogEffects } from './effects/log.effects';
import { MessagingEffects } from './effects/messaging.effects';
import { PlanEffects } from './effects/plan.effects';
import { QuestionEffects } from './effects/question.effects';
import { RouterEffects } from './effects/router.effects';
import { SessionEffects } from './effects/session.effects';
import { TutorSettingsEffects } from './effects/tutor-settings.effects';
import { WhiteboardEffects } from './effects/whiteboard.effects';
import { lessonReducer } from './lesson/lesson.reducer';
import { logReducer } from './log/log.reducer';
import { planReducer } from './plan/plan.reducers';
import { questionReducer } from './question/question.reducer';
import { sessionReducer } from './session/session.reducer';
import { tutorSettingsReducer } from './tutor-settings/tutor-settings.reducer';

export * as fromActivity from './activity';
export * as fromAlerts from './alerts';
export * as fromChat from './chat';
export * as fromDevice from './device';
export * as fromDropIn from './drop-in';
export * as fromLesson from './lesson';
export * as fromLog from './log';
export * as fromPlan from './plan';
export * as fromQuestion from './question';
export * as fromSession from './session';
export { LoadedState } from './session/session.reducer';
export * as fromState from './state';
export { TutorState } from './state';
export * as fromWhiteboard from './whiteboard';

export const effects = [
  RouterEffects,
  MessagingEffects,
  SessionEffects,
  LessonEffects,
  QuestionEffects,
  WhiteboardEffects,
  PlanEffects,
  LogEffects,
  ChatEffects,
  ActivityEffects,
  DeviceEffects,
  TutorSettingsEffects
];

export const reducers = {
  session: sessionReducer,
  lessons: lessonReducer,
  plans: planReducer,
  activities: activityReducer,
  questions: questionReducer,
  logs: logReducer,
  chat: chatReducer,
  alerts: alertsReducer,
  devices: deviceReducer,
  tutorSettings: tutorSettingsReducer,
  dropIn: dropInReducer
};
