@if (tutorId) {
<label [class]="labelClass??''" [for]="name">{{ label }}</label>
@if (tutors.length !== 0) {
<select class="form-control" [formControl]="tutorId" [id]="name">
  @for (tutor of tutors; track tutor) {
  <option [ngValue]="tutor.id">
    {{ tutor | name }}
  </option>
  }
</select>
}
@if (tutors.length === 0) {
<span>
  {{ noTutorsAvailableMessage }}
</span>
}
}