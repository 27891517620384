<ng-template #content>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.video" />
      {{ videoTitle }}
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body">
    @if (screenShares.length > 0) {
    <div class="h-100">
      @if (publishing) {
      <kip-opentok-video-publisher style="display:none" [audio]="true" [audioDevice]="audioDevice"
        [publisherName]="publisherName" [session]="session" [video]="true" [videoDevice]="videoDevice" />
      }
      @for (screenShare of screenShares; track screenShare) {
      <kip-opentok-video-subscriber [session]="session" [stream]="screenShare" [subscribeAudio]="false"
        [subscribeVideo]="true" [tutorId]="tutorId" />
      }
      <div class="d-none">
        @for (audioShare of audioShares; track audioShare) {
        <kip-opentok-video-subscriber [session]="session" [stream]="audioShare" [subscribeAudio]="true"
          [subscribeVideo]="false" [tutorId]="tutorId" />
        }
      </div>
    </div>
    }
    @if (screenShares.length === 0) {
    <div class="d-flex border-0 px-3 py-1 rounded-lg d-flex justify-content-center">
      <kip-monty-error style="width: 200px;" />
      <p class="ms-2">The tutor is not currently broadcasting.</p>
    </div>
    }
  </div>
  <div class="modal-footer">
    @if (screenShares.length > 0) {
    @if (!publishing) {
    <button class="btn btn-secondary" type="button" (click)="startTalking()">Start
      Talking</button>
    }
    @if (publishing) {
    <button class="btn btn-secondary" type="button" (click)="stopTalking()">Stop Talking</button>
    }
    }
    <button class="btn btn-primary" type="button" (click)="close()">Cancel</button>
  </div>
</ng-template>