import { QuestionParametersMultipleChoice, QuestionSummary } from '../../models';

export function convertSingleChoice(question: QuestionSummary<QuestionParametersMultipleChoice>, genericInput: string,
  genericChoice: string, render: (input: string) => string, showAnswerOnly: boolean) {

  if (showAnswerOnly) {

    const answerValue = question.parameters.options.find(x => x.value === question.answers[0].values[0])?.text;
    const answerIndex = question.parameters.options.findIndex(x => x.value === question.answers[0].values[0]);

    if (answerValue?.includes('<p><img')) {
      const alphabetLetter = String.fromCharCode(65 + answerIndex);
      return `<span class="kip-success-tutor-answer">${alphabetLetter}</span>`;
    }

    if (answerValue?.includes('<p>')) {
      return `${answerValue.replace('<p>', '<p class="mb-0"><span class="kip-success-tutor-answer">')}</span>` ?? '';
    }

    return `<span class="kip-success-tutor-answer">${answerValue}</span>`;
  }

  const answer = `<span class="kip-success-tutor-answer">${genericInput.replace('&nbsp;', question.answers[0].values[0].toString())}`;

  let optionsText = '';
  for (const option of question.parameters.options) {
    optionsText += option.value === question.answers[0].values[0] ? `| <b>${render(option.text ?? '')}</b>` : `| ${render(option.text ?? '')}`;
  }
  optionsText = genericChoice.replace('&nbsp;', optionsText.slice(2));

  if (question.text.includes('___')) {
    return `${question.text.replace('___', answer) + optionsText}1231232112312321`;
  }

  const optionsAndAnswers = optionsText + answer;
  return `${question.text + optionsAndAnswers} 12312321`;
}
