import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { initializeSentry } from 'auth-lib';
import * as deepmerge from 'deepmerge';
import { OpenTokErrorCodes } from 'open-tok-lib';
import { map, Observable, shareReplay } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { environment } from '../../../environments/environment';
import { Environment } from '../../../environments/environment-interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  readonly #httpClient = inject(HttpClient);

  config$: Observable<Environment>;

  constructor() {
    this.config$ = this.#httpClient.get<Partial<Environment>>('/assets/config/config.json').pipe(
      map(customConfig => {
        const mergedConfig = deepmerge(environment, customConfig);

        // This is a hack for now to get around the environment constant.
        Object.assign(environment, mergedConfig);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ServiceEnvironment.value = mergedConfig as any;

        const whiteboardResizingBug1 = '\'save\')';
        const whiteboardResizingBug2 = 'clearRect';
        const whiteboardResizingBug3 = '\'getRetinaScaling\')';
        const authConfigBug = 'could not find matching config for state';
        const soundBug = 'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission';
        const openTokStatsTestBug1 = 'getStats';
        const openTokStatsTestBug2 = 'Cannot read properties of undefined (reading \'sample\')';
        const nonException = 'Non-Error exception captured with keys';
        const angularIssue1 = 'Failed to execute \'invoke\' on \'CreateHTMLCallback\': The provided callback is no longer runnable';
        const soundPlayError1 = 'The play() request was interrupted by a call to pause()';
        const soundPlayError2 = 'play() failed because the user didn\'t interact with the document first';
        const overlayError = 'The overlay does not exist';
        const openTokProcessMessageBug = 'Cannot read properties of null (reading \'processMessage\')';
        const pdfError = 'startCleanup: Page';
        const avatarError = 'Error creating WebGL context';
        const enumerateError = 'AbortError: enumerateDevices() failed';

        initializeSentry(mergedConfig.sentry, [OpenTokErrorCodes.AccessDenied, OpenTokErrorCodes.HardwareUnavailable,
          whiteboardResizingBug1, whiteboardResizingBug2, whiteboardResizingBug3, authConfigBug, soundBug, nonException,
          openTokStatsTestBug1, openTokStatsTestBug2, angularIssue1, soundPlayError1, soundPlayError2, overlayError,
          openTokProcessMessageBug, pdfError, avatarError, enumerateError]);

        return mergedConfig;
      }),
      shareReplay(1)
    );
  }
}
