<div class="kip-monty">
  <kip-monty-error class="kip-monty__asset" />
  <p class="kip-monty__headline">
    Uh-oh! You don't seem to have access to this site.
  </p>
  <p class="kip-monty__text">
    We track these errors automatically, but if the problem persists, feel free to <a
      href="https://kipmcgrath.atlassian.net/servicedesk/customer/portal/21" rel="noopener" target="_blank">contact
      us</a>.<br />In the
    meantime, try reloading.
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="gotoParentPortal()">Parent Portal</button>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="reloadPage()">Reload</button>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="logout()">Logout</button>
</div>