import { createAction, props } from '@ngrx/store';
import { OverlaysBackgroundColor } from 'ui-common-lib';

enum OverlaysBackgroundColorActionTypes {
  SetColor = 'Student > Set Settings'
}

export const setColorAction = createAction(
  OverlaysBackgroundColorActionTypes.SetColor,
  props<{
    overlays?: OverlaysBackgroundColor;
    backgrounds?: OverlaysBackgroundColor;
    isStudentInTutorMode?: boolean;
  }>()
);
