@if (activity && activity.isManual) {
<div class="d-flex flex-column flex-fill h-100 mh-100">
  <kip-pdf class="h-100 mh-100" [activityDescription]="activity.description | currencySwap : regionId"
    [activityHtml]="activity.html" [activityName]="activity.name | currencySwap : regionId"
    [allowJira]="allowJira && !activity.isJiraReportingBlocked" [allowToggleAnswers]="allowToggleAnswers"
    [pdfFile]="pdfToView" [soundFile]="activity.soundFile" (displayJira)="jira.open()"
    (toggleAnswers)="toggleAnswers()" />
  <kip-jira-modal #jira requestText="Report Activity Content" style="display:none"
    [jiraStyleType]="jiraStyleTypeContent" [metaData]="jiraMetaData" [requestType]="jiraRequestType" [uId]="jiraUId" />
</div>
}