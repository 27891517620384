@if (!isInline) {
<h2 class="pb-3 fw-light">Create account for {{ contactName }}</h2>
}
@if (!resultStatus || resultStatus === resultStatuses.PasswordInvalid || resultStatus ===
resultStatuses.UserNameAlreadyTaken) {
<form [formGroup]="loginForm" (ngSubmit)="submit()">
  <div class="kip-form-group" [ngClass]="{'form-group-invalid' : username | isFormControlInvalid }">
    <label class="w-100 d-flex justify-content-between align-items-baseline" for="username"><span>Username</span><span
        class="small text-muted">Must be at least 6 characters</span></label>
    <input class="form-control" id="username" type="text" [formControl]="username" (input)="clearResults()">
    <kip-control-error
      clientSideErrorMessage="Minimum 6 characters. Only letters and the following symbols are allowed: -._@+)"
      [control]="username" />
  </div>
  @if (resultStatus === resultStatuses.UserNameAlreadyTaken) {
  <div class="form-group-invalid mb-3">
    <span class="form-control-invalid">The requested username is already in use, try a different one.</span>
  </div>
  }
  <kip-password-form-partial [form]="loginForm" />
  @if (resultStatus === resultStatuses.PasswordInvalid) {
  <div class="form-group-invalid mb-3">
    <span class="form-control-invalid">The server rejected the provided password. Try a more complex password containing
      a mixture of upper and lower case characters and numbers.</span>
  </div>
  }
  <div class="kip-form-group" [ngClass]="{'form-group-invalid' : loginForm | isFormControlInvalid }">
    <kip-control-error [control]="loginForm" />
  </div>
  <button class="btn btn-primary" type="submit" [disabled]="!loginForm.valid">Create account</button>
  <button class="btn btn-link" type="button" (click)="cancelled.emit()">Cancel</button>
</form>
}
@if (resultStatus === resultStatuses.UserAlreadyExists) {
<div class="ms-2 form-group-invalid">
  <div class="form-control-invalid">
    A login has already been created for {{ contactName }}, refreshing the contacts will retrieve the updated details.
  </div>
  <button class="btn btn-primary mt-3" type="button" (click)="submitted.emit()">Refresh Contacts</button>
</div>
}
@if (resultStatus === resultStatuses.ContactNotFound || resultStatus === resultStatuses.UnexpectedFailure) {
<div class="ms-2 form-group-invalid">
  <div class="form-control-invalid">
    A login for {{ contactName }} couldn't be created. If this problem continues please contact the service desk.
  </div>
  <button class="btn btn-primary mt-3" type="button" (click)="submitted.emit()">Close</button>
</div>
}