import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-celebrate-party',
  templateUrl: './celebrate-party.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCelebratePartyComponent implements IconComponent {

}
