export enum OrganisationActivitiesRoutes {
  List = 'list',
  Edit = 'edit',
  New = 'new'
}

export enum OrganisationActivitiesRouteParams {
  ActivityId = 'activityId'
}

export function gotoOrganisationActivitiesEdit(activityId: number) {
  return `/${activityId}/${OrganisationActivitiesRoutes.Edit}`;
}

export function gotoOrganisationActivitiesNew() {
  return `/${OrganisationActivitiesRoutes.New}`;
}

export function gotoOrganisationActivitiesList() {
  return `/${OrganisationActivitiesRoutes.List}`;
}
