import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { TreeName } from '../../models';
import { SharedService } from '../../services';

@Component({
  selector: 'kip-simple-tree-picker',
  templateUrl: './tree-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTreePickerComponent implements OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subjectId = 0;
  #regionId: FormControl<number | null> | undefined;
  #subscriptions: Subscription[] = [];

  treeNames: TreeName[] = [];

  @Input({ required: true }) set regionId(value: FormControl<number | null> | undefined) {
    this.#regionId = value;
    if (value) {
      this.#getTreeNames(value.value);
      this.#subscriptions.push(value.valueChanges.subscribe(regionIdValue => {
        this.#getTreeNames(regionIdValue);
      }));
    }
  }

  get regionId() {
    return this.#regionId;
  }

  @Input({ required: true }) set subjectId(value: number) {
    if (this.#subjectId !== value) {
      this.#subjectId = value;
      const regionId = this.#regionId;
      if (regionId) {
        this.#getTreeNames(regionId.value);
      }
    }
  }

  get subjectId() {
    return this.#subjectId;
  }

  @Input({ required: true }) treeId: FormControl<number | null> | undefined;
  @Input({ required: true }) label = '';
  @Input() description = '';
  @Input({ required: true }) name = '';

  @Input() labelClass: string | undefined;

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #getTreeNames(value: number | null) {
    this.#subscriptions.push(
      this.#sharedService.getTreeNames(value ?? 0)
        .subscribe(treeNames => {
          // allow assessments/other subject to be any tree
          this.treeNames = (treeNames || []).filter(s => s.subjectIds.length === 0 || s.subjectIds.includes(this.subjectId) || this.subjectId === 9 || this.subjectId === 3);
          this.#changeDetectorRef.markForCheck();
        }));
  }
}
