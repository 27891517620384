@if (dropInSessionDescription) {
<div class="kip-form-group" [ngClass]="{'form-group-invalid' : dropInSessionDescription | isFormControlInvalid }">
  <label for="desciption">Tutor Description (@if (dropInSessionDescription.value) {
    <span>{{dropInSessionDescription.value.length}}</span>
    }/500)</label>
  <input autocomplete="off" class="form-control" id="description" kipStopPropagation maxlength="500" name="description"
    type="text" [formControl]="dropInSessionDescription">
  <kip-control-error clientSideErrorMessage="Description can be no longer than 500 characters"
    [control]="dropInSessionDescription" />
</div>
}

<kip-tutor-drop-in-session-subject-settings [allSubjects]="allSubjects"
  [dropInSessionSubjects]="dropInSessionSubjects" />

<kip-tutor-drop-in-session-proficiency-settings [allProficiencies]="allProficiencies"
  [dropInSessionProficiencies]="dropInSessionProficiencies" />