import { createCurrencySymbols } from './create-currency-symbols';

export function createCurrencyParser(format: Intl.NumberFormat): (value: string) => number {

  const symbols = createCurrencySymbols(format);
  const pattern = `[^\\d\\${symbols.decimal}\\${symbols.negative}]`;
  const expression = new RegExp(pattern, 'g');

  return value => {
    const sanitized = value?.replace(expression, '')?.replace(symbols.decimal, '.');
    return Number.parseFloat(sanitized);
  };
}
