<div class="kip-word-picker-layout" [class.kip-readonly]="readonly">
  <kip-question-text [question]="question" />
  <div class="col-12">
    @for (paragraph of paragraphs; track paragraph) {
    <p>
      @for (word of paragraph.words; track word) {
      <span
        [class]="'kip-bring-to-front kip-word rounded px-1 ' + (word.valid | answerValidation: 'kip-word-correct': 'kip-word-incorrect')"
        [class.kip-selected]="selected(word.index)" [innerHTML]="word.text | safe : 'html'"
        (click)="updateWordIndices(word.index)"></span>
      }
    </p>
    }
  </div>
  @if (showHint) {
  <div class="col-12 mt-4">
    Hint : There are more correct answers to find
  </div>
  }
</div>