import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kip-form-control-contact-type',
  templateUrl: './form-control-contact-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlContactTypeComponent {

  readonly isPrimary = input.required<FormControl<boolean | null>>();
  readonly isEmergency = input.required<FormControl<boolean | null>>();

}
