<div class="mb-3 p-1 fw-light h4">You can generate questions, review and edit them. Once you save you will not be able
  to alter the questions in any way.</div>

<kip-form-group-wrapper submitText="Generate Questions" [formGroup]="aiForm" [submitDisabled]="loading"
  (submittedAndValidated)="getQuestions()">
  <div class="kip-form-group">
    <label for="aiTopic">Topic
      <span class="ms-2 small text-uppercase">Tell AI what to generate questions about - Email content team for new
        "permanent" topics</span>
    </label>
    <ng-select id="aiTopic" [addTag]="addTopicPromise" [items]="aiTopics" [ngModel]="topic.value"
      [ngModelOptions]="{standalone: true}" (ngModelChange)="topic.setValue($event)" />
  </div>
  <kip-form-control-grade id="grade" label="Grade" labelExtra="tell AI difficulty of questions" [control]="gradeId" />
  <kip-form-control-select id="questions" label="Questions" [control]="questions" [values]="questionCountSelection" />
  <kip-form-control-select id="multipleChoiceAnswer" label="Multiple Choice Answers" [control]="multipleChoiceAnswers"
    [values]="multipleAnswersCountSelection" />
  <div class="kip-form-group">
    <label for="aiPrompt">Standard Prompts
      @for (regionId of regionIds; track regionId)
      {
      <kip-region-flag [regionId]="regionId" />
      }
    </label>
    <select class="form-control" id="aiPrompt" [ngModelOptions]="{standalone: true}" [(ngModel)]="aiPromptValue"
      (ngModelChange)="additionalPrompt.setValue($event.prompt)">
      @for (aiPrompt of aiPrompts; track aiPrompt) {
      <option [ngValue]="aiPrompt" [selected]="aiPrompt.id === aiPromptValue.id">
        {{ aiPrompt.name }}</option>
      }
    </select>
  </div>
  <kip-form-control-long-text id="additionalPrompt" label="Additional Prompt" labelExtra="Any additional AI information"
    [control]="additionalPrompt" [rows]="3" />
  Note - generating questions is VERY slow. It may take a minute or even more to generate more than 10 questions.
</kip-form-group-wrapper>

@if (generatedActivity.content.length > 0)
{
<div class="row">
  <div class="form-group">
    <label class="mb-3 p-1 fw-light h4" for="additionalData">Additional Data</label>
    <textarea class="form-control mb-2" id="additionalData" rows="3"
      [(ngModel)]="generatedActivity.additionalData"></textarea>
  </div>
</div>
}

@for (generatedQuestion of generatedActivity.content; track generatedQuestion; let index=$index) {
<div class="row">
  <div class="form-group">
    <label class="mb-3 p-1 fw-light h4" [for]="'question' + index">Question #{{index++1}}</label>
    <textarea class="form-control mb-2" rows="3" [id]="'question' + index"
      [(ngModel)]="generatedQuestion.question"></textarea>
  </div>
</div>
<div class="row">
  <div class="form-group">
    <label [for]="'correctAnswer' + index">Correct Answer</label>
    <input class="form-control mb-2" type="text" [id]="'correctAnswer' + index"
      [(ngModel)]="generatedQuestion.answer" />
  </div>
</div>
<div class="row">
  <div class="form-group">
    <label [for]="'explanation' + index">Answer Explanation</label>
    <textarea class="form-control mb-2" rows="3" [id]="'explanation' + index"
      [(ngModel)]="generatedQuestion.explanation"></textarea>
  </div>
</div>
<div class="row">
  <div class="form-group">
    <label>Possible Answers</label>
    @for (selection of generatedQuestion.selection; track selection; let index2=$index;) {
    <input class="form-control mb-2" type="text" [(ngModel)]="generatedQuestion.selection[index2]" />
    }
  </div>
</div>
}