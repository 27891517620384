@if (pointsData) {
<div class="d-flex rounded p-2" style="color:white" [style.background-color]="pointsData.ribbonColor">
  <div>
    <fa-icon class="bg-white border p-1 me-2" [icon]="pointsData.icon" />
    <!--
    Investigate how to fix this.
  <fa-icon class="bg-white border p-1 me-2" [icon]="pointsData.icon"
      [styles]="{'stroke': pointsData.iconColor, 'color': pointsData.iconColor}"
       /> -->
    <div class="fw-bold" style="width:130px;display:inline-block">{{ pointsData.title }}</div>
  </div>
  @if (students) {
  <div class="badge kip-badge kip-badge--outline ms-auto text-white border-white">
    {{ students }} students
  </div>
  }
</div>
<div
  class="col-12 small text-overline text-secondary text-center d-flex align-items-center justify-content-center border-start border-end border-bottom rounded-bottom pt-2 pb-1 mt-n1">
  {{ pointsData.points }} points and then get award <kip-icon class="ms-2" style="height: 20px;width: 20px;"
    [displayIcon]="pointsData.award" />
</div>
}