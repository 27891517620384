import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-finish-flag',
  templateUrl: './finish-flag.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconFinishFlagComponent implements IconComponent {

}
