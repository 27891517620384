@if (!allowCancel) {
<div class="h2 py-3 fw-light ms-3">Custom Activity</div>
}
<form class="col-12" id="customActivityForm" [formGroup]="organisationActivityForm" (ngSubmit)="submit()">
  <div class="border rounded p-3">
    <div class="mb-3 p-1 fw-light h4">Audience</div>
    @if (organisations.length !== 1) {
    <kip-form-control-select id="organisationId" label="Organisation" [control]="organisationId"
      [values]="organisations" />
    }
    <kip-model-select id="groupingId" label="Activity Audience" [ngModelOptions]="{standalone: true}" [values]="groups"
      [(ngModel)]="currentGrouping" />

    @if (currentGrouping === activityGrouping.Subject) {
    <kip-form-control-select id="subjectId" label="Subject" [control]="subjectId" [values]="subjects" />
    }

    @if (currentGrouping === activityGrouping.Student) {
    <kip-form-control-select id="studentId" label="Student" [control]="studentId" [values]="students" />
    }

    @if (currentGrouping === activityGrouping.Centre) {
    <kip-form-control-select id="centreId" label="Centre" [control]="centreId" [values]="centres" />
    }

    @if (currentGrouping === activityGrouping.Tutor) {
    <kip-form-control-select id="tutorId" label="Tutor" [control]="tutorId" [values]="tutors" />
    }

  </div>
  <div class="border rounded p-3 mt-3">
    <div class="mb-3 p-1 fw-light h4">Description</div>
    <kip-form-control-text id="name" label="Activity Name" [control]="name" [maxLength]="100" />
    <kip-form-control-long-text id="description" label="Short Description" [control]="description" [maxLength]="500"
      [rows]="3" />
  </div>

  <div class="border rounded p-3 mt-3">
    <div class="mb-3 p-1 fw-light h4">Settings</div>
    @if (allowHidden) {
    <kip-form-control-checkbox id="isHidden" label="Do not show activity in Explore list" [control]="isHidden" />
    }
    <kip-form-control-checkbox id="isHiddenStudent" label="Do not show activity for students (only visible for Tutors)"
      [control]="isHiddenStudent" />
    <kip-form-control-checkbox id="repeatEachLesson"
      label="Repeat each lesson (this activity will appear in each subsequent lesson until removed)"
      [control]="repeatEachLesson" />
    @if (activityType !== activityTypes.Ai)
    {
    <kip-form-control-checkbox id="isSimpleScoring" label="Simple scoring (A/B/C)" [control]="isSimpleScoring" />
    <kip-form-control-number id="manualQuestionCount" label="Maximum score" [control]="manualQuestionCount" [max]="100"
      [min]="0" />
    }
  </div>

  <div class="border rounded p-3 mt-3">
    <div class="mb-3 p-1 fw-light h4">Timers</div>
    <kip-form-control-number errorMessage="Please provide a valid Max Allowed Time (0-100 minutes or blank)"
      id="maxAllowedTimeMinutes" label="Maximum allowed time (minutes)" [control]="maxAllowedTimeMinutes" [max]="100"
      [min]="0" />
    <kip-form-control-number errorMessage="Please provide a valid Warning Time (0-100 minutes or blank)"
      id="warningTimeMinutes" label="Warning time (minutes)" [control]="warningTimeMinutes" [max]="100" [min]="0" />
    <kip-form-control-checkbox id="warningTimeTutorOnly" label="Only warn Tutor when warning time is up"
      [control]="warningTimeTutorOnly" />
  </div>
  <div class="border rounded p-3 mt-3">
    <div class="mb-3 p-1 fw-light h4">Add Content</div>
    @if (isNew) {
    <div class="d-flex w-100 my-3">
      <div
        class="btn kip-btn border rounded-lg py-4 px-3 me-2 d-flex flex-column h-100 align-items-center justify-content-center flex-grow-1"
        [class.selected]="activityType === activityTypes.General" (click)="activityType = activityTypes.General">
        <fa-icon class="mb-3" size="3x" [icon]="icons.whiteboardOpen" />
        <span class="text-secondary">I'd like to type the content myself</span>
      </div>
      <div
        class="btn kip-btn border rounded-lg py-4 px-3 ms-2 d-flex flex-column h-100 align-items-center justify-content-center flex-grow-1"
        [class.selected]="activityType === activityTypes.Pdf" (click)="activityType = activityTypes.Pdf">
        <fa-icon class="mb-3" size="3x" [icon]="icons.activityType.manual" />
        <span class="text-secondary">I'd like to upload a PDF or Document</span>
      </div>
      @if (aiQuestionsEnabled)
      {
      <div
        class="btn kip-btn border rounded-lg py-4 px-3 ms-2 d-flex flex-column h-100 align-items-center justify-content-center flex-grow-1"
        [class.selected]="activityType === activityTypes.Ai" (click)="activityType = activityTypes.Ai">
        <fa-icon class="mb-3" size="3x" [icon]="icons.brainBooster" />
        <span class="text-secondary">I'd like to create questions using AI</span>
      </div>
      }
    </div>
    }

    @switch (activityType) {
    @case (activityTypes.General) {
    <kip-markdown-editor [text]="activityMarkdown" [textLabel]="'Activity Content'" (update)="markdownChange($event)" />
    }
    @case (activityTypes.Ai) {
    @if (!isNew)
    {
    <div class="alert alert-primary d-flex align-items-center">
      <fa-icon class="me-2" size="2x" [icon]="icons.information.solid" />
      <div><span class="fw-bold">This has AI generated questions, they can be seen below.<br /><br />
          {{ organisationActivity?.additionalData ?? ''}}
        </span></div>
    </div>
    <kip-explore [activityData]="{activityId: activityId}" />
    }
    @else {
    <kip-organisation-activity-ai [regionIds]="regionIds" [(generatedActivity)]="generatedActivity" />
    }
    }
    @case (activityTypes.Pdf)
    {
    @if (isNew)
    {
    <div class="alert alert-primary d-flex align-items-center">
      <fa-icon class="me-2" size="2x" [icon]="icons.information.solid" />
      <div><span class="fw-bold">To upload a document to this activity, please press Save and add the files in the
          box that appears below.</span></div>
    </div>
    }
    @else {
    <div class="col-8">
      <div>
        @if (activityType === activityTypes.Pdf && isNew) {
        <div class="alert alert-primary d-flex align-items-center">
          <fa-icon class="me-2" size="2x" [icon]="icons.information.solid" />
          <div><span class="fw-bold">Below you can upload a PDF/Word file for the activity. You can also upload the
              solution PDF/Word file
              with the answers, which is visible to the tutor only.</span></div>
        </div>
        }
      </div>
      <div>
        <kip-organisation-activity-pdf title="Main PDF" [activityId]="id.value" [pdfFile]="pdfFile"
          [solution]="false" />
      </div>
      <div>
        <kip-organisation-activity-pdf title="Solution PDF (optional)" [activityId]="id.value"
          [pdfFile]="pdfSolutionFile" [solution]="true" />
      </div>
    </div>
    }
    }
    }
    <kip-form-error [formGroup]="organisationActivityForm" />
  </div>
  <div class="w-100 d-flex mt-3">
    @if (allowCancel) {
    <button class="btn btn-outline-primary" type="button" (click)="cancelled.emit()">Back to
      custom activity list</button>
    }
    @if (!allowCancel && !isNew) {
    <div class="btn btn-outline-primary" (click)="skipUpload()">Create another
      activity</div>
    }
    @if (isNew || allowCancel) {
    <button class="btn btn-primary ms-auto" type="submit" [disabled]="submitDisabled">{{ saveButtonText }}</button>
    }
  </div>
</form>