@if (ranking(); as ranking) {
  <div class="text-center" data-placement="top" data-toggle="tooltip" [title]="comment()">
    @if (label(); as label) {
      <span class="ms-2 me-2">{{ label }}</span>
    }
    <fa-icon class="ms-2" size="sm"
      [class.text-danger]="ranking < 3"
      [class.text-secondary]="ranking === 0"
      [class.text-success]="ranking >= 4"
      [class.text-warning]="ranking >= 3 && ranking < 4"
      [icon]="icons.points.star.solid" />
    <span class="small"
      [class.text-danger]="ranking < 3"
      [class.text-secondary]="ranking === 0"
      [class.text-success]="ranking >= 4"
      [class.text-warning]="ranking >= 3 && ranking < 4">
      {{ ranking | number : '1.0-1' }}
    </span>
  </div>
}
