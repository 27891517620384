/* eslint-disable @typescript-eslint/no-wrapper-object-types */

import { fabric } from 'fabric';

import { FabricObjectExtended } from '.';

interface ObjectExtended extends Object {
  top: number;
  left: number;
  scaleX: number;
  scaleY: number;
}

export interface TransformExtended {
  corner: string;
  original: ObjectExtended;
  originX: string;
  originY: string;
  width: number;
  theta: number;
}

export abstract class WhiteboardMemento {

  abstract apply(): void;
  abstract revert(): void;

  protected invoke(action: () => void, ...objects: fabric.Object[]) {

    // Tag the objects as mementos
    for (const object of objects) {
      const objExtended = object as FabricObjectExtended;
      if (objExtended.data) {
        try {
          objExtended.data.memento = true;
        } catch {
          objExtended.data = Object.assign({}, objExtended.data, { memento: true });
        }
      }
    }

    action();

    // Then clear the memento flag after processing the action
    for (const obj of objects) {
      const objExtended = obj as FabricObjectExtended;
      if (objExtended.data) {
        try {
          delete objExtended.data.memento;
        } catch {
          objExtended.data = Object.assign({}, objExtended.data, { memento: undefined });
        }
      }
    }
  }

  protected emitTransformation(name: string, obj: fabric.Object, canvas: fabric.Canvas) {
    canvas.fire(name, {
      target: obj,
      transform: {}
    });
  }
}
