export function sortByAscending<T>(pluck: keyof T) {

  return (left: T, right: T) => {

    const leftValue = left[pluck];
    const rightValue = right[pluck];

    return leftValue === rightValue ? 0 : leftValue < rightValue ? -1 : 1;
  };
}
