@if (title && students && students.length > 0) {
<div class="d-flex rounded text-dark bg-light p-2 mb-2">
  <span class="fw-bold">{{ title }}</span>
  <div class="badge kip-badge kip-badge--outline ms-auto bg-white">
    {{ count }} students
  </div>
</div>
}
@if (students && students.length > 0) {
<div class="row">
  @for (student of students; track student; let i = $index) {
  <div class="col-12">
    @if (i > 10 && showAll || i < 10) { <div class="border-bottom p-2 small">
      <div class="row">
        <div class="col">
          {{ student.name }}
        </div>
        <div class="col-6 ms-auto row">
          <div class="col-4 text-nowrap">
            <fa-icon class="pe-1" [icon]="icons.points.star.solid" />{{ student.points ?? 0 }}
          </div>
          <div class="col-4 text-nowrap">
            <fa-icon class="pe-1" [icon]="icons.points.medal" /> {{ student.awards ?? 0}}
          </div>
          <div class="col-4 text-nowrap">
            <fa-icon class="pe-1" [icon]="icons.points.certificates" />{{ student.certificates ?? 0}}
          </div>
        </div>
      </div>
  </div>
  }
</div>
}
@if (students.length > 10 && !showAll) {
<div class="btn-link text-link w-100 text-center p-2 text-primary cursor-pointer" (click)="showAll = !showAll">Show all
  ...</div>
}
</div>
}