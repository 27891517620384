import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';

import { commonRegex } from '../common-regex/common-regex';
import { CommonValidators } from '../common-validators/common-validators';
import { ConfirmPassword } from '../models';
import { ProfileService } from '../services';

interface TutorPasswordReset {
  password: string;
}

@Component({
  selector: 'kip-tutor-password',
  templateUrl: './tutor-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TutorPasswordComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #resettingPassword = false;
  #userName = '';
  #subscriptions: Subscription[] = [];

  passwordForm: FormGroupTypeSafe<ConfirmPassword & TutorPasswordReset>;

  set resettingPassword(value: boolean) {
    if (value && !this.#userName) {
      this.#subscriptions.push(
        this.#profileService.getTutorUserName().subscribe(tutorUserName => {
          this.#userName = tutorUserName.userName;
          this.#changeDetectorRef.markForCheck();
        }));
    }
    this.#resettingPassword = value;
  }

  get resettingPassword() {
    return this.#resettingPassword;
  }

  get password() {
    return this.passwordForm.getSafe(p => p.password);
  }

  get username() {
    return this.#userName;
  }

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */

    this.passwordForm = this.#fb.group<ConfirmPassword & TutorPasswordReset>({
      password: new FormControl<string | null>(null, [Validators.required, Validators.pattern(commonRegex.password), CommonValidators.hasUniqueCharacter]),
      confirmPassword: new FormControl<string | null>(null, Validators.required)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    const password = this.password.value;
    this.#subscriptions.push(
      this.#profileService.resetTutorPassword(password).subscribe({
        next: value => {
          if (value === null) {
            this.resettingPassword = false;
            this.passwordForm.reset();
            this.#changeDetectorRef.markForCheck();
          }
        },
        error: (error: unknown) => {
          this.passwordForm.handleServerErrors(error);
          this.#changeDetectorRef.markForCheck();
        }
      }));
  }
}
