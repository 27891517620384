import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { generateBreadcrumbs, RouteParams, Student, studentsRoute, toName } from 'ui-common-lib';

import { StudentService } from '../services';

@Component({
  selector: 'kip-student-edit',
  templateUrl: './student-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentEditComponent implements OnInit, OnDestroy {

  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #student: Student | undefined;
  #subscriptions: Subscription[] = [];

  get breadcrumbs() {
    return generateBreadcrumbs(2, [studentsRoute, toName(this.#student)]);
  }

  get student() {
    return this.#student;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#route.paramMap.subscribe(paramMap => {
        const studentId = +(paramMap.get(RouteParams.StudentId) ?? 0);
        this.#subscriptions.push(
          this.#studentService.getStudentInfoById(studentId)
            .subscribe(studentDetails => {
              this.#student = studentDetails;
              this.#changeDetectorRef.markForCheck();
            }));
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    this.#router.navigate(['..'], { relativeTo: this.#route });
  }

  resetPassword(studentDetails: Student) {
    this.#router.navigate(['password'], { relativeTo: this.#route, state: { student: studentDetails } });
  }

  cancel() {
    this.#router.navigate(['..'], { relativeTo: this.#route });
  }

}
