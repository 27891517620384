import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-explosive-bang',
  templateUrl: './explosive-bang.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconExplosiveBangComponent implements IconComponent {

}
