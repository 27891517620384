/* eslint-disable spellcheck/spell-checker */

export enum HelpNodeName {
  UsingKipLearn = 'B0263D14-39CB-401A-96AB-729EA6F9BB79',
  Tutor = 'A89562C6-420E-47E0-8F3A-DE1DD1A4915E',
  TutorMyDay = '2EB96389-C7C1-49B9-904A-1840ED47D15C',
  LessonPlanning = '3D91DC58-F6A7-4D21-895E-D8804FA19CEB',
  LessonPlanningInitialPlans = '1FE85352-C026-48B8-8C0E-14A015C42CB8',
  LessonPlanningInitialPlansMathsEOG = '52432249-12F7-4EEF-9447-F3074387061A',
  LessonPlanningInitialPlansSpelling = '1B09E92E-ED6F-4EC9-9DD2-FDA9A014C2C4',
  LessonPlanningInitialPlansReading = '1238515E-234C-41EF-AB3E-ECE64C8C2591',
  LessonPlanningMarking = '75252C0E-EEDB-41A0-95F4-47FA20B147F8',
  LessonPlanningOngoingPlans = '6FE280D4-D3BA-42D2-A9B9-2669D73BFC44',
  LessonPlanningAddingActivities = 'FF3F8EBB-C194-45D3-A63D-3D0AA393D74C',
  LessonPlanningAddingActivitiesSelect = 'E6B77710-496B-46F1-91E9-10BAE8987720',
  LessonPlanningAddingActivitiesHistory = 'E9D72448-3B74-4BC5-88A4-34A54A3BE6A8',
  LessonPlanningAddingActivitiesCreate = '997A4AFF-4746-4C98-9426-BBCAF90DF2BB',
  LessonPlanningStudent = 'E1D9F5E7-C6D7-4EF0-9C46-33935EA00FD1',
  TutorPortal = 'F5490AED-71B8-4F74-ACA4-C73FDFFACB6C',
  Plan = '856A5F79-3FBA-4AAE-8A82-F145590024A2',
  Print = '058988D3-28D7-4601-8663-653062A7DA7F',
  Mark = 'D6CC1937-08B8-4225-B00A-E214778C3AA0',
  CustomActivities = '5256EFE8-B820-4A40-A4AD-50CEBE607119',
  Goals = '51C34913-05EB-48C4-BA27-3B1D9DCFC4A1',
  Explore = '8E01D9B5-C975-46BC-BA17-079A2333C614',
  Tree = '8E01D9B5-C975-46BC-BA17-079A2333C615',
  TutorSettings = '76472D46-63C3-468C-BFC6-50B7B25E70EB',
  ReportIssue = '2E864EC7-ADB9-402B-9F04-780B98717B2F',
  Student = '05E1C4D3-0755-4871-9FB1-EAAC8C62F28A',
  StudentMyDay = '248172FE-BCA3-4E93-9267-0DFB60BE8C84',
  Lobby = '8E304075-31B4-4369-A761-0A6765B1CEA2',
  Lesson = '355BBF8A-2763-4CBE-8A4D-F4FD39C4B0EC',
  StudentSettings = 'AD59637C-ED05-488C-8401-8B59A9835A9E',
  Awards = '60F67985-DAD7-4DD1-A136-EAB3D6559DC1',
  Both = '0176807E-6D04-4322-9B35-1CBF4FE8068D',
  Avatar = '3342FC00-86F4-4A7E-8B46-F72FFB1A4EF3',
  Parent = 'E604123E-7D0F-473A-B52A-36CD3A5CE39F',
  TBD = 'E22E1149-C266-4919-A7A8-4E1ABCF5A726',
  DIY = 'E1904F38-0F9F-46D5-8E5C-A05C6F6839B7',
  DropIn = 'B2E2AF25-DBFF-45B0-AD5F-983001C5292F',
  AcceptableLessonPlans = 'E46B490A-0FB4-4641-83E7-79AF61A3D79F',
  KipDifference = '688BF39F-E867-4FE2-B7CC-82CE3F23892A',
  KnowYourParents = 'FE6DD336-2C5D-468F-B9A3-C537C29C6967',
  KnowYourStudents = 'B81CFFFD-B29D-4797-992E-C1CD543283CE',
  OurKipCurriculum = '3D80C8F2-666A-4313-8336-ED7DD2789879',
  ReadyForKipLearn = 'F023C8D7-BD05-43C5-A596-72F77FE2A9E3',
  InitialPlanTest = 'AF44B897-9A55-4860-B4E6-06EC95BCAEC9',
  History = '261118eb-3320-4f27-b9ad-cc8a4ade33f0',
  FmsUsingKipManage = 'E6371F2C-D572-4046-A118-E43262CF4AE2',
  NextTest = '32D14C73-6263-47BD-9280-0226895F8DED',
  ReadyPlayerMeCreate = '812E83DE-CD37-413D-A280-B2798073EADD',
  ReadyPlayerMeInteract = '912E83DE-CD37-413D-A280-B2798073EADD'
}
