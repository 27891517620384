<div class="d-flex flex-column h-100">
  <div class="theme-aurora p-md-4 flex-fill d-flex justify-content-center">
    <div class="kip-student w-100 h-100 m-md-auto">
      <div
        class="d-flex flex-column overflow-auto h-100 col-12 col-sm-6 col-md-10 col-xl-6 m-auto bg-white p-4 rounded">
        <img class="col-10 col-md-6 mx-auto mb-auto" src="./assets/svg/kip-logo-blue-landscape.svg">

        <kip-file-uploader [disabled]="isUploadingFinished" [hasError]="hasError" [isUploaded]="isUploadingFinished"
          [isUploading]="isLoading" [progress]="progress$ | async" [title]="'Tap here to browse and select a photo'"
          (fileList)="files = $event" (fileModelList)="fileModels = $event" (newUploading)="onNewUploading()" />

        <div class="kip-form-group mt-3">
          <input class="form-control" id="activity-title" placeholder="Activity name (optional)..." type="text"
            [(ngModel)]="activityName">
        </div>

        <div class="w-100 text-end mt-auto">
          @if (currentUploadStatus !== uploadStatus.Success && currentUploadStatus !== uploadStatus.Error) {
          <button class="btn btn-primary btn-lg w-100" type="button" [disabled]="isLoading || !files.length"
            (click)="createAdHocActivity()">
            @if (currentUploadStatus === uploadStatus.Waiting) {
            <span>Submit file{{ files.length > 1 ? 's' : ''
              }}</span>
            }
            @if (currentUploadStatus === uploadStatus.Uploading) {
            <span>
              <kip-spinner class="me-2" /> Uploading
            </span>
            }
            @if (currentUploadStatus === uploadStatus.Processing) {
            <span>
              <kip-spinner class="me-2" /> Processing
            </span>
            }
          </button>
          }

          @if (currentUploadStatus === uploadStatus.Success || currentUploadStatus === uploadStatus.Error) {
          <button class="btn kip-btn text-btn btn-lg w-100 pointer-events-none border-0" type="button"
            [class.btn-danger]="currentUploadStatus === uploadStatus.Error"
            [class.btn-success]="currentUploadStatus === uploadStatus.Success" [disabled]>
            @if (currentUploadStatus === uploadStatus.Success) {
            <fa-icon class="me-2" [icon]="icons.check" />
            }
            @if (currentUploadStatus === uploadStatus.Error) {
            <fa-icon class="me-2" [icon]="icons.validity.invalid" />
            }
            {{ result }}
          </button>
          }

        </div>
      </div>
    </div>
  </div>
</div>