<ng-container>
  @if (awardMessage?.text) {
  <span> {{ awardMessage?.text }}</span>
  }
  @if (awardMessage?.icon) {
  <div style="width:25px; display:inline-block;">
    <kip-icon [displayIcon]="awardMessage?.icon" [skinTone]="skinTone" />
  </div>
  }
  @if (awardMessage?.label) {
  <span>{{ awardMessage?.label }}</span>
  }
</ng-container>