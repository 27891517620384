<label>{{ label() }} </label>
<div class="kip-form-group">
  <div class="kip-button-group btn-group btn-group-toggle">
    @if (!useEmojis()) {
    @for (rankingValue of rankingValues; track rankingValue) {
    @if (rankingValue !== null || allowNull()) {
    <input class="btn-check" type="radio" [disabled]="disabled()" [formControl]="ranking()"
      [id]="prefix + 'R' + (rankingValue??0)" [name]="prefix + 'rankingPicker'" [value]="rankingValue">
    <label class="kip-button-group__option btn" [for]="prefix + 'R' + (rankingValue??0)">
      @if (rankingValue !== null) {
      <fa-icon class="fa-2x" style="color:gold"
        [icon]="(ranking().value ?? 0) < rankingValue ?icons.points.star.regular: icons.points.star.solid" />
      }
    </label>
    }
    }
    }
    @else {
    @for (rankingValue of emojiRankingValues; track rankingValue) {
    @if (rankingValue !== null || allowNull()) {
    <input class="btn-check" type="radio" [disabled]="disabled()" [formControl]="ranking()"
      [id]="prefix + 'R' + (rankingValue.value ?? 0)" [name]="prefix + 'rankingPicker'" [value]="rankingValue.value">
    <label class="kip-button-group__option btn" [for]="prefix + 'R' + (rankingValue.value ?? 0)">
      @if (rankingValue.icon !== null) {
      <fa-icon class="fa-3x" [icon]="rankingValue.icon" />
      }
    </label>
    }
    }
    }
  </div>
</div>