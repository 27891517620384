import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SkinTone } from 'awards-lib';

import { BlockedEmojis } from '../emojis/blocked-emojis';
import { Emoji } from '../emojis/emoji';

@Component({
  selector: 'kip-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiPickerComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #skin: SkinTone = SkinTone.SkinToneA;
  #skinConverted: 1 | 2 | 3 | 4 | 5 | 6 = 1;

  get skinConverted() {
    return this.#skinConverted;
  }

  @Input({ required: true }) set skin(value: SkinTone) {
    this.#skin = value;
    switch (this.#skin) {
      case SkinTone.SkinToneA:
        this.#skinConverted = 1;
        break;
      case SkinTone.SkinToneB:
        this.#skinConverted = 2;
        break;
      case SkinTone.SkinToneC:
        this.#skinConverted = 3;
        break;
      case SkinTone.SkinToneD:
        this.#skinConverted = 4;
        break;
      case SkinTone.SkinToneE:
        this.#skinConverted = 5;
        break;
      case SkinTone.SkinToneF:
        this.#skinConverted = 6;
        break;
    }
    localStorage.setItem('emoji-mart.skin', this.#skinConverted.toString());
    this.#changeDetectorRef.markForCheck();
  }

  get skin() {
    return this.#skin;
  }

  @Output() readonly skinChange = new EventEmitter<SkinTone>();
  @Output() readonly emojiSelect = new EventEmitter<string>();

  shouldShowEmoji(emojiToCheck: Emoji | string) {
    const unifiedEmoji = typeof emojiToCheck === 'string' ? emojiToCheck : emojiToCheck.unified!;
    const unifiedEmojiCodes = unifiedEmoji.split('-');

    return BlockedEmojis.filter(emoji => unifiedEmojiCodes.includes(emoji.unified!)).length === 0;
  }

  changeSkin(skin: 1 | 2 | 3 | 4 | 5 | 6) {
    switch (skin) {
      case 1:
        this.skinChange.emit(SkinTone.SkinToneA);
        break;
      case 2:
        this.skinChange.emit(SkinTone.SkinToneB);
        break;
      case 3:
        this.skinChange.emit(SkinTone.SkinToneC);
        break;
      case 4:
        this.skinChange.emit(SkinTone.SkinToneD);
        break;
      case 5:
        this.skinChange.emit(SkinTone.SkinToneE);
        break;
      case 6:
        this.skinChange.emit(SkinTone.SkinToneF);
        break;
    }
  }

  emojiPick(value: { emoji: Emoji }) {
    const skinToneValue = value.emoji.skinTone !== 1 && value.emoji.skinTone !== undefined ? `_st${value.emoji.skinTone}` : '';
    const newEmoji = ` :${value.emoji.shortName}${skinToneValue}: `;
    this.emojiSelect.emit(newEmoji);
  }

}
