import { Age, AgeResult, ComprehensionForm, ComprehensionTest } from '../models';
import { convertNumberToAge } from './convert-number-to-age';

export function determineAgeComprehensionTest(score: number | null, formName: ComprehensionForm | null,
  comprehensionTests: readonly ComprehensionTest[]): { age: Age | undefined, result: AgeResult } | undefined {

  if (score !== null && formName) {
    const comprehensionTest = comprehensionTests.find(r => r.score === score && r.form === formName);
    if (comprehensionTest) {
      let age: Age | undefined;
      if (comprehensionTest.age) {
        age = convertNumberToAge(comprehensionTest.age);
      }
      return { age: age, result: comprehensionTest.ageResult };
    }
  }

  return undefined;
}
