import { Injectable } from '@angular/core';
import { HttpWithAbsoluteService } from 'service-lib';

import { SessionCaption, SessionLessonChatInfoFull } from '../models';

export enum Sound {
  Help = 'request-help',
  MessageTutor = 'message-tutor',
  StudentLeave = 'disconnected',
  StudentEnter = 'connected',
  StudentVideoDenied = 'access-denied',
  StudentVideoFailure = 'access-failure',
  StudentStoppedInteracting = 'stopped-interaction',
  StudentStartedInteracting = 'started-interaction',
  StudentStoppedLookingTab = 'stopped-looking-tab',
  StudentStartedLookingTab = 'started-looking-tab',
  StudentCompletedActivity = 'finished-activity',
  StudentWarningTimeReached = 'student-warning-time',
  StudentMaxTimeReached = 'student-max-time',
  DropInAccessRequest = 'drop-in-access-request'
}

@Injectable({ providedIn: 'root' })
export class SoundService extends HttpWithAbsoluteService {

  getSessionCaptions(sessionId: number, warningsOnly: boolean) {
    return this.get<SessionCaption[]>(`tutor/sessions/captions/${sessionId}?warningsOnly=${warningsOnly}`);
  }

  getSessionChats(sessionId: number, warningsOnly: boolean) {
    return this.get<SessionLessonChatInfoFull[]>(`tutor/sessions/chats/${sessionId}?warningsOnly=${warningsOnly}`);
  }

  play(sound: Sound) {
    const audio = new Audio();
    audio.src = `assets/sounds/${sound}.mp3`;
    audio.play();
  }

  getSoundFile(file: string) {
    return this.getSoundCached(file);
  }
}
