import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { determineOptional, FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-long-text',
  templateUrl: './form-control-long-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlLongTextComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<string>>();
  readonly maxLength = input<number | null>(null);
  readonly labelExtra = input('');
  readonly rows = input(3);
  readonly disabled = input(false);

  get optional() {
    return determineOptional(this.control());
  }

}
