import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AnswerType, IndexedOption, QuestionFillInTheBlank, QuestionParametersFillInTheBlank, ValidationResult } from '../../models';
import { QuestionLayout } from '../question-layout';

interface PositionInfo {
  x: number;
  y: number;
}

interface OptionInfo {
  option: DraggableOption;
  element: HTMLElement;
  rect: DOMRect;
}

interface MatchInfo {
  source: DraggableOption;
  target: DraggableOption;
}

interface DraggableOption extends IndexedOption {
  isDraggable: boolean;
  isMatched: boolean;
}

enum DragMode {
  FromSource = 'FromSource',
  FromTarget = 'FromTarget',
  SourceToTarget = 'SourceToTarget',
  TargetToSource = 'TargetToSource',
  TargetToTarget = 'TargetToTarget'
}

@Component({
  selector: 'kip-question-fill-in-the-blank',
  templateUrl: './fill-in-the-blank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FillInTheBlankComponent extends QuestionLayout {

  #source: OptionInfo | undefined;
  #target: OptionInfo | undefined;
  #target2: OptionInfo | undefined;
  #mode: DragMode | undefined;
  #dragMode: DragMode | undefined;
  #draggingElement: number | string | undefined;
  #currentX: number | undefined;
  #currentY: number | undefined;
  #sourceOptions: DraggableOption[] = [];
  #targetOptions: DraggableOption[] = [];
  readonly #correctAnswers: AnswerType[] = [];
  readonly #destroyers: (() => void)[] = [];
  readonly #matchs: (MatchInfo | undefined)[] = [];
  targetElements: HTMLElement[] = [];

  get sourceElements(): HTMLElement[] | undefined {
    return this.sources ? this.sources.map(v => v.nativeElement) : undefined;
  }

  get answers(): AnswerType[] {
    return this.#targetOptions.map(s => s.value === undefined ? '' : s.value);
  }

  override set validationResults(validationResults: ValidationResult[]) {
    this._validationResults = validationResults;
    this.#updateTargetContainerUI();
  }

  override get validationResults() {
    return this._validationResults;
  }

  get sourceOptions(): DraggableOption[] {
    return this.#sourceOptions.filter(o => !o.isMatched);
  }

  get targetOptions(): DraggableOption[] {
    return this.#targetOptions;
  }

  get currentX(): any {
    return this.#currentX;
  }

  get currentY(): any {
    return this.#currentY;
  }

  get draggingElement() {
    return this.#draggingElement;
  }

  override question: QuestionFillInTheBlank | undefined;

  targetHTML: SafeHtml | undefined;

  @ViewChild('container', { static: true }) containerElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('sourceContainer', { static: true }) sourceContainerElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('mirror', { static: false }) mirrorElement: ElementRef<HTMLDivElement> | undefined;

  @ViewChildren('source') sources: QueryList<ElementRef<HTMLDivElement>> | undefined;

  constructor(private readonly renderer: Renderer2, private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer) {
    super();
  }

  sourceMatched(option: DraggableOption): boolean {
    if (this.#source) {
      return this.#matched(option, this.#source, match => match.source);
    }

    return false;
  }

  override initialize() {
    // Parse question into correct format
    this.#formatQuestion();

    // In readonly mode ensure the events are not bound
    if (!this.readonly) {

      if (this.containerElement) {
        // Add the event handlers to start a match
        this.#listen(this.containerElement.nativeElement, 'mousedown', (event: MouseEvent) => {
          this.#startMatch(event.target as HTMLElement);
          this.#setCurrentPosition(event);
        });

        this.#listen(this.containerElement.nativeElement, 'touchstart', (event: TouchEvent) => {
          if (event.changedTouches.length > 0) {
            const touch = event.changedTouches[0];
            this.#startMatch(touch.target as HTMLElement);
            this.#setCurrentPosition(touch);
          }
        });

        this.#listen(this.containerElement.nativeElement, 'touchmove', (event: TouchEvent) => {
          if (event.changedTouches.length > 0) {
            const touch = event.changedTouches[0];

            this.#setCurrentPosition(touch);
            this.#progressMatch();
            this.changeDetectorRef.markForCheck();
          }
          if (event?.preventDefault && this.draggingElement) { // not to fail unit tests
            event.preventDefault(); // to fix the issue of mirror element not following touch point in iPad
          }
        });
      }

      this.#listen(document, 'mousemove', (event: MouseEvent) => {
        this.#setCurrentPosition(event);
        this.#progressMatch();
        this.changeDetectorRef.markForCheck();
      });

      // Add the event handler to end a match
      this.#listen(document, 'mouseup', () => {
        this.#endMatch();
      });

      this.#listen(document, 'touchend', () => {
        this.#endMatch();
      });
    }
  }

  override destroy() {
    if (!this.readonly) {
      for (const destroy of this.#destroyers) {
        destroy();
      }
    }
  }

  readonly #overlapComparerFunc = (target1: HTMLElement, target2: HTMLElement) => {
    const rect1 = target1.getBoundingClientRect();
    const rect2 = target2.getBoundingClientRect();

    return !(rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom);
  };

  #formatQuestion() {

    // Build the parameters for display
    const parameters: QuestionParametersFillInTheBlank = this.question?.parameters ?? {
      order: [],
      target: ''
    };

    // order source options according to the given order
    const order = parameters.order;
    const sourceParameters: string[] = [];
    const answers = this.question?.answers;
    if (answers) {
      for (const [index, answer] of answers.entries()) {
        sourceParameters[index] = answer.values[0].toString();
        this.#correctAnswers[index] = answer.values[0];
      }
    }
    this.#reorder(sourceParameters, order);
    this.targetHTML = this.domSanitizer.bypassSecurityTrustHtml(parameters.target);
    this.changeDetectorRef.detectChanges();

    const targetParameters: HTMLElement[] = [];

    if (this.containerElement) {
      const targetNodes = this.containerElement.nativeElement.querySelectorAll('answer');
      this.targetElements = [...targetNodes] as HTMLElement[];

      for (const [index, item] of this.targetElements.entries()) {
        targetParameters[index] = item;
      }
    }

    this.#sourceOptions = this.#buildSourceOptions(sourceParameters);
    this.#targetOptions = this.#buildTargetOptions(targetParameters);
    this.#updateTargetContainerUI();
    this.changeDetectorRef.markForCheck();
  }

  #updateTargetContainerUI() {
    let cssClass = '';
    if (this.targetElements) {
      for (const [index, item] of this.targetElements.entries()) {
        if (item.hasChildNodes()) {
          item.innerHTML = '';
        }
        // Get the respective target option
        const targetOption = this.targetOptions[index];
        const div = this.renderer.createElement('div') as HTMLDivElement;
        const alertDanger = 'alert-danger';
        const alertPrimary = 'alert-primary';
        const alertSuccess = 'alert-success';

        this.renderer.addClass(div, 'kip-target');
        this.renderer.addClass(div, alertPrimary);
        this.renderer.addClass(div, 'rounded');
        if (this._validationResults && this._validationResults.length > index) {
          switch (this._validationResults[index]) {
            case ValidationResult.Incorrect:
              this.renderer.removeClass(div, alertPrimary);
              this.renderer.addClass(div, alertDanger);
              break;
            case ValidationResult.Correct:
              this.renderer.removeClass(div, alertPrimary);
              this.renderer.addClass(div, alertSuccess);
              break;
            case ValidationResult.NotKnown:
              // do nothing
              break;
          }
        }
        // Update the target elements
        if (targetOption.isMatched) {
          div.innerHTML = targetOption.value.toString();
          this.renderer.addClass(div, 'kip-target-item');
        } else {
          if (this.readonly) {
            this.renderer.removeClass(div, alertPrimary);
            if (this.studentAnswers === undefined) {
              div.innerHTML = this.#correctAnswers[index].toString();
              cssClass = alertSuccess;
            } else {
              div.innerHTML = this.studentAnswers[index].toString();
              cssClass = this.studentAnswers[index] === this.#correctAnswers[index] ? alertSuccess : alertDanger;
            }
            this.renderer.addClass(div, cssClass);
            this.renderer.removeClass(div, 'kip-target');
            this.renderer.addClass(div, 'kip-placeholder-tutor');
          } else {
            this.renderer.addClass(div, 'kip-placeholder');
          }
        }
        this.renderer.appendChild(item, div);
      }

    }
  }

  #setCurrentPosition(event: MouseEvent | Touch) {
    if (event) {
      const position = this.#resolvePosition(event.clientX, event.clientY);

      this.#currentX = position.x;
      this.#currentY = position.y;
    }
  }

  #buildSourceOptions(options: string[]): DraggableOption[] {
    return options.map((option, index) => ({
      index: index,
      text: option,
      image: undefined,
      value: '',
      isDraggable: false,
      isMatched: false
    }));
  }

  #buildTargetOptions(options: HTMLElement[]): DraggableOption[] {
    return options.map((_option, index) => ({
      index: index,
      text: undefined,
      image: undefined,
      value: '',
      isDraggable: true,
      isMatched: false
    }));
  }

  #matched(option: DraggableOption, info: OptionInfo, accessor: (match: MatchInfo) => DraggableOption): boolean {

    // If the source has started a match, then activate it
    // Otherwise check if it is matched
    if (info && info.option.index === option.index) {
      return true;
    }

    return !!this.#findMatch(option, accessor);
  }

  #findMatch(option: DraggableOption, accessor: (match: MatchInfo) => DraggableOption): MatchInfo | undefined {
    return this.#matchs.find(match => {

      // If the match is undefined, then don't match it
      // This is if a match hasn't been selected for a source option yet
      if (match) {
        const current = accessor(match);
        return current && current.index === option.index;
      }
      return false;
    });
  }

  #findStartOption(element: HTMLElement, elements: HTMLElement[], options: DraggableOption[]): OptionInfo | undefined {
    return elements
      .map((source, index) => ({
        option: options[index],
        element: source,
        rect: source.getBoundingClientRect()
      }))
      .find(info => info.element === element || info.element.contains(element));
  }

  #findOption(element: HTMLElement, elements: HTMLElement[], options: DraggableOption[]): OptionInfo | undefined {
    return elements
      .map((source, index) => ({
        option: options[index],
        element: source,
        rect: source.getBoundingClientRect()
      }))
      .find(_option => this.#overlapComparerFunc(_option.element, element));
  }

  #listen(element: Document | HTMLElement, event: string, handler: (event: Event) => void) {
    const destroy = this.renderer.listen(element, event, handler);
    this.#destroyers.push(destroy);
  }

  #startMatch(element: HTMLElement) {
    // if partially matches are there reset variables
    this.#clearVariables();

    let infoSource: OptionInfo | undefined;

    // Get the option info for the element that triggered the event
    if (this.sourceElements) {
      infoSource = this.#findStartOption(element, this.sourceElements, this.sourceOptions);
    }

    // if it is from source
    if (infoSource) {

      // Track the start source option
      this.#source = infoSource;
      this.#mode = DragMode.FromSource;
      this.#draggingElement = infoSource.option.text;

    } else {

      const infoTarget = this.#findStartOption(element, this.targetElements, this.targetOptions);
      // if it is from target
      if (infoTarget) {

        // Track the start target option, which activates the connector rendering
        this.#target = infoTarget;
        this.#mode = DragMode.FromTarget;
        this.#draggingElement = infoTarget.option.value;
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  #endMatch() {

    this.#draggingElement = undefined;

    // We are only interested in ending a match if dragMode is set that means we have active targets or source
    if (this.#dragMode && this.#target && this.#target.option.isDraggable) {
      if (this.#dragMode === DragMode.SourceToTarget && this.#source) { // find and clear existing match then create new match

        this.#clearExistingMatchForTarget();

        // set target option replaced text as source option text
        this.#target.option.value = this.#source.option.text ?? '';
        this.#target.option.isMatched = true;

        // set source option isMatched to true
        this.#source.option.isMatched = true;

        // Set the matching state for the current source and target selection

        if (this._validationResults && this._validationResults.length > this.#target.option.index) {
          this._validationResults[this.#target.option.index] = ValidationResult.NotKnown;
        }

        this.#matchs[this.#source.option.index] = {
          source: this.#source.option,
          target: this.#target.option
        };
      } else if (this.#dragMode === DragMode.TargetToSource) { // find and clear existing match if there is one

        this.#clearExistingMatchForTarget();

        // reset target option replaced text as source option text
        this.#target.option.value = '';
        this.#target.option.isMatched = false;

      } else if (this.#dragMode === DragMode.TargetToTarget && this.#target2 && // target should be matched and target2 should be draggable
        this.#target.option.isMatched && this.#target2.option.isDraggable) {

        const fromExistingMatch = this.#findMatch(this.#target.option, match => match.target);
        const toExistingMatch = this.#findMatch(this.#target2.option, match => match.target);

        // both targets have been matched, swap the matches
        if (fromExistingMatch && toExistingMatch) {

          // reset target option replaced text as source option text
          this.#target.option.value = toExistingMatch.source.text ?? '';
          this.#target.option.isMatched = true;
          this.#target2.option.value = fromExistingMatch.source.text ?? '';
          this.#target2.option.isMatched = true;

          if (this._validationResults && this._validationResults.length > this.#target2.option.index) {
            this._validationResults[this.#target2.option.index] = ValidationResult.NotKnown;
          }

          // swap the targets
          this.#matchs[fromExistingMatch.source.index] = {
            source: fromExistingMatch.source,
            target: this.#target2.option
          };

          if (this._validationResults && this._validationResults.length > this.#target.option.index) {
            this._validationResults[this.#target.option.index] = ValidationResult.NotKnown;
          }

          this.#matchs[toExistingMatch.source.index] = {
            source: toExistingMatch.source,
            target: this.#target.option
          };
        } else if (fromExistingMatch) {// if target2 is not yet matched remove the old match and create a new match

          // reset target option replaced text as source option text
          this.#target.option.value = '';
          this.#target.option.isMatched = false;

          this.#target2.option.value = fromExistingMatch.source.text ?? '';
          this.#target2.option.isMatched = true;

          if (this._validationResults && this._validationResults.length > this.#target2.option.index) {
            this._validationResults[this.#target2.option.index] = ValidationResult.NotKnown;
          }

          // swap the targets
          this.#matchs[fromExistingMatch.source.index] = {
            source: fromExistingMatch.source,
            target: this.#target2.option
          };
        }
      }
      this.#updateTargetContainerUI();
      this.sendUpdates();
    }

    this.changeDetectorRef.markForCheck();
    this.#clearVariables();
  }

  #clearExistingMatchForTarget() {
    if (this.#target) {
      const existing = this.#findMatch(this.#target.option, match => match.target);
      if (existing) {
        const infoSource = existing.source;
        // clear existing match
        this.#matchs[existing.source.index] = undefined;
        infoSource.isMatched = false;
      }
    }
  }

  #progressMatch() {

    // If there is a source element active, then calculate the mouse position
    if (this.#mode === DragMode.FromSource && this.mirrorElement) {
      // Get the target matching the provided element
      const infoTarget = this.#findOption(this.mirrorElement.nativeElement, this.targetElements, this.targetOptions);

      if (infoTarget) {
        this.#target = this.#findOption(this.mirrorElement.nativeElement, this.targetElements, this.targetOptions);
        this.#dragMode = DragMode.SourceToTarget;
      } else {
        this.#target = undefined;
        this.#dragMode = undefined;
      }

    } else if (this.#mode === DragMode.FromTarget && // Two possibilities target to source or target to target

      this.sourceContainerElement && this.mirrorElement) {
      // check whether if it is source container when dragging target to source
      if (this.#overlapComparerFunc(this.sourceContainerElement.nativeElement, this.mirrorElement.nativeElement)) {
        this.#dragMode = DragMode.TargetToSource;
      } else {

        // Get the target matching the provided element
        const infoTarget = this.#findOption(this.mirrorElement.nativeElement, this.targetElements, this.targetOptions);
        if (infoTarget) {
          this.#target2 = infoTarget;
          this.#dragMode = DragMode.TargetToTarget;
        } else {
          this.#target2 = undefined;
          this.#dragMode = undefined;
        }

      }
    }
  }

  #resolvePosition(x: number, y: number): PositionInfo {

    // Get the container boundaries to calculate the required position info

    if (this.containerElement) {
      const rect: DOMRect = this.containerElement.nativeElement.getBoundingClientRect();
      return {
        x: x - rect.left,
        y: y - rect.top
      };
    }
    return { x: 0, y: 0 };
  }

  #clearVariables() {
    this.#source = undefined;
    this.#target = undefined;
    this.#target2 = undefined;
    this.#dragMode = undefined;
    this.#mode = undefined;
  }

  // to reorder elements of arr[] according to index[]
  #reorder(arr: AnswerType[], index: readonly number[]) {
    const temp: AnswerType[] = [];
    const len = arr.length;

    // arr[i] should be present at index[i] index
    for (let i = 0; i < len; i++) {
      temp[i] = arr[index[i]];
    }

    // Copy temp[] to arr[]
    for (let i = 0; i < len; i++) {
      arr[i] = temp[i];
    }
  }
}
