import { WhiteboardColorName } from './whiteboard-color-name';

export class WhiteboardColor {
  selected = false;

  constructor(
    readonly name: WhiteboardColorName,
    readonly definition: string,
    readonly highlightDefinition: string) { }
}
