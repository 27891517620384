import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { AccountContactComponent } from './account-detail/account-detail-tabs/account-contact/account-contact.component';
import { AccountContactDeleteComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-delete/account-contact-delete.component';
import { AccountContactEditComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-edit/account-contact-edit.component';
import { AccountContactEditLoginComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-edit-login/account-contact-edit-login.component';
import { AccountContactLoginComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-edit-login/account-contact-login-modal/account-contact-login.component';
import { AccountContactLoginModalComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-edit-login/account-contact-login-modal/account-contact-login-modal.component';
import { AccountContactEditPasswordComponent } from './account-detail/account-detail-tabs/account-contact/account-contact-edit-password/account-contact-edit-password.component';

@NgModule({
  declarations: [
    AccountContactComponent,
    AccountContactDeleteComponent,
    AccountContactEditComponent,
    AccountContactEditLoginComponent,
    AccountContactEditPasswordComponent,
    AccountContactLoginComponent,
    AccountContactLoginModalComponent
  ],
  imports: [
    CommonModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    AccountContactComponent,
    AccountContactDeleteComponent,
    AccountContactEditComponent,
    AccountContactEditLoginComponent,
    AccountContactEditPasswordComponent,
    AccountContactLoginComponent,
    AccountContactLoginModalComponent
  ]
})
export class AccountContactLibModule { }
