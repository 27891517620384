import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { AwardGroupType, OrganisationAward } from '../../models';
import { AwardsService } from '../../services';

@Component({
  selector: 'kip-awards-group-organisation',
  templateUrl: './awards-group-organisation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwardsGroupOrganisationComponent implements OnInit, OnDestroy {

  readonly #awardsService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #organisationAwards: OrganisationAward[] = [];
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get organisationAwards() {
    return this.#organisationAwards;
  }

  @Input({ required: true }) collapse = false;

  @Output()
  readonly awardPicked = new EventEmitter<OrganisationAward>();

  @Output()
  readonly toggled = new EventEmitter<AwardGroupType | undefined>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#awardsService.getOrganisationAwards().subscribe(organisationAwards => {
        this.#organisationAwards = organisationAwards;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggle() {
    if (!this.collapse) {
      this.toggled.emit(AwardGroupType.Organisation);
    } else {
      this.collapse = false;
      this.toggled.emit(undefined);
    }
  }
}
