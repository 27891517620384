import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-crown-king-queen',
  templateUrl: './crown-king-queen.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCrownKingQueenComponent implements IconComponent {

}
