import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-terms',
  templateUrl: './form-control-terms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlTermsComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | null | undefined>>();
  readonly allowNone = input(true);

}
