import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-strong-muscles-e',
  templateUrl: './strong-muscles-e.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconStrongMusclesEComponent implements IconComponent {

}
