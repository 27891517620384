import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { JiraRequestType, JiraStyleType } from '../models';
import { JiraComponent } from './jira.component';

@Component({
  selector: 'kip-jira-modal',
  templateUrl: './jira-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JiraModalComponent implements OnDestroy {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;
  readonly jiraStyleTypeDropdownItem = JiraStyleType.DropdownItem;
  readonly jiraStyleTypeContent = JiraStyleType.Content;
  readonly jiraStyleTypeReport = JiraStyleType.Report;

  @Input() jiraStyleType: JiraStyleType = JiraStyleType.DropdownItem;

  /* eslint-disable kip/no-unused-public-members */

  @Input() uId: string | undefined;
  @Input() metaData: { [prop: string]: string } = {};
  @Input() requestText = 'Report Issue';
  @Input() requestType: JiraRequestType = JiraRequestType.Bug;
  @Input() dropdownMenu = false;
  @Input() initialDescription = '';

  /* eslint-enable kip/no-unused-public-members */

  ngOnDestroy() {
    this.#modalService.dismissAll();
  }

  open() {

    // This is the only way I could get drag and drop to work
    // The element ref needs to be created by opening the modal first

    const modalRef = this.#modalService.open(JiraComponent, { centered: true });

    const componentInstance = modalRef.componentInstance as JiraComponent;
    componentInstance.uId = this.uId ?? '';
    componentInstance.metaData = this.metaData;
    componentInstance.requestText = this.requestText;
    componentInstance.requestType = this.requestType;
    componentInstance.jiraRequestTypeVisible = this.jiraStyleType !== JiraStyleType.Content;
    componentInstance.initialDescription = this.initialDescription;
  }
}
