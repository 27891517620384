<header class="kip-header mb-3" [ngClass]="{'kip-header--no-breadcrumbs': breadcrumbs().length === 0}">
  @if (breadcrumbs().length > 0)
  {
  <kip-breadcrumbs [breadcrumbs]="breadcrumbs()" />
  }
  <h1 class="fw-light">
    @if (icon(); as icon)
    {
    <fa-icon [icon]="icon" />
    }
    {{ title() }}
  </h1>
</header>
<main class="col-12 px-3">
  <ng-template #content><ng-content /></ng-template>
  @if (multipleSection())
  {
  <ng-container *ngTemplateOutlet="content" />
  }
  @else {
  <section class="kip-card kip-card--summary mb-3">
    <ng-container *ngTemplateOutlet="content" />
  </section>
  }
</main>