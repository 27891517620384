export enum AnswerValidatorType {
  Equality = 1,
  Maths = 2,
  Paragraph = 3,
  WordPicker = 4,
  EqualityUnordered = 5,
  MathsUnordered = 6,
  TimeMinutesHours = 7,
  Money = 8,
  Number = 9,
  //  MoneySouthAfrican = 10, redundant
  Units = 11,
  Fractions = 12,
  Algebra = 13
}
