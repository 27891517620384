export enum Icon {
  TradeId = 0,
  AbacusMaths = 1,
  Alphabet = 2,
  BooksReading = 3,
  ExpertAddition = 4,
  ExpertDivision = 5,
  ExpertMultiplication = 6,
  ExpertSubtraction = 7,
  GraphBarChart = 8,
  GraphLineChart = 9,
  LearningMathsFemale = 10,
  LearningMathsMale = 11,
  Numeracy = 12,
  ReadingBook = 13,
  RulerMeasurement = 14,
  Time = 15,
  TriangleMeasurement = 16,
  WritingHand = 17,
  BrainSmart = 18,
  CheckCorrect = 19,
  ClapHands = 20,
  CrownKingQueen = 21,
  DiamondJewel = 22,
  LightBulbBright = 23,
  Medal1stFirst = 24,
  Medal2ndSecond = 25,
  Medal3rdThird = 26,
  MedalAward = 27,
  MedalBadge = 28,
  StarGold = 29,
  ThumbsUp = 30,
  TrophyAward = 31,
  UnlockedLevel = 32,
  BirthdayBalloon = 33,
  BirthdayCake = 34,
  BirthdayRibbon = 35,
  CelebrateParty = 36,
  ChristmasTree = 37,
  GraduatedBoy = 38,
  GraduatedGirl = 39,
  GraduationAcademic = 40,
  PresentGift = 41,
  SantaClaus = 42,
  Halloween = 43,
  Snowman = 44,
  BlastOff = 45,
  BullseyeTarget = 46,
  ClimbingSkill = 47,
  ComputerWhizBoy = 48,
  ComputerWhizGirl = 49,
  ElectricLightning = 50,
  ExplosiveBang = 51,
  ExplosiveBomb = 52,
  ExplosiveTnt = 53,
  FinishFlag = 54,
  FireForce = 55,
  GraphWhizBoy = 56,
  GraphWhizGirl = 57,
  HomeworkMaster = 58,
  MagicWand = 59,
  OneHundred = 60,
  RacecarSpeed = 61,
  RunningSpeedBoy = 62,
  RunningSpeedGirl = 63,
  ScienceWhizBoy = 64,
  ScienceWhizGirl = 65,
  SpaceCadetBoy = 66,
  SpaceCadetGirl = 67,
  StopwatchTimer = 68,
  StrongMuscles = 69,
  SuperheroPower = 70,
  WizardMagic = 71,
  StarSilver = 72,
  StarBronze = 73,
  RubyTrophy = 74,
  PlatinumTrophy = 75,
  DiamondTrophy = 76,
  PointsBlue = 77,
  DiySet11 = 78,
  DiySet12 = 79,
  DiySet13 = 80,
  DiySet14 = 81,
  DiySet21 = 82,
  DiySet22 = 83,
  DiySet23 = 84,
  DiySet24 = 85,
  DiySet31 = 86,
  DiySet32 = 87,
  DiySet33 = 88,
  DiySet34 = 89
}
