import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileService, Role, StudentSettings, Theme } from 'ui-common-lib';

@Component({
  selector: 'kip-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #currentTheme = '';
  #subscriptions: Subscription[] = [];
  #isStudent = false;

  readonly theme = Theme;

  get currentTheme() {
    return this.#currentTheme;
  }

  get isStudent(): boolean {
    return this.#isStudent;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#profileService
        .trackUserProfile()
        .subscribe(profile => {
          this.#currentTheme = profile ? (profile.settings as StudentSettings).theme ?? '' : '';
          this.#isStudent = profile ? profile.roles.includes(Role.Student) : false;
          this.#changeDetectorRef.markForCheck();
        }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  changeTheme(theme: Theme) {
    this.#subscriptions.push(
      this.#profileService.changeTheme(theme).subscribe(() => {
        // do nothing
      }));
  }

}
