@if (studentNote) {
<article class="kip-note kip-note--pinned">
  <header class="d-flex align-items-center mb-3">
    <div class="kip-note__icon kip-note__icon--pinned text-primary me-2">
      <fa-icon [fixedWidth]="true" [icon]="studentNote.note.noteCategory.name | noteCategoryIcon" />
    </div>
    <h2 class="fw-light">{{ studentNote.createdOn | dateTime }}</h2>
  </header>
  <div class="d-flex mb-3">
    <span class="kip-note__category">{{ studentNote.note.noteCategory.name }}</span>
  </div>
  <div class="d-flex flex-column">
    <div class="kip-info">
      <div class="kip-info__entry mb-0">
        <kip-emoji-message [message]="studentNote.note.description" />
      </div>
    </div>
  </div>
  <div class="mt-auto d-flex">
    <div class="ms-auto kip-note__user">
      <fa-icon class="me-2" [icon]="icons.tutor.solid" />
      <span>{{ studentNote.createdByUser }}</span>
    </div>
  </div>
</article>
}