import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Button, ProfileService } from 'ui-common-lib';

import { StudentGoal } from '../../../models';
import { StudentService } from '../../../services';
import { StudentGoalHistoryComponent } from './student-goal-history.component';

@Component({
  selector: 'kip-student-goal',
  templateUrl: './student-goal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentGoalComponent implements OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #studentService = inject(StudentService);

  #buttons: Button[] = [];
  #studentId: number | undefined;
  #editing = false;
  #studentGoals: StudentGoal[] = [];
  #studentGoalToEdit: StudentGoal | undefined;
  #canEdit = false;
  #subscriptions: Subscription[] = [];
  historyEnabled = false;

  get buttons() {
    return this.#buttons;
  }

  get canEdit() {
    return this.#canEdit;
  }

  get studentGoals() {
    return this.#studentGoals;
  }

  get studentGoalToEdit() {
    return this.#studentGoalToEdit;
  }

  get hasResults() {
    return this.#studentGoals && this.#studentGoals.length > 0;
  }

  get editing() {
    return this.#editing;
  }

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#init();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @ViewChild(StudentGoalHistoryComponent)
  readonly studentGoalHistoryComponent: StudentGoalHistoryComponent | undefined;

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  add() {
    this.#editing = true;
  }

  edit(studentGoal: StudentGoal) {
    if (this.canEdit) {
      this.#editing = true;
      this.#studentGoalToEdit = studentGoal;
    }
  }

  cancelled() {
    this.#studentGoalToEdit = undefined;
    this.#editing = false;
  }

  submitted() {
    this.#init();
  }

  goalCompleted() {
    if (this.studentGoalHistoryComponent) {
      this.studentGoalHistoryComponent.load();
    }
  }

  #init() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        const buttons: Button[] = [];
        if (value) {
          buttons.push({ text: 'Set a Goal', action: () => { this.add(); } });
        }
        this.#buttons = buttons;
        this.#changeDetectorRef.markForCheck();
      }));
    this.#studentGoalToEdit = undefined;
    this.#editing = false;
    if (this.studentId) {
      this.#subscriptions.push(
        this.#studentService.getGoalsByStudentId(this.studentId).subscribe(
          goals => {
            this.#studentGoals = goals;
            this.#changeDetectorRef.markForCheck();
          }));
    } else {
      this.#studentGoals = [];
    }
  }
}
