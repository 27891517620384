import { ChangeDetectionStrategy, Component } from '@angular/core';

import { WhiteboardIconBase } from './whiteboard-icon-base';

@Component({
  selector: 'kip-whiteboard-icon-text',
  templateUrl: './text.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardIconTextComponent extends WhiteboardIconBase {

}
