import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { AwardData } from '../models';

@Injectable({ providedIn: 'root' })
export class PointService extends HttpService {

  getLessonPoints(lessonId: number): Observable<AwardData> {
    return this.get<AwardData>(`student/points/${lessonId}`);
  }

  getTotalPoints(): Observable<AwardData> {
    return this.get<AwardData>('student/points/total');
  }
}
