import { ChangeDetectionStrategy, Component } from '@angular/core';

import { WhiteboardIconBase } from './whiteboard-icon-base';

@Component({
  selector: 'kip-whiteboard-icon-grid',
  templateUrl: './grid.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardIconGridComponent extends WhiteboardIconBase {

}
