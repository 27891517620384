export enum AssociationType {
  Initial = 1,
  Subsequent = 2,
  Repeat = 3,
  Manual = 4,
  Linked = 5,
  SkillBuilder = 6,
  AdHoc = 7,
  SubsequentCheck = 8,
  Decision = 9,
  SubsequentAccelerated = 10,
  InProgress = 11
}
