/* eslint-disable sonarjs/no-duplicate-string */

import { Region, RegionId } from '../../models';

export const regions: Region[] =
  [
    {
      id: RegionId.Australia,
      countryCode: 'au',
      flag: '/assets/svg/regions/au.svg',
      name: 'Australia',
      locale: 'en-AU',
      currency: 'AUD',
      selected: false,
      validSuffixes: ['c', 'cents', '¢'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Dollar',
        primaryCurrencyWordSingularLower: 'dollar',
        primaryCurrencyWordPluralUpper: 'Dollars',
        primaryCurrencyWordPluralLower: 'dollars',
        primaryCurrencySymbol: '$',
        decimalSymbol: '.',
        secondaryCurrencyWordSingularUpper: 'Cent',
        secondaryCurrencyWordSingularLower: 'cent',
        secondaryCurrencyWordPluralUpper: 'Cents',
        secondaryCurrencyWordPluralLower: 'cents',
        secondaryCurrencySymbol: 'c',
        taxName: 'GST',
        fullStop: 'full stop',
        pointOrComma: 'point',
        highest: 'Highest',
        lowest: 'Lowest',
        notes: 'notes',
        commaOrSemicolon: ',',
        coin001: '',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_5.png',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_10.png',
        coin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_20.png',
        coin025: '',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_50.png',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_100.png',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_200.png',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_5.png',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_10.png',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_20.png',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_50.png',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_100.png',
        oCoin001: '',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_5.png',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_10.png',
        oCoin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_20.png',
        oCoin025: '',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_50.png',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_100.png',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/coin_200.png',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_5.png',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_10.png',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_20.png',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_50.png',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_1/educator/maths/money/note_100.png'
      }
    },
    {
      id: RegionId.NewZealand,
      countryCode: 'nz',
      flag: '/assets/svg/regions/nz.svg',
      name: 'New Zealand',
      locale: 'en-NZ',
      currency: 'NZD',
      selected: false,
      validSuffixes: ['c', 'cents', '¢'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Dollar',
        primaryCurrencyWordSingularLower: 'dollar',
        primaryCurrencyWordPluralUpper: 'Dollars',
        primaryCurrencyWordPluralLower: 'dollars',
        primaryCurrencySymbol: '$',
        decimalSymbol: '.',
        secondaryCurrencyWordSingularUpper: 'Cent',
        secondaryCurrencyWordSingularLower: 'cent',
        secondaryCurrencyWordPluralUpper: 'Cents',
        secondaryCurrencyWordPluralLower: 'cents',
        secondaryCurrencySymbol: 'c',
        taxName: 'GST',
        highest: 'Highest',
        lowest: 'Lowest',
        notes: 'notes',
        fullStop: 'full stop',
        pointOrComma: 'point',
        commaOrSemicolon: ',',
        coin001: '',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_5.png',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_10.png',
        coin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_20.png',
        coin025: '',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_50.png',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_100.png',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_200.png',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_5.png',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_10.png',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_20.png',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_50.png',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_100.png',
        oCoin001: '',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_5.png',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_10.png',
        oCoin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_20.png',
        oCoin025: '',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_50.png',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_100.png',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/coin_200.png',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_5.png',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_10.png',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_20.png',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_50.png',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_2/educator/maths/money/note_100.png'
      }
    },
    {
      id: RegionId.UnitedKingdom,
      countryCode: 'uk',
      flag: '/assets/svg/regions/gb.svg',
      name: 'United Kingdom',
      locale: 'en-GB',
      currency: 'GBP',
      selected: false,
      validSuffixes: ['p', 'pence'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Pound',
        primaryCurrencyWordSingularLower: 'pound',
        primaryCurrencyWordPluralUpper: 'Pounds',
        primaryCurrencyWordPluralLower: 'pounds',
        primaryCurrencySymbol: '£',
        decimalSymbol: '.',
        secondaryCurrencyWordSingularUpper: 'Pence',
        secondaryCurrencyWordSingularLower: 'pence',
        secondaryCurrencyWordPluralUpper: 'Pence',
        secondaryCurrencyWordPluralLower: 'pence',
        secondaryCurrencySymbol: 'p',
        taxName: 'VAT',
        highest: 'Highest',
        lowest: 'Lowest',
        notes: 'notes',
        fullStop: 'full stop',
        pointOrComma: 'point',
        commaOrSemicolon: ',',
        coin001: '',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_5.png',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_10.png',
        coin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_20.png',
        coin025: '',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_50.png',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_100.png',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_200.png',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_5.png',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_10.png',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_20.png',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_50.png',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_100.png',
        oCoin001: '',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_5.png',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_10.png',
        oCoin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_20.png',
        oCoin025: '',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_50.png',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_100.png',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_200.png',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_5.png',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_10.png',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_20.png',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_50.png',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_100.png'
      }
    },
    {
      id: RegionId.UnitedArabEmirates,
      countryCode: 'ae',
      flag: '/assets/svg/regions/ae.svg',
      name: 'United Arab Emirates',
      locale: 'en-GB',
      currency: 'GBP',
      selected: false,
      validSuffixes: ['p', 'pence'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Pound',
        primaryCurrencyWordSingularLower: 'pound',
        primaryCurrencyWordPluralUpper: 'Pounds',
        primaryCurrencyWordPluralLower: 'pounds',
        primaryCurrencySymbol: '£',
        decimalSymbol: '.',
        secondaryCurrencyWordSingularUpper: 'Pence',
        secondaryCurrencyWordSingularLower: 'pence',
        secondaryCurrencyWordPluralUpper: 'Pence',
        secondaryCurrencyWordPluralLower: 'pence',
        secondaryCurrencySymbol: 'p',
        taxName: 'VAT',
        highest: 'Highest',
        lowest: 'Lowest',
        notes: 'notes',
        fullStop: 'full stop',
        pointOrComma: 'point',
        commaOrSemicolon: ',',
        coin001: '',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_5.png',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_10.png',
        coin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_20.png',
        coin025: '',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_50.png',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_100.png',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_200.png',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_5.png',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_10.png',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_20.png',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_50.png',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_100.png',
        oCoin001: '',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_5.png',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_10.png',
        oCoin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_20.png',
        oCoin025: '',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_50.png',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_100.png',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/coin_200.png',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_5.png',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_10.png',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_20.png',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_50.png',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_3/educator/maths/money/note_100.png'
      }
    },
    {
      id: RegionId.SouthAfrica,
      countryCode: 'sa',
      flag: '/assets/svg/regions/za.svg',
      name: 'South Africa',
      locale: 'en-ZA',
      currency: 'ZAR',
      selected: false,
      validSuffixes: ['c', 'cents', '¢'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Rand',
        primaryCurrencyWordSingularLower: 'rand',
        primaryCurrencyWordPluralUpper: 'Rand',
        primaryCurrencyWordPluralLower: 'rand',
        primaryCurrencySymbol: 'R',
        decimalSymbol: ',',
        secondaryCurrencyWordSingularUpper: 'Cent',
        secondaryCurrencyWordSingularLower: 'cent',
        secondaryCurrencyWordPluralUpper: 'Cents',
        secondaryCurrencyWordPluralLower: 'cents',
        secondaryCurrencySymbol: 'c',
        taxName: 'VAT',
        highest: 'Highest',
        lowest: 'Lowest',
        notes: 'notes',
        fullStop: 'full stop',
        pointOrComma: 'comma',
        commaOrSemicolon: ';',
        coin001: '',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/5c.png',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/10c.png',
        coin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/20c.png',
        coin025: '',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/50c.png',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r1.png',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r2.png',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r5.png',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r10.png',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r20.png',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r50.png',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r100.png',
        oCoin001: '',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/5c.png',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/10c.png',
        oCoin020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/20c.png',
        oCoin025: '',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/50c.png',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r1.png',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r2.png',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r5.png',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r10.png',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r20.png',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r50.png',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_4/educator/maths/money/r100.png'
      }
    },
    {
      id: RegionId.UnitedStates,
      countryCode: 'us',
      flag: '/assets/svg/regions/us.svg',
      name: 'United States',
      locale: 'en-US',
      currency: 'USD',
      selected: false,
      validSuffixes: ['c', 'cents', '¢'],
      currencySwap: {
        primaryCurrencyWordSingularUpper: 'Dollar',
        primaryCurrencyWordSingularLower: 'dollar',
        primaryCurrencyWordPluralUpper: 'Dollars',
        primaryCurrencyWordPluralLower: 'dollars',
        primaryCurrencySymbol: '$',
        decimalSymbol: '.',
        secondaryCurrencyWordSingularUpper: 'Cent',
        secondaryCurrencyWordSingularLower: 'cent',
        secondaryCurrencyWordPluralUpper: 'Cents',
        secondaryCurrencyWordPluralLower: 'cents',
        secondaryCurrencySymbol: '¢',
        taxName: 'GST',
        highest: 'Greatest',
        lowest: 'Least',
        notes: 'bills',
        fullStop: 'period',
        pointOrComma: 'point',
        commaOrSemicolon: ',',
        coin001: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_01.svg',
        coin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_05.svg',
        coin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_10.svg',
        coin020: '',
        coin025: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_25.svg',
        coin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_50.svg',
        coin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_100.svg',
        coin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_200.jpg',
        note005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_5.svg',
        note010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_10.svg',
        note020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_20.svg',
        note050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_50.svg',
        note100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_100.svg',
        oCoin001: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_01_obverse.svg',
        oCoin005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_05_obverse.svg',
        oCoin010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_10_obverse.svg',
        oCoin020: '',
        oCoin025: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_25_obverse.svg',
        oCoin050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_50_obverse.svg',
        oCoin100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_100_obverse.jpg',
        oCoin200: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/coin_200_obverse.jpg',
        oNote005: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_5_obverse.jpg',
        oNote010: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_10_obverse.jpg',
        oNote020: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_20_obverse.jpg',
        oNote050: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_50_obverse.jpg',
        oNote100: 'https://d2f9wo3x2pufsw.cloudfront.net/country_us/note_100_obverse.jpg'
      }
    }
  ];
