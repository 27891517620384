import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { EnrolmentPaymentComponent } from './components/enrolment-payment/enrolment-payment.component';
import { EnrolmentPaymentOptionsComponent } from './components/enrolment-payment-options/enrolment-payment-options.component';
import { EnrolmentPaymentProcessingComponent } from './components/enrolment-payment-processing/enrolment-payment-processing.component';
import { EnrolmentSelectedBundlesComponent } from './components/enrolment-selected-bundles/enrolment-selected-bundles.component';
import { EnrolmentSelectedSessionsComponent } from './components/enrolment-selected-sessions/enrolment-selected-sessions.component';
import { EnrolmentSelectedTutorsComponent } from './components/enrolment-selected-tutors/enrolment-selected-tutors.component';
import { EnrolmentStepsComponent } from './components/enrolment-steps/enrolment-steps.component';
import { EnrolmentSubjectsComponent } from './components/enrolment-subjects/enrolment-subjects.component';
import { EnrolmentViewBundlesComponent } from './components/enrolment-view-bundles/enrolment-view-bundles.component';

@NgModule({
  imports: [
    CommonModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    NgbModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  declarations: [
    EnrolmentPaymentComponent,
    EnrolmentPaymentOptionsComponent,
    EnrolmentPaymentProcessingComponent,
    EnrolmentSelectedBundlesComponent,
    EnrolmentSelectedSessionsComponent,
    EnrolmentSelectedTutorsComponent,
    EnrolmentStepsComponent,
    EnrolmentSubjectsComponent,
    EnrolmentViewBundlesComponent
  ],
  exports: [
    EnrolmentPaymentComponent,
    EnrolmentPaymentOptionsComponent,
    EnrolmentPaymentProcessingComponent,
    EnrolmentSelectedBundlesComponent,
    EnrolmentSelectedSessionsComponent,
    EnrolmentSelectedTutorsComponent,
    EnrolmentStepsComponent,
    EnrolmentSubjectsComponent,
    EnrolmentViewBundlesComponent
  ]
})
export class EnrolmentLibModule { }
