<div>
  <nav class="kip-navbar kip-lesson-navbar">
    <div
      class="kip-navbar-container kip-xl-container d-flex flex-row align-items-center justify-content-between mx-auto py-2 px-3">
      <div class="kip-logo-container d-flex flex-row cursor-pointer">
        <kip-logo />
      </div>
    </div>
  </nav>
  <div class="pt-3 w-100">
    <form class="paymentWidgets" data-brands="VISA MASTER" [action]="redirectUrl"></form>
  </div>
  <div class="mt-3 w-100 text-center text-primary small">
    Need assistance? Call our Assessment Team on 1800 573 153
  </div>
</div>