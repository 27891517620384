/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpService } from 'service-lib';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends HttpService {

  readonly #swPush = inject(SwPush);

  public sendMessage(userIds: number[], title: string, body: string, icon?: string) {
    return this.post<number>('push-subscriptions/send',
      { userIds: userIds, title: title, body: body, icon: icon ?? 'assets/icons/icon-128x128.png' }
    );
  }

  public sendTestMessage() {
    return this.post<number>('push-subscriptions/test',
      {
        title: 'Important Notification',
        body: 'Your child, John Citizen, has a lesson at 3:00 PM today. Please do not be late.',
        icon: 'assets/icons/icon-128x128.png'
      }
    );
  }

  public subscribe() {
    // Retrieve public VAPID key from the server
    this.httpClient.get(`${this.endpoint}/push-subscriptions`, { responseType: 'text' }).subscribe({
      next: publicKey => {
        // Request subscription with the service worker
        this.#swPush.requestSubscription({
          serverPublicKey: publicKey
        })
          // Distribute subscription to the server
          .then(subscription => this.post('push-subscriptions', subscription).subscribe(
            {
              next: () => {
                // do nothing
              },
              error: error => console.error(error)
            }));
      },
      error: error => console.error(error)
    });
  }

  public unsubscribeAll() {
    this.#swPush.unsubscribe()
      .then(() => this.delete('push-subscriptions').subscribe(
        {
          next: () => {
            // do nothing
          },
          error: error => console.error(error)
        }))
      .catch((error: unknown) => console.error(error));
  }

  public unsubscribe(endpoint: string) {
    this.#swPush.unsubscribe()
      .then(() => this.delete(`push-subscriptions/${encodeURIComponent(endpoint)}`).subscribe(
        {
          next: () => {
            // do nothing
          },
          error: error => console.error(error)
        }))
      .catch((error: unknown) => console.error(error));
  }
}
