import { Pipe, PipeTransform } from '@angular/core';

import { ageFromNumber } from '../helpers';
import { Age } from '../models';

@Pipe({
  name: 'ageFromNumber'
})
export class AgeFromNumberPipe implements PipeTransform {
  transform(age: number | null | undefined): Age | undefined {
    return ageFromNumber(age);
  }
}
