import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../../../account.service';
import { AccountContact } from '../../../../../models';

@Component({
  selector: 'kip-account-contact-login',
  templateUrl: './account-contact-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountContactLoginComponent implements OnInit, OnDestroy {

  readonly #accountService = inject(AccountService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #activeModal = inject(NgbActiveModal);

  #primaryContacts: AccountContact[] = [];
  #editingContact: AccountContact | undefined;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get editingContact() {
    return this.#editingContact;
  }

  get primaryContacts() {
    return this.#primaryContacts;
  }

  get currentModal() {
    return this.#activeModal;
  }

  /* eslint-disable kip/no-unused-public-members */
  @Input({ required: true }) userId: number | undefined;
  @Input({ required: true }) contactId: number | undefined;
  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly loginCreated = new EventEmitter<boolean>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#accountService.getContacts().subscribe(contacts => {
        this.#primaryContacts = contacts.filter(contact => contact.contact.isPrimary && !contact.userName
          && (!this.contactId || contact.id === this.contactId));
        if (this.#primaryContacts.length === 1) {
          this.#editingContact = this.#primaryContacts[0];
        }
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  selectContact(contact: AccountContact) {
    this.#editingContact = contact;
  }

  cancelEdit() {
    if (this.#primaryContacts.length > 1) {
      this.#editingContact = undefined;
    } else {
      this.#activeModal.close();
    }
  }

  completeEdit() {
    this.loginCreated.emit(this.#primaryContacts.length > 1);
    this.#activeModal.close();
  }
}
