import { overlaysBackgroundColorReducer } from './overlays-background-color/overlays-background-color.reducer';
import { routingReducer } from './routing-state/routing.reducer';

export * as fromOverlaysBackgroundColor from './overlays-background-color';
export * as fromRouting from './routing-state';

export const reducers = {
  overlaysBackgroundColor: overlaysBackgroundColorReducer,
  routing: routingReducer
};

export * from './overlays-background-color/overlays-background-color.reducer';
export * from './overlays-background-color/overlays-background-color.selectors';
export * from './routing-state/routing.reducer';
export * from './routing-state/routing.selectors';
