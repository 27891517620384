import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-explosive-bomb',
  templateUrl: './explosive-bomb.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconExplosiveBombComponent implements IconComponent {

}
