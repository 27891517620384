import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ProfileService, Role } from 'ui-common-lib';

export const centreManagerGuard = () => {
  const router = inject(Router);
  const profileService = inject(ProfileService);
  return profileService.getUserProfile().pipe(
    map(profile => {
      const isCentreManager = profile.roles.includes(Role.CentreManager);
      const isSuperUser = profile.roles.includes(Role.SuperUser);
      const isCentreSupport = profile.roles.some(role => role.startsWith(Role.Support));

      return isCentreManager || isCentreSupport || isSuperUser;
    }),
    tap((isAuthorized: boolean) => {
      if (!isAuthorized) {
        router.navigate(['403-forbidden']);
      }
    }));
};
