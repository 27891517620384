import { createAction, props } from '@ngrx/store';

import { Alert, AlertActionName } from '../../models';

enum AlertActionTypes {
  ClearAlerts = 'Tutor > Alerts > Clear Alerts',
  AddAlert = 'Tutor > Alerts > Add Alert',
  RemoveAlert = 'Tutor > Alerts > Remove Alert',
  SelectAction = 'Tutor > Alerts > Send Action',
  RemoveMatchingAlerts = 'Tutor > Alerts > Remove Matching'
}

export const clearAlertsAction = createAction(AlertActionTypes.ClearAlerts);

export const addAlertAction = createAction(
  AlertActionTypes.AddAlert,
  props<{
    readonly alert: Alert;
  }>()
);

export const removeAlertAction = createAction(
  AlertActionTypes.RemoveAlert,
  props<{
    readonly alert: Alert;
  }>()
);

export const removeMatchingAlertsAction = createAction(
  AlertActionTypes.RemoveMatchingAlerts,
  props<{
    readonly shouldBeRemoved: (alert: Alert) => boolean;
  }>()
);

export const selectAlertActionAction = createAction(
  AlertActionTypes.SelectAction,
  props<{
    readonly alert: Alert;
    readonly action: AlertActionName;
  }>()
);
