import { Pipe, PipeTransform } from '@angular/core';

import { LessonSummary, SessionSummary } from '../models';

@Pipe({
  name: 'lessonSummary'
})
export class LessonSummaryPipe implements PipeTransform {
  transform(lesson: LessonSummary, session: SessionSummary, category = false): string {

    return `(${lesson.studentGradeShortCode ?? (!session.isDropIn ? '?' : '')}${session.isAssessment ? 'A' : lesson.subjectShortCode}${category ? lesson.category : ''})`;
  }
}
