import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-strong-muscles-d',
  templateUrl: './strong-muscles-d.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconStrongMusclesDComponent implements IconComponent {

}
