export enum QuestionLayoutType {
  Generic = 1,
  SingleAnswerMultipleChoice = 2,
  MultipleAnswerMultipleChoice = 3,
  Sorting = 4,
  Selection = 5,
  Matching = 6,
  DigitalClock = 7,
  AnalogueClock = 8,
  WordPicker = 9,
  Spelling = 10,
  WordMatch = 11,
  FillInTheBlank = 12,
  Drill = 13
}
