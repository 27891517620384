import { ChangeDetectionStrategy, Component, effect, inject, input, model, output, TemplateRef, viewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { NotesForSession, SessionNote } from '../models';

@Component({
  selector: 'kip-session-notes',
  templateUrl: './session-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionNotesComponent {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;
  readonly allowInternalRanking = input.required<boolean | undefined>();
  readonly allowParentRanking = input.required<boolean | undefined>();
  readonly note = model.required<SessionNote | undefined>();

  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly noteSaved = output<NotesForSession>();

  constructor() {
    effect(() => {
      const note = this.note();
      const modalContent = this.modalContent();

      if (note && modalContent) {
        this.#modalService.open(modalContent, { size: 'lg', centered: true });
      } else {
        this.#modalService.dismissAll();
      }
    });
  }

  updateNote(note: NotesForSession) {
    this.noteSaved.emit(note);
    this.note.set(undefined);
  }

  closeModal() {
    this.#modalService.dismissAll();
  }
}
