import { SimpleScore } from 'pipes-directives-lib';

export function convertSimpleToPercentage(simpleScore: SimpleScore) {
  let percentage: number | undefined;
  switch (simpleScore) {
    case SimpleScore.A:
      percentage = 100;
      break;
    case SimpleScore.B:
      percentage = 75;
      break;
    case SimpleScore.C:
      percentage = 50;
      break;
    case SimpleScore.None:
      percentage = undefined;
      break;
  }

  return percentage;
}
