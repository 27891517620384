import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-1-4',
  templateUrl: './diy-set1-4.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet14Component implements IconComponent {

}
