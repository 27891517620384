<div class="kip-word-match-layout">
  <kip-question-text [question]="question" />
  <div #container class="gu-unselectable">
    <div class="d-flex flex-row justify-content-center mb-4">
      @for (sourceOption of sourceOptions; track sourceOption; let i = $index) {
      <div #source class="kip-word px-2" [class.kip-active]="sourceMatched(sourceOption)">
        {{sourceOption.text}}
      </div>
      }
    </div>
    <div class="d-flex flex-row justify-content-center">
      <div>
        @for (targetOption of targetOptions; track targetOption; let i = $index) {
        <div #target class="flex-word">
          @for (letter of getLetters(targetOption.value); track letter; let i = $index) {
          <div [ngClass]="getLetterStyles(letter)">
          </div>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>