<label [class]="labelClass()??''">{{ label() }}</label>
<div class="kip-form-group">
  <div class="kip-button-group btn-group btn-group-toggle">
    @for (currency of currencies(); track currency) {
    <input class="btn-check" name="currency-picker" type="radio" [disabled]="disabled()" [formControl]="currencyCode()"
      [id]="currency.code" [value]="currency.code">
    <label class="kip-button-group__option btn" [for]="currency.code">
      {{ currency.code}}
    </label>
    }
  </div>
</div>