import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Tutor } from '../../models';
import { SharedService } from '../../services';

@Component({
  selector: 'kip-tutor-picker',
  templateUrl: './tutor-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorPickerComponent implements OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #tutors: Tutor[] = [];

  #centreId: number | undefined;
  #subscriptions: Subscription[] = [];

  get tutors() {
    if (this.aiOnly) {
      const tutors: Tutor[] = [{
        id: 0,
        givenName: 'None',
        familyName: '',
        gender: null,
        isAI: true,
        dateDisabledOn: null,
        videoRecordOptionId: 0
      }];
      tutors.push(...this.#tutors.filter(s => s.isAI));

      return tutors;
    }

    return this.#tutors;
  }

  @Input() aiOnly = false;
  @Input({ required: true }) tutorId: FormControl<number> | undefined;
  @Input({ required: true }) label = '';
  @Input({ required: true }) name = '';
  @Input() labelClass: string | undefined;
  @Input() noTutorsAvailableMessage = ' : No tutors are available';

  /* eslint-disable kip/no-unused-public-members */

  @Input() unavailableTutorIds: readonly number[] = [];

  @Input({ required: true }) set centreId(centreId: number | undefined) {
    if (centreId && this.#centreId !== centreId) {
      this.#centreId = centreId;
      this.#subscriptions.push(
        this.#sharedService.getTutors(this.#centreId)
          .subscribe(tutors => {
            this.#tutors = tutors.filter(s => !this.unavailableTutorIds.includes(s.id));
            this.#changeDetectorRef.markForCheck();
          }));
    }
  }

  get centreId() {
    return this.#centreId;
  }

  /* eslint-enable kip/no-unused-public-members */

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

}
