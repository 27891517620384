import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-2-1',
  templateUrl: './diy-set2-1.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet21Component implements IconComponent {

}
