export enum SortColumnKey {
  None = 0,
  Day = 1,
  Time = 2,
  Tutor = 3,
  Centre = 4,
  GivenName = 5,
  FamilyName = 6,
  Grade = 7,
  Region = 8,
  Gender = 9,
  Status = 10,
  Date = 11,
  PaymentMethod = 12,
  BillingPeriod = 13,
  Currency = 14,
  Hidden = 15,
  Organisation = 16,
  Name = 17,
  Type = 18,
  Session = 19,
  EndDate = 20,
  Subject = 21,
  MiddleName = 22,
  TimeZone = 23,
  VideoRecordOption = 24
}
