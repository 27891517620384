import { QuestionControlParameterItem, QuestionSummary } from '../../models';

export function convertSelection(question: QuestionSummary<QuestionControlParameterItem[]>,
  genericInput: string, render: (input: string) => string, showAnswerOnly: boolean) {
  const values: (number | string | undefined)[] = [];
  for (const answer of question.answers) {
    values.push(answer.values[0]);
  }

  if (showAnswerOnly) {
    return genericInput.replace('&nbsp;', values.map(value => `<span class="kip-success-tutor-answer">${value}</span>`).join(''));
  }
  let selection = '';
  for (const parameter of question.parameters) {
    selection += values.includes(parameter.value) ? ` <b>${render(parameter.text)}</b>` : ` ${render(parameter.text)}`;
  }

  return question.text + genericInput.replace('&nbsp;', selection);
}
