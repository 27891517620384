import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';

import { SolutionAttemptSummaryComponent } from '../solution-attempt-summary/solution-attempt-summary.component';
import { AnalogueClockComponent } from './layouts/analogue-clock/analogue-clock.component';
import { DigitalClockComponent } from './layouts/digital-clock/digital-clock.component';
import { AnswerBoxComponent } from './layouts/drill/controls/answer-box/answer-box.component';
import { DrillComponent } from './layouts/drill/drill.component';
import { FillInTheBlankComponent } from './layouts/fill-in-the-blank/fill-in-the-blank.component';
import { DropdownComponent } from './layouts/generic/controls/dropdown/dropdown.component';
import { ReadonlyComponent } from './layouts/generic/controls/readonly/readonly.component';
import { TextAreaComponent } from './layouts/generic/controls/text-area/text-area.component';
import { TextBoxComponent } from './layouts/generic/controls/text-box/text-box.component';
import { GenericComponent } from './layouts/generic/generic.component';
import { MatchingComponent } from './layouts/matching/matching.component';
import { MultipleChoiceComponent } from './layouts/multiple-choice/multiple-choice.component';
import { QuestionOptionComponent } from './layouts/question-option/question-option.component';
import { QuestionTextComponent } from './layouts/question-text/question-text.component';
import { SortingComponent } from './layouts/sorting/sorting.component';
import { SpellingComponent } from './layouts/spelling/spelling.component';
import { SpellingTimerService } from './layouts/spelling/spelling-timer.service';
import { WordMatchComponent } from './layouts/word-match/word-match.component';
import { WordPickerComponent } from './layouts/word-picker/word-picker.component';
import { QUESTION_BEHAVIOUR_CONTROLLER_FACTORY, QuestionBehaviour, QuestionBehaviourController } from './models';
import { QuestionNavigatorComponent } from './navigator/navigator.component';
import { AnswerValidationPipe, CurrencySwapPipe, InputLengthPipe, SafeHtmlPipe, TextAlignmentPipe } from './pipes';
import { QuestionComponent } from './question/question.component';
import { QuestionSummaryComponent } from './question-summary/question-summary.component';
import { QuestionSoundService, QuestionsService } from './services';

// IMPORTANT
// Do not use barrel files in libraries, it will break the compilation
// https://github.com/ng-packagr/ng-packagr/issues/1173

// There is also weird issues with functions for factories
// https://thilanka-nuwan.medium.com/angular-lambda-not-supported-error-work-around-8f204f4cad9a

function createBehaviourControllerFactory(soundService: QuestionSoundService) {
  return (behaviour: QuestionBehaviour) => new QuestionBehaviourController(behaviour, soundService);
}

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    IconLibModule,
    NgbTooltipModule,
    NgSelectModule,
    PipesDirectivesLibModule
  ],
  providers: [{
    provide: QUESTION_BEHAVIOUR_CONTROLLER_FACTORY,
    useFactory: createBehaviourControllerFactory,
    deps: [QuestionSoundService]
  },
    QuestionsService,
    SpellingTimerService],
  declarations: [
    AnalogueClockComponent,
    AnswerBoxComponent,
    AnswerValidationPipe,
    CurrencySwapPipe,
    DigitalClockComponent,
    DrillComponent,
    DropdownComponent,
    FillInTheBlankComponent,
    GenericComponent,
    InputLengthPipe,
    MatchingComponent,
    MultipleChoiceComponent,
    QuestionComponent,
    QuestionNavigatorComponent,
    QuestionOptionComponent,
    QuestionSummaryComponent,
    QuestionTextComponent,
    ReadonlyComponent,
    SafeHtmlPipe,
    SolutionAttemptSummaryComponent,
    SortingComponent,
    SpellingComponent,
    TextAlignmentPipe,
    TextAreaComponent,
    TextBoxComponent,
    WordMatchComponent,
    WordPickerComponent
  ],
  exports: [
    CurrencySwapPipe,
    QuestionComponent,
    QuestionNavigatorComponent,
    QuestionSummaryComponent,
    SolutionAttemptSummaryComponent
  ]
})
export class QuestionsLibModule {

}
