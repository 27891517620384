import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconLibModule } from 'icon-lib';

import { MarkdownEditorComponent } from './editor/markdown-editor.component';
import { ToolbarComponent } from './editor/toolbar/toolbar.component';
import { MarkdownModalComponent } from './modal/markdown-modal.component';

@NgModule({
  declarations: [
    MarkdownEditorComponent,
    MarkdownModalComponent,
    ToolbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconLibModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ReactiveFormsModule
  ],
  exports: [
    MarkdownEditorComponent,
    MarkdownModalComponent,
    ToolbarComponent
  ]
})
export class MarkdownLibModule { }
