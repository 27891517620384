import {
  ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Proficiency } from '../models';

@Component({
  selector: 'kip-tutor-drop-in-session-proficiency-settings',
  templateUrl: './tutor-drop-in-session-proficiency-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorDropInSessionProficiencySettingsComponent {

  showTutorProficiencies = false;

  // eslint-disable-next-line kip/no-unused-public-members
  @Input({ required: true }) dropInSessionProficiencies: FormControl<Proficiency[] | undefined> | undefined;
  @Input({ required: true }) allProficiencies: Proficiency[] | undefined;

  @ViewChildren('proficiencyCheckbox') proficiencyCheckboxes: QueryList<ElementRef> | undefined;

  setProficiency(proficiency: Proficiency) {
    if (!this.dropInSessionProficiencies?.value) {
      return;
    }

    const proficiencies = [...this.dropInSessionProficiencies.value];

    const proficienciesIndex = proficiencies.findIndex(item => item.id === proficiency.id);

    if (proficienciesIndex > -1) {
      proficiencies.splice(proficienciesIndex, 1);
    }
    else if (proficiencies.filter(item => item.id === proficiency.id).length === 0) {
      proficiencies.push(proficiency);
    }

    this.dropInSessionProficiencies.setValue(proficiencies);
  }

  openProficiencies() {
    this.showTutorProficiencies = !this.showTutorProficiencies;

    // set the controls to reflect the selected options
    setTimeout(() => {
      if (this.proficiencyCheckboxes) for (const element of this.proficiencyCheckboxes) {
        if (!this.dropInSessionProficiencies?.value) {
          continue;
        }
        if (this.dropInSessionProficiencies.value.some(subject => subject.id === Number.parseInt(element.nativeElement.value, 10))) {
          element.nativeElement.checked = true;
        }
      }
    }, 10);
  }

}
