import { createAction, props } from '@ngrx/store';
import { TutorSettings } from 'ui-common-lib';

enum TutorSettingsActionTypes {
  Load = 'Tutor > Tutor Settings > Load'
}

export const loadTutorSettingsAction = createAction(
  TutorSettingsActionTypes.Load,
  props<{
    readonly tutorSettings: TutorSettings;
  }>()
);

