<div class="kip-color-picker-body" [class.simple-mode]="isSimpleMode">
  <p class="kip-color-title">{{ title }}</p>
  <div class="color-btn-body">
    @for (item of colors; track item; let first = $first) {
    <button class="btn-color" type="button"
      [class.active-color]="item.borderColor === selectedColor.borderColor && isSimpleMode"
      [style.background]="item.color" [style.outline-color]="item.borderColor" (click)="onSelectColor(item)">
    </button>
    }
    <button class="btn-color btn-gradient-color" type="button" [class.active-color]="!isSimpleMode"
      [style.border-color]="selectedColor.borderColor" (click)="onExtendColor()"></button>
  </div>
  <div class="kip-color-picker-content">
    @if (!isSimpleMode) {
    <span [cpCancelButton]="true" [cpDialogDisplay]="'inline'" [cpToggle]="true"
      [style.background]="selectedColor.color" [(colorPicker)]="selectedColor.color"
      (colorPickerChange)="onColorPickerChange($event)">
    </span>
    } @else {
    <span [cpCancelButton]="true" [cpDialogDisplay]="'inline'" [cpToggle]="true" [cpWidth]="'305px'"
      [style.background]="selectedColor.color" [(colorPicker)]="selectedColor.color"
      (colorPickerChange)="onColorPickerChange($event)">
    </span>
    }

  </div>
</div>