@if (hasActivityNotes()) {
<button class="btn kip-btn float-end border-0 rounded-0 h-100" ngbTooltip="Add Activity Notes" placement="bottom"
  type="button" (click)="appendActivityNotes()">
  <fa-icon class="text-success" [fixedWidth]="true" [icon]="icons.note.existing" />
</button>
}
<form [formGroup]="noteForm" (ngSubmit)="submit()">
  @if (note(); as note)
  {
  @if (!note.date) {
  <div class="mb-3">
    How did <span class="fw-bold"> {{ note.studentName }}</span> fare in today's lesson?
  </div>
  }
  @else {
  <div class="mb-3">
    How did <span class="fw-bold"> {{ note.studentName }}</span> fare on the lesson dated {{
    note.date |
    dateTime: 'DD-MMM-YY'}}?
  </div>
  }
  }
  <kip-tabs tabClass="kip-tab-sheet bg-white d-flex border-bottom" [tabIndex]="currentTabIndex()" [tabs]="tabs()"
    (tabIndexChange)="currentTabIndex.set($event)" />

  @if (currentTabIndex() === tabIndex.Internal)
  {
  <kip-emoji-edit label="Tutor Internal Notes" name="lessonInternal" ngDefaultControl subLabel="PRIVATE (IGNORED BY AI)"
    [edit]="true" [formControl]="internalNote" />
  @if (allowInternalRanking()) {
  <div class="kip-form-group">
    <kip-ranking-picker name="internalRanking" prefix="I" [allowNull]="true" [ranking]="internalNoteRanking" />
  </div>
  }
  <hr class="my-3">
  <kip-emoji-edit label="Lesson Focus" name="lessonFocus" ngDefaultControl subLabel="PRIVATE (INCLUDED BY AI)"
    [edit]="true" [formControl]="focusNote" />
  }
  @if (currentTabIndex() === tabIndex.Parent)
  {
  @if (aiModelId() > 0)
  {
  <kip-emoji-edit label="AI Prompt" name="aiPrompt" ngDefaultControl subLabel="GENERATED (DISPLAY ONLY)"
    [allowEmojis]="false" [edit]="true" [formControl]="aiNote" />
  <hr class="my-3">
  }
  <kip-emoji-edit label="Parent" name="lessonParent" ngDefaultControl subLabel="SHARED (AI/HUMAN GENERATED)"
    [edit]="true" [formControl]="parentNote" [initFocus]="false" />
  @if (allowParentRanking()) {
  <div class="kip-form-group">
    <kip-ranking-picker name="parentRanking" prefix="P" [allowNull]="true" [ranking]="parentNoteRanking" />
  </div>
  }
  <div class="notes-parent-warning py-3 d-flex align-items-center">
    <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
    <div class="pt-3 pb-1">
      Summary notes entered here will appear in the Parent Portal once you hit 'Submit'.<br />
      If you are using AI, it is your responsibility to check to see that the notes are clear and correct.
    </div>
  </div>
  }
  <kip-form-error [formGroup]="noteForm" />
  <button class="btn btn-primary mt-3" type="submit">
    {{ parentNote.value ? 'Save Notes and Parent Notes' : 'Save Notes'}}
  </button>
  @if (aiModelId() > 0)
  {
  <button class="btn btn-outline-primary ms-2 mt-3" type="button" [disabled]="generatingAI()"
    (click)="generateAIResponse()">
    <fa-icon class="text-success" [animation]="generatingAI()?'spin': undefined" [fixedWidth]="true"
      [icon]="icons.brainBooster" />
    Generate AI response
  </button>
  }
  <button class="btn btn-link ms-2 mt-3" type="button" (click)="ignoreSave()">Cancel</button>
</form>