import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accountsRoute } from 'ui-common-lib';

import { AccountBundleDetailComponent } from './account-bundle/account-bundle-detail/account-bundle-detail.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountRouteParams, AccountRoutes } from './account-route-helper';

const routes: Routes = [
  {
    path: '',
    component: AccountListComponent,
    data: accountsRoute
  },
  {
    path: `:${AccountRouteParams.AccountId}`,
    component: AccountDetailComponent
  },
  {
    path: `:${AccountRouteParams.AccountId}/${AccountRoutes.Edit}`,
    component: AccountEditComponent
  },
  {
    path: `:${AccountRouteParams.AccountId}/${AccountRoutes.Bundles}`,
    component: AccountBundleDetailComponent
  },
  {
    path: `:${AccountRouteParams.AccountId}/${AccountRoutes.Bundles}/:${AccountRouteParams.BundleId}`,
    component: AccountBundleDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
