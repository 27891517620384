import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-writing-hand-c',
  templateUrl: './writing-hand-c.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconWritingHandCComponent implements IconComponent {

}
