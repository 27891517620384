import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-climbing-skill-a',
  templateUrl: './climbing-skill-a.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconClimbingSkillAComponent implements IconComponent {

}
