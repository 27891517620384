import { fabric } from 'fabric';

import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardTool } from '../whiteboard-tool';

export class WhiteboardHighlighterTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Highlighter);
  }

  setup(color: WhiteboardColor, canvas: fabric.Canvas) {
    this.resetCanvas(canvas, true, false);
    canvas.freeDrawingBrush.color = color.highlightDefinition;
    canvas.freeDrawingBrush.width = 10;
  }

  teardown(canvas: fabric.Canvas) {
    canvas.isDrawingMode = false;
  }
}
