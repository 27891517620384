import { KeyboardLayouts, KeyboardType, SpecialKeys } from '../models';

export function determineKeyboards() {
  const keyboardLayouts = KeyboardLayouts;
  const keyboardMap = new Map<KeyboardType, string>();

  const keyboardTypes = Object.values(KeyboardType);

  for (const keyboardType of keyboardTypes) {

    const keyboardArray = keyboardLayouts[keyboardType];
    if (keyboardArray) {

      let keyboardText = keyboardArray.join('').replace(/ +/g, '');

      const specialKeys = Object.values(SpecialKeys);
      for (const specialKey of specialKeys) {
        while (keyboardText.includes(specialKey)) {
          keyboardText = keyboardText.replace(specialKey, specialKey === SpecialKeys.Space ? ' ' : '');
        }
      }

      keyboardMap.set(keyboardType, keyboardText);
    }

  }
  return keyboardMap;
}

export function determineBestMatch(answer: string) {
  let keyboardType = KeyboardType.AlphabeticalLowercase;
  const keyboards = determineKeyboards();
  const keys = keyboards.keys();
  const characters = [...answer];
  let bestCase = 0;
  for (const key of keys) {
    const keyboardString = keyboards.get(key);
    if (keyboardString) {
      let match = 0;
      for (const character of characters) {
        if (keyboardString.includes(character)) {
          match++;
        }
      }

      if (match > bestCase) {
        bestCase = match;
        keyboardType = key;
      }
    }
  }

  return keyboardType;
}

export function outputKeyboards() {
  const keyboards = determineKeyboards();
  const keys = keyboards.keys();
  for (const key of keys) {
    const keyboardText = keyboards.get(key);
    if (keyboardText && keyboardText.length > 0) {
      console.log(`${key} | ${keyboardText}`);
    }
  }

  console.log('Tests');
  const testCases = ['12345', 'rabbit', 'RABBIT', '123', '1234.4', 'R1234,22', '1 + 2 = ', '1 < 2'];
  for (const testCase of testCases) {
    console.log(`${testCase} | ${determineBestMatch(testCase)}`);
  }

}
