import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-ai-model-picker',
  templateUrl: './ai-model-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiModelPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly modelId = input.required<FormControl<number>>();
  readonly models = toSignal(this.#sharedService.getAiModels(), { initialValue: [] });
  readonly label = input('');
  readonly name = input('');
  readonly labelClass = input<string | undefined>(undefined);

}
