import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceEnvironment } from 'service-lib';

@Component({
  selector: 'kip-credit-card-popup',
  templateUrl: './credit-card-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['::ng-deep .wpwl-form-card { background-color:#FFFFFF !important; margin-top: 25px !important; border-radius: 15px !important; border:none !important;padding:25px !important; }',
    '::ng-deep .wpwl-label-brand { display:none; }']
})
export class CreditCardPopupComponent implements AfterViewInit {

  get redirectUrl() {
    return `${ServiceEnvironment.value.api.endpoint}/payments/complete`;
  }

  constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly route: ActivatedRoute) { }

  ngAfterViewInit() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${ServiceEnvironment.value.tillPaymentsScriptUrl}?checkoutId=${this.route.snapshot.queryParamMap.get('checkoutId')}`;
    this.elementRef.nativeElement.append(script);
  }
}
