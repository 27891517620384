import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-thumbs-up-b',
  templateUrl: './thumbs-up-b.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconThumbsUpBComponent implements IconComponent {

}
