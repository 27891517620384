import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-science-whiz-boy-d',
  templateUrl: './science-whiz-boy-d.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconScienceWhizBoyDComponent implements IconComponent {

}
