<label for="activityContent">{{textLabel}}</label>
<div class="kip-question-editor mb-3">
  <kip-toolbar (selectMarkdownConfig)="change($event)" />
  <textarea #input class="kip-text-area form-control" id="activityContent" [(ngModel)]="text"
    (ngModelChange)="change();"></textarea>

  <div class="bg-light border border-top-0 p-3 cursor-pointer" [attr.aria-expanded]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed">
    <fa-icon class="me-2" [fixedWidth]="true" [icon]="isCollapsed?icons.carets.right:icons.carets.down" />
    <span>Preview</span>
  </div>

  <div #collapse="ngbCollapse" class="collapse show border border-top-0 px-3 pt-3" [innerHTML]="textHTML"
    [ngbCollapse]="isCollapsed"></div>
</div>