import { InjectionToken } from '@angular/core';

import { WhiteboardMemento } from './whiteboard-memento';

export type WhiteboardMementoManagerFactory = () => WhiteboardMementoManager;
export const MEMENTO_MANAGER_FACTORY = new InjectionToken<WhiteboardMementoManagerFactory>('spectrum - whiteboard memento manager');

export class WhiteboardMementoManager {

  private undoes: WhiteboardMemento[] = [];
  private redoes: WhiteboardMemento[] = [];

  add(memento: WhiteboardMemento) {
    this.undoes.push(memento);
    this.redoes = [];
  }

  clear() {
    this.undoes = [];
    this.redoes = [];
  }

  undo() {
    this.#invoke(this.undoes, this.redoes, memento => memento.revert());
  }

  redo() {
    this.#invoke(this.redoes, this.undoes, memento => memento.apply());
  }

  #invoke(source: WhiteboardMemento[], target: WhiteboardMemento[], invoker: (memento: WhiteboardMemento) => void) {
    if (source.length > 0) {
      const memento = source.pop();
      if (memento) {
        invoker(memento);
        target.push(memento);
      }
    }
  }
}
