export enum VideoEffectType {
  None = 0,
  Blur = 1,
  Image1 = 2,
  Pixelate = 3,
  Image2 = 4,
  Image3 = 5,
  Image4 = 6,
  Image5 = 7
}
