import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../../models';
import { CurrencyValidationHelper } from '../currency/currency-validation.helper';

type NumberEqualityComparer = (studentAnswer: AnswerType) => boolean;

interface NumberValidatorOptions {
  formatter: Intl.NumberFormat;
}

export class NumberValidator implements AnswerValidator {

  #region: Region | undefined;

  set region(value: Region | undefined) {
    this.#region = value;

    if (value) {
      this.options = {
        formatter: new Intl.NumberFormat(value.locale)
      };
    }
  }

  get region() {
    return this.#region;
  }
  options: NumberValidatorOptions | undefined;

  validate(question: Question, answers: AnswerType[]): ValidationResult[] {

    if (answers.length === question.answers.length) {
      return answers.map((studentAnswer, index) => {
        const answer = question.answers[index];
        for (const expected of answer.values) {
          const validator = this.resolveAnswerValidator(expected);

          if (validator(studentAnswer)) {
            return ValidationResult.Correct;
          }

        }
        return ValidationResult.Incorrect;
      });
    }

    return Array.from<ValidationResult>({ length: answers.length }).fill(ValidationResult.Incorrect);
  }

  protected resolveAnswerValidator(answer: AnswerType): NumberEqualityComparer {
    if (!answer) {
      return () => false;
    }

    return (studentAnswer: AnswerType) => {
      if (this.options && this.#region) {

        // if the number has a zero decimal (e.g 1.0 it would display 1, which is not what we want)

        let minimumFractionDigits = 0;

        const answerValue = answer.toString();
        if (answerValue.includes('.')) {
          minimumFractionDigits = answerValue.split('.')[1].length;
        }

        // This is a hack, as some numbers have more than 3 decimal places
        // and if you don't specify, it defaults to 3

        const helper = new CurrencyValidationHelper(answer,
          new Intl.NumberFormat(this.#region.locale,
            {
              style: 'decimal',
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: 10
            }), []);
        helper.createMatches();
        return helper.validate(studentAnswer);
      }

      return false;
    };
  }
}
