<div class="kip-question d-flex flex-column h-100 kip-no-select" [ngClass]="regionClass">
  <div class="d-flex flex-column align-items-center my-auto">
    @if (tags) {
    <div style="position:absolute;right:20px;top:20px">
      @for (tag of tags; track tag) {
      <button class="btn btn-outline-secondary me-2" type="button" [ngbTooltip]="tipContent">
        <ng-template #tipContent>
          <div [innerHtml]="tag.htmlDetails"></div>
        </ng-template>
        {{ tag.name }}
      </button>
      }
    </div>
    }
    <div class="kip-layout p-2" id="questionPreview">
      <ng-container #layout />
    </div>
    <div class="kip-buttons text-center">
      <button class="kip-submit btn btn-success mt-3 p-0" type="button" [disabled]="canSubmit ? undefined : 'disabled'"
        [hidden]="!showSubmit" (click)="validateAnswers()">Submit</button>
      <div class="d-flex justify-content-center w-100 mt-3">
        @if (allowSkip && !readonly) {
        <button class="btn btn-text question-btn-link" type="button" (click)="skip()">Skip</button>
        }
        @if (allowShowAnswer && !readonly) {
        <button class="btn btn-text question-btn-link" type="button" (click)="showAnswer()">Show</button>
        }
        @if (allowShowAnswer && readonly) {
        <button class="btn btn-text question-btn-link" type="button" (click)="next()">Next</button>
        }
        @if (allowQuit && !readonly) {
        <button class="btn btn-text question-btn-link" type="button" (click)="this.quit.emit()">Quit</button>
        }
      </div>
    </div>
  </div>
</div>
@if (version) {
<div style="position:absolute;top:10px;left:10px">
  {{ version }}
</div>
}

@if (showSoundState) {
<div style="position:absolute;top:10px;right:10px">
  {{ soundState }}
</div>
}