import { ScoringStatus } from 'pipes-directives-lib';

import { Scoring } from './scoring';
import { ScoringStrategy } from './scoring-strategy';

export class ScoringStrategyMarkActivity implements ScoringStrategy {
  shouldCalculatePercentage(scoring: Scoring): boolean {
    return scoring.scoringStatus === ScoringStatus.Scoring;
  }

  shouldMarkInvalid(scoring: Scoring): boolean {
    return scoring.scoringStatus !== ScoringStatus.Scoring ? false : scoring.isDirty;
  }
}
