<div class="kip-form-group">
  <label for="keyword-search">Keyword Search</label>
  <input autocomplete="off" class="form-control" id="keyword-search" type="text" [(ngModel)]="keyword">
</div>
<div class="row">
  <div class="col-5" [hidden]="!isKeywordSearch">
    <kip-awards-filtered-list [keyword]="keyword" (awardPicked)="selectIcon($event)" />
  </div>
  <div class="col-5" [hidden]="isKeywordSearch">
    <kip-awards-group-list (awardPicked)="selectIcon($event)" />
  </div>
  <div class="col-7">
    <div class="m-2" style="width:50px;display:inline-block">
      <kip-icon [displayIcon]="awardPickedIcon" />
    </div>
    <kip-student-awards-base [lessonId]="lessonId" [selectedAward]="selectedAward" [studentId]="studentId"
      (cancel)="cancel.emit()" (chatAlert)="chatAlert.emit($event)" />
  </div>
</div>