import { fabric } from 'fabric';

import { TransformExtended, WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardScaleMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly obj: fabric.Object;

  private readonly currentTop: number | undefined;
  private readonly currentLeft: number | undefined;
  private readonly currentScaleX: number | undefined;
  private readonly currentScaleY: number | undefined;

  private readonly previousTop: number;
  private readonly previousLeft: number;
  private readonly previousScaleX: number;
  private readonly previousScaleY: number;

  constructor(canvas: fabric.Canvas, obj: fabric.Object, transform: TransformExtended) {
    super();

    this.canvas = canvas;
    this.obj = obj;

    this.currentTop = obj.top;
    this.currentLeft = obj.left;
    this.currentScaleX = obj.scaleX;
    this.currentScaleY = obj.scaleY;

    this.previousTop = transform.original.top;
    this.previousLeft = transform.original.left;
    this.previousScaleX = transform.original.scaleX;
    this.previousScaleY = transform.original.scaleY;
  }

  apply() {
    this.invoke(
      () => {
        this.obj.top = this.currentTop;
        this.obj.left = this.currentLeft;
        this.obj.scaleX = this.currentScaleX;
        this.obj.scaleY = this.currentScaleY;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:scaled', this.obj, this.canvas);
      },
      this.obj);
  }

  revert() {
    this.invoke(
      () => {
        this.obj.top = this.previousTop;
        this.obj.left = this.previousLeft;
        this.obj.scaleX = this.previousScaleX;
        this.obj.scaleY = this.previousScaleY;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:scaled', this.obj, this.canvas);
      },
      this.obj);
  }
}
