import { StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { ConfigId, getOpenIdConfiguration } from 'auth-lib';
import { map } from 'rxjs';

import { ConfigService } from './shared';

export function initFn(configService: ConfigService) {
  return () => configService.config$;
}

export const appInitializerFn = (configService: ConfigService) => {
  return new StsConfigHttpLoader(configService.config$.pipe(map(s => getOpenIdConfiguration(s.auth).filter(f => f.configId !== ConfigId.Parent))));
};
