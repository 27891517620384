import { HttpEventType, HttpProgressEvent } from '@angular/common/http';

export function fileUploadingProgressHandler(response: HttpProgressEvent): number {
  if (response.type === HttpEventType.UploadProgress) {
    const eventTotal = response.total ?? 0;
    return Math.round(response.loaded / eventTotal * 100);
  }

  return 0;
}

export function fileUploadCompletionHandler(response: HttpProgressEvent, isFinished = false): boolean {
  const total = response?.total;
  const loaded = response?.loaded;

  return isFinished || !!(total && loaded && total === loaded);
}

export const REPORT_PROGRESS_OPTION = {
  reportProgress: true,
  observe: 'events'
};
