@if (!enrolmentSessions) {
<div>Loading !!</div>
}
@if (enrolmentSessions) {
<div>
  @if (enrolmentSessions.length === 0) {
  <div>
    No lessons found !
  </div>
  }
  @if (enrolmentSessions.length > 0) {
  <div>
    <kip-enrolment-session [enrolmentSessions]="enrolmentSessions" [listOnly]="false"
      (enrolmentSessionSelected)="selectEnrolmentSession.emit($event)" />
  </div>
  }
</div>
}