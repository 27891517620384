import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputLength'
})
export class InputLengthPipe implements PipeTransform {

  transform(length?: number, _width?: number) {

    if (length) {
      return ` kip-input-${length}`;
    }
    return '';
  }
}
