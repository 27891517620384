@if (curriculumNodes && curriculumNodes.length > 0) {
<div class="kip-explore-menu overflow-hidden mb-3">
  <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2 rounded-top"
    [class.kip-activity--expanded]="expanded" (click)="expanded = !expanded">
    <div class="flex-column text-start">
      <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
          [icon]="expanded ? icons.folder.open : icons.calendar.expand" /></span>
      Content based on curriculum
    </div>
    <div class="flex-column text-end">
      <fa-icon [icon]="expanded ? icons.chevrons.down : icons.chevrons.right" />
    </div>
  </div>
  @if (expanded) {
  <div class="kip-activity-block">
    @for (curriculumNode of curriculumNodes; track curriculumNode) {
    <kip-curriculum-node [allowPreview]="allowPreview" [regionId]="regionId" [resetTime]="resetTime"
      [rootNode]="curriculumNode" [singleSelection]="singleSelection" (showPreview)="showPreview.emit($event)" />
    }
  </div>
  }
</div>
}