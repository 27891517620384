import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../../models';
import { CurrencyValidationHelper } from './currency-validation.helper';

type CurrencyEqualityComparer = (studentAnswer: AnswerType) => boolean;

interface CurrencyValidatorOptions {
  readonly formatter: Intl.NumberFormat;
  readonly suffixes: readonly string[];
}

export class CurrencyValidator implements AnswerValidator {

  #region: Region | undefined;

  set region(value: Region | undefined) {
    this.#region = value;

    if (value) {
      this.options = {
        formatter: new Intl.NumberFormat(value.locale, { style: 'currency', currency: value.currency }),
        suffixes: value.validSuffixes
      };
    }
  }

  get region() {
    return this.#region;
  }

  options: CurrencyValidatorOptions | undefined;

  validate(question: Question, answers: AnswerType[]): ValidationResult[] {

    if (answers.length === question.answers.length) {
      return answers.map((studentAnswer, index) => {
        const answer = question.answers[index];
        for (const expected of answer.values) {
          const validator = this.resolveAnswerValidator(expected);

          if (validator(studentAnswer)) {
            return ValidationResult.Correct;
          }

        }
        return ValidationResult.Incorrect;
      });
    }

    return Array.from<ValidationResult>({ length: answers.length }).fill(ValidationResult.Incorrect);
  }

  protected resolveAnswerValidator(answer: AnswerType): CurrencyEqualityComparer {
    if (!answer) {
      return () => false;
    }

    return (studentAnswer: AnswerType) => {
      if (this.options) {
        const helper = new CurrencyValidationHelper(answer, this.options.formatter, this.options.suffixes);
        helper.createMatches();
        return helper.validate(studentAnswer);
      }

      return false;
    };
  }
}
