import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { GenericPickerOption } from '../../models';

@Component({
  selector: 'kip-generic-picker',
  templateUrl: './generic-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericPickerComponent<T> {

  readonly control = input.required<FormControl<T>>();
  readonly label = input.required<string>();
  readonly name = input.required<string>();
  readonly options = input.required<GenericPickerOption<T>[]>();
  readonly clientSideErrorMessage = input('');
  readonly hideSingle = input(false);

}
