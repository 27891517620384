import { ChangeDetectorRef, inject } from '@angular/core';

import { AnswerType, KeyboardType, QuestionControlParameters, ValidationResult } from '../../../models';

export abstract class QuestionControl {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #autoFocus = false;
  #validationResult: ValidationResult = ValidationResult.NotKnown;

  validationResultEnum = ValidationResult;
  inputLength: number | undefined;
  inputMode: string | undefined;
  tabIndex: number | undefined;
  displayAnswer: AnswerType | undefined;
  parameters: QuestionControlParameters | undefined;
  keyboardType: KeyboardType = KeyboardType.AlphabeticalLowercase;

  submit: () => void = () => {
    // ignore
  };

  update: () => void = () => {
    // ignore
  };

  onChange() {
    this.#validationResult = ValidationResult.NotKnown;
  }

  onUpdate() {
    this.update();
  }

  setValidationResult(validationResult: ValidationResult) {
    this.#validationResult = validationResult;
    this.#changeDetectorRef.markForCheck();
  }

  get validationResult() {
    return this.#validationResult;
  }

  set autoFocus(value: boolean) {
    this.#autoFocus = value;

    // To cater for delays in config being applied and controls being rendered
    // The focus needs to handled when the property is set
    if (this.autoFocus) {
      window.setTimeout(() => this.focus());
    }
  }

  get autoFocus(): boolean {
    return this.#autoFocus;
  }

  abstract get values(): AnswerType[];

  focus() {
    // ignore
  }
}
