import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-reading-book',
  templateUrl: './reading-book.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconReadingBookComponent implements IconComponent {

}
