import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-graph-whiz-girl-f',
  templateUrl: './graph-whiz-girl-f.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconGraphWhizGirlFComponent implements IconComponent {

}
