<div class="kip-history d-flex flex-column h-100 kip-no-select px-3">
  <kip-lesson-history-wrapper
    noResultsText="Nice, a new student! There is no Lesson History data to display here for now."
    [hasHistory]="hasHistory()" [isLoadingHistory]="isLoadingHistory()" [video]="video()"
    (videoChange)="video.set($event)">

    @if (!previewHistory()) {
    <div class="mt-3 mx-3 kip-session">
      @for (history of studentLessonHistory() ?? []; track history) {
      <kip-lesson-history-lesson [collapse]="collapse()" [history]="history" [regionId]="regionId()"
        [studentId]="studentId()" (collapseChange)="collapse.set($event)"
        (editActivityNote)="activityNoteHistory.set($event)" (editLessonNote)="lessonNoteHistory.set($event)"
        (editSessionNote)="sessionNoteHistory.set($event)" (previewHistory)="previewHistory.set($event)"
        (showHtml)="showHtml($event)" (showVideo)="video.set($event)" />
      }
    </div>
    <kip-load-more-wrapper [isLoadingHistory]="isLoadingHistory()" [showLoadMore]="showLoadMore()"
      (loadMoreLessons)="loadMoreLessons.emit()" />
    }
    <kip-activity-review [previewHistory]="previewHistory()" [regionId]="regionId()" [studentId]="studentId()"
      (clear)="previewHistory.set(null)" />

  </kip-lesson-history-wrapper>
</div>

@if (sessionNoteHistory(); as sessionNoteHistory)
{
@if (sessionNote(); as sessionNote) {
<kip-session-notes [allowInternalRanking]="sessionNoteHistory.allowTutorSessionInternalNoteRanking"
  [allowParentRanking]="sessionNoteHistory.allowTutorSessionParentNoteRanking" [note]="sessionNote"
  (noteSaved)="sessionNoteSaved($event)" />
}
}

@if (lessonNoteHistory(); as lessonNoteHistory)
{
@if (lessonNote(); as lessonNote) {
<kip-lesson-notes [activityNotes]="activityNotes()"
  [allowInternalRanking]="lessonNoteHistory.allowTutorLessonInternalNoteRanking"
  [allowParentRanking]="lessonNoteHistory.allowTutorLessonParentNoteRanking" [note]="lessonNote"
  (noteSaved)="lessonNoteSaved($event)" />
}
}

@if (activityNoteHistory(); as activityNoteHistory)
{
@if (activityNote(); as activityNote)
{
<kip-activity-note [note]="activityNote" (noteSaved)="activityNoteSaved($event)" />
}
}