import { ChangeDetectionStrategy, Component, computed, inject, input, OnDestroy, OnInit, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-video-record-option-picker',
  templateUrl: './video-record-option-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoRecordOptionPickerComponent implements OnInit, OnDestroy {

  #subscriptions: Subscription[] = [];
  readonly #sharedService = inject(SharedService);
  readonly icons = Icons;

  readonly videoRecordOptions = toSignal(this.#sharedService.getVideoRecordOptions(), { initialValue: [] });
  readonly name = input('');
  readonly label = input('');
  readonly videoRecordOptionId = input.required<FormControl<number | null>>();
  readonly labelClass = input<string | undefined>(undefined);
  readonly videoRecordOptionIdChange = output<number>();

  readonly selectedVideoRecordOptionId = signal<number | null>(0);

  readonly warning = computed(() => {
    const videoRecordOptions = this.videoRecordOptions();
    const videoRecordOptionId = this.selectedVideoRecordOptionId();

    const videoRecordOption = videoRecordOptions.find(s => s.id === videoRecordOptionId);

    return videoRecordOption?.warning ?? '';
  });

  ngOnInit() {
    this.selectedVideoRecordOptionId.set(this.videoRecordOptionId().value);
    this.#subscriptions.push(this.videoRecordOptionId().valueChanges.subscribe(value => {
      this.selectedVideoRecordOptionId.set(value);
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

}
