<ng-template #idleWarning>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.act" />
      Start Acting
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body kip-student px-0 py-2">
    <div class="kip-form-group ms-2 me-2">
      <input class="form-control w-100" placeholder="Filter..." type="text" [(ngModel)]="filter" />
    </div>
    <ul class="kip-tab-sheet kip-tab-sheet--small border-bottom bg-white d-flex">
      <li class="kip-tab-sheet__tab kip-tab-sheet__tab--small font-size-normal"
        [class.kip-tab-sheet__tab--active]="currentTabIndex === switchUserIndex.Student"
        (click)="currentTabIndex=switchUserIndex.Student">
        Students {{ students.length }}
      </li>
      <li class="kip-tab-sheet__tab kip-tab-sheet__tab--small font-size-normal"
        [class.kip-tab-sheet__tab--active]="currentTabIndex === switchUserIndex.Tutor"
        (click)="currentTabIndex=switchUserIndex.Tutor">
        Tutors {{ tutors.length }}
      </li>
    </ul>
    <div class="p-3 overflow-auto" style="max-height: 75vh;">
      @if (currentTabIndex === switchUserIndex.Student) {
      <div class="kip-observe__content h-100 overflow-hidden bg-white">
        @for (student of students | slice:0:50; track trackBy($index, student)) {
        <button class="btn btn-outline-secondary mb-2 me-2 py-1" type="button" (click)="startActing(student.id)">
          {{ student.name}}</button>
        }
      </div>
      }
      @if (currentTabIndex === switchUserIndex.Tutor) {
      <div class="kip-observe__content h-100 overflow-hidden bg-white">
        @for (tutor of tutors | slice:0:50; track trackBy($index, tutor)) {
        <button class="btn btn-outline-secondary mb-2 me-2 py-1" type="button" (click)="startActing(tutor.id)"> {{
          tutor.name}}</button>
        }
      </div>
      }
    </div>
    <div class="d-flex justify-content-between pt-2 px-3 border-top">
      <button class="btn kip-btn ms-auto" type="button" (click)="close()">Close</button>
    </div>
  </div>
</ng-template>