import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'warningMaxTime'
})
export class WarningMaxTimePipe implements PipeTransform {

  transform(warningTimeMinutes?: number | null, warningTimeTutorOnly?: boolean,
    maxAllowedTimeMinutes?: number | null): string {
    const warning = warningTimeMinutes !== null && warningTimeMinutes !== undefined ? `w${warningTimeMinutes}${warningTimeTutorOnly ? 't' : ''}` : '';
    const max = maxAllowedTimeMinutes !== null && maxAllowedTimeMinutes !== undefined ? `m${maxAllowedTimeMinutes}` : '';

    return `${warning}${max}`;
  }
}
