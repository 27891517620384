import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ConsoleLog, ConsoleLogType } from '../models';

@Injectable({ providedIn: 'root' })
export class ConsoleLogService {

  readonly #consoleLog: OmitThisParameter<any>;
  readonly #consoleTrace: OmitThisParameter<any>;
  readonly #consoleError: OmitThisParameter<any>;
  readonly #consoleInfo: OmitThisParameter<any>;
  readonly #consoleDebug: OmitThisParameter<any>;
  readonly #consoleWarn: OmitThisParameter<any>;
  readonly #consoleSubject = new BehaviorSubject<ConsoleLog[]>([]);

  constructor() {

    this.#consoleWarn = console.warn.bind(console);
    console.warn = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Warn, date: new Date(), data: data }]);
      this.#consoleWarn.apply(console, data);
    };

    this.#consoleDebug = console.debug.bind(console);

    console.debug = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Debug, date: new Date(), data: data }]);
      this.#consoleDebug.apply(console, data);
    };

    this.#consoleInfo = console.info.bind(console);
    console.info = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Info, date: new Date(), data: data }]);
      this.#consoleInfo.apply(console, data);
    };

    this.#consoleError = console.error.bind(console);
    console.error = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Error, date: new Date(), data: data }]);
      this.#consoleError.apply(console, data);
    };

    this.#consoleTrace = console.trace.bind(console);
    console.trace = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Trace, date: new Date(), data: data }]);
      this.#consoleTrace.apply(console, data);
    };

    this.#consoleLog = console.log.bind(console);
    console.log = (...data: any[]) => {
      const logs = this.#consoleSubject.value;
      this.#consoleSubject.next([...logs, { type: ConsoleLogType.Log, date: new Date(), data: data }]);
      this.#consoleLog.apply(console, data);
    };
  }

  clear() {
    this.#consoleSubject.next([]);
  }

  consoleLog() {
    return this.#consoleSubject;
  }

}
