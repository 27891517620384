export enum LessonEnrolmentStage {
  Introduction = 1,
  SelectingSubjects = 2,
  SelectingSessions = 3,
  ReviewingSessions = 4,
  ReviewingTutors = 5,
  SelectingBundles = 6,
  SelectingPayment = 7,
  ProcessingPayment = 8,
  Complete = 9,
  Skipped = 10,
  DigitalEnrolment = 11 // phase 1 only for purposes of MVP
}
