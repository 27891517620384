@if (students.length === 0) {
<div>
  Rewards and Prizes System
</div>
}

<div class="row">

  <kip-points-student-list class="col-12 col-lg-6 col-xl-4 mb-3" [count]="students250.length" [students]="students250"
    [title]="'0 to 250 points'" />
  <kip-points-student-list class="col-12 col-lg-6 col-xl-4 mb-3" [count]="students500.length" [students]="students500"
    [title]="'250 to 500 points'" />
  <kip-points-student-list class="col-12 col-lg-6 col-xl-4 mb-3" [count]="students1000.length" [students]="students1000"
    [title]="'500 to 1000 points'" />

  @for (pointsData of pointsList; track pointsData) {
  <div class="col-12 col-lg-6 col-xl-4 mb-3">
    <kip-points-graphic [pointsData]="pointsData" [students]="(pointsData.students??[]).length" />
    <kip-points-student-list [students]="pointsData.students" />
  </div>
  }
</div>

@if (students.length === 0) {
<div>
  <div>
    Choose a reward from the prize box when you reach
  </div>
  <div>
    250 points, 500 points and every 500 points thereafter.
  </div>
</div>
}