import { DrillType, QuestionParametersDrills, QuestionSummary } from '../../models';

export function convertDrill(question: QuestionSummary<QuestionParametersDrills>, genericInput: string, showAnswerOnly: boolean) {

  let text = '';

  if (!showAnswerOnly) {
    text += question.parameters.values[0].toString();

    for (let index = 1, lastIndex = question.parameters.values.length; index < lastIndex; index++) {
      let sign = '';
      switch (question.parameters.type) {
        case DrillType.Addition:
          sign = '+';
          break;
        case DrillType.Subtraction:
          sign = '-';
          break;
        case DrillType.Multiplication:
          sign = 'x';
          break;
        case DrillType.Division:
          sign = '÷';
          break;
      }

      text += ` ${sign} ${question.parameters.values[index].toString()}`;
    }
  }
  const answerHtml = `<span class="kip-success-tutor-answer">${question.answers[0].values[0].toString()}</span>`;
  const replacedInput = genericInput.replace('&nbsp;', answerHtml);

  return !showAnswerOnly ? `${text} = ${replacedInput}` : replacedInput;
}
