export enum ScriptName {
  GoogleAddressPicker = 'googleAddressPicker'
}

export interface Scripts {
  name: ScriptName;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {
    name: ScriptName.GoogleAddressPicker,
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCF4ConhkIYfY1UIOzjuRUB4cCJmrtQOjo&libraries=places'
  }
];
