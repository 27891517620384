/* eslint-disable @angular-eslint/no-forward-ref, kip/no-unused-public-members,  kip/decorators-out-of-order-before-constructor */

import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kip-model-select',
  templateUrl: './model-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelSelectComponent),
      multi: true
    }
  ]

})
export class ModelSelectComponent implements ControlValueAccessor {

  value: number | null = null;

  @Input() values: readonly { id: number, name: string }[] = [];

  @Input({ required: true }) id = 'id';

  @Input({ required: true }) label = 'label';

  onChange = (_value: number) => {
    // wire up
  };
  onTouched = () => {
    // wire up
  };

  writeValue(value: number): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(_isDisabled: boolean): void {
    // Handle disabling the input if needed
  }
}
