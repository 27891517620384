import { createReducer, on } from '@ngrx/store';

import * as DeviceActions from './device.actions';

export interface DeviceState {
  readonly videoDevice: string | undefined;
  readonly audioDevice: string | undefined;
}

const initialState: DeviceState = {
  videoDevice: undefined,
  audioDevice: undefined
};

export const deviceReducer = createReducer(initialState,
  on(DeviceActions.loadDevicesAction, (_state, action): DeviceState => {
    return {
      videoDevice: action.videoDeviceId,
      audioDevice: action.audioDeviceId
    };
  }),
  on(DeviceActions.setVideoDeviceAction, (state, action): DeviceState => {
    return {
      videoDevice: action.deviceId,
      audioDevice: state.audioDevice
    };
  }),
  on(DeviceActions.setAudioDeviceAction, (state, action): DeviceState => {
    return {
      videoDevice: state.videoDevice,
      audioDevice: action.deviceId
    };
  })
);
