import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-homework-master',
  templateUrl: './homework-master.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconHomeworkMasterComponent implements IconComponent {

}
