import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-relationship-picker',
  templateUrl: './relationship-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelationshipPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly relationships = toSignal(this.#sharedService.getRelationships(), { initialValue: [] });
  readonly relationshipId = input.required<FormControl<number | undefined>>();
  readonly label = input('');
  readonly name = input('');
  readonly optional = input(false);
  readonly labelClass = input<string | undefined>(undefined);

}
