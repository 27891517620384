<kip-form-group-wrapper [formGroup]="noteForm" [showCancel]="true" [submitText]="buttonText"
  (cancelled)="cancelled.emit()" (submittedAndValidated)="submit()">
  <h2 class="fw-light mb-2">{{ action }} Note</h2>

  @if (studentNote && studentNote.lessonInfo) {
  <div class="small">
    <fa-icon [icon]="icons.time.regular" />
    <span class="ms-2">{{ studentNote.lessonInfo.date | dateTime }}</span>
    <fa-icon class="ms-2" [fixedWidth]="true" [icon]="studentNote.note.noteCategory.name | noteCategoryIcon" />
    <span class="ms-2"> {{ studentNote.note.noteCategory.name }}</span>
    <span class="ms-2">- {{ studentNote.lessonInfo.subject }}</span>
    @if (studentNote.lessonInfo.activityName) {
    <span class="ms-2">- {{
      studentNote.lessonInfo.activityName }}</span>
    }
    <span class="ms-2">- {{ studentNote.lessonInfo.internal? 'Internal': 'Parent'}}</span>
  </div>
  }

  @if (!locked) {
  <kip-form-control-select id="noteCatgory" label="Note Category" [control]="noteCategoryId"
    [values]="noteCategories" />
  }
  <kip-form-control-emoji errorMessage="Please provide a valid note description" id="emoji" label=""
    [control]="description" />
  @if (studentNote && studentNote.lessonInfo && !studentNote.lessonInfo.internal) {
  <div class="mb-3 d-flex align-items-center">
    <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
    <div class="pe-3">Notes entered here will appear in the Parent Portal. Be sure to write clean, concise
      and respectful notes
      as this will be visible to the parent.</div>
  </div>
  }
  @if (!locked) {
  <kip-form-control-checkbox id="pinned" label="Pin Note" [control]="pinned" />
  }
  <div buttons>
    @if (id.value > 0) {
    <button class="btn btn-outline-danger ms-2" type="button" (click)="delete()">
      <fa-icon [icon]="icons.delete" />
      Delete
    </button>
    }
  </div>
</kip-form-group-wrapper>