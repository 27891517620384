import {
  IconAbacusMathsComponent, IconAlphabetComponent, IconBooksReadingComponent,
  IconExpertAdditionComponent,
  IconExpertDivisionComponent, IconExpertMultiplicationComponent, IconExpertSubtractionComponent,
  IconGraphBarChartComponent, IconGraphLineChartComponent, IconLearningMathsFemaleAComponent,
  IconLearningMathsFemaleBComponent, IconLearningMathsFemaleCComponent, IconLearningMathsFemaleDComponent,
  IconLearningMathsFemaleEComponent, IconLearningMathsFemaleFComponent, IconLearningMathsMaleAComponent,
  IconLearningMathsMaleBComponent, IconLearningMathsMaleCComponent, IconLearningMathsMaleDComponent,
  IconLearningMathsMaleEComponent, IconLearningMathsMaleFComponent, IconNumeracyComponent,
  IconReadingBookComponent, IconRulerMeasurementComponent, IconTimeComponent,
  IconTriangleMeasurementComponent, IconWritingHandAComponent, IconWritingHandBComponent,
  IconWritingHandCComponent, IconWritingHandDComponent, IconWritingHandEComponent,
  IconWritingHandFComponent
} from './academia';
import {
  IconBrainSmartComponent, IconCheckCorrectComponent, IconClapHandsAComponent,
  IconClapHandsBComponent, IconClapHandsCComponent, IconClapHandsDComponent,
  IconClapHandsEComponent, IconClapHandsFComponent,
  IconCrownKingQueenComponent, IconDiamondJewelComponent, IconDiamondTrophyComponent, IconLightbulbBrightComponent,
  IconMedal1stFirstComponent, IconMedal2ndSecondComponent, IconMedal3rdThirdComponent,
  IconMedalAwardComponent, IconMedalBadgeComponent, IconPlatinumTrophyComponent, IconRubyTrophyComponent,
  IconStarBronzeComponent, IconStarGoldComponent,
  IconStarSilverComponent,
  IconThumbsUpAComponent, IconThumbsUpBComponent, IconThumbsUpCComponent, IconThumbsUpDComponent,
  IconThumbsUpEComponent, IconThumbsUpFComponent, IconTrophyAwardComponent,
  IconUnlockedLevelComponent
} from './achievement';
import {
  IconDiySet11Component, IconDiySet12Component, IconDiySet13Component, IconDiySet14Component,
  IconDiySet21Component, IconDiySet22Component, IconDiySet23Component, IconDiySet24Component,
  IconDiySet31Component, IconDiySet32Component, IconDiySet33Component, IconDiySet34Component
} from './diy';
import {
  IconBirthdayBalloonComponent, IconBirthdayCakeComponent,
  IconBirthdayRibbonComponent,
  IconCelebratePartyComponent, IconChristmasTreeComponent, IconGraduatedBoyAComponent,
  IconGraduatedBoyBComponent, IconGraduatedBoyCComponent, IconGraduatedBoyDComponent,
  IconGraduatedBoyEComponent, IconGraduatedBoyFComponent, IconGraduatedGirlAComponent,
  IconGraduatedGirlBComponent, IconGraduatedGirlCComponent, IconGraduatedGirlDComponent,
  IconGraduatedGirlEComponent, IconGraduatedGirlFComponent, IconGraduationAcademicComponent,
  IconHalloweenComponent, IconPresentGiftComponent, IconSantaClausAComponent,
  IconSantaClausBComponent, IconSantaClausCComponent, IconSantaClausDComponent,
  IconSantaClausEComponent, IconSantaClausFComponent, IconSnowmanComponent
} from './events';
import { Icon } from './icon';
import {
  IconBlastOffComponent, IconBullsEyeTargetComponent, IconClimbingSkillAComponent,
  IconClimbingSkillBComponent, IconClimbingSkillCComponent, IconClimbingSkillDComponent,
  IconClimbingSkillEComponent,
  IconClimbingSkillFComponent,
  IconComputerWhizBoyAComponent,
  IconComputerWhizBoyBComponent,
  IconComputerWhizBoyCComponent,
  IconComputerWhizBoyDComponent,
  IconComputerWhizBoyEComponent,
  IconComputerWhizBoyFComponent,
  IconComputerWhizGirlAComponent,
  IconComputerWhizGirlBComponent,
  IconComputerWhizGirlCComponent,
  IconComputerWhizGirlDComponent,
  IconComputerWhizGirlEComponent,
  IconComputerWhizGirlFComponent, IconElectricLightningComponent, IconExplosiveBangComponent,
  IconExplosiveBombComponent, IconExplosiveTntComponent, IconFinishFlagComponent,
  IconFireForceComponent,
  IconGraphWhizBoyAComponent,
  IconGraphWhizBoyBComponent,
  IconGraphWhizBoyCComponent,
  IconGraphWhizBoyDComponent,
  IconGraphWhizBoyEComponent,
  IconGraphWhizBoyFComponent,
  IconGraphWhizGirlAComponent,
  IconGraphWhizGirlBComponent,
  IconGraphWhizGirlCComponent,
  IconGraphWhizGirlDComponent,
  IconGraphWhizGirlEComponent,
  IconGraphWhizGirlFComponent,
  IconHomeworkMasterComponent, IconMagicWandComponent, IconOneHundredComponent, IconPointsBlueComponent,
  IconRacecarSpeedComponent,
  IconRunningSpeedBoyAComponent, IconRunningSpeedBoyBComponent, IconRunningSpeedBoyCComponent,
  IconRunningSpeedBoyDComponent, IconRunningSpeedBoyEComponent,
  IconRunningSpeedBoyFComponent,
  IconRunningSpeedGirlAComponent,
  IconRunningSpeedGirlBComponent,
  IconRunningSpeedGirlCComponent,
  IconRunningSpeedGirlDComponent,
  IconRunningSpeedGirlEComponent,
  IconRunningSpeedGirlFComponent,
  IconScienceWhizBoyAComponent,
  IconScienceWhizBoyBComponent,
  IconScienceWhizBoyCComponent,
  IconScienceWhizBoyDComponent,
  IconScienceWhizBoyEComponent,
  IconScienceWhizBoyFComponent,
  IconScienceWhizGirlAComponent,
  IconScienceWhizGirlBComponent,
  IconScienceWhizGirlCComponent,
  IconScienceWhizGirlDComponent,
  IconScienceWhizGirlEComponent,
  IconScienceWhizGirlFComponent,
  IconSpaceCadetBoyAComponent,
  IconSpaceCadetBoyBComponent,
  IconSpaceCadetBoyCComponent,
  IconSpaceCadetBoyDComponent,
  IconSpaceCadetBoyEComponent,
  IconSpaceCadetBoyFComponent,
  IconSpaceCadetGirlAComponent,
  IconSpaceCadetGirlBComponent,
  IconSpaceCadetGirlCComponent,
  IconSpaceCadetGirlDComponent,
  IconSpaceCadetGirlEComponent,
  IconSpaceCadetGirlFComponent, IconStopwatchTimerComponent,
  IconStrongMusclesAComponent, IconStrongMusclesBComponent,
  IconStrongMusclesCComponent, IconStrongMusclesDComponent,
  IconStrongMusclesEComponent, IconStrongMusclesFComponent,
  IconSuperheroPowerAComponent, IconSuperheroPowerBComponent,
  IconSuperheroPowerCComponent, IconSuperheroPowerDComponent,
  IconSuperheroPowerEComponent, IconSuperheroPowerFComponent,
  IconWizardMagicAComponent, IconWizardMagicBComponent,
  IconWizardMagicCComponent, IconWizardMagicDComponent,
  IconWizardMagicEComponent, IconWizardMagicFComponent
} from './performance';
import { IconPointsTradeComponent } from './points-trade.component';

export const iconsList = {
  [Icon.TradeId]: IconPointsTradeComponent,
  [Icon.AbacusMaths]: IconAbacusMathsComponent,
  [Icon.Alphabet]: IconAlphabetComponent,
  [Icon.BooksReading]: IconBooksReadingComponent,
  [Icon.DiySet11]: IconDiySet11Component,
  [Icon.DiySet12]: IconDiySet12Component,
  [Icon.DiySet13]: IconDiySet13Component,
  [Icon.DiySet14]: IconDiySet14Component,
  [Icon.DiySet21]: IconDiySet21Component,
  [Icon.DiySet22]: IconDiySet22Component,
  [Icon.DiySet23]: IconDiySet23Component,
  [Icon.DiySet24]: IconDiySet24Component,
  [Icon.DiySet31]: IconDiySet31Component,
  [Icon.DiySet32]: IconDiySet32Component,
  [Icon.DiySet33]: IconDiySet33Component,
  [Icon.DiySet34]: IconDiySet34Component,
  [Icon.ExpertAddition]: IconExpertAdditionComponent,
  [Icon.ExpertDivision]: IconExpertDivisionComponent,
  [Icon.ExpertMultiplication]: IconExpertMultiplicationComponent,
  [Icon.ExpertSubtraction]: IconExpertSubtractionComponent,
  [Icon.GraphBarChart]: IconGraphBarChartComponent,
  [Icon.GraphLineChart]: IconGraphLineChartComponent,
  [Icon.LearningMathsFemale]:
    [IconLearningMathsFemaleAComponent,
      IconLearningMathsFemaleBComponent,
      IconLearningMathsFemaleCComponent,
      IconLearningMathsFemaleDComponent,
      IconLearningMathsFemaleEComponent,
      IconLearningMathsFemaleFComponent],
  [Icon.LearningMathsMale]:
    [IconLearningMathsMaleAComponent,
      IconLearningMathsMaleBComponent,
      IconLearningMathsMaleCComponent,
      IconLearningMathsMaleDComponent,
      IconLearningMathsMaleEComponent,
      IconLearningMathsMaleFComponent],
  [Icon.Numeracy]: IconNumeracyComponent,
  [Icon.ReadingBook]: IconReadingBookComponent,
  [Icon.RulerMeasurement]: IconRulerMeasurementComponent,
  [Icon.Time]: IconTimeComponent,
  [Icon.TriangleMeasurement]: IconTriangleMeasurementComponent,
  [Icon.WritingHand]:
    [
      IconWritingHandAComponent,
      IconWritingHandBComponent,
      IconWritingHandCComponent,
      IconWritingHandDComponent,
      IconWritingHandEComponent,
      IconWritingHandFComponent
    ],
  [Icon.BrainSmart]: IconBrainSmartComponent,
  [Icon.CheckCorrect]: IconCheckCorrectComponent,
  [Icon.ClapHands]: [
    IconClapHandsAComponent,
    IconClapHandsBComponent,
    IconClapHandsCComponent,
    IconClapHandsDComponent,
    IconClapHandsEComponent,
    IconClapHandsFComponent
  ],
  [Icon.CrownKingQueen]: IconCrownKingQueenComponent,
  [Icon.DiamondJewel]: IconDiamondJewelComponent,
  [Icon.LightBulbBright]: IconLightbulbBrightComponent,
  [Icon.Medal1stFirst]: IconMedal1stFirstComponent,
  [Icon.Medal2ndSecond]: IconMedal2ndSecondComponent,
  [Icon.Medal3rdThird]: IconMedal3rdThirdComponent,
  [Icon.MedalAward]: IconMedalAwardComponent,
  [Icon.MedalBadge]: IconMedalBadgeComponent,
  [Icon.StarGold]: IconStarGoldComponent,
  [Icon.StarSilver]: IconStarSilverComponent,
  [Icon.StarBronze]: IconStarBronzeComponent,
  [Icon.ThumbsUp]:
    [IconThumbsUpAComponent,
      IconThumbsUpBComponent,
      IconThumbsUpCComponent,
      IconThumbsUpDComponent,
      IconThumbsUpEComponent,
      IconThumbsUpFComponent],
  [Icon.TrophyAward]: IconTrophyAwardComponent,
  [Icon.RubyTrophy]: IconRubyTrophyComponent,
  [Icon.PlatinumTrophy]: IconPlatinumTrophyComponent,
  [Icon.DiamondTrophy]: IconDiamondTrophyComponent,
  [Icon.UnlockedLevel]: IconUnlockedLevelComponent,
  [Icon.BirthdayBalloon]: IconBirthdayBalloonComponent,
  [Icon.BirthdayCake]: IconBirthdayCakeComponent,
  [Icon.BirthdayRibbon]: IconBirthdayRibbonComponent,
  [Icon.CelebrateParty]: IconCelebratePartyComponent,
  [Icon.ChristmasTree]: IconChristmasTreeComponent,
  [Icon.GraduatedBoy]: [
    IconGraduatedBoyAComponent,
    IconGraduatedBoyBComponent,
    IconGraduatedBoyCComponent,
    IconGraduatedBoyDComponent,
    IconGraduatedBoyEComponent,
    IconGraduatedBoyFComponent
  ],
  [Icon.GraduatedGirl]:
    [IconGraduatedGirlAComponent,
      IconGraduatedGirlBComponent,
      IconGraduatedGirlCComponent,
      IconGraduatedGirlDComponent,
      IconGraduatedGirlEComponent,
      IconGraduatedGirlFComponent],
  [Icon.GraduationAcademic]: IconGraduationAcademicComponent,
  [Icon.Halloween]: IconHalloweenComponent,
  [Icon.PresentGift]: IconPresentGiftComponent,
  [Icon.SantaClaus]:
    [IconSantaClausAComponent,
      IconSantaClausBComponent,
      IconSantaClausCComponent,
      IconSantaClausDComponent,
      IconSantaClausEComponent,
      IconSantaClausFComponent],
  [Icon.Snowman]: IconSnowmanComponent,
  [Icon.BlastOff]: IconBlastOffComponent,
  [Icon.BullseyeTarget]: IconBullsEyeTargetComponent,
  [Icon.ClimbingSkill]:
    [IconClimbingSkillAComponent,
      IconClimbingSkillBComponent,
      IconClimbingSkillCComponent,
      IconClimbingSkillDComponent,
      IconClimbingSkillEComponent,
      IconClimbingSkillFComponent],
  [Icon.ComputerWhizBoy]:
    [IconComputerWhizBoyAComponent,
      IconComputerWhizBoyBComponent,
      IconComputerWhizBoyCComponent,
      IconComputerWhizBoyDComponent,
      IconComputerWhizBoyEComponent,
      IconComputerWhizBoyFComponent],
  [Icon.ComputerWhizGirl]:
    [IconComputerWhizGirlAComponent,
      IconComputerWhizGirlBComponent,
      IconComputerWhizGirlCComponent,
      IconComputerWhizGirlDComponent,
      IconComputerWhizGirlEComponent,
      IconComputerWhizGirlFComponent],
  [Icon.ElectricLightning]: IconElectricLightningComponent,
  [Icon.ExplosiveBang]: IconExplosiveBangComponent,
  [Icon.ExplosiveBomb]: IconExplosiveBombComponent,
  [Icon.ExplosiveTnt]: IconExplosiveTntComponent,
  [Icon.FinishFlag]: IconFinishFlagComponent,
  [Icon.FireForce]: IconFireForceComponent,
  [Icon.GraphWhizBoy]:
    [IconGraphWhizBoyAComponent,
      IconGraphWhizBoyBComponent,
      IconGraphWhizBoyCComponent,
      IconGraphWhizBoyDComponent,
      IconGraphWhizBoyEComponent,
      IconGraphWhizBoyFComponent],
  [Icon.GraphWhizGirl]:
    [IconGraphWhizGirlAComponent,
      IconGraphWhizGirlBComponent,
      IconGraphWhizGirlCComponent,
      IconGraphWhizGirlDComponent,
      IconGraphWhizGirlEComponent,
      IconGraphWhizGirlFComponent],
  [Icon.HomeworkMaster]: IconHomeworkMasterComponent,
  [Icon.MagicWand]: IconMagicWandComponent,
  [Icon.OneHundred]: IconOneHundredComponent,
  [Icon.RacecarSpeed]: IconRacecarSpeedComponent,
  [Icon.RunningSpeedBoy]:
    [IconRunningSpeedBoyAComponent,
      IconRunningSpeedBoyBComponent,
      IconRunningSpeedBoyCComponent,
      IconRunningSpeedBoyDComponent,
      IconRunningSpeedBoyEComponent,
      IconRunningSpeedBoyFComponent],
  [Icon.RunningSpeedGirl]:
    [IconRunningSpeedGirlAComponent,
      IconRunningSpeedGirlBComponent,
      IconRunningSpeedGirlCComponent,
      IconRunningSpeedGirlDComponent,
      IconRunningSpeedGirlEComponent,
      IconRunningSpeedGirlFComponent],
  [Icon.ScienceWhizBoy]:
    [IconScienceWhizBoyAComponent,
      IconScienceWhizBoyBComponent,
      IconScienceWhizBoyCComponent,
      IconScienceWhizBoyDComponent,
      IconScienceWhizBoyEComponent,
      IconScienceWhizBoyFComponent],
  [Icon.ScienceWhizGirl]:
    [IconScienceWhizGirlAComponent,
      IconScienceWhizGirlBComponent,
      IconScienceWhizGirlCComponent,
      IconScienceWhizGirlDComponent,
      IconScienceWhizGirlEComponent,
      IconScienceWhizGirlFComponent],
  [Icon.SpaceCadetBoy]:
    [IconSpaceCadetBoyAComponent,
      IconSpaceCadetBoyBComponent,
      IconSpaceCadetBoyCComponent,
      IconSpaceCadetBoyDComponent,
      IconSpaceCadetBoyEComponent,
      IconSpaceCadetBoyFComponent],
  [Icon.SpaceCadetGirl]:
    [IconSpaceCadetGirlAComponent,
      IconSpaceCadetGirlBComponent,
      IconSpaceCadetGirlCComponent,
      IconSpaceCadetGirlDComponent,
      IconSpaceCadetGirlEComponent,
      IconSpaceCadetGirlFComponent],
  [Icon.StopwatchTimer]: IconStopwatchTimerComponent,
  [Icon.StrongMuscles]:
    [IconStrongMusclesAComponent,
      IconStrongMusclesBComponent,
      IconStrongMusclesCComponent,
      IconStrongMusclesDComponent,
      IconStrongMusclesEComponent,
      IconStrongMusclesFComponent],
  [Icon.SuperheroPower]:
    [IconSuperheroPowerAComponent,
      IconSuperheroPowerBComponent,
      IconSuperheroPowerCComponent,
      IconSuperheroPowerDComponent,
      IconSuperheroPowerEComponent,
      IconSuperheroPowerFComponent],
  [Icon.WizardMagic]:
    [IconWizardMagicAComponent,
      IconWizardMagicBComponent,
      IconWizardMagicCComponent,
      IconWizardMagicDComponent,
      IconWizardMagicEComponent,
      IconWizardMagicFComponent],
  [Icon.PointsBlue]: IconPointsBlueComponent
};
