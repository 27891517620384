import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'device-information-lib';
import { Icons } from 'icon-lib';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { ProfileService, Role } from 'ui-common-lib';

import {
  ToolbarAction,
  ToolbarSvg,
  WhiteboardColor,
  WhiteboardColorName,
  WhiteboardEraserTool,
  WhiteboardGraphTool,
  WhiteboardGridTool,
  WhiteboardGridType,
  WhiteboardGridTypeName,
  WhiteboardHighlighterTool,
  WhiteboardLassoTool,
  WhiteboardPenTool,
  WhiteboardShapeTool,
  WhiteboardTextTool,
  WhiteboardTool,
  WhiteboardToolbarSelection
} from '../models';
import { GraphToolService } from '../tool-dialog/services';
import { WhiteboardService } from '../whiteboard.service';

@Component({
  selector: 'kip-whiteboard-toolbar',
  templateUrl: './toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardToolbarComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #modalService = inject(NgbModal);
  readonly #deviceService = inject(DeviceDetectorService);
  readonly #whiteboardService = inject(WhiteboardService);
  readonly #profileService = inject(ProfileService);
  readonly #dialogService = inject(GraphToolService);

  #color = '';
  #tool = '';
  #selectedColor = '';
  #selectedTool = '';
  #previousTool = '';
  #closing = false;
  #resizer: ResizeObserver | undefined;
  #resizeHandler: (() => void) | undefined;
  #resizeInterval: number | undefined;
  #resizeSubject: Subject<number> | undefined;
  #currentIndex = 0;
  #maxOffset = 0;
  #toolsOffset = 0;
  #showToolNavButtons = false;
  #showColors = false;
  #toolItemHeights: number[] = [];
  #isTablet = false;
  #isMobile = false;
  readonly #toolbarItemColors = new Map();
  #isTutor = false;
  #defaultColor = '';
  #subscriptions: Subscription[] = [];

  readonly #defaultTool = 'pen';
  readonly #colorsMap: { [key: string]: WhiteboardColor } = {};
  readonly #toolsMap: { [key: string]: WhiteboardTool } = {};
  readonly #colors = [
    new WhiteboardColor(WhiteboardColorName.Black, '#000000', 'rgba(0, 0, 0, 0.3)'),
    new WhiteboardColor(WhiteboardColorName.Blue, '#0000ff', 'rgba(0, 255, 255, 0.4)'),
    new WhiteboardColor(WhiteboardColorName.Green, '#00ff00', 'rgba(0, 255, 0, 0.4)'),
    new WhiteboardColor(WhiteboardColorName.Yellow, '#ffff00', 'rgba(255, 255, 0, 0.4)'),
    new WhiteboardColor(WhiteboardColorName.Red, '#ff0000', 'rgba(255, 0, 0, 0.4)')
  ];
  readonly #tools = [
    new WhiteboardPenTool(),
    new WhiteboardHighlighterTool(),
    new WhiteboardTextTool(),
    new WhiteboardShapeTool(() => this.tool = ToolbarSvg.Lasso, new WhiteboardGraphTool(this.#dialogService)),
    new WhiteboardEraserTool(),
    new WhiteboardLassoTool(),
    new WhiteboardGridTool()
    // Currently only the cartesian plane tool has been built
    // so instead of having a new list of tools for graphing,
    // the graph tool has been added into the WhiteboardShapeTool temporarily.
    // new WhiteboardGraphTool(this.dialogService)
  ];

  manualActivityWhiteboardClosed = false;

  readonly icons = Icons;

  get colors(): WhiteboardColor[] {
    return this.#colors;
  }

  get tools(): WhiteboardTool[] {
    return this.#tools;
  }

  get closing() {
    return this.#closing;
  }

  get toolsOffset() {
    return `-${this.#toolsOffset}px`;
  }

  get showToolNavButtons() {
    return this.#showToolNavButtons;
  }

  set showColors(value: boolean) {
    this.#showColors = value;
  }

  get showColors() {
    return this.#showColors;
  }

  get currentColor() {
    return this.#selectedColor;
  }

  get isUpScrollDisabled() {
    return this.#currentIndex === 0 || !this.#currentIndex;
  }

  get isDownScrollDisabled() {
    return this.#currentIndex >= this.#maxOffset;
  }

  get hasTouch() {
    this.#isTablet = this.#deviceService.isTablet();
    this.#isMobile = this.#deviceService.isMobile();

    return this.#isTablet || this.#isMobile;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) allowClose = false;
  @Input({ required: true }) printAllEnabled = false;

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) isScrolling = false;
  @Input({ required: true }) isManual = false;
  @Input({ required: true }) toolbarActions: readonly ToolbarAction[] = [];

  @Input({ required: true })
  set color(value: string) {
    this.#color = value;
    this.selectColor(value);
  }

  get color() {
    return this.#color;
  }

  @Input({ required: true })
  set tool(value: string) {
    this.#tool = value;
    this.selectTool(value);
  }

  get tool() {
    return this.#tool;
  }

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;
  @ViewChild('kipWhiteboardToolbarContainer') kipWhiteboardToolbarContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('toolArea', { static: true }) toolArea: ElementRef<HTMLDivElement> | undefined;

  @Output() readonly selection = new EventEmitter<WhiteboardToolbarSelection>();
  @Output() readonly undo = new EventEmitter<void>();
  @Output() readonly redo = new EventEmitter<void>();
  @Output() readonly clear = new EventEmitter<void>();
  @Output() readonly print = new EventEmitter<void>();
  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly gridTypeChange = new EventEmitter<WhiteboardGridType>();
  @Output() readonly enableScrolling = new EventEmitter<void>();
  @Output() readonly disableScrolling = new EventEmitter<void>();
  @Output() readonly printAll = new EventEmitter<void>();

  constructor() {

    // Build the color and tool maps
    this.#colorsMap = this.#colors.reduce((map: { [key: string]: WhiteboardColor }, color) => {
      map[color.name] = color;
      return map;
    }, {});

    this.#toolsMap = this.#tools.reduce((map: { [key: string]: WhiteboardTool }, tool) => {
      map[tool.name] = tool;
      return map;
    }, {});
  }

  ngOnInit() {
    this.#isTutor = !!this.#profileService.currentUserProfile?.roles.includes(Role.Tutor);
    this.#toolbarItemColors.set(ToolbarSvg.Pen, this.#isTutor ? WhiteboardColorName.Black : WhiteboardColorName.Blue);
    this.#toolbarItemColors.set(ToolbarSvg.Highlighter, this.#isTutor ? WhiteboardColorName.Yellow : WhiteboardColorName.Green);
    this.#defaultColor = this.#isTutor ? WhiteboardColorName.Black : WhiteboardColorName.Blue;

    this.#subscriptions.push(this.#dialogService.whiteboardGraphOptionsSubmitted.subscribe(_dialogOptions => {
      // if the graph is being created switch to the selection tool so the tutor doesn't click on the map
      // to move the graph and open the modal instead.
      setTimeout(() => {
        for (const tool of this.#tools) {
          tool.selected = tool.name === ToolbarSvg.Lasso;
        }
        this.#selectedTool = ToolbarSvg.Lasso;
        this.#emitSelection();
      }, 200);
    }));

    if (this.hasTouch && this.isManual) {
      this.manualActivityWhiteboardClosed = false;
      this.#whiteboardService.onOpenToolbar();
      this.enableScrolling.emit();
    }

    // Apply the default color and tool
    if (!this.#selectedColor) {
      this.selectColor(this.#defaultColor);
    }

    if (!this.#selectedTool || !this.#previousTool) {
      this.selectTool(this.#defaultTool);
    }
  }

  ngAfterViewInit() {
    if (this.kipWhiteboardToolbarContainer) {
      const parent: HTMLDivElement = this.kipWhiteboardToolbarContainer.nativeElement.closest('.kip-whiteboard-container')!;
      if (window.getComputedStyle(parent).display === 'none') {
        this.#whiteboardService.onCloseToolbar();
      } else {
        this.#whiteboardService.onOpenToolbar();
      }
    }

    if (this.toolArea) {
      const container = this.toolArea.nativeElement.parentElement;
      if (container) {
        const marginTop = Number.parseInt(window.getComputedStyle(container).marginTop, 10);
        this.#resizeSubject = new Subject<number>();
        this.#subscriptions.push(
          this.#resizeSubject
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(height => this.#toolsResized(height)));
        this.#resizeHandler = () => {
          if (this.toolArea && this.#resizeSubject && container.parentElement && container.nextSibling) {
            this.#resizeSubject.next(container.parentElement.offsetHeight - (container.nextSibling as HTMLElement).offsetHeight - marginTop);
          }
        };
        if (ResizeObserver) {
          this.#resizer = new ResizeObserver(this.#resizeHandler);
          if (container.parentElement) {
            this.#resizer.observe(container.parentElement);
          }
          window.setTimeout(this.#resizeHandler, 500);
        } else {
          window.addEventListener('resize', this.#resizeHandler);
          this.#resizeInterval = window.setInterval(this.#resizeHandler, 500);
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.#resizer && this.toolArea) {
      this.#resizer.unobserve(this.toolArea.nativeElement);
    }

    if (this.#resizeHandler) {
      window.removeEventListener('resize', this.#resizeHandler);
    }

    if (this.#resizeInterval) {
      window.clearInterval(this.#resizeInterval);
    }

    if (this.#resizeSubject) {
      this.#resizeSubject.unsubscribe();
      this.#resizeSubject = undefined;
    }

    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggleScrolling() {
    if (!this.isScrolling) {
      this.enableScrolling.emit();
    } else {
      this.disableScrolling.emit();
    }
  }

  initiateClosing() {
    if (this.allowClose) {
      this.#whiteboardService.onCloseToolbar();
      this.#closing = true;
      window.setTimeout(() => {
        this.tool = this.#previousTool ?? this.#defaultTool;
        this.close.emit();
        this.#closing = false;
      }, 500);
    } else {
      if (this.manualActivityWhiteboardClosed) {
        this.#whiteboardService.onOpenToolbar();
      } else {
        this.#whiteboardService.onCloseToolbar();
      }
      this.manualActivityWhiteboardClosed = !this.manualActivityWhiteboardClosed;
      this.tool = this.#previousTool ?? this.#defaultTool;
    }

    if (this.manualActivityWhiteboardClosed) {
      this.enableScrolling.emit();
    } else {
      this.disableScrolling.emit();
    }
  }

  selectColor(name: string) {
    let color: WhiteboardColor = this.#colorsMap[name];
    const current: WhiteboardColor = this.#colorsMap[this.#selectedColor];

    // Compare to the current selection and short-circuit if not changed
    if (current && current.name === color.name) {
      return;
    }

    // Clear the current selection
    if (current) {
      current.selected = false;
    }

    // The setup the new selection apply a default if required
    if (!color) {
      color = this.#colorsMap[this.#defaultColor];
    }

    color.selected = true;

    if (this.#selectedTool === ToolbarSvg.Pen || this.#selectedTool === ToolbarSvg.Highlighter) {
      this.#toolbarItemColors.set(this.#selectedTool, color.name);
    }

    this.#selectedColor = color.name;

    this.#emitSelection();
  }

  selectTool(name: string) {
    this.#showColors = false;
    let tool: WhiteboardTool = this.#toolsMap[name];
    const current: WhiteboardTool = this.#toolsMap[this.#selectedTool];

    // Compare to the current selection and show options
    if (current && current.name === tool.name && current.options.length > 0) {
      current.showOptions = true;
    }

    if (tool.name === ToolbarSvg.Pen || tool.name === ToolbarSvg.Highlighter) {
      this.#selectedColor = this.#toolbarItemColors.get(tool.name);
    }

    // Clear the current selection and hide the options
    if (current) {
      current.selected = false;

      if (current.options.length > 0) {
        current.showOptions = false;
      }

    }

    // Resolve the state of the new selection
    if (!tool) {
      tool = this.#toolsMap[this.#defaultTool];
    }

    tool.selected = true;

    this.#selectedTool = tool.name;
    this.#previousTool = tool.name;

    // Disable scroll mode when a tool is selected
    this.disableScrolling.emit();

    // If there are options, display them
    if (tool.options.length > 0) {
      tool.showOptions = true;
    }

    this.#emitSelection();
  }

  selectToolOption(toolName: ToolbarSvg, optionName: string) {
    const tool: WhiteboardTool = this.#toolsMap[toolName];

    if (toolName === ToolbarSvg.Grid) {
      let gridType = WhiteboardGridType.Blank;
      switch (optionName) {
        case WhiteboardGridTypeName.Small:
          gridType = WhiteboardGridType.Small;
          break;
        case WhiteboardGridTypeName.Large:
          gridType = WhiteboardGridType.Large;
          break;
        case WhiteboardGridTypeName.Horizontal:
          gridType = WhiteboardGridType.Horizontal;
          break;
      }
      this.gridTypeChange.emit(gridType);
    }

    if (tool) {
      tool.selectedOption = optionName;

      this.#emitSelection();
    }

    tool.showOptions = false;
  }

  toolsScrollDown() {
    if (this.#currentIndex < this.#maxOffset) {
      this.#currentIndex++;
      this.#calculateOffset();
    }
  }

  toolsScrollUp() {
    if (this.#currentIndex > 0) {
      this.#currentIndex--;
      this.#calculateOffset();
    }
  }

  openModal() {
    this.#modalService.open(this.modalContent, { size: 'lg', centered: true, modalDialogClass: 'kip-modal-themed theme-aurora bg-none' });
  }

  closeModal() {
    this.#modalService.dismissAll();
  }

  clearWhiteboard() {
    this.clear.emit();
    this.#modalService.dismissAll();
  }

  printItem() {
    if (this.printAllEnabled) {
      this.printAll.emit();
    } else {
      this.print.emit();
    }
  }

  #toolsResized(height: number) {
    if (this.toolArea) {
      const element = this.toolArea.nativeElement;
      const children = element.children;
      if (children.length > 0) {
        if (height >= (children[0] as HTMLElement).offsetHeight) {
          this.#showToolNavButtons = false;
          this.#currentIndex = 0;
          this.#toolsOffset = 0;
        } else {
          this.#showToolNavButtons = true;
          window.setTimeout(() => {
            this.#calculateMaxOffset(element);
          }, 200);
        }
        this.#changeDetectorRef.markForCheck();
      }
    }
  }

  #calculateMaxOffset(element: any) {
    this.#toolItemHeights = [...element.querySelectorAll('.kip-item')].map(x => this.#getElementHeight(x));
    const availableHeight = this.#calculateAvailableHeight(element);
    let height = 0;
    for (let i = this.#toolItemHeights.length - 1; i >= 0; i--) {
      height += this.#toolItemHeights[i];
      if (height >= availableHeight) {
        this.#maxOffset = i + 1;
        break;
      }
    }
    if (this.#currentIndex > this.#maxOffset) {
      this.#currentIndex = this.#maxOffset;
    }
    this.#calculateOffset();
    this.#changeDetectorRef.markForCheck();
  }

  #calculateAvailableHeight(element: any) {
    const parent = element.parentElement;
    const navHeight = this.#getElementHeight(element.previousSibling) + this.#getElementHeight(element.nextSibling);
    const toggleHeight = this.#getElementHeight(parent.nextSibling);
    const marginHeight = Number.parseInt(window.getComputedStyle(parent).marginTop, 10);
    const containerHeight = this.#getElementHeight(parent.parentElement);
    return containerHeight - navHeight - toggleHeight - marginHeight;
  }

  #calculateOffset() {
    this.#toolsOffset = 0;
    for (let i = 0; i < this.#currentIndex; i++) {
      this.#toolsOffset += this.#toolItemHeights[i];
    }
  }

  #getElementHeight(element: any): number {
    const computed = window.getComputedStyle(element);
    return element.offsetHeight as number + Number.parseInt(computed.marginTop, 10) + Number.parseInt(computed.marginBottom, 10);
  }

  #emitSelection() {
    const color = this.#colorsMap[this.#selectedColor];
    const tool = this.#toolsMap[this.#selectedTool];
    // Only emit if a color and tool are selected
    if (color && tool) {
      const selection = new WhiteboardToolbarSelection(color, tool);
      this.selection.emit(selection);
    }
  }
}
