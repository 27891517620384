import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Icons } from 'icon-lib';

import { CardPageSize } from '../models';

@Component({
  selector: 'kip-card-paged',
  templateUrl: './card-paged.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPagedComponent {

  readonly icons = Icons;
  readonly firstPage = 1;

  _pageNumber = this.firstPage;

  get numberOfPages() {
    return Math.ceil(this.items.length / this.pageSize);
  }

  get pageNumber() {
    return this._pageNumber;
  }

  get start() {
    return (this.pageNumber - 1) * this.pageSize;
  }

  get end() {
    return (this.pageNumber - 1) * this.pageSize + this.pageSize;
  }

  get isPreviousDisabled() {
    return this.pageNumber === this.firstPage;
  }

  get isNextDisabled() {
    return this.pageNumber === this.numberOfPages;
  }

  @Input({ required: true }) pageSize = CardPageSize.Five;

  @Input({ required: true }) items: readonly any[] = [];

  @ContentChild(TemplateRef, { static: true }) templateRef: any;

  nextPage() {
    const newPageNumber = this.pageNumber + 1;
    this._pageNumber = newPageNumber < this.numberOfPages ?
      newPageNumber : this.numberOfPages;
  }

  previousPage() {
    const newPageNumber = this.pageNumber - 1;
    this._pageNumber = newPageNumber || this.firstPage;
  }
}
