import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-running-speed-girl-f',
  templateUrl: './running-speed-girl-f.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconRunningSpeedGirlFComponent implements IconComponent {

}
