import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';

import { AwardsFilteredListComponent } from './awards-filtered-list/awards-filtered-list.component';
import { AwardsGridComponent } from './awards-grid/awards-grid.component';
import { AwardsGroupIconsComponent } from './awards-group/awards-group-icons/awards-group-icons.component';
import { AwardsGroupListComponent } from './awards-group/awards-group-list/awards-group-list.component';
import { AwardsGroupOrganisationComponent } from './awards-group/awards-group-organisation/awards-group-organisation.component';
import { AwardsListComponent } from './awards-list/awards-list.component';
import { AwardsMessageComponent } from './awards-message/awards-message.component';
import { CertificateNodeComponent } from './certificate-tree/certificate-node/certificate-node.component';
import { CertificateTreeComponent } from './certificate-tree/certificate-tree.component';
import {
  IconDiySet11Component, IconDiySet12Component, IconDiySet13Component, IconDiySet14Component,
  IconDiySet21Component, IconDiySet22Component, IconDiySet23Component, IconDiySet24Component,
  IconDiySet31Component, IconDiySet32Component, IconDiySet33Component, IconDiySet34Component
} from './icons';
import { IconAbacusMathsComponent } from './icons/academia/abacus-maths.component';
import { IconAlphabetComponent } from './icons/academia/alphabet.component';
import { IconBooksReadingComponent } from './icons/academia/books-reading.component';
import { IconExpertAdditionComponent } from './icons/academia/expert-addition.component';
import { IconExpertDivisionComponent } from './icons/academia/expert-division.component';
import { IconExpertMultiplicationComponent } from './icons/academia/expert-multiplication.component';
import { IconExpertSubtractionComponent } from './icons/academia/expert-subtraction.component';
import { IconGraphBarChartComponent } from './icons/academia/graph-bar-chart.component';
import { IconGraphLineChartComponent } from './icons/academia/graph-line-chart.component';
import { IconLearningMathsFemaleAComponent } from './icons/academia/learning-maths-female-a.component';
import { IconLearningMathsFemaleBComponent } from './icons/academia/learning-maths-female-b.component';
import { IconLearningMathsFemaleCComponent } from './icons/academia/learning-maths-female-c.component';
import { IconLearningMathsFemaleDComponent } from './icons/academia/learning-maths-female-d.component';
import { IconLearningMathsFemaleEComponent } from './icons/academia/learning-maths-female-e.component';
import { IconLearningMathsFemaleFComponent } from './icons/academia/learning-maths-female-f.component';
import { IconLearningMathsMaleAComponent } from './icons/academia/learning-maths-male-a.component';
import { IconLearningMathsMaleBComponent } from './icons/academia/learning-maths-male-b.component';
import { IconLearningMathsMaleCComponent } from './icons/academia/learning-maths-male-c.component';
import { IconLearningMathsMaleDComponent } from './icons/academia/learning-maths-male-d.component';
import { IconLearningMathsMaleEComponent } from './icons/academia/learning-maths-male-e.component';
import { IconLearningMathsMaleFComponent } from './icons/academia/learning-maths-male-f.component';
import { IconNumeracyComponent } from './icons/academia/numeracy.component';
import { IconReadingBookComponent } from './icons/academia/reading-book.component';
import { IconRulerMeasurementComponent } from './icons/academia/ruler-measurement.component';
import { IconTimeComponent } from './icons/academia/time.component';
import { IconTriangleMeasurementComponent } from './icons/academia/triangle-measurement.component';
import { IconWritingHandAComponent } from './icons/academia/writing-hand-a.component';
import { IconWritingHandBComponent } from './icons/academia/writing-hand-b.component';
import { IconWritingHandCComponent } from './icons/academia/writing-hand-c.component';
import { IconWritingHandDComponent } from './icons/academia/writing-hand-d.component';
import { IconWritingHandEComponent } from './icons/academia/writing-hand-e.component';
import { IconWritingHandFComponent } from './icons/academia/writing-hand-f.component';
import { IconBrainSmartComponent } from './icons/achievement/brain-smart.component';
import { IconCheckCorrectComponent } from './icons/achievement/check-correct.component';
import { IconClapHandsAComponent } from './icons/achievement/clap-hands-a.component';
import { IconClapHandsBComponent } from './icons/achievement/clap-hands-b.component';
import { IconClapHandsCComponent } from './icons/achievement/clap-hands-c.component';
import { IconClapHandsDComponent } from './icons/achievement/clap-hands-d.component';
import { IconClapHandsEComponent } from './icons/achievement/clap-hands-e.component';
import { IconClapHandsFComponent } from './icons/achievement/clap-hands-f.component';
import { IconCrownKingQueenComponent } from './icons/achievement/crown-king-queen.component';
import { IconDiamondJewelComponent } from './icons/achievement/diamond-jewel.component';
import { IconDiamondTrophyComponent } from './icons/achievement/diamond-trophy.component';
import { IconLightbulbBrightComponent } from './icons/achievement/lightbulb-bright.component';
import { IconMedal1stFirstComponent } from './icons/achievement/medal-1st-first.component';
import { IconMedal2ndSecondComponent } from './icons/achievement/medal-2nd-second.component';
import { IconMedal3rdThirdComponent } from './icons/achievement/medal-3rd-third.component';
import { IconMedalAwardComponent } from './icons/achievement/medal-award.component';
import { IconMedalBadgeComponent } from './icons/achievement/medal-badge.component';
import { IconPlatinumTrophyComponent } from './icons/achievement/platinum-trophy.component';
import { IconRubyTrophyComponent } from './icons/achievement/ruby-trophy.component';
import { IconStarBronzeComponent } from './icons/achievement/star-bronze.component';
import { IconStarGoldComponent } from './icons/achievement/star-gold.component';
import { IconStarSilverComponent } from './icons/achievement/star-silver.component';
import { IconThumbsUpAComponent } from './icons/achievement/thumbs-up-a.component';
import { IconThumbsUpBComponent } from './icons/achievement/thumbs-up-b.component';
import { IconThumbsUpCComponent } from './icons/achievement/thumbs-up-c.component';
import { IconThumbsUpDComponent } from './icons/achievement/thumbs-up-d.component';
import { IconThumbsUpEComponent } from './icons/achievement/thumbs-up-e.component';
import { IconThumbsUpFComponent } from './icons/achievement/thumbs-up-f.component';
import { IconTrophyAwardComponent } from './icons/achievement/trophy-award.component';
import { IconUnlockedLevelComponent } from './icons/achievement/unlocked-level.component';
import { IconBirthdayBalloonComponent } from './icons/events/birthday-balloon.component';
import { IconBirthdayCakeComponent } from './icons/events/birthday-cake.component';
import { IconBirthdayRibbonComponent } from './icons/events/birthday-ribbon.component';
import { IconCelebratePartyComponent } from './icons/events/celebrate-party.component';
import { IconChristmasTreeComponent } from './icons/events/christmas-tree.component';
import { IconGraduatedBoyAComponent } from './icons/events/graduated-boy-a.component';
import { IconGraduatedBoyBComponent } from './icons/events/graduated-boy-b.component';
import { IconGraduatedBoyCComponent } from './icons/events/graduated-boy-c.component';
import { IconGraduatedBoyDComponent } from './icons/events/graduated-boy-d.component';
import { IconGraduatedBoyEComponent } from './icons/events/graduated-boy-e.component';
import { IconGraduatedBoyFComponent } from './icons/events/graduated-boy-f.component';
import { IconGraduatedGirlAComponent } from './icons/events/graduated-girl-a.component';
import { IconGraduatedGirlBComponent } from './icons/events/graduated-girl-b.component';
import { IconGraduatedGirlCComponent } from './icons/events/graduated-girl-c.component';
import { IconGraduatedGirlDComponent } from './icons/events/graduated-girl-d.component';
import { IconGraduatedGirlEComponent } from './icons/events/graduated-girl-e.component';
import { IconGraduatedGirlFComponent } from './icons/events/graduated-girl-f.component';
import { IconGraduationAcademicComponent } from './icons/events/graduation-academic.component';
import { IconHalloweenComponent } from './icons/events/halloween.component';
import { IconPresentGiftComponent } from './icons/events/present-gift.component';
import { IconSantaClausAComponent } from './icons/events/santa-claus-a.component';
import { IconSantaClausBComponent } from './icons/events/santa-claus-b.component';
import { IconSantaClausCComponent } from './icons/events/santa-claus-c.component';
import { IconSantaClausDComponent } from './icons/events/santa-claus-d.component';
import { IconSantaClausEComponent } from './icons/events/santa-claus-e.component';
import { IconSantaClausFComponent } from './icons/events/santa-claus-f.component';
import { IconSnowmanComponent } from './icons/events/snowman.component';
import { IconSwitchComponent } from './icons/icon-switch.component';
import { IconBlastOffComponent } from './icons/performance/blast-off.component';
import { IconBullsEyeTargetComponent } from './icons/performance/bullseye-target.component';
import { IconClimbingSkillAComponent } from './icons/performance/climbing-skill-a.component';
import { IconClimbingSkillBComponent } from './icons/performance/climbing-skill-b.component';
import { IconClimbingSkillCComponent } from './icons/performance/climbing-skill-c.component';
import { IconClimbingSkillDComponent } from './icons/performance/climbing-skill-d.component';
import { IconClimbingSkillEComponent } from './icons/performance/climbing-skill-e.component';
import { IconClimbingSkillFComponent } from './icons/performance/climbing-skill-f.component';
import { IconComputerWhizBoyAComponent } from './icons/performance/computer-whiz-boy-a.component';
import { IconComputerWhizBoyBComponent } from './icons/performance/computer-whiz-boy-b.component';
import { IconComputerWhizBoyCComponent } from './icons/performance/computer-whiz-boy-c.component';
import { IconComputerWhizBoyDComponent } from './icons/performance/computer-whiz-boy-d.component';
import { IconComputerWhizBoyEComponent } from './icons/performance/computer-whiz-boy-e.component';
import { IconComputerWhizBoyFComponent } from './icons/performance/computer-whiz-boy-f.component';
import { IconComputerWhizGirlAComponent } from './icons/performance/computer-whiz-girl-a.component';
import { IconComputerWhizGirlBComponent } from './icons/performance/computer-whiz-girl-b.component';
import { IconComputerWhizGirlCComponent } from './icons/performance/computer-whiz-girl-c.component';
import { IconComputerWhizGirlDComponent } from './icons/performance/computer-whiz-girl-d.component';
import { IconComputerWhizGirlEComponent } from './icons/performance/computer-whiz-girl-e.component';
import { IconComputerWhizGirlFComponent } from './icons/performance/computer-whiz-girl-f.component';
import { IconElectricLightningComponent } from './icons/performance/electric-lightning.component';
import { IconExplosiveBangComponent } from './icons/performance/explosive-bang.component';
import { IconExplosiveBombComponent } from './icons/performance/explosive-bomb.component';
import { IconExplosiveTntComponent } from './icons/performance/explosive-tnt.component';
import { IconFinishFlagComponent } from './icons/performance/finish-flag.component';
import { IconFireForceComponent } from './icons/performance/fire-force.component';
import { IconGraphWhizBoyAComponent } from './icons/performance/graph-whiz-boy-a.component';
import { IconGraphWhizBoyBComponent } from './icons/performance/graph-whiz-boy-b.component';
import { IconGraphWhizBoyCComponent } from './icons/performance/graph-whiz-boy-c.component';
import { IconGraphWhizBoyDComponent } from './icons/performance/graph-whiz-boy-d.component';
import { IconGraphWhizBoyEComponent } from './icons/performance/graph-whiz-boy-e.component';
import { IconGraphWhizBoyFComponent } from './icons/performance/graph-whiz-boy-f.component';
import { IconGraphWhizGirlAComponent } from './icons/performance/graph-whiz-girl-a.component';
import { IconGraphWhizGirlBComponent } from './icons/performance/graph-whiz-girl-b.component';
import { IconGraphWhizGirlCComponent } from './icons/performance/graph-whiz-girl-c.component';
import { IconGraphWhizGirlDComponent } from './icons/performance/graph-whiz-girl-d.component';
import { IconGraphWhizGirlEComponent } from './icons/performance/graph-whiz-girl-e.component';
import { IconGraphWhizGirlFComponent } from './icons/performance/graph-whiz-girl-f.component';
import { IconHomeworkMasterComponent } from './icons/performance/homework-master.component';
import { IconMagicWandComponent } from './icons/performance/magic-wand.component';
import { IconOneHundredComponent } from './icons/performance/one-hundred.component';
import { IconPointsBlueComponent } from './icons/performance/points-blue.component';
import { IconRacecarSpeedComponent } from './icons/performance/racecar-speed.component';
import { IconRunningSpeedBoyAComponent } from './icons/performance/running-speed-boy-a.component';
import { IconRunningSpeedBoyBComponent } from './icons/performance/running-speed-boy-b.component';
import { IconRunningSpeedBoyCComponent } from './icons/performance/running-speed-boy-c.component';
import { IconRunningSpeedBoyDComponent } from './icons/performance/running-speed-boy-d.component';
import { IconRunningSpeedBoyEComponent } from './icons/performance/running-speed-boy-e.component';
import { IconRunningSpeedBoyFComponent } from './icons/performance/running-speed-boy-f.component';
import { IconRunningSpeedGirlAComponent } from './icons/performance/running-speed-girl-a.component';
import { IconRunningSpeedGirlBComponent } from './icons/performance/running-speed-girl-b.component';
import { IconRunningSpeedGirlCComponent } from './icons/performance/running-speed-girl-c.component';
import { IconRunningSpeedGirlDComponent } from './icons/performance/running-speed-girl-d.component';
import { IconRunningSpeedGirlEComponent } from './icons/performance/running-speed-girl-e.component';
import { IconRunningSpeedGirlFComponent } from './icons/performance/running-speed-girl-f.component';
import { IconScienceWhizBoyAComponent } from './icons/performance/science-whiz-boy-a.component';
import { IconScienceWhizBoyBComponent } from './icons/performance/science-whiz-boy-b.component';
import { IconScienceWhizBoyCComponent } from './icons/performance/science-whiz-boy-c.component';
import { IconScienceWhizBoyDComponent } from './icons/performance/science-whiz-boy-d.component';
import { IconScienceWhizBoyEComponent } from './icons/performance/science-whiz-boy-e.component';
import { IconScienceWhizBoyFComponent } from './icons/performance/science-whiz-boy-f.component';
import { IconScienceWhizGirlAComponent } from './icons/performance/science-whiz-girl-a.component';
import { IconScienceWhizGirlBComponent } from './icons/performance/science-whiz-girl-b.component';
import { IconScienceWhizGirlCComponent } from './icons/performance/science-whiz-girl-c.component';
import { IconScienceWhizGirlDComponent } from './icons/performance/science-whiz-girl-d.component';
import { IconScienceWhizGirlEComponent } from './icons/performance/science-whiz-girl-e.component';
import { IconScienceWhizGirlFComponent } from './icons/performance/science-whiz-girl-f.component';
import { IconSpaceCadetBoyAComponent } from './icons/performance/space-cadet-boy-a.component';
import { IconSpaceCadetBoyBComponent } from './icons/performance/space-cadet-boy-b.component';
import { IconSpaceCadetBoyCComponent } from './icons/performance/space-cadet-boy-c.component';
import { IconSpaceCadetBoyDComponent } from './icons/performance/space-cadet-boy-d.component';
import { IconSpaceCadetBoyEComponent } from './icons/performance/space-cadet-boy-e.component';
import { IconSpaceCadetBoyFComponent } from './icons/performance/space-cadet-boy-f.component';
import { IconSpaceCadetGirlAComponent } from './icons/performance/space-cadet-girl-a.component';
import { IconSpaceCadetGirlBComponent } from './icons/performance/space-cadet-girl-b.component';
import { IconSpaceCadetGirlCComponent } from './icons/performance/space-cadet-girl-c.component';
import { IconSpaceCadetGirlDComponent } from './icons/performance/space-cadet-girl-d.component';
import { IconSpaceCadetGirlEComponent } from './icons/performance/space-cadet-girl-e.component';
import { IconSpaceCadetGirlFComponent } from './icons/performance/space-cadet-girl-f.component';
import { IconStopwatchTimerComponent } from './icons/performance/stopwatch-timer.component';
import { IconStrongMusclesAComponent } from './icons/performance/strong-muscles-a.component';
import { IconStrongMusclesBComponent } from './icons/performance/strong-muscles-b.component';
import { IconStrongMusclesCComponent } from './icons/performance/strong-muscles-c.component';
import { IconStrongMusclesDComponent } from './icons/performance/strong-muscles-d.component';
import { IconStrongMusclesEComponent } from './icons/performance/strong-muscles-e.component';
import { IconStrongMusclesFComponent } from './icons/performance/strong-muscles-f.component';
import { IconSuperheroPowerAComponent } from './icons/performance/superhero-power-a.component';
import { IconSuperheroPowerBComponent } from './icons/performance/superhero-power-b.component';
import { IconSuperheroPowerCComponent } from './icons/performance/superhero-power-c.component';
import { IconSuperheroPowerDComponent } from './icons/performance/superhero-power-d.component';
import { IconSuperheroPowerEComponent } from './icons/performance/superhero-power-e.component';
import { IconSuperheroPowerFComponent } from './icons/performance/superhero-power-f.component';
import { IconWizardMagicAComponent } from './icons/performance/wizard-magic-a.component';
import { IconWizardMagicBComponent } from './icons/performance/wizard-magic-b.component';
import { IconWizardMagicCComponent } from './icons/performance/wizard-magic-c.component';
import { IconWizardMagicDComponent } from './icons/performance/wizard-magic-d.component';
import { IconWizardMagicEComponent } from './icons/performance/wizard-magic-e.component';
import { IconWizardMagicFComponent } from './icons/performance/wizard-magic-f.component';
import { IconPointsTradeComponent } from './icons/points-trade.component';
import { PointsGraphicComponent } from './points-level/points-graphic/points-graphic.component';
import { PointsIndicatorComponent } from './points-level/points-indicator/points-indicator.component';
import { PointsListComponent } from './points-level/points-list/points-list.component';
import { PointsStudentListComponent } from './points-level/points-student-list/points-student-list.component';
import { SkinTonePickerComponent } from './skin-tone-picker/skin-tone-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconLibModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule
  ],
  declarations: [
    AwardsFilteredListComponent,
    AwardsGridComponent,
    AwardsGroupIconsComponent,
    AwardsGroupListComponent,
    AwardsGroupOrganisationComponent,
    AwardsListComponent,
    AwardsMessageComponent,
    CertificateNodeComponent,
    CertificateTreeComponent,
    IconAbacusMathsComponent,
    IconAlphabetComponent,
    IconBirthdayBalloonComponent,
    IconBirthdayCakeComponent,
    IconBirthdayRibbonComponent,
    IconBlastOffComponent,
    IconBooksReadingComponent,
    IconBrainSmartComponent,
    IconBullsEyeTargetComponent,
    IconCelebratePartyComponent,
    IconCheckCorrectComponent,
    IconChristmasTreeComponent,
    IconClapHandsAComponent,
    IconClapHandsBComponent,
    IconClapHandsCComponent,
    IconClapHandsDComponent,
    IconClapHandsEComponent,
    IconClapHandsFComponent,
    IconClimbingSkillAComponent,
    IconClimbingSkillBComponent,
    IconClimbingSkillCComponent,
    IconClimbingSkillDComponent,
    IconClimbingSkillEComponent,
    IconClimbingSkillFComponent,
    IconComputerWhizBoyAComponent,
    IconComputerWhizBoyBComponent,
    IconComputerWhizBoyCComponent,
    IconComputerWhizBoyDComponent,
    IconComputerWhizBoyEComponent,
    IconComputerWhizBoyFComponent,
    IconComputerWhizGirlAComponent,
    IconComputerWhizGirlBComponent,
    IconComputerWhizGirlCComponent,
    IconComputerWhizGirlDComponent,
    IconComputerWhizGirlEComponent,
    IconComputerWhizGirlFComponent,
    IconCrownKingQueenComponent,
    IconDiamondJewelComponent,
    IconDiamondTrophyComponent,
    IconDiySet11Component,
    IconDiySet12Component,
    IconDiySet13Component,
    IconDiySet14Component,
    IconDiySet21Component,
    IconDiySet22Component,
    IconDiySet23Component,
    IconDiySet24Component,
    IconDiySet31Component,
    IconDiySet32Component,
    IconDiySet33Component,
    IconDiySet34Component,
    IconElectricLightningComponent,
    IconExpertAdditionComponent,
    IconExpertDivisionComponent,
    IconExpertMultiplicationComponent,
    IconExpertSubtractionComponent,
    IconExplosiveBangComponent,
    IconExplosiveBombComponent,
    IconExplosiveTntComponent,
    IconFinishFlagComponent,
    IconFireForceComponent,
    IconGraduatedBoyAComponent,
    IconGraduatedBoyBComponent,
    IconGraduatedBoyCComponent,
    IconGraduatedBoyDComponent,
    IconGraduatedBoyEComponent,
    IconGraduatedBoyFComponent,
    IconGraduatedGirlAComponent,
    IconGraduatedGirlBComponent,
    IconGraduatedGirlCComponent,
    IconGraduatedGirlDComponent,
    IconGraduatedGirlEComponent,
    IconGraduatedGirlFComponent,
    IconGraduationAcademicComponent,
    IconGraphBarChartComponent,
    IconGraphLineChartComponent,
    IconGraphWhizBoyAComponent,
    IconGraphWhizBoyBComponent,
    IconGraphWhizBoyCComponent,
    IconGraphWhizBoyDComponent,
    IconGraphWhizBoyEComponent,
    IconGraphWhizBoyFComponent,
    IconGraphWhizGirlAComponent,
    IconGraphWhizGirlBComponent,
    IconGraphWhizGirlCComponent,
    IconGraphWhizGirlDComponent,
    IconGraphWhizGirlEComponent,
    IconGraphWhizGirlFComponent,
    IconHalloweenComponent,
    IconHomeworkMasterComponent,
    IconLearningMathsFemaleAComponent,
    IconLearningMathsFemaleBComponent,
    IconLearningMathsFemaleCComponent,
    IconLearningMathsFemaleDComponent,
    IconLearningMathsFemaleEComponent,
    IconLearningMathsFemaleFComponent,
    IconLearningMathsMaleAComponent,
    IconLearningMathsMaleBComponent,
    IconLearningMathsMaleCComponent,
    IconLearningMathsMaleDComponent,
    IconLearningMathsMaleEComponent,
    IconLearningMathsMaleFComponent,
    IconLightbulbBrightComponent,
    IconMagicWandComponent,
    IconMedal1stFirstComponent,
    IconMedal2ndSecondComponent,
    IconMedal3rdThirdComponent,
    IconMedalAwardComponent,
    IconMedalBadgeComponent,
    IconNumeracyComponent,
    IconOneHundredComponent,
    IconPlatinumTrophyComponent,
    IconPointsBlueComponent,
    IconPointsTradeComponent,
    IconPresentGiftComponent,
    IconRacecarSpeedComponent,
    IconReadingBookComponent,
    IconRubyTrophyComponent,
    IconRulerMeasurementComponent,
    IconRunningSpeedBoyAComponent,
    IconRunningSpeedBoyBComponent,
    IconRunningSpeedBoyCComponent,
    IconRunningSpeedBoyDComponent,
    IconRunningSpeedBoyEComponent,
    IconRunningSpeedBoyFComponent,
    IconRunningSpeedGirlAComponent,
    IconRunningSpeedGirlBComponent,
    IconRunningSpeedGirlCComponent,
    IconRunningSpeedGirlDComponent,
    IconRunningSpeedGirlEComponent,
    IconRunningSpeedGirlFComponent,
    IconSantaClausAComponent,
    IconSantaClausBComponent,
    IconSantaClausCComponent,
    IconSantaClausDComponent,
    IconSantaClausEComponent,
    IconSantaClausFComponent,
    IconScienceWhizBoyAComponent,
    IconScienceWhizBoyBComponent,
    IconScienceWhizBoyCComponent,
    IconScienceWhizBoyDComponent,
    IconScienceWhizBoyEComponent,
    IconScienceWhizBoyFComponent,
    IconScienceWhizGirlAComponent,
    IconScienceWhizGirlBComponent,
    IconScienceWhizGirlCComponent,
    IconScienceWhizGirlDComponent,
    IconScienceWhizGirlEComponent,
    IconScienceWhizGirlFComponent,
    IconSnowmanComponent,
    IconSpaceCadetBoyAComponent,
    IconSpaceCadetBoyBComponent,
    IconSpaceCadetBoyCComponent,
    IconSpaceCadetBoyDComponent,
    IconSpaceCadetBoyEComponent,
    IconSpaceCadetBoyFComponent,
    IconSpaceCadetGirlAComponent,
    IconSpaceCadetGirlBComponent,
    IconSpaceCadetGirlCComponent,
    IconSpaceCadetGirlDComponent,
    IconSpaceCadetGirlEComponent,
    IconSpaceCadetGirlFComponent,
    IconStarBronzeComponent,
    IconStarGoldComponent,
    IconStarSilverComponent,
    IconStopwatchTimerComponent,
    IconStrongMusclesAComponent,
    IconStrongMusclesBComponent,
    IconStrongMusclesCComponent,
    IconStrongMusclesDComponent,
    IconStrongMusclesEComponent,
    IconStrongMusclesFComponent,
    IconSuperheroPowerAComponent,
    IconSuperheroPowerBComponent,
    IconSuperheroPowerCComponent,
    IconSuperheroPowerDComponent,
    IconSuperheroPowerEComponent,
    IconSuperheroPowerFComponent,
    IconSwitchComponent,
    IconThumbsUpAComponent,
    IconThumbsUpBComponent,
    IconThumbsUpCComponent,
    IconThumbsUpDComponent,
    IconThumbsUpEComponent,
    IconThumbsUpFComponent,
    IconTimeComponent,
    IconTriangleMeasurementComponent,
    IconTrophyAwardComponent,
    IconUnlockedLevelComponent,
    IconWizardMagicAComponent,
    IconWizardMagicBComponent,
    IconWizardMagicCComponent,
    IconWizardMagicDComponent,
    IconWizardMagicEComponent,
    IconWizardMagicFComponent,
    IconWritingHandAComponent,
    IconWritingHandBComponent,
    IconWritingHandCComponent,
    IconWritingHandDComponent,
    IconWritingHandEComponent,
    IconWritingHandFComponent,
    PointsGraphicComponent,
    PointsIndicatorComponent,
    PointsListComponent,
    PointsStudentListComponent,
    SkinTonePickerComponent
  ],
  exports: [
    AwardsFilteredListComponent,
    AwardsGridComponent,
    AwardsGroupIconsComponent,
    AwardsGroupListComponent,
    AwardsGroupOrganisationComponent,
    AwardsListComponent,
    AwardsMessageComponent,
    CertificateNodeComponent,
    CertificateTreeComponent,
    IconAbacusMathsComponent,
    IconAlphabetComponent,
    IconBirthdayBalloonComponent,
    IconBirthdayCakeComponent,
    IconBirthdayRibbonComponent,
    IconBlastOffComponent,
    IconBooksReadingComponent,
    IconBrainSmartComponent,
    IconBullsEyeTargetComponent,
    IconCelebratePartyComponent,
    IconCheckCorrectComponent,
    IconChristmasTreeComponent,
    IconClapHandsAComponent,
    IconClapHandsBComponent,
    IconClapHandsCComponent,
    IconClapHandsDComponent,
    IconClapHandsEComponent,
    IconClapHandsFComponent,
    IconClimbingSkillAComponent,
    IconClimbingSkillBComponent,
    IconClimbingSkillCComponent,
    IconClimbingSkillDComponent,
    IconClimbingSkillEComponent,
    IconClimbingSkillFComponent,
    IconComputerWhizBoyAComponent,
    IconComputerWhizBoyBComponent,
    IconComputerWhizBoyCComponent,
    IconComputerWhizBoyDComponent,
    IconComputerWhizBoyEComponent,
    IconComputerWhizBoyFComponent,
    IconComputerWhizGirlAComponent,
    IconComputerWhizGirlBComponent,
    IconComputerWhizGirlCComponent,
    IconComputerWhizGirlDComponent,
    IconComputerWhizGirlEComponent,
    IconComputerWhizGirlFComponent,
    IconCrownKingQueenComponent,
    IconDiamondJewelComponent,
    IconDiamondTrophyComponent,
    IconDiySet11Component,
    IconDiySet12Component,
    IconDiySet13Component,
    IconDiySet14Component,
    IconDiySet21Component,
    IconDiySet22Component,
    IconDiySet23Component,
    IconDiySet24Component,
    IconDiySet31Component,
    IconDiySet32Component,
    IconDiySet33Component,
    IconDiySet34Component,
    IconElectricLightningComponent,
    IconExpertAdditionComponent,
    IconExpertDivisionComponent,
    IconExpertMultiplicationComponent,
    IconExpertSubtractionComponent,
    IconExplosiveBangComponent,
    IconExplosiveBombComponent,
    IconExplosiveTntComponent,
    IconFinishFlagComponent,
    IconFireForceComponent,
    IconGraduatedBoyAComponent,
    IconGraduatedBoyBComponent,
    IconGraduatedBoyCComponent,
    IconGraduatedBoyDComponent,
    IconGraduatedBoyEComponent,
    IconGraduatedBoyFComponent,
    IconGraduatedGirlAComponent,
    IconGraduatedGirlBComponent,
    IconGraduatedGirlCComponent,
    IconGraduatedGirlDComponent,
    IconGraduatedGirlEComponent,
    IconGraduatedGirlFComponent,
    IconGraduationAcademicComponent,
    IconGraphBarChartComponent,
    IconGraphLineChartComponent,
    IconGraphWhizBoyAComponent,
    IconGraphWhizBoyBComponent,
    IconGraphWhizBoyCComponent,
    IconGraphWhizBoyDComponent,
    IconGraphWhizBoyEComponent,
    IconGraphWhizBoyFComponent,
    IconGraphWhizGirlAComponent,
    IconGraphWhizGirlBComponent,
    IconGraphWhizGirlCComponent,
    IconGraphWhizGirlDComponent,
    IconGraphWhizGirlEComponent,
    IconGraphWhizGirlFComponent,
    IconHalloweenComponent,
    IconHomeworkMasterComponent,
    IconLearningMathsFemaleAComponent,
    IconLearningMathsFemaleBComponent,
    IconLearningMathsFemaleCComponent,
    IconLearningMathsFemaleDComponent,
    IconLearningMathsFemaleEComponent,
    IconLearningMathsFemaleFComponent,
    IconLearningMathsMaleAComponent,
    IconLearningMathsMaleBComponent,
    IconLearningMathsMaleCComponent,
    IconLearningMathsMaleDComponent,
    IconLearningMathsMaleEComponent,
    IconLearningMathsMaleFComponent,
    IconLightbulbBrightComponent,
    IconMagicWandComponent,
    IconMedal1stFirstComponent,
    IconMedal2ndSecondComponent,
    IconMedal3rdThirdComponent,
    IconMedalAwardComponent,
    IconMedalBadgeComponent,
    IconNumeracyComponent,
    IconOneHundredComponent,
    IconPlatinumTrophyComponent,
    IconPointsBlueComponent,
    IconPointsTradeComponent,
    IconPresentGiftComponent,
    IconRacecarSpeedComponent,
    IconReadingBookComponent,
    IconRubyTrophyComponent,
    IconRulerMeasurementComponent,
    IconRunningSpeedBoyAComponent,
    IconRunningSpeedBoyBComponent,
    IconRunningSpeedBoyCComponent,
    IconRunningSpeedBoyDComponent,
    IconRunningSpeedBoyEComponent,
    IconRunningSpeedBoyFComponent,
    IconRunningSpeedGirlAComponent,
    IconRunningSpeedGirlBComponent,
    IconRunningSpeedGirlCComponent,
    IconRunningSpeedGirlDComponent,
    IconRunningSpeedGirlEComponent,
    IconRunningSpeedGirlFComponent,
    IconSantaClausAComponent,
    IconSantaClausBComponent,
    IconSantaClausCComponent,
    IconSantaClausDComponent,
    IconSantaClausEComponent,
    IconSantaClausFComponent,
    IconScienceWhizBoyAComponent,
    IconScienceWhizBoyBComponent,
    IconScienceWhizBoyCComponent,
    IconScienceWhizBoyDComponent,
    IconScienceWhizBoyEComponent,
    IconScienceWhizBoyFComponent,
    IconScienceWhizGirlAComponent,
    IconScienceWhizGirlBComponent,
    IconScienceWhizGirlCComponent,
    IconScienceWhizGirlDComponent,
    IconScienceWhizGirlEComponent,
    IconScienceWhizGirlFComponent,
    IconSnowmanComponent,
    IconSpaceCadetBoyAComponent,
    IconSpaceCadetBoyBComponent,
    IconSpaceCadetBoyCComponent,
    IconSpaceCadetBoyDComponent,
    IconSpaceCadetBoyEComponent,
    IconSpaceCadetBoyFComponent,
    IconSpaceCadetGirlAComponent,
    IconSpaceCadetGirlBComponent,
    IconSpaceCadetGirlCComponent,
    IconSpaceCadetGirlDComponent,
    IconSpaceCadetGirlEComponent,
    IconSpaceCadetGirlFComponent,
    IconStarBronzeComponent,
    IconStarGoldComponent,
    IconStarSilverComponent,
    IconStopwatchTimerComponent,
    IconStrongMusclesAComponent,
    IconStrongMusclesBComponent,
    IconStrongMusclesCComponent,
    IconStrongMusclesDComponent,
    IconStrongMusclesEComponent,
    IconStrongMusclesFComponent,
    IconSuperheroPowerAComponent,
    IconSuperheroPowerBComponent,
    IconSuperheroPowerCComponent,
    IconSuperheroPowerDComponent,
    IconSuperheroPowerEComponent,
    IconSuperheroPowerFComponent,
    IconSwitchComponent,
    IconThumbsUpAComponent,
    IconThumbsUpBComponent,
    IconThumbsUpCComponent,
    IconThumbsUpDComponent,
    IconThumbsUpEComponent,
    IconThumbsUpFComponent,
    IconTimeComponent,
    IconTriangleMeasurementComponent,
    IconTrophyAwardComponent,
    IconUnlockedLevelComponent,
    IconWizardMagicAComponent,
    IconWizardMagicBComponent,
    IconWizardMagicCComponent,
    IconWizardMagicDComponent,
    IconWizardMagicEComponent,
    IconWizardMagicFComponent,
    IconWritingHandAComponent,
    IconWritingHandBComponent,
    IconWritingHandCComponent,
    IconWritingHandDComponent,
    IconWritingHandEComponent,
    IconWritingHandFComponent,
    PointsGraphicComponent,
    PointsIndicatorComponent,
    PointsListComponent,
    PointsStudentListComponent,
    SkinTonePickerComponent
  ]
})
export class AwardsLibModule { }
