import { RegionId } from '../../models';
import { regions } from './regions';

function getNumberPart(value: string) {
  return value.endsWith('.') ? Number(value.slice(0, Math.max(0, value.length - 1))) : Number(value);
}

function swapCommasAUNZ(value: string, regionId: RegionId) {
  if (regionId === RegionId.Australia || regionId === RegionId.NewZealand) {
    return value.replace(/,/g, ' ');
  }

  return value;
}

function getFormattedOutput(value: string, convert: Intl.NumberFormat, includeUnderline: boolean) {

  const numberPart = getNumberPart(value);

  let output = convert.format(numberPart);
  if (includeUnderline) {
    output = `<u>${output}</u>`;
  }

  return output + (value.endsWith('.') ? '.' : '');
}

function getMinimumFractionDigits(value: string) {
  // if the number has a zero decimal (e.g 1.0 it would display 1, which is not what we want)

  let minimumFractionDigits = 0;

  const searchValue = value.endsWith('.') ? value.slice(0, Math.max(0, value.length - 1)) : value;

  if (searchValue.includes('.')) {
    minimumFractionDigits = searchValue.split('.')[1].length;
  }

  return minimumFractionDigits;
}

export function currencySwapFunction(text: string, regionId: RegionId = RegionId.Australia, includeUnderline = false) {
  let outputText = text;
  const region = regions.find(s => s.id === regionId);
  if (region && outputText) {

    // swap out special codes

    for (const currencySwap in region.currencySwap) {
      let newText = region.currencySwap[currencySwap];
      if (includeUnderline) {
        newText = `<u>${newText}</u>`;
      }
      outputText = outputText.replace(new RegExp(`[_*]${currencySwap}`, 'gi'), newText);
    }

    // swap out currency values

    let regExp = new RegExp('[_*]currency[0-9.]*', 'gi');
    outputText = outputText.replace(regExp, (value: string) => {
      const matchValue = value.slice(9);
      const numberPart = getNumberPart(matchValue);
      const wholeNumber: Intl.NumberFormatOptions = {
        style: 'currency', currency: region.currency,
        minimumFractionDigits: 0, maximumFractionDigits: 0
      };
      const partNumber: Intl.NumberFormatOptions = { style: 'currency', currency: region.currency };
      return swapCommasAUNZ(getFormattedOutput(matchValue,
        Intl.NumberFormat(region.locale, numberPart % 1 === 0 ? wholeNumber : partNumber),
        includeUnderline), regionId);
    });

    // swap out number values

    regExp = new RegExp('[_*]number[0-9.]*', 'gi');
    outputText = outputText.replace(regExp, (value: string) => {

      const matchValue = value.slice(7);

      // This is a hack, as some numbers have more than 3 decimal places
      // and if you don't specify, it defaults to 3

      return swapCommasAUNZ(getFormattedOutput(matchValue,
        Intl.NumberFormat(region.locale, {
          style: 'decimal',
          minimumFractionDigits: getMinimumFractionDigits(matchValue),
          maximumFractionDigits: 10
        }), includeUnderline), regionId);
    });

    // swap of time values - just ignore completely

    regExp = new RegExp('[_*]time', 'gi');
    outputText = outputText.replace(regExp, () => '');

    // swap of year values - just ignore completely

    regExp = new RegExp('[_*]year', 'gi');
    outputText = outputText.replace(regExp, () => '');

  }

  return outputText;
}
