import { createAction, props } from '@ngrx/store';

enum RouterActionTypes {
  SetWindowUnloading = 'RouterState > Set Window Unloading'
}

export const setWindowUnloadingAction = createAction(
  RouterActionTypes.SetWindowUnloading,
  props<{
    isWindowUnloading: boolean;
  }>()
);
