@if ((overlaySelector | async); as overlay) {
@if (overlay?.overlays?.enabled && !overlay.isStudentInTutorMode) {
<kip-overlay [bgColor]="overlay?.overlays?.color || ''" />
}
}

@if (isLoaded) {
<div [class]="'kip-app ' + (isStudent? theme : '')" [class.bg-primary-gradient]="isTutor">
  @if (currentDevice.supported) {
  <div class="h-100" [class.kip-student]="isStudent">
    @if (isAuthenticatedOrRouteAnonymouslyAccessible) {
    <router-outlet />
    } @else {
    <main class="kip-auth d-flex justify-content-center align-items-center" kipDeviceOrientation>
      <div class="kip-auth__login bg-white h-75 w-75 d-flex justify-content-between">
        @switch (whiteLabel) {
        @case ('tutorfly') {
        <div class="col-12 col-md-6 d-flex flex-column justify-content-around align-items-center">
          <img class="col-12 col-md-8 px-3 px-md-0" src="./assets/svg/tutorfly-logo.svg" style="max-width: 450px;">
          @if (logoutMessage) {
          <div class="alert alert-warning mx-2 mt-2" role="alert">
            {{ logoutMessage }}
          </div>
          }
          <div class="d-flex flex-column justify-content-center align-items-center w-100">
            <button class="btn col-12 col-md-8 col-xl-6 p-0 mb-4" style="max-width: 325px;" type="button"
              (click)="classLinkLogin()">
              <img class="w-100" src="./assets/classlink-login-button-large.png">
            </button>
            <button class="btn col-12 col-md-8 col-xl-6 p-0 mb-4" style="max-width: 325px;" type="button"
              (click)="cleverLogin()">
              <img class="w-100" src="./assets/LogInWithClever2x.png">
            </button>
            <button class="btn btn-primary py-3 col-12 col-md-8 col-xl-6" style="max-width: 325px;" type="button"
              (click)="login()">Log in with Password</button>
          </div>
        </div>
        }
        @default {
        <div class="col-12 col-md-6 d-flex flex-column justify-content-around align-items-center">
          @switch (orientation) {
          @case ('portrait') {
          <img src="./assets/svg/kip-logo-blue-portrait.svg">
          }
          @case ('landscape') {
          <div class="w-100 d-flex justify-content-center">
            <img class="d-none d-md-block" src="./assets/svg/kip-logo-blue-portrait.svg">
            <img class="d-block d-md-none w-75 mb-n5" src="./assets/svg/kip-logo-blue-landscape.svg">
          </div>
          }
          @default {
          <img src="./assets/svg/kip-logo-blue-portrait.svg">
          }
          }
          @if (logoutMessage) {
          <div class="alert alert-warning mx-2 mt-2" role="alert">
            {{ logoutMessage }}
          </div>
          }
          <button class="btn btn-lg btn-primary w-50" type="button" (click)="login()">Login</button>
        </div>
        }
        }
        <div class="kip-auth__scene d-none d-md-block col-md-6" [class.tutorfly]="whiteLabel === 'tutorfly'"></div>
      </div>
    </main>
    }
    @if (isAuthenticatedOrRouteAnonymouslyAccessible) {
    <kip-update />
    <kip-supported-browser />
    }
  </div>
  } @else {
  <main class="kip-auth d-flex justify-content-center align-items-center" kipDeviceOrientation>
    <div class="kip-auth__login bg-white h-75 w-75 d-flex justify-content-between">
      <div class="col-12 col-md-6 d-flex flex-column align-items-center"
        [ngClass]="{ 'justify-content-around': (orientation === 'portrait' && currentDevice.device !== 'iPad'), 'justify-content-center': (orientation === 'landscape' || currentDevice.device === 'iPad') }">
        <img class="d-none d-md-block" src="./assets/svg/kip-logo-blue-portrait.svg">
        <img class="d-block d-md-none" src="./assets/svg/kip-logo-blue-landscape.svg">
        <div class="no-wrap text-secondary m-2" [ngClass]="{'text-center': currentDevice.device !== 'iPad'}">
          Your browser ({{ currentDevice.browser }}) is not supported. To provide the best experience possible, please
          use
          <span class="text-primary fw-bold mb-2">{{currentDevice.supportedBrowsers[0]}}</span>
          on this device.
        </div>
      </div>
      <div class="kip-auth__scene d-none d-md-block col-md-6" [class.tutorfly]="whiteLabel === 'tutorfly'"></div>
    </div>
  </main>
  }
</div>
} @else {
<div class="d-flex justify-content-center mt-5">
  <kip-spinner />
</div>
}


@if (authError) {
<kip-redirect-warning buttonText="Back to Login" warningMessage="It would appear you have bookmarked the login page."
  (acceptRedirect)="login()" />
}