import { PageInfo } from './page-info';

export interface PagedList<T> {
  readonly pageInfo: PageInfo;
  readonly items: readonly T[];
}

export function defaultEmptyPage<T>(): PagedList<T> {
  return {
    items: [],
    pageInfo: {
      numberOfItemsOnPage: 0,
      totalNumberOfPages: 0,
      pageEnd: 0,
      pageNumber: 0,
      pageStart: 0,
      totalNumberOfResults: 0
    }
  };
}
