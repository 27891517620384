import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { AwardsLibModule } from 'awards-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { EmojiEditComponent } from './emoji-edit/emoji-edit.component';
import { EmojiMessageComponent } from './emoji-message/emoji-message.component';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { FormControlEmojiComponent } from './form-control-emoji/form-control-emoji.component';

@NgModule({
  declarations: [
    EmojiEditComponent,
    EmojiMessageComponent,
    EmojiPickerComponent,
    FormControlEmojiComponent
  ],
  imports: [
    AwardsLibModule,
    CommonModule,
    EmojiModule,
    FormsModule,
    IconLibModule,
    PickerModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    EmojiEditComponent,
    EmojiMessageComponent,
    EmojiPickerComponent,
    FormControlEmojiComponent
  ]
})
export class EmojiLibModule { }
