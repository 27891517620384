import { ChangeDetectionStrategy, Component } from '@angular/core';

import { RegionFlag } from './region-flag';

@Component({
  selector: 'kip-region-flag-us',
  templateUrl: './region-flag-us.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionFlagUsComponent implements RegionFlag {

}
