import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

export type Orientation = 'landscape' | 'portrait';

@Directive({
  selector: '[kipDeviceOrientation]'
})
export class DeviceOrientationDirective {
  @HostBinding('class') orientation: Orientation | undefined;

  @Output() readonly orientationChange = new EventEmitter<Orientation>();

  constructor() {
    this.#setOrientation();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.#setOrientation();
  }

  #setOrientation() {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');

    // https://stackoverflow.com/questions/44709114/javascript-screen-orientation-on-safari
    const value = mediaQueryList.matches ? 'portrait' : 'landscape';
    if (value !== this.orientation) {
      this.orientation = value;
      this.orientationChange.emit(value);
    }
  }
}
