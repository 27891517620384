import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

import { DeliveryMethodType } from '../models';

@Pipe({
  name: 'deliveryMethodIcon'
})
export class DeliveryMethodIconPipe implements PipeTransform {
  transform(deliveryMethod: DeliveryMethodType | null | undefined): IconDefinition {
    return deliveryMethod === DeliveryMethodType.InCentre ? Icons.deliveryMethod.inCentre : Icons.deliveryMethod.online;
  }
}
