import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconDefinition, Icons } from 'icon-lib';

@Component({
  selector: 'kip-ranking-picker',
  templateUrl: './ranking-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingPickerComponent {

  readonly icons = Icons;
  readonly rankingValues: (number | null)[] = [null, 1, 2, 3, 4, 5];
  readonly emojiRankingValues: ({ value: number | null, icon: IconDefinition | null })[] = [
    { value: null, icon: null },
    { value: 1, icon: Icons.rankings.poor },
    { value: 3, icon: Icons.rankings.ok },
    { value: 5, icon: Icons.rankings.good }];
  readonly allowNull = input.required<boolean>();
  readonly prefix = input.required<string>();
  readonly name = input.required<string>();
  readonly label = input('');
  readonly useEmojis = input(false);
  readonly ranking = input.required<FormControl<number | null>>();
  readonly disabled = input(false);

}
