import { Injectable } from '@angular/core';

import { ScriptName, ScriptStore } from './script.store';

enum ScriptStatus {
  Loaded = 'Loaded',
  AlreadyLoaded = 'AlreadyLoaded',
  Error = 'Error'
}

interface ScriptPromise {
  script: ScriptName;
  loaded: boolean;
  status: ScriptStatus;
}

@Injectable({
  providedIn: 'root'
})

// Adapted from https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular

@Injectable()
export class ScriptService {

  private readonly scripts: { [key: string]: { loaded: boolean, src: string } } = {};

  constructor() {
    for (const script of ScriptStore) {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    }
  }

  load(...scripts: ScriptName[]) {
    const promises: Promise<ScriptPromise>[] = [];
    for (const script of scripts) {
      promises.push(this.loadScript(script));
    }
    return Promise.all(promises);
  }

  loadScript(name: ScriptName) {
    return new Promise<ScriptPromise>((resolve, _reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: ScriptStatus.AlreadyLoaded });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: ScriptStatus.Loaded });
        };
        script.onerror = (_error: any) => resolve({ script: name, loaded: false, status: ScriptStatus.Error });
        document.querySelectorAll('head')[0].append(script);
      }
    });
  }

}
