import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';
import { PagedList, PageRequest } from 'ui-common-lib';

import {
  AccountBundle, AccountBundleDetail, AccountContact, AccountContactRegistrationResponse,
  AccountDetail, AccountFilter, AccountNotification, AccountPayment, AccountSummary
} from './models';
@Injectable({
  providedIn: 'root'
})
export class AccountService extends HttpService {

  queryAccounts(requestPage: PageRequest<AccountFilter>): Observable<PagedList<AccountSummary>> {
    return this.post<PagedList<AccountSummary>>('accounts/query', requestPage);
  }

  getAccountById(accountId: number): Observable<AccountDetail> {
    return this.get<AccountDetail>(`accounts/${accountId}`);
  }

  queryBundlesByAccountId(accountId: number, _pageToFetch: PageRequest): Observable<AccountBundleDetail> {
    return this.post<AccountBundleDetail>(`accounts/${accountId}/bundle/query`, {});
  }

  queryExpiredBundlesByAccountId(accountId: number, _pageToFetch: PageRequest): Observable<PagedList<AccountBundle>> {
    return this.post<PagedList<AccountBundle>>(`accounts/${accountId}/expired/bundle/query`, {});
  }

  queryAccountNotifications(accountId: number, requestPage: PageRequest): Observable<PagedList<AccountNotification>> {
    return this.post<PagedList<AccountNotification>>(`accounts/${accountId}/notifications/query`, requestPage);
  }
  getContactsByAccountId(accountId: number): Observable<AccountContact[]> {
    return this.get<AccountContact[]>(`accounts/${accountId}/contact`);
  }

  getContacts(): Observable<AccountContact[]> {
    return this.get<AccountContact[]>('accounts/contacts');
  }

  getPaymentMethodByAccountId(accountId: number): Observable<AccountPayment> {
    return this.get<AccountPayment>(`accounts/${accountId}/payment`);
  }

  postAccountPayment(accountId: number, accountPayment: AccountPayment): Observable<string> {
    return this.post<string>(`accounts/${accountId}/payment`, accountPayment);
  }

  postNewContact(accountId: number, studentContact: AccountContact): Observable<string> {
    return this.post<string>(`accounts/${accountId}/contact`, studentContact);
  }

  deleteContact(accountId: number, contactId: number, password: string) {
    return this.post<string>(`accounts/${accountId}/contacts/${contactId}/delete`, { password: password });
  }

  updateExistingContact(accountId: number, studentContact: AccountContact): Observable<string> {
    return this.put<string>(`accounts/${accountId}/contact`, studentContact);
  }

  createUserForContact(accountId: number, contactId: number, userName: string, password: string):
    Observable<AccountContactRegistrationResponse> {
    return this.post<AccountContactRegistrationResponse>(`accounts/${accountId}/contact/${contactId}/user`,
      { userName: userName, password: password });
  }

  resetPasswordForContact(accountId: number, contactId: number, newPassword: string): Observable<string> {
    return this.put<string>(`accounts/${accountId}/contact/${contactId}/user/password`, { password: newPassword });
  }
}
