<div class=" kip-toolbar btn-toolbar justify-content-between" role="toolbar">
  <div class="btn-group" role="group">
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="Bold" placement="top" type="button"
      (click)="onSelect(markdownOptions.bold)">
      <fa-icon [icon]="icons.markdownToolbar.bold" />
    </button>
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="Italic" placement="top" type="button"
      (click)="onSelect(markdownOptions.italic)">
      <fa-icon [icon]="icons.markdownToolbar.italic" />
    </button>
    <div class="kip-toolbar-item" ngbDropdown>
      <button class="btn btn-outline-light kip-toolbar-item" ngbDropdownToggle ngbTooltip="Headings" placement="top"
        type="button">
        <fa-icon id="headingsDropDown" [icon]="icons.markdownToolbar.heading" />
      </button>
      <div aria-labelledby="headingsDropDown" ngbDropdownMenu>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.h1)">
          <h1>Heading</h1>
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.h2)">
          <h2>Heading</h2>
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.h3)">
          <h3>Heading</h3>
        </button>
      </div>
    </div>
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="Sup" placement="top" type="button"
      (click)="onSelect(markdownOptions.sup)">
      <fa-icon [icon]="icons.markdownToolbar.sup" />
    </button>
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="Sub" placement="top" type="button"
      (click)="onSelect(markdownOptions.sub)">
      <fa-icon [icon]="icons.markdownToolbar.sub" />
    </button>
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="Ordered List" placement="top" type="button"
      (click)="onSelect(markdownOptions.orderedList)">
      <fa-icon [icon]="icons.markdownToolbar.listOl" />
    </button>
    <button class="btn btn-outline-light kip-toolbar-item" ngbTooltip="List" placement="top" type="button"
      (click)="onSelect(markdownOptions.unorderedList)">
      <fa-icon [icon]="icons.markdownToolbar.list" />
    </button>
    <div class="kip-toolbar-item" ngbDropdown>
      <button class="btn btn-outline-light kip-toolbar-item" ngbDropdownToggle ngbTooltip="Image Width" placement="top"
        type="button">
        <fa-icon id="imageWidthDropDown" [icon]="icons.markdownToolbar.imageWidth" />
      </button>
      <div aria-labelledby="imageWidthDropDown" ngbDropdownMenu>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.imageWidth20)">
          20% view width
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.imageWidth40)">
          40% view width
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.imageWidth60)">
          60% view width
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.imageWidth80)">
          80% view width
        </button>
        <button class="dropdown-item" type="button" (click)="onSelect(markdownOptions.imageWidth100)">
          100% view width
        </button>
      </div>
    </div>
  </div>
  <div class="btn-group" role="group">
    <div class="kip-toolbar-item" ngbDropdown>
      <button class="btn btn-outline-light kip-toolbar-item" ngbDropdownToggle placement="top" type="button">
        <fa-icon id="helpDropDown" [icon]="icons.markdownToolbar.question" />
      </button>
      <div aria-labelledby="helpDropDown" ngbDropdownMenu>
        <a class="btn btn-link dropdown-item" href="https://commonmark.org/help/" rel="noopener"
          target="_blank">Markdown Reference</a>
      </div>
    </div>
  </div>
</div>