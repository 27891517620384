<div #container class="kip-whiteboard" style="overflow-y: auto; overflow-x: hidden;"
  [style.background-color]="backgroundColor">
  <div class="kip-canvas" style="overflow: unset; mix-blend-mode: multiply;">
    <pinch-zoom [autoHeight]="true" [disablePan]="disablePan" [disabled]="!isManual" [wheel]="false">
      <img #image alt="No image" style="position: absolute; width: 100%;" [src]="safeBackgroundImage"
        [style.display]="safeBackgroundImage?'':'none'" [width]="backgroundImageWidth" (load)="displayToolbar()" />
      <canvas #canvas></canvas>
    </pinch-zoom>
  </div>
  @if (hasImageLoaded) {
  @if (!readOnlyOrArchived)
  {
  <div class="kip-whiteboard-toolbar ps-3 h-100 d-flex align-items-end justify-content-end position-sticky top-0"
    style="pointer-events: none;">
    <div class="kip-question-controls">
      @if (showViewToggle) {
      <label class="m-0 btn kip-btn rounded-0 border-0 w-auto" placement="left"
        [ngClass]="othersViewing ? 'text-danger border-danger' : 'border-0'"
        [ngbTooltip]="othersViewing ? 'Close Student Whiteboard' : 'Open Student Whiteboard'"
        (click)="othersViewingChange.emit()">
        <fa-icon class="kip-icon" size="lg" [fixedWidth]="true"
          [icon]="othersViewing ? icons.close.regular : icons.sendWhiteboard.regular" />
        <fa-icon class="ms-1" size="lg" [icon]="icons.studentWithCap" />
      </label>
      }
    </div>
    <kip-whiteboard-toolbar class="d-flex flex-column justify-content-end h-100" [allowClose]="allowClose"
      [color]="color" [isManual]="isManual" [isScrolling]="isScrolling" [printAllEnabled]="printAllEnabled"
      [tool]="tool" [toolbarActions]="toolbarActions" (clear)="onClear()" (close)="onClose()"
      (disableScrolling)="disablePanDisableScroll()" (enableScrolling)="enablePanEnableScroll()"
      (gridTypeChange)="gridTypeChange.emit($event)" (print)="onPrint()" (printAll)="printAll.emit()" (redo)="onRedo()"
      (selection)="onToolbarSelection($event)" (undo)="onUndo()" />
  </div>
  }
  @else {
  <div class="d-flex align-items-end justify-content-end">
    <span class="p-2"> Whiteboard has been archived</span>
  </div>
  }
  }
</div>