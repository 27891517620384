<div class="col-12 mb-2">
  <div #dropAreaStudentImage class="image-uploader-drop-area-small">
    <form class="image-uploader-form mb-0">
      <input class="image-uploader-file" id="studentPhoto" type="file" [accept]="validFileTypes"
        (change)="fileUploadChange($event)">
      <label class="text-primary h4 mb-0 text-center w-100 py-5" for="studentPhoto">Click here to browse and
        select<br>or drag and drop here</label>
    </form>
  </div>
  @if (preview) {
  <span>Click the image to clear it.</span>
  }
  @if (invalidFileTypeImage) {
  <p class="text-danger text-left">{{ invalidFileTypeImage }}</p>
  }

  <p class="small text-start">File types accepted: .jpg, .jpeg, .gif, .bmp, .png<br>
    Maximum size 20mb</p>

  @if (preview) {
  <img #img style="width:90px" (click)="clear()">
  }
</div>