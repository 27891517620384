import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { Subscription } from 'rxjs';
import { Strength, StudentService, StudentStrength } from 'student-common-lib';

@Component({
  selector: 'kip-strength-edit',
  templateUrl: './strength-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrengthEditComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get id() {
    return this.strengthForm.getSafe(x => x.id);
  }

  get description() {
    return this.strengthForm.getSafe(x => x.description);
  }

  get reviewDate() {
    return this.strengthForm.getSafe(x => x.reviewDate);
  }

  get completed() {
    return this.strengthForm.getSafe(x => x.completed);
  }

  get action(): string {
    return !this.isNew ? 'Edit' : 'Add';
  }

  get buttonText(): string {
    return !this.isNew ? 'Save Changes' : 'Add Strength';
  }

  get minDate() {
    return moment().toDateStruct();
  }

  get isNew() {
    return this.id.value === 0;
  }

  strengthForm: FormGroupTypeSafe<Strength>;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  studentStrength: StudentStrength | undefined;

  @Input({ required: true }) studentId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly submitted = new EventEmitter();
  @Output() readonly strengthCompleted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */

    this.strengthForm = this.#fb.group<Strength>({
      id: new FormControl<number>(0, Validators.required),
      description: new FormControl<string | null>(null, Validators.required),
      reviewDate: new FormControl<DateStruct | null>(null, Validators.required),
      completed: new FormControl<boolean>(false)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    if (this.studentStrength) {
      this.strengthForm.patchSafe(this.studentStrength.strength);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (this.strengthForm.invalid) {
      markControlsAsTouched(this.strengthForm);
      return;
    }
    const strength = this.strengthForm.value;
    if (this.studentId) {
      const studentId = this.studentId;
      if (strength.id > 0) {
        this.#subscriptions.push(
          this.#studentService.updateExistingStrength(studentId, strength)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (strength.completed) {
                    this.strengthCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.strengthForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }));
      } else {
        this.#subscriptions.push(
          this.#studentService.postNewStrength(studentId, strength)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (strength.completed) {
                    this.strengthCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.strengthForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }
            ));
      }
    }
  }

  delete() {
    const strength = this.strengthForm.value;
    if (this.studentId) {
      this.#subscriptions.push(
        this.#studentService.deleteExistingStrength(this.studentId, strength.id)
          .subscribe(
            {
              next: () => {
                this.submitted.emit();
                this.#changeDetectorRef.markForCheck();
              },
              error: (error: unknown) => {
                this.strengthForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              },
              complete: () => this.#studentService.refreshCacheItem(this.studentId!)
            }
          ));
    }
  }

}
