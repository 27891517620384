/* eslint-disable @typescript-eslint/no-deprecated */

import {
  AfterContentChecked, ApplicationRef, ChangeDetectionStrategy, Component,
  ComponentFactoryResolver,
  ComponentRef, ElementRef, EmbeddedViewRef, Injector, ViewChild
} from '@angular/core';

import { AnswerType, QuestionControlParameterItem, QuestionControlType, QuestionGeneric, QuestionParametersGeneric, ValidationResult } from '../../models';
import { determineBestMatch } from '../../utilities';
import { QuestionLayout } from '../question-layout';
import { DropdownComponent } from './controls/dropdown/dropdown.component';
import { QuestionControl } from './controls/question-control';
import { ReadonlyComponent } from './controls/readonly/readonly.component';
import { TextAreaComponent } from './controls/text-area/text-area.component';
import { TextBoxComponent } from './controls/text-box/text-box.component';

@Component({
  selector: 'kip-question-generic',
  templateUrl: './generic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericComponent extends QuestionLayout implements AfterContentChecked {

  #displayAnswer: readonly AnswerType[] | undefined;
  #correctAnswer: AnswerType[] = [];

  readonly #controls: { [questionControlType in QuestionControlType]: any } = {
    [QuestionControlType.TextBox]: TextBoxComponent,
    [QuestionControlType.Dropdown]: DropdownComponent,
    [QuestionControlType.TextArea]: TextAreaComponent
  };

  #controlRefs: ComponentRef<QuestionControl>[] | undefined;

  override question: QuestionGeneric | undefined;

  get answers(): AnswerType[] {

    // For each control rendered, get the value
    // Note that an array of values can be provided by a control and need to be flattened
    const answers: AnswerType[] = [];

    if (this.#controlRefs) {
      for (const controlRef of this.#controlRefs) {
        const values = controlRef.instance.values;

        if (values) {
          for (const value of values) {
            answers.push(value);
          }
        }
      }
    }

    return answers;
  }

  override set validationResults(validationResults: ValidationResult[]) {
    if (this._validationResults !== validationResults && this.#controlRefs) {
      this._validationResults = validationResults;

      if (this._validationResults.length !== this.#controlRefs.length) {
        for (const controlRef of this.#controlRefs) {
          controlRef.instance.setValidationResult(ValidationResult.NotKnown);
        }
      } else {
        for (let index = 0, lastIndex = this._validationResults.length; index < lastIndex; index++) {
          this.#controlRefs[index].instance.setValidationResult(this.validationResults[index]);
        }
      }
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement> | undefined;

  constructor(
    private readonly injector: Injector,
    private readonly applicationRef: ApplicationRef,
    // This is deprecated, but if we switch over to the alternative, it breaks
    // eslint-disable-next-line etc/no-deprecated
    private readonly componentFactoryResolver: ComponentFactoryResolver) {

    super();
  }

  ngAfterContentChecked() {

    // Ensure the controls are only created once - this handler is called repeatedly
    if (!this.#controlRefs) {
      this.#controlRefs = [];

      // Find all the <control> tags that need processing in the question text
      // The control creation needs to be delayed to avoid change detection issues
      window.setTimeout(() => {
        if (this.container) {
          const controls = this.container.nativeElement.querySelectorAll('control');
          const questionParameters: QuestionParametersGeneric = this.question?.parameters ?? {};
          this.#controlRefs = this.getControlRefsOrdered(questionParameters, [...controls]);
        }
      });
    }
  }

  override initialize() {

    // Load the question text as inner HTML so controls can be appended
    this.displayAnswer();
    if (this.container && this.question) {
      this.container.nativeElement.innerHTML = this.question.text;
    }
  }

  override incomplete() {
    if (this.#controlRefs) {
      for (const controlRef of this.#controlRefs) {
        const values = controlRef.instance.values;

        if (values.includes('')) {
          const autoFocus = controlRef.instance.autoFocus;
          controlRef.instance.autoFocus = true;
          controlRef.instance.focus();
          controlRef.instance.autoFocus = autoFocus;
          return;
        }
      }
    }
  }

  getControlRefsOrdered(questionParameters: QuestionParametersGeneric, controls: Element[]) {

    const controlRefs: ComponentRef<QuestionControl>[] = [];

    // We need to get all the property names in order they were added in the question parameters object
    // This is the same order as the answers array
    // Note when using katex fractions, the order the controls appear in the dom is different
    // ie 1/2 the 2 appears before the 1 in the html dom

    const propertyNames = Object.getOwnPropertyNames(questionParameters);
    if (this.question) {
      this.#correctAnswer = this.question.answers.map(s => s.values[0]);
      for (const control of controls) {

        // Match the question parameter to the control id so we know how to build that control

        const id = control.getAttribute('control-id');
        if (id !== null) {
          const controlParameters = questionParameters[id];

          if (controlParameters) {
            const propertyIndex = propertyNames.indexOf(id);
            // Get the type of control that is required and check it is valid
            const controlType = this.readonly
              ? ReadonlyComponent
              : this.#controls[controlParameters.type];
            if (!controlType) {
              throw new Error(`Unable to resolve control '${controlParameters.type}'.`);
            }

            // Create an instance of the required control
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory<QuestionControl>(controlType);
            const controlRef = componentFactory.create(this.injector);

            // Ensure the component is added to the application ref for change detection
            this.applicationRef.attachView(controlRef.hostView);

            // If the values are just digits and/or . - show numeric keyboard

            controlRef.instance.inputMode = this.question.answers[propertyIndex].values.some(v => !(/^[0-9.]+$/).test((v ?? '').toString())) ? undefined : 'numeric';

            // Configure the control instance
            // Enable auto focus if it is the first control
            controlRef.instance.tabIndex = propertyIndex + 1;
            controlRef.instance.autoFocus = propertyIndex === 0 && this.autoFocus;
            controlRef.instance.parameters = controlParameters;
            controlRef.instance.inputLength = this.#correctAnswer[propertyIndex].toString().length;

            // Displays the correct answer initially. When the student interacts displays to the student entered value

            if (this.#displayAnswer) {
              if (this.#displayAnswer[propertyIndex] === this.#correctAnswer[propertyIndex]) {
                controlRef.instance.setValidationResult(ValidationResult.Correct);
              } else {
                controlRef.instance.setValidationResult(ValidationResult.Incorrect);
              }
            } else {
              // Display correct answers css styles in the explore mode (tutor)
              controlRef.instance.setValidationResult(this.readonly ? ValidationResult.Correct : ValidationResult.NotKnown);
            }

            let answer = this.#displayAnswer ? this.#displayAnswer[propertyIndex] :
              this.#correctAnswer[propertyIndex];

            // allow display value for dropdown to be html/value defined by text

            if (controlParameters.type === QuestionControlType.Dropdown && controlParameters.items) {
              for (const item of controlParameters.items) {
                if (this.isIdVersion(item) && item.value === answer) {
                  answer = item.text;
                  break;
                }
              }
            }

            const keyboardType = determineBestMatch((answer ?? '').toString());

            controlRef.instance.keyboardType = keyboardType;
            controlRef.instance.displayAnswer = answer;
            controlRef.instance.submit = () => this.submit();
            controlRef.instance.update = () => this.sendUpdates();
            // Finally get the component element and append to the container
            const viewRef = controlRef.hostView as EmbeddedViewRef<QuestionControl>;
            const element = viewRef.rootNodes[0] as HTMLElement;
            controlRefs.push(controlRef);
            control.append(element);

            // Make single character text boxes auto progress to the next control

            if (controlParameters.type === QuestionControlType.TextBox && controlParameters.maxLength === 1) {
              element.addEventListener('keyup', event => {
                this.#moveToNext(event.target as HTMLInputElement, controlRef);
              });
            }
          }
        }
      }

      // As discussed above, we need to ensure the controls are ordered in the same order
      // as the answers (not in the order they appear in the html dom)

      controlRefs.sort((a: ComponentRef<QuestionControl>, b: ComponentRef<QuestionControl>) =>
        (a.instance.tabIndex ?? 0) - (b.instance.tabIndex ?? 0));
    }

    return controlRefs;
  }

  isIdVersion(value: QuestionControlParameterItem | string): value is QuestionControlParameterItem {
    return (value as QuestionControlParameterItem).value !== undefined;
  }

  displayAnswer() {
    if (this.readonly && this.question && this.studentAnswers !== undefined) {
      this.#displayAnswer = this.studentAnswers;
    }
  }

  override destroy() {
    // Clean up the controls
    if (this.#controlRefs) {
      for (const controlRef of this.#controlRefs) {
        controlRef.destroy();
      }
      this.#controlRefs = undefined;
    }
  }

  #moveToNext(textBox: HTMLInputElement, controlRef: ComponentRef<QuestionControl>) {
    if (textBox.value !== '' && this.#controlRefs) {
      const targetIndex = this.#controlRefs.indexOf(controlRef) + 1;
      const targetControlRef = this.#controlRefs[targetIndex >= this.#controlRefs.length ? 0 : targetIndex];
      if (targetControlRef.instance instanceof TextBoxComponent) {
        targetControlRef.instance.focusAndSelect();
      }
    }
  }

}
