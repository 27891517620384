import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AutoReloadService {
    readonly #reloadCountKey = 'AUTO_RELOAD_COUNT';
    readonly #maxReloads = 5;
    readonly #delayTimeInMs = 5000;
    readonly #reloadTimeInMs = 5000;
    #reloadTimer: number | undefined;
    #reloadDelayTimer: number | undefined;

    get reloadTimerActive(): boolean {
        return !!this.#reloadTimer;
    }

    get reloadCount() {
        const count = localStorage.getItem(this.#reloadCountKey);
        if (!count || Number.isNaN(+count)) {
            return 0;
        }

        return +count;
    }

    get tooManyReloads() {
        return this.reloadCount >= this.#maxReloads;
    }

    @Output()
    readonly reloadTimerChange = new EventEmitter<void>();

    reload(force = false) {
        if (force) {
            this.#clearAutoReload();
            localStorage.removeItem(this.#reloadCountKey);
            window.location.reload();
        }

        if (this.reloadCount < this.#maxReloads) {
            this.#autoReload();
        } else {
            this.#clearAutoReload();
        }
    }

    handleAutoReload(shouldReload: boolean) {
        if (shouldReload) {
            this.#setUpAutoReloadDelay();
        } else {
            this.#clearAutoReloadDelay();
            this.#clearAutoReload();
            localStorage.removeItem(this.#reloadCountKey);
        }
    }

    #setUpAutoReloadDelay() {
        if (!this.#reloadDelayTimer && !this.tooManyReloads) {
            this.#reloadDelayTimer = window.setTimeout(() => this.#setUpAutoReload(), this.#delayTimeInMs);
        }
    }

    #setUpAutoReload() {
        if (!this.#reloadTimer && !this.tooManyReloads) {
            this.#reloadTimer = window.setTimeout(() => this.reload(), this.#reloadTimeInMs);
            this.reloadTimerChange.emit();
        }
        this.#clearAutoReloadDelay();
    }

    #clearAutoReloadDelay() {
        if (this.#reloadDelayTimer) {
            clearTimeout(this.#reloadDelayTimer);
            this.#reloadDelayTimer = undefined;
        }
    }

    #clearAutoReload() {
        if (this.#reloadTimer) {
            clearTimeout(this.#reloadTimer);
            this.#reloadTimer = undefined;
            this.reloadTimerChange.emit();
        }
    }

    #autoReload() {
        localStorage.setItem(this.#reloadCountKey, (this.reloadCount + 1).toString());
        window.location.reload();
    }
}
