@if (fileList.length) {
<div class="kip-uploaded-file-list-body">
  @for (img of fileList; track img) {
  <div class="img-body d-flex align-items-center w-100" [class.pointer-events-none]="isUploading"
    (click)="selectedFile.emit(img)">
    @if (!img.hasError && !hasError) {
    <img alt="No image" [src]="img.src">
    }
    <div class="title-info-body">
      <div class="d-flex justify-content-between w-100">
        <div class="w-100">
          <div class="file-name-body d-flex align-items-center px-1">
            @if (!img.editMode) {
            <p class="file-name ellipsis">{{ img.fileName }}</p>
            @if (!img.hasError || !hasError) {
            <fa-icon kipStopPropagation [class.pointer-events-none]="isUploading || isUploaded"
              [icon]="icons.penToSquare" (click)="onOpenEditMode(img)" />
            }
            <fa-icon kipStopPropagation [class.pointer-events-none]="isUploading || isUploaded" [icon]="icons.xMark"
              (click)="onRemove(img)" />
            } @else {
            <input class="edit-file-input" type="text" [id]="editInputId" [(ngModel)]="img.fileName"
              (keydown.enter)="onEdit(img)">
            <fa-icon kipStopPropagation [icon]="icons.check" (click)="onEdit(img)" />
            }
            @if (img.isUploading && isUploading) {
            <fa-icon class="d-flex align-items-center ms-auto file-icon file-loader-icon"
              [animation]="img.isUploading?'spin':undefined" [icon]="icons.reload" />
            }
            @if (img.hasError || hasError) {
            <fa-icon class="d-flex align-items-center ms-auto file-icon file-loader-icon"
              [icon]="icons.circleExclamation" />
            }
            @if (img.isUploaded && !isUploading && (!img.hasError && !hasError)) {
            <fa-icon class="d-flex align-items-center ms-auto file-icon file-success-icon" [icon]="icons.circleCheck" />
            }
          </div>
          <div class="file-info d-flex align-items-center">
            {{ img.size }} <div class="file-date-info ellipsis">{{ img.uploadingInfo }}</div>
          </div>
        </div>
      </div>
      @if (img.isUploading && isUploading || isUploaded) {
      <div class="kip-file-loader-body">
        <div class="kip-file-loader" [style.width.%]="isUploaded ? 100 : img.progress"></div>
      </div>
      }
    </div>
  </div>
  }
</div>
}