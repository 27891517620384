@if (contact(); as contact) {
<article class="kip-contact kip-card kip-card--summary">
  <header class="d-flex mb-3">
    <h2 class="fw-light">{{ contact.fullName }}</h2>
    @if (canEdit()) {
    <button class="btn btn-link btn-link--small text-primary ms-auto" type="button"
      (click)="edit.emit(contact)">Edit</button>
    }
  </header>
  @if (isPrimaryOrEmergency()) {
  <div class="d-flex mb-3">
    @if (contact.isPrimary) {
    <span class="kip-badge kip-badge--primary me-1 text-uppercase">Primary</span>
    }
    @if (contact.isEmergency) {
    <span class="kip-badge kip-badge--primary me-1 text-uppercase">Emergency</span>
    }
  </div>
  }
  <div class="d-flex flex-column">
    @if (relationship(); as relationship) {
    <div class="kip-info">
      <div class="kip-info__heading">Relationship</div>
      <div class="kip-info__entry mb-0">{{ relationship }}</div>
    </div>
    }
    @if (contact.phone) {
    <div class="kip-info">
      <div class="kip-info__heading">Phone</div>
      <div class="kip-info__entry mb-0">{{ contact.phone }}</div>
    </div>
    }
    @if (contact.email) {
    <div class="kip-info">
      <div class="kip-info__heading">Email</div>
      <div class="kip-info__entry mb-0">{{ contact.email }}</div>
    </div>
    }
    @if (contact.userName) {
    <div class="kip-info">
      <div class="kip-info__heading">Username</div>
      <div class="kip-info__entry mb-0">{{ contact.userName }}</div>
    </div>
    }
  </div>
</article>
}