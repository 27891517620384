/* eslint @typescript-eslint/no-useless-constructor : 0 */

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AnswerType } from '../../../../models';
import { QuestionControl } from '../question-control';

@Component({
  selector: 'kip-generic-readonly',
  templateUrl: './readonly.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadonlyComponent extends QuestionControl implements OnInit {

  #width: number | undefined;

  get width() {
    return this.#width;
  }

  get values(): AnswerType[] {
    return [];
  }

  ngOnInit() {
    if (this.parameters) {
      this.#width = this.parameters.width;
    }
  }
}
