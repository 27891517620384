<div class="pt-3">
  @if (!resettingPassword) {
  <label class="w-100">Security</label>
  }
  @if (!resettingPassword) {
  <button class="btn btn-link p-0 align-self-start" type="button" (click)="resettingPassword = true">Reset
    Password</button>
  }
  @if (resettingPassword) {
  <div>
    <kip-readonly-control id="username" label="UserName" [value]="username" />
    <kip-form-group-wrapper [formGroup]="passwordForm" [showCancel]="true" (cancelled)="resettingPassword = false"
      (submittedAndValidated)="submit()">
      <kip-password-form-partial [form]="passwordForm" />
    </kip-form-group-wrapper>
  </div>
  }
</div>