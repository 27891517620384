<form [formGroup]="noteForm" (ngSubmit)="submit()">
  @if (note(); as note)
  {
  @if (!note.date) {
  <div class="mb-3">
    Anything to report on today's session?
  </div>
  }
  @else {
  <div class="mb-3">
    Anything to report on the session dated {{ note!.date! | dateTime: 'DD-MMM-YY'}}?
  </div>
  }
  }
  <kip-emoji-edit label="Internal" name="sessionInternal" ngDefaultControl subLabel="PRIVATE" [edit]="true"
    [formControl]="internalNote" />
  @if (allowInternalRanking()) {
  <div class="kip-form-group">
    <kip-ranking-picker name="internalRanking" prefix="I" [allowNull]="true" [ranking]="internalNoteRanking" />
  </div>
  }
  <hr class="my-3">
  <kip-emoji-edit label="Parent" name="sessionParent" ngDefaultControl subLabel="SHARED" [edit]="true"
    [formControl]="parentNote" [initFocus]="false" />
  @if (allowParentRanking()) {
  <div class="kip-form-group">
    <kip-ranking-picker name="parentRanking" prefix="P" [allowNull]="true" [ranking]="parentNoteRanking" />
  </div>
  }
  <div class="notes-parent-warning py-3 d-flex align-items-center">
    <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
    <div class="pt-3 pb-1">
      Summary notes entered here will appear in the Parent Portal. Be sure to write clean,
      concise and respectful notes as this will be visible to the parent.</div>
  </div>
  <kip-form-error [formGroup]="noteForm" />
  <button class="btn btn-primary mt-2" type="submit">Submit Session Summary</button>
  <button class="btn btn-link ms-2 mt-2" type="button" (click)="cancelledEditing.emit()">Cancel</button>
</form>