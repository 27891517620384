import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-subject',
  templateUrl: './form-control-subject.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlSubjectComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | null>>();

}
