export enum TutorRoutes {
  Explore = 'explore',
  Help = 'help',
  Icons = 'icons',
  Changes = 'changes',
  DIY = 'diy',
  InitialPlan = 'initial-plan',
  Activities = 'activities',
  Goals = 'goals',
  Session = 'session',
  LessonPlanner = 'lesson-planner',
  Planning = 'planning',
  Settings = 'settings',
  Print = 'print',
  Mark = 'mark',
  Tutor = 'tutor',
  DropIn = 'drop-in',
  History = 'history',
  Search = 'search',
  Lesson = 'lesson',
  Next = 'next',
  Trees = 'trees',
  Videos = 'videos'
}

export enum TutorRouteParams {
  SessionId = 'sessionId',
  Index = 'index',
  PrintSessionId = 'printSessionId',
  StudentId = 'studentId',
  ActivityId = 'activityId',
  UId = 'uId',
  SubjectId = 'enrolmentId'
}

export enum SessionQueryParams {
  Teach = 'teach',
  Observe = 'observe',
  Name = 'name'
}

export function gotoPrint(sessionId?: number) {
  let baseRoute = `/${TutorRoutes.Tutor}/${TutorRoutes.Print}`;
  if (sessionId) {
    baseRoute = `/${baseRoute}/${sessionId}`;
  }
  return baseRoute;
}

export function gotoSession(sessionId: number) {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Session}/${sessionId}`;
}

export function gotoLessonPlanner(index: number) {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.LessonPlanner}/${index}`;
}

export function gotoTutorDashboard() {
  return `/${TutorRoutes.Tutor}`;
}

export function gotoChanges() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Changes}`;
}

export function gotoIcons() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Icons}`;
}

export function gotoDIY() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.DIY}`;
}

export function gotoInitialPlan() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.InitialPlan}`;
}

export function gotoVideos() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Videos}`;
}

export function gotoDropIn() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.DropIn}`;
}

export function goToDropInEdit(sessionScheduleId: number) {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.DropIn}/edit/${sessionScheduleId}`;
}

export function gotoHistory(studentId?: number, subjectId?: number) {
  let baseRoute = `/${TutorRoutes.Tutor}/${TutorRoutes.History}`;
  baseRoute = studentId && subjectId ? `/${baseRoute}/${studentId}/${subjectId}` : `/${baseRoute}/${TutorRoutes.Search}`;
  return baseRoute;
}

export function gotoNext() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Lesson}/${TutorRoutes.Next}`;
}

export function gotoPlanning(index?: number) {
  let baseRoute = `/${TutorRoutes.Tutor}/${TutorRoutes.Planning}`;
  if (index) {
    baseRoute = `/${baseRoute}/${TutorRoutes.LessonPlanner}/${index}`;
  }
  return baseRoute;
}

export function urlIsPlanning(url: string) {
  return url.includes(TutorRoutes.Planning);
}

export function exitLessonPlanner() {
  if (!urlIsPlanning(window.location.pathname)) {
    return gotoTutorDashboard();
  }

  return gotoPlanning();
}

export function gotoGoals() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Goals}/list`;
}

export function gotoActivities() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Activities}/list`;
}

export function gotoMark() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Mark}`;
}

export function gotoTree() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Trees}`;
}

export function gotoExplore(activityId?: number) {
  let baseRoute = `/${TutorRoutes.Tutor}/${TutorRoutes.Explore}`;
  if (activityId) {
    baseRoute = `/${baseRoute}/${activityId}`;
  }
  return baseRoute;
}

export function gotoHelp(uId?: string) {
  let baseRoute = `/${TutorRoutes.Tutor}/${TutorRoutes.Help}`;
  if (uId) {
    baseRoute = `/${baseRoute}/${uId}`;
  }
  return baseRoute;
}

export function gotoTutorSettings() {
  return `/${TutorRoutes.Tutor}/${TutorRoutes.Settings}`;
}
