import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService, REPORT_PROGRESS_OPTION } from 'service-lib';
import { Note } from 'student-common-lib';

import { LessonType, SessionSummary } from '../../shared';
import { AssessmentCalculations, EventsRequest, Lesson, LessonOrder, Session, SessionNote } from '../models';

export interface NewSessionLessonRequest {
  readonly enrolmentId: number;
  readonly lessonPlanId: number;
  readonly subjectId: number; // legacy logic
  readonly lessonType: LessonType;
}

export interface NewSessionRequest {
  readonly sessionScheduleId: number;
  readonly tutorId: number; // legacy logic
  readonly duration: number; // legacy logic
  readonly centreId: number; // legacy logic
  readonly isAssessment: boolean; // legacy logic
  readonly date: DateStruct;
  readonly time: string;
  readonly lessons: readonly NewSessionLessonRequest[];
}

interface NewSessionResponse {
  sessionId: number;
}

export interface AssessmentQuickEntry {
  strengths: string;
  weaknesses: string;
  goal: string;
}

@Injectable({ providedIn: 'root' })
export class SessionService extends HttpWithAbsoluteService {

  getAssessmentCalculations(): Observable<AssessmentCalculations> {
    return this.get<AssessmentCalculations>('assessments/calculations-data');
  }

  getSessionSummariesExcel(periodFrom: number, periodTo: number, unplannedOnly?: boolean) {
    return this.getBlob('tutor/sessions/excel', {
      periodFrom: periodFrom,
      periodTo: periodTo,
      unplannedOnly: unplannedOnly
    });
  }

  getSessionSummaries(periodFrom: number, periodTo: number, unplannedOnly?: boolean): Observable<SessionSummary[]> {
    return this.get<SessionSummary[]>('tutor/sessions', {
      periodFrom: periodFrom,
      periodTo: periodTo,
      unplannedOnly: unplannedOnly
    });
  }

  getSessionSummary(sessionScheduleId: number, date?: DateStruct, time?: string): Observable<SessionSummary> {
    return this.post<SessionSummary>(`tutor/sessions/single/${sessionScheduleId}`, {
      date: date,
      time: time
    });
  }

  getSession(sessionId: number, eventsRequest: EventsRequest): Observable<Session> {
    return this.post<Session>(`tutor/sessions/${sessionId}`, eventsRequest);
  }

  getSessionLesson(sessionId: number, lessonId: number, eventsRequest: EventsRequest): Observable<Lesson> {
    return this.post<Lesson>(`tutor/sessions/${sessionId}/${lessonId}`, eventsRequest);
  }

  recordSignalRError(sessionId: number): Observable<boolean> {
    return this.post<boolean>(`tutor/sessions/signalRError/${sessionId}`, null);
  }

  getTutorPhoto(tutorId: number) {
    return this.getBlob(`avatar/download-tutor-photo/${tutorId}`);
  }

  getStudentPhoto(studentId: number) {
    return this.getBlob(`avatar/download-student-photo/${studentId}`);
  }

  changeToAssessment(sessionId: number): Observable<string> {
    return this.post<string>(`tutor/sessions/change-to-assessment/${sessionId}`, {});
  }

  addCaption(sessionId: number, studentId: number | null, observerUserId: number | null, tutorId: number | null, caption: string, warning: boolean): Observable<string> {
    return this.post<string>('tutor/sessions/add-caption', {
      sessionId: sessionId,
      studentId: studentId,
      observerUserId: observerUserId,
      tutorId: tutorId,
      caption: caption,
      warning: warning
    });
  }

  createAdHocActivity(lessonPlanId: number, imageData: string, name: string, eventsRequest: EventsRequest): Observable<HttpProgressEvent | HttpResponse<number>> {
    return this.post<HttpProgressEvent | HttpResponse<number>>(
      `tutor/sessions/create-adhoc/${lessonPlanId}`,
      { imageData: imageData, name: name, eventsRequest: eventsRequest },
      undefined,
      REPORT_PROGRESS_OPTION);
  }

  getSessionNotesUpdate(sessionId: number) {
    return this.get<SessionNote>(`note/get-updates/${sessionId}`);
  }

  getAdhocImage(imageName: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/adhoc-image-url/${imageName}`, ErrorBlobPath.Image);
  }

  getActivityNote(activityGuid: string): Observable<Note> {
    return this.get<Note>(`tutor/sessions/activity-note/${activityGuid}`);
  }

  getAssessmentQuickEntry(studentId: number): Observable<AssessmentQuickEntry> {
    return this.get<AssessmentQuickEntry>(`assessments/quick-entry/${studentId}`);
  }

  updateAssessmentStrengths(studentId: number, description: string): Observable<string> {
    return this.post<string>(`assessments/strengths/${studentId}`, { description: description });
  }

  updateAssessmentWeaknesses(studentId: number, description: string): Observable<string> {
    return this.post<string>(`assessments/weaknesses/${studentId}`, { description: description });
  }

  updateAssessmentGoal(studentId: number, description: string): Observable<string> {
    return this.post<string>(`assessments/goal/${studentId}`, { description: description });
  }

  updateActivityNote(activityGuid: string, description: string): Observable<string> {
    return this.post<string>(`tutor/sessions/update-activity-note/${activityGuid}`, { description: description });
  }

  updateSessionLessonOrder(lessonOrder: LessonOrder[]): Observable<string> {
    return this.post<string>('tutor/sessions/lesson-order', lessonOrder);
  }

  setDropInSessionAvailability(sessionId: number, available: boolean): Observable<string> {
    return this.post<string>(`tutor/sessions/set-dropin-session-availability/${sessionId}`, { isAvailable: available });
  }

  generateSessionAndLessons(newSessionRequest: NewSessionRequest): Observable<NewSessionResponse> {
    return this.postNoRetry<NewSessionResponse>('tutor/sessions', newSessionRequest);
  }

  overrideLockout(studentId: number) {
    return this.post(`tutor/sessions/override-lockout/${studentId}`, null);
  }
}
