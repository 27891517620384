import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-superhero-power-b',
  templateUrl: './superhero-power-b.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSuperheroPowerBComponent implements IconComponent {

}
