import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { AnswerType, QuestionSummary } from '../lib/models';

interface StudentAnswer {
  readonly answers: readonly AnswerType[];
  readonly correct?: boolean;
}

@Component({
  selector: 'kip-solution-attempt-summary',
  templateUrl: './solution-attempt-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SolutionAttemptSummaryComponent {

  readonly icons = Icons;

  @Input() studentAnswers: readonly AnswerType[] | undefined;
  @Input() allStudentAnswers: readonly StudentAnswer[] | undefined;
  @Input() questionSummary: QuestionSummary<any> = { text: '', type: 0, answers: [], parameters: [] };

  @Output() readonly answerSelected = new EventEmitter<readonly AnswerType[]>();
  @Output() readonly displaySolution = new EventEmitter();

}
