<div class="kip-matching-layout">
  <kip-question-text [question]="question" />
  <div #container class="kip-container">
    <svg #connectors class="kip-connectors h-100 w-100" />
    <div class="d-flex flex-row">
      <ul class="kip-column kip-bring-to-front mb-0 ps-0">
        @for (sourceOption of sourceOptions; track sourceOption) {
        <li #source class="kip-option kip-source kip-bring-to-front text-center rounded mb-2 p-2"
          [class.kip-active]="sourceMatched(sourceOption) && !readonly">
          <kip-question-option [option]="sourceOption" />
        </li>
        }
      </ul>
      <div class="kip-column kip-spacer"></div>
      <ul class="kip-column kip-bring-to-front mb-0 ps-0">
        @for (targetOption of targetOptions; track targetOption) {
        <li #target
          [class]="'kip-option kip-target kip-bring-to-front text-center rounded mb-2 p-2 ' + (targetOption.valid | answerValidation: 'kip-correct': 'kip-incorrect')"
          [class.kip-active]="targetMatched(targetOption)">
          <kip-question-option [option]="targetOption" />
        </li>
        }
      </ul>
    </div>
  </div>
</div>