<div class="kip-tab">
  <div class="d-flex justify-content-between align-items-baseline w-100">
    <h2 class="kip-tab__header">Historical Goals</h2>
    <button class="btn btn-outline-primary btn-letter-spacing mb-3" type="button" (click)="toggle()">{{ enabled?
      "Hide":"Show" }}</button>
  </div>

  @if (enabled) {
  <div>
    @if (!hasResults) {
    <kip-no-results title="Looks like you don't have any historical goals set for this student!" />
    } @else {
    <div class="kip-tab m-0">
      @if (studentGoals.length > 0) {
      <div class="kip-tab__article-list mb-2">
        @for (studentGoal of studentGoals; track studentGoal) {
        <div class="kip-tab__article">
          <kip-goal-card [studentGoal]="studentGoal" />
        </div>
        }
      </div>
      }
    </div>
    }
  </div>
  }
</div>