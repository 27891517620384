import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-birthday-balloon',
  templateUrl: './birthday-balloon.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBirthdayBalloonComponent implements IconComponent {

}
