/* eslint-disable rxjs/no-ignored-subscription */

import { inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { DeviceDetectorService } from 'device-information-lib';
import * as moment from 'moment';
import { EMPTY, firstValueFrom, from, Observable, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService, ServiceEnvironment } from 'service-lib';
import { getUIVersion, ProfileService, UserProfile } from 'ui-common-lib';
import * as uuid from 'uuid';
import { WhiteboardEvent, WhiteboardGridType, WhiteboardService } from 'whiteboard-lib';

import { Sound, SoundService } from './sound.service';

export type EventType =
  'ActivityClosed' |
  'ActivityCloseRequested' |
  'ActivityCompleted' |
  'ActivityFileRemoved' |
  'ActivityFileRenamed' |
  'ActivityFilesAdded' |
  'ActivityFirstAttempt' |
  'ActivityOpened' |
  'ActivityOpenRequested' |
  'ActivityPercentage' |
  'ActivityScored' |
  'ActivitySecondAttempt' |
  'ActivityStarted' |
  'ActivityStartFirstAttempt' |
  'ActivityStartSecondAttempt' |
  'ActivityTimeAlerted' |
  'ActivityVideoArchived' |
  'AddDIYActivitiesStudent' |
  'AddDIYActivitiesTutor' |
  'AssessmentExitRequested' |
  'AssessmentHomeOpenRequested' |
  'AssessmentResultsOpenRequested' |
  'AssessmentResultTypeOpenRequested' |
  'AudioToggled' |
  'AwardGiven' |
  'BroadcastingPublishDenied' |
  'BroadcastingPublishFailure' |
  'CaptionsError' |
  'Chat' |
  'ChatUpdateTyping' |
  'CompleteDropInLesson' |
  'CustomActivityQuestionClosed' |
  'CustomActivityQuestionOpened' |
  'DropInLessonAccepted' |
  'DropInLessonCompleted' |
  'DropInSessionJoinRequestAccepted' |
  'DropInSessionJoinRequestDenied' |
  'DropInSessionJoinRequested' |
  'DropInSessionRequestCancelled' |
  'ForceReload' |
  'ForceReloadVideo' |
  'GradesAchieved' |
  'HelpAcknowledged' |
  'HelpCleared' |
  'HelpRequested' |
  'InstallApp' |
  'InstallAvailable' |
  'JoinLessonCompleted' |
  'LessonAttend' |
  'LessonAttendCompleted' |
  'LessonClosed' |
  'LessonDefer' |
  'LessonDeferCompleted' |
  'LessonDisconnected' |
  'LessonDoItYourselfToggle' |
  'LessonEnrolmentAccountCreated' |
  'LessonEnrolmentBundleCurrencyUpdated' |
  'LessonEnrolmentBundleSelected' |
  'LessonEnrolmentBundleTypeUpdated' |
  'LessonEnrolmentBundleUpdated' |
  'LessonEnrolmentCustomerRepresentsUpdated' |
  'LessonEnrolmentOpenRequested' |
  'LessonEnrolmentPaymentEntrySelected' |
  'LessonEnrolmentPaymentOptionsUpdated' |
  'LessonEnrolmentProgressUpdated' |
  'LessonEnrolmentSessionsUpdated' |
  'LessonEnrolmentSubjectsUpdated' |
  'LessonFinished' |
  'LessonFocused' |
  'LessonOpened' |
  'LessonPlanUpdated' |
  'LessonStartObserving' |
  'LessonStopObserving' |
  'LessonUnfocused' |
  'LobbyClosed' |
  'LobbyOpened' |
  'Log' |
  'NewUnitRequired' |
  'OnlineToggled' |
  'PullFromLobby' |
  'QuestionAnswered' |
  'QuestionClosed' |
  'QuestionIntroductionViewed' |
  'QuestionOpened' |
  'QuestionSkipped' |
  'QuestionUpdated' |
  'QuestionWorkedSolutionViewed' |
  'ResetPassword' |
  'ScreenShareSettingUpdated' |
  'SessionClosed' |
  'SessionDisconnected' |
  'SessionOpened' |
  'SessionOpenSuccess' |
  'SkillBuilderAdded' |
  'SkillBuilderRequestedTutor' |
  'SkillBuildersRequestedTutor' |
  'StartBroadcastingObservers' |
  'StartedInteracting' |
  'StartedLookingTab' |
  'StartObserving' |
  'StartTeaching' |
  'StartTeachingClass' |
  'StopBroadcastingObservers' |
  'StopObserving' |
  'StoppedInteracting' |
  'StoppedLookingTab' |
  'StopTeaching' |
  'StopTeachingClass' |
  'StudentAiChat' |
  'StudentAutoLoggedOutOnIdle' |
  'StudentChat' |
  'StudentChatUpdateTyping' |
  'StudentDetailsUpdated' |
  'StudentPublishAllowed' |
  'StudentPublishDenied' |
  'StudentPublishFailure' |
  'StudentScreenSharePublishAllowed' |
  'StudentScreenSharePublishDenied' |
  'StudentScreenSharePublishFailure' |
  'StudentScreenShareStreamDestroyed' |
  'StudentStreamConnected' |
  'StudentStreamDestroyed' |
  'StudentSubscribeFailure' |
  'SystemChat' |
  'TutorAutoLoggedOutOnIdle' |
  'TutorChat' |
  'TutorChatUpdateTyping' |
  'TutorPublishAllowed' |
  'TutorPublishDenied' |
  'TutorPublishFailure' |
  'TutorScreenSharePublishAllowed' |
  'TutorScreenSharePublishDenied' |
  'TutorScreenSharePublishFailure' |
  'TutorScreenShareStreamDestroyed' |
  'TutorStreamDestroyed' |
  'TutorSubscribeFailure' |
  'TutorSystemChat' |
  'UpdateFinishLessonNote' |
  'UpdateFormFields' |
  'UpdateLessonDoItYourself' |
  'UpdateStartLessonNote' |
  'VideoEffectRequested' |
  'VideoToggled' |
  'WhiteboardClosed' |
  'WhiteboardCloseRequested' |
  'WhiteboardEvent' |
  'WhiteboardGridType' |
  'WhiteboardOpened' |
  'WhiteboardOpenRequested';

export interface DeviceData {
  readonly browser: string;
  readonly browserVersion: string;
  readonly device: string;
  readonly deviceType: string;
  readonly os: string;
  readonly osVersion: string;
  readonly screenHeight: string;
  readonly screenWidth: string;
  readonly uiVersion: string;
}

interface ActionCreatorConverter<T2 extends object> {
  action: ActionCreator<string, (props: T2) => Action & T2>;
  converter: (...args: any[]) => T2;
  sounds?: { sound: Sound, play: (args: T2) => boolean }[];
}

export abstract class MessagingService extends HttpService {

  readonly #authService = inject(AuthService);
  readonly #soundService = inject(SoundService);
  readonly #deviceService = inject(DeviceDetectorService);
  readonly #whiteboardService = inject(WhiteboardService);

  readonly #maxRetries = 5;
  readonly #retryDelayMs = 5000;
  private desiredState: HubConnectionState = HubConnectionState.Disconnected;
  private isAuthenticated = false;
  private receivedEvent = false;
  protected hub: HubConnection | undefined;
  protected userProfile: UserProfile | undefined;
  protected readonly profileService = inject(ProfileService);

  constructor(
    private readonly module: string,
    private readonly stream: string) {

    super();
    this.#authService.isLoggedIn.subscribe(isLoggedIn => this.isAuthenticated = isLoggedIn);
  }

  get deviceData() {
    const deviceInfo = this.#deviceService.getDeviceInfo();

    const uiVersion = getUIVersion();

    const deviceData: DeviceData = {

      browser: deviceInfo.browser,
      browserVersion: deviceInfo.browser_version,
      device: deviceInfo.device,
      deviceType: deviceInfo.deviceType,
      os: deviceInfo.os,
      osVersion: deviceInfo.os_version,
      screenHeight: window.innerHeight.toString(),
      screenWidth: window.innerWidth.toString(),
      uiVersion: uiVersion
    };

    return deviceData;
  }

  connect(): Observable<void> {
    let promise = Promise.resolve();

    // See https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio
    // for more information on signal r

    if (!this.hub) {
      // Build the hub
      this.hub = new HubConnectionBuilder()
        .withUrl(`${ServiceEnvironment.value.api?.endpoint}/messaging/${this.stream}`, {
          accessTokenFactory: () => firstValueFrom(this.#authService.getToken())
        })
        .build();
      // Add a handler to catch any close errors, so reconnects can happen
      this.hub.onclose(error => {
        console.log(`SignalR hub closed - ${error}`);
        if (this.desiredState === HubConnectionState.Connected) {
          this.reconnect();
        }
      });

      this.desiredState = HubConnectionState.Connected;

      // Start the hub
      promise = this.hub
        .start()
        .then(() => {
          this.receivedEvent = false;
          console.log(`Connected SignalR - ${this.hub?.connectionId}`);
        }, (error: unknown) => {
          console.log(`SignalR Error ${error}`);
        });
    }

    return from(promise);
  }

  disconnect(): Observable<void> {
    let promise = Promise.resolve();

    this.desiredState = HubConnectionState.Disconnected;

    if (this.hub) {
      promise = this.hub
        .stop()
        .then(() => {
          console.log('Disconnected SignalR');
          this.hub = undefined;
        });
    }

    return from(promise);
  }

  abstract onReceiveEventTrue(): void;

  abstract onReconnect(): void;

  protected sendWhiteboardGridType(path: string, lessonGuid: string, activityGuid: string, pageGuid:
    string, gridType: WhiteboardGridType, broadcastLessonGuid: string): Observable<string> {

    // broadcast lesson guid is used so when reviewing last homework whiteboard
    // changes made are visible to the tutor and student on current weeks channel

    const args = {
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      pageGuid: pageGuid,
      gridType: gridType,
      broadcastLessonGuid: broadcastLessonGuid
    };

    return this.post(`whiteboard/grid-type/${path}`, args);
  }

  protected sendWhiteboardEvent<TState>(path: string, store: Store<TState>, lessonGuid: string, activityGuid: string, pageGuid:
    string, event: WhiteboardEvent, broadcastLessonGuid: string): Observable<string> {

    const eventId = uuid.v1();
    const timestamp = moment.utc().toDate();

    // broadcast lesson guid is used so when reviewing last homework whiteboard
    // changes made are visible to the tutor and student on current weeks channel

    const args = {
      eventId: eventId,
      timestamp: timestamp,
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      pageGuid: pageGuid,
      data: event,
      broadcastLessonGuid: broadcastLessonGuid
    };

    if (this.#whiteboardService.isPartialEvent(event)) {
      this.#whiteboardService.storePartialWhiteboardEvent(path, args);
      return EMPTY;
    }

    this.#whiteboardService.clearPartialWhiteboardEvent();
    this.dispatchOfflineAction('Whiteboard', store, args);

    return this.post(`whiteboard/events/${path}`, args);
  }

  protected sendEvent<TState>(name: EventType, store: Store<TState>, args: { [field: string]: any, eventId?: string, timestamp?: Date }): Observable<string> {
    // Create an event id and timestamp for the event store and return the event id as the observable result

    args.eventId = uuid.v1();
    args.timestamp = moment.utc().toDate();

    this.dispatchOfflineAction(name, store, args);

    return from(this.send(name, args))
      .pipe(
        map(() => args.eventId ?? ''),
        catchError((err: unknown, caught) => {
          console.log(`Sent event: ${name} - error ${err}`);
          return caught;
        })
      );
  }

  protected send(name: string, args: any): Promise<void> {
    let promise = Promise.resolve();

    if (this.isConnected() && // If a hub is available then validate the auth session
      // When validating the session pass the current url for redirect if login is required
      this.isAuthenticated) {

      // Log the event name for debugging
      console.log(`Sent Event: ${name} - ${this.hub?.state}`);

      // Invoke the send
      if (this.hub) {
        promise = this.hub.send.apply(this.hub, [name, args]);
      }
    } else {
      console.log(`Tried to send event: ${name} - but not connected/authenticated`);
    }

    return promise;
  }

  protected receiveEventSimple<TState>(
    name: EventType, store: Store<TState>, ...actions: ActionCreator<string, () => Action>[]) {
    this.receive(name, () => {
      for (const action of actions) {
        const dispatchAction: Action = action();
        store.dispatch(dispatchAction);
      }
    });
  }

  protected receiveEvent<TState, T2 extends object>(
    name: EventType, store: Store<TState>, ...actions: ActionCreatorConverter<T2>[]) {
    this.receive(name, (...args) => {
      for (const action of actions) {
        const convertedArgs = action.converter(args);
        const dispatchAction: Action = action.action(convertedArgs);
        if (action.sounds) {
          for (const sound of action.sounds) {
            if (sound.play(convertedArgs)) {
              this.#soundService.play(sound.sound);
            }
          }
        }
        store.dispatch(dispatchAction);
      }
    });
  }

  protected receive(name: EventType, handler: (...args: any[]) => void) {

    // If a hub is available register the receive handler
    if (this.hub) {
      this.hub.on(name, args => {
        console.log(`Received Event: ${name}`);

        if (!this.receivedEvent) {
          this.receivedEvent = true;
          this.onReceiveEventTrue();
        }

        // We received an array of args, so use the apply() to split them out
        // eslint-disable-next-line prefer-spread
        handler.apply(undefined, args);
      });
    }
  }

  protected isConnectedAndReceivedEvent() {
    return this.isConnected() && this.receivedEvent;
  }

  protected isConnected(): boolean {
    const hub: HubConnection | undefined = this.hub;
    if (hub) {
      return hub.state === HubConnectionState.Connected;
    }
    return false;
  }

  private dispatchOfflineAction<TState>(name: string, store: Store<TState>, ...args: any[]) {

    // Fire an action so offline events can be tracked in local storage
    store.dispatch({
      type: `${this.module} > Event > ${name}`,
      args: args
    });
  }

  private reconnect(optionalCount?: number) {
    // Reconnect after delay to allow state to settle
    // Limiting the number of retries as not everything is recoverable.
    const count = optionalCount ?? 1;

    timer(this.#retryDelayMs).subscribe(() => {
      if (this.hub) {
        console.log(`Reconnecting SignalR ...attempt ${count}`);
        this.hub
          .start()
          .then(async () => {
            console.log('Reconnected SignalR');
            // eslint-disable-next-line @typescript-eslint/await-thenable, @typescript-eslint/no-confusing-void-expression
            await this.onReconnect();
          })
          .catch((error: unknown) => {
            console.log(`Reconnect error - ${error}`);
            if (count <= this.#maxRetries) {
              this.reconnect((count ?? 0) + 1);
            } else {
              console.log(`Stopped attempting to reconnect after ${count} attempts due to ${error}`);
            }
          });
      }
    });
  }
}
