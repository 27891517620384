/* eslint-disable kip/decorators-out-of-order-before-constructor */

import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { Subscription } from 'rxjs';

import { NgbDateFormatter } from '../../helpers/ngb-date-formatter';
import { DateValidator } from './date-validator';

@Component({
  selector: 'kip-date-picker',
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFormatter }],
  templateUrl: './date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnInit, OnDestroy {

  readonly #ngbCalendar = inject(NgbCalendar);

  #subscriptions: Subscription[] = [];
  readonly icons = Icons;

  get weekdays() {
    return this.weekday
      ? Array.isArray(this.weekday.value)
        ? this.weekday.value
        : this.weekday.value !== null
          ? [this.weekday.value]
          : []
      : [];
  }

  @Input({ required: true }) date: FormControl<DateStruct | null> | undefined;
  @Input() weekday: FormControl<number[] | number | null> | undefined;
  @Input() minDate: DateStruct = { year: 0, month: 1, day: 1 };
  @Input() maxDate: DateStruct = { year: 9999, month: 1, day: 1 };

  @Input() optional = false;
  @Input() placeholder = '';
  @Input() name = '';
  @Input() label = '';
  @Input() labelClass: string | undefined;

  isDisabled = (date: NgbDate) => {
    if (this.weekday) {
      // the mod 7 is needed because our weekdays works from 0 to 6
      // and ng bootstrap weekday works from 1 to 7
      return !this.weekdays.includes(this.#ngbCalendar.getWeekday(date) % 7);
    }

    return false;
  };

  ngOnInit() {
    if (this.weekday) {
      this.#subscriptions.push(
        this.weekday.valueChanges.subscribe(() => {
          this.#update();
        }));
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #update() {
    if (this.date?.value && this.weekday) {

      // Add the custom validator to make sure keyboard input dates
      // are also validated for correct weekday.
      this.date.clearValidators();
      /*eslint-disable @typescript-eslint/unbound-method */
      this.date.setValidators([DateValidator(this.weekdays), Validators.required]);
      /*eslint-enable @typescript-eslint/unbound-method */
      this.date.updateValueAndValidity();

      // If date is in the past, find the next date that is the correct day of week
      let momentDate = moment([this.date.value.year, this.date.value.month - 1, this.date.value.day]);
      const today = moment().toStartOfDay();

      if (momentDate.isBefore(today)) {
        const upcomingWeekdays = [
          ...this.weekdays.filter(d => d >= today.day()),
          ...this.weekdays.filter(d => d < today.day()).map(d => d + 7)
        ].sort((a, b) => a - b);
        momentDate = moment(today).day(upcomingWeekdays[0]);
      }

      if (momentDate.isSameOrAfter(today)) {
        const upcomingWeekdays = [
          ...this.weekdays.filter(d => d >= today.day()),
          ...this.weekdays.filter(d => d < today.day())
        ].sort((a, b) => a - b);
        momentDate.day(upcomingWeekdays[0]);
      }

      // emitEvent is false, so that this does not go into loops
      const ngbDate = new NgbDate(momentDate.year(), momentDate.month() + 1, momentDate.date());
      this.date.setValue(ngbDate, { emitEvent: false });
      this.date.markAsTouched();
    }
  }

}
