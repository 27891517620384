import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Icons } from 'icon-lib';

import { OverlaysBackgroundColor } from '../../models';

@Component({
  selector: 'kip-form-control-overlays',
  templateUrl: './form-control-overlays.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlOverlaysComponent {

  readonly icons = Icons;

  readonly control = input.required<FormControl<OverlaysBackgroundColor>>();
  readonly label = input('');
  readonly toggled = output();

}
