import { createAction, props } from '@ngrx/store';
import { WhiteboardEvent, WhiteboardGridType, WhiteboardGuidKey } from 'whiteboard-lib';

enum WhiteboardActionTypes {
  Send = 'Tutor > Whiteboard > Send',
  RequestOpen = 'Tutor > Whiteboard > Request Open',
  RequestClose = 'Tutor > Whiteboard > Request Close',
  StudentOpen = 'Tutor > Whiteboard > Student Open',
  StudentClose = 'Tutor > Whiteboard > Student Close',
  Receive = 'Tutor > Whiteboard > Receive',
  SendGridType = 'Tutor > Whiteboard > Send Grid Type',
  ReceiveGridType = 'Tutor > Whiteboard > Receive Grid Type'
}

export const sendGridTypeAction = createAction(
  WhiteboardActionTypes.SendGridType,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly gridType: WhiteboardGridType;
    readonly broadcastLessonGuid: string;
  }>()
);

export const requestOpenAction = createAction(
  WhiteboardActionTypes.RequestOpen,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const requestCloseAction = createAction(
  WhiteboardActionTypes.RequestClose,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const studentOpenAction = createAction(
  WhiteboardActionTypes.StudentOpen,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const studentCloseAction = createAction(
  WhiteboardActionTypes.StudentClose,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const receiveGridTypeAction = createAction(
  WhiteboardActionTypes.ReceiveGridType,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly gridType: WhiteboardGridType;
  }>()
);

export const sendAction = createAction(
  WhiteboardActionTypes.Send,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly event: WhiteboardEvent;
    readonly broadcastLessonGuid: string;
  }>()
);

export const receiveAction = createAction(
  WhiteboardActionTypes.Receive,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly event: WhiteboardEvent;
  }>()
);

