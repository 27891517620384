@if (lesson(); as lesson) {
<div (click)="openLesson.emit()">
  <div class="h-100">
    <div class="kip-student-my-day__lesson-card d-flex border border-primary rounded-lg h-100 cursor-pointer">
      <div
        class="kip-myday__subject-icon--homework alert-primary border-end m-3 me-0 rounded-lg small text-primary text-center d-flex justify-content-center align-items-center p-2">
        <div
          class="align-items-center d-flex h-100 justify-content-center kip-myday__subject-icon kip-myday__subject-icon--english w-100">
          <fa-icon class="text-primary" size="2xl" [icon]="icons.activityAssociation.diy" />
        </div>
      </div>
      <div class="col d-flex flex-column w-75 p-3">
        <div class="text-overline text-secondary small mb-1"> Do it yourself </div>
        <div class="d-flex small mt-1">
          <fa-icon class="text-primary me-2" size="1x" [fixedWidth]="true" [icon]="lesson.subject.id | subjectIcon" />
          {{ lesson.subject.title }}
        </div>
        <div class="d-flex small mt-1">
          <fa-icon class="text-primary me-2" size="1x" [fixedWidth]="true" [icon]="icons.calendar.main" />
          {{ lesson.dateTime | dateFromOffset : 'Do MMM' }} - {{ lesson.lessonAvailableUntil |
          dateFromOffset : 'Do MMM' }}
        </div>
      </div>
      @if (displayOpenButtons()) {
      <div class="d-flex align-items-center justify-content-center">
        <div
          class="btn btn-primary btn-circle d-flex justify-content-center align-items-center border-0 shadow-btn me-3">
          <fa-icon class="text-white z-index-20" size="xl" [fixedWidth]="true" [icon]="icons.arrows.right.regular" />
        </div>
      </div>
      }
    </div>
  </div>
</div>
}