@if (certificateNode) {
<div class="kip-activity-selector kip-activity d-flex flex-row justify-content-between border-top p-2"
  (click)="nodeSelected()">
  <div class="flex-column text-start">
    {{ certificateNode.path }}
  </div>
  <div class="flex-column text-end">
    @if (certificateNode.data) {
    <span> {{ certificateNode.data.otherAttributes }}</span>
    }
    @if (certificateNode.data) {
    <span> {{ certificateNode.data.pageSize }}</span>
    }
    @if (certificateNode.children.length > 0) {
    <fa-icon [icon]="certificateNode.expanded ? icons.chevrons.down : icons.chevrons.right" />
    }
  </div>
</div>
}
@if (certificateNode && certificateNode.expanded) {
<div>
  @if (certificateNode.children) {
  <div>
    @for (node of certificateNode.children; track node) {
    <div class="ps-4">
      <kip-certificate-node [certificateNode]="node" (select)="select.emit($event)" />
    </div>
    }
  </div>
  }
</div>
}