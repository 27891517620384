import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-wizard-magic-e',
  templateUrl: './wizard-magic-e.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconWizardMagicEComponent implements IconComponent {

}
