import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgbDropdownModule, NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { IconLibModule } from 'icon-lib';
import { JiraLibModule } from 'jira-lib';
import { PdfLibModule } from 'pdf-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { QuestionsLibModule } from 'questions-lib';
import { ServiceLibModule } from 'service-lib';
import { UiCommonLibModule } from 'ui-common-lib';
import { WhiteboardModule } from 'whiteboard-lib';

import { ActivityComponent } from './activity-selector/activity/activity.component';
import { ActivityHistoryComponent } from './activity-selector/activity-history/activity-history.component';
import { ActivitySelectorComponent } from './activity-selector/activity-selector.component';
import { CurriculumNodeComponent } from './activity-selector/curriculum-node/curriculum-node.component';
import { CurriculumNodesComponent } from './activity-selector/curriculum-nodes/curriculum-nodes.component';
import { DIYRuleComponent } from './activity-selector/diy-rule/diy-rule.component';
import { StatsComponent } from './activity-selector/stats/stats.component';
import { ExploreComputerComponent } from './explore/computer/explore-computer.component';
import { ExploreComponent } from './explore/explore.component';
import { ExploreManualComponent } from './explore/manual/explore-manual.component';
import { DIYActivityNamesPipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconLibModule,
    JiraLibModule,
    NgbDropdownModule,
    NgbTooltipModule,
    PdfLibModule,
    PipesDirectivesLibModule,
    QuestionsLibModule,
    ServiceLibModule,
    UiCommonLibModule,
    WhiteboardModule
  ],
  declarations: [
    ActivityComponent,
    ActivityHistoryComponent,
    ActivitySelectorComponent,
    CurriculumNodeComponent,
    CurriculumNodesComponent,
    DIYActivityNamesPipe,
    DIYRuleComponent,
    ExploreComponent,
    ExploreComputerComponent,
    ExploreManualComponent,
    StatsComponent
  ],
  exports: [
    ActivityComponent,
    ActivityHistoryComponent,
    ActivitySelectorComponent,
    CurriculumNodeComponent,
    CurriculumNodesComponent,
    DIYActivityNamesPipe,
    DIYRuleComponent,
    ExploreComponent,
    ExploreComputerComponent,
    ExploreManualComponent,
    StatsComponent
  ]
})

export class ExploreLibModule { }
