import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';
import { PagedList, PageRequest } from 'ui-common-lib';

import { GenericName } from '../../models';
import { AIPrompt, AIRequest, GeneratedActivity, OrganisationActivity, OrganisationActivityContent, OrganisationActivityFilter, OrganisationActivityFull } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrganisationActivityService extends HttpWithAbsoluteService {

  readonly activityEndpoint = 'tutor/activities';

  getPdf(path: string): Observable<HttpResponse<Blob>> {
    return this.getUrlAndDownloadWithErrorBlob(`assets/activity-pdf-url/${path}`, ErrorBlobPath.Pdf, path === null);
  }

  clearPdf(activityId: number) {
    return this.get<string>(`assets/clear-pdf/${activityId}`);
  }

  clearPdfSolution(activityId: number) {
    return this.get<string>(`assets/clear-pdf-solution/${activityId}`);
  }

  uploadPdf(file: File, activityId: number) {
    const formData = new FormData();
    formData.append('activityId', activityId.toString());
    formData.append('file', file);
    return this.postFormData<{ destination: string }>(`assets/upload-pdf/${activityId}`, formData);
  }

  uploadPdfSolution(file: File, activityId: number) {
    const formData = new FormData();
    formData.append('activityId', activityId.toString());
    formData.append('file', file);
    return this.postFormData<{ destination: string }>(`assets/upload-pdf-solution/${activityId}`, formData);
  }

  generateQuestions(aiPrompt: AIRequest): Observable<GeneratedActivity> {
    return this.post<GeneratedActivity>('ai/questions', aiPrompt);
  }

  addQuestions(activityId: number, generatedActivity: GeneratedActivity): Observable<boolean> {
    return this.post<boolean>('ai/add-questions', { activityId: activityId, generatedActivity: generatedActivity });
  }

  getAITopics(): Observable<string[]> {
    return this.get<string[]>('ai/topics');
  }

  getAIPrompts(): Observable<AIPrompt[]> {
    return this.get<AIPrompt[]>('ai/prompts');
  }

  getOrganisationActivityTypes(): Observable<GenericName[]> {
    return this.get<GenericName[]>(`${this.activityEndpoint}/organisation-activity-types`);
  }

  getOrganisations(): Observable<OrganisationActivityContent> {
    return this.get<OrganisationActivityContent>(`${this.activityEndpoint}/organisations`);
  }

  getOrganisationActivity(activityId: number): Observable<OrganisationActivityFull> {
    return this.get<OrganisationActivityFull>(`${this.activityEndpoint}/organisation-activity/${activityId}`);
  }

  queryOrganisationActivities(page: PageRequest<OrganisationActivityFilter>): Observable<PagedList<OrganisationActivityFull>> {
    return this.post<PagedList<OrganisationActivityFull>>(`${this.activityEndpoint}/organisation-activities/query`, page);
  }

  newActivity(organisationActivity: OrganisationActivity): Observable<OrganisationActivityFull> {
    return this.put<OrganisationActivityFull>(`${this.activityEndpoint}/create-organisation-activity`, organisationActivity);
  }

  updateActivity(activityId: number, organisationActivity: OrganisationActivity): Observable<OrganisationActivityFull> {
    return this.post<OrganisationActivityFull>(`${this.activityEndpoint}/update-organisation-activity/${activityId}`, organisationActivity);
  }
}
