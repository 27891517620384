@for (activity of plannedActivities(); track activity) {
<div class="row g-0 border-bottom p-2"
  [ngClass]="activity.percentage | percentageResult : 'history-list__result-indicator--success':'history-list__result-indicator--warning':'history-list__result-indicator--danger'">
  <div class="col-12 col-lg d-flex align-items-center text-truncate me-3">
    <fa-icon class="px-2" [fixedWidth]="true" [icon]="activity | activityBaseIcon" />
    <fa-icon class="px-2" [icon]="icons.comment" [ngClass]="{'invisible': !activity.html}"
      (click)="showHtml.emit(activity)" />
    <label class="form-check-label text-truncate" [for]="activity.activityId">
      {{ activity.name | currencySwap : regionId() }}@if (activity.description ) {
      <span class="text-muted"> - {{
        activity.description | currencySwap : regionId() }}</span>
      }
    </label>
    @for (video of activity.videos; track video) {
    <fa-icon class="ms-2" [icon]="icons.video" (click)="showVideo.emit(video)" />
    }
  </div>
  <div class="col-12 col-lg-auto mt-1 mt-lg-0 d-flex align-items-center justify-content-between justify-content-lg-end">
    <div class="history-list__time-spent text-truncate ms-2">
      <fa-icon [icon]="icons.time.regular" />
      {{ activity.completionTime | duration }}
    </div>
    @if (!activity.inProgress && !(activity.age || activity.ageResult)) {
    <div class="history-list__activity-attempted text-truncate">
      <fa-icon [icon]="icons.percent" />
      {{ (activity.correct ? activity.correct : 0) + '/' + (activity.attempted ? activity.attempted: 0) }}
      <span class="fw-bold">{{ '(' + (activity.total ? activity.total : 0) + ')' }}</span>
    </div>
    }
    @if (!activity.inProgress && (activity.age || activity.ageResult)) {
    <div class="history-list__activity-attempted text-truncate">
      <fa-icon [icon]="icons.percent" />
      {{ activity.ageResult | ageResult: (activity.age | ageFromNumber) : null : true }}
    </div>
    }
    @if (activity.inProgress) {
    <div class="history-list__activity-attempted text-truncate">
      In Progress
    </div>
    }
    @if ((activity.age || activity.ageResult)) {
    <div class="history-list__percentage fw-bold">
      {{ activity.correct}}
    </div>
    }
    @if (!(activity.age || activity.ageResult)) {
    <div class="history-list__percentage fw-bold"
      [ngClass]="activity.percentage | percentageResult : 'history-list__text-result-indicator--success':'history-list__text-result-indicator--warning':'history-list__text-result-indicator--danger'">
      {{ activity.percentage | percentage }}
    </div>
    }
    @if (showPreview()) {
    <div class="history-list__percentage fw-bold">
      <fa-icon class="px-2" [icon]="icons.preview.solid" (click)="previewActivity.emit(activity)" />
    </div>
    }
    <div class="history-list__percentage fw-bold">
      <fa-icon class="px-2" [icon]="activity.internalNote() | noteIcon : activity.parentNote(): ''"
        (click)="editActivityNote.emit(activity)" />
    </div>
  </div>
</div>
@if (activity.internalNote() || activity.parentNote()) {
<div class="row g-0 border-bottom p-2">
  <div class="col-12 col-lg d-flex align-items-center text-truncate me-3">
    @if (activity.internalNote()) {
    <div class="py-2 bg-light border-bottom">
      <fa-icon class="me-2 ms-2" size="lg" [icon]="icons.home.regular" />
      <kip-emoji-message [message]="activity.internalNote()" />
    </div>
    }
    @if (activity.parentNote()) {
    <div class="py-2 bg-light border-bottom">
      <fa-icon class="me-2 ms-2" size="lg" [icon]="icons.contacts" />
      <kip-emoji-message [message]="activity.parentNote()" />
    </div>
    }
  </div>
</div>
}
}