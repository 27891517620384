import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

import { BaseActivity } from '../models';

@Pipe({
  name: 'activityBaseIcon'
})
export class ActivityBaseIconPipe implements PipeTransform {
  transform(activity: BaseActivity): IconDefinition {

    if (activity.isManual) {
      return Icons.activityType.manual;
    }
    if (activity.activityId !== 0) {
      return Icons.activityType.computer;
    }

    return Icons.activityType.adhoc;
  }
}
