@if (!noClicked() && (updateAvailable() || updateDetected())) {
<div class="pointer-events-none fixed-bottom d-flex min-vw-100 mx-3 mb-3">
  <div class="pointer-events-auto ms-auto alert bg-white border shadow-sm col-12 col-md-6 col-xl-4 col-xxl-3 me-5"
    role="alert">
    @if (updateDetected()) {
    <div class="no-wrap">
      <span class="text-primary fw-bold mb-2">An important update is coming</span>
      <p>It is being downloaded now. Please wait.</p>
    </div>
    }
    @if (updateAvailable()) {
    <div class="no-wrap">
      <span class="text-primary fw-bold mb-2">An important update is available</span>
      <p>Please update to receive the latest features and enhancements.</p>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <button class="btn btn-link text-secondary" type="button" (click)="noClicked.set(true)">Not now</button>
      <button class="btn btn-primary" type="button" (click)="yesClicked()">Update</button>
    </div>
    }
  </div>
</div>
}