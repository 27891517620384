import { fabric } from 'fabric';

import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardTool } from '../whiteboard-tool';

export class WhiteboardPenTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Pen);
  }

  setup(color: WhiteboardColor, canvas: fabric.Canvas) {
    this.resetCanvas(canvas, true, false);
    canvas.freeDrawingBrush.color = color.definition;
    canvas.freeDrawingBrush.width = 1;
  }

  teardown(canvas: fabric.Canvas) {
    canvas.isDrawingMode = false;
  }
}
