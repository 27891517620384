import { TutorLockAction, TutorLockActivityType, TutorLockProperties } from '../models';
import { LessonEntityState } from '../store/models';

export class TutorLockResolver {

  #tutorLocked: boolean;

  readonly #state: LessonEntityState;
  readonly #properties: TutorLockProperties;

  get isTutorOpenAction() {
    return this.#properties.tutorLockAction === TutorLockAction.TutorQuestionOpenAction;
  }

  get isStudentOpenAction() {
    return this.#properties.tutorLockAction === TutorLockAction.StudentQuestionOpenAction;
  }

  get isCustomActivityType() {
    return this.#properties.tutorLockActivityType === TutorLockActivityType.CustomActivity;
  }

  constructor(
    readonly state: LessonEntityState,
    readonly properties: TutorLockProperties) {
    this.#state = state;
    this.#properties = properties;
    this.#tutorLocked = this.#state.tutorLocked;
  }

  isTutorLocked(): boolean {

    if (this.isCustomActivityType) {

      if (this.isStudentOpenAction) {
        this.#tutorLocked = this.#isTutorLockedStudentOpenCustomActivityAction(this.#state, this.#properties.questionGuid);
      }

      if (this.isTutorOpenAction) {
        this.#tutorLocked = this.#isTutorLockedTutorOpenCustomActivityAction(this.#state, this.#properties.questionGuid);
      }

    }
    else {

      if (this.isStudentOpenAction) {
        this.#tutorLocked = this.#isTutorLockedStudentOpenComputerActivityAction(this.#state, this.#properties.questionGuid);
      }

      if (this.isTutorOpenAction) {
        this.#tutorLocked = this.#isTutorLockedTutorOpenComputerActivityAction(this.#state, this.#properties.questionGuid);
      }

    }

    return this.#tutorLocked;
  }

  #isTutorLockedStudentOpenCustomActivityAction(state: LessonEntityState, studentQuestionGuid = ''): boolean {

    const isOnSameActivity = state.activityGuid === state.tutorActivityGuid;
    const isOnSameQuestion = !studentQuestionGuid && state.tutorCustomActivityPageGuid === state.customActivityPageGuid ||
      studentQuestionGuid && state.tutorCustomActivityPageGuid === studentQuestionGuid;

    if (state.tutorLocked &&
      isOnSameActivity &&
      isOnSameQuestion) {

      return false;
    }

    return state.tutorLocked;
  }

  #isTutorLockedTutorOpenCustomActivityAction(state: LessonEntityState, tutorQuestionGuid = ''): boolean {

    const isOnSameActivity = state.tutorActivityGuid === state.activityGuid;
    const isOnSameQuestion = !tutorQuestionGuid && state.tutorCustomActivityPageGuid === state.customActivityPageGuid ||
      tutorQuestionGuid && tutorQuestionGuid === state.customActivityPageGuid;

    // custom activities need to check against the activity guid as the
    // question guid can match nil (00000000-0000-0000-0000-000000000000) across multiple activities
    return !(isOnSameActivity && isOnSameQuestion);
  }

  #isTutorLockedStudentOpenComputerActivityAction(state: LessonEntityState, studentQuestionGuid = ''): boolean {

    const isOnSameQuestion = !studentQuestionGuid && state.tutorQuestionGuid === state.questionGuid ||
      studentQuestionGuid && state.tutorQuestionGuid === studentQuestionGuid;

    if (state.tutorLocked &&
      isOnSameQuestion) {
      return false;
    }

    return state.tutorLocked;
  }

  #isTutorLockedTutorOpenComputerActivityAction(state: LessonEntityState, tutorQuestionGuid = ''): boolean {

    const isOnSameActivity = state.tutorActivityGuid === state.activityGuid;
    const isOnSameQuestion = !tutorQuestionGuid && state.tutorQuestionGuid === state.questionGuid ||
      tutorQuestionGuid && state.questionGuid === tutorQuestionGuid;

    if (state.tutorLocked &&
      isOnSameQuestion) {
      return false;
    }
    else if (state.activityGuid !== '' &&
      isOnSameActivity ||
      !isOnSameQuestion) {
      // tutor hasn't changed activity but has changed questions,
      // set the lock to true to not follow the student
      return true;
    }

    return state.tutorLocked;
  }
}
