import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EmojiLibModule } from 'emoji-lib';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { ActivityNoteComponent } from './activity-note-modal/activity-note.component';
import { EditLessonNotesComponent } from './lesson-notes/edit-lesson-notes.component';
import { LessonNotesComponent } from './lesson-notes-modal/lesson-notes.component';
import { EditSessionNotesComponent } from './session-notes/edit-session-notes.component';
import { SessionNotesComponent } from './session-notes-modal/session-notes.component';

@NgModule({
  declarations: [
    ActivityNoteComponent,
    EditLessonNotesComponent,
    EditSessionNotesComponent,
    LessonNotesComponent,
    SessionNotesComponent
  ],
  imports: [
    CommonModule,
    EmojiLibModule,
    FormsLibModule,
    IconLibModule,
    NgbTooltipModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    ActivityNoteComponent,
    EditLessonNotesComponent,
    EditSessionNotesComponent,
    LessonNotesComponent,
    SessionNotesComponent
  ]
})
export class TutorNotesLibModule { }
