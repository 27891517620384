import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kip-readonly-control',
  templateUrl: './readonly-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadonlyControlComponent {

  @Input({ required: true }) id = 'id';
  @Input({ required: true }) label = 'label';
  @Input({ required: true }) value = '';
}
