<div #kipWhiteboardToolbarContainer
  class="kip-whiteboard-toolbar-container d-flex flex-column align-items-center justify-content-between bg-white mt-5"
  style="pointer-events: all;" [class.closing]="closing || manualActivityWhiteboardClosed">
  <div class="btn kip-btn shadow-btn border-0 rounded w-75 py-1 m-1" [ngClass]="{'disabled':isUpScrollDisabled}"
    [style.display]="showToolNavButtons ? 'block' : 'none'" (click)="toolsScrollUp()">
    <fa-icon size="1x" [icon]="icons.carets.up" />
  </div>
  <div #toolArea class="flex-fill h-100 overflow-hidden" [style.padding-bottom]="showToolNavButtons ? '60px' : '0px'">
    <div [style.margin-top]="toolsOffset">
      <div class="d-flex flex-column">
        <kip-whiteboard-icon-color class="kip-item btn kip-btn border-0 mt-1" ngbTooltip="Colour" placement="left"
          [color]="currentColor" (click)="showColors = !showColors" />
      </div>
      <div class="d-flex flex-column border-top mt-1">
        @for (tool of tools; track tool) {
        <div class="kip-tool-item" placement="left" [ngbTooltip]="tool.name | titlecase">
          <kip-whiteboard-icon class="kip-item btn kip-btn border-0 mt-1"
            [class.selected]="tool.selected && !isScrolling" [selected]="tool.selected" [toolbarSvg]="tool.name"
            (click)="selectTool(tool.name)" (touchstart)="selectTool(tool.name)" />
        </div>
        }
      </div>
      <div class="d-flex flex-column border-top mt-1">
        <kip-whiteboard-icon-undo class="kip-item btn kip-btn border-0 mt-1" ngbTooltip="Undo" placement="left"
          (click)="undo.emit()" (touchstart)="undo.emit()" />
        <kip-whiteboard-icon-redo class="kip-item btn kip-btn border-0 mt-1" ngbTooltip="Redo" placement="left"
          (click)="redo.emit()" (touchstart)="redo.emit()" />
      </div>
      <div class="d-flex flex-column border-top my-1">
        <kip-whiteboard-icon-print class="kip-item btn kip-btn border-0 mt-1" ngbTooltip="Print" placement="left"
          (click)="printItem()" (touchstart)="printItem()" />
        <kip-whiteboard-icon-clear class="kip-item btn kip-btn border-0 mt-1" ngbTooltip="Clear" placement="left"
          (click)="openModal()" (touchstart)="openModal()" />
      </div>
    </div>
  </div>
  <div class="btn kip-btn shadow-btn border-0 rounded w-75 py-1 m-1" [ngClass]="{'disabled':isDownScrollDisabled}"
    [style.display]="showToolNavButtons ? 'block' : 'none'" (click)="toolsScrollDown()">
    <fa-icon size="1x" [icon]="icons.carets.down" />
  </div>
  @for (tool of tools; track tool) {
  <div class="kip-tool-item position-absolute top-0 d-flex justify-content-center align-items-center h-100 me-4"
    style="pointer-events: all;">
    @if (tool.showOptions && tool.options.length > 0 && !showColors) {
    <div class="kip-options d-flex flex-column align-items-center p-1 shadow-btn rounded">
      @for (option of tool.options; track option) {
      @if (option.visible) {
      <div class="kip-item btn kip-btn border-0 text-primary p-2 mt-1 selected"
        [class.selected]="tool.selectedOption === option.name && !isScrolling"
        (click)="selectToolOption(tool.name, option.name)">
        @if (option.svg) {
        <div [innerHTML]="option.svg | safe : 'html'"></div>
        }
        @if (!option.svg) {
        <fa-icon size="1x" [icon]="option.icon" />
        }
      </div>
      }
      }
    </div>
    }
  </div>
  }
  <div class="kip-tool-item position-absolute top-0 d-flex justify-content-center align-items-center h-100 me-4">
    @if (showColors) {
    <div class="kip-options d-flex flex-column align-items-center p-1 shadow-btn rounded">
      @for (color of colors; track color) {
      <kip-whiteboard-icon-color class="kip-item btn kip-btn border-0 p-2 mt-1" placement="left" [color]="color.name"
        [ngbTooltip]="color.name | titlecase" [selected]="color.selected"
        (click)="selectColor(color.name); showColors = !showColors" />
      }
    </div>
    }
  </div>
</div>
<div class="kip-question-controls" style="z-index: 20;">
  <label class="kip-whiteboard-button m-0 btn rounded-0 border-0" placement="left"
    [class.active]="!manualActivityWhiteboardClosed"
    [ngClass]="manualActivityWhiteboardClosed ? 'kip-btn' : 'kip-whiteboard-close'"
    [ngbTooltip]="manualActivityWhiteboardClosed ? 'Whiteboard' : 'Close Whiteboard'" (click)="initiateClosing()">
    @if (!manualActivityWhiteboardClosed) {
    <fa-icon class="kip-icon" size="lg" [fixedWidth]="true" [icon]="icons.close.solid" />
    }
    @if (manualActivityWhiteboardClosed) {
    <fa-icon class="kip-icon" size="lg" [fixedWidth]="true" [icon]="icons.whiteboardOpen" />
    }
  </label>
  @if (hasTouch && isManual) {
  <label class="m-0 btn bg-white rounded-0 border-0 p-1" (click)="toggleScrolling()">
    <div
      class="btn kip-btn kip-btn--no-hover border-0 selected rounded w-100 h-100 d-flex align-items-center justify-content-center"
      [class.selected]="isScrolling">
      <kip-whiteboard-icon-scroll />
    </div>
  </label>
  }
  @for (toolbarAction of toolbarActions; track toolbarAction) {
  <label class="m-0 btn kip-btn rounded-0 border-0" placement="left" [ngbTooltip]="toolbarAction.toolTip"
    (click)="toolbarAction.emit()">
    <fa-icon class="kip-icon" size="lg" [fixedWidth]="true" [icon]="toolbarAction.icon" />
  </label>
  }
</div>

<ng-template #content>
  <div class="modal-header d-flex justify-content-end border-0">
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="closeModal()">
      <fa-icon [icon]="icons.close.solid" />
    </button>
  </div>
  <div class="modal-body kip-student">
    <div class="row">
      <div class="col-5 d-flex align-items-center justify-content-center">
        <kip-monty-error class="w-100" />
      </div>
      <div class="col-7 d-flex flex-column justify-content-center">
        <h2 class="h2 text-primary fw-bold">Clear whiteboard?</h2>
        <p class="h5 text-secondary">This will clear all of your edits.</p>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 kip-student">
    <button class="btn btn-outline-danger border-0 text-btn shadow-btn" type="button" (click)="clearWhiteboard()">
      <fa-icon class="me-2" [icon]="icons.delete" /> Clear Whiteboard
    </button>
    <button class="btn btn-primary" type="button" (click)="closeModal()">Cancel</button>
  </div>
</ng-template>

<kip-graph-options-modal />