import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-body',
  templateUrl: './monty-body.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyBodyComponent implements MontyGraphic {

}
