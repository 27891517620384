import { fabric } from 'fabric';

import { WhiteboardColor } from './whiteboard-color';
import { WhiteboardTool } from './whiteboard-tool';

export class WhiteboardToolbarSelection {

  constructor(
    private readonly color: WhiteboardColor,
    public readonly tool: WhiteboardTool) { }

  selectable(obj: fabric.Object): boolean {
    return this.tool.selectable(obj);
  }

  setup(canvas: fabric.Canvas) {
    this.tool.setup(this.color, canvas);
  }

  teardown(canvas: fabric.Canvas) {
    this.tool.teardown(canvas);
  }
}
