<!-- @TODO: Move styles -->
<style>
  .kip-audio-control .kip-slider {
    width: 120px;
    height: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .kip-audio-control .audio-button {
    background-color: #FFFFFF;
    transition: background-color ease-in-out 0.3s;
  }

  .kip-audio-control .audio-button.text-success {
    color: #177060 !important
  }

  .kip-audio-control .audio-button:hover {
    background-color: #CCE7FF;
    cursor: pointer;
  }

  .kip-audio-control .kip-audio-time {
    min-width: 60px;
  }

  .kip-audio-control .kip-audio-volume {
    bottom: -50px;
    right: 4px;
    z-index: 11;
    transition: all ease-in-out 0.3s;
    opacity: 1;
  }

  .kip-audio-control .kip-audio-volume>div:nth-child(1) {
    width: 20px;
    height: 20px;
    right: 11px;
    top: -10px;
    transform: rotate(45deg);
  }

  .kip-audio-control .kip-audio-volume .kip-slider {
    width: 170px;
  }

  .kip-audio-control .kip-audio-volume.volume-hidden {
    z-index: 9;
    bottom: 0px;
    transform: scale(0);
    transform-origin: bottom right;
    opacity: 0;
    right: 0px;
  }
</style>
<div class="kip-audio-control position-relative user-select-none" [class.d-none]="!audioLoaded">
  <div class="d-flex rounded shadow-btn p-1 px-2 bg-white position-relative" style="z-index:10;">
    <fa-icon class="text-primary p-2 me-1 rounded audio-button" size="lg" [class.text-success]="playSpeed === 0.75"
      [icon]="icons.audioPlayer.slower" (click)="playSpeed = playSpeed === 0.75 ? 1 : 0.75" />
    <fa-icon class="text-primary p-2 me-1 rounded audio-button" size="lg" [fixedWidth]="true"
      [icon]="playing ? icons.audioPlayer.pause : icons.audioPlayer.play" (click)="playing = !playing" />
    <fa-icon class="text-primary p-2 me-1 rounded audio-button" size="lg" [class.text-success]="playSpeed === 1.25"
      [icon]="icons.audioPlayer.faster" (click)="playSpeed = playSpeed === 1.25 ? 1 : 1.25" />
    <div #audioPosition class="p-2 text-center kip-audio-time"></div>
    <div #audioSeek class="p-1 rounded audio-button">
      <div class="rounded-lg overflow-hidden bg-light kip-slider">
        <div class="bg-primary h-100 rounded-lg" style="width:0%"></div>
      </div>
    </div>
    <div class="p-2 text-center kip-audio-time">{{ duration }}</div>
    <fa-icon class="text-primary p-2 rounded audio-button" size="lg" [icon]="icons.audioPlayer.volume"
      (click)="showVolume = !showVolume" />
  </div>
  <div class="position-absolute rounded bg-white shadow-lg p-1 kip-audio-volume volume-hidden"
    [class.volume-hidden]="!showVolume">
    <div class="bg-white position-absolute"></div>
    <div #audioVolume class="p-1 rounded audio-button position-relative overflow-hidden">
      <div class="rounded-lg overflow-hidden bg-light kip-slider">
        <div class="bg-primary h-100 rounded-lg" style="width:40%"></div>
      </div>
    </div>
  </div>
  <audio #audioPlayer></audio>
</div>