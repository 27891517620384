import { createReducer, on } from '@ngrx/store';

import { JoinRequest } from '../../models';
import * as DropInActions from './drop-in.actions';

export interface DropInState {
  readonly requests: readonly JoinRequest[];
}

const initialState: DropInState = {
  requests: []
};

export const dropInReducer = createReducer(initialState,
  on(DropInActions.dropInSessionJoinRequestedAction, (state, action) => {
    const requests = [...state.requests];
    requests.push(action.request);
    return { requests: requests };
  }),
  on(DropInActions.dropInSessionJoinAcceptedAction, (state, action) => {
    const existingRequests = [...state.requests];
    existingRequests.splice(existingRequests.indexOf(action.request), 1);
    return { requests: existingRequests };
  }),
  on(DropInActions.dropInSessionJoinDeniedAction, (state, action) => {
    const existingRequests = [...state.requests];
    existingRequests.splice(existingRequests.indexOf(action.request), 1);
    return { requests: existingRequests };
  }),
  on(DropInActions.dropInSessionRequestCancelledAction, (state, action) => {
    const existingRequests = [...state.requests];
    existingRequests.splice(existingRequests.indexOf(action.request), 1);
    return { requests: existingRequests };
  })
);
