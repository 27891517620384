import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';

import { SentryConfig } from './sentry-config';

interface Message {
  message: string | undefined;
}

export function initializeSentry(sentryConfig?: SentryConfig, additionalErrorsToIgnore: string[] = []) {
  if (sentryConfig) {
    const chunkFailedRegex = /Loading chunk [\d]+ failed/;
    const releaseVersion = document.head.querySelector('[property="kiplearn:version"][content]')?.getAttribute('content') ?? '0.0.0';
    const dist = document.head.querySelector('[property="kiplearn:dist"][content]')?.getAttribute('content') ?? '';

    Sentry.init({
      dsn: sentryConfig.dsn,
      environment: sentryConfig.environment,
      release: releaseVersion,
      dist: dist,
      beforeSend: (event, hint) => {

        // removes Non-Error exception captured with keys bug
        if (hint?.originalException instanceof HttpErrorResponse) {
          return null;
        }

        if (hint?.originalException) {
          const originalException = hint.originalException;
          const message = typeof originalException === 'string' ? originalException : (originalException as Message).message;
          if (message && chunkFailedRegex.test(message)) {
            window.location.reload();
            return null;
          }
        }
        return event;
      },
      ignoreErrors: [
        chunkFailedRegex,
        ...additionalErrorsToIgnore
      ]
    });
  }
}
