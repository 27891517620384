<div class="kip-digital-clock-layout">
  <kip-question-text [question]="question" />
  <div class="kip-columns d-flex flex-row align-items-center">
    @if (!readonly) {
    <div class="kip-button kip-bring-to-front d-flex flex-column p-3">
      <div id="incrementBtn">
        <fa-icon class="cursor-pointer kip-increment kip-increment-hour" [icon]="icons.increment"
          (click)="updateHours(1)" (mousedown)="incrementHours()" (mouseout)="pauseTimer()" (mouseup)="pauseTimer()" />
      </div>
      <div>
        <fa-icon class="cursor-pointer kip-decrement kip-decrement-hour" [icon]="icons.decrement"
          (click)="updateHours(-1)" (mousedown)="decrementHours()" (mouseout)="pauseTimer()" (mouseup)="pauseTimer()" />
      </div>
    </div>
    }
    <div class="flex-fill">
      <div class="kip-digits fw-bold text-center text-nowrap py-2 px-4">
        <span
          [ngClass]="isValidHours !== undefined ? isValidHours? 'alert-success kip-digits-valid' : 'alert-danger kip-digits-invalid': 'kip-digits'">{{hours}}</span>
        <span class="kip-blink">:</span>
        <span
          [ngClass]="isValidMinutes !== undefined ? isValidMinutes? 'alert-success kip-digits-valid' : 'alert-danger kip-digits-invalid': 'kip-digits'">{{minutes}}</span>
      </div>
    </div>
    @if (!readonly) {
    <div class="kip-button kip-bring-to-front d-flex flex-column p-3">
      <div>
        <fa-icon class="cursor-pointer kip-increment kip-increment-min" [icon]="icons.increment"
          (click)="updateMinutes(interval, 1)" (mousedown)="incrementMinutes()" (mouseout)="pauseTimer()"
          (mouseup)="pauseTimer()" />
      </div>
      <div>
        <fa-icon class="cursor-pointer kip-decrement kip-decrement-min" [icon]="icons.decrement"
          (click)="updateMinutes(interval, -1)" (mousedown)="decrementMinutes()" (mouseout)="pauseTimer()"
          (mouseup)="pauseTimer()" />
      </div>
    </div>
    }
  </div>
</div>