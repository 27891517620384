import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { filter, Subscription } from 'rxjs';

import { Address, defaultLatLng, radiusMetresForAddressSearchs } from '../../models';
import { ScriptName, ScriptService } from '../../services';
@Component({
  selector: 'kip-address-picker',
  templateUrl: './address-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressPickerComponent implements OnInit, OnDestroy {

  readonly #scriptService = inject(ScriptService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #addressNotFound = false;
  #expandAddress = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  // google address object keys
  readonly streetNumberComponent = 'street_number';
  readonly routeComponent = 'route';
  readonly localityComponent = 'locality';
  readonly postalTownComponent = 'postal_town';
  readonly neighborhood = 'neighborhood';
  readonly administrativeAreaLevel1Component = 'administrative_area_level_1';
  readonly postalCodeComponent = 'postal_code';
  readonly countryName = 'country';
  readonly establishment = 'establishment';
  readonly placeName = 'name';
  readonly addressComponent = 'address_components';
  readonly formattedAddress = 'formatted_address';
  readonly types = 'types';
  readonly autoCompleteResultFields = [this.addressComponent, this.formattedAddress, this.placeName, this.types];

  autocomplete: google.maps.places.Autocomplete | undefined;

  get addressLine1() {
    return this.addressForm.getSafe(x => x.addressLine1);
  }

  get addressLine2() {
    return this.addressForm.getSafe(x => x.addressLine2);
  }

  get dependentLocality() {
    return this.addressForm.getSafe(x => x.dependentLocality);
  }

  get locality() {
    return this.addressForm.getSafe(x => x.locality);
  }

  get country() {
    return this.addressForm.getSafe(x => x.country);
  }

  get countryCode() {
    return this.addressForm.getSafe(x => x.countryCode);
  }

  get postalCode() {
    return this.addressForm.getSafe(x => x.postalCode);
  }

  get formattedAddressByGoogle() {
    return this.addressForm.getSafe(x => x.formattedAddressByGoogle);
  }

  get inputAddress() {
    return this.addressForm.getSafe(x => x.inputAddress);
  }

  get addressNotFound() {
    return this.#addressNotFound && !this.addressForm.valid;
  }

  @Input({ required: true }) addressForm!: FormGroupTypeSafe<Address>;
  @Input() label: string | undefined;
  @Input({ required: true }) name = '';
  @Input() latLng: google.maps.LatLngLiteral = defaultLatLng;
  @Input() autoExpand = true;

  @Input()
  set expandAddress(value: boolean) {
    this.#expandAddress = value;
    if (this.expandAddressChange.observed) {
      this.expandAddressChange.emit(value);
    }
  }

  get expandAddress(): boolean {
    return this.#expandAddress;
  }

  @ViewChild('search', { static: false }) searchElementRef: ElementRef<HTMLInputElement> | undefined;

  @Output() readonly expandAddressChange = new EventEmitter<boolean>();

  /*eslint-disable @typescript-eslint/unbound-method */

  static buildDefaultControls(fb: FormBuilderTypeSafe) {
    const validators = [Validators.required];

    return fb.group<Address>({
      addressLine1: new FormControl<string | null>(null, validators),
      addressLine2: new FormControl<string | null>(null),
      dependentLocality: new FormControl<string | null>(null, validators),
      locality: new FormControl<string | null>(null),
      postalCode: new FormControl<string | null>(null),
      country: new FormControl<string | null>(null, validators),
      formattedAddressByGoogle: new FormControl<string | null>(null),
      // This is the address either entered by user / or suggested by google
      inputAddress: new FormControl<string | null>(null),
      countryCode: new FormControl<string | null>(null)
    });
  }

  /*eslint-enable @typescript-eslint/unbound-method */

  ngOnInit() {
    /*
     * Attempt to expand the address if a value is patched/set on initialisation
     * Will only emit when the form is pristine so won't expand when typing
     * Will only emit if autoExpand is true
     * Will expand when a value with non null properties is patched
     * Will contract when a value with all null properties is patched
     */
    this.#subscriptions.push(
      this.addressForm.valueChanges
        .pipe(filter(_ => this.addressForm.pristine && this.autoExpand))
        .subscribe(value => {
          this.expandAddress = this.#hasNonNullProperties(value);
        }));

    this.#scriptService.loadScript(ScriptName.GoogleAddressPicker).then(() => {
      const autocompleteOptions: google.maps.places.AutocompleteOptions = {};
      const circle = new google.maps.Circle({ center: this.latLng, radius: radiusMetresForAddressSearchs });
      const latlongBound = circle.getBounds();
      if (latlongBound) {
        autocompleteOptions.bounds = latlongBound;
      }

      if (this.searchElementRef) {
        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, autocompleteOptions);
        this.autocomplete.setFields(this.autoCompleteResultFields);
        this.autocomplete.addListener('place_changed', () => this.#placeChanged());

        // This logic is used to display the google address if it exists from previously entered data
        this.#subscriptions.push(
          this.formattedAddressByGoogle.valueChanges.subscribe(value => {
            if (this.inputAddress.value === null && value !== null) {
              this.inputAddress.setValue(value);
            }
            this.#changeDetectorRef.markForCheck();
          }));

        // we need this to prevent default submit action.
        this.searchElementRef.nativeElement.addEventListener('keydown', (event: any) => {
          if (event.keyCode === 13) {
            event.preventDefault();
          }
        });
      }
      this.#changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggleExpandedAddress() {
    this.expandAddress = !this.expandAddress;
  }

  #placeChanged() {
    if (this.autocomplete) {
      const place = this.autocomplete.getPlace();

      // verify result
      if (place === undefined || place === null) {
        return;
      }
      // set other values
      this.#formatAddress(place);
    }
  }

  #formatAddress(place: google.maps.places.PlaceResult) {

    this.expandAddress = true;
    this.#addressNotFound = false;
    this.#reset();

    if (!place?.address_components) {
      this.#addressNotFound = true;
      return;
    }

    let name = '', streetNumber = '', route = '';

    if (place.address_components.length > 0 && this.#findAddressComponent(this.establishment, place.address_components[0].types)) {
      name = place.name ?? '';
    }

    for (const component of place.address_components) {

      if (this.#findAddressComponent(this.streetNumberComponent, component.types)) {
        streetNumber = component.long_name;
      }
      if (this.#findAddressComponent(this.routeComponent, component.types)) {
        route = component.long_name;
      }

      if (this.#findAddressComponent(this.neighborhood, component.types)) {
        this.addressLine2.setValue(component.long_name);
      }

      if (this.#findAddressComponent(this.localityComponent, component.types)
        || this.#findAddressComponent(this.postalTownComponent, component.types)) {
        this.dependentLocality.setValue(component.short_name);
      }

      if (this.#findAddressComponent(this.administrativeAreaLevel1Component, component.types)) {
        this.locality.setValue(component.long_name);
      }

      if (this.#findAddressComponent(this.postalCodeComponent, component.types)) {
        this.postalCode.setValue(component.long_name);
      }

      if (this.#findAddressComponent(this.countryName, component.types)) {
        this.country.setValue(component.long_name);
      }

      if (this.#findAddressComponent(this.countryName, component.types)) {
        this.countryCode.setValue(component.short_name);
      }
    }

    const addressLine1 = this.#formatAddressLine1(name, streetNumber, route);
    this.addressLine1.setValue(addressLine1);
    this.formattedAddressByGoogle.setValue(place.formatted_address ?? '');
    this.inputAddress.setValue(place.formatted_address ?? '');
    this.#changeDetectorRef.markForCheck();
  }

  #findAddressComponent(componentType: string, types: string[]): string {
    return types.find(t => t === componentType) ?? '';
  }

  #formatAddressLine1(name: string, streetNumber: string, route: string) {
    const tokens = [
      { value: name, join: ', ' },
      { value: streetNumber, join: ' ' },
      { value: route, join: '' }
    ];

    const formattedValue = tokens.reduce((prev, cur) => {
      const newValue = prev.value + prev.join + (cur.value ?? '');
      return { value: newValue, join: newValue ? cur.join : '' };
    }, { value: '', join: '' });

    return formattedValue.value;
  }

  #reset() {
    // This is to reset all fields so that they can be populated
    // based on search result except input address
    this.addressLine1.reset();
    this.addressLine2.reset();
    this.dependentLocality.reset();
    this.locality.reset();
    this.postalCode.reset();
    this.country.reset();
  }

  #hasNonNullProperties(obj: { [key: string]: any }): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== null && obj[key] !== undefined) {
        return true; // If at least one property is not null or undefined
      }
    }
    return false; // If all properties are null or undefined
  }
}
