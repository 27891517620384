@if (isStudent) {
<div class="w-100 border-bottom p-2">
  <div class="small mb-2">Change Theme:</div>
  <div class="d-flex justify-content-between">
    <div class="kip-dropdown__theme-swatch kip-dropdown__theme-swatch--aurora"
      [class.selected]="currentTheme === theme.Default" (click)="changeTheme(theme.Default)"></div>
    <div class="kip-dropdown__theme-swatch kip-dropdown__theme-swatch--sunset"
      [class.selected]="currentTheme === theme.Sunset" (click)="changeTheme(theme.Sunset)"></div>
    <div class="kip-dropdown__theme-swatch kip-dropdown__theme-swatch--electric-storm"
      [class.selected]="currentTheme === theme.ElectricStorm" (click)="changeTheme(theme.ElectricStorm)"></div>
    <div class="kip-dropdown__theme-swatch kip-dropdown__theme-swatch--lab-green"
      [class.selected]="currentTheme === theme.LabGreen" (click)="changeTheme(theme.LabGreen)"></div>
  </div>
</div>
}