<div class="row g-0 bg-white">
  <div class="kip-section-toggle col-12 d-flex justify-content px-3 py-2 border-bottom cursor-pointer"
    (click)="toggle()">
    <div>
      <span>Organisation</span>
    </div>
    <div class="ms-auto">
      <fa-icon [icon]="collapse ? icons.chevrons.up : icons.chevrons.down" />
    </div>
  </div>
  @if (collapse) {
  <div>
    @for (icon of organisationAwards; track icon) {
    <div class="m-2" style="width:50px;display:inline-block; cursor:pointer">
      <kip-icon [displayIcon]="icon.awardId" [skinTone]="0" (click)="awardPicked.emit(icon)" />
    </div>
    }
  </div>
  }
</div>