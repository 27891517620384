import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  readonly #useCustomKeyboard$ = new Subject<boolean>();
  readonly #qwertyKeyboard$ = new Subject<boolean>();

  get useCustomKeyboard$() {
    return this.#useCustomKeyboard$;
  }

  get qwertyKeyboard$() {
    return this.#qwertyKeyboard$;
  }

  static canvasActiveTexArea$ = new Subject<HTMLTextAreaElement>();
  static checkInputsAndTextAreas$ = new Subject<boolean>();
  static hideKeyboard$ = new Subject<null>();
  static focusedInputs: { inputMode: string, element: HTMLInputElement | HTMLTextAreaElement }[] = [];
  static isDockedAndVisible = false;
  static focusedTextAre: any;
  static isChatTextAreaFocused = false;
  static changeKeyboardPositionEvent$ = new Subject();
  static updateKeyboardPosition = () => setTimeout(() => KeyboardService.changeKeyboardPositionEvent$.next(null));
}
