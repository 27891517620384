import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import * as OT from '@opentok/client';

import { Caption } from '../models';

@Component({
  selector: 'kip-opentok-video-subscriber',
  templateUrl: './subscriber.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SubscriberComponent implements AfterViewInit, OnDestroy {

  #stream: OT.Stream | undefined;
  #session: OT.Session | undefined;
  #subscriber: OT.Subscriber | undefined;
  #subscribeAudio = false;
  #subscribeVideo = true;
  #subscribed = false;

  @Input() observerId: number | undefined;
  @Input() tutorId: number | undefined;
  @Input() studentId: number | undefined;

  @ViewChild('subscriberDiv', { static: true }) subscriberDiv: ElementRef<HTMLDivElement> | undefined;

  @Output() readonly subscribeFailure = new EventEmitter<{ name: string, message: string }>();
  @Output() readonly imageData = new EventEmitter<string>();
  @Output() readonly captionReceive = new EventEmitter<Caption>;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set session(value: OT.Session | undefined) {
    if (this.#session !== value) {
      this.#session = value;
      this.#trySubscribeWithActions();
    }
  }

  get session() {
    return this.#session;
  }

  @Input({ required: true }) set subscribeAudio(value: boolean) {
    this.#subscribeAudio = value;
    this.#trySubscribeWithActions();
  }

  get subscribeAudio() {
    return this.#subscribeAudio;
  }

  @Input() set subscribeVideo(value: boolean) {
    this.#subscribeVideo = value;
    this.#trySubscribeWithActions();
  }

  get subscribeVideo() {
    return this.#subscribeVideo;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) set stream(value: OT.Stream | undefined) {
    if (this.#stream !== value) {
      this.#stream = value;
      this.#trySubscribeWithActions();
    }
  }

  get stream() {
    return this.#stream;
  }

  constructor() {
    this.#subscribed = false;
  }

  ngAfterViewInit() {
    this.#trySubscribeWithActions();
  }

  ngOnDestroy() {
    if (this.session && this.#subscriber && this.#subscriber.stream) {
      if (this.#subscribed) {
        this.session.unsubscribe(this.#subscriber);
      }
      this.#subscribed = false;
      this.#subscriber = undefined;
    }
  }

  getImageData() {
    if (this.#subscriber) {
      const imgData = this.#subscriber.getImgData();
      if (imgData) {
        this.imageData.emit(imgData);
      }
    }
  }

  #trySubscribeWithActions()
  {
    this.#trySubscribe(() => {
      if (this.#subscriber) {
        this.#subscriber.subscribeToVideo(this.#subscribeVideo);
        this.#subscriber.subscribeToAudio(this.#subscribeAudio);
      }
    });
  }

  #trySubscribe(actionAfterSubscribe?: () => void) {
    if (this.session && this.stream) {
      if (!this.#subscriber && this.subscriberDiv) {
        this.#subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement,
          {
            height: '100%',
            width: '100%',
            showControls: false,
            subscribeToVideo: this.#subscribeVideo,
            subscribeToAudio: this.#subscribeAudio
          }, err => {
            if (err) {
              this.subscribeFailure.emit({ name: err.name, message: err.message });
            } else {
              this.#subscribed = true;
              if (actionAfterSubscribe) {
                actionAfterSubscribe();
              }
            }
          });
        this.#subscriber.subscribeToCaptions(true).then(() => {
          // do nothing
        });
        this.#subscriber.on({
          captionReceived: (event: Caption) => {
            this.captionReceive.emit(event);
          }
        });
      } else {
        if (actionAfterSubscribe) {
          actionAfterSubscribe();
        }
      }
    }
  }
}
