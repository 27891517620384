<div class="mb-3 d-flex align-items-center justify-content-center w-100">
    @if (!isLoadingHistory()) {
    @if (showLoadMore())
    {
    <button class="btn btn-lg btn-outline-primary fw-bold" type="button" (click)="loadMoreLessons.emit()">Load More
        Results</button>
    }
    }
    @else {
    <kip-spinner />
    }
</div>