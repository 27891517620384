<nav aria-label="breadcrumb">
  <ol class="kip-breadcrumbs">
    <li><a routerLink="/">
        <fa-icon size="sm" [icon]="icons.home.solid" />
      </a></li>
    @for (breadcrumb of breadcrumbs(); track breadcrumb) {
    <li>
      @if (breadcrumb.path)
      {
      <a [routerLink]="breadcrumb.path">{{ breadcrumb.name }}</a>
      }
      @else
      {
      {{ breadcrumb.name}}
      }
    </li>
    }
    <ng-content />
  </ol>
</nav>