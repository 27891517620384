<label [class]="labelClass()??''">{{ label() }}</label>
<div class="kip-form-group">
  <div class="kip-button-group btn-group btn-group-toggle">
    @for (bundleOfferType of bundleOfferTypes(); track bundleOfferType) {
    <input class="btn-check" name="bundle-offer-type-picker" type="radio" [disabled]="disabled()"
      [formControl]="bundleOfferTypeId()" [id]="bundleOfferType.id" [value]="bundleOfferType.id">
    <label class="kip-button-group__option btn" [for]="bundleOfferType.id">
      {{ bundleOfferType.name}}
    </label>
    }
  </div>
</div>