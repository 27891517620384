@if (answerBoxEntry) {
<input #element autocapitalize="off" autocomplete="off" autocorrect="off" inputmode="numeric" maxLength="1"
  spellcheck="false" [attr.data-keyboard]="keyboardType"
  [class]="'kip-bring-to-front form-control drill-answer rounded-sm ' + (valid | answerValidation: 'alert-success': 'alert-danger') + ((answerBoxEntry.correctValue === null || readonly) ? '' : ' kip-input')"
  [style.display]="answerBoxEntry.correctValue === null || readonly?'none':''"
  [tabIndex]="answerBoxEntry.tabIndex || answerBoxEntry.index" (change)="onChange()" (input)="onInput()"
  (keydown)="onKeydown($event)" (keyup)="onKeyup($event)" />
@if (answerBoxEntry.displayAnswer !== null && readonly) {
<span class="rounded-sm drill-answer"
  [ngClass]="valid | answerValidation: 'alert-success':'alert-danger'">{{answerBoxEntry.displayAnswer}}</span>
}
<span [style.display]="answerBoxEntry.correctValue === null?'':'none'">{{answerBoxEntry.displayText}}</span>
}