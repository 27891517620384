import { createReducer, on } from '@ngrx/store';

import { Alert } from '../../models';
import * as AlertActions from './alerts.actions';

export interface AlertsState {
  readonly alerts: readonly Alert[];
}

const initialState: AlertsState = {
  alerts: []
};

export const alertsReducer = createReducer(initialState,
  on(AlertActions.clearAlertsAction, (): AlertsState => {
    return {
      alerts: []
    };
  }),
  on(AlertActions.addAlertAction, (state, action) => {
    const alerts = [...state.alerts];
    alerts.push(action.alert);
    return { alerts: alerts };
  }),
  on(AlertActions.removeAlertAction, (state, action) => {
    const existingAlerts = [...state.alerts];
    existingAlerts.splice(existingAlerts.indexOf(action.alert), 1);
    return { alerts: existingAlerts };
  }),
  on(AlertActions.selectAlertActionAction, (state, action) => {
    action.alert.sendAction(action.action);
    const afterActionAlerts = [...state.alerts];
    afterActionAlerts.splice(afterActionAlerts.indexOf(action.alert), 1);
    return { alerts: afterActionAlerts };
  }),
  on(AlertActions.removeMatchingAlertsAction, (state, action) => {
    const afterRemovalAlerts = [...state.alerts];
    for (const alert of state.alerts) {
      if (action.shouldBeRemoved(alert)) {
        afterRemovalAlerts.splice(afterRemovalAlerts.indexOf(alert), 1);
      }
    }
    return { alerts: afterRemovalAlerts };
  })
);
