<fieldset class="kip-form-group">
  <legend class="legend--small">Contact Type</legend>
  <div class="custom-control custom-checkbox kip-choice">
    <div class="form-check">
      <input class="form-check-input" id="isPrimary" name="isPrimary" type="checkbox" [formControl]="isPrimary()">
      <label class="custom-control-label form-check-label form-check-label--append" for="isPrimary">Primary</label>
    </div>
  </div>
  <div class="custom-control custom-checkbox kip-choice">
    <div class="form-check">
      <input class="form-check-input" id="isEmergency" name="isEmergency" type="checkbox" [formControl]="isEmergency()">
      <label class="custom-control-label form-check-label form-check-label--append" for="isEmergency">Emergency</label>
    </div>
  </div>
</fieldset>