import { fabric } from 'fabric';

import { TransformExtended, WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardMoveMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly obj: fabric.Object;

  private readonly currentTop: number | undefined;
  private readonly currentLeft: number | undefined;

  private readonly previousTop: number;
  private readonly previousLeft: number;

  constructor(canvas: fabric.Canvas, obj: fabric.Object, transform: TransformExtended) {
    super();

    this.canvas = canvas;
    this.obj = obj;

    this.currentTop = obj.top;
    this.currentLeft = obj.left;

    this.previousTop = transform?.original?.top;
    this.previousLeft = transform?.original?.left;
  }

  apply() {
    this.invoke(
      () => {
        this.obj.top = this.currentTop;
        this.obj.left = this.currentLeft;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:moved', this.obj, this.canvas);
      },
      this.obj);
  }

  revert() {
    this.invoke(
      () => {
        this.obj.top = this.previousTop;
        this.obj.left = this.previousLeft;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:moved', this.obj, this.canvas);
      },
      this.obj);
  }
}
