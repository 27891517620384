<ng-template #idleWarning>
  <button aria-label="Close" class="btn btn-link btn--icon ms-auto mt-2 me-2 mb-n3" type="button" (click)="close()">
    <fa-icon [icon]="icons.close.light" />
  </button>
  <div class="modal-body kip-student p-4">
    <div class="d-flex align-items-center">
      <kip-monty-slow-request class="col-4 d-none d-sm-block" />
      <div class="ps-4">
        <h1 class="h1 mb-4 fw-normal text-primary">Do you need more time?</h1>
        <p>It looks like this session ended a while ago and you have become idle.</p>
        @if (idleService?.idleSecondsCounter$ | async; as counter) {
        <p>
          <b>You will be logged out in {{ idleLogoutTime - counter }} seconds.</b>
        </p>
        }
        <p class="form-check mt-n2">
          <input class="form-check-input me-2 ms-0" id="doNotAskAgain" type="checkbox"
            (change)="onDoNotAskAgain($event)">
          <label class="form-check-label" for="doNotAskAgain">Do not ask me again for this session</label>
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <button class="btn btn-outline-danger text-btn shadow-sm border-0 me-2 ms-0" type="button" (click)="logout()">
        Log out</button>
      <button class="btn btn-primary shadow-sm border-0" type="button" (click)="close()">I need more time</button>
    </div>
  </div>
</ng-template>