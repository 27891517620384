/* eslint-disable rxjs/no-ignored-subscription */

import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { HttpService } from 'service-lib';
import { DigitalEnrolmentResult, PaymentResult, PaymentToken } from 'ui-common-lib';

import { BundleEnrolData, BundleSelector, LessonEnrolment } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LessonEnrolmentService extends HttpService {

  getBundleSelector(centreId: number): Observable<BundleSelector> {
    return this.get<BundleSelector>(`bundle-type/select/${centreId}`);
  }

  getBundles(lessonId: number): Observable<BundleEnrolData> {
    return this.get<BundleEnrolData>(`lesson-enrolments/${lessonId}/bundles`);
  }

  getLessonEnrolment(lessonId: number): Observable<LessonEnrolment> {
    return this.get<LessonEnrolment>(`lesson-enrolments/${lessonId}`);
  }

  launchDigitalEnrolmentForm(enrolment: LessonEnrolment): Observable<DigitalEnrolmentResult> {
    const result = new Subject<DigitalEnrolmentResult>();

    const { digitalEnrolmentUrl, digitalEnrolmentError } = enrolment;

    if (digitalEnrolmentError) {
      console.log(`Error: Cannot load digital enrolment form. ${digitalEnrolmentError}`);
    }

    if (digitalEnrolmentUrl) {
      const left = window.screenX + (window.innerWidth - 650) / 2;
      const top = window.screenY + (window.innerHeight - 475) / 2;

      const popup = window.open(digitalEnrolmentUrl, 'DigitalEnrolmentFormPopup', `width=650,height=475,top=${top},left=${left}`);

      if (popup) {
        let checkClosed = 0;
        const handleResult = (event: MessageEvent<{ isSuccessful: boolean }>) => {
          if (event.source === popup) {
            window.clearInterval(checkClosed);
            result.next(event.data.isSuccessful ? DigitalEnrolmentResult.Succeeded : DigitalEnrolmentResult.Failed);
            popup.close();
            window.removeEventListener('message', handleResult);
            result.complete();
          }
        };
        window.addEventListener('message', handleResult);
        checkClosed = window.setInterval(() => {
          if (popup.closed) {
            window.clearInterval(checkClosed);
            result.next(DigitalEnrolmentResult.Cancelled);
            window.removeEventListener('message', handleResult);
            result.complete();
          }
        }, 300);
      } else {
        result.next(DigitalEnrolmentResult.Blocked);
        result.complete();
      }
    }
    else {
      result.next(DigitalEnrolmentResult.Failed);
      result.complete();
    }

    return result;
  }

  launchCardPayment(lessonId: number): Observable<PaymentResult> {
    const result = new Subject<PaymentResult>();
    this.get<PaymentToken>(`lesson-enrolments/${lessonId}/payment`).pipe(take(1)).subscribe(token => {
      if (!token.checkoutId) {
        result.next(PaymentResult.Failed);
      } else {
        const left = window.screenX + (window.innerWidth - 650) / 2;
        const top = window.screenY + (window.innerHeight - 475) / 2;
        const popup = window.open(`/credit-card?checkoutId=${token.checkoutId}`, 'EnrolmentPaymentPopup', `width=650,height=475,top=${top},left=${left}`);
        if (popup) {
          let checkClosed = 0;
          const handleResult = (event: MessageEvent<{ isSuccessful: boolean }>) => {
            if (event.source === popup) {
              window.clearInterval(checkClosed);
              result.next(event.data.isSuccessful ? PaymentResult.Succeeded : PaymentResult.Failed);
              popup.close();
              window.removeEventListener('message', handleResult);
              result.complete();
            }
          };
          window.addEventListener('message', handleResult);
          checkClosed = window.setInterval(() => {
            if (popup.closed) {
              window.clearInterval(checkClosed);
              result.next(PaymentResult.Cancelled);
              window.removeEventListener('message', handleResult);
              result.complete();
            }
          }, 300);
        } else {
          result.next(PaymentResult.Blocked);
          result.complete();
        }
      }
    });
    return result;
  }

  preparePayment(lessonId: number): Observable<PaymentToken> {
    return this.get<PaymentToken>(`lesson-enrolments/${lessonId}/payment`);
  }
}
