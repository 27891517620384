/* eslint-disable @angular-eslint/directive-class-suffix */

import { Directive, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class CanDeactivateComponent {

  protected _navigatingAway = false;

  abstract isDirty: boolean;

  navigateAwayAllowed$: Subject<boolean> = new Subject<boolean>();

  set navigatingAway(value: boolean) {
    this._navigatingAway = value;
  }

  get navigatingAway() {
    return this._navigatingAway;
  }

  updateNavigateAwayAllowed(value: boolean) {
    this.navigatingAway = false;
    this.navigateAwayAllowed$.next(value);
  }

  canDeactivate() {
    if (this.isDirty) {
      this.navigatingAway = true;
      return this.navigateAwayAllowed$;
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event) {
    if (this.isDirty) {
      $event.preventDefault();
    }
  }
}
