import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';

import { AIPrompt, AIRequest, GeneratedActivity } from '../models';
import { OrganisationActivityService } from '../services';

@Component({
  selector: 'kip-organisation-activity-ai',
  templateUrl: './organisation-activity-ai.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationActivityAIComponent implements OnInit, OnDestroy {
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #organisationActivityService = inject(OrganisationActivityService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #aiPrompts: AIPrompt[] = [];
  #aiTopics: string[] = [];

  readonly questionCountSelection: { id: number, name: string }[] = [
    { id: 5, name: '5 Questions' },
    { id: 10, name: '10 Questions' },
    { id: 15, name: '15 Questions' },
    { id: 20, name: '20 Questions' }
  ];

  readonly multipleAnswersCountSelection: { id: number, name: string }[] = [
    { id: 1, name: 'Single' },
    { id: 2, name: '2 Answers' },
    { id: 3, name: '3 Answers' },
    { id: 4, name: '4 Answers' }
  ];

  aiForm: FormGroupTypeSafe<AIRequest>;

  aiPromptValue: AIPrompt = { id: 0, name: 'None', prompt: '', regionId: 0 };
  loading = false;

  get aiTopics() {
    return this.#aiTopics;
  }

  get aiPrompts() {
    return this.#aiPrompts.filter(s => s.id === 0 || this.regionIds.includes(s.regionId));
  }

  get topic() {
    return this.aiForm.getSafe(x => x.topic);
  }

  get gradeId() {
    return this.aiForm.getSafe(x => x.gradeId);
  }

  get additionalPrompt() {
    return this.aiForm.getSafe(x => x.additionalPrompt);
  }

  get questions() {
    return this.aiForm.getSafe(x => x.questions);
  }

  get multipleChoiceAnswers() {
    return this.aiForm.getSafe(x => x.multipleChoiceAnswers);
  }

  @Input({ required: true }) regionIds: readonly number[] = [];
  @Input({ required: true }) generatedActivity: GeneratedActivity = { additionalData: '', content: [] };

  @Output() readonly generatedActivityChange = new EventEmitter<GeneratedActivity>();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */

    this.aiForm = this.#fb.group<AIRequest>({
      topic: new FormControl<string>('', Validators.required),
      gradeId: new FormControl<number>(1, Validators.required),
      additionalPrompt: new FormControl<string>(''),
      questions: new FormControl<number>(10, Validators.required),
      multipleChoiceAnswers: new FormControl<number>(4, Validators.required)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#organisationActivityService.getAIPrompts().subscribe(aiPrompts => {
        this.#aiPrompts = aiPrompts;
        this.aiPromptValue = aiPrompts[0];
        this.#changeDetectorRef.markForCheck();
      }),
      this.#organisationActivityService.getAITopics().subscribe(aiTopics => {
        this.#aiTopics = aiTopics;
        this.#changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  readonly addTopicPromise = (name: string) => new Promise(resolve => {
    resolve(name);
  });

  getQuestions() {
    if (!this.aiForm.invalid) {
      this.loading = true;
      this.#changeDetectorRef.markForCheck();
      this.#subscriptions.push(this.#organisationActivityService.generateQuestions(this.aiForm.value).subscribe(
        {
          next: generatedActivity => {
            this.loading = false;
            this.generatedActivity = generatedActivity;
            this.generatedActivityChange.emit(generatedActivity);
            this.#changeDetectorRef.markForCheck();
          },
          error: (error: unknown) => {
            this.aiForm.handleServerErrors(error);
            this.#changeDetectorRef.markForCheck();
          }
        }));
    }
  }

}
