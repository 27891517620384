import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';
import { PageRequest } from 'ui-common-lib';

import { Award, AwardData, CertificateNode, OrganisationAward, StudentAward, StudentAwardItem, StudentAwardSummary, StudentPointsRedeemed, StudentPointsSummary } from '../models';

@Injectable({ providedIn: 'root' })
export class AwardsService extends HttpWithAbsoluteService {

  getCertificatePdfFile(file: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/certificate-pdf-url/${file}`, ErrorBlobPath.Pdf);
  }

  getCertificates() {
    return this.get<CertificateNode>('award/certificates');
  }

  getStudents() {
    return this.get<StudentAwardSummary[]>('award/students');
  }

  getAwards() {
    return this.get<Award[]>('award');
  }

  getStudentAwards() {
    return this.get<StudentAwardItem[]>('award/student');
  }

  getOrganisationAwards() {
    return this.get<OrganisationAward[]>('award/organisation/all');
  }

  postNewAward(lessonId: number, studentAward: StudentAward): Observable<string> {
    return this.post<string>(`award/${lessonId}/student`, studentAward);
  }

  queryPointsRecords(studentId: number, page: PageRequest): Observable<StudentPointsSummary> {
    return this.post<StudentPointsSummary>(`award/${studentId}/student/query`, page);
  }

  postRedeemedPoints(studentPointsRedeemed: StudentPointsRedeemed) {
    return this.post<string>('tutor/points/redeemed', studentPointsRedeemed);
  }

  getStudentTotalPoints(studentId: number): Observable<AwardData> {
    return this.get<AwardData>(`tutor/points/total/${studentId}`);
  }
}
