/* eslint-disable rxjs/no-ignored-subscription */

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { ServiceEnvironment } from './environment';

@Injectable({ providedIn: 'root' })
export abstract class HttpService {

  readonly #cachedBlobs = new Map<string, string>();
  protected readonly httpClient = inject(HttpClient);

  get endpoint() {
    return ServiceEnvironment.value.api.endpoint;
  }

  get buildGuid() {
    return ServiceEnvironment.value.buildGuid;
  }

  protected get<TResponse>(path: string, query?: HttpParams | { [param: string]: string[] | any }): Observable<TResponse> {
    return this.httpClient.get<TResponse>(`${this.endpoint}/${path}`, {
      params: query
    });
  }

  protected getBlobCached(path: string) {
    const promise: Promise<string> = new Promise<string>(resolve => {
      const cachedUrl = this.#cachedBlobs.get(path);
      if (cachedUrl) {
        resolve(cachedUrl);
      } else {
        this.getBlob(path).subscribe(
          {
            next: response => {
              const url = response.body ? window.URL.createObjectURL(response.body) : '';
              this.#cachedBlobs.set(path, url);
              resolve(url);
            },
            error: () => {
              resolve('');
            }
          });
      }
    });

    return promise;
  }

  protected getBlob(path: string, query?: HttpParams | { [param: string]: string[] | any }): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.endpoint}/${path}`, { responseType: 'blob', observe: 'response', params: query });
  }

  protected getBlobParams(path: string, params: HttpParams): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${this.endpoint}/${path}`, { params: params, responseType: 'blob', observe: 'response' });
  }

  protected getBlobParamsWithComplexParams(path: string, params: any) {
    return this.httpClient.post(`${this.endpoint}/${path}`, params, { observe: 'response', responseType: 'blob' });
  }

  protected postNoRetry<TResponse>(path: string, body: any, query?: HttpParams | { [param: string]: string[] | any }): Observable<TResponse> {
    const options: { headers: HttpHeaders } = { headers: new HttpHeaders().set('No-Retry', 'true') };
    return this.httpClient.post<TResponse>(`${this.endpoint}/${path}`, body, {
      params: query,
      ...options
    });
  }

  protected postWithTextResponse(path: string, body: string): Observable<string> {
    return this.httpClient.post<string>(`${this.endpoint}/${path}`, body, { responseType: 'text' as 'json' });
  }

  protected post<TResponse>(path: string, body: any, query?: HttpParams | { [param: string]: string[] | any }, options?: { [key: string]: any }): Observable<TResponse> {
    return this.httpClient.post<TResponse>(`${this.endpoint}/${path}`, body, {
      params: query,
      ...options
    });
  }

  protected postBlob(path: string, body: any, query?: HttpParams | { [param: string]: string[] | any }, options?: { [key: string]: any }) {
    return this.httpClient.post(`${this.endpoint}/${path}`, body, {
      params: query,
      responseType: 'blob', observe: 'response',
      ...options
    });
  }

  protected delete<TResponse>(path: string): Observable<TResponse> {
    return this.httpClient.delete<TResponse>(`${this.endpoint}/${path}`);
  }

  protected put<TResponse>(path: string, body: any, query?: HttpParams | { [param: string]: string[] | any }, options?: { [key: string]: any }): Observable<TResponse> {
    return this.httpClient.put<TResponse>(`${this.endpoint}/${path}`, body, {
      params: query,
      ...options
    });
  }

  protected patch<TResponse>(path: string, body: any): Observable<TResponse> {
    return this.httpClient.patch<TResponse>(`${this.endpoint}/${path}`, body);
  }

  protected postFormData<TResponse>(path: string, formData: FormData, options?: { [key: string]: any }): Observable<TResponse> {
    return this.httpClient.post<TResponse>(`${this.endpoint}/${path}`, formData, options);
  }

  protected serializeDate(value: Date, adjust?: (value: moment.Moment) => moment.Moment): string {

    // Ensure the date if in the local time
    // And apply any offset needed (like start of day)
    let local = moment.utc(value).local();

    if (adjust) {
      local = adjust(local);
    }

    // Then convert the date back to UTC for the server to process (all server dates are in UTC)
    // Format the date appropriately to cater for cultural variations and ensuring it is global
    const utc = moment.utc(local);
    return utc.format('YYYY-MM-DD HH:mm:ss');
  }

  protected serializeDateVerbatim(value: Date, adjust?: (value: moment.Moment) => moment.Moment): string {

    // And apply any offset needed (like start of day)
    let date = moment(value);

    if (adjust) {
      date = adjust(date);
    }

    // Format the date appropriately to cater for cultural variations and ensuring it is global
    return date.format('YYYY-MM-DD HH:mm:ss');
  }
}
