import { createAction, props } from '@ngrx/store';
import { LessonLog } from 'message-lib';

enum LogActionTypes {
  LoadMany = 'Tutor > Log > Load Many',
  Add = 'Tutor > Log > Add'
}

export const loadManyAction = createAction(
  LogActionTypes.LoadMany,
  props<{
    readonly lessonGuid: string;
    readonly logs: readonly LessonLog[];
  }>()
);

export const addAction = createAction(
  LogActionTypes.Add,
  props<{
    readonly lessonGuid: string;
    readonly log: LessonLog;
  }>()
);

