import { fabric } from 'fabric';

import { WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardClearMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly objects: fabric.Object[];

  constructor(canvas: fabric.Canvas, objects: fabric.Object[]) {
    super();

    this.canvas = canvas;
    this.objects = objects;
  }

  apply() {
    this.invoke(
      () => {
        for (const object of this.objects) {
          this.canvas.remove(object);
        }
      },
      ...this.objects);
  }

  revert() {
    this.invoke(
      () => {
        for (const object of this.objects) {
          this.canvas.add(object);
        }
      },
      ...this.objects);
  }
}
