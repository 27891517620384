import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

import { convertDateToMoment } from '../helpers';
import { Age } from '../models';

export function calculateAge(date: DateStruct | null, effectiveDate?: Date | DateStruct | string | null  ): Age {
  if (date === null) {
    return { year: 0, month: 0 };
  }
  const today = effectiveDate ? convertDateToMoment(effectiveDate) : moment();

  const momentTime = moment('00:00:00');
  const birthday = moment().set(
    {
      year: date.year,
      month: date.month - 1,
      day: date.day,
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute'),
      seconds: momentTime.get('second')
    });

  const years = today.diff(birthday, 'years');
  const months = today.diff(birthday, 'months') % 12;

  return { year: years, month: months };
}
