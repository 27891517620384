<ng-template #content>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.notifications" />
      Send Notification
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body">
    <kip-form-group-wrapper [formGroup]="notificationForm" [showCancel]="true" (cancelled)="close()"
      (submittedAndValidated)="submit()">
      <kip-form-control-text id="notificationTitle" label="Title" maxlength="200" [control]="title" />
      <kip-form-control-long-text id="notificationBody" label="Body" maxlength="1000" [control]="body" [rows]="5" />
      <div class="py-3 d-flex align-items-center">
        <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
        <div class="pe-3">This allows notification messages to be sent to others. Be sure to write clean, concise
          and respectful messages.</div>
      </div>
      <div buttons>
        {{ result }}
      </div>
    </kip-form-group-wrapper>
  </div>
</ng-template>