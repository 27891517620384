@if (videoRecordOptionId(); as videoRecordOptionId) {
@if (label()) {
<label [class]="labelClass()??''" [for]="name()">{{ label() }}</label>
}
<div class="input-group">
  <select class="form-control" [formControl]="videoRecordOptionId" [id]="name()"
    (change)="videoRecordOptionIdChange.emit(videoRecordOptionId.value ?? 0)">
    @for (videoRecordOption of videoRecordOptions(); track videoRecordOption) {
    <option [ngValue]="videoRecordOption.id">
      {{videoRecordOption.name }}
    </option>
    }
  </select>
</div>
@if (warning(); as warning)
{
<div class="alert alert-danger mt-2" role="alert">{{ warning }}</div>
}
}