import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

const unitsRegex = new RegExp(/[centimetres|cm|kilo|kilograms|kg|g|grams|metres|m|millilitres|mL|L|litres\s]/g);
const centimetresRegex = new RegExp(/c(?:entimetres|m)/g);
const kilogramsRegex = new RegExp(/k(?:ilo(?:grams)?|g)/g);
const gramsRegex = new RegExp(/g|grams/g);
const metresRegex = new RegExp(/m(?:etres)?/g);
const millilitresRegex = new RegExp(/m(?:illilitres|L)/g);
const litresRegex = new RegExp(/L|litres?/g);

export class UnitsValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {
    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);
    if (studentAnswers.length === question.answers.length) {
      for (let index = 0; index < studentAnswers.length; index++) {
        const studentAnswer = studentAnswers[index];
        const answer = question.answers[index];
        for (const expected of answer.values) {
          const actualValue = this.#sanitizeValue(studentAnswer);
          const expectedValue = this.#sanitizeValue(expected);
          if (actualValue === expectedValue) {
            validationResults[index] = this.#validateAnswer(this.#removeSpace(studentAnswer), this.#removeSpace(expected));
          }
        }
      }
    }
    return validationResults;
  }

  #validateAnswer(studentAnswer: string, correctAnswer: string): ValidationResult {
    if (new RegExp(centimetresRegex).test(studentAnswer) && new RegExp(centimetresRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    if (new RegExp(kilogramsRegex).test(studentAnswer) && new RegExp(kilogramsRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    if (new RegExp(metresRegex).test(studentAnswer) && new RegExp(metresRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    if (new RegExp(millilitresRegex).test(studentAnswer) && new RegExp(millilitresRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    if (new RegExp(litresRegex).test(studentAnswer) && new RegExp(litresRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    if (new RegExp(gramsRegex).test(studentAnswer) && new RegExp(gramsRegex).test(correctAnswer)) {
      return ValidationResult.Correct;
    }
    return ValidationResult.Incorrect;
  }

  #sanitizeValue(value: AnswerType): string {
    if (value !== undefined) {
      return value
        .toString()
        .trim()
        .replace(unitsRegex, '');
    }
    return value;
  }

  #removeSpace(value: AnswerType): string {
    if (value !== undefined) {
      return value
        .toString()
        .trim()
        .replace(/ /g, '');
    }
    return value;
  }
}
