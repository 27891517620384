<ng-template #content>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.helpGuide" />
      Help Guide
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="kip-explore kip-explore--help container-fluid h-100 mh-100 py-3">
      <div class="kip-explore__wrapper h-100">
        <section class="kip-explore__content h-100">
          <div class="d-flex flex-row g-0 h-100">
            <div class="col-12 col-lg-4 h-100 mh-100 kip-explore__wrapper pe-1">
              <div class="kip-explore__content card">
                <div class="card-body">
                  <kip-help-tree #tree [selectedUId]="selectedUId" [uId]="uId" (nodeSelected)="nodeSelected($event)" />
                </div>
              </div>
            </div>
            <div class="d-none d-lg-flex col-lg-8 kip-explore__wrapper ps-1">
              <kip-help-view [helpNodeSelected]="helpNodeSelected" (quit)="tree.reload()" />
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="px-3 w-100 text-end">
      <button class="btn kip-btn" type="button" (click)="close()">Close</button>
    </div>
  </div>
</ng-template>