import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cappedNumber'
})
export class CappedNumberPipe implements PipeTransform {
  transform(value: number, max: number): string {
    if (value > max) {
      return `${max}+`;
    }

    if (value > 0) {
      return value.toString();
    }
    return '';
  }
}
