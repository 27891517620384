import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-space-cadet-boy-f',
  templateUrl: './space-cadet-boy-f.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSpaceCadetBoyFComponent implements IconComponent {

}
