import { FabricObjectExtended } from '.';
import { ToolbarSvg } from './toolbar-svg';
import { WhiteboardColor } from './whiteboard-color';
import { WhiteboardToolOption } from './whiteboard-tool-option';

export abstract class WhiteboardTool {

  selected = false;
  showOptions = false;
  selectedOption = '';

  get options(): WhiteboardToolOption[] {
    return [];
  }

  constructor(readonly name: ToolbarSvg) { }

  selectable(_obj: fabric.Object): boolean {
    return false;
  }

  abstract setup(color: WhiteboardColor, canvas: fabric.Canvas): void;
  abstract teardown(_canvas: fabric.Canvas): void;

  protected resetCanvas(canvas: fabric.Canvas, drawing: boolean, selection: boolean) {
    canvas.isDrawingMode = drawing;
    canvas.selection = selection;

    canvas.discardActiveObject();
  }

  protected setSelectableState(canvas: fabric.Canvas, selectable: boolean, optionalFilter?: (obj: fabric.Object) => boolean) {
    const filter = optionalFilter ?? (() => true);
    canvas.forEachObject(obj => {
      const objExtended = obj as FabricObjectExtended;
      if (objExtended?.data?.id) {
        obj.selectable = filter && filter(obj) ? selectable : false;

        // Need to also ensure text objects have edited editing
        if (obj.type === 'i-text') {
          const text = obj as fabric.IText;
          text.exitEditing();
        }
      }
    });
  }
}
