/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EventType, MessagingService as MessagingServiceBase, Sound } from 'message-lib';
import { Observable, of, tap } from 'rxjs';
import { TutorSettings } from 'ui-common-lib';

import { Feature, FeatureFlagService } from '../../shared';
import { fromDropIn, fromSession, TutorState } from '../store';
@Injectable({ providedIn: 'root' })
export class DropInMessagingService extends MessagingServiceBase {

  readonly #store = inject(Store<TutorState>);
  readonly #featureFlagService = inject(FeatureFlagService);

  #enabled = false;

  constructor() {
    super('Tutor', 'drop-in');
    this.init();
  }

  get tutorSettings() {
    return this.userProfile?.settings as TutorSettings | undefined;
  }

  init() {
    this.profileService.trackUserProfile().subscribe(userProfile => {
      this.userProfile = userProfile;
    });

    this.#featureFlagService.isFeatureEnabled(Feature.DropIn).subscribe(enabled => this.#enabled = enabled);
  }

  override connect(): Observable<void> {
    return super.connect().pipe(
      tap(() => {
        // This event is just to confirm the connection is working and received an event.
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.receive('SessionOpenSuccess', () => { });

        this.receiveEvent('DropInSessionJoinRequested', this.#store, {
          action: fromDropIn.dropInSessionJoinRequestedAction,
          converter: (args: [number, number, string, number, string]) =>
          ({
            request: {
              sessionId: args[0],
              studentId: args[1],
              studentName: args[2],
              tutorId: args[3],
              connectionId: args[4]
            }
          }),
          sounds: [
            {
              sound: Sound.DropInAccessRequest,
              play: () =>
                this.tutorSettings?.playSoundOnDropInAccessRequest ?? false
            }
          ]
        });

        this.receiveEvent('DropInSessionRequestCancelled', this.#store, {
          action: fromDropIn.dropInSessionRequestCancelledAction,
          converter: (args: [number, number, string, number, string]) =>
          ({
            request: {
              sessionId: args[0],
              studentId: args[1],
              studentName: args[2],
              tutorId: args[3],
              connectionId: args[4]
            }
          })
        });
      })
    );
  }

  onReconnect() {
    this.#store.dispatch(fromSession.reconnectAction());
  }

  override onReceiveEventTrue() {
    this.#store.dispatch(fromSession.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
  }

  sessionOpened(sessionId: number): Observable<string> {
    return this.sendEventWrapper('SessionOpened', this.#store, { sessionId: sessionId, deviceData: JSON.stringify(this.deviceData) });
  }

  dropInSessionJoinRequestAccepted(lessonId: number, connectionId: string): Observable<string> {
    return this.sendEventWrapper('DropInSessionJoinRequestAccepted', this.#store, { lessonId: lessonId, connectionId: connectionId, deviceData: JSON.stringify(this.deviceData) });
  }

  dropInSessionJoinRequestDenied(connectionId: string): Observable<string> {
    return this.sendEventWrapper('DropInSessionJoinRequestDenied', this.#store, { connectionId: connectionId, deviceData: JSON.stringify(this.deviceData) });
  }

  private sendEventWrapper<TState>(name: EventType, store: Store<TState>, args: { [field: string]: any }) {
    if (!this.#enabled) {
      return of('');
    }

    this.#store.dispatch(fromSession.updateSignalRConnectionStateAction({
      connected: this.isConnectedAndReceivedEvent()
    }));
    return this.sendEvent(name, store, args);
  }
}
