<ng-template #redirectWarning>
  <div class="modal-body kip-student p-4">
    <div class="d-flex align-items-center">
      <kip-monty-error class="pe-3" />
      <div class="ps-3">
        <h1 class="h1 mb-3 fw-normal text-primary">Link expired</h1>
        <p>{{ warningMessage }}</p>
        <small>If you have bookmarked this page, please delete it.</small>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn kip-btn text-btn shadow-sm border-0 me-2" type="button" (click)="goBack()">
        {{ buttonText }}</button>
    </div>
  </div>
</ng-template>