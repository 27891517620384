@for (button of buttons(); track button; let first = $first) {

@if (button.children)
{
<div class="btn-group me-3">
  <button class="btn btn-outline-primary" type="button" [routerLink]="button.route">{{ button.text }}</button>
  <div aria-label="Button group with nested dropdown" class="btn-group" ngbDropdown role="group">
    <button class="btn btn-outline-primary dropdown-toggle-split" ngbDropdownToggle type="button"></button>
    <div class="dropdown-menu kip-dropdown__menu kip-dropdown__menu--top-left ms-n3" ngbDropdownMenu>
      @for (child of button.children; track child)
      {
      <button class="kip-dropdown__entry" ngbDropdownItem type="button" [routerLink]="child.route">{{ child.text
        }}</button>
      }
    </div>
  </div>
</div>
}
@else {

@if (button.route)
{
<a [ngClass]="{'ms-2': !first, 'btn btn-outline-primary btn-letter-spacing': true}" [routerLink]="button.route">{{
  button.text }}</a>
}
@if (button.action)
{
<a [ngClass]="{'ms-2': !first, 'btn btn-outline-primary btn-letter-spacing': true}" (click)="button.action()">{{
  button.text }}</a>
}
}
}