import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentNote } from '../../../../models';

@Component({
  selector: 'kip-student-note-dropdown-general',
  templateUrl: './student-note-dropdown-general.component.html',
  styleUrl: '../student-note-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentNoteDropdownGeneralComponent {

  readonly icons = Icons;

  @Input({ required: true })
  studentNote: StudentNote | undefined;
}
