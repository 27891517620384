<div
  class="bg-white btn-group d-flex justify-content-center rounded-bottom rounded-top-0 mb-2 align-items-stretch pointer-events-auto cursor-default"
  style="outline: 1px solid #e7e7e7; max-width: 100%;">
  <div class="kip-tutor-solution-btn d-flex font-size-normal p-0"
    [class.active]="!studentAnswers && allStudentAnswers?.length"
    [ngClass]="{ 'btn kip-btn' : allStudentAnswers?.length }" (click)="this.displaySolution.emit()">
    <span class="fw-bold text-secondary ms-2 my-auto">Solution:</span>
    <kip-question-summary [question]="questionSummary" />
  </div>
  @for (attempt of allStudentAnswers; track attempt; let i = $index) {
  <button class="btn kip-btn px-1 py-0" placement="bottom" type="button"
    [class.active]="attempt.answers === studentAnswers"
    [ngClass]="{'btn-success':attempt.correct,'btn-danger':!attempt.correct}"
    [ngbTooltip]="(i + 1 | ordinal) + ' attempt'" (click)="this.answerSelected.emit(attempt.answers)">
    <fa-icon class="px-1" [fixedWidth]="true"
      [icon]="attempt.correct ? icons.questions.correct : icons.questions.incorrectMultiple"
      [ngClass]="{'text-success': attempt.correct, 'text-danger': !attempt.correct}" />
  </button>
  }
</div>