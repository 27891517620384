import { ChangeDetectionStrategy, Component, ElementRef, inject, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';

import { IndexedOption, QuestionWordMatch } from '../../models';
import { QuestionLayout } from '../question-layout';

interface PositionInfo {
  x: number;
  y: number;
}

interface OptionInfo {
  option: IndexedOption;
  element: HTMLElement;
  rect: DOMRect;
}

interface MatchInfo {
  source: IndexedOption;
  target: IndexedOption;
}

@Component({
  selector: 'kip-question-word-match',
  templateUrl: './word-match.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WordMatchComponent extends QuestionLayout {

  readonly #renderer = inject(Renderer2);

  readonly #below: string[] = ['g', 'j', 'p', 'q', 'y'];
  readonly #centre: string[] = ['a', 'c', 'e', 'i', 'm', 'n', 'o', 'r', 's', 'u', 'v', 'w', 'x', 'z'];
  readonly #above: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'b', 'd', 'f', 'h', 'k', 'l', 't'];

  #source: OptionInfo | undefined;
  #target: OptionInfo | undefined;

  #sourceOptions: IndexedOption[] = [];
  #targetOptions: IndexedOption[] = [];
  #orderedParameters: readonly any[] = [];
  #parameters: any;

  readonly #destroyers: (() => void)[] = [];
  readonly #matches: (MatchInfo | undefined)[] = [];

  override question: QuestionWordMatch | undefined;

  get answers(): any[] {
    return [];
  }

  get sourceOptions(): IndexedOption[] {
    return this.#sourceOptions;
  }

  get targetOptions(): IndexedOption[] {
    return this.#targetOptions;
  }

  @ViewChild('container', { static: true }) containerElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChildren('source') sourceElements: QueryList<ElementRef<HTMLElement>> | undefined;
  @ViewChildren('target') targetElements: QueryList<ElementRef<HTMLElement>> | undefined;

  getLetterStyles(letter: string): string {
    let styles = '';
    if (this.#isShort(letter)) {
      styles = 'kip-letter-short-centre';
    } else if (this.#isTall(letter) && this.#isAbove(letter)) {
      styles = 'kip-letter-tall-above';
    } else if (this.#isTall(letter) && this.#isBelow(letter)) {
      styles = 'kip-letter-tall-below';
    }
    return styles;
  }

  getLetters(value: number | string | undefined): string[] {
    if (value) {
      return [...value.toString()];
    }

    return [];
  }

  sourceMatched(option: IndexedOption): boolean {
    return this.#matched(option, this.#source, match => match.source);
  }

  override initialize() {
    this.#parameters = this.question?.parameters ?? [];
    this.#orderedParameters = this.question?.answers ?? [];

    this.#sourceOptions = this.#buildOptions(this.#parameters, false);
    this.#targetOptions = this.#buildOptions(this.#orderedParameters, true);

    if (this.containerElement) {
      // Add the event handlers to start a match
      this.#listen(this.containerElement.nativeElement, 'mousedown', (event: MouseEvent) => {
        this.#startMatch(event.target as HTMLElement);
      });

      this.#listen(this.containerElement.nativeElement, 'touchstart', (event: TouchEvent) => {
        if (event.changedTouches.length > 0) {
          const touch = event.changedTouches[0];
          this.#startMatch(touch.target as HTMLElement);
        }
      });

      // Add the event handlers to track the matching move (so a line is drawn)
      this.#listen(this.containerElement.nativeElement, 'mousemove', (event: MouseEvent) => {
        this.#updateConnector(event.target);
      });

      this.#listen(this.containerElement.nativeElement, 'touchmove', (event: TouchEvent) => {
        if (event.changedTouches.length > 0) {
          const touch = event.changedTouches[0];

          // The event element will be the starting source element, but we need the target
          // Unfortunately, this has to be calculated based on the event coordinates
          const position = this.#resolvePosition(touch.clientX, touch.clientY);
          let element: HTMLElement | undefined;
          if (this.targetElements) {
            element = this.targetElements
              .map(target => target.nativeElement)
              .find(target => this.#comparerFunc(target, position));
          }

          this.#updateConnector(element ?? event.target);
        }
      });
    }

    // Add the event handler to end a match
    this.#listen(document, 'mouseup', () => {
      this.#endMatch();
    });

    this.#listen(document, 'touchend', () => {
      this.#endMatch();
    });
  }

  override destroy() {
    for (const destroy of this.#destroyers) {
      destroy();
    }
  }

  #isTall(letter: string): boolean {
    return this.#above.includes(letter) || this.#below.includes(letter);
  }

  #isShort(letter: string): boolean {
    return this.#centre.includes(letter);
  }

  #isAbove(letter: string): boolean {
    return this.#above.includes(letter);
  }

  #isBelow(letter: string): boolean {
    return this.#below.includes(letter);
  }

  // Add a comparer function that can be overridden for testing
  // Due to differences in how tests and 'real-time' are rendered, the logic will be different
  /* NO COVERAGE */
  readonly #comparerFunc = (target: HTMLElement, position: PositionInfo) => {
    const rect = target.getBoundingClientRect();
    return position.x >= target.offsetLeft &&
      position.y >= target.offsetTop &&
      position.x <= target.offsetLeft + rect.width &&
      position.y <= target.offsetTop + rect.height;
  };

  #buildOptions(options: readonly any[], isAnswer: boolean): IndexedOption[] {
    return options.map((option, index) => ({
      index: index,
      text: isAnswer ? null : option,
      image: undefined,
      value: isAnswer ? option.values : option
    }));
  }

  #matched(option: IndexedOption, info: OptionInfo | undefined, accessor: (match: MatchInfo) => IndexedOption): boolean {

    // If the source has started a match, then activate it
    // Otherwise check if it is matched
    if (info && info.option.index === option.index) {
      return true;
    }

    return !!this.#findMatch(option, accessor);
  }

  #findMatch(option: IndexedOption, accessor: (match: MatchInfo) => IndexedOption): MatchInfo | undefined {
    return this.#matches.find(match => {

      // If the match is undefined, then don't match it
      // This is if a match hasn't been selected for a source option yet
      if (match) {
        const current = accessor(match);
        return current && current.index === option.index;
      }
      return false;
    });
  }

  #findOption(element: HTMLElement, elements: QueryList<ElementRef<HTMLElement>> | undefined,
    options: IndexedOption[]): OptionInfo | undefined {
    if (elements) {
      return elements
        .map((source, index) => ({
          option: options[index],
          element: source.nativeElement,
          rect: source.nativeElement.getBoundingClientRect()
        }))
        .find(info => info.element === element || info.element.contains(element));
    }

    return undefined;
  }

  #listen(element: Document | HTMLElement, event: string, handler: (event: any) => void) {
    const destroy = this.#renderer.listen(element, event, handler);
    this.#destroyers.push(destroy);
  }

  #startMatch(element: HTMLElement) {

    // Get the option info for the element that triggered the event
    const info = this.#findOption(element, this.sourceElements, this.sourceOptions); // sourceOptions
    if (info) {

      // If a match is already in place for the source, clear it
      const existing = this.#findMatch(info.option, match => match.source);

      if (existing) {

        // clear styles

        // Then set the match info to undefined
        this.#matches[existing.source.index] = undefined;
      }
      // Track the start source option, which activates the connector rendering
      this.#source = info;
      this.#renderer.addClass(info.element, 'kip-source');
    }
  }

  #endMatch() {

    // We are only interested in ending a match if a source and target is active
    if (this.#source && this.#target) {
      if (this.#validate()) {
        // A target can only be matched if not already associated to a source
        const existing = this.#findMatch(this.#target.option, match => match.target);

        if (!existing) {

          this.#renderer.addClass(this.#target.element, 'kip-target');
          // remove source option from source
          this.#renderer.removeChild(this.#source.element.parentNode, this.#source.element);

          // create word container div with letters
          const wordContainer = this.#renderer.createElement('div');
          this.#renderer.addClass(wordContainer, 'flex-word');
          const letters = this.getLetters(this.#source.option.text);
          for (const letter of letters) {
            const letterBox = this.#renderer.createElement('div');
            this.#renderer.addClass(letterBox, this.getLetterStyles(letter));
            this.#renderer.appendChild(letterBox, this.#renderer.createText(letter));
            this.#renderer.appendChild(wordContainer, letterBox);
          }

          // add source option before the target option and then remove the target option
          this.#renderer.insertBefore(this.#target.element.parentNode, wordContainer, this.#target.element);
          this.#renderer.removeChild(this.#target.element.parentNode, this.#target.element);

          // Set the matching state for the current source and target selection
          this.#matches[this.#source.option.index] = {
            source: this.#source.option,
            target: this.#target.option
            // connector: this.connector.clone()
          };
          // }
        }
      } else {
        if (this.#source) {
          this.#renderer.removeClass(this.#source.element, 'kip-source');
        }
        if (this.#target) {
          this.#renderer.removeClass(this.#target.element, 'kip-target');
        }
      }

      // Clear the cache variables, which will render the connectors appropriately
      this.#source = undefined;
      this.#target = undefined;
    }
  }

  #updateConnector(element: any) {
    // If there is a source element active, then calculate the mouse position
    if (this.#source) {
      // Get the target matching the provided element
      this.#target = this.#findOption(element, this.targetElements, this.targetOptions);
    }
  }

  #resolvePosition(x: number, y: number): PositionInfo {

    // Get the container boundaries to calculate the required position info
    if (this.containerElement) {
      const rect: DOMRect = this.containerElement.nativeElement.getBoundingClientRect();
      return {
        x: x - rect.left,
        y: y - rect.top
      };
    }

    return { x: 0, y: 0 };
  }

  #validate(): boolean {
    return this.#target !== undefined && this.#source !== undefined && (this.#target.option.value ?? '').toString().includes(this.#source.option.text ?? '');
  }
}
