import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  readonly #authService = inject(AuthService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.#authService.getToken().pipe(
      mergeMap((token: string) => {
        let clonedRequest = request;
        if (token) {
          clonedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`)
          });
        }
        return next.handle(clonedRequest);
      }),
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatusCode.Forbidden
        ) {
          alert(`${request.url} - Url is forbidden, please contact support`);
        } else if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatusCode.GatewayTimeout
        ) {
          alert(`${request.url} - Url is timing out, please contact support`);
        }
        if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatusCode.Unauthorized
        ) {
          this.#authService.logout();
        }
        return throwError(() => error);
      })
    );
  }
}
