import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number | null | undefined, dashForZero = false): string {
    return value === 0 && dashForZero ? '-%' : `${Math.round(value ?? 0)}%`;
  }
}
