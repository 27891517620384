import { createReducer, on } from '@ngrx/store';

import { LessonPlan, LessonPlanActivity } from '../../models';
import * as PlanActions from './plan.actions';

export interface LessonPlanDirty {
  readonly lessonPlan: LessonPlan;
  readonly isDirty: boolean;
  readonly initialActivityIds: readonly number[];
  readonly initialHomeworkIds: readonly number[];
}

export interface PlanState {
  readonly entities: {
    readonly [lessonPlanId: number]: LessonPlanDirty;
  };
  readonly errors: {
    readonly [lessonPlanId: number]: string;
  };
}

const initialState: PlanState = {
  entities: {},
  errors: {}
};

function removeDuplicatesAndAppend(target: readonly LessonPlanActivity[], source: readonly LessonPlanActivity[]) {
  const activities: LessonPlanActivity[] = [];
  for (const activity of source) {
    // sometimes the source can have the activity twice, so need to check the activities being added as well to prevent duplicates
    if (!target.some(a => a.activityId === activity.activityId) && !activities.some(a => a.activityId === activity.activityId)) {
      activities.push(activity);
    }
  }
  return [...target, ...activities];
}

function arraysAreEqual(array1: readonly number[], array2: readonly number[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

function isDirty(lessonPlan: LessonPlan, initialActivityIds: readonly number[], initialHomeworkIds: readonly number[]) {
  const newActivityIds = lessonPlan.activities.map(s => s.activityId);
  const newHomeworkIds = lessonPlan.homework.map(s => s.activityId);

  return !arraysAreEqual(newActivityIds, initialActivityIds) ||
    !arraysAreEqual(newHomeworkIds, initialHomeworkIds);
}

function createPlanState(state: PlanState, value: Partial<PlanState>): PlanState {
  return Object.assign({}, state, value) as PlanState;
}

export const planReducer = createReducer(initialState,
  on(PlanActions.loadAction, (state, action) => {

    const existingErrorsRemoveForThisPlan = Object.fromEntries(
      Object.entries(state.errors).filter(([key]) => key !== action.lessonPlanId.toString()));

    return createPlanState(state, {
      entities: {
        ...state.entities, [action.lessonPlanId]: {
          lessonPlan: action.lessonPlan,
          isDirty: false,
          initialActivityIds: action.lessonPlan.activities.map(s => s.activityId),
          initialHomeworkIds: action.lessonPlan.homework.map(s => s.activityId)
        }
      },
      errors: existingErrorsRemoveForThisPlan
    });
  }),
  on(PlanActions.acceptedAction, (state, action) => {
    const lessonPlanAccepted = state.entities[action.lessonPlanId];
    return createPlanState(state, {
      entities: {
        ...state.entities, [action.lessonPlanId]: {
          lessonPlan: { ...lessonPlanAccepted.lessonPlan, markdownEdited: undefined, accepted: true, hasAssessmentActivities: action.hasAssessmentActivities, isDIYCompatible: action.isDIYCompatible },
          isDirty: false,
          initialActivityIds: lessonPlanAccepted.lessonPlan.activities.map(s => s.activityId),
          initialHomeworkIds: lessonPlanAccepted.lessonPlan.homework.map(s => s.activityId)
        }
      }
    });
  }),
  on(PlanActions.updateSkillbuildersAction, (state, action) => {
    return createPlanState(state, {
      entities: {
        ...state.entities, [action.lessonPlanId]: {
          lessonPlan: action.lessonPlan,
          isDirty: false,
          initialActivityIds: action.lessonPlan.activities.map(s => s.activityId),
          initialHomeworkIds: action.lessonPlan.homework.map(s => s.activityId)
        }
      }
    });
  }),
  on(PlanActions.addHomeworkAction, (state, action) => {
    const lessonPlanHomework = state.entities[action.lessonPlanId];
    if (lessonPlanHomework && action.activities) {
      const newLessonPlan: LessonPlan = {
        ...lessonPlanHomework.lessonPlan,
        homework: removeDuplicatesAndAppend(lessonPlanHomework.lessonPlan.homework, action.activities)
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanHomework.initialActivityIds, lessonPlanHomework.initialHomeworkIds),
            initialActivityIds: lessonPlanHomework.initialActivityIds,
            initialHomeworkIds: lessonPlanHomework.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.addLessonAction, (state, action) => {
    const lessonPlanLesson = state.entities[action.lessonPlanId];
    if (lessonPlanLesson && action.activities) {
      const newLessonPlan: LessonPlan = {
        ...lessonPlanLesson.lessonPlan,
        activities: removeDuplicatesAndAppend(lessonPlanLesson.lessonPlan.activities, action.activities)
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanLesson.initialActivityIds, lessonPlanLesson.initialHomeworkIds),
            initialActivityIds: lessonPlanLesson.initialActivityIds,
            initialHomeworkIds: lessonPlanLesson.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.clearActivitiesAction, (state, action) => {
    const lessonPlanClear = state.entities[action.lessonPlanId];
    if (lessonPlanClear) {
      let lessonActivities = [...lessonPlanClear.lessonPlan.activities];
      let homeworkActivities = [...lessonPlanClear.lessonPlan.homework];
      if (action.isHomework) {
        homeworkActivities = homeworkActivities.filter(s => s.attempted);
      } else {
        lessonActivities = lessonActivities.filter(s => s.attempted);
      }

      const newLessonPlan: LessonPlan = {
        ...lessonPlanClear.lessonPlan,
        activities: lessonActivities,
        homework: homeworkActivities
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanClear.initialActivityIds, lessonPlanClear.initialHomeworkIds),
            initialActivityIds: lessonPlanClear.initialActivityIds,
            initialHomeworkIds: lessonPlanClear.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.updateMarkdownAction, (state, action) => {
    const lessonPlanUpdate = state.entities[action.lessonPlanId];
    if (lessonPlanUpdate) {
      const lessonActivities = [...lessonPlanUpdate.lessonPlan.activities];
      const homeworkActivities = [...lessonPlanUpdate.lessonPlan.homework];

      if (action.isHomework) {
        const index = homeworkActivities.findIndex(s => action.activityId !== 0 && s.activityId === action.activityId);
        if (index !== -1) {
          homeworkActivities[index] = { ...homeworkActivities[index], markdown: action.markdown, html: action.html };
        }
      } else {
        const index = lessonActivities.findIndex(s => action.activityId !== 0 && s.activityId === action.activityId);
        if (index !== -1) {
          lessonActivities[index] = { ...lessonActivities[index], markdown: action.markdown, html: action.html };
        }
      }

      const newLessonPlan: LessonPlan = {
        ...lessonPlanUpdate.lessonPlan,
        markdownEdited: true,
        activities: lessonActivities,
        homework: homeworkActivities
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanUpdate.initialActivityIds, lessonPlanUpdate.initialHomeworkIds),
            initialActivityIds: lessonPlanUpdate.initialActivityIds,
            initialHomeworkIds: lessonPlanUpdate.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.deleteActivityAction, (state, action) => {
    const lessonPlanDelete = state.entities[action.lessonPlanId];
    if (lessonPlanDelete) {
      const lessonActivities = [...lessonPlanDelete.lessonPlan.activities];
      const homeworkActivities = [...lessonPlanDelete.lessonPlan.homework];
      if (action.isHomework) {
        const index = homeworkActivities.findIndex(s => action.activityId !== 0 && s.activityId === action.activityId ||
          action.activityId === 0 && s.lessonActivityPlanId === action.lessonActivityPlanId);
        if (index !== -1) {
          homeworkActivities.splice(index, 1);
        }
      } else {
        const index = lessonActivities.findIndex(s => action.activityId !== 0 && s.activityId === action.activityId ||
          action.activityId === 0 && s.lessonActivityPlanId === action.lessonActivityPlanId);
        if (index !== -1) {
          lessonActivities.splice(index, 1);
        }
      }

      const newLessonPlan: LessonPlan = {
        ...lessonPlanDelete.lessonPlan,
        activities: lessonActivities,
        homework: homeworkActivities
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanDelete.initialActivityIds, lessonPlanDelete.initialHomeworkIds),
            initialActivityIds: lessonPlanDelete.initialActivityIds,
            initialHomeworkIds: lessonPlanDelete.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.orderActivitiesAction, (state, action) => {
    const entity = state.entities[action.lessonPlanId];
    const lessonPlan: LessonPlan = entity.lessonPlan;

    let activities = [...lessonPlan.activities];
    let homework = [...lessonPlan.homework];

    // Sorter func to replicate the order in the action
    const sortFunc = (unorderedActivities: LessonPlanActivity[]): LessonPlanActivity[] => {
      let i = 0;
      const orderedActivities: LessonPlanActivity[] = [];
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      for (const _unorderedActivity of unorderedActivities) {
        const order = action.lessonPlanOrder[i];
        let item: LessonPlanActivity | undefined;
        if (!order.lessonActivityPlanId) {
          item = unorderedActivities.find(a => a.activityId === order.activityId);
          if (item) {
            orderedActivities.push(item);
          }
        }
        else {
          item = unorderedActivities.find(a => a.lessonActivityPlanId === order.lessonActivityPlanId);
          if (item) {
            orderedActivities.push(item);
          }
        }
        i++;
      }
      return orderedActivities;
    };

    if (action.isHomework) {

      homework = action.lessonPlanOrder
        .map(o => lessonPlan.homework.find(f => o.lessonActivityPlanId && f.lessonActivityPlanId === o.lessonActivityPlanId || !o.lessonActivityPlanId && f.activityId === o.activityId))
        .reduce((prev, current) => current === undefined ? prev : [...prev, current], []);

      homework = Object.assign(homework, sortFunc(homework));
    } else {

      activities = action.lessonPlanOrder
        .map(o => lessonPlan.activities.find(f => o.lessonActivityPlanId && f.lessonActivityPlanId === o.lessonActivityPlanId || !o.lessonActivityPlanId && f.activityId === o.activityId))
        .reduce((prev, current) => current === undefined ? prev : [...prev, current], []);

      activities = Object.assign(activities, sortFunc(activities));
    }

    const newLessonPlan: LessonPlan = {
      ...lessonPlan,
      activities: activities,
      homework: homework
    };

    return createPlanState(state, {
      entities: {
        ...state.entities, [action.lessonPlanId]: {
          initialActivityIds: entity.initialActivityIds,
          initialHomeworkIds: entity.initialHomeworkIds,
          isDirty: true,
          lessonPlan: newLessonPlan
        }
      }
    });

  }),
  on(PlanActions.replaceActivityAction, (state, action) => {
    const lessonPlanReplace = state.entities[action.lessonPlanId];
    if (lessonPlanReplace) {
      const lessonActivities = [...lessonPlanReplace.lessonPlan.activities];
      const homeworkActivities = [...lessonPlanReplace.lessonPlan.homework];
      if (action.isHomework) {
        const index = homeworkActivities.findIndex(s => s.activityId === action.activityId);
        if (index !== -1) {
          homeworkActivities[index] = { ...action.newActivity, originalActivityId: action.activityId };
        }
      } else {
        const index = lessonActivities.findIndex(s => s.activityId === action.activityId);
        if (index !== -1) {
          lessonActivities[index] = { ...action.newActivity, originalActivityId: action.activityId };
        }
      }

      const newLessonPlan: LessonPlan = {
        ...lessonPlanReplace.lessonPlan,
        activities: lessonActivities,
        homework: homeworkActivities
      };

      return createPlanState(state, {
        entities: {
          ...state.entities, [action.lessonPlanId]: {
            lessonPlan: newLessonPlan,
            isDirty: isDirty(newLessonPlan, lessonPlanReplace.initialActivityIds, lessonPlanReplace.initialHomeworkIds),
            initialActivityIds: lessonPlanReplace.initialActivityIds,
            initialHomeworkIds: lessonPlanReplace.initialHomeworkIds
          }
        }
      });
    }

    return state;
  }),
  on(PlanActions.errorAction, (state, action) => {
    return createPlanState(state, {
      errors: { ...state.errors, [action.lessonPlanId]: action.error }
    });
  })
);
