/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck
// this was a hack as it was getting confused about typescript versions and
// complaining about the Itnl library

export interface CurrencySymbols {
  currency: string;
  decimal: string;
  grouping: string;
  negative: string;
}

export function createCurrencySymbols(format: Intl.NumberFormat): CurrencySymbols {
  const sample = format.formatToParts(-12_345.67);

  const currency = sample.find(o => o.type === 'currency')?.value ?? '';
  const decimal = sample.find(o => o.type === 'decimal')?.value ?? '';
  const grouping = sample.find(o => o.type === 'group')?.value ?? '';
  const negative = sample.find(o => o.type === 'minusSign')?.value ?? '';

  return {
    currency: currency,
    decimal: decimal,
    grouping: grouping,
    negative: negative
  };
}
