import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

export class EqualityUnorderedValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {

    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);

    if (studentAnswers.length === question.answers.length) {

      const matchedIndexes: number[] = [];

      // For each provided answer check the corresponding question answer matches
      for (let index = 0; index < studentAnswers.length; index++) {
        const studentAnswer = studentAnswers[index];

        // Loop through all the possible answers
        // An answer can only be used once
        // Answers must therefore be unique

        for (let answerIndex = 0; answerIndex < question.answers.length; answerIndex++) {

          if (!matchedIndexes.includes(answerIndex)) {
            const answer = question.answers[answerIndex];

            // Each question answer has a set of alternatives, so check all of them

            for (const expected of answer.values) {
              const actualValue = this.#sanitizeValue(studentAnswer);
              const expectedValue = this.#sanitizeValue(expected);

              if (actualValue === expectedValue) {
                validationResults[index] = ValidationResult.Correct;
                matchedIndexes.push(answerIndex);
                break;
              }
            }
          }
        }
      }

    }

    return validationResults;
  }

  #sanitizeValue(value: AnswerType): string {

    let returnValue = value;

    if (returnValue !== undefined) {
      returnValue = returnValue
        .toString()
        .trim();
    }

    return returnValue;
  }
}
