import { createReducer, on } from '@ngrx/store';
import { OverlaysBackgroundColor } from 'ui-common-lib';

import * as SetColorActions from './overlays-background-color.actions';

export interface OverlaysBackgroundColorState {
  readonly overlays?: OverlaysBackgroundColor;
  readonly backgrounds?: OverlaysBackgroundColor;
  readonly isStudentInTutorMode?: boolean;
}

const initialState: OverlaysBackgroundColorState = {};

export const overlaysBackgroundColorReducer = createReducer(initialState,
  on(SetColorActions.setColorAction, (state, action): OverlaysBackgroundColorState => {
    return {
      ...state,
      ...action,
      isStudentInTutorMode: !!action.isStudentInTutorMode
    };
  })
);
