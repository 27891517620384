@if (showBlank) {
<div class="d-flex flex-column flex-fill h-100 mh-100">
  <div class="card  flex-fill">
  </div>
</div>
}

@if (!whiteboard) {
<kip-explore-manual [activity]="activity" [allowJira]="allowJira" [regionId]="regionId" />
}
@if (!whiteboard) {
<kip-explore-computer class="kip-session" [activity]="activity"
  [activityIdsAlreadySelected]="activityIdsAlreadySelected" [allowAddSkillbuilder]="allowAddSkillbuilder"
  [allowJira]="allowJira" [backgroundColor]="backgroundColor" [regionId]="regionId" [soundRegionId]="soundRegionId"
  [studentData]="studentData" (addSkillbuilder)="addSkillbuilder.emit($event)" />
}

@if (whiteboard) {
<div class="card flex-fill h-100" style="min-height:550px" [style.background-color]="backgroundColor">
  <div class="card-body d-flex flex-row p-0">
    <kip-whiteboard-pages [page]="page" [pages]="pages" [soundFile]="activity?.soundFile"
      (selectPage)="page = $event" />
    <div class="kip-questions-content kip-questions-content--manual d-flex flex-column flex-fill position-relative">
      <div class="kip-whiteboard-container kip-whiteboard-container--manual">
        <kip-whiteboard [backgroundImage]="whiteboardBackgroundImage" [readonly]="!isTutor"
          [whiteboardGuidKey]="whiteboardGuidKey" (events)="onWhiteboardEvent($event)" />
      </div>
    </div>
  </div>
</div>
}