import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Icon, iconGroup, SkinTone } from '../../icons';
import { Award, AwardGroupType, OrganisationAward } from '../../models';
import { AwardsService } from '../../services';

@Component({
  selector: 'kip-awards-group-list',
  templateUrl: './awards-group-list-component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwardsGroupListComponent implements OnInit, OnDestroy {

  readonly #awardsService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #awards: Award[] = [];
  #subscriptions: Subscription[] = [];

  readonly skinTone = SkinTone.SkinToneA;
  readonly iconGroups = iconGroup;
  readonly awardGroupType = AwardGroupType;

  get awards() {
    return this.#awards;
  }

  activeGroup: AwardGroupType | undefined;

  @Output()
  readonly awardPicked = new EventEmitter<Award>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#awardsService.getAwards().subscribe(awards => {
        this.#awards = awards;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  pick(value: Icon, groupTypeId: AwardGroupType) {
    const award = this.awards.find(x => x.id === value);

    if (award !== undefined) {
      award.awardGroupTypeId = groupTypeId;
      this.awardPicked.emit(award);
    }
  }

  pickOrganisation(value: OrganisationAward) {
    const award: Award = {
      id: value.awardId,
      label: value.label,
      description: value.description,
      points: value.points,
      name: '',
      awardGroupTypeId: AwardGroupType.Organisation
    };

    this.awardPicked.emit(award);
  }
}
