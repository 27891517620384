import { createAction, props } from '@ngrx/store';
import { LessonChat } from 'message-lib';

enum ChatActionTypes {
  LoadMany = 'Tutor > Chat > Load Many',
  Add = 'Tutor > Chat > Add',
  UpdateTyping = 'Tutor > Chat > Typing',
  RefreshMessages = 'Tutor > Chat > Refresh'
}

export const loadManyAction = createAction(
  ChatActionTypes.LoadMany,
  props<{
    readonly lessonGuid: string;
    readonly chatMessages: readonly LessonChat[];
  }>()
);

export const addAction = createAction(
  ChatActionTypes.Add,
  props<{
    readonly lessonGuid: string;
    readonly chatMessages: LessonChat;
  }>()
);

export const updateTypingAction = createAction(
  ChatActionTypes.UpdateTyping,
  props<{
    readonly lessonGuid: string;
    readonly isTyping: boolean;
  }>()
);

export const refreshChatAction = createAction(ChatActionTypes.RefreshMessages);
