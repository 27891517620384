import { createSelector } from '@ngrx/store';

import { SelectedSession } from '../../models';
import { selectSessionEntity, selectSessionState } from '../state';
import { SessionState } from './session.reducer';

export const selectConnectionState = createSelector(selectSessionState, (state: SessionState) => state.connected);
export const selectPublishState = createSelector(selectSessionState, (state: SessionState) => state.publishState);
export const selectPublishVideo = createSelector(selectSessionState, (state: SessionState) => state.publishVideo);
export const selectPublishAudio = createSelector(selectSessionState, (state: SessionState) => state.publishAudio);
export const selectLoadedState = createSelector(selectSessionState, (state: SessionState) => state.loaded);
export const selectSessionId = createSelector(selectSessionState, (state: SessionState) => state.entity?.sessionId ?? 0);
export const selectClassInProgress = createSelector(selectSessionState, (state: SessionState) => state.classInProgress);
export const selectCaptions = createSelector(selectSessionState, (state: SessionState) => state.captions);

export const selectSelectedSession = createSelector(selectSessionEntity, entity => {
  let session: SelectedSession | undefined;

  if (entity) {
    session = {
      sessionId: entity.sessionId,
      dateTime: entity.dateTime,
      dateTimeOffsetIanaTimeZoneName: entity.dateTimeOffsetIanaTimeZoneName,
      tutorId: entity.tutorId,
      tutorPhotoUrl: entity.tutorPhotoUrl,
      tutorPhotoDefault: entity.tutorPhotoDefault,
      tutorImageData: entity.tutorImageData,
      parentNote: entity.parentNote,
      internalNote: entity.internalNote,
      parentNoteRanking: entity.parentNoteRanking,
      internalNoteRanking: entity.internalNoteRanking,
      tutor: entity.tutor,
      centreTitle: entity.centreTitle,
      regionName: entity.regionName,
      duration: entity.duration,
      isAssessment: entity.isAssessment,
      idle: entity.idle,
      isDropIn: entity.isDropIn,
      isDropInSessionAvailable: entity.isDropInSessionAvailable,
      allowTutorLessonInternalNoteRanking: entity.allowTutorLessonInternalNoteRanking,
      allowTutorLessonParentNoteRanking: entity.allowTutorLessonParentNoteRanking,
      allowTutorSessionInternalNoteRanking: entity.allowTutorSessionInternalNoteRanking,
      allowTutorSessionParentNoteRanking: entity.allowTutorSessionParentNoteRanking,
      sessionRecordingTermsAndConditionsTemplateId: entity.sessionRecordingTermsAndConditionsTemplateId,
      tutorRecordingEnabled: entity.tutorRecordingEnabled,
      sessionRecordingHtml: entity.sessionRecordingHtml,
      sessionCaptions: entity.sessionCaptions,
      studentPaymentWarnings: entity.studentPaymentWarnings
    };
  }

  return session;
});
