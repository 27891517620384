export enum HolidayDefaultAction {
  NoAction = 0,
  Flag = 1,
  Cancel = 2,
  DoItYourself = 3,
  Bundle = 4
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export const HolidayDefaultActionLabelMapping: Record<HolidayDefaultAction, string> = {
  [HolidayDefaultAction.Flag]: 'Flag',
  [HolidayDefaultAction.Cancel]: 'Cancel all sessions',
  [HolidayDefaultAction.DoItYourself]: 'Change all sessions to DIY',
  [HolidayDefaultAction.Bundle]: 'Determine by student bundle',
  [HolidayDefaultAction.NoAction]: 'All sessions continue as normal'
};

export const HolidayDefaultActionOptions: { action: HolidayDefaultAction, label: string }[] =
  Object.entries(HolidayDefaultActionLabelMapping).map(([action, label]) => ({ action: +action as HolidayDefaultAction, label }));
