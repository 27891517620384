import { Pipe, PipeTransform } from '@angular/core';

import { ValidationResult } from '../models';

@Pipe({
  name: 'answerValidation'
})
export class AnswerValidationPipe implements PipeTransform {

  transform(valid: ValidationResult, correct: string, incorrect: string) {

    switch (valid) {
      case ValidationResult.Correct:
        return correct;
      case ValidationResult.Incorrect:
        return incorrect;
      default:
        return '';
    }
  }
}
