import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { Icons } from 'icon-lib';

import { FilterValue, SortBy, SortColumnKey, SortDirection } from '../models';

@Component({
  selector: 'kip-paged-filter',
  templateUrl: './paged-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagedFilterComponent<T> {

  readonly icons = Icons;
  readonly sortDirection = SortDirection;
  readonly columnName = input.required<string>();
  readonly id = input.required<string>();
  readonly sortColumnKey = input<SortColumnKey | undefined>(undefined);
  readonly values = input<FilterValue<T>[]>([]);
  readonly value = input<T | null>(null);
  readonly filterActive = computed(() => {
    const value = this.value();

    return !!value;
  });
  readonly outputValue = output<T | null>();
  readonly sortBy = model<SortBy | undefined>();
  readonly sortedAsc = computed(() => {
    const sortBy = this.sortBy();
    const sortColumnKey = this.sortColumnKey();

    if (!sortBy) {
      return false;
    }

    return sortBy.sortColumnKey === sortColumnKey
      && sortBy.sortDirection === SortDirection.Ascending;
  });
  readonly sortedDesc = computed(() => {
    const sortBy = this.sortBy();
    const sortColumnKey = this.sortColumnKey();

    if (!sortBy) {
      return false;
    }

    return sortBy.sortColumnKey === sortColumnKey
      && sortBy.sortDirection === SortDirection.Descending;
  });

  changeSort(sortDirection: SortDirection) {
    const sortColumnKey = this.sortColumnKey();
    if (sortColumnKey) {
      this.sortBy.set({ sortColumnKey: sortColumnKey, sortDirection: sortDirection });
    }
  }
}
