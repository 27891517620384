import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-1-2',
  templateUrl: './diy-set1-2.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet12Component implements IconComponent {

}
