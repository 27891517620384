<div class="d-none d-md-flex flex-column kip-whiteboard__pdf-buttons user-select-none"
  [class.kip-scrollable]="scrollable">
  <div class="kip-scroller kip-btn text-center border-0" (click)="scrollUp()">
    <fa-icon [icon]="icons.carets.up" />
  </div>
  <ul #container class="kip-items z-index-20 mb-0 pb-0" [class.kip-items--rounded]="!scrollable">
    @for (currentPage of pages; track currentPage; let i = $index) {
    @if (scrollable || i > 0) {
    <li class="kip-anchor"></li>
    }
    <li class="kip-item position-relative d-flex flex-row" [class.kip-active]="true"
      (click)="click(currentPage.pageNumber)">
      @if (currentPage.pageType === pageType.Pdf) {
      <span class="kip-item-inner text-center">
        @if (currentPage.pageNumber !== page) {
        <span>{{ currentPage.pageNumber }}</span>
        }
        @if (currentPage.pageNumber === page) {
        <fa-icon [icon]="icons.preview.solid" />
        }
        @if (currentPage.hasData) {
        <fa-icon class="me-1 position-absolute bottom-0 right-0" size="xs" [fixedWidth]="true"
          [icon]="icons.whiteboardOpen" />
        }
      </span>
      }
      @if (currentPage.pageType === pageType.Blank) {
      <span class="kip-item-inner text-center" title="Blank Working Page">
        <fa-icon [icon]="icons.whiteboardPage.blank" />
      </span>
      }
      @if (currentPage.pageType === pageType.Additional) {
      <span class="kip-item-inner text-center" title="Uploaded Content">
        <fa-icon [icon]="icons.whiteboardPage.additional" />
      </span>
      }
    </li>
    @if (i === (pages.length - 1)) {
    <li class="kip-anchor"></li>
    }
    }
  </ul>
  <div class="kip-scroller kip-btn text-center rounded-right-0 border-0" (click)="scrollDown()">
    <fa-icon [icon]="icons.carets.down" />
  </div>
</div>
<div class="kip-whiteboard__sound-player">
  <kip-whiteboard-audio [soundFile]="soundFile" />
</div>