@if (helpNode) {
<div class="kip-activity-selector kip-activity cursor-pointer d-flex flex-row justify-content-between border-top p-2"
  (click)="nodeSelected()">
  <div class="flex-column test-start pe-2" [ngClass]="{'fw-bold': selected}">
    {{ helpNode.name }}
  </div>
  <div class="flex-column test-end">
    @if (helpNode.children.length > 0) {
    <fa-icon [icon]="helpNode.expanded ? icons.chevrons.down : icons.chevrons.right" />
    }
  </div>
</div>
}
@if (helpNode && helpNode.expanded) {
<div>
  @if (helpNode.children) {
  <div>
    @for (node of helpNode.children; track node) {
    <div class="ps-4">
      <kip-help-node [helpNode]="node" [selectedUId]="selectedUId" (selectHelpNode)="selectHelpNode.emit($event)" />
    </div>
    }
  </div>
  }
</div>
}