import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateTimeOffsetIanaTimeZoneName } from 'moment-extensions-lib';
import { allowedDaysHomework } from 'pipes-directives-lib';

import { DashboardLesson, DashboardLessonAndHomework, DashboardLessonState, LessonStatus, LessonSummary, LessonType } from '../models';

@Injectable({ providedIn: 'root' })
export class MyDayDashboardService {

  mapLessonState(minutesEarlyStartDashboard: number, ...lessons: LessonSummary[]) {

    const state: DashboardLessonState = {
      at: moment.utc().startOf('minute'),
      available: [],
      homework: [],
      upcoming: [],
      diyLessons: [],
      useNewCalendar: false
    };

    for (const lesson of lessons) {
      // Homework checks

      let available = true;

      if (lesson.accepted && lesson.lessonStatus === LessonStatus.Attended && lesson.homeworkActivityCount > 0
        && !this.#lessonIsHomeworkExpired(state.at, lesson)) {
        state.homework.push({ available, ...lesson });
      }

      // Lesson Checks
      if (!this.#lessonTypeExpired(lesson, state.at)
        && lesson.lessonStatus !== LessonStatus.Deferred && lesson.lessonStatus !== LessonStatus.Forfeit &&
        lesson.dropInLessonCompleted !== true) {
        const today = this.#lessonTypeMapperToday(lesson.dateTime);
        const thisWeek = this.#lessonTypeMapperThisWeek(lesson.dateTime);
        available = this.#lessonIsAvailable(state.at, lesson, minutesEarlyStartDashboard);

        if (lesson.lessonType === LessonType.HomeMakeUp) {
          if (thisWeek) {
            state.diyLessons.push({ available, ...lesson });
          }
        }
        else if (today && thisWeek) {
          state.available.push({ available, ...lesson });
        } else {
          state.upcoming.push({ available, ...lesson });
        }
      }

      state.useNewCalendar = lesson.centreNewCalendar;
    }

    return state;
  }

  mapDashboardLessonStateToLessonAndHomework(dashboardState: DashboardLessonState, visibleLessonCount: number) {

    let lessons = [...dashboardState.available, ...dashboardState.upcoming];
    lessons = this.#sortLessonsByDateTime(lessons);

    const homework = this.#sortLessonsByDateTime(dashboardState.homework);
    const lessonsAndHomework: DashboardLessonAndHomework[] = [];

    for (let i = 0; i < visibleLessonCount; i++) {

      const lessonAndHomework: DashboardLessonAndHomework = { lesson: undefined, homework: undefined };

      if (lessons.length > i) {
        lessonAndHomework.lesson = lessons[i];
      }

      if (homework.length > i) {
        lessonAndHomework.homework = homework[i];
      }

      if (lessonAndHomework.lesson || lessonAndHomework.homework) {
        lessonsAndHomework.push(lessonAndHomework);
      }

    }

    return lessonsAndHomework;
  }

  #sortLessonsByDateTime(lessons: DashboardLesson[]) {
    return lessons.sort((a, b) => {

      const dateTimeA = moment(a.dateTime.dateTimeOffset);
      const dateTimeB = moment(b.dateTime.dateTimeOffset);
      const diff = dateTimeA.diff(dateTimeB);

      if (diff > 0) {
        return 1;
      }
      else if (diff === 0) {
        return 0;
      }
      else {
        return -1;
      }
    });
  }

  #lessonTypeExpired(lesson: LessonSummary, now: moment.Moment) {
    const availableUntil = moment.utc(lesson.lessonAvailableUntil.dateTimeOffset).startOf('minute');
    return availableUntil.diff(now, 'minutes') < 0;
  }

  #lessonTypeMapperThisWeek(lessonDateTime: DateTimeOffsetIanaTimeZoneName) {
    let thisWeek = false;
    const endOfWeek = moment().tz(lessonDateTime.ianaTimeZone).endOf('week').add(1, 'days');
    const lessonTime = moment(lessonDateTime.dateTimeOffset).tz(lessonDateTime.ianaTimeZone);
    thisWeek = lessonTime.isSameOrBefore(endOfWeek);

    return thisWeek;
  }

  #lessonTypeMapperToday(lessonDateTime: DateTimeOffsetIanaTimeZoneName) {
    let today = false;
    const endOfDay = moment(lessonDateTime.dateTimeOffset).tz(lessonDateTime.ianaTimeZone);
    today = moment().isSameOrAfter(endOfDay, 'day');

    return today;
  }

  #lessonIsAvailable(now: moment.Moment, lesson: LessonSummary, minutesEarlyStartDashboard: number) {
    let available = false;
    if (lesson.isAssessment) {
      available = true;
    } else {
      const date = moment.utc(lesson.dateTime.dateTimeOffset).startOf('minute');
      const minutes = date.diff(now, 'minutes');

      if (minutes <= minutesEarlyStartDashboard) {
        available = true;
      }
    }

    return available;
  }

  #lessonIsHomeworkExpired(now: moment.Moment, lesson: LessonSummary) {
    const deadline = moment.utc(lesson.dateTime.dateTimeOffset).add(allowedDaysHomework, 'days').startOf('minute');
    return deadline.diff(now, 'minutes') < 0;
  }
}
