/* eslint-disable angular-file-naming/service-filename-suffix */

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NgbDateFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const momentDate = moment(value, ['DD MM YYYY', 'DD MMM YYYY']);
      if (momentDate.isValid()) {
        return momentDate.toDateStruct();
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const momentDate = moment().toMoment(date);
    if (!momentDate.isValid()) {
      return '';
    }

    return momentDate.format('DD MMM YYYY');
  }
}
