/* eslint-disable max-len */

export const protractor360 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 635.09 635.09" version="1.0">
<path style="fill-rule:evenodd;stroke:#000;stroke-linecap:round;stroke-width:.897;fill:#fff" d="M422.44 563.07a13.575 13.575 0 1 1-27.15 0 13.575 13.575 0 1 1 27.15 0z" transform="translate(-91.319 -245.525)"/>
<path style="stroke:#000;fill:none" d="M396.75 552.36h6.5M400 555.61v-6.5" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" transform="translate(-104.454 -234.82)" d="M104.9 552.36h36.8"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" transform="rotate(-90 -169.637 -65.183)" xml:space="preserve" y="158.236" x="-552.302">
  <tspan y="158.236" x="-552.302">0</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m87.72 497.3 36.24 6.39" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-80.001 -181.148 -68.278)" y="226.075" x="-474.451">
  <tspan x="-474.451" y="226.075">10</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m102.03 443.91 34.58 12.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-70 -208.906 -58.532)" y="301.032" x="-382.182">
  <tspan x="-382.182" y="301.032">20</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m125.39 393.81 31.87 18.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-60.001 -244.583 -46.004)" y="358.827" x="-278.3">
  <tspan x="-278.3" y="358.827">30</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m157.09 348.54 28.19 23.65" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-50 -293.014 -28.998)" y="397.705" x="-165.959">
  <tspan x="-165.959" y="397.705">40</tspan>
</text>
<path d="m218.17 309.45 23.66 28.19" style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" transform="translate(-104.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-40 -374.806 26.08)" y="430.626" x="-31.721">
  <tspan x="-31.721" y="430.626">50</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m241.45 277.75 18.4 31.87" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-30 -479.407 36.451)" y="414.595" x="70.289">
  <tspan x="70.289" y="414.595">60</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m291.55 254.39 12.58 34.58" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-20 -707.092 116.4)" y="392.094" x="187.018">
  <tspan x="187.018" y="392.094">70</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m344.94 240.08 6.39 36.25" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-10 -1383.23 353.817)" y="349.665" x="298.067">
  <tspan x="298.067" y="349.665">80</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="M400 235.26v36.81" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" y="288.598" x="400.06" transform="translate(-82.454 -234.82)">
  <tspan x="400.06" y="288.598">90</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m455.06 240.08-6.39 36.25" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(10 1300.775 -588.637)" y="210.747" x="489.9">
  <tspan x="489.9" y="210.747">100</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m508.45 254.39-12.58 34.58" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(20 624.638 -351.22)" y="118.478" x="564.856">
  <tspan x="564.856" y="118.478">110</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m558.55 277.75-18.4 31.87" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(30 396.953 -271.271)" y="14.595" x="622.651">
  <tspan x="622.651" y="14.595">120</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m603.83 309.45-23.66 28.19" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(40 281.354 -230.68)" y="-97.745" x="661.529">
  <tspan x="661.529" y="-97.745">130</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m642.91 348.54-28.19 23.65" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(50 210.56 -205.822)" y="-215.131" x="680.309">
  <tspan x="680.309" y="-215.131">140</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m674.61 393.81-31.87 18.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(60.001 162.129 -188.816)" y="-333.993" x="678.42">
  <tspan x="678.42" y="-333.993">150</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m697.97 443.91-34.58 12.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(70 126.452 -176.288)" y="-450.723" x="655.919">
  <tspan x="655.919" y="-450.723">160</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m712.28 497.3-36.24 6.39" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(80.001 98.694 -166.542)" y="-561.771" x="613.49">
  <tspan x="613.49" y="-561.771">170</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="M717.1 552.36h-36.8" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(90 76.183 -158.637)" y="-663.764" x="552.422">
  <tspan x="552.422" y="-663.764">180</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m712.28 607.43-36.24-6.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(99.999 57.293 -152.004)" y="-753.604" x="474.571">
  <tspan x="474.571" y="-753.604">190</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m697.97 660.82-34.58-12.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(110 40.984 -146.277)" y="-828.56" x="382.303">
  <tspan x="382.303" y="-828.56">200</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m674.61 710.91-31.87-18.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(120 26.56 -141.212)" y="-886.356" x="278.42">
  <tspan x="278.42" y="-886.356">210</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m642.91 756.19-28.19-23.66" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(130 13.522 -136.634)" y="-925.234" x="166.079">
  <tspan x="166.079" y="-925.234">220</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m603.83 795.27-23.66-28.19" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(140 1.507 -132.415)" y="-944.013" x="48.694">
  <tspan x="48.694" y="-944.013">230</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m558.55 826.98-18.4-31.87" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(150 -9.767 -128.457)" y="-942.124" x="-70.169">
  <tspan x="-70.169" y="-942.124">240</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m508.45 850.34-12.58-34.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(160 -20.524 -124.68)" y="-919.623" x="-186.898">
  <tspan x="-186.898" y="-919.623">250</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m455.06 864.64-6.39-36.24" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(170 -30.955 -121.017)" y="-877.194" x="-297.946">
  <tspan x="-297.946" y="-877.194">260</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="M400 869.46v-36.8" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(180 -41.227 -117.41)" y="-816.127" x="-399.94">
  <tspan x="-399.94" y="-816.127">270</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m344.94 864.64 6.39-36.24" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-170 -51.499 -113.803)" y="-738.276" x="-489.78">
  <tspan x="-489.78" y="-738.276">280</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m291.55 850.34 12.58-34.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-160 -61.93 -110.14)" y="-646.007" x="-564.736">
  <tspan x="-564.736" y="-646.007">290</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m241.45 826.98 18.4-31.87" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-150 -72.687 -106.363)" y="-542.124" x="-622.531">
  <tspan x="-622.531" y="-542.124">300</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m196.17 795.27 23.66-28.19" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-140 -83.96 -102.405)" y="-429.783" x="-661.409">
  <tspan x="-661.409" y="-429.783">310</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m157.09 756.19 28.19-23.66" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-130 -95.976 -98.186)" y="-312.398" x="-680.189">
  <tspan x="-680.189" y="-312.398">320</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m125.39 710.91 31.87-18.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-120 -109.014 -93.608)" y="-193.535" x="-678.3">
  <tspan x="-678.3" y="-193.535">330</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m102.03 660.82 34.58-12.59" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-110 -123.438 -88.543)" y="-76.806" x="-655.799">
  <tspan x="-655.799" y="-76.806">340</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="m87.72 607.43 36.24-6.4" transform="translate(-82.454 -234.82)"/>
<text style="font-size:17.6px;font-family:Bitstream Vera Sans;fill:#000;text-anchor:middle;line-height:100%;text-align:center" xml:space="preserve" transform="rotate(-100 -139.746 -82.816)" y="34.242" x="-613.37">
  <tspan x="-613.37" y="34.242">350</tspan>
</text>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.897;fill:none" d="M82.903 552.36H119.7" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m82.819 546.83 18.401.32M82.964 541.29l18.386.64M83.206 535.76l18.374.96M83.544 530.23l18.356 1.29M84.509 519.2l18.301 1.93M85.136 513.7l18.264 2.24M85.858 508.21l18.222 2.56M86.677 502.74l18.173 2.88" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m84.11 524.73 25.98 2.27" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m88.6 491.83 18.06 3.51M89.703 486.41l17.997 3.82M90.902 481l17.928 4.14M92.194 475.62l17.856 4.45M95.06 464.92l17.69 5.07M96.633 459.61l17.597 5.38M98.297 454.33l17.503 5.69M100.05 449.08l17.4 5.99" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m93.708 470.29 25.192 6.75" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m103.84 438.68 17.18 6.59M105.87 433.53l17.06 6.89M107.99 428.41l16.94 7.19M110.2 423.33l16.81 7.49M114.88 413.3l16.54 8.06M117.35 408.34l16.39 8.36M119.9 403.43l16.25 8.64M122.55 398.57l16.09 8.92" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m112.61 418.35 23.64 11.02" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m128.08 388.98 15.77 9.47M130.97 384.26l15.61 9.75M133.95 379.59l15.43 10.02M137.01 374.97l15.25 10.29M143.36 365.9l14.88 10.82M146.65 361.45l14.7 11.07M150.02 357.06l14.5 11.33M153.47 352.72l14.3 11.58" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m140.25 370.48 21.37 14.96" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m160.58 344.24 13.89 12.07M164.25 340.09l13.68 12.32M167.99 336.01l13.46 12.55M171.8 332l13.24 12.78M179.63 324.17l12.79 13.23M183.65 320.36l12.55 13.45M187.73 316.62l12.32 13.67M191.88 312.95l12.07 13.88" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m175.78 328.14 18.44 18.44" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m200.36 305.83 11.58 14.3M204.69 302.38l11.33 14.5M209.09 299.01l11.07 14.7M213.54 295.72l10.81 14.89M222.61 289.37l10.29 15.25M227.22 286.31l10.03 15.43M231.89 283.34l9.76 15.6M236.62 280.44l9.47 15.78" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m218.12 292.61 14.96 21.37" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m246.2 274.91 8.93 16.09M251.07 272.27l8.64 16.24M255.98 269.71l8.36 16.39M260.94 267.24l8.06 16.54M270.97 262.56l7.49 16.81M276.05 260.35l7.19 16.94M281.16 258.23l6.9 17.06M286.32 256.2l6.59 17.18" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m265.99 264.98 11.02 23.63" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m296.72 252.42 5.99 17.39M301.97 250.66l5.69 17.5M307.25 248.99l5.38 17.6M312.56 247.42l5.07 17.69M323.26 244.56l4.45 17.85M328.64 243.26l4.14 17.93M334.04 242.07l3.83 17.99M339.47 240.96l3.51 18.06" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m317.93 246.07 6.75 25.19" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m350.37 239.04 2.88 18.17M355.85 238.22l2.56 18.22M361.34 237.5l2.24 18.26M366.84 236.87l1.92 18.3M377.87 235.91l1.28 18.35M383.4 235.57l.96 18.37M388.93 235.33l.64 18.39M394.46 235.18l.32 18.4" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m372.36 236.47 2.28 25.99" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m405.54 235.18-.32 18.4M411.07 235.33l-.64 18.39M416.6 235.57l-.96 18.37M422.13 235.91l-1.28 18.35M433.16 236.87l-1.92 18.3M438.66 237.5l-2.24 18.26M444.15 238.22l-2.56 18.22M449.63 239.04l-2.88 18.17" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m427.64 236.47-2.28 25.99" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m460.53 240.96-3.51 18.06M465.96 242.07l-3.83 17.99M471.36 243.26l-4.14 17.93M476.74 244.56l-4.45 17.85M487.44 247.42l-5.07 17.69M492.75 248.99l-5.38 17.6M498.03 250.66l-5.69 17.5M503.28 252.42l-5.99 17.39" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m482.07 246.07-6.75 25.19" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m513.68 256.2-6.59 17.18M518.84 258.23l-6.9 17.06M523.95 260.35l-7.19 16.94M529.03 262.56l-7.49 16.81M539.06 267.24 531 283.78M544.02 269.71l-8.36 16.39M548.93 272.27l-8.64 16.24M553.8 274.91 544.87 291" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m534.01 264.98-11.02 23.63" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m563.38 280.44-9.47 15.78M568.11 283.34l-9.76 15.6M572.78 286.31l-10.03 15.43M577.39 289.37l-10.29 15.25M586.46 295.72l-10.81 14.89M590.91 299.01l-11.07 14.7M595.31 302.38l-11.33 14.5M599.64 305.83l-11.58 14.3" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m581.88 292.61-14.96 21.37" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m608.12 312.95-12.07 13.88M612.27 316.62l-12.32 13.67M616.35 320.36l-12.55 13.45M620.37 324.17l-12.79 13.23M628.2 332l-13.24 12.78M632.01 336.01l-13.46 12.55M635.75 340.09l-13.68 12.32M639.42 344.24l-13.89 12.07" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m624.22 328.14-18.44 18.44" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m646.53 352.72-14.3 11.58M649.98 357.06l-14.5 11.33M653.35 361.45l-14.7 11.07M656.64 365.9l-14.88 10.82M662.99 374.97l-15.25 10.29M666.05 379.59l-15.43 10.02M669.03 384.26l-15.61 9.75M671.92 388.98l-15.77 9.47" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m659.75 370.48-21.37 14.96" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m677.45 398.57-16.09 8.92M680.1 403.43l-16.25 8.64M682.65 408.34l-16.39 8.36M685.12 413.3l-16.54 8.06M689.8 423.33l-16.81 7.49M692.01 428.41l-16.94 7.19M694.13 433.53l-17.06 6.89M696.16 438.68l-17.18 6.59" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m687.39 418.35-23.64 11.02" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m699.95 449.08-17.4 5.99M701.7 454.33l-17.5 5.69M703.37 459.61l-17.6 5.38M704.94 464.92l-17.69 5.07M707.81 475.62l-17.86 4.45M709.1 481l-17.93 4.14M710.3 486.41l-18 3.82M711.4 491.83l-18.06 3.51" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m706.29 470.29-25.19 6.75" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m713.32 502.74-18.17 2.88M714.14 508.21l-18.22 2.56M714.86 513.7l-18.26 2.24M715.49 519.2l-18.3 1.93M716.46 530.23l-18.36 1.29M716.79 535.76l-18.37.96M717.04 541.29l-18.39.64M717.18 546.83l-18.4.32" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="M715.89 524.73 689.91 527" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m717.18 557.9-18.4-.32M717.04 563.43l-18.39-.64M716.79 568.96l-18.37-.96M716.46 574.49l-18.36-1.28M715.49 585.52l-18.3-1.92M714.86 591.02l-18.26-2.24M714.14 596.51l-18.22-2.56M713.32 601.99l-18.17-2.88" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m715.89 580-25.98-2.27" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m711.4 612.89-18.06-3.51M710.3 618.32l-18-3.83M709.1 623.72l-17.93-4.14M707.81 629.11l-17.86-4.45M704.94 639.8l-17.69-5.07M703.37 645.11l-17.6-5.38M701.7 650.39l-17.5-5.68M699.95 655.64l-17.4-5.99" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m706.29 634.43-25.19-6.75" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m696.16 666.05-17.18-6.6M694.13 671.2l-17.06-6.89M692.01 676.31l-16.94-7.19M689.8 681.39l-16.81-7.48M685.12 691.43l-16.54-8.07M682.65 696.38l-16.39-8.35M680.1 701.29l-16.25-8.64M677.45 706.16l-16.09-8.92" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m687.39 686.37-23.64-11.02" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m671.92 715.75-15.77-9.48M669.03 720.47l-15.61-9.75M666.05 725.14l-15.43-10.02M662.99 729.75l-15.25-10.29M656.64 738.82l-14.88-10.81M653.35 743.28l-14.7-11.08M649.98 747.67l-14.5-11.33M646.53 752l-14.3-11.58" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m659.75 734.24-21.37-14.96" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m639.42 760.48-13.89-12.07M635.75 764.63l-13.68-12.31M632.01 768.71l-13.46-12.55M628.2 772.73l-13.24-12.78M620.37 780.56l-12.79-13.24M616.35 784.37l-12.55-13.46M612.27 788.11l-12.32-13.68M608.12 791.78l-12.07-13.89" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m624.22 776.58-18.44-18.44" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m599.64 798.9-11.58-14.3M595.31 802.34l-11.33-14.5M590.91 805.71l-11.07-14.69M586.46 809.01l-10.81-14.89M577.39 815.36 567.1 800.1M572.78 818.41l-10.03-15.43M568.11 821.39l-9.76-15.61M563.38 824.28l-9.47-15.77" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m581.88 812.11-14.96-21.36" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m553.8 829.82-8.93-16.1M548.93 832.46l-8.64-16.25M544.02 835.02l-8.36-16.4M539.06 837.49 531 820.95M529.03 842.17l-7.49-16.82M523.95 844.37l-7.19-16.94M518.84 846.49l-6.9-17.06M513.68 848.52l-6.59-17.18" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m534.01 839.75-11.02-23.64" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m503.28 852.31-5.99-17.4M498.03 854.06l-5.69-17.5M492.75 855.73l-5.38-17.6M487.44 857.3l-5.07-17.69M476.74 860.17l-4.45-17.86M471.36 861.46l-4.14-17.93M465.96 862.66l-3.83-18M460.53 863.76l-3.51-18.06" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m482.07 858.65-6.75-25.19" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m449.63 865.69-2.88-18.18M444.15 866.5l-2.56-18.22M438.66 867.23l-2.24-18.27M433.16 867.85l-1.92-18.3M422.13 868.82l-1.28-18.36M416.6 869.16l-.96-18.38M411.07 869.4l-.64-18.39M405.54 869.54l-.32-18.4" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m427.64 868.25-2.28-25.98" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m394.46 869.54.32-18.4M388.93 869.4l.64-18.39M383.4 869.16l.96-18.38M377.87 868.82l1.28-18.36M366.84 867.85l1.92-18.3M361.34 867.23l2.24-18.27M355.85 866.5l2.56-18.22M350.37 865.69l2.88-18.18" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m372.36 868.25 2.28-25.98" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m339.47 863.76 3.51-18.06M334.04 862.66l3.83-18M328.64 861.46l4.14-17.93M323.26 860.17l4.45-17.86M312.56 857.3l5.07-17.69M307.25 855.73l5.38-17.6M301.97 854.06l5.69-17.5M296.72 852.31l5.99-17.4" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m317.93 858.65 6.75-25.19" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m286.32 848.52 6.59-17.18M281.16 846.49l6.9-17.06M276.05 844.37l7.19-16.94M270.97 842.17l7.49-16.82M260.94 837.49l8.06-16.54M255.98 835.02l8.36-16.4M251.07 832.46l8.64-16.25M246.2 829.82l8.93-16.1" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m265.99 839.75 11.02-23.64" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m236.62 824.28 9.47-15.77M231.89 821.39l9.76-15.61M227.22 818.41l10.03-15.43M222.61 815.36l10.29-15.26M213.54 809.01l10.81-14.89M209.09 805.71l11.07-14.69M204.69 802.34l11.33-14.5M200.36 798.9l11.58-14.3" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m218.12 812.11 14.96-21.36" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m191.88 791.78 12.07-13.89M187.73 788.11l12.32-13.68M183.65 784.37l12.55-13.46M179.63 780.56l12.79-13.24M171.8 772.73l13.24-12.78M167.99 768.71l13.46-12.55M164.25 764.63l13.68-12.31M160.58 760.48l13.89-12.07" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m175.78 776.58 18.44-18.44" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m153.47 752 14.3-11.58M150.02 747.67l14.5-11.33M146.65 743.28l14.7-11.08M143.36 738.82l14.88-10.81M137.01 729.75l15.25-10.29M133.95 725.14l15.43-10.02M130.97 720.47l15.61-9.75M128.08 715.75l15.77-9.48" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m140.25 734.24 21.37-14.96" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m122.55 706.16 16.09-8.92M119.9 701.29l16.25-8.64M117.35 696.38l16.39-8.35M114.88 691.43l16.54-8.07M110.2 681.39l16.81-7.48M107.99 676.31l16.94-7.19M105.87 671.2l17.06-6.89M103.84 666.05l17.18-6.6" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m112.61 686.37 23.64-11.02" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m100.05 655.64 17.4-5.99M98.297 650.39l17.503-5.68M96.633 645.11l17.597-5.38M95.06 639.8l17.69-5.07M92.194 629.11l17.856-4.45M90.902 623.72l17.928-4.14M89.703 618.32l17.997-3.83M88.6 612.89l18.06-3.51" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m93.708 634.43 25.192-6.75" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.63427;fill:none" d="m86.677 601.99 18.173-2.88M85.858 596.51l18.222-2.56M85.136 591.02l18.264-2.24M84.509 585.52l18.301-1.92M83.544 574.49l18.356-1.28M83.206 568.96l18.374-.96M82.964 563.43l18.386-.64M82.819 557.9l18.401-.32" transform="translate(-82.454 -234.82)"/>
<path style="stroke:#000;stroke-linecap:round;stroke-width:.89906;fill:none" d="m84.11 580 25.98-2.27" transform="translate(-82.454 -234.82)"/>
</svg>
`;
