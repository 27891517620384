import { Injectable } from '@angular/core';
import { Question } from 'questions-lib';
import { map, Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { HelpActivityDetail, HelpData, HelpNode } from '../models';

@Injectable({ providedIn: 'root' })
export class HelpService extends HttpService {

  getHtml(uId: string): Observable<HelpData> {
    return this.get<HelpData>(`help/data/${uId}`);
  }

  getTree(): Observable<HelpNode> {
    return this.get<HelpNode>('help/tree');
  }

  startActivity(activityId: number): Observable<HelpActivityDetail> {
    return this.post<HelpActivityDetail>(`help/start/${activityId}`, null);
  }

  answerQuestion(id: number, correct: boolean, answer: string, percentage: number | null): Observable<string> {
    return this.post<string>(`help/answer/${id}/`, { answer: answer, percentage: percentage, correct: correct });
  }

  getQuestion(questionId: number): Observable<Question> {

    // Ensure the question JSON is expanded for the Question type
    return this.get<any>(`tutor/questions/${questionId}`).pipe(
      map(question => Object.assign({
        questionId: question.questionId,
        introduction: question.introduction,
        workedSolution: question.workedSolution
      }, question.data))
    );
  }

}
