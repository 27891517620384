@if (helpNodeSelected && !helpActivity) {
<div class="kip-explore__content kip-explore__content--help kip-session overflow-hidden card h-100">
  @if (helpNodeSelected.pdfFile)
  {
  <kip-pdf class="h-100" soundFile="" [pdfFile]="helpNodeSelected.pdfFile" />
  }
  @else {
  <div class="h-100 mh-100 w-100 overflow-auto p-3" [innerHTML]="helpNodeSelected.safeHtml">
  </div>
  @if (helpNodeSelected.activities.length) {
  <div class="d-flex flex-column text-center text-center border-top py-3">
    @for (activity of helpNodeSelected.activities; track activity) {
    @if (!activity.percentage && activity.startedOn) {
    <div class="alert-primary rounded mb-3 p-1 mx-auto">
      {{ activity.startedOn ? 'Started: ' + (activity.startedOn | dateFromDateStruct) : ''}}
    </div>
    }
    @if (activity.percentage) {
    <div class="mx-auto mb-3 rounded p-1" [ngClass]="activity.percentage | percentageResultAlertClass">
      Completed: {{ activity.percentage | percentage }}
      @if (activity.startedOn) {
      <span>({{ activity.startedOn | dateFromDateStruct}})</span>
      }
    </div>
    }
    <button class="btn btn-lg btn-success mx-auto" type="button" [disabled]="activity.percentage"
      (click)="start(activity.id)">
      Quiz: {{ activity.name }}
    </button>
    }
  </div>
  }
  }
</div>
}

@if (helpActivity) {
<kip-help-quiz [(helpActivity)]="helpActivity" (quit)="quit.emit()" />
}