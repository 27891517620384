import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-star-silver',
  templateUrl: './star-silver.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconStarSilverComponent implements IconComponent {

}
