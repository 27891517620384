@if (option) {
@if (!option.image) {
<span class="kip-option-text" [innerHTML]="html"></span>
}
@if (option.image) {
<img class="kip-option-image" [src]="option.image | safe: 'url'">
}
@if (sound) {
<span class="kip-option-sound" (click)="playSound()">
  <fa-icon class="mt-n1" size="1x" [icon]="icons.sound.solid" />
</span>
}
}