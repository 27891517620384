import { createAction, props } from '@ngrx/store';
import { LessonEnrolment, LessonEnrolmentSessionSchedule, LessonEnrolmentStage } from 'enrolment-lib';
import { DateStruct } from 'moment-extensions-lib';
import { BillingIntervalType, LessonStatus, LessonType, Subject } from 'ui-common-lib';

import { AssessmentStatus, DIYUpdateResult } from '../../../shared';
import { Lesson, LessonOrder, LessonPlan, SessionLessonTimings, UpdatedLesson } from '../../models';

enum LessonActionTypes {
  AcknowledgeHelp = 'Tutor > Lesson > Help > Acknowledge',
  AddDIY = 'Tutor > Lesson > Add DIY',
  AddSkillBuilder = 'Tutor > Lesson > Plan > Update > Add skill builder',
  Attend = 'Tutor > Lesson > Attend',
  AttendCompleted = 'Tutor > Lesson > Attend Completed',
  Chat = 'Tutor > Lesson > Chat',
  ClearMessage = 'Tutor > Lesson > Clear Message',
  ClearHelp = 'Tutor > Lesson > Help > Clear',
  Close = 'Tutor > Lesson > Close',
  CloseActivity = 'Tutor > Lesson > Activity > Close',
  CloseLobby = 'Tutor > Lesson > Close Lobby',
  CloseCustomActivityQuestion = 'Tutor > Lesson > Custom Activity Question > Close',
  CloseQuestion = 'Tutor > Lesson > Question > Close',
  Defer = 'Tutor > Lesson > Defer',
  DeferCompleted = 'Tutor > Lesson > Defer Completed',
  Disconnect = 'Tutor > Lesson > Disconnect',
  DropInLessonCompleted = 'Tutor > Lesson > Drop In Lesson Marked Completed',
  CompleteDropInLesson = 'Tutor > Lesson > Complete Drop In Lesson',
  Follow = 'Tutor > Lesson > View Follow',
  InstallApp = 'Tutor > Lesson > Install App',
  InstallAvailable = 'Tutor > Lesson > Install Available',
  JoinLessonCompleted = 'Tutor > Join Lesson Completed',
  ForceReload = 'Tutor > Lesson > Force Reload',
  ForceReloadVideo = 'Tutor > Lesson > Force Reload Video',
  LoadEnrolment = 'Tutor > Lesson > Load Enrolment',
  LoadMany = 'Tutor > Lesson > Load Many',
  Load = 'Tutor > Lesson > Load',
  Open = 'Tutor > Lesson > Open',
  OpenActivity = 'Tutor > Lesson > Activity > Open',
  OpenLobby = 'Tutor > Lesson > Open Lobby',
  OpenCustomActivityQuestion = 'Tutor > Lesson > Custom Activity Question > Open',
  OpenQuestion = 'Tutor > Lesson > Question > Open',
  PullFromLobby = 'Tutor > Lesson > Pull From Lobby',
  RequestHelp = 'Tutor > Lesson > Help > Request',
  ResetPassword = 'Tutor > Lesson > Reset Password',
  ResetPasswordComplete = 'Tutor > Lesson > Reset Password Complete',
  RestartTeaching = 'Tutor > Lesson > Restart Teaching',
  SkillBuilderRequested = 'Tutor > Lesson > Plan > Update > Request skill builder',
  StartedInteracting = 'Tutor > Lesson > Started Interacting',
  StartedLookingTab = 'Tutor > Lesson > Started Looking Tab',
  StartObserving = 'Tutor > Lesson > Start Observing',
  StartTeaching = 'Tutor > Lesson > Start Teaching',
  StopObserving = 'Tutor > Lesson > Stop Observing',
  StoppedInteracting = 'Tutor > Lesson > Stopped Interacting',
  StoppedLookingTab = 'Tutor > Lesson > Stopped Looking Tab',
  StopTeaching = 'Tutor > Lesson > Stop Teaching',
  StudentImageData = 'Student > Lesson > Image Data',
  StudentPhotoLoad = 'Tutor > Lesson > Student Photo Load',
  StudentPhotoUpdate = 'Tutor > Lesson > Student Photo Update',
  StudentPublishDenied = 'Tutor > Lesson > Publish > Denied',
  StudentPublishFailure = 'Tutor > Lesson > Publish > Failure',
  StudentPublishStreaming = 'Tutor > Lesson > Publish > Streaming',
  StudentStreamConnected = 'Tutor > Lesson > Student Stream Connected',
  SubscribeFailure = 'Tutor > Lesson > Subscribe Failure',
  SystemChat = 'Tutor > Lesson > System Chat',
  ToggleAudio = 'Tutor > Lesson > Toggle Audio',
  ToggleOnline = 'Tutor > Lesson > Toggle Online',
  ToggleVideo = 'Tutor > Lesson > Toggle Video',
  Update = 'Tutor > Lesson > Update',
  ToggleLessonDoItYourself = 'Tutor > Lesson > Toggle Lesson Do It Yourself',
  UpdateLessonDoItYourself = 'Tutor > Lesson > Update Lesson Do It Yourself',
  UpdateAssessment = 'Tutor > Lesson > Update Assessment',
  UpdateAssessmentStatus = 'Tutor > Lesson > Update Assessment Status',
  UpdateEnrolmentAccountCreated = 'Tutor > Lesson > Update > Enrolment Account Created',
  UpdateEnrolmentBundle = 'Tutor > Lesson > Update > Enrolment Bundle',
  UpdateEnrolmentBundleCurrency = 'Tutor > Lesson > Update > Enrolment Bundle Currency',
  UpdateEnrolmentBundleType = 'Tutor > Lesson > Update > Enrolment Bundle Type',
  UpdateEnrolmentCustomerRepresents = 'Tutor > Lesson > Update > Enrolment Customer Represents',
  UpdateEnrolmentPaymentEntry = 'Tutor > Lesson > Update > Enrolment Payment Entry',
  UpdateEnrolmentPaymentOptions = 'Tutor > Lesson > Update > Enrolment Payment Options',
  UpdateEnrolmentProgress = 'Tutor > Lesson > Update > Enrolment Progress',
  UpdateEnrolmentSessions = 'Tutor > Lesson > Update > Enrolment Sessions',
  UpdateEnrolmentSubjects = 'Tutor > Lesson > Update > Enrolment Subjects',
  UpdateHasSkillbuilders = 'Tutor > Lesson > Update Has Skillbuilders',
  UpdateFinishLessonNote = 'Tutor > Lesson > Update Finish Lesson Note',
  UpdateLessonOrder = 'Tutor > Lesson > Order > Update',
  UpdateNotes = 'Tutor > Lesson > Update Notes',
  UpdateNotesCancelled = 'Tutor > Lesson > Update Notes Cancelled',
  UpdatePinned = 'Tutor > Lesson > Pinned > Update',
  UpdatePlan = 'Tutor > Lesson > Plan > Update',
  UpdateStartLessonNote = 'Tutor > Lesson > Update Start Lesson Note',
  UpdateStudent = 'Tutor > Lesson > Student > Update',
  UpdateTimings = 'Tutor > Lesson > Timings > Update',
  ViewActivity = 'Tutor > Lesson > View Activity',
  ViewCustomActivityQuestion = 'Tutor > Lesson > View CustomActivityQuestion',
  ViewQuestion = 'Tutor > Lesson > View Question'
}

export const updateHasSkillbuildersAction = createAction(
  LessonActionTypes.UpdateHasSkillbuilders,
  props<{
    readonly lessonGuid: string;
    readonly hasSkillbuilders: boolean;
  }>()
);

export const updateStartLessonNoteAction = createAction(
  LessonActionTypes.UpdateStartLessonNote,
  props<{
    readonly lessonGuid: string;
    readonly note: string;
    readonly noteRanking: number | null;
  }>()
);

export const updateFinishLessonNoteAction = createAction(
  LessonActionTypes.UpdateFinishLessonNote,
  props<{
    readonly lessonGuid: string;
    readonly note: string;
    readonly noteRanking: number | null;
  }>()
);

export const clearMessageAction = createAction(
  LessonActionTypes.ClearMessage,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const addDIYAction = createAction(
  LessonActionTypes.AddDIY,
  props<{
    readonly lessonId: number;
    readonly isHomework: boolean;
  }>()
);

export const loadAction = createAction(
  LessonActionTypes.Load,
  props<{
    readonly sessionLesson: Lesson;
  }>()
);

export const loadManyAction = createAction(
  LessonActionTypes.LoadMany,
  props<{
    readonly sessionLessons: readonly Lesson[];
  }>()
);

export const installAppAction = createAction(
  LessonActionTypes.InstallApp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const installAvailableAction = createAction(
  LessonActionTypes.InstallAvailable,
  props<{
    readonly lessonGuid: string;
    readonly available: boolean;
  }>()
);

export const loadEnrolmentAction = createAction(
  LessonActionTypes.LoadEnrolment,
  props<{
    readonly lessonGuid: string;
    readonly lessonEnrolment: LessonEnrolment;
  }>()
);

export const toggleLessonDoItYourselfAction = createAction(
  LessonActionTypes.ToggleLessonDoItYourself,
  props<{
    readonly lessonGuid: string;
    readonly doItYourself: boolean;
  }>()
);

export const updateLessonDoItYourselfAction = createAction(
  LessonActionTypes.UpdateLessonDoItYourself,
  props<{
    readonly lessonGuid: string;
    readonly isOnline: boolean;
    readonly lessonType: LessonType;
    readonly lessonStatus: LessonStatus;
    readonly updateResult: DIYUpdateResult;
    readonly updateFromParent: boolean;
    readonly lessonId: number;
  }>()
);

export const updateEnrolmentProgressAction = createAction(
  LessonActionTypes.UpdateEnrolmentProgress,
  props<{
    readonly lessonGuid: string;
    readonly enrolmentStage: LessonEnrolmentStage;
  }>()
);

export const updateEnrolmentPaymentOptionsAction = createAction(
  LessonActionTypes.UpdateEnrolmentPaymentOptions,
  props<{
    readonly lessonGuid: string;
    readonly debitDay: number;
    readonly startDate: DateStruct;
    readonly upfrontPaymentOption: number;
  }>()
);

export const updateEnrolmentPaymentEntryAction = createAction(
  LessonActionTypes.UpdateEnrolmentPaymentEntry,
  props<{
    readonly lessonGuid: string;
    readonly assessorEnteringPayment: boolean;
  }>()
);

export const updateEnrolmentSubjectsAction = createAction(
  LessonActionTypes.UpdateEnrolmentSubjects,
  props<{
    readonly lessonGuid: string;
    readonly subjects: Subject[];
  }>()
);

export const updateEnrolmentSessionsAction = createAction(
  LessonActionTypes.UpdateEnrolmentSessions,
  props<{
    readonly lessonGuid: string;
    readonly sessions: LessonEnrolmentSessionSchedule[];
  }>()
);

export const updateEnrolmentBundleTypeAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundleType,
  props<{
    readonly lessonGuid: string;
    readonly bundleOfferTypeId: number;
  }>()
);

export const joinLessonCompletedAction = createAction(
  LessonActionTypes.JoinLessonCompleted,
  props<{
    readonly lessonGuid: string;
    readonly connectionId: string;
  }>()
);

export const updateEnrolmentBundleCurrencyAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundleCurrency,
  props<{
    readonly lessonGuid: string;
    readonly bundleCurrency: string;
  }>()
);

export const updateEnrolmentBundleAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundle,
  props<{
    readonly lessonGuid: string;
    readonly bundleOfferId: number;
    readonly billingInterval: BillingIntervalType;
  }>()
);

export const updateEnrolmentCustomerRepresentsAction = createAction(
  LessonActionTypes.UpdateEnrolmentCustomerRepresents,
  props<{
    readonly lessonGuid: string;
    readonly customerRepresentsOrganisation: boolean;
  }>()
);

export const resetPasswordAction = createAction(
  LessonActionTypes.ResetPassword,
  props<{
    readonly lessonGuid: string;
    readonly password: string | null;
  }>()
);

export const resetPasswordCompleteAction = createAction(
  LessonActionTypes.ResetPasswordComplete,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateEnrolmentAccountCreatedAction = createAction(
  LessonActionTypes.UpdateEnrolmentAccountCreated,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateAction = createAction(
  LessonActionTypes.Update,
  props<{
    readonly sessionLesson: Lesson;
  }>()
);

export const updateAssessmentStatusAction = createAction(
  LessonActionTypes.UpdateAssessmentStatus,
  props<{
    readonly lessonGuid: string;
    readonly assessmentStatus: AssessmentStatus;
  }>()
);

export const updateStudentAction = createAction(
  LessonActionTypes.UpdateStudent,
  props<{
    readonly lessonId: number;
    readonly givenName: string;
    readonly familyName: string;
    readonly dateOfBirth: DateStruct;
    readonly gradeId: number | undefined;
    readonly regionId: number | undefined;
    readonly levelTitle: string;
  }>()
);

export const followAction = createAction(
  LessonActionTypes.Follow,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateAssessmentAction = createAction(
  LessonActionTypes.UpdateAssessment,
  props<{
    readonly lessonGuid: string;
    readonly assessmentId: number;
  }>()
);

export const studentImageDataAction = createAction(
  LessonActionTypes.StudentImageData,
  props<{
    readonly lessonGuid: string;
    readonly imageData: string;
  }>()
);

export const updateNotesAction = createAction(
  LessonActionTypes.UpdateNotes,
  props<{
    readonly lessonGuid: string;
    readonly internalNote: string;
    readonly focusNote: string;
    readonly aiNote: string;
    readonly parentNote: string;
    readonly internalNoteRanking: number | null;
    readonly parentNoteRanking: number | null;
    readonly parentNoteApproved: boolean;
  }>()
);

export const updateNotesCancelledAction = createAction(
  LessonActionTypes.UpdateNotesCancelled,
  props<{
    readonly lessonGuid: string;
    readonly aiNote: string;
    readonly parentNote: string;
    readonly parentNoteApproved: boolean;
  }>()
);

export const viewActivityAction = createAction(
  LessonActionTypes.ViewActivity,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
  }>()
);

export const viewQuestionAction = createAction(
  LessonActionTypes.ViewQuestion,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly questionGuid: string;
  }>()
);

export const startedLookingTabAction = createAction(
  LessonActionTypes.StartedLookingTab,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const stoppedLookingTabAction = createAction(
  LessonActionTypes.StoppedLookingTab,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const startedInteractingAction = createAction(
  LessonActionTypes.StartedInteracting,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const stoppedInteractingAction = createAction(
  LessonActionTypes.StoppedInteracting,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openLobbyAction = createAction(
  LessonActionTypes.OpenLobby,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const closeLobbyAction = createAction(
  LessonActionTypes.CloseLobby,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openAction = createAction(
  LessonActionTypes.Open,
  props<{
    readonly sessionLesson: Lesson;
  }>()
);

export const closeAction = createAction(
  LessonActionTypes.Close,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateTimingsAction = createAction(
  LessonActionTypes.UpdateTimings,
  props<{
    readonly lessonGuid: string;
    readonly timings: SessionLessonTimings;
  }>()
);

export const disconnectAction = createAction(
  LessonActionTypes.Disconnect,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const studentPublishFailureAction = createAction(
  LessonActionTypes.StudentPublishFailure,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const studentPhotoLoadAction = createAction(
  LessonActionTypes.StudentPhotoLoad,
  props<{
    readonly lessonGuid: string;
    readonly studentId: number;
  }>()
);

export const studentPhotoUpdateAction = createAction(
  LessonActionTypes.StudentPhotoUpdate,
  props<{
    readonly lessonGuid: string;
    readonly studentPhotoUrl: string;
    readonly studentPhotoDefault: boolean;
  }>()
);

export const studentPublishDeniedAction = createAction(
  LessonActionTypes.StudentPublishDenied,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const studentPublishStreamingAction = createAction(
  LessonActionTypes.StudentPublishStreaming,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const chatAction = createAction(
  LessonActionTypes.Chat,
  props<{
    readonly lessonGuid: string;
    readonly message: string;
    readonly chatGroup: string;
    readonly warning: boolean;
  }>()
);

export const systemChatAction = createAction(
  LessonActionTypes.SystemChat,
  props<{
    readonly lessonGuid: string;
    readonly message: string;
    readonly chatGroup: string;
  }>()
);

export const updatePinnedAction = createAction(
  LessonActionTypes.UpdatePinned,
  props<{
    readonly lessonGuid: string;
    readonly lessonId: number;
    readonly isPinned: boolean;
  }>()
);

export const updateLessonOrderAction = createAction(
  LessonActionTypes.UpdateLessonOrder,
  props<{
    readonly lessonOrder: LessonOrder[];
  }>()
);

export const toggleOnlineAction = createAction(
  LessonActionTypes.ToggleOnline,
  props<{
    readonly lessonGuid: string;
    readonly isOnline: boolean;
  }>()
);

export const forceReloadAction = createAction(
  LessonActionTypes.ForceReload,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const forceReloadVideoAction = createAction(
  LessonActionTypes.ForceReloadVideo,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const pullFromLobbyAction = createAction(
  LessonActionTypes.PullFromLobby,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const toggleVideoAction = createAction(
  LessonActionTypes.ToggleVideo,
  props<{
    readonly lessonGuid: string;
    readonly publish: boolean;
  }>()
);

export const subscribeFailureAction = createAction(
  LessonActionTypes.SubscribeFailure,
  props<{
    readonly lessonGuid: string;
    readonly name: string;
    readonly message: string;
  }>()
);

export const toggleAudioAction = createAction(
  LessonActionTypes.ToggleAudio,
  props<{
    readonly lessonGuid: string;
    readonly publish: boolean;
  }>()
);

export const startTeachingAction = createAction(
  LessonActionTypes.StartTeaching,
  props<{
    readonly lessonId: number;
  }>()
);

export const stopTeachingAction = createAction(
  LessonActionTypes.StopTeaching,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const startObservingAction = createAction(
  LessonActionTypes.StartObserving,
  props<{
    readonly lessonId: number;
  }>()
);

export const stopObservingAction = createAction(
  LessonActionTypes.StopObserving,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const requestHelpAction = createAction(
  LessonActionTypes.RequestHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const acknowledgeHelpAction = createAction(
  LessonActionTypes.AcknowledgeHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const clearHelpAction = createAction(
  LessonActionTypes.ClearHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openActivityAction = createAction(
  LessonActionTypes.OpenActivity,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
  }>()
);

export const closeActivityAction = createAction(
  LessonActionTypes.CloseActivity,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
  }>()
);

export const openQuestionAction = createAction(
  LessonActionTypes.OpenQuestion,
  props<{
    readonly questionGuid: string;
    readonly lessonGuid: string;
  }>()
);

export const closeQuestionAction = createAction(
  LessonActionTypes.CloseQuestion,
  props<{
    readonly questionGuid: string;
    readonly lessonGuid: string;
  }>()
);

export const updatePlanAction = createAction(
  LessonActionTypes.UpdatePlan,
  props<{
    readonly lesson: UpdatedLesson;
  }>()
);

export const studentStreamConnected = createAction(
  LessonActionTypes.StudentStreamConnected,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const restartTeaching = createAction(
  LessonActionTypes.RestartTeaching
);

export const skillBuilderRequestedAction = createAction(
  LessonActionTypes.SkillBuilderRequested,
  props<{
    readonly lessonId: number;
    readonly skillbuilderActivityId: number;
  }>()
);

export const addSkillBuilderAction = createAction(
  LessonActionTypes.AddSkillBuilder,
  props<{
    readonly lessonPlanId: number;
    readonly lessonPlan: LessonPlan;
  }>()
);

export const deferLessonAction = createAction(
  LessonActionTypes.Defer,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const deferLessonCompletedAction = createAction(
  LessonActionTypes.DeferCompleted,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const attendLessonAction = createAction(
  LessonActionTypes.Attend,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const attendLessonCompletedAction = createAction(
  LessonActionTypes.AttendCompleted,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const completeDropInLessonAction = createAction(
  LessonActionTypes.CompleteDropInLesson,
  props<{
    readonly lessonId: number;
    readonly studentId: number;
  }>()
);

export const dropInLessonCompletedAction = createAction(
  LessonActionTypes.DropInLessonCompleted,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openCustomActivityQuestion = createAction(
  LessonActionTypes.OpenCustomActivityQuestion,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly pageGuid: string;
  }>()
);

export const closeCustomActivityQuestion = createAction(
  LessonActionTypes.CloseCustomActivityQuestion,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const viewCustomActivityQuestion = createAction(
  LessonActionTypes.ViewCustomActivityQuestion,
  props<{
    readonly lessonGuid: string;
    readonly pageGuid: string;
  }>()
);
