<ng-template #saveWarning let-modal>
  <div class="modal-header">
    <span class="kip-modal-title">{{ unsavedChanges }}</span>
  </div>
  <div class="modal-body">
    @if (warningTitle) {
    <span class="fw-bold"> {{ warningTitle }}<br />
    </span>
    }
    {{ warningText }}
    <hr class="mt-4" />
    <div class="d-flex flex-row align-items-center justify-content-between mx-auto">
      <div class="d-flex">
        <button class="btn btn-primary me-2" type="button" (click)="modal.close('save')">{{ save }}</button>
        <button class="btn btn-link" type="button" (click)="modal.close('cancel')">{{ cancel }}</button>
      </div>
      <button class="btn btn-danger me-2" type="button" (click)="modal.close('discard')"> {{ discard }}</button>
    </div>
  </div>
</ng-template>