import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-christmas-tree',
  templateUrl: './christmas-tree.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconChristmasTreeComponent implements IconComponent {

}
