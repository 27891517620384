import { ChangeDetectionStrategy, Component, effect, inject, input, model, output, TemplateRef, viewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { ActivityNote, AIResponse, LessonNote, NotesForLesson } from '../models';

@Component({
  selector: 'kip-lesson-notes',
  templateUrl: './lesson-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonNotesComponent {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;
  readonly allowInternalRanking = input.required<boolean | undefined>();
  readonly allowParentRanking = input.required<boolean | undefined>();
  readonly note = model.required<LessonNote | undefined>();
  readonly activityNotes = input<ActivityNote[] | undefined>(undefined);

  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly noteSaved = output<NotesForLesson>();
  readonly noteCancelledWithParentNotApproved = output<AIResponse>();

  constructor() {
    effect(() => {
      const note = this.note();
      const modalContent = this.modalContent();

      if (note && modalContent) {
        this.#modalService.open(modalContent, { size: 'xl', centered: true });
      } else {
        this.#modalService.dismissAll();
      }
    });
  }

  updateNote(note: NotesForLesson) {
    this.noteSaved.emit(note);
    this.note.set(undefined);
  }

  closeModal(response: AIResponse | undefined) {
    this.#modalService.dismissAll();
    if (response) {
      this.noteCancelledWithParentNotApproved.emit(response);
    }
  }
}
