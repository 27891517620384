import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PointsData } from '../points-data';

@Component({
  selector: 'kip-points-graphic',
  templateUrl: './points-graphic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointsGraphicComponent {
  @Input({ required: true }) pointsData: PointsData | undefined;

  @Input({ required: true }) students: number | undefined;
}
