import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { FormDataType, PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
import { Subscription } from 'rxjs';

import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ManualActivityRequest, unknownPdf } from '../models';
import { PdfService } from '../services';

@Component({
  selector: 'kip-pdf',
  templateUrl: './pdf.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfComponent implements OnDestroy {

  readonly #pdfService = inject(PdfService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #soundFile = '';
  #pdfFile = '';
  #manualActivityRequest: ManualActivityRequest = { activityId: 0, lessonId: 0 };
  #soundAvailable = false;
  #pdfAvailable = false;
  #pages = 1;
  #page = 1;
  #title: string | undefined;
  #fileType: string | undefined;
  #buttonText: string | undefined;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  pdfSrc: Blob | string = '';

  get page() {
    return this.#page;
  }

  get pages() {
    return this.#pages;
  }

  get pdfAvailable() {
    return this.#pdfAvailable;
  }

  get soundAvailable() {
    return this.#soundAvailable;
  }

  get title() {
    return this.#title;
  }

  get buttonText() {
    return this.#buttonText;
  }

  get message() {
    let message = '';
    if (this.#fileType) {
      message = `A ${this.#fileType} file is missing from this activity. The curriculum team have been notified of this error.`;
    }
    return message;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set pdfFile(value: string) {
    this.#pdfAvailable = false;
    if (this.#pdfFile !== value) {
      this.#pdfFile = value;
      if (value !== '' && value !== null && value !== unknownPdf) {
        this.#subscriptions.push(
          this.#pdfService.getPdfFile(value).subscribe({
            next: response => {
              this.pdfSrc = response.body === null ? '' : response.body;
              this.#pdfAvailable = true;

              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              const fileType = 'PDF';
              this.#openErrorModal(fileType);
              this.#changeDetectorRef.markForCheck();
            }
          }));
      }
    }
  }

  get pdfFile() {
    return this.#pdfFile;
  }

  @Input({ required: true })
  set soundFile(value: string) {
    this.#soundAvailable = false;
    if (this.#soundFile !== value) {
      this.#soundFile = value;
      if (value !== '' && value !== null) {
        this.#pdfService.getSoundFile(value).then(response => {
          this.#soundAvailable = true;
          if (this.audio) {
            this.audio.nativeElement.src = response;
          }
          this.#changeDetectorRef.markForCheck();
        });
      } else {
        this.#soundAvailable = false;
      }
    }
  }

  get soundFile() {
    return this.#soundFile;
  }

  @Input()
  set manualActivityRequest(value: ManualActivityRequest) {
    if (value !== undefined && (this.#manualActivityRequest.activityId !== value.activityId ||
      this.#manualActivityRequest.lessonId !== value.lessonId ||
      this.#manualActivityRequest.parentLessonId !== value.parentLessonId)) {
      this.#manualActivityRequest = value;
      this.#subscriptions.push(
        this.#pdfService.getManualActivity(value).subscribe(result => {
          this.activityName = result.name;
          this.pdfFile = result.pdfFile;
          this.soundFile = result.soundFile;
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  get manualActivityRequest() {
    return this.#manualActivityRequest;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('audio', { static: true }) audio: ElementRef<HTMLAudioElement> | undefined;
  @ViewChild('errorModal') errorModal: ErrorModalComponent | undefined;

  @Input() allowJira = false;
  @Input() allowToggleAnswers = false;
  @Input() activityName = '';
  @Input() activityDescription = '';
  @Input() activityHtml = '';
  @Input() formData: FormDataType = {};
  @Input() enablePrint = true;

  @Output() readonly formDataChange = new EventEmitter<FormDataType>();
  @Output() readonly displayJira = new EventEmitter();
  @Output() readonly toggleAnswers = new EventEmitter();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onPageChange(page: number) {
    this.#page = page;
  }

  onPagesLoaded(event: PagesLoadedEvent) {
    this.#pages = event.pagesCount;
  }

  #openErrorModal(fileType: string) {
    this.#title = `${fileType} File Missing`;
    this.#fileType = fileType;
    this.#buttonText = 'Back to Activity List';
    this.errorModal?.open();
  }
}
