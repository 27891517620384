/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, last, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { LessonActivityType } from '../../../shared';
import { LessonPlan, SessionLesson } from '../../models';
import { LessonPlanService } from '../../services';
import * as fromLesson from '../lesson';
import { getEventsRequest } from '../log';
import * as fromPlan from '../plan';
import { TutorState } from '../state';

function mergeLessonPlanAndStates(lessonPlanId: number, lessonPlan: LessonPlan, states: SessionLesson[]) {
  const lessonState = states.find(current => current.lessonPlanId === lessonPlanId);

  const lessonPlanOut: LessonPlan = {
    id: lessonPlan.id,
    userId: lessonPlan.userId,
    subjectId: lessonPlan.subjectId,
    tutorId: lessonPlan.tutorId,
    studentId: lessonPlan.studentId,
    accepted: lessonPlan.accepted,
    allowBulkLessonPlanning: lessonPlan.allowBulkLessonPlanning,
    isDIYCompatible: lessonPlan.isDIYCompatible,
    isDIYGenerated: lessonPlan.isDIYGenerated,
    isDropInLessonCompleted: lessonPlan.isDropInLessonCompleted,
    lessonGeneratedFromDateOffsetTimeZone: lessonPlan.lessonGeneratedFromDateOffsetTimeZone,
    hasAssessmentActivities: lessonPlan.hasAssessmentActivities,
    canRegenerate: lessonPlan.canRegenerate,
    subjectName: lessonPlan.subjectName,
    studentName: lessonPlan.studentName,
    grade: lessonPlan.grade,
    dateOfBirth: lessonPlan.dateOfBirth,
    regionId: lessonPlan.regionId,
    treeSubjectIds: lessonPlan.treeSubjectIds,
    maximumAllowedHomeworkActivities: lessonPlan.maximumAllowedHomeworkActivities,
    maximumAllowedLessonActivities: lessonPlan.maximumAllowedLessonActivities,
    activities: lessonPlan.activities.map(s => Object.assign({}, s)),
    homework: lessonPlan.homework.map(s => Object.assign({}, s))
  };

  let changed = false;

  if (lessonState) {
    for (const activity of lessonPlanOut.activities) {
      const result = lessonState.activities.find(a => a.activityId === activity.activityId);

      if (result) {

        let completionTime = 0;
        for (const q of result.questions) {
          completionTime = completionTime + q.duration;
        }

        const newValues = {
          correct: result.lessonActivityType === LessonActivityType.Manual ? result.manualScoring.correct : result.questions.filter(q => q.correct && q.attempts.length === 1).length,
          attempted: result.questions.filter(q => q.attempts.length > 0 || q.skipped).length,
          total: result.lessonActivityType === LessonActivityType.Manual ? result.manualScoring.total : result.questions.length,
          completionTime: completionTime,
          percentage: result.percentage,
          isSimpleScoring: result.manualScoring.isSimpleScoring,
          simpleScore: result.manualScoring.simpleScore,
          inProgress: result.manualScoring.inProgress,
          noScoringRequired: result.manualScoring.noScoringRequired
        };

        if (activity.correct !== newValues.correct ||
          activity.attempted !== newValues.attempted ||
          activity.total !== newValues.total ||
          activity.completionTime !== newValues.completionTime ||
          activity.percentage !== result.percentage) {

          changed = true;
          activity.startedOn = new Date(); // TODO: Hack to get around the fact that date is unknown
          activity.correct = newValues.correct;
          activity.attempted = newValues.attempted;
          activity.total = newValues.total;
          activity.isSimpleScoring = newValues.isSimpleScoring;
          activity.simpleScore = newValues.simpleScore;
          activity.inProgress = newValues.inProgress;
          activity.noScoringRequired = newValues.noScoringRequired;
          activity.completionTime = newValues.completionTime;
          activity.percentage = result.percentage;
        }
      }
    }
  }

  return { newValue: lessonPlanOut, changed: changed };
}

@Injectable()
export class PlanEffects {

  readonly #store = inject(Store<TutorState>);
  readonly #actions$ = inject(Actions);
  readonly #lessonPlanService = inject(LessonPlanService);

  accept$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromPlan.acceptAction),
      concatLatestFrom(
        () => this.#store.select(fromPlan.selectLessonPlans)
      ),
      switchMap(([action, lessonPlans]) => {
        const lessonPlanDirty = lessonPlans[action.lessonPlanId];
        if (lessonPlanDirty) {
          this.#lessonPlanService.acceptLessonPlan(action.lessonPlanId, lessonPlanDirty.lessonPlan, action.notifyChanges, getEventsRequest()).subscribe(response => {
            this.#store.dispatch(
              fromPlan.acceptedAction({ lessonPlanId: action.lessonPlanId, isDIYCompatible: response.isDIYCompatible, hasAssessmentActivities: response.hasAssessmentActivities })
            );
          });
        }

        return EMPTY;
      }));
  }, { dispatch: false });

  copyAll$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromPlan.copyAllAction),
      concatLatestFrom(
        () => this.#store.select(fromPlan.selectLessonPlans)
      ),
      switchMap(([action, lessonPlans]) => {
        const sourceLessonPlanDirty = lessonPlans[action.sourceLessonPlanId];

        from(action.destinationLessonPlanIds)
          .pipe(
            mergeMap(destinationLessonPlanId =>
              this.#lessonPlanService.acceptLessonPlan(destinationLessonPlanId, sourceLessonPlanDirty.lessonPlan, action.notifyChanges, getEventsRequest(), true).pipe(
                mergeMap(() => this.#lessonPlanService.getLessonPlan(destinationLessonPlanId)),
                concatLatestFrom(
                  () => this.#store.select(fromLesson.selectSessionLessons)
                ),
                mergeMap(([lessonPlan, states]) => [fromPlan.loadAction(
                  {
                    lessonPlanId: destinationLessonPlanId,
                    lessonPlan: mergeLessonPlanAndStates(destinationLessonPlanId, lessonPlan, states).newValue
                  })])
              )
            ),
            last()
          ).subscribe(() => {
            this.#store.dispatch(
              fromPlan.copyAllCompletedAction({
                lessonPlanIds: action.destinationLessonPlanIds
              }));
          });

        return EMPTY;
      })
    );
  }, { dispatch: false });

  update$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromPlan.updateAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSessionLessons),
        this.#store.select(fromPlan.selectLessonPlans)
      ),
      switchMap(([action, states, lessonPlans]) => {
        const lessonPlanDirty = lessonPlans[action.lessonPlanId];
        if (lessonPlanDirty) {
          const result = mergeLessonPlanAndStates(action.lessonPlanId, lessonPlanDirty.lessonPlan, states ?? []);
          if (result.changed) {
            return [fromPlan.updateSkillbuildersAction({ lessonPlanId: action.lessonPlanId, lessonPlan: result.newValue })];
          }
        }
        return EMPTY;
      })
    );
  });

  get$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromPlan.getAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSessionLessons)
      ),
      switchMap(([action, states]) =>
        this.#lessonPlanService
          .getLessonPlan(action.lessonPlanId)
          .pipe(
            switchMap(lessonPlan => [fromPlan.loadAction(
              {
                lessonPlanId: action.lessonPlanId,
                lessonPlan: mergeLessonPlanAndStates(action.lessonPlanId, lessonPlan, states).newValue
              })]),
            catchError(() => [fromPlan.errorAction({ lessonPlanId: action.lessonPlanId, error: 'Error getting plan' })])
          )
      )
    );
  });
}
