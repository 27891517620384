export class CustomActivityQuestionIndexFormatter {

  static getQuestionIndexFromPageGuid(pageGuid: string): number | undefined {
    let questionIndex: number | undefined = undefined;

    if (pageGuid) {
      try {
        const indexString = pageGuid.slice(pageGuid.length - 2);
        questionIndex = Number.parseInt(indexString, 10);
      }
      catch {
        // failed to parse int, fall back to default
        console.warn('Custom activity question index unable to be parsed from page guid');
      }
    }
    return questionIndex;
  }

}
