import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  Centre, DayOfWeek, daysOfWeek, FilterValue, PagedList, PageInfo, PageListSize as PageSize, PageRequest, SessionScheduleFilter,
  SessionScheduleSummary, SortBy, SortColumnKey, toName,
  ViewTypes
} from '../models';
import { SharedService } from '../services';

@Component({
  selector: 'kip-multi-view-session-picker',
  templateUrl: './session-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionPickerComponent implements OnInit, OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #centres: Centre[] = [];
  #tutors: FilterValue<number>[] = [];

  readonly sortColumnKey = SortColumnKey;
  readonly icons = Icons;
  readonly viewTypes = ViewTypes;
  readonly resultsPerPage = PageSize.Five;
  readonly daysOfWeek = daysOfWeek;

  currentLoad: Observable<PageInfo> = new Observable<PageInfo>();
  sessionSchedules: PagedList<SessionScheduleSummary> | undefined;

  sortBy: SortBy | undefined;

  filter: SessionScheduleFilter = {
    centreId: null,
    weekday: null,
    tutorId: null
  };

  get centres() {
    return this.#centres;
  }

  get tutors() {
    return this.#tutors;
  }

  @Input({ required: true }) selectedSessionSchedule: SessionScheduleSummary | undefined;
  @Input({ required: true }) studentName = '';
  @Input() viewType: ViewTypes = ViewTypes.List;
  @Input() pageRequest: PageRequest<SessionScheduleFilter> | undefined;
  @Input() sessionSelectTitle = 'Session Select';

  @Output() readonly selectSessionSchedule = new EventEmitter<SessionScheduleSummary>();
  @Output() readonly changePageRequest = new EventEmitter<PageRequest<SessionScheduleFilter>>();
  @Output() readonly changeViewType = new EventEmitter<ViewTypes>();

  ngOnInit() {
    if (!this.pageRequest) {
      this.pageRequest = {
        pageNumber: 1,
        pageSize: this.resultsPerPage
      };
    }
    this.loadPage(this.pageRequest);

    this.#subscriptions.push(
      this.#sharedService.getCentres().subscribe(values => {
        this.#centres = values;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  // This is very specific to enrolment add component
  // we need to ensure that state is returned back to the parent
  // so that shared service state can be updated.
  ngOnDestroy() {
    this.changePageRequest.emit(this.pageRequest);
    this.changeViewType.emit(this.viewType);
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  loadPage(pageToFetch: PageRequest<SessionScheduleFilter>) {
    this.currentLoad = this.#sharedService.querySessions(pageToFetch)
      .pipe(
        tap(sessionSchedules => {
          this.sessionSchedules = sessionSchedules;
          this.pageRequest = pageToFetch;
          this.#changeDetectorRef.markForCheck();
        }),
        map(sessionSchedules => sessionSchedules.pageInfo)
      );
  }

  viewTypeChanged(viewType: ViewTypes) {
    this.viewType = viewType;
  }

  updateTutorFilter(value: number | null) {
    this.filter = { ... this.filter, tutorId: value };
  }

  updateCentreFilter(value: number | null) {
    this.filter = { ... this.filter, centreId: value, tutorId: null };

    if (value) {
      this.#subscriptions.push(
        this.#sharedService.getTutors(value).subscribe(values => {
          this.#tutors = values.map(t => {
            return { id: t.id, title: toName(t) } as FilterValue<number>;
          });
          this.#changeDetectorRef.markForCheck();
        }));
    } else {
      this.#tutors = [];
    }
  }

  updateWeekDayFilter(value: DayOfWeek | null) {
    this.filter = { ... this.filter, weekday: value };
  }
}
