@switch (jiraStyleType) {
@case (jiraStyleTypeReport) {
<button class="btn btn-link btn--icon" type="button" (click)="open()">
  <fa-icon class="text-primary" [icon]="icons.reportJira.light" />
</button>
}
@case (jiraStyleTypeContent) {
<button class="btn btn-link btn--icon" type="button" (click)="open()">
  <fa-icon [icon]="icons.reportJira.light" />
</button>
}
@case (jiraStyleTypeDropdownItem) {
<button class="btn kip-dropdown__entry" type="button" (click)="open()">
  <fa-icon class="pe-2" [fixedWidth]="true" [icon]="icons.reportJira.regular" />Report Issue
</button>
}
}