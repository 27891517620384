import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Theme } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-theme',
  templateUrl: './form-control-theme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlThemeComponent extends BaseFormControlComponent {

  readonly control = input.required<FormControl<Theme>>();
  readonly themeValues: { value: Theme, label: string }[] = [
    { value: Theme.Default, label: 'Default' },
    { value: Theme.ElectricStorm, label: 'Electric Storm' },
    { value: Theme.LabGreen, label: 'Lab Green' },
    { value: Theme.Sunset, label: 'Sunset' }];
  readonly disabled = input(false);

}
