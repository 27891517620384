<div class="kip-fill-in-the-blank-layout">
  <kip-question-text class="text-center" [question]="question" />
  <div #container
    [class]="'kip-container ' + ((question === undefined ? undefined : question.textAlignment) | textAlignment)">

    <div #sourceContainer class="kip-source-container justify-content-center" [style.display]="readonly?'none':''">
      @for (sourceOption of sourceOptions; track sourceOption; let i = $index) {
      <div #source class="px-2 kip-source kip-bring-to-front text-nowrap"
        [class.kip-source-item]="sourceMatched(sourceOption)">
        {{sourceOption.text}}
      </div>
      }
    </div>

    <div class="kip-spacer" [style.display]="readonly?'none':''"></div>

    <div #targetContainer class="kip-target-container justify-content-center">
      <p [innerHTML]="targetHTML"></p>
    </div>

    @if (draggingElement) {
    <div #mirror class="kip-mirror-item" [style.left.px]="currentX" [style.top.px]="currentY">
      {{draggingElement}}
    </div>
    }
  </div>
</div>