import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { Subscription } from 'rxjs';
import { StudentService, StudentWeakness, Weakness } from 'student-common-lib';

@Component({
  selector: 'kip-weakness-edit',
  templateUrl: './weakness-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeaknessEditComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  weaknessForm: FormGroupTypeSafe<Weakness>;

  get isNew() {
    return this.id.value === 0;
  }

  get id() {
    return this.weaknessForm.getSafe(x => x.id);
  }

  get description() {
    return this.weaknessForm.getSafe(x => x.description);
  }

  get reviewDate() {
    return this.weaknessForm.getSafe(x => x.reviewDate);
  }

  get completed() {
    return this.weaknessForm.getSafe(x => x.completed);
  }

  get action(): string {
    return !this.isNew ? 'Edit' : 'Add';
  }

  get buttonText(): string {
    return !this.isNew ? 'Save Changes' : 'Add Weakness';
  }

  get minDate() {
    return moment().toDateStruct();
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) studentWeakness: StudentWeakness | undefined;

  @Input({ required: true }) studentId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly submitted = new EventEmitter();
  @Output() readonly weaknessCompleted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */

    this.weaknessForm = this.#fb.group<Weakness>({
      id: new FormControl<number>(0, Validators.required),
      description: new FormControl<string | null>(null, Validators.required),
      reviewDate: new FormControl<DateStruct | null>(null, Validators.required),
      completed: new FormControl<boolean>(false)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    if (this.studentWeakness) {
      this.weaknessForm.patchSafe(this.studentWeakness.weakness);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (this.weaknessForm.invalid) {
      markControlsAsTouched(this.weaknessForm);
      return;
    }
    const weakness = this.weaknessForm.value;
    if (this.studentId) {
      const studentId = this.studentId;
      if (weakness.id > 0) {
        this.#subscriptions.push(
          this.#studentService.updateExistingWeakness(studentId, weakness)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (weakness.completed) {
                    this.weaknessCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.weaknessForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }));
      } else {
        this.#subscriptions.push(
          this.#studentService.postNewWeakness(studentId, weakness)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (weakness.completed) {
                    this.weaknessCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.weaknessForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }
            ));
      }
    }
  }

  delete() {
    const weakness = this.weaknessForm.value;
    if (this.studentId) {
      this.#subscriptions.push(
        this.#studentService.deleteExistingWeakness(this.studentId, weakness.id)
          .subscribe(
            {
              next: () => {
                this.submitted.emit();
                this.#changeDetectorRef.markForCheck();
              },
              error: (error: unknown) => {
                this.weaknessForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              },
              complete: () => this.#studentService.refreshCacheItem(this.studentId!)
            }
          ));
    }
  }

}
