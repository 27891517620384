@if (!editing) {
<section class="kip-tab">
  <ng-template #tableHeader>
    <div class="w-100 d-flex justify-content-between">
      <h2 class="kip-tab__header mb-0">Assessment Results</h2>
      <kip-buttons [buttons]="buttons" />
    </div>
  </ng-template>
  <ng-template #noResultsInSystem>
    <kip-no-results text="Lets add our first." title="Looks like you don't have any results for this student yet!"
      [addButtons]="buttons" [canEdit]="canEdit" />
  </ng-template>
  <kip-paged-table listType="assessments" [initialPageSize]="resultsPerPage"
    [noResultsInSystemTemplate]="noResultsInSystem" [pageLoading]="currentLoad" [tableHeaderTemplate]="tableHeader"
    [(filter)]="filter" (pageSelected)="loadPage($event)">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">
          <kip-paged-filter columnName="Assessment" id="assessmentGroupOptions" [value]="filter.assessmentResultTypeId"
            [values]="assessmentResultTypes" (outputValue)="updateAssessmentResultTypeId($event)" />
        </th>
        <th scope="col">Result</th>
        @if (canEditOrDelete) {
        <th scope="col">Action</th>
        }
      </tr>
    </thead>
    <tbody>
      @for (assessmentResult of assessmentResults.items; track assessmentResult) {
      <tr class="cursor-pointer">
        <td (click)="navigate(assessmentResult.assessmentId)">{{ assessmentResult.dateTime | dateTime }} </td>
        <td (click)="navigate(assessmentResult.assessmentId)">{{ assessmentResult.resultTypeTitle }}</td>
        <td (click)="navigate(assessmentResult.assessmentId)">{{ assessmentResult.result || '-' }}</td>
        @if (canEditOrDelete) {
        <td class="text-center" (click)="deleteAssessmentResultDialog(assessmentResult.assessmentResultId)"><fa-icon
            class="text-danger" [icon]="icons.delete" /></td>
        }
      </tr>
      }
    </tbody>
  </kip-paged-table>
</section>
}
@if (editing) {
<section class="kip-tab kip-tab--is-edit">
  <div class="col-xl-12 col-xxl-8">
    @if (assessmentId !== null && studentId) {
    <kip-student-assessment-change class="w-100" [assessmentId]="assessmentId" [comprehensionTest]="comprehensionTest"
      [dateOfBirth]="dateOfBirth" [grade]="gradeId" [readingTest]="readingTest" [studentId]="studentId"
      (complete)="onComplete($event)" />
    }
  </div>
</section>
}

<kip-confirm-dialog #confirm title="Delete assessment result" (yes)="deleteAssessmentResultConfirm()">
  Are you sure you wish to delete this assessment result?
</kip-confirm-dialog>