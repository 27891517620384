import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum SaveWarningAction {
  Save = 'save',
  Discard = 'discard',
  Cancel = 'cancel'
}
@Component({
  selector: 'kip-save-warning',
  templateUrl: './save-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveWarningComponent {

  readonly #modalService = inject(NgbModal);

  @Input() unsavedChanges = 'Unsaved Changes';
  @Input() warningTitle = 'Warning!';
  @Input() warningText = 'You will lose unsaved changes.';
  @Input() save = 'Save';
  @Input() cancel = 'Cancel';
  @Input() discard = 'Discard';

  @ViewChild('saveWarning') saveWarning: TemplateRef<any> | undefined;

  @Output() readonly action = new EventEmitter<SaveWarningAction>();

  /* eslint-disable kip/no-unused-public-members */

  warnAboutUnsavedChanges() {
    this.#modalService.open(this.saveWarning, { size: 'lg' }).result.then(result => {
      switch (result) {
        case 'save':
          this.action.emit(SaveWarningAction.Save);
          break;
        case 'discard':
          this.action.emit(SaveWarningAction.Discard);
          break;
        case 'cancel':
          this.action.emit(SaveWarningAction.Cancel);
          break;
      }
    });
  }

  /* eslint-enable kip/no-unused-public-members */
}
