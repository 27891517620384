import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { AwardsService, StudentPointsTransaction } from 'awards-lib';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { defaultEmptyPage, PagedList, PageInfo, PageListSize, PageRequest } from 'ui-common-lib';

@Component({
  selector: 'kip-student-awards-list',
  templateUrl: './student-awards-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentAwardsListComponent {

  readonly #awardsService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #studentId: number | undefined;

  readonly resultsPerPage = PageListSize.TwentyFive;

  awards = 0;
  points = 0;
  pointsTransactions: PagedList<StudentPointsTransaction> = defaultEmptyPage();
  currentLoad: Observable<PageInfo> = new Observable<PageInfo>();

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#load();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  loadPage(pageToFetch: PageRequest) {
    this.currentLoad = this.#awardsService.queryPointsRecords(this.#studentId ?? 0, pageToFetch)
      .pipe(
        tap(summary => {
          this.awards = summary.awardData.awards;
          this.points = summary.awardData.points;
          this.pointsTransactions = summary.transactions;
          this.#changeDetectorRef.markForCheck();
        }),
        map(summary => summary.transactions.pageInfo)
      );
  }

  #load() {
    const firstPage = 1;
    this.loadPage({
      pageNumber: firstPage,
      pageSize: this.resultsPerPage
    });
  }
}
