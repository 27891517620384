<div class="d-flex justify-content-between align-items-center kip-tcc pe-1 py-1 border-bottom" ngbAutofocus>
  <div class="modal-title h3 p-1 ms-3" id="modal-basic-title">
    <fa-icon class="me-1" size="1x" [icon]="icons.fileContract" />
    {{ title }}
  </div>
  <button aria-label="Close" class="btn btn-link btn--icon" type="button" (click)="close()">
    <fa-icon [icon]="icons.close.regular" />
  </button>
</div>
<div class="modal-body overflow-auto" [style]="fixedHeight?'height: calc(90vh - 100px);':''">
  <div [innerHTML]="html | safe : 'html'">

  </div>
</div>
<div class="w-100 p-2 d-flex justify-content-between border-top">
  <button class="btn btn-primary" type="button" (click)="close()">Close</button>
  @if (showPrint) {
  <button class="btn btn-primary" type="button" (click)="print()">Print</button>
  }
</div>