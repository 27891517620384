import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { AnswerType, QuestionSpeed, QuestionSpelling, ValidationResult } from '../../models';
import { QuestionsService } from '../../services';
import { QuestionLayout } from '../question-layout';
import { SpellingTimerService } from './spelling-timer.service';

@Component({
  selector: 'kip-question-spelling',
  templateUrl: './spelling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpellingComponent extends QuestionLayout {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #questionsService = inject(QuestionsService);
  readonly #timerService = inject(SpellingTimerService);

  #valid = ValidationResult.NotKnown;
  #interval = 500;
  #parameters: string[] = [];
  #width = 0;
  #showInput = false;
  #displayAnswer: AnswerType | undefined;
  #subscriptions: Subscription[] = [];
  readonly #letters$ = new Subject<string[]>();
  readonly #subscription = new Subscription();

  protected override autoReady = false;

  override replayable = true;
  override speed = QuestionSpeed.Normal;

  override question: QuestionSpelling | undefined;

  get valid() {
    return this.#valid;
  }

  get width(): number {
    return this.#width < 80 ? 80 : this.#width;
  }

  get showInput(): boolean {
    return this.#showInput;
  }

  get letters$(): Observable<string[]> {
    return this.#letters$;
  }

  get answers(): AnswerType[] {
    if (this.input) {
      return [this.input.nativeElement.value];
    }
    return [];
  }

  get displayValue(): AnswerType | undefined {
    return this.#displayAnswer;
  }

  override set validationResults(values: ValidationResult[]) {
    if (this._validationResults !== values) {
      this._validationResults = values;
      if (values.length === 1) {
        this.#valid = values[0];
        if (!this.#valid && this.input) {
          this.input.nativeElement.focus();
        }
        this.#changeDetectorRef.markForCheck();
      }
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  get parameters() {
    return this.#parameters;
  }

  get interval() {
    return this.#interval;
  }

  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement> | undefined;

  constructor() {

    super();

    // Observe the settings and update the speed as required
    this.#subscription.add(this.#questionsService.settings$.subscribe(settings => {
      this.#applySpeed(settings.speed);
    }));
    this.#subscriptions.push(this.#subscription);
  }

  onChange() {
    this.#valid = ValidationResult.NotKnown;
  }

  onInput() {
    this.sendUpdates();
  }

  override incomplete() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  override initialize() {

    // Extract the parameters and get the letters to display immediately
    // Ignore the parameters field as it doesn't work with spelling swap
    // We still need to use it to determine if we are going to display the letters
    this.#parameters = (this.question?.parameters ?? []).length > 0 ?
      [...(this.question?.answers[0].values[0] ?? '').toString()] : [];

    // Calculate the input width from the number of letters
    if (this.question) {
      this.#width = this.question.answers[0].values[0].toString().length * 20;
    }
    this.displayAnswers();
    this.#play();
  }

  displayAnswers() {
    if (this.readonly && this.question) {
      const correctAnswer = this.question.answers[0].values[0];
      if (this.studentAnswers !== undefined) {
        const studentAnswer = this.studentAnswers[0];
        this.#displayAnswer = studentAnswer;
        this.#valid = correctAnswer !== studentAnswer ? ValidationResult.Incorrect : ValidationResult.Correct;
      } else {
        this.#displayAnswer = correctAnswer;
        this.#valid = ValidationResult.Correct;
      }
    }
  }

  override replay() {

    // Only replay of the play sequence is not active
    // This is indicated by the input showing
    if (this.#showInput) {
      this.#play();
    }
  }

  override destroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #play() {

    this.#showInput = false;

    // Do not invoke the sequence if in readonly mode
    if (!this.readonly) {

      // Reset the ready state for the container question to configure its template
      this.triggerReady(false);

      this.#invokeSound();
    } else {

      // In ready, trigger the ready state immediately
      this.triggerReady(true);
    }
  }

  #displayLetters() {

    // Create a timer to display a letter at a given interval
    // An empty value is appended to the end to allow time for the last character to be seen
    const letters: string[] = [];

    if (this.#parameters) {
      this.#subscription.add(this.#timerService
        .createTimer(this.#interval, [...this.#parameters, ''])
        .subscribe(
          {
            next: letter => {
              letters.push(letter);
              this.#letters$.next(letters);
            },
            error: () => {
              // ignore
            },
            complete: () => setTimeout(() => this.#displayInput(), 300)
          }));
      this.#subscriptions.push(this.#subscription);
    } else {

      // If there are no parameters then jump straight to the input (no letters to show)
      this.#displayInput();
    }
  }

  #applySpeed(speed: QuestionSpeed | undefined) {

    // Set the letter display interval based on the configured speed
    this.#interval = 500;

    if (speed === QuestionSpeed.Slow) {
      this.#interval = 750;
    } else if (speed === QuestionSpeed.Fast) {
      this.#interval = 225;
    }
  }

  #invokeSound() {
    // Invoke the word sound and then show the input
    if (this.behaviour) {
      this.#subscriptions.push(
        this.behaviour
          .invokeSequence('word')
          .subscribe({
            next: () => this.#displayLetters(),
            error: () => {
              this.#displayLetters();
            }
          }));
    } else {
      this.#displayLetters();
    }
  }

  #displayInput() {

    // Show the input and clear the letters
    this.#showInput = true;
    this.#letters$.next([]);

    // If auto focus is enabled then activate it
    if (this.autoFocus) {
      this.#changeDetectorRef.detectChanges();
      if (this.input) {
        this.input.nativeElement.focus();
      }
    }

    // Because of the sound, the ready state is delayed and needs invoking manually
    this.triggerReady(true);
  }
}
