@if (display) {
<div class="pointer-events-none fixed-bottom d-flex min-vw-100 mx-3 mb-3">
  <div class="pointer-events-auto ms-auto alert bg-white border shadow-sm col-12 col-md-6 col-xl-4 col-xxl-3 me-5"
    role="alert">
    <div class="no-wrap">
      <span class="text-primary fw-bold mb-2">Your browser seems to be quite old or not supported.</span>
      <p>You may experience some unexpected behavior and failures.<br />
        It is recommended you use a more up to date device.<br /><br />
        {{ device }}
      </p>
      @if (showSupported) {
      <p>
        Supported browsers are :
      </p>
      }
      @if (showSupported) {
      <ul>
        @for (supportedDevice of supportedDevices; track supportedDevice) {
        <li>{{ supportedDevice }}</li>
        }
      </ul>
      }
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <button class="btn btn-primary" type="button" (click)="okClicked()">Ok, I understand</button>
    </div>
  </div>
</div>
}