import { ApplicationRef, ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { PwaService } from 'pwa-lib';
import { concat, first, interval, Subscription } from 'rxjs';

@Component({
  selector: 'kip-update',
  templateUrl: './update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateComponent implements OnDestroy {

  readonly #appRef = inject(ApplicationRef);
  readonly #pwaService = inject(PwaService);

  #subscriptions: Subscription[] = [];

  readonly noClicked = signal(false);
  readonly updateDetected = toSignal(this.#pwaService.updateDetected$, { initialValue: false });
  readonly updateAvailable = toSignal(this.#pwaService.updateAvailable$, { initialValue: false });

  constructor() {

    // taken from here  https://angular.io/guide/service-worker-communications

    const appIsStable$ = this.#appRef.isStable.pipe(first(isStable => isStable));
    const everyHour$ = interval(60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    this.#subscriptions.push(everyHourOnceAppIsStable$.subscribe(() => {
      try {
        if (this.#pwaService.checkForUpdateAvailable()) {
          this.#pwaService.checkForUpdate().then(updateFound => {
            console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
            if (updateFound) {
              this.#pwaService.updatePwa();
            }
          });
        } else {
          console.log('Check for updates unavailable - service workers are disabled');
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  yesClicked() {
    this.#pwaService.updatePwa();
  }
}
