<div class="d-flex mb-3">
  <div class="d-flex border rounded-lg px-3 py-2 col">
    <kip-points-badge />
    <div class="ms-2 text-start">
      <div class="text-primary pt-1 fw-normal h2 mb-0">{{ points }}</div>
      <p class="mb-0 text-secondary small">Points</p>
    </div>
  </div>

  <div class="d-flex border rounded-lg px-3 py-2 ms-3 col">
    <kip-awards-badge />
    <div class="ms-2 text-start">
      <div class="text-primary pt-1 fw-normal h2 mb-0">{{ awards }}</div>
      <p class="mb-0 text-secondary small">Awards</p>
    </div>
  </div>
</div>

<kip-paged-table listType="student points transactions" [initialPageSize]="resultsPerPage" [isSearchable]="true"
  [pageLoading]="currentLoad" (pageSelected)="loadPage($event)">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Award</th>
      <th scope="col">Description</th>
      <th scope="col">Type</th>
      <th scope="col">Points</th>
    </tr>
  </thead>
  <tbody>
    @for (pointsTransaction of pointsTransactions.items; track pointsTransaction) {
    <tr class="cursor-pointer">
      <td>{{ pointsTransaction.displayDate | dateFromDateStruct }} </td>
      <td> <kip-icon class="d-inline-block me-2" style="width: 32px;" [displayIcon]="pointsTransaction.awardId" /> {{
        pointsTransaction.label }}</td>
      <td>{{ pointsTransaction.description }}</td>
      <td>{{
        pointsTransaction.type }}</td>
      <td>{{ pointsTransaction.points }}</td>
    </tr>
    }
  </tbody>
</kip-paged-table>