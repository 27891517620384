import { Pipe, PipeTransform } from '@angular/core';

import { SimpleScore } from '../models';

@Pipe({
  name: 'manualScore'
})
export class ManualScorePipe implements PipeTransform {

  transform(correct: number | undefined, total: number | undefined, inProgress: boolean | undefined, isSimpleScoring: boolean | undefined, simpleScore: SimpleScore | undefined): string {
    let questionSummary = '';

    if (inProgress) {
      questionSummary = 'In progress';
    }
    else if (isSimpleScoring && simpleScore) {
      questionSummary = `Score - ${simpleScore}`;
    } else if (!isSimpleScoring && total && !inProgress) {
      questionSummary = `${correct ?? 0} of ${total} (${total})`;
    }

    return questionSummary;
  }
}
