import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { HelpNodeName } from 'help-lib';
import { Icons } from 'icon-lib';
import { PwaService } from 'pwa-lib';
import { Observable, Subscription } from 'rxjs';
import { StudentService } from 'student-common-lib';
import { OverlaysBackgroundColor, ProfileService, Role, StudentSettings, UserProfile } from 'ui-common-lib';

import { environment } from '../../../environments';
import { fromOverlaysBackgroundColor, OverlaysBackgroundColorState } from '../../store';
import { Feature, FeatureFlagService, gotoStudentDashboard } from '..';
import { gotoAvatar, gotoReadyPlayerMeInteract } from '../common-route-helper';
import { gotoAwards, gotoStudentSettings } from '../student-route-helper';
import {
  gotoActivities, gotoChanges, gotoDIY, gotoDropIn, gotoExplore, gotoGoals, gotoHelp, gotoHistory, gotoIcons, gotoInitialPlan, gotoMark,
  gotoNext,
  gotoPlanning, gotoPrint, gotoTree, gotoTutorDashboard, gotoTutorSettings,
  gotoVideos,
  urlIsPlanning
} from '../tutor-route-helper';

@Component({
  selector: 'kip-navbar',
  templateUrl: './navbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit, OnDestroy {

  readonly #router = inject(Router);
  readonly #authService = inject(AuthService);
  readonly #profileService = inject(ProfileService);
  readonly #pwaService = inject(PwaService);
  readonly #studentService = inject(StudentService);
  readonly #store = inject(Store<OverlaysBackgroundColorState>);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #featureFlagService = inject(FeatureFlagService);

  #profile: UserProfile | undefined;
  #subscriptions: Subscription[] = [];
  #isTutor = false;
  #isSuperUser = false;
  #isStudent = false;
  #isCentreManager = false;
  #loginCreationOpen = false;
  #studentSettings?: StudentSettings;
  #installAvailable = false;

  readonly icons = Icons;
  readonly whiteLabel = environment.whiteLabel;

  get showIcons() {
    return environment.showIcons;
  }

  get showSentry() {
    return environment.showSentry || this.#isSuperUser;
  }

  get isActing() {
    return this.#profile?.actorId;
  }

  get profile() {
    return this.#profile;
  }

  get isTutor(): boolean {
    return this.#isTutor;
  }

  get isCentreManager() {
    return this.#isCentreManager;
  }

  get isSuperUser() {
    return this.#isSuperUser;
  }

  get isStudent(): boolean {
    return this.#isStudent;
  }

  get isHome(): boolean {
    return this.#router.url === gotoStudentDashboard() || this.#router.url === gotoTutorDashboard();
  }

  get showLoginCreation() {
    return this.#profile ? !!this.#profile.showLoginCreation : false;
  }

  get userId() {
    return this.#profile ? this.#profile.userId : undefined;
  }

  set loginCreationOpen(value: boolean) {
    this.#loginCreationOpen = value;
  }

  get loginCreationOpen() {
    return this.#loginCreationOpen;
  }

  get installAvailable() {
    return this.#installAvailable && !this.#pwaService.isStandalone;
  }

  get isDropInEnabled(): Observable<boolean> {
    return this.#featureFlagService.isFeatureEnabled(Feature.DropIn);
  }

  get overlays() {
    return this.#studentSettings?.overlays;
  }

  get showReportIssueLink(): boolean {
    const isAllowedRoute = this.#router.url === gotoTutorDashboard() || urlIsPlanning(this.#router.url);
    return isAllowedRoute && this.#isCentreManager;
  }

  get franchiseePortalUrl(): string {
    return environment.franchiseePortal;
  }

  @Input() uId = '';

  @Input() jiraMetaData: { [prop: string]: string } = {};

  @Input({ required: true }) helpNodeName: HelpNodeName | undefined;

  ngOnInit() {
    this.#subscriptions.push(
      this.#profileService
        .trackUserProfile()
        .subscribe(profile => {
          this.#profile = profile;
          this.#isTutor = this.#isInRole(Role.Tutor) || this.#isInRole(Role.CentreManager);
          this.#isStudent = this.#isInRole(Role.Student);
          this.#isCentreManager = this.#isInRole(Role.CentreManager);
          this.#isSuperUser = this.#isInRole(Role.SuperUser);
          this.#changeDetectorRef.markForCheck();

          if (this.isStudent && profile) {
            this.#studentSettings = JSON.parse(JSON.stringify(profile.settings)) as StudentSettings;
          }
        }),
      this.#pwaService.installGeneralAvailable$.subscribe(value => {
        this.#installAvailable = value;
        this.#changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  raiseSentryError() {
    alert('This should raise a Sentry error - check logs');
    throw new Error('Sentry error test');
  }

  install() {
    this.#pwaService.installPwa();
  }

  stopActing() {
    this.#subscriptions.push(
      this.#profileService.stopActing().subscribe(() => {
        window.location.reload();
      }));
  }

  logout() {
    this.#authService.logout();
  }

  settings() {
    this.#router.navigate([this.#isStudent ? gotoStudentSettings() : gotoTutorSettings()]);
  }

  dashboard() {
    this.#router.navigate([this.#isStudent ? gotoStudentDashboard() : gotoTutorDashboard()]);
  }

  diy() {
    this.#router.navigate([gotoDIY()]);
  }

  initialPlan() {
    this.#router.navigate([gotoInitialPlan()]);
  }

  videos() {
    this.#router.navigate([gotoVideos()]);
  }

  iconsList() {
    this.#router.navigate([gotoIcons()]);
  }

  studentAwards() {
    this.#router.navigate([gotoAwards()]);
  }

  history() {
    this.#router.navigate([gotoHistory()]);
  }

  next() {
    this.#router.navigate([gotoNext()]);
  }

  planning() {
    this.#router.navigate([gotoPlanning()]);
  }

  dropIn() {
    this.#router.navigate([gotoDropIn()]);
  }

  print() {
    this.#router.navigate([gotoPrint()]);
  }

  mark() {
    this.#router.navigate([gotoMark()]);
  }

  avatar() {
    this.#router.navigate([gotoAvatar()]);
  }

  changes() {
    this.#router.navigate([gotoChanges()]);
  }

  explore() {
    this.#router.navigate([gotoExplore()]);
  }

  tree() {
    this.#router.navigate([gotoTree()]);
  }

  readyPlayerMeInteract() {
    this.#router.navigate([gotoReadyPlayerMeInteract()]);
  }

  help() {
    this.#router.navigate([gotoHelp()]);
  }

  goals() {
    this.#router.navigate([gotoGoals()]);
  }

  activities() {
    this.#router.navigate([gotoActivities()]);
  }

  loginCreated(showLoginCreation: boolean) {
    if (!showLoginCreation) {
      this.#profileService.hideLoginCreation();
    }
  }

  onToggleOverlays() {
    if (this.overlays && this.#studentSettings) {
      this.#studentSettings.overlays.enabled = !this.overlays.enabled;

      if (this.overlays.enabled && this.#studentSettings?.backgrounds) {
        this.#studentSettings.backgrounds.enabled = false;
      }

      this.#changeDetectorRef.detectChanges();
      this.#updateSettings();
    }
  }

  onChangeOverlaysColor(color: string) {
    if (this.overlays && this.#studentSettings) {
      this.#studentSettings.overlays.color = color;
      this.#changeDetectorRef.detectChanges();
      this.#updateSettings();
    }
  }

  #isInRole(role: string) {
    if (this.profile) {
      return this.profile.roles.includes(role);
    }

    return false;
  }

  #updateSettings() {
    if (this.#studentSettings && this.#profile?.tutorOrStudentId) {
      const data = {
        backgrounds: JSON.parse(JSON.stringify(this.#studentSettings?.backgrounds)) as OverlaysBackgroundColor,
        overlays: JSON.parse(JSON.stringify(this.overlays)) as OverlaysBackgroundColor
      };

      this.#store.dispatch(fromOverlaysBackgroundColor.setColorAction(data));
      this.#subscriptions.push(
        this.#studentService.updateStudentSettings(this.#profile?.tutorOrStudentId, this.#studentSettings)
          .subscribe());
    }
  }
}
