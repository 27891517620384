import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

@Pipe({
  name: 'dateFromDateStruct'
})
export class DateFromDateStructPipe implements PipeTransform {

  transform(value: DateStruct | string | null | undefined, format = 'DD MMM YYYY'): string {
    if (!value) { return ''; }

    if (typeof value === 'string') {
      return moment(value).format(format);
    }

    return moment()
      .set(
        {
          years: value.year,
          months: value.month - 1,  // Moment months are 0 to 11, C# and Nb bootstrap 1 to 12
          date: value.day           // so changed the month value to align with C# and bootstrap.
        })
      .format(format);      // @HACK - Force litte endian date format. I don't know how locales work for 'L' in moment.js
  }
}
