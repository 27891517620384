@if (!isLoadingComplete) {
<div class="kip-monty">
  @switch (templateLoadingState()) {
  @case (pagedListLoadingState.Loading) {
  <kip-spinner />
  }
  @case (pagedListLoadingState.Slow) {
  <kip-monty-slow-request class="kip-monty__asset" />
  <p class="kip-monty__text">
    Things are a little slow right now
  </p>
  <p>
    Your request is taking some time...
  </p>
  <button class="btn btn-outline-primary" type="button" (click)="onSlowRequestCancel()">Cancel</button>
  }
  @case (pagedListLoadingState.Cancelled) {
  <kip-monty-error class="kip-monty__asset" />
  <p class="kip-monty__text">You've cancelled the request!</p>
  <p>
    Would you like to try again?
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="retryPageLoad()">Retry</button>
  }
  @case (pagedListLoadingState.Error) {
  <kip-monty-error class="kip-monty__asset" />
  <p class="kip-monty__text">
    Uh-oh! Something went wrong...
  </p>
  <p>
    We track these errors automatically, but if the problem persists, feel free to <a
      href="https://kipmcgrath.atlassian.net/servicedesk/customer/portal/21" rel="noopener" target="_blank">contact
      us</a>.<br />In
    the meantime, try reloading.
  </p>
  <button class="btn btn-outline-primary mt-3" type="button" (click)="reloadPage()">Reload</button>
  }
  }
</div>
}
@if (isLoadingComplete()) {
@if (noResultsInSystem()) {
<ng-container *ngTemplateOutlet="noResultsInSystemTemplate() || noResultsInSystemDefault" />
} @else {
<div class="row g-0 mb-3">
  <div class="col-12 d-flex align-items-center" [class.col-lg-6]="isSearchable()">
    <ng-container *ngTemplateOutlet="listHeaderTemplate()" />
  </div>
  @if (isSearchable()) {
  <div class="col-12 col-lg-auto ms-auto">
    <div class="input-group">
      <button class="btn btn-outline-secondary border-end-0 border" type="button" (click)="onSearch()">
        <fa-icon [icon]="icons.search" />
      </button>
      <input #searchInput class="form-control border-start-0 border" id="table-paged-search" type="search"
        [ngModel]="searchQuery()" (ngModelChange)="searchQuery.set($event)">
      <ng-container *ngTemplateOutlet="listOptionButtonTemplate()" />
    </div>
  </div>
  }
</div>
@if (searchYieldNoResults()) {
<div class="kip-monty">
  <kip-monty-empty class="kip-monty__asset" />
  <p class="kip-monty__headline">Your search terms returned '0' results</p>
  <p class="kip-monty__text">Try again by altering your search terms or filter terms.</p>
  <button class="btn btn-outline-secondary" type="button" (click)="resetSearch()">
    Clear
  </button>
</div>
}
@if (hasResults()) {
<ng-content />
<div class="row align-items-start g-0 text-muted">
  <div class="col d-flex align-items-center">
    <div class="me-2">
      <span class="fw-bold">{{ totalNumberOfResults() }}</span> {{ (totalNumberOfResults() > 1) ? 'Results'
      : 'Result' }}
    </div>
    <select class="form-control" style="width: 4.5rem" [ngModel]="pageSize()" (change)="onPageSizeChange($event)">
      <option>5</option>
      <option>10</option>
      <option>25</option>
      <option>50</option>
      <option>100</option>
    </select>
  </div>
  <div class="col text-center">
    <div class="btn-group" role="group">
      <button class="kip-paginator__button" type="button" (click)="firstPage()">
        <fa-layers [fixedWidth]="true">
          <fa-icon transform="shrink-4 left-4 rotate--90" [icon]="icons.horizontalRule" />
          <fa-icon transform="right-3" [icon]="icons.chevrons.left" />
        </fa-layers>
      </button>
      <button class="kip-paginator__button" type="button" (click)="previousPage()">
        <fa-icon [fixedWidth]="true" [icon]="icons.chevrons.left" />
      </button>
      @for (page of pages(); track page) {
      <button type="button"
        [ngClass]="(page === currentPage()?'btn btn-primary kip-paginator__button--width-only':'kip-paginator__button kip-paginator__button--width-only')"
        (click)="onPageSelection(page)">
        {{page}}
      </button>
      }
      <button class="kip-paginator__button" type="button" (click)="nextPage()">
        <fa-icon [fixedWidth]="true" [icon]="icons.chevrons.right" />
      </button>
      <button class="kip-paginator__button" type="button" (click)="lastPage()">
        <fa-layers [fixedWidth]="true">
          <fa-icon transform="left-3" [icon]="icons.chevrons.right" />
          <fa-icon transform="shrink-4 right-4 rotate--90" [icon]="icons.horizontalRule" />
        </fa-layers>
      </button>
    </div>
  </div>
  <div class="col">
    <form class="d-flex flex-row align-items-center" [formGroup]="goToPageForm"
      [ngClass]="{'form-group-invalid' : !goToPageNumber.valid && goToPageNumber.dirty }" (ngSubmit)="onGoToPage()">
      <label class="ms-auto me-2" for="toPageInput">To Page:</label>
      <input class="form-control text-muted" formControlName="pageNumber" id="toPageInput" style="width: 3rem"
        type="text" [ngbTooltip]="errors ? errors : ''" />
      <button class="btn btn-link text-muted" type="submit">Go <fa-icon [icon]="icons.carets.right" />
      </button>
    </form>
  </div>
</div>
}
}
}
<ng-template #noResultsInSystemDefault>
  <div class="kip-monty">
    <kip-monty-empty class="kip-monty__asset" />
    <p class="kip-monty__headline">Sorry, we couldn't find any {{ listType() }}!</p>
  </div>
</ng-template>