import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { PdfService } from 'pdf-lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kip-whiteboard-audio',
  templateUrl: './audio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardAudioComponent implements AfterViewInit, OnDestroy {

  readonly #pdfService = inject(PdfService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #soundFile: string | undefined;
  #showVolume = false;
  #playing = false;
  #playSpeed = 1;
  #audioLoaded = false;
  #duration = '00:00';
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  set showVolume(value: boolean) {
    this.#showVolume = value;
  }

  get showVolume() {
    return this.#showVolume;
  }

  set playing(value: boolean) {
    this.#playing = value;
    this.#changePlayState();
  }

  get playing() {
    return this.#playing;
  }

  set playSpeed(value: number) {
    if (value >= 0.75 && value <= 1.25) {
      this.#playSpeed = value;
      this.#changePlayState();
    }
  }

  get playSpeed() {
    return this.#playSpeed;
  }

  get audioLoaded() {
    return this.#audioLoaded;
  }

  get duration() {
    return this.#duration;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set soundFile(value: string | undefined) {
    this.#soundFile = value;
    this.#playing = false;
    this.#changePlayState();
    this.#audioLoaded = false;
    this.#changeDetectorRef.markForCheck();

    if (value) {
      this.#pdfService.getSoundFile(value).then(response => {
        if (this.audioPlayer) {
          const audio = this.audioPlayer.nativeElement;
          audio.src = response;
          audio.addEventListener('loadeddata', () => {
            this.#audioLoaded = true;
            this.#duration = moment.utc(audio.duration * 1000).format('mm:ss');
            this.#changeDetectorRef.markForCheck();
            this.#setCurrentTime(0, audio.duration);
            this.#setVolume(audio.volume);
          });
        }
      });
    }
  }

  get soundFile() {
    return this.#soundFile;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement> | undefined;
  @ViewChild('audioPosition') audioPosition: ElementRef<HTMLElement> | undefined;
  @ViewChild('audioSeek') audioSeek: ElementRef<HTMLElement> | undefined;
  @ViewChild('audioVolume') audioVolume: ElementRef<HTMLElement> | undefined;

  ngAfterViewInit() {
    if (this.audioPlayer) {
      const audio = this.audioPlayer.nativeElement;
      this.#addEvents(audio, ['timeupdate'], () => {
        this.#setCurrentTime(audio.currentTime, audio.duration);
      });
      this.#addEvents(audio, ['ended'], () => {
        this.#playing = false;
        this.#changeDetectorRef.markForCheck();
      });
      if (this.audioVolume) {
        this.#setVolume(audio.volume);
        this.#setupSlider(this.audioVolume.nativeElement, value => {
          audio.volume = value;
          this.#setVolume(value);
        });
      }
      if (this.audioSeek) {
        this.#setCurrentTime(0, 0);
        this.#setupSlider(this.audioSeek.nativeElement, (value, isFinal) => {
          audio.currentTime = value * audio.duration;
          this.#setCurrentTime(value, audio.duration);
          if (isFinal && value === 100) {
            this.#playing = false;
            this.#changeDetectorRef.markForCheck();
          }
        });
      }
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #setupSlider(container: HTMLElement, action: (value: number, isFinal: boolean) => void) {
    const startHandler = (startHandlerEvent: MouseEvent | TouchEvent) => {
      const bar = container.children[0];
      const barRect = bar.getBoundingClientRect();
      let lastX = this.#getClientX(startHandlerEvent, 0);
      let isFinal = false;
      const moveHandler = (moveHandlerEvent: MouseEvent | TouchEvent) => {
        lastX = this.#getClientX(moveHandlerEvent, lastX);
        let offset = lastX - barRect.left;
        if (offset < 0) {
          offset = 0;
        } else if (offset > barRect.width) {
          offset = barRect.width;
        }
        action(offset / barRect.width, isFinal);
      };
      const endHandler = (endHandlerEvent: MouseEvent | TouchEvent) => {
        isFinal = true;
        moveHandler(endHandlerEvent);
        this.#removeEvents(document.body, ['mousemove', 'touchmove'], moveHandler);
        this.#removeEvents(document.body, ['mouseleave', 'mouseup', 'touchend', 'touchcancel'], endHandler);
      };
      this.#addEvents(document.body, ['mousemove', 'touchmove'], moveHandler);
      this.#addEvents(document.body, ['mouseleave', 'mouseup', 'touchend', 'touchcancel'], endHandler);
    };

    this.#addEvents(container, ['mousedown', 'touchstart'], startHandler);
  }

  #getClientX(event: MouseEvent | TouchEvent, defaultValue: number) {

    if ('touches' in event && event.touches.length > 0) {
      return event.touches[0].clientX;
    }

    if ('clientX' in event) {
      return event.clientX;
    }

    return defaultValue;
  }

  #addEvents(target: HTMLElement, eventNames: string[], handler: (event: MouseEvent | TouchEvent | any) => void) {
    for (const eventName of eventNames) {
      target.addEventListener(eventName, handler);
    }
  }

  #removeEvents(target: HTMLElement, eventNames: string[], handler: (event: MouseEvent | TouchEvent | any) => void) {
    for (const eventName of eventNames) {
      target.removeEventListener(eventName, handler);
    }
  }

  #changePlayState() {
    if (this.#audioLoaded && this.audioPlayer) {
      const audio = this.audioPlayer.nativeElement;
      audio.playbackRate = this.#playSpeed;
      if (audio.paused && this.#playing) {
        audio.play();
      } else if (!audio.paused && !this.#playing) {
        audio.pause();
      }
    }
  }

  #setCurrentTime(currentTime: number, duration: number) {
    if (this.audioPosition) {
      this.audioPosition.nativeElement.innerText = moment.utc(currentTime * 1000).format('mm:ss');
    }
    if (this.audioSeek) {
      const progressBar = this.audioSeek.nativeElement.children[0].children[0] as HTMLElement;
      progressBar.style.width = duration > 0 ? `${currentTime / duration * 100}%` : '0%';
    }
  }

  #setVolume(volume: number) {
    if (this.audioVolume) {
      const volumeBar = this.audioVolume.nativeElement.children[0].children[0] as HTMLElement;
      volumeBar.style.width = `${volume * 100}%`;
    }
  }
}
