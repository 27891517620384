import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

export function convertDateToMoment(effectiveDate: Date | DateStruct | string) {
  if (typeof effectiveDate === 'string') {
    return moment(new Date(effectiveDate));
  }
  if ((effectiveDate as Date).getTime) {
    return moment(effectiveDate);
  }
  const dateStruct = effectiveDate as DateStruct;
  return moment(new Date(dateStruct.year, dateStruct.month, dateStruct.day));
}
