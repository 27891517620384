import { Injectable } from '@angular/core';
import { Question } from 'questions-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

import { AccessKeysAndCurriculum, ContentNode, CurriculumNode, ExploreActivity, StudentQuestionResult } from '../models';

@Injectable({ providedIn: 'root' })
export class ExploreService extends HttpWithAbsoluteService {

  getAdhocImage(imageName: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/adhoc-image-url/${imageName}`, ErrorBlobPath.Image);
  }

  getAccessKeys(): Observable<AccessKeysAndCurriculum> {
    return this.get<AccessKeysAndCurriculum>('tutor/contents/access-keys');
  }

  getActivities(regionId: number): Observable<ContentNode> {
    return this.get<ContentNode>(`tutor/contents/${regionId}/tree`);
  }

  getAssessmentActivities(regionId: number): Observable<ContentNode> {
    return this.get<ContentNode>(`tutor/contents/${regionId}/assessments`);
  }

  getAdditionalActivities(): Observable<ContentNode[]> {
    return this.get<ContentNode[]>('tutor/contents/additional');
  }

  getCurriculumTree(regionId: number): Observable<CurriculumNode[]> {
    return this.get<CurriculumNode[]>(`tutor/contents/${regionId}/regional`);
  }

  getResults(lessonPlanActivityId: number): Observable<StudentQuestionResult[]> {
    return this.get<StudentQuestionResult[]>(`tutor/questions/results/${lessonPlanActivityId}`);
  }

  getQuestion(questionId: number, regionId: number): Observable<Question> {

    // Ensure the question JSON is expanded for the Question type
    return this.get<any>(`tutor/questions/${questionId}?regionId=${regionId}`).pipe(
      map(question => Object.assign({
        questionId: question.questionId,
        introduction: question.introduction,
        workedSolution: question.workedSolution,
        contentGuid: question.contentGuid
      }, question.data))
    );
  }

  getExploreNewActivityById(treeActivityId: number): Observable<ExploreActivity> {
    return this.get<ExploreActivity>(`tutor/activities/explore-new/${treeActivityId}`);
  }

  getExploreActivityById(activityId: number): Observable<ExploreActivity> {
    return this.get<ExploreActivity>(`tutor/activities/explore/${activityId}`);
  }
}
