import { VideoEffectType } from './video-effect-type';

type videoEffectImageType = { [key in VideoEffectType]: string };

export const videoEffectImage: videoEffectImageType =
{
  [VideoEffectType.None]: '',
  [VideoEffectType.Blur]: '',
  [VideoEffectType.Image1]: 'assets/video-background1.png',
  [VideoEffectType.Pixelate]: '',
  [VideoEffectType.Image2]: 'assets/video-background2.jpg',
  [VideoEffectType.Image3]: 'assets/video-background3.jpg',
  [VideoEffectType.Image4]: 'assets/video-background4.jpg',
  [VideoEffectType.Image5]: 'assets/video-background5.jpg'
};
