import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject,
  Input, OnDestroy,
  OnInit, Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';

import { BgColor, ColorPickerBg, ColorPickerBorder } from '../../models';

@Component({
  selector: 'kip-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #defaultColors = [
    { color: ColorPickerBg.Blue, borderColor: ColorPickerBorder.Blue },
    { color: ColorPickerBg.Red, borderColor: ColorPickerBorder.Red },
    { color: ColorPickerBg.Orange, borderColor: ColorPickerBorder.Orange },
    { color: ColorPickerBg.Green, borderColor: ColorPickerBorder.Green }
  ];

  #subscriptions: Subscription[] = [];
  readonly #colorControl = new FormControl<string | null>('');
  readonly #defaultColor = { ...this.#defaultColors[0], borderColor: '#363738' };
  #selectedColor: BgColor = { ...this.#defaultColors[0] };

  get selectedColor() {
    return this.#selectedColor;
  }

  @Input() title = 'Select an overlay:';
  @Input() isSimpleMode = true;
  @Input({ required: true }) color? = '';
  @Input() colors: BgColor[] = this.#defaultColors;
  @Output() readonly changeColor = new EventEmitter<string>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#colorControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe(value => this.changeColor.next(value ?? '')));

    if (this.color) {
      const selectedColor = this.colors.find(item => item.color === this.color);
      if (selectedColor) {
        this.#selectedColor = { ...selectedColor };
      } else {
        this.isSimpleMode = false;
        this.#selectedColor = { ...this.#defaultColor, color: this.color };
      }
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onSelectColor(color: BgColor) {
    this.changeColor.next(color.color);
    this.#selectedColor = { ...color };
    this.isSimpleMode = true;
    this.#changeDetectorRef.markForCheck();
  }

  onExtendColor() {
    this.isSimpleMode = false;
    this.#selectedColor = this.#defaultColor;
    this.changeColor.next(this.#selectedColor.color);
    this.#changeDetectorRef.markForCheck();
  }

  onColorPickerChange(color: string) {
    this.#colorControl.setValue(color);
  }
}
