import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

const fractionsRegex = new RegExp(/^(\d+(?:(?: \d+)*\/\d+)?)$/g);

export class FractionsValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {
    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);
    if (studentAnswers.length === question.answers.length) {
      for (let index = 0; index < question.answers.length; index++) {
        const studentAnswer = studentAnswers[index];
        const answer = question.answers[index];
        for (const expected of answer.values) {
          validationResults[index] = this.#validateAnswer(this.#getPossibleAnswers(this.#sanitizeValue(expected)),
            this.#sanitizeValue(studentAnswer));
        }
      }
    }
    return validationResults;
  }

  #validateAnswer(expectedAnswers: string[], studentAnswer: string): ValidationResult {
    for (const answer of expectedAnswers) {
      if (new RegExp(fractionsRegex).test(studentAnswer) && studentAnswer === answer) {
        return ValidationResult.Correct;
      }
    }
    return ValidationResult.Incorrect;
  }

  #sanitizeValue(value: AnswerType): string {
    // Removes extra space before and after '/' and any extra space (>1) in the mixed number
    let returnValue = '';
    if (value !== undefined) {
      returnValue = value.toString().trim();
      if (!new RegExp(fractionsRegex).test(returnValue)) {
        returnValue = returnValue.replace(/\/\s+/g, '/').replace(/\s+\//g, '/').replace(/\s{2,}/, ' ');
      }
    }
    return returnValue;
  }

  #getPossibleAnswers(value: string): string[] {
    const possibleAnswers: string[] = [];
    let answer = value;
    possibleAnswers.push(answer);
    if (new RegExp(/ /g).test(answer)) {
      const improperNumParts = answer.split(' ', 2);
      const wholeNumber = improperNumParts[0];
      const fraction = improperNumParts[1];
      answer = this.#getSimpleFraction(Number.parseInt(wholeNumber, 10), fraction);
    } else {
      const properNumParts = answer.split('/', 2);
      const numerator = properNumParts[0];
      const denominator = properNumParts[1];
      if (Number.parseInt(numerator, 10) % Number.parseInt(denominator, 10) !== 0) {
        answer = this.#getMixedFraction(Number.parseInt(numerator, 10), Number.parseInt(denominator, 10));
      }
    }
    possibleAnswers.push(answer);
    return possibleAnswers;
  }

  #getSimpleFraction(wholeNumber: number, fraction: string): string {
    const fractionNumberParts = fraction.split('/', 2);
    const numerator = Number.parseInt(fractionNumberParts[0], 10);
    const denominator = Number.parseInt(fractionNumberParts[1], 10);
    return `${(denominator * wholeNumber + numerator).toString()}/${denominator.toString()}`;
  }

  #getMixedFraction(numerator: number, denominator: number): string {
    const integer = Math.floor(numerator / denominator);
    const fraction = `${(numerator - integer * denominator).toString()}/${denominator.toString()}`;
    return `${integer.toString()} ${fraction}`;
  }
}
