import { fabric } from 'fabric';
import { KeyboardService } from 'keyboard-lib';

import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardTool } from '../whiteboard-tool';

export class WhiteboardTextTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Text);
  }

  override selectable(obj: fabric.Object): boolean {
    return this.#isText(obj);
  }

  setup(color: WhiteboardColor, canvas: fabric.Canvas) {
    this.resetCanvas(canvas, false, false);
    this.setSelectableState(canvas, true, obj => this.#isText(obj));

    canvas.on('mouse:down', event => {
      const selected: fabric.Object | null = canvas.getActiveObject();

      if (!selected) {
        const pointer = canvas.getPointer(event.e);
        const text = new fabric.IText('', {
          selectable: true,
          top: pointer.y,
          left: pointer.x,
          fontFamily: 'Arial',
          fill: color.definition,
          fontSize: 24
        });

        canvas.add(text);
        canvas.renderAll();

        // Ensure the text object is still in edit mode
        canvas.setActiveObject(text);
        text.enterEditing();

      } else if (this.#isText(selected)) {

        // If selecting a text object force editing mode
        const text = selected as fabric.IText;
        text.enterEditing();
      }

      // Pass typed value to text area
      const textAreaList: NodeListOf<HTMLTextAreaElement> | null = document.querySelectorAll('[data-fabric-hiddentextarea]');
      const textArea = textAreaList[textAreaList.length - 1];

      if (textAreaList?.length > 1) {
        for (const item of textAreaList) {
          if (item !== textArea) {
            item.remove();
          }
        }
      }

      if (textArea) {
        KeyboardService.canvasActiveTexArea$.next(textArea);
      }
    });
  }

  teardown(canvas: fabric.Canvas) {
    canvas.off('mouse:down');

    // Reset the selectable state of all text objects
    this.setSelectableState(canvas, false, obj => this.#isText(obj));
  }

  #isText(obj: fabric.Object): boolean {
    return obj.type === 'i-text';
  }
}
