import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { MarkDownConfig, MarkdownOption } from '../../models';
import { MarkdownOptions } from '../markdown-options';

@Component({
  selector: 'kip-toolbar',
  templateUrl: './toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {

  readonly icons = Icons;
  readonly markdownOptions: MarkdownOption = MarkdownOptions;

  @Output() readonly selectMarkdownConfig = new EventEmitter<MarkDownConfig | undefined>();

  onSelect(markdown: MarkDownConfig) {
    this.selectMarkdownConfig.emit(markdown || undefined);
  }

}
