<kip-form-group-wrapper [formGroup]="goalForm" [showCancel]="true" [submitText]="buttonText"
  (cancelled)="cancelled.emit()" (submittedAndValidated)="submit()">

  <h2 class="kip-tab__header">{{ action }} Goal</h2>
  <kip-form-control-long-text id="description" label="Description" [control]="description" [rows]="3">
    <span>
      <br />
      <fa-icon [icon]="icons.lightbulb" />
      Goals should be SMART - Specific, Measurable, Achievable, Relevant, and Time-bound. Remember our mission: Every
      minute of every lesson we will focus on achieving our student's goals.
    </span>
  </kip-form-control-long-text>
  <kip-form-control-date id="reviewDate" label="Target Date for Review" [control]="reviewDate" [minDate]="minDate" />
  @if (!isNew) {
  <kip-form-control-checkbox headerLabel="Completion" id="completed" label="Goal Target Met" [control]="completed" />
  }

  <div buttons>
    @if (id.value > 0) {
    <button class="btn btn-outline-danger ms-2" type="button" (click)="delete()">
      <fa-icon [icon]="icons.delete" />
      Delete
    </button>
    }
    @if (id.value === 0 && !isParent) {
    <button class="btn btn-secondary ms-2" type="button" (click)="choose()">
      Choose
    </button>
    }
  </div>
</kip-form-group-wrapper>

<kip-organisation-goal-picker (selected)="selectGoal($event)" />