import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconLibModule } from 'icon-lib';

import { ControlErrorComponent } from './control-error/control-error.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormBuilderTypeSafe } from './helpers/typed-form';
import { FormControlInvalidPipe, FormHasErrorPipe } from './pipes';

@NgModule({
  declarations: [
    ControlErrorComponent,
    FormControlInvalidPipe,
    FormErrorComponent,
    FormHasErrorPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconLibModule,
    ReactiveFormsModule
  ],
  exports: [
    ControlErrorComponent,
    FormControlInvalidPipe,
    FormErrorComponent,
    FormHasErrorPipe
  ],
  providers: [
    FormBuilderTypeSafe
  ]
})
export class FormsLibModule { }
