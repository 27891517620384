import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kip-redirect-warning',
  templateUrl: './redirect-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectWarningComponent implements OnInit {

  readonly #modalService = inject(NgbModal);

  @Input({ required: true }) warningMessage = '';
  @Input({ required: true }) buttonText = '';
  @ViewChild('redirectWarning') redirectWarning: TemplateRef<any> | undefined;
  @Output() readonly acceptRedirect = new EventEmitter();

  ngOnInit() {
    this.#warnAboutRedirect();
  }

  goBack() {
    this.#modalService.dismissAll();
    this.acceptRedirect.emit();
  }

  #warnAboutRedirect() {
    setTimeout(() => {
      this.#modalService.open(this.redirectWarning, { size: 'lg', centered: true, modalDialogClass: 'kip-modal-themed theme-aurora bg-none' });
    }, 100);
  }
}
