import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-success',
  templateUrl: './monty-success.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontySuccessComponent implements MontyGraphic { }
