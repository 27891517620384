<div class="p-0" ngbDropdown placement="bottom-right auto">
  <button class="btn kip-btn btn-sm me-2" ngbDropdownToggle type="button" (click)="refresh()">
    <fa-icon class="me-1" size="1x" [icon]="icons.note.square" /> Notes
  </button>
  <div class="small kip-note-dropdown__menu kip-dropdown__menu kip-dropdown__menu--top-right" ngbDropdownMenu>
    <div class="mb-2 w-100 fw-light mt-2 pt-1 ms-1 px-2 d-flex align-items-end">
      <span class="h3 mb-0">Notes {{ givenName ? 'for ' + givenName : '' }}</span>
    </div>
    <div class="kip-note-dropdown__button-group btn-group w-100 mt-1 pb-2 px-2 border-bottom shadow-sm rounded-0">
      <button class="btn btn-sm btn-outline-primary" type="button" [class.active]="showLessonNotes" [disabled]="editing"
        (click)="currentNoteViewingMode = notesViewingMode.Lesson">
        Lesson
      </button>
      <button class="btn btn-sm btn-outline-primary" type="button" [class.active]="showSessionNotes"
        [disabled]="editing" (click)="currentNoteViewingMode = notesViewingMode.Session">
        Session
      </button>
      <button class="btn btn-sm btn-outline-primary" type="button" [class.active]="showGeneralNotes"
        [disabled]="editing" (click)="currentNoteViewingMode = notesViewingMode.General">
        General
      </button>
    </div>
    <div class="kip-note-dropdown__scrollarea overflow-auto w-100 px-2">
      @if (!isLoadingComplete) {
      <kip-spinner class="text-center p-5 d-block" />
      }
      @if (studentNotes && isLoadingComplete) {
      @if (!editing) {
      @if (pinnedStudentNotes.length > 0 && showGeneralNotes) {
      @for (studentNote of pinnedStudentNotes; track studentNote) {
      <div (click)="editGeneralNote(studentNote.note)">
        <kip-student-note-dropdown-general [studentNote]="studentNote.note" />
      </div>
      }
      }
      @if (pinnedStudentNotes.length === 0 && visibleNotes.length === 0) {
      <kip-no-results title="There's nothing here for now." />
      }
      @for (studentNote of visibleNotes; track studentNote) {
      @if (studentNote.lessonNote && (studentNote.lessonNote.internalSessionNote && showSessionNotes ||
      studentNote.lessonNote.internalLessonNote && showLessonNotes || studentNote.lessonNote.lessonId === lessonId)) {
      <div class="kip-note-dropdown__entry">
        <div class="kip-note-dropdown__header kip-note-dropdown__header--lesson">
          <fa-icon class="kip-note-dropdown__header-icon" size="lg" [fixedWidth]="true"
            [icon]="icons.deviceType.display" />
          <span class="kip-note-dropdown__headline">
            {{ studentNote.lessonNote.subject }}
          </span>
          @if (studentNote.lessonNote.lessonId === lessonId) {
          <span class="badge kip-badge kip-badge--primary">
            This {{ showLessonNotes ? 'Lesson': 'Session'}}
          </span>
          }
          @if (studentNote.lessonNote.lessonId !== lessonId) {
          <span class="text-nowrap">
            {{ studentNote.date | dateTime: defaultDateFormat }}
          </span>
          }
        </div>
        <div class="d-flex flex-column w-100">
          @if ((studentNote.lessonNote.internalSessionNote || studentNote.lessonNote.lessonId === lessonId) &&
          showSessionNotes) {
          <div class="kip-note-dropdown__description"
            (click)="studentNote.lessonNote.lessonId === lessonId ? editInternalNote() : editGeneralNote(studentNote.lessonNote.internalSessionNote)">
            <div class="text-overline mb-2">Internal summary</div>
            @if (studentNote.lessonNote.internalSessionNote) {
            <kip-emoji-message [message]="studentNote.lessonNote.internalSessionNote.note.description" />
            }
            @if (!studentNote.lessonNote.internalSessionNote && studentNote.lessonNote.lessonId === lessonId) {
            <span class="text-muted">
              Click to add internal summary...
            </span>
            }
          </div>
          }
          @if ((studentNote.lessonNote.internalLessonNote || studentNote.lessonNote.lessonId === lessonId) &&
          showLessonNotes) {
          <div class="kip-note-dropdown__description"
            (click)="studentNote.lessonNote.lessonId === lessonId ? editInternalNote() : editGeneralNote(studentNote.lessonNote.internalLessonNote)">
            <div class="text-overline mb-2">Internal summary</div>
            @if (studentNote.lessonNote.internalLessonNote) {
            <kip-emoji-message [message]="studentNote.lessonNote.internalLessonNote.note.description" />
            }
            @if (!studentNote.lessonNote.internalLessonNote && studentNote.lessonNote.lessonId === lessonId) {
            <span class="text-muted">
              Click to add internal summary...
            </span>
            }
          </div>
          }
          @if ((studentNote.lessonNote.parentSessionNote || studentNote.lessonNote.lessonId === lessonId) &&
          showSessionNotes) {
          <div class="kip-note-dropdown__description"
            (click)="studentNote.lessonNote.lessonId === lessonId ? editParentNote() :editGeneralNote(studentNote.lessonNote.parentSessionNote)">
            <div class="text-overline mb-2">Parent summary</div>
            @if (studentNote.lessonNote.parentSessionNote) {
            <kip-emoji-message [message]="studentNote.lessonNote.parentSessionNote.note.description" />
            }
            @if (!studentNote.lessonNote.parentSessionNote && studentNote.lessonNote.lessonId === lessonId) {
            <span class="text-muted">
              Click to add parent summary...
            </span>
            }
          </div>
          }
          @if ((studentNote.lessonNote.lessonFocusNote || studentNote.lessonNote.lessonId === lessonId) &&
          showLessonNotes) {
          <div class="kip-note-dropdown__description"
            (click)="studentNote.lessonNote.lessonId === lessonId ? editParentNote() :editGeneralNote(studentNote.lessonNote.lessonFocusNote)">
            <div class="text-overline mb-2">Lesson Focus</div>
            @if (studentNote.lessonNote.lessonFocusNote) {
            <kip-emoji-message [message]="studentNote.lessonNote.lessonFocusNote.note.description" />
            }
            @if (!studentNote.lessonNote.lessonFocusNote && studentNote.lessonNote.lessonId === lessonId) {
            <span class="text-muted">
              Click to add lesson focus...
            </span>
            }
          </div>
          }
          @if ((studentNote.lessonNote.parentLessonNote || studentNote.lessonNote.lessonId === lessonId) &&
          showLessonNotes) {
          <div class="kip-note-dropdown__description"
            (click)="studentNote.lessonNote.lessonId === lessonId ? editParentNote() :editGeneralNote(studentNote.lessonNote.parentLessonNote)">
            <div class="text-overline mb-2">Parent summary</div>
            @if (studentNote.lessonNote.parentLessonNote) {
            <kip-emoji-message [message]="studentNote.lessonNote.parentLessonNote.note.description"
              [warning]="studentNote.lessonNote.parentLessonNote && !studentNote.lessonNote.parentLessonNote.note.approved" />
            }
            @if (!studentNote.lessonNote.parentLessonNote && studentNote.lessonNote.lessonId === lessonId) {
            <span class="text-muted">
              Click to add parent summary...
            </span>
            }
          </div>
          }
        </div>
        @if (studentNote.lessonNote.internalActivityNotes.length > 0 && showLessonNotes) {
        <div class="w-100">
          <div class="kip-note-dropdown__accordion text-overline w-100 d-flex justify-content-between"
            [class.kip-note-dropdown__accordion--expanded]="studentNote.lessonNote.expanded"
            (click)="studentNote.lessonNote.expanded = !studentNote.lessonNote.expanded">
            <div class="user-select-none cursor-pointer">
              <span>Activity Notes</span>
              <fa-icon class="ms-2 kip-note-dropdown__accordion--hover small" [icon]="'activity' | noteCategoryIcon" />
              <span class=" kip-note-dropdown__accordion--hover ms-1">{{
                studentNote.lessonNote.internalActivityNotes.length }}</span>
            </div>
            <fa-icon [fixedWidth]="true"
              [icon]="studentNote.lessonNote.expanded ? icons.angle.up.regular : icons.angle.down.regular" />
          </div>
          @if (studentNote.lessonNote.expanded) {
          <div>
            @for (activityNote of studentNote.lessonNote.internalActivityNotes; track activityNote) {
            <div class="kip-note-dropdown__description kip-note-dropdown__description--activity"
              (click)="editGeneralNote(activityNote.note)">
              @if (activityNote.activityName) {
              <div class="text-uppercase text-overline mb-2">
                {{ activityNote.activityName}}
              </div>
              }
              <kip-emoji-message [message]="activityNote.note.note.description" />
            </div>
            }
          </div>
          }
        </div>
        }
        @if (studentNote.lessonNote.lessonId !== lessonId) {
        <div class="kip-note-dropdown__footer d-flex flex-column">
          @if (!studentNote.lessonNote.internalLessonNote?.updatedOn) {
          <div>
            <fa-icon class="me-1" [icon]="icons.tutor.solid" />
            {{ studentNote.lessonNote.tutor }} at {{ studentNote.date | dateTime: 'h:mm a' }}
          </div>
          }
          @if (studentNote.lessonNote.internalLessonNote?.updatedOn; as updatedOn) {
          <div>
            <fa-icon class="me-1" [icon]="icons.rename" />
            Edited by {{ studentNote.lessonNote.internalLessonNote?.updatedByUser }}
            {{ updatedOn | dateTime: 'D/MM/YY, h:mma' }}
          </div>
          }
        </div>
        }
      </div>
      }
      @if (studentNote.note) {
      <kip-student-note-dropdown-general [studentNote]="studentNote.note" (click)="editGeneralNote(studentNote.note)" />
      }
      @if (studentNote.assessmentNote) {
      <div class="kip-note-dropdown__entry kip-note-dropdown__notes kip-note-dropdown__notes--lesson">
        <div class="kip-note-dropdown__header kip-note-dropdown__header--system">
          <fa-icon class="kip-note-dropdown__header-icon" size="lg" [fixedWidth]="true" [icon]="icons.sparkles" />
          <span class="kip-note-dropdown__headline">
            Assessment
          </span>
          <span class="small text-nowrap text-dark">
            {{ studentNote.date | dateTime: defaultDateFormat }}
          </span>
        </div>
        <div class="w-100">
          <div class="d-flex flex-column w-100">
            <div class="kip-note-dropdown__accordion w-100 text-overline d-flex justify-content-between"
              [class.kip-note-dropdown__accordion--expanded]="studentNote.assessmentNote.expanded"
              (click)="studentNote.assessmentNote.expanded = !studentNote.assessmentNote.expanded">
              <div class="user-select-none cursor-pointer">
                <span>Results Notes</span>
                <fa-icon class="ms-2 kip-note-dropdown__accordion--hover small"
                  [icon]="'activity' | noteCategoryIcon" />
                <span class=" kip-note-dropdown__accordion--hover ms-1">
                  {{ assessmentNoteCount(studentNote.assessmentNote) }}
                </span>
              </div>
              <fa-icon [fixedWidth]="true"
                [icon]="studentNote.assessmentNote.expanded?icons.angle.up.regular:icons.angle.down.regular" />
            </div>
            @if (studentNote.assessmentNote.expanded) {
            <div>
              @for (resultNote of studentNote.assessmentNote.resultNotes; track resultNote) {
              @if (resultNote.internalNote) {
              <div class="kip-note-dropdown__description kip-note-dropdown__description--activity"
                (click)="editGeneralNote(resultNote.internalNote)">
                <div class="text-uppercase text-overline mb-2">
                  Internal {{ resultNote.resultType }}
                </div>
                <kip-emoji-message [message]="resultNote.internalNote.note.description" />
              </div>
              }
              @if (resultNote.parentNote) {
              <div class="kip-note-dropdown__description kip-note-dropdown__description--activity"
                (click)="editGeneralNote(resultNote.parentNote)">
                <div class="text-uppercase text-overline mb-2">
                  Parent {{ resultNote.resultType }}
                </div>
                <kip-emoji-message [message]="resultNote.parentNote.note.description" />
              </div>
              }
              }
            </div>
            }
          </div>
        </div>
      </div>
      }
      }
      } @else {
      <div class="bg-white mt-2 p-2 border rounded">
        @if (currentEditState === editState.EditingLesson) {
        <kip-tutor-edit-lesson-notes [activityNotes]="activityNotes" [allowInternalRanking]="false"
          [allowParentRanking]="false" [note]="lessonNote" (cancelledEditing)="cancelledLessonEditing($event)"
          (save)="updateLessonNote($event)" />
        }
        @if (currentEditState === editState.EditingSession) {
        <kip-tutor-edit-session-notes [allowInternalRanking]="false" [allowParentRanking]="false" [note]="sessionNote"
          (cancel)="clearEditState()" (save)="updateSessionNote($event)" />
        }
        @if (currentEditState === editState.EditingGeneral) {
        <kip-student-note-dropdown-edit class="w-100" [studentId]="studentId" [studentNote]="studentNoteToEdit"
          (cancelled)="cancelled()" (submitted)="submitted()" />
        }
      </div>
      }
      }
    </div>
  </div>
</div>