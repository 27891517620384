import { createAction, props } from '@ngrx/store';
import { FormDataType } from 'ngx-extended-pdf-viewer';
import { ScoringStatus, SimpleScore } from 'pipes-directives-lib';
import { LessonActivityPlanFile } from 'ui-common-lib';

import { LessonActivity, SessionActivityType } from '../../../shared';
import { ReadingSummary } from '../../models';

export interface ActivityContext {
  readonly lessonGuid: string;
  readonly sessionActivityType: SessionActivityType;
  readonly activity: LessonActivity;
}

export interface ActivityUpdateContext {
  readonly sessionActivityType: SessionActivityType;
  readonly activity: LessonActivity;
}

enum ActivityActionTypes {
  LoadMany = 'Tutor > Activity > Load Many',
  Update = 'Tutor > Activity > Update',
  RequestOpen = 'Tutor > Activity > Request Open',
  RequestClose = 'Tutor > Activity > Request Close',
  Percentage = 'Tutor > Activity > Percentage',
  ManualScore = 'Tutor > Activity > Manual Score',
  FirstAttempt = 'Tutor > Activity > First Attempt',
  SecondAttempt = 'Tutor > Activity > Second Attempt',
  WhiteboardDrawn = 'Tutor > Activity > Whiteboard Drawn',
  UpdateNotes = 'Tutor > Activity > Update Notes',
  UpdateFormFields = 'Tutor > Activity > Update Form Fields',
  VideoArchived = 'Tutor > Activity > Video Archived',
  TimeAlerted = 'Tutor > Activity > Time Alerted',
  UpdateCompletionTime = 'Tutor > Activity > Update CompletionTime',
  ActivityCompleted = 'Tutor > Lesson > Activity > Completed',
  ActivityFilesAdded = 'Tutor > Lesson > Activity > Files Added',
  ActivityFileRemoved = 'Tutor > Lesson > Activity > File Removed',
  ActivityFileRenamed = 'Tutor > Lesson > Activity > File Renamed',
  ActivityFirstAttempt = 'Tutor > Lesson > Activity > Timed Activity First Round Attempt',
  ActivityStartFirstAttempt = 'Tutor > Lesson > Activity > Timed Activity First Round Start',
  ActivitySecondAttempt = 'Tutor > Lesson > Activity > Timed Activity Second Attempt',
  ActivityStartSecondAttempt = 'Tutor > Lesson > Activity > Timed Activity Second Start'
}

export const activityFilesAddedAction = createAction(
  ActivityActionTypes.ActivityFilesAdded,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
    readonly files: LessonActivityPlanFile[];
  }>()
);

export const activityFileRemovedAction = createAction(
  ActivityActionTypes.ActivityFileRemoved,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
    readonly fileId: number;
  }>()
);

export const activityFileRenamedAction = createAction(
  ActivityActionTypes.ActivityFileRenamed,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
    readonly fileId: number;
    readonly fileName: string;
  }>()
);

export const updateCompletionTimeAction = createAction(
  ActivityActionTypes.UpdateCompletionTime,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
    readonly completionTime: number;
  }>()
);

export const activityCompletedAction = createAction(
  ActivityActionTypes.ActivityCompleted,
  props<{
    readonly activityGuid: string;
    readonly lessonGuid: string;
    readonly completedOn: Date | undefined;
    readonly completionTime: number;
  }>()
);

export const loadManyAction = createAction(
  ActivityActionTypes.LoadMany,
  props<{
    readonly contexts: ActivityContext[];
  }>()
);

export const updateFormFieldsAction = createAction(
  ActivityActionTypes.UpdateFormFields,
  props<{
    readonly activityGuid: string;
    readonly pdfFormFields: FormDataType;
    readonly pdfFormCalculations: ReadingSummary;
  }>()
);

export const updateNotesAction = createAction(
  ActivityActionTypes.UpdateNotes,
  props<{
    readonly activityGuid: string;
    readonly internalNote: string;
  }>()
);

export const updateAction = createAction(
  ActivityActionTypes.Update,
  props<{
    readonly lessonGuid: string;
    readonly activityContexts: readonly ActivityUpdateContext[];
  }>()
);

export const requestOpenAction = createAction(
  ActivityActionTypes.RequestOpen,
  props<{
    readonly lessonGuid: string;
    readonly previousLessonGuid: string;
    readonly activityGuid: string;
    readonly questionGuid: string | undefined;
    readonly pageGuid: string | undefined;
  }>()
);

export const requestCloseAction = createAction(
  ActivityActionTypes.RequestClose,
  props<{
    readonly lessonGuid: string;
    readonly previousLessonGuid: string;
    readonly activityGuid: string;
  }>()
);

export const percentageAction = createAction(
  ActivityActionTypes.Percentage,
  props<{
    readonly activityGuid: string;
    readonly percentage: number;
    readonly ageResult: number | null;
    readonly age: number | null;
  }>()
);

export const manualScoreAction = createAction(
  ActivityActionTypes.ManualScore,
  props<{
    readonly activityGuid: string;
    readonly correct: number;
    readonly total: number;
    readonly inProgress: boolean;
    readonly simpleScore: SimpleScore;
    readonly percentage: number | undefined;
    readonly ageResult: number | null;
    readonly age: number | null;
    readonly scoringStatus: ScoringStatus | null;
  }>()
);

export const whiteboardDrawnAction = createAction(
  ActivityActionTypes.WhiteboardDrawn,
  props<{
    readonly activityGuid: string;
  }>()
);

export const videoArchivedAction = createAction(
  ActivityActionTypes.VideoArchived,
  props<{
    readonly activityGuid: string;
    readonly videoGuid: string;
  }>()
);

export const timeAlertedAction = createAction(
  ActivityActionTypes.TimeAlerted,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly isWarning: boolean;
    readonly helpRequested: boolean;
    readonly minutesRemaining: number | undefined;
  }>()
);

export const timedActivityFirstAttempt = createAction(
  ActivityActionTypes.ActivityFirstAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly correct: number;
  }>()
);

export const timedActivityStartFirstAttempt = createAction(
  ActivityActionTypes.ActivityStartFirstAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly startDateTime: Date;
  }>()
);

export const timedActivitySecondAttempt = createAction(
  ActivityActionTypes.ActivitySecondAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly correct: number;
  }>()
);

export const timedActivityStartSecondAttempt = createAction(
  ActivityActionTypes.ActivityStartSecondAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly startDateTime: Date;
  }>()
);
