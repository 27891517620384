import { ScreenSize } from './screen-size';

export function determineScreenSize() {
    let screenSize = ScreenSize.Sm;
    if (window.matchMedia('(max-width: 40em)').matches) {
        screenSize = ScreenSize.Sm;
    } else if (window.matchMedia('(min-width: 40.063em)').matches) {
        screenSize = ScreenSize.Md;
    } else if (window.matchMedia('(min-width: 64.063em)').matches) {
        screenSize = ScreenSize.Lg;
    }
    if (window.matchMedia('(min-width: 90.063em').matches) {
        screenSize = ScreenSize.Xl;
    }

    return screenSize;
}
