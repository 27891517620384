import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { AssessmentResultSummary, ResultType } from '../../models';

@Component({
  selector: 'kip-student-assessment-result',
  templateUrl: './student-assessment-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentAssessmentResultComponent {

  readonly icons = Icons;

  get earlyReading() {
    return this.assessmentResults ? this.assessmentResults.results.find(x => x.resultType === ResultType.EarlyReading) : null;
  }

  get readingAge() {
    return this.assessmentResults ? this.assessmentResults.results.find(x => x.resultType === ResultType.ReadingAge) : null;
  }

  get comprehension() {
    return this.assessmentResults ? this.assessmentResults.results.find(x => x.resultType === ResultType.Comprehension) : null;
  }

  get spelling() {
    return this.assessmentResults ? this.assessmentResults.results.find(x => x.resultType === ResultType.Spelling) : null;
  }

  get mathsEog() {
    return this.assessmentResults ? this.assessmentResults.results.find(x => x.resultType === ResultType.MathsEOG) : null;
  }

  @Input({ required: true }) assessmentResults: AssessmentResultSummary | undefined;
}
