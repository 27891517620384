import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-santa-claus-a',
  templateUrl: './santa-claus-a.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSantaClausAComponent implements IconComponent {

}
