@if (isLoading) {
<div class="d-flex justify-content-center py-5">
  <kip-spinner />
</div>
}
@if (!isLoading) {
<button aria-label="Close" class="btn btn-link btn--icon m-3 float-end" type="button" (click)="cancel()">
  <fa-icon [icon]="icons.close.regular" />
</button>
<div class="container">
  @if (verificationSent) {
  <form #verifyMobileForm (submit)="onSubmit()">
    <h2 class="h2 mb-3 fw-normal text-primary">Verification Sent</h2>
    <p class="fs-3 mb-4">We have sent a code to the mobile ending {{mobileEnding}}.</p>
    <p class="fs-3 mb-4 fw-bold">Please enter your verification code below.</p>
    <div class="d-flex mb-3">
      <input class="form-control" maxlength="1" pattern="[0-9]" type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)" (paste)="onPaste($event)">
      <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)">
      <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)">
      <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)">
      <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)">
      <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
        (keydown)="onKey($event)">
    </div>
    @if (errorMessage) {
    <p class="fs-3 mb-4 text-danger">{{errorMessage}}</p>
    }
    <button class="btn kip-btn text-btn btn-primary shadow-sm border-0 ms-auto" type="submit">Submit</button>
  </form>
  }
  <div>
    @if (!verificationSent) {
    <form #verifyMobileLastFourForm (submit)="verify()">
      <h2 class="h2 mb-3 fw-normal text-primary">Verify Account</h2>
      <p class="fs-3 mb-4">Before updating your payment details we need to verify that you are the
        owner of this
        account.</p>
      <p class="fs-3 mb-4">Please enter the last 4 digits of your mobile then click below to send a
        verification
        code.</p>
      <div class="d-flex mb-3">
        <input class="form-control" maxlength="1" pattern="[0-9]" type="tel" (input)="onInput($event)"
          (keydown)="onKey($event)" (paste)="onPaste($event)">
        <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
          (keydown)="onKey($event)">
        <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
          (keydown)="onKey($event)">
        <input class="form-control" maxlength="1" pattern="[0-9]" required type="tel" (input)="onInput($event)"
          (keydown)="onKey($event)">
      </div>
      @if (errorMessage) {
      <p class="text-center text-danger">{{errorMessage}}</p>
      }
      <button class="btn text-btn btn-primary shadow-sm border-0 w-100 py-2 mt-2" type="submit"
        [disabled]="updatePaymentRetryCounter$">
        @if(!updatePaymentRetryCounter$) {
        Verify account
        }
        @if(updatePaymentRetryCounter$) {
        Resend in {{updatePaymentRetryCounter$ | async | date:'mm:ss'}}
        }
      </button>
    </form>
    }
  </div>
</div>
}