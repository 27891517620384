import { Gender } from '../../models';

interface GenderTitle {
  id: Gender;
  title: string;
}

export function convertGender(gender: Gender) {
  const value = Gender[gender];
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return '';
}

export const genders: GenderTitle[] = [
  {
    id: Gender.Female,
    title: convertGender(Gender.Female)
  },
  {
    id: Gender.Male,
    title: convertGender(Gender.Male)
  },
  {
    id: Gender.Unspecified,
    title: convertGender(Gender.Unspecified)
  }
];
