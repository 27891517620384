import { ScoringStatus, SimpleScore } from 'pipes-directives-lib';

import { convertSimpleToPercentage } from './convert-simple-to-percentage';
import { ScoringStrategy } from './scoring-strategy';
import { ScoringStrategyDefault } from './scoring-strategy-default';

export class Scoring {

  readonly #isSimpleScoring: boolean;

  #percentage: number | undefined;
  #isInvalid = false;
  #correct: number;
  #total: number;
  #inProgress = false;
  #scoringStatus: ScoringStatus = ScoringStatus.Scoring;
  #simpleScore: SimpleScore;
  #initialCorrect: number;
  #initialTotal: number;
  #initialSimpleScore: SimpleScore;
  #initialInProgress = false;
  #initialScoringStatus: ScoringStatus = ScoringStatus.Scoring;

  public strategy: ScoringStrategy;

  constructor(
    isSimpleScoring: boolean,
    correct: number,
    total: number,
    inProgress: boolean,
    simpleScore: SimpleScore,
    private readonly onChange?: () => void,
    scoringStatus: ScoringStatus = ScoringStatus.Scoring,
    strategy: ScoringStrategy = new ScoringStrategyDefault()
  ) {
    this.#isSimpleScoring = isSimpleScoring;
    this.#correct = correct;
    this.#total = total;
    this.#simpleScore = simpleScore;
    this.#initialCorrect = correct;
    this.#initialTotal = total;
    this.#initialSimpleScore = simpleScore;
    this.#inProgress = inProgress;
    this.#initialInProgress = inProgress;
    this.#scoringStatus = scoringStatus;
    this.#initialScoringStatus = scoringStatus;
    this.strategy = strategy;

    this.#calculatePercentage();
  }

  set correct(value: number) {
    if (this.#correct !== value) {
      this.#correct = value;
      this.#calculatePercentage();
      if (this.onChange) {
        this.onChange();
      }
    }
  }

  get correct() {
    return this.#correct;
  }

  set inProgress(value: boolean) {
    if (this.#inProgress !== value) {
      this.#inProgress = value;
      this.#simpleScore = SimpleScore.None;
      this.#total = this.#initialTotal;
      this.#correct = 0;
      this.#calculatePercentage();
      if (this.onChange) {
        this.onChange();
      }
    }
  }

  get inProgress() {
    return this.#inProgress;
  }

  set scoringStatus(status: ScoringStatus) {
    if (this.#scoringStatus !== status) {
      this.#simpleScore = SimpleScore.None;
      this.#total = this.#initialTotal;
      this.#correct = 0;
      this.#scoringStatus = status;

      this.#calculatePercentage();
      if (this.onChange) {
        this.onChange();
      }
    }
  }

  get scoringStatus() {
    return this.#scoringStatus;
  }

  set total(value: number) {
    if (this.#total !== value) {
      this.#total = value;
      this.#calculatePercentage();
      if (this.onChange) {
        this.onChange();
      }
    }
  }

  get total() {
    return this.#total;
  }

  get percentage() {
    return this.#percentage;
  }

  get isInvalid() {
    return this.#isInvalid;
  }

  get simpleScore() {
    return this.#simpleScore;
  }

  get isSimpleScoring() {
    return this.#isSimpleScoring;
  }

  get isDirty() {
    return this.#initialCorrect !== this.#correct ||
      this.#initialTotal !== this.#total ||
      this.#initialSimpleScore !== this.#simpleScore ||
      this.#initialInProgress !== this.#inProgress ||
      this.#initialScoringStatus !== this.scoringStatus;
  }

  get isA() {
    return this.#simpleScore === SimpleScore.A;
  }

  get isB() {
    return this.#simpleScore === SimpleScore.B;
  }

  get isC() {
    return this.#simpleScore === SimpleScore.C;
  }

  setA() {
    this.#simpleScore = this.#simpleScore === SimpleScore.A ? SimpleScore.None : SimpleScore.A;
    this.#calculatePercentage();
    if (this.onChange) {
      this.onChange();
    }
  }

  setB() {
    this.#simpleScore = this.#simpleScore === SimpleScore.B ? SimpleScore.None : SimpleScore.B;
    this.#calculatePercentage();
    if (this.onChange) {
      this.onChange();
    }
  }

  setC() {
    this.#simpleScore = this.#simpleScore === SimpleScore.C ? SimpleScore.None : SimpleScore.C;
    this.#calculatePercentage();
    if (this.onChange) {
      this.onChange();
    }
  }

  reset() {
    if (this.#simpleScore === null) {
      this.#simpleScore = SimpleScore.None;
    }
    this.#initialCorrect = this.#correct;
    this.#initialTotal = this.#total;
    this.#initialSimpleScore = this.#simpleScore;
    this.#initialInProgress = this.#inProgress;
    this.#initialScoringStatus = this.#scoringStatus;
    this.#markInvalid();
  }

  #calculatePercentage() {
    if (this.strategy.shouldCalculatePercentage(this)) {

      this.#percentage = this.isDirty ? 0 : undefined;
      if (this.#correct === undefined || this.#correct === null) {
        this.#correct = 0;
      }

      if (this.#total === undefined || this.#total === null) {
        this.#total = 0;
      }

      if (this.#simpleScore !== SimpleScore.None && this.#simpleScore !== null) {

        this.#percentage = convertSimpleToPercentage(this.#simpleScore);

        this.#correct = this.#percentage !== undefined ? Math.round(this.#total * this.#percentage / 100) : 0;
        this.#markInvalid();
        return;
      }
      if (!this.#isSimpleScoring && this.#correct > 0 && this.#total !== 0) {
        this.#percentage = Number.parseInt((100 * this.#correct / this.#total).toString(), 10);
      }
    } else {
      this.#percentage = undefined;
    }

    this.#markInvalid();
  }

  #markInvalid() {
    this.#isInvalid = this.strategy.shouldMarkInvalid(this) && (this.#correct < 0 || this.#correct > 1000 || this.#percentage === undefined ||
      this.#percentage < 0 || this.#percentage > 100 ||
      this.#total < 0 || this.#total > 1000);
  }
}

