/* eslint-disable angular-file-naming/service-filename-suffix */

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormArray, FormControl, FormGroup, UntypedFormBuilder, ValidationErrors } from '@angular/forms';

export const serverError = 'serverError';

// reference link for the below implementation
// https://ruanbeukes.net/angular-typesafe-reactive-forms/
// https://embed.plnkr.co/kzCgt7pL45TFQZ9OzSpf/

type FormGroupControlsOf<T> = {
  [P in keyof T]: FormArray | FormControl | FormGroup;
};

export interface ErrorCodes {
  [propName: string]: string[];
}

export abstract class FormGroupTypeSafe<T extends unknown | { [key: string]: any }> extends FormGroup {
  // give the value a custom type s
  override value!: T;

  // create helper methods to achieve this syntax eg: this.form.getSafe(x => x.heroName).patchValue('Peter')

  abstract patchSafe(value: Partial<T>, options?: {
    onlySelf?: boolean | undefined;
    emitEvent?: boolean | undefined;
  }  ): void;
  abstract getSafe<T2>(propertyFunction: (typeVal: T) => T2): FormControl<T2>;
  abstract getSafeForm<T2>(propertyFunction: (typeVal: T) => T2): FormGroupTypeSafe<T2>;
  abstract getSafeArray<T2>(propertyFunction: (typeVal: T) => T2): FormArray;
  abstract setControlSafe(propertyFunction: (typeVal: T) => any, control: FormControl<T>): void;
  abstract handleServerErrors(errors: unknown, handledHttpCodes?: HttpStatusCode[]): ValidationErrors | null;
  abstract invalidControls(): string[];
  // If you need more function implement declare them here but implement them on FormBuilderTypeSafe.group instantiation.
}

export function findInvalidControlsRecursive(formToInvestigate: FormArray | FormGroup): string[] {
  const invalidControls: string[] = [];
  const recursiveFunc = (form: FormArray | FormGroup) => {
    for (const field of Object.keys(form.controls)) {
      const control = form.get(field);
      if (control) {
        if (control.invalid) { invalidControls.push(field); }
        if (control instanceof FormGroup || control instanceof FormArray) {
          recursiveFunc(control);
        }
      }
    }
  };
  recursiveFunc(formToInvestigate);

  if (formToInvestigate.invalid && invalidControls.length === 0) {
    invalidControls.push('formValidation');
  }

  return invalidControls;
}

@Injectable()
export class FormBuilderTypeSafe extends UntypedFormBuilder {
  // override group to be type safe
  override group<T extends unknown | { [key: string]: any }>(controlsConfig: FormGroupControlsOf<T>,
    extra?: AbstractControlOptions | null  ): FormGroupTypeSafe<T> {

    // instantiate group from angular type
    const gr = super.group(controlsConfig, extra) as FormGroupTypeSafe<T>;

    const getPropertyName = (propertyFunction: (typeVal: T) => any): string =>
      propertyFunction.toString().slice(Math.max(0, propertyFunction.toString().indexOf('.') + 1));

    if (gr) {

      gr.patchSafe = (value: Partial<T>, options?: {
        onlySelf?: boolean | undefined;
        emitEvent?: boolean | undefined;
      }  ): void => {
        gr.patchValue(value, options);
      };

      // implement getSafe method
      gr.getSafe = (propertyFunction: (typeVal: T) => any): FormControl => {
        const stringValue = getPropertyName(propertyFunction);
        return gr.get(stringValue) as FormControl;
      };

      gr.getSafeForm = (propertyFunction: (typeVal: T) => any): FormGroupTypeSafe<any> => {
        const stringValue = getPropertyName(propertyFunction);
        return gr.get(stringValue) as FormGroupTypeSafe<any>;
      };

      gr.getSafeArray = (propertyFunction: (typeVal: T) => any): FormArray => {
        const stringValue = getPropertyName(propertyFunction);
        return gr.get(stringValue) as FormArray;
      };

      // implement setControlSafe
      gr.setControlSafe = (propertyFunction: (typeVal: T) => any, control: FormControl<T>) => {
        const stringValue = getPropertyName(propertyFunction);
        gr.setControl(stringValue, control);
      };

      // implement more functions as needed

      gr.invalidControls = () => findInvalidControlsRecursive(gr);

      // this assumes fluent validation format

      gr.handleServerErrors = (error: unknown, handledHttpCodes?: HttpStatusCode[]): ValidationErrors | null => {
        let handledCodes = [HttpStatusCode.BadRequest];
        if (handledHttpCodes) {
          handledCodes = [...handledCodes, ...handledHttpCodes];
        }

        if (error instanceof HttpErrorResponse) {
          if (handledCodes.includes(error.status)) {
            const errorCodes: ErrorCodes = error.error.errors;
            let formGroupLevelErrors: string[] = [];
            let hasFormGroupLevelError = false;
            for (const errorCode in errorCodes) {

              const formattedControlNames: string[] = [];

              for (const code of errorCode.split('.')) {
                const formattedName = code.charAt(0).toLowerCase() + code.slice(1);
                formattedControlNames.push(formattedName);
              }

              const controlName = formattedControlNames.join('.');

              const formControl = gr.get(controlName);

              if (formControl) {
                const validationErrors: { [key: string]: string[] } = {};
                validationErrors[serverError] = errorCodes[errorCode];
                formControl.setErrors(validationErrors);
              } else {
                formGroupLevelErrors = [...formGroupLevelErrors, ...errorCodes[errorCode]];
                hasFormGroupLevelError = true;
              }
            }

            if (hasFormGroupLevelError) {
              const validationErrors: { [key: string]: string[] } = {};
              validationErrors[serverError] = formGroupLevelErrors;
              gr.setErrors(validationErrors);

              return validationErrors;
            }
          } else {
            const validationErrors: { [key: string]: string[] } = {};
            if (error.status === HttpStatusCode.NotFound) {
              validationErrors[serverError] = ['Record to change could not be found, please contact your administrator'];
            } else if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
              validationErrors[serverError] = ['You do not have the permissions to perform this action, please contact your administrator'];
            } else {
              validationErrors[serverError] = ['Something went wrong, please contact your administrator'];
            }
            gr.setErrors(validationErrors);
            return validationErrors;
          }
        }

        return null;
      };
    }
    return gr;
  }
}

// TODO: This is a temporary fix to mark all control as touched
// to trigger form validation, once we move to angular 8.2 +
// we can use the default method markAllAsTouched
// provided in form control
// https://angular.io/api/forms/AbstractControl#markAllAsTouched
export function markControlsAsTouched(group: FormArray | FormGroup) {
  const keys = Object.keys(group.controls);
  for (const key of keys) {
    const abstractControl = (group.controls as any)[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      markControlsAsTouched(abstractControl);
    } else {
      abstractControl.markAsTouched();
    }
  }
}

export function markControlsAsValid(group: FormArray | FormGroup) {
  const keys = Object.keys(group.controls);
  for (const key of keys) {
    const abstractControl = (group.controls as any)[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      markControlsAsValid(abstractControl);
    } else {
      abstractControl.setErrors(null);
    }
  }
}
