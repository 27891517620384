import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-climbing-skill-f',
  templateUrl: './climbing-skill-f.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconClimbingSkillFComponent implements IconComponent {

}
