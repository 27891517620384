import { Age, AgeResult, ReadingTest } from '../models';
import { convertNumberToAge } from './convert-number-to-age';

export function determineAgeReadingTest(words: number | null,
  readingTests: readonly ReadingTest[]): { age: Age | undefined, result: AgeResult } | undefined {

  if (words !== null) {
    const readingTest = readingTests.find(r => r.wordsMin <= words && words <= r.wordsMax);
    if (readingTest) {
      let age: Age | undefined;
      if (readingTest.age) {
        age = convertNumberToAge(readingTest.age);
      }
      return { age: age, result: readingTest.ageResult };
    }
  }
  return undefined;
}
