<form [formGroup]="noteForm" (ngSubmit)="submit()">
  <div class="w-100 d-flex align-items-baseline mb-2">
    <div class="text-overline--secondary">
      {{ action }} Note
    </div>
    @if (id.value > 0) {
    <button class="ms-auto mb-0 btn btn-link link-danger small" type="button" (click)="delete()">Delete note</button>
    }
  </div>

  @if (studentNote && studentNote.lessonInfo) {
  <div class="small rounded p-1" [ngClass]="studentNote.lessonInfo.internal ? 'alert-info' : 'alert-warning'">
    <fa-icon class="me-2" [fixedWidth]="true"
      [icon]="studentNote.lessonInfo.internal ? icons.eyes.eye : icons.warning.solid" />
    <span>This note is {{ studentNote.lessonInfo.internal ? 'only viewable to internal staff': 'visible in Parent
      Portal'}}
    </span>
  </div>
  <div class="d-flex flex-column mb-n3 mt-2 p-1">
    <span>
      <fa-icon class="me-2" [fixedWidth]="true" [icon]="studentNote.note.noteCategory.name | noteCategoryIcon" />
      {{ studentNote.note.noteCategory.name }} note
    </span>
    <span class="mt-1">
      <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.time.regular" />
      {{ studentNote.lessonInfo.date | dateTime }}
    </span>
    @if (studentNote.lessonInfo.subject) {
    <span class="mt-1">
      <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.subject.other" />
      {{ studentNote.lessonInfo.subject }}
    </span>
    }
    @if (studentNote.lessonInfo.activityName) {
    <span class="mt-1">
      <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.activityType.unknown" />
      {{ studentNote.lessonInfo.activityName }}
    </span>
    }
  </div>
  }

  @if (!locked) {
  <kip-form-control-select id="noteCategory" label="Note Category" [control]="noteCategoryId"
    [values]="noteCategories" />
  }
  <kip-form-control-emoji errorMessage="Please provide a valid note description" id="emoji" label=""
    [control]="description" />
  @if (studentNote && studentNote.lessonInfo && !studentNote.lessonInfo.internal) {
  <div class="mb-3 d-flex align-items-center">
    <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
    <div class="pe-3">This note will be visible in the parent portal.</div>
  </div>
  }
  @if (!locked) {
  <kip-form-control-checkbox id="pinNote" label="Pin Note" [control]="pinned" />
  }
  <kip-form-error [formGroup]="noteForm" />
  <div class="w-100 d-flex">
    <button class="btn btn-sm btn-link" type="button" (click)="cancelled.emit()">Cancel</button>
    <button class="btn btn-sm btn-primary ms-auto" type="submit">{{ buttonText }}</button>
  </div>
</form>