@if (rootNode && rootNode.hasChildren && !rootNode.accessBlocked) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
  [class.kip-activity--expanded]="expand" (click)="expand = !expand">
  <div class="d-flex align-items-center">
    <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
        [icon]="expand ? icons.folder.open : icons.calendar.expand" /> {{rootNode.name | currencySwap : regionId
      }}</span>
    @if (rootNode.unit) {
    <span class="me-2">{{ rootNode.unit }}</span>
    }
    @if (rootNode.kipPointsScaleFactor) {
    <span class="me-2">
      <fa-icon [icon]="icons.points.star.regular" /> x {{ rootNode.kipPointsScaleFactor }}
    </span>
    }
    @if (rootNode.regions && rootNode.regions.length > 0) {
    <kip-region-flag class="me-2" [regionId]="regionId" />
    }
    <kip-diy-rule [diyRule]="rootNode" />
  </div>
  <div class="d-flex align-items-center">
    @if (showStatsAndHistory) {
    <kip-stats [stats]="rootNode.stats" />
    }
    @if (rootNode.warningTimeMinutes || rootNode.maxAllowedTimeMinutes) {
    <span class="me-2"
      [ngbTooltip]="rootNode.warningTimeMinutes | warningMaxTime : rootNode.warningTimeTutorOnly : rootNode.maxAllowedTimeMinutes">
      <fa-icon [icon]="icons.time.regular" />
    </span>
    }
    <fa-icon [fixedWidth]="true" [icon]="expand ? icons.chevrons.down : icons.chevrons.right" />
  </div>
</div>
}

@if (rootNode && !rootNode.hasChildren) {
<div class="kip-activity cursor-pointer d-flex flex-row align-items-center justify-content-between p-2"
  [id]="'CN' + rootNode.id.toString()" (click)="selectActivity(rootNode, $event)">
  <div class="flex-row text-start d-flex align-items-center">
    @if (isActivity && !singleSelection) {
    <input class="form-check-input mt-0 me-2" type="checkbox" [(ngModel)]="rootNode.selected">
    }
    {{rootNode.name | currencySwap : regionId }}
    @if (rootNode.description) {
    <span class="text-muted"> - {{rootNode.description | currencySwap : regionId}}</span>
    }
    @if (rootNode.unit) {
    <span class="ms-1 kip-badge kip-badge--outline kip-badge--small">{{ rootNode.unit }}</span>
    }
  </div>
  <div class="d-flex flex-column flex-xxl-row">
    @if (showStatsAndHistory) {
    <kip-activity-history class="me-2" [contentHistory]="rootNode.history" />
    }
    <div class="d-flex align-items-center">
      @if (rootNode.warningTimeMinutes || rootNode.maxAllowedTimeMinutes) {
      <span class="me-2 d-none d-xxl-inline"
        [ngbTooltip]="rootNode.warningTimeMinutes | warningMaxTime : rootNode.warningTimeTutorOnly : rootNode.maxAllowedTimeMinutes">
        <fa-icon [icon]="icons.time.regular" />
      </span>
      }
      @if (rootNode.kipPointsScaleFactor) {
      <span class="me-2">
        <fa-icon [icon]="icons.points.star.regular" /> x {{ rootNode.kipPointsScaleFactor }}
      </span>
      }
      @if (rootNode.linkedActivities && rootNode.linkedActivities.length > 0) {
      <span class="me-2" placement="left" [ngbTooltip]="tipContent">
        <fa-icon [icon]="icons.activityAssociation.linked" />
        <ng-template #tipContent>
          @for (linkedActivity of rootNode.linkedActivities; track linkedActivity) {
          <div>
            <fa-icon class="me-1" [icon]="linkedActivity.isHomework?icons.homework:icons.lesson" />
            <fa-icon class="me-1"
              [icon]="linkedActivity.isManual?icons.activityType.manual:icons.activityType.computer" />
            {{ linkedActivity.name }}
          </div>
          }
        </ng-template>
      </span>
      }
      @if (rootNode.regions && rootNode.regions.length > 0) {
      <kip-region-flag class="me-2" [regionId]="regionId" />
      }
      <ng-template #htmlContent>
        @if (rootNode.curriculum) {
        @for (curriculum of rootNode.curriculum; track curriculum) {
        <div>{{ curriculum}}</div>
        }
        }
      </ng-template>
      @if (rootNode.curriculum) {
      <fa-icon class="me-2" [icon]="icons.curriculum" [ngbTooltip]="htmlContent" />
      }
      @if (isActivity && !singleSelection && allowPreview) {
      <fa-icon class="py-0 px-2 mx-2 btn kip-btn border-top border-bottom text-primary" [icon]="icons.preview.regular"
        (click)="previewActivity(rootNode, $event)" />
      }
      @if (isActivity && !singleSelection) {
      <input type="checkbox" [(ngModel)]="rootNode.selected">
      }
    </div>
  </div>
</div>
}

@if (rootNode && rootNode.hasChildren && expand && !rootNode.accessBlocked) {
@for (node of subNodes; track node) {
<kip-activity class="kip-activity-block d-block" [allowPreview]="allowPreview" [regionId]="regionId"
  [resetTime]="resetTime" [rootNode]="node" [showStatsAndHistory]="showStatsAndHistory"
  [singleSelection]="singleSelection" (showPreview)="showPreview.emit($event)" />
}
}