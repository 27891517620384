import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { DropInMessagingService, MessagingService } from '../../services';
import * as fromActivity from '../activity';
import * as fromLesson from '../lesson';
import { getEventsRequest } from '../log';
import * as fromQuestion from '../question';
import * as fromSession from '../session';
import { TutorState } from '../state';
import * as fromWhiteboard from '../whiteboard';

@Injectable()
export class MessagingEffects {

  readonly #actions$ = inject(Actions);
  readonly #dropInMessagingService = inject(DropInMessagingService);
  readonly #store = inject(Store<TutorState>);
  readonly #messagingService = inject(MessagingService);

  openSession$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.openAction, fromSession.loadAction, fromSession.reconnectAction),
      concatLatestFrom(
        () => this.#store.select(fromSession.selectSelectedSession)
      ),
      switchMap(([_action, session]) => {
        if (session) {
          return this.#messagingService.sessionOpened(session.sessionId).pipe(
            switchMap(() => this.#dropInMessagingService.sessionOpened(session.sessionId)),
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  addDropInLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.addDropInLessonAction),
      switchMap(action => {
        return this.#messagingService.dropInSessionLessonOpened(action.sessionId, action.lessonId).pipe(
          switchMap(() => EMPTY)
        );
      })
    );
  }, { dispatch: false });

  addDIY$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.addDIYAction),
      switchMap(action => this.#messagingService.addDIY(action.lessonId, action.isHomework, getEventsRequest()).pipe(
        switchMap(() =>
          EMPTY)
      ))
    );
  }, { dispatch: false });

  subscribeFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.subscribeFailureAction),
      switchMap(action => this.#messagingService.subscribeFailure(action.lessonGuid, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenSharePublishFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.screenSharePublishFailureAction),
      switchMap(action => this.#messagingService.screenSharePublishFailure(action.sessionId, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenShareStreamDestroyed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.screenShareStreamDestroyedAction),
      switchMap(action => this.#messagingService.screenShareStreamDestroyed(action.sessionId, action.reason).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenSharePublishAllowed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.screenSharePublishAllowedAction),
      switchMap(action => this.#messagingService.screenSharePublishAllowed(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenSharePublishDenied$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.screenSharePublishDeniedAction),
      switchMap(action => this.#messagingService.screenSharePublishDenied(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.publishFailureAction),
      switchMap(action => this.#messagingService.publishFailure(action.sessionId, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  streamDestroyed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.streamDestroyedAction),
      switchMap(action => this.#messagingService.streamDestroyed(action.sessionId, action.reason).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishAllowed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.publishAllowedAction),
      switchMap(action => this.#messagingService.publishAllowed(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishDenied$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.publishDeniedAction),
      switchMap(action => this.#messagingService.publishDenied(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  closeSession$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.closeAction),
      switchMap(action => this.#messagingService.sessionClosed(action.sessionId).pipe(
        switchMap(() => EMPTY),
        finalize(() => {
          this.#messagingService.disconnect();
          this.#dropInMessagingService.disconnect();
        })
      ))
    );
  }, { dispatch: false });

  toggleOnline$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.toggleOnlineAction),
      switchMap(action => this.#messagingService.onlineToggled(action.lessonGuid, action.isOnline).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  skillBuilderRequested$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.skillBuilderRequestedAction),
      switchMap(action => this.#messagingService.skillbuilderRequested(action.lessonId, action.skillbuilderActivityId, getEventsRequest()).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  forceReload$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.forceReloadAction),
      switchMap(action => this.#messagingService.forceReload(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  forceReloadVideo$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.forceReloadVideoAction),
      switchMap(action => this.#messagingService.forceReloadVideo(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  pullFromLobby$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.pullFromLobbyAction),
      switchMap(action => this.#messagingService.pullFromLobby(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  toggleVideo$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.toggleVideoAction),
      switchMap(action => this.#messagingService.videoToggled(action.lessonGuid, action.publish).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  toggleAudio$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.toggleAudioAction),
      switchMap(action => this.#messagingService.audioToggled(action.lessonGuid, action.publish).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  requestWhiteboardOpen$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.requestOpenAction),
      concatLatestFrom(() => [this.#store.select(fromLesson.selectObservingLesson), this.#store.select(fromLesson.selectTeachingLesson)]),
      switchMap(([action, observeLesson, teachLesson]) => {

        const lesson = teachLesson ?? observeLesson;

        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          this.#messagingService.requestOpenWhiteboard(lesson.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid, previousLessonGuid).pipe(
            switchMap(() =>
              EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  requestWhiteboardClose$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.requestCloseAction),
      concatLatestFrom(() => [
        this.#store.select(fromLesson.selectObservingLesson),
        this.#store.select(fromLesson.selectTeachingLesson)
      ]),
      switchMap(([action, observeLesson, teachLesson]) => {

        const lesson = teachLesson ?? observeLesson;

        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          this.#messagingService.requestCloseWhiteboard(lesson.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid, previousLessonGuid).pipe(
            switchMap(() =>
              EMPTY));
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });

  startTeaching$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.startTeachingAction, fromLesson.loadManyAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectTeachingLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.startTeaching(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  stopTeaching$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.stopTeachingAction),
      switchMap(action => this.#messagingService.stopTeaching(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  startTeachingClass$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.startTeachingClassAction),
      switchMap(action =>
        this.#messagingService.startTeachingClass(action.sessionId).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  stopTeachingClass$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.stopTeachingClassAction),
      switchMap(action => this.#messagingService.stopTeachingClass(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  broadcastingPublishFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.broadcastingPublishFailureAction),
      switchMap(action =>
        this.#messagingService.broadcastingPublishFailure(action.sessionId, action.name, action.message).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  broadcastingPublishDenied$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.broadcastingPublishDeniedAction),
      switchMap(action =>
        this.#messagingService.broadcastingPublishDenied(action.sessionId).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  startBroadcastingObservers$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.startBroadcastingObserversAction),
      switchMap(action =>
        this.#messagingService.startBroadcastingObservers(action.sessionId, action.streamId).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  stopBroadcastingObservers$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.stopBroadcastingObserversAction),
      switchMap(action => this.#messagingService.stopBroadcastingObservers(action.sessionId).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  installApp$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.installAppAction),
      switchMap(action => this.#messagingService.installApp(action.lessonGuid).pipe(
        switchMap(() =>
          EMPTY)
      ))
    );
  }, { dispatch: false });

  startObserving$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.startObservingAction, fromLesson.loadManyAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectObservingLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.startObserving(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  stopObserving$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.stopObservingAction),
      switchMap(action => this.#messagingService.stopObserving(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  acknowledgeHelp$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.acknowledgeHelpAction),
      switchMap(action => this.#messagingService.helpAcknowledged(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  clearHelp$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.clearHelpAction),
      switchMap(action => this.#messagingService.helpCleared(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  updateFormFields$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.updateFormFieldsAction),
      switchMap(action => this.#messagingService.updateFormFields(action.activityGuid, action.pdfFormFields, action.pdfFormCalculations).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  requestOpenActivity$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.requestOpenAction),
      switchMap(action => {
        return this.#messagingService.requestOpenActivity(action.lessonGuid, action.activityGuid, action.questionGuid, action.previousLessonGuid, action.pageGuid).pipe(
          switchMap(() => EMPTY));
      }
      )
    );
  }, { dispatch: false });

  requestCloseActivity$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.requestCloseAction),
      switchMap(action => this.#messagingService.requestCloseActivity(action.lessonGuid, action.activityGuid, action.previousLessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  chat$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.chatAction),
      switchMap(action => this.#messagingService.chat(action.lessonGuid, action.message, action.chatGroup, action.warning).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  captionsError$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.disableCaptionsAction),
      switchMap(action => this.#messagingService.captionsError(action.sessionId, action.statusCode).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  systemChat$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.systemChatAction),
      switchMap(action => this.#messagingService.systemChat(action.lessonGuid, action.message, action.chatGroup).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  resetPassword$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.resetPasswordAction),
      switchMap(action => this.#messagingService.resetPassword(action.lessonGuid, action.password).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  whiteboardSetGridType$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.sendGridTypeAction),
      switchMap(action => this.#messagingService.whiteboardGridType(action.lessonGuid, action.whiteboardGuidKey,
        action.gridType, action.broadcastLessonGuid).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  whiteboardSend$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.sendAction),
      switchMap(action => this.#messagingService.whiteboardEvent(action.lessonGuid, action.whiteboardGuidKey,
        action.event, action.broadcastLessonGuid).pipe(
          switchMap(() =>
            [
              fromActivity.whiteboardDrawnAction({ activityGuid: action.whiteboardGuidKey.activityGuid }),
              fromQuestion.whiteboardDrawnAction({ questionGuid: action.whiteboardGuidKey.pageGuid })
            ])
        ))
    );
  });

  lessonAttend$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.attendLessonAction),
      switchMap(action => this.#messagingService.attendLesson(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  toggleLessonDoItYourself$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.toggleLessonDoItYourselfAction),
      switchMap(action => this.#messagingService.lessonDoItYourselfToggle(action.lessonGuid, action.doItYourself).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  lessonDefer$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.deferLessonAction),
      switchMap(action => this.#messagingService.deferLesson(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  getLastWeeksHomeworkPreviousLessonGuidFromAction(previousLessonGuid: string | undefined, lessonGuid: string) {
    if (lessonGuid === previousLessonGuid) {
      return lessonGuid;
    }
    return undefined;
  }
}
