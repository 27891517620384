import { createReducer, on } from '@ngrx/store';
import { TutorSettings } from 'ui-common-lib';

import * as TutorActions from './tutor-settings.actions';

const initialState: TutorSettings | undefined = undefined;

export const tutorSettingsReducer = createReducer<TutorSettings | undefined>(initialState,
  on(TutorActions.loadTutorSettingsAction, (_state, action): TutorSettings => action.tutorSettings)
);
