import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentWeakness } from '../../../../models';

@Component({
  selector: 'kip-weakness-card',
  templateUrl: './weakness-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeaknessCardComponent {

  readonly icons = Icons;

  @Input({ required: true })
  studentWeakness: StudentWeakness | undefined;
}
