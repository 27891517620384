import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-running-speed-boy-b',
  templateUrl: './running-speed-boy-b.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconRunningSpeedBoyBComponent implements IconComponent {

}
