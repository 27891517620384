import { KeyboardType } from './keyboard-type';

interface KeyboardLayoutObject {
  [key: string]: string[];
}

export enum SpecialKeys {
  Backspace = '{bksp}',
  Uppercase = '{shift}',
  Space = '{space}',
  Enter = '{enter}',
  Numbers = '{123}',
  Lowercase = '{shiftup}',
  Abc = '{abc}',
  Symbols = '{symbols}',
  Left = '{left}',
  Right = '{right}',
  Open = '{open}',
  Close = '{close}'
}

export const KeyboardLayouts: KeyboardLayoutObject = {
  [KeyboardType.AlphabeticalLowercase]: [
    `a b c d e f g h i j ${SpecialKeys.Backspace}`,
    `k l m n o p q r s ${SpecialKeys.Enter}`,
    `${SpecialKeys.Uppercase} t u v w x y z , . ${SpecialKeys.Uppercase}`,
    `${SpecialKeys.Numbers} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.AlphabeticalUppercase]: [
    `A B C D E F G H I J ${SpecialKeys.Backspace}`,
    `K L M N O P Q R S ${SpecialKeys.Enter}`,
    `${SpecialKeys.Lowercase} T U V W X Y Z ! ? ${SpecialKeys.Lowercase}`,
    `${SpecialKeys.Numbers} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.QwertyLowercase]: [
    `q w e r t y u i o p ${SpecialKeys.Backspace}`,
    `a s d f g h j k l ${SpecialKeys.Enter}`,
    `${SpecialKeys.Uppercase} z x c v b n m , . ${SpecialKeys.Uppercase}`,
    `${SpecialKeys.Numbers} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.QwertyUppercase]: [
    `Q W E R T Y U I O P ${SpecialKeys.Backspace}`,
    `A S D F G H J K L ${SpecialKeys.Enter}`,
    `${SpecialKeys.Lowercase} Z X C V B N M ! ? ${SpecialKeys.Lowercase}`,
    `${SpecialKeys.Numbers} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.NumberPad]: [
    `1 2 3 ${SpecialKeys.Backspace}`,
    '4 5 6 ',
    `7 8 9 ${SpecialKeys.Enter}`,
    `${SpecialKeys.Left} 0 ${SpecialKeys.Right} [${SpecialKeys.Close} ${SpecialKeys.Open}]`
  ], [KeyboardType.Radio]: [
    `${SpecialKeys.Left} ${SpecialKeys.Enter} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.Numbers]: [
    `1 2 3 4 5 6 7 8 9 0 ${SpecialKeys.Backspace}`,
    `@ # $ & * ( ) ' " ${SpecialKeys.Enter}`,
    `${SpecialKeys.Symbols} % - + = / ; : , . ${SpecialKeys.Symbols}`,
    `${SpecialKeys.Abc} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.Symbols]: [
    `1 2 3 4 5 6 7 8 9 0 ${SpecialKeys.Backspace}`,
    `£ € ¥ _ ^ [ ] { } ${SpecialKeys.Enter}`,
    `${SpecialKeys.Numbers} § | … \\ ß < > ! ? ${SpecialKeys.Numbers}`,
    `${SpecialKeys.Abc} ${SpecialKeys.Left} ${SpecialKeys.Space} ${SpecialKeys.Right} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ],
  [KeyboardType.NumberRow]: [
    `1 2 3 4 5 6 7 8 9 0 ${SpecialKeys.Backspace}`,
    `${SpecialKeys.Left} ${SpecialKeys.Right} ${SpecialKeys.Enter} ${SpecialKeys.Close} ${SpecialKeys.Open}`
  ]
};
