export function computerScore(correct: number | undefined, skipped: number | undefined, attempted: number | undefined, total: number | undefined,
  firstAttemptCorrectQuestions: number | undefined, secondAttemptCorrectQuestions: number | undefined): string {

  const firstAttempt = firstAttemptCorrectQuestions !== undefined && firstAttemptCorrectQuestions !== null ?
    `R1 : ${firstAttemptCorrectQuestions}` :
    '';
  const secondAttempt = secondAttemptCorrectQuestions !== undefined && secondAttemptCorrectQuestions !== null ?
    `, R2 : ${secondAttemptCorrectQuestions}` :
    '';

  const skippedText = skipped === undefined || skipped === 0 ? '' : `, S${skipped}`;

  return `${firstAttempt}${secondAttempt} ${correct ?? 0} of ${attempted ?? 0} (${total ?? 0}${skippedText})`;
}
