import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { PdfLibModule } from 'pdf-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { WhiteboardAudioComponent } from './audio/audio.component';
import { MEMENTO_MANAGER_FACTORY, WhiteboardMementoManager, WhiteboardMementoManagerFactory } from './models';
import { WhiteboardPagesComponent } from './pages/pages.component';
import { PinchZoomModule } from './pinch-zoom/pinch-zoom.module';
import {
  WhiteboardIconClearComponent, WhiteboardIconColorComponent, WhiteboardIconComponent,
  WhiteboardIconEraserComponent, WhiteboardIconGraphComponent, WhiteboardIconGridComponent,
  WhiteboardIconHighlighterComponent, WhiteboardIconLassoComponent,
  WhiteboardIconPenComponent, WhiteboardIconPrintComponent, WhiteboardIconRedoComponent,
  WhiteboardIconScrollComponent, WhiteboardIconShapeComponent, WhiteboardIconTextComponent, WhiteboardIconUndoComponent
} from './svg';
import { WhiteboardToolbarGraphModalComponent } from './tool-dialog/whiteboard-toolbar-graph-modal.component';
import { WhiteboardToolbarComponent } from './toolbar/toolbar.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';

function createMementoManagerFactory(): WhiteboardMementoManagerFactory {
  return () => new WhiteboardMementoManager();
}

@NgModule({
  imports: [
    CommonModule,
    FormsLibModule,
    IconLibModule,
    NgbTooltipModule,
    PdfLibModule,
    PinchZoomModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  declarations: [
    WhiteboardAudioComponent,
    WhiteboardComponent,
    WhiteboardIconClearComponent,
    WhiteboardIconColorComponent,
    WhiteboardIconComponent,
    WhiteboardIconEraserComponent,
    WhiteboardIconGraphComponent,
    WhiteboardIconGridComponent,
    WhiteboardIconHighlighterComponent,
    WhiteboardIconLassoComponent,
    WhiteboardIconPenComponent,
    WhiteboardIconPrintComponent,
    WhiteboardIconRedoComponent,
    WhiteboardIconScrollComponent,
    WhiteboardIconShapeComponent,
    WhiteboardIconTextComponent,
    WhiteboardIconUndoComponent,
    WhiteboardPagesComponent,
    WhiteboardToolbarComponent,
    WhiteboardToolbarGraphModalComponent
  ],
  exports: [
    WhiteboardAudioComponent,
    WhiteboardComponent,
    WhiteboardIconClearComponent,
    WhiteboardIconColorComponent,
    WhiteboardIconComponent,
    WhiteboardIconEraserComponent,
    WhiteboardIconGraphComponent,
    WhiteboardIconGridComponent,
    WhiteboardIconHighlighterComponent,
    WhiteboardIconLassoComponent,
    WhiteboardIconPenComponent,
    WhiteboardIconPrintComponent,
    WhiteboardIconRedoComponent,
    WhiteboardIconScrollComponent,
    WhiteboardIconShapeComponent,
    WhiteboardIconTextComponent,
    WhiteboardIconUndoComponent,
    WhiteboardPagesComponent,
    WhiteboardToolbarGraphModalComponent
  ]
})
export class WhiteboardModule {
  static forRoot(): ModuleWithProviders<WhiteboardModule> {
    return {
      ngModule: WhiteboardModule,
      providers: [
        {
          provide: MEMENTO_MANAGER_FACTORY,
          useFactory: createMementoManagerFactory,
          multi: false
        }
      ]
    };
  }
}
