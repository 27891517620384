import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimerService {

  private readonly _tick$ = new Subject<void>();

  private timerSubscription: Subscription | undefined;

  get tick$(): Observable<void> {
    return this._tick$;
  }

  startTimer() {
    if (!this.timerSubscription) {

      // Create timer and emit for every tick
      this.timerSubscription = timer(1000, 1000).subscribe(() => {
        this._tick$.next();
      });
    }
  }

  endTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }
}
