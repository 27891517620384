import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { LoadingState, StripeData } from '../models';

declare let Stripe: stripe.StripeStatic;

interface DirectDebitSummary {
  stripeElement: string;
  bankAccountName: string;
  emailAddress: string;
}

@Component({
  selector: 'kip-direct-debit',
  templateUrl: './direct-debit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectDebitComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #agreeToDirectDebit = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly directDebitSetupError = 'directDebitSetupError';
  readonly bankAccountValidationError = 'bankAccountValidationError';

  readonly stripeOptions: stripe.elements.ElementsOptions = {
    style: {
      base: {
        lineHeight: '24px',
        fontFamily: 'inherit',
        fontWeight: '400',
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontSize: '16px'
      }
    }
  };

  get stripeElement() {
    return this.directDebitSummaryForm.getSafe(x => x.stripeElement);
  }

  get bankAccountName() {
    return this.directDebitSummaryForm.getSafe(x => x.bankAccountName);
  }

  get emailAddress() {
    return this.directDebitSummaryForm.getSafe(x => x.emailAddress);
  }

  set agreeToDirectDebit(value: boolean) {
    this.#agreeToDirectDebit = value;
    this.validityChange.emit(!this.directDebitSummaryForm.invalid && value);
  }

  get agreeToDirectDebit() {
    return this.#agreeToDirectDebit;
  }

  directDebitSummaryForm: FormGroupTypeSafe<DirectDebitSummary>;
  useAlternateEmailAddress = false;

  stripe: stripe.Stripe | undefined;
  directDebitAccount: stripe.elements.Element | undefined;
  bankName = '';
  // This is needed as in the event binding we need to ref 'this'
  cardValidationHandler: stripe.elements.handler = this.#onChange.bind(this);

  @Input()
  accountName = '';

  @Input({ required: true })
  stripeAccountInfo: StripeData | undefined;

  @Input()
  accountEmail = '';

  @Input()
  submitExternal = false;

  @ViewChild('bankAccountInfo', { static: true }) bankAccountInfo: ElementRef<HTMLDivElement> | undefined;

  @Output()
  readonly setupIntent = new EventEmitter<string>();

  @Output()
  readonly loadingState = new EventEmitter<LoadingState>();

  @Output()
  readonly validityChange = new EventEmitter<boolean>();

  constructor() {
    /* eslint-disable @typescript-eslint/unbound-method */
    this.directDebitSummaryForm = this.#fb.group<DirectDebitSummary>({
      stripeElement: new FormControl<string | null>(null, Validators.required),
      bankAccountName: new FormControl<string | null>(null, Validators.required),
      emailAddress: new FormControl<string | null>(null, [Validators.required, Validators.email])
    });
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    this.loadingState.emit(LoadingState.Loading);

    this.emailAddress.setValue(this.accountEmail);
    this.bankAccountName.setValue(this.accountName);

    this.#subscriptions.push(
      this.directDebitSummaryForm.statusChanges.subscribe(result => {
        this.validityChange.emit(result === 'VALID' && this.#agreeToDirectDebit);
      }));

    this.#initializeStripe();
    this.loadingState.emit(LoadingState.Complete);
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  changeUseAlternateEmailAddress() {
    this.useAlternateEmailAddress = !this.useAlternateEmailAddress;
    if (this.useAlternateEmailAddress) {
      this.emailAddress.reset();
    } else {
      this.emailAddress.patchValue(this.emailAddress.value);
    }
  }

  validatePaymentDetails() {
    if (this.directDebitSummaryForm.invalid) {
      markControlsAsTouched(this.directDebitSummaryForm);
      return;
    }

    this.loadingState.emit(LoadingState.Loading);
    const bankAccountInfo = this.directDebitSummaryForm.value;

    /*eslint-disable @typescript-eslint/naming-convention */
    const confirmCardSetupOption = {
      payment_method: {
        au_becs_debit: this.directDebitAccount,
        billing_details: {
          name: bankAccountInfo.bankAccountName,
          email: bankAccountInfo.emailAddress
        }
      }
    };
    /*eslint-enable @typescript-eslint/naming-convention */

    if (this.stripeAccountInfo) {
      (this.stripe as any as { confirmAuBecsDebitSetup: (clientSecret: string, data?: any) => Promise<stripe.SetupIntentResponse> })
        .confirmAuBecsDebitSetup(this.stripeAccountInfo.clientSecret, confirmCardSetupOption)
        .then(result => {
          this.loadingState.emit(LoadingState.Complete);
          if (result.error) {
            this.directDebitSummaryForm.setErrors({ [this.directDebitSetupError]: result.error.message });
          } else {
            this.setupIntent.emit(result.setupIntent?.payment_method ?? '');
          }
          this.#changeDetectorRef.markForCheck();
        });
    }
  }

  #initializeStripe() {
    if (this.stripeAccountInfo) {
      this.stripe = Stripe(ServiceEnvironment.value.stripePublishableApiKey,
        {
          stripeAccount: this.stripeAccountInfo.stripeAccount,
          betas: ['au_bank_account_beta_2']
        });
      const elements = this.stripe.elements();
      this.directDebitAccount = elements.create('auBankAccount' as any, this.stripeOptions);
      this.directDebitAccount.mount(this.bankAccountInfo?.nativeElement);
      this.directDebitAccount.addEventListener('change', this.cardValidationHandler);
    }
  }

  #onChange(event: stripe.elements.ElementChangeResponse) {
    if (event.error) {
      this.stripeElement.setErrors({ [this.bankAccountValidationError]: event.error.message });
    } else {
      this.stripeElement.setErrors(null);
    }

    if (event.bankName && (event as any).branchName) {
      this.bankName = `${event.bankName} (${(event as any).branchName})`;
    }

    this.stripeElement.markAsTouched();
    this.directDebitSummaryForm.setErrors(null);
  }
}
