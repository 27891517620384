import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, effect, inject, input, model, OnDestroy, signal, TemplateRef, untracked, viewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { SharedService } from '../services';

@Component({
  selector: 'kip-video',
  templateUrl: './video.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #modalService = inject(NgbModal);

  #subscriptions: Subscription[] = [];
  readonly #video = signal('');
  readonly #url = signal<string | undefined>(undefined);
  readonly #loading = signal(true);
  readonly #size = signal('');
  readonly #error = signal(false);
  readonly #percentDone = signal(0);

  readonly icons = Icons;
  readonly percentDone = this.#percentDone.asReadonly();
  readonly error = this.#error.asReadonly();
  readonly size = this.#size.asReadonly();
  readonly loading = this.#loading.asReadonly();
  readonly url = this.#url.asReadonly();
  readonly videoTitle = input('Video');
  readonly modalOptions = input<NgbModalOptions>({ fullscreen: true });
  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly video = model('');

  constructor() {
    effect(() => {
      const value = this.video();
      untracked(() => this.#updateVideo(value));
    }, { allowSignalWrites: true });
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  close() {
    this.#video.set('');
    this.#modalService.dismissAll();
  }

  #updateVideo(value: string) {
    if (value) {
      this.#loading.set(true);
      const modalContent = this.modalContent();
      if (modalContent) {
        this.#modalService.open(modalContent, this.modalOptions());
      }
      this.#subscriptions.push(
        this.#sharedService.getVideo(value).subscribe({
          next: response => {
            if (response.type === HttpEventType.DownloadProgress && response.total) {
              this.#size.set(this.#formatBytes(response.total));
              this.#percentDone.set(Math.round(response.loaded * 100 / response.total));
            }
            if (response.type === HttpEventType.Response) {
              this.#error.set(false);
              this.#loading.set(false);
              this.#url.set(response.body ? window.URL.createObjectURL(response.body) : '');
            }
          },
          error: () => {
            this.#loading.set(false);
            this.#error.set(true);
          }
        }));
    } else {
      this.#loading.set(false);
      this.close();
    }
  }

  // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

  #formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
