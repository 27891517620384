@if (!editing) {
@if (!hasResults) {
<div class="kip-tab">
  <kip-no-results text="Lets add one." title="Looks like you don't have any current weaknesses set for this student!"
    [addButtons]="buttons" [canEdit]="canEdit" />
</div>
} @else {
<div class="kip-tab">
  <div class="d-flex justify-content-between align-items-baseline w-100">
    <h2 class="kip-tab__header">Current Weaknesses</h2>
    <kip-buttons [buttons]="buttons" />
  </div>
  @if (studentWeaknesses.length > 0) {
  <div class="kip-tab__article-list mb-2">
    @for (studentWeakness of studentWeaknesses; track studentWeakness) {
    <div class="kip-tab__article" (click)="edit(studentWeakness)">
      <kip-weakness-card [studentWeakness]="studentWeakness" />
    </div>
    }
  </div>
  }
</div>
<kip-student-weakness-history [studentId]="studentId" [(enabled)]="historyEnabled" />
}
} @else {
<div class="kip-tab kip-tab--is-edit row g-0 mt-0">
  <kip-weakness-edit class="col-12 col-lg-12 col-xl-6" [studentId]="studentId" [studentWeakness]="studentWeaknessToEdit"
    (cancelled)="cancelled()" (submitted)="submitted()" (weaknessCompleted)="weaknessCompleted()" />
</div>
}