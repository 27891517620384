import { createAction, props } from '@ngrx/store';

enum DeviceActionTypes {
  SetVideo = 'Tutor > Devices > Set Video',
  SetAudio = 'Tutor > Devices > Set Audio',
  Load = 'Tutor > Devices > Load'
}

export const setVideoDeviceAction = createAction(
  DeviceActionTypes.SetVideo,
  props<{
    readonly deviceId: string;
  }>()
);

export const setAudioDeviceAction = createAction(
  DeviceActionTypes.SetAudio,
  props<{
    readonly deviceId: string;
  }>()
);

export const loadDevicesAction = createAction(
  DeviceActionTypes.Load,
  props<{
    readonly videoDeviceId: string | undefined;
    readonly audioDeviceId: string | undefined;
  }>()
);
