import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Award, AwardsService, StudentAward } from 'awards-lib';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kip-student-awards-base',
  templateUrl: './student-awards-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentAwardsBaseComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #awardsService = inject(AwardsService);

  #subscriptions: Subscription[] = [];

  readonly maxLabelLength = 50;
  readonly maxDescriptionLength = 500;

  awardForm: FormGroupTypeSafe<StudentAward>;

  get awardId() {
    return this.awardForm.getSafe(x => x.awardId);
  }

  get label() {
    return this.awardForm.getSafe(x => x.label);
  }

  get description() {
    return this.awardForm.getSafe(x => x.description);
  }

  get points() {
    return this.awardForm.getSafe(x => x.points);
  }

  get date() {
    return this.awardForm.getSafe(x => x.date);
  }

  get awardGroupTypeId() {
    return this.awardForm.getSafe(x => x.awardGroupTypeId);
  }

  @Input({ required: true }) studentId: number | undefined;
  @Input({ required: true }) lessonId: number | undefined;

  // eslint-disable-next-line accessor-pairs
  @Input({ required: true })
  set selectedAward(award: Award | undefined) {
    if (award !== undefined) {
      this.points.setValue(award.points);
      this.label.setValue(award.label);
      this.description.setValue(award.description);
      this.awardId.setValue(award.id);
      this.awardGroupTypeId.setValue(award.awardGroupTypeId);
    }
  }

  @Output() readonly cancel = new EventEmitter();
  @Output() readonly chatAlert = new EventEmitter<string>();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */
    this.awardForm = this.#fb.group<StudentAward>({
      studentId: new FormControl<number>(0, Validators.required),
      awardId: new FormControl<number>(0, [Validators.min(1), Validators.required]),
      label: new FormControl<string>('', [Validators.required, Validators.maxLength(this.maxLabelLength)]),
      description: new FormControl<string | null>(null, Validators.maxLength(this.maxDescriptionLength)),
      points: new FormControl<number>(20, [Validators.required, Validators.min(0), Validators.max(1000)]),
      date: new FormControl<DateStruct>(moment().add(0, 'days').toDateStruct()),
      awardGroupTypeId: new FormControl<number>(1, Validators.required)
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    this.awardForm.patchSafe({ studentId: this.studentId });
    const studentAward = this.awardForm.value;
    this.#subscriptions.push(
      this.#awardsService.postNewAward(this.lessonId ?? 0, studentAward)
        .subscribe(error => {
          if (error) {
            this.awardForm.handleServerErrors(error);
          } else {
            this.chatAlert.emit(`award_${studentAward.awardId} ${studentAward.label}`);
            this.cancel.emit();
          }
        }));
  }
}
