@if (date) {
<label [class]="labelClass??''" [for]="name">{{ label }}@if (optional) {
  <span class="ms-2 small text-uppercase">Optional</span>
  }</label>
<div class="input-group">
  <input #dp1="ngbDatepicker" class="form-control" name="dp1" ngbDatepicker [formControl]="date" [id]="name"
    [markDisabled]="isDisabled" [maxDate]="maxDate" [minDate]="minDate" [placeholder]=" placeholder || ''">
  <button class="btn btn-outline-secondary" type="button" (click)="dp1.toggle()">
    <fa-icon [icon]="icons.date" />
  </button>
</div>
}