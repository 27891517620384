import { createReducer, on } from '@ngrx/store';
import { SessionCaption } from 'message-lib';
import { PublishState } from 'open-tok-lib';

import { ConnectionState, IdleStatus } from '../../../shared';
import { SessionEntity } from '../models';
import * as SessionActions from './session.actions';

export enum LoadedState {
  Loading = 1,
  Loaded = 2,
  Error = 3
}

export interface SessionState {
  readonly loaded: LoadedState;
  readonly connected: ConnectionState;
  readonly publishState: PublishState;
  readonly publishVideo: boolean;
  readonly publishAudio: boolean;
  readonly screenSharePublishState: PublishState;
  readonly entity: SessionEntity | undefined;
  readonly classInProgress: boolean;
  readonly captions: SessionCaption[];
}

const initialState: SessionState = {
  loaded: LoadedState.Loading,
  connected: ConnectionState.Unknown,
  publishState: PublishState.Unknown,
  publishVideo: true,
  publishAudio: true,
  screenSharePublishState: PublishState.Unknown,
  entity: undefined,
  classInProgress: false,
  captions: []
};

function createSessionEntity(state: SessionState, value: Partial<SessionEntity>): SessionEntity {
  return Object.assign({}, state.entity, value) as SessionEntity;
}

function createSessionState(state: SessionState, value: Partial<SessionState>): SessionState {
  return Object.assign({}, state, value) as SessionState;
}

export const sessionReducer = createReducer(initialState,
  on(SessionActions.errorAction, state => createSessionState(state, { loaded: LoadedState.Error })),
  on(SessionActions.loadAction, (state, action) => {
    const returnedEntity: SessionEntity = {
      dateTime: action.session.dateTime,
      dateTimeOffsetIanaTimeZoneName: action.session.dateTimeOffsetIanaTimeZoneName,
      duration: action.session.duration,
      isAssessment: action.session.isAssessment,
      idle: IdleStatus.Active,
      internalNote: action.session.internalNote,
      parentNote: action.session.parentNote,
      internalNoteRanking: action.session.internalNoteRanking,
      parentNoteRanking: action.session.parentNoteRanking,
      sessionId: action.session.sessionId,
      tutorId: action.session.tutorId,
      tutor: action.session.tutor,
      centreTitle: action.session.centreTitle,
      adjustableHeaderId: action.session.adjustableHeaderId,
      regionName: action.session.regionName,
      sessionCaptions: action.session.sessionCaptions,
      tutorPhotoUrl: '',
      tutorImageData: '',
      tutorPhotoDefault: true,
      isDropIn: action.session.isDropIn,
      isDropInSessionAvailable: action.session.isDropInSessionAvailable,
      allowTutorLessonInternalNoteRanking: action.session.allowTutorLessonInternalNoteRanking,
      allowTutorLessonParentNoteRanking: action.session.allowTutorLessonParentNoteRanking,
      allowTutorSessionInternalNoteRanking: action.session.allowTutorSessionInternalNoteRanking,
      allowTutorSessionParentNoteRanking: action.session.allowTutorSessionParentNoteRanking,
      sessionRecordingTermsAndConditionsTemplateId: action.session.sessionRecordingTermsAndConditionsTemplateId,
      tutorRecordingEnabled: action.session.tutorRecordingEnabled,
      sessionRecordingHtml: action.session.sessionRecordingHtml,
      studentPaymentWarnings: action.session.studentPaymentWarnings
    };

    return createSessionState(state, {
      entity: returnedEntity, loaded: LoadedState.Loaded,
      captions: action.session.captions
    });
  }),
  on(SessionActions.disableCaptionsAction, state => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, {
        sessionCaptions: false
      });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.tutorPhotoUpdateAction, (state, action) => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, {
        tutorPhotoDefault: action.tutorPhotoDefault,
        tutorPhotoUrl: action.tutorPhotoUrl
      });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.addCaptionAction, (state, action) => {
    return createSessionState(state, { captions: [...state.captions, { name: action.name, caption: action.caption, warning: action.warning, sent: action.sent, timestamp: action.timestamp }] });
  }),
  on(SessionActions.closeAction, state => {
    return createSessionState(state, { entity: undefined, connected: ConnectionState.Disconnected, publishState: PublishState.Unknown });
  }),
  on(SessionActions.updateDropInSessionAvailabilityAction, (state, action) => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, { isDropInSessionAvailable: action.isAvailable });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.updateIdleStatusAction, (state, action) => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, { idle: action.idleStatus });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.tutorImageDataAction, (state, action) => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, { tutorImageData: action.imageData });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.updateNotesAction, (state, action) => {
    let returnedEntity: SessionEntity | undefined;
    if (state.entity) {
      returnedEntity = createSessionEntity(state, {
        internalNote: action.internalNote,
        parentNote: action.parentNote,
        internalNoteRanking: action.internalNoteRanking,
        parentNoteRanking: action.parentNoteRanking
      });
    }
    return createSessionState(state, { entity: returnedEntity });
  }),
  on(SessionActions.togglePublishVideoAction, state => {
    return createSessionState(state, { publishVideo: state.publishState === PublishState.Allowed ? !state.publishVideo : state.publishVideo });
  }),
  on(SessionActions.togglePublishAudioAction, state => {
    return createSessionState(state, { publishAudio: state.publishState === PublishState.Allowed ? !state.publishAudio : state.publishAudio });
  }),
  on(SessionActions.publishVideoOnAction, state => {
    return createSessionState(state, { publishVideo: state.publishState === PublishState.Allowed ? true : state.publishVideo });
  }),
  on(SessionActions.publishAudioOnAction, state => {
    return createSessionState(state, { publishAudio: state.publishState === PublishState.Allowed ? true : state.publishAudio });
  }),
  on(SessionActions.screenSharePublishAllowedAction, state => {
    return createSessionState(state, { screenSharePublishState: PublishState.Allowed });
  }),
  on(SessionActions.screenSharePublishDeniedAction, state => {
    return createSessionState(state, { screenSharePublishState: PublishState.Denied });
  }),
  on(SessionActions.screenSharePublishFailureAction, state => {
    return createSessionState(state, { screenSharePublishState: PublishState.Unable });
  }),
  on(SessionActions.publishAllowedAction, state => {
    return createSessionState(state, { publishState: PublishState.Allowed });
  }),
  on(SessionActions.publishDeniedAction, state => {
    return createSessionState(state, { publishState: PublishState.Denied });
  }),
  on(SessionActions.publishFailureAction, state => {
    return createSessionState(state, { publishState: PublishState.Unable });
  }),
  on(SessionActions.updateSignalRConnectionStateAction, (state, action) => {
    return createSessionState(state, { connected: action.connected ? ConnectionState.Connected : ConnectionState.Disconnected });
  }),
  on(SessionActions.startTeachingClassAction, state => {
    return createSessionState(state, {
      publishAudio: state.publishState === PublishState.Allowed ? true : state.publishAudio,
      classInProgress: true
    });
  }),
  on(SessionActions.stopTeachingClassAction, state => {
    return createSessionState(state, {
      publishAudio: state.publishState === PublishState.Allowed ? false : state.publishAudio,
      classInProgress: false
    });
  })
);
