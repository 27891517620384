import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Icons } from 'icon-lib';
import { JiraRequestType, JiraStyleType } from 'jira-lib';
// eslint-disable-next-line no-restricted-imports
import { LessonQuestionAttempt } from 'projects/lms/src/app/shared';
import { AnswerType, currencySwapFunction, Question, QuestionData, QuestionNavigatorItem, QuestionNavigatorSelected, QuestionSummary, RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';
import { WhiteboardDataService, WhiteboardService } from 'whiteboard-lib';

import {
  AssociationType,
  ExploreActivity,
  LessonPlanActivity,
  StudentActivityData,
  StudentQuestionResult
} from '../../models';
import { ExploreService } from '../../services';

// eslint-disable-next-line @typescript-eslint/no-shadow
enum currentView { Question = 0, Introduction = 1, WorkedSolution = 2, AdditionalData = 3 }

@Component({
  selector: 'kip-explore-computer',
  templateUrl: './explore-computer.component.html',
  styleUrl: './explore-computer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreComputerComponent implements OnDestroy {

  readonly #whiteboardService = inject(WhiteboardService);
  readonly #whiteboardDataService = inject(WhiteboardDataService);
  readonly #exploreService = inject(ExploreService);
  readonly #domSanitizer = inject(DomSanitizer);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #jiraMetaData: { [prop: string]: string } = {};
  #questionData: QuestionData | undefined;
  #navigation: QuestionNavigatorItem[] = [];
  #introduction: SafeHtml | null = null;
  #workedSolution: SafeHtml | null = null;
  #additionalData: SafeHtml | null = null;
  #question: Question | undefined;
  #activity: ExploreActivity | undefined;
  #currentView: currentView = currentView.Question;
  #allStudentAnswers: readonly LessonQuestionAttempt[] | undefined;
  #studentAnswers: readonly AnswerType[] | undefined;
  #studentQuestionResultsMapByQuestionId = new Map<number, StudentQuestionResult>();
  #currentStudentQuestionResult: StudentQuestionResult | undefined;
  #studentData: StudentActivityData | undefined;
  #showWhiteboard = false;
  #backgroundImage = '';
  #subscriptions: Subscription[] = [];
  questionSummary: QuestionSummary<any> = { text: '', type: 0, answers: [], parameters: [] };

  readonly currentViews = currentView;
  readonly icons = Icons;
  readonly jiraStyleTypeContent = JiraStyleType.Content;

  get skillBuilderText() {
    if (this.#currentStudentQuestionResult?.skillbuilder && this.allowAddSkillbuilder) {
      return this.activityIdsAlreadySelected.includes(this.#currentStudentQuestionResult.skillbuilder.activityId) ? 'Skillbuilder Added' : 'Add Skillbuilder';
    }

    return '';
  }

  get jiraMetaData() {
    return this.#jiraMetaData;
  }

  get jiraRequestType() {
    return JiraRequestType.Question;
  }

  get jiraUId() {
    return this.question?.contentGuid;
  }

  get navigation(): QuestionNavigatorItem[] {
    return this.#navigation;
  }

  get additionalData(): SafeHtml | null {
    return this.#additionalData;
  }

  get htmlIntroduction(): SafeHtml | null {
    return this.#introduction;
  }

  get allowIntroduction(): boolean {
    return this.#introduction !== null;
  }

  get allowAdditionalData(): boolean {
    return this.#additionalData !== null;
  }

  get htmlWorkedSolution(): SafeHtml | null {
    return this.#workedSolution;
  }

  get allowWorkedSolution(): boolean {
    return this.#workedSolution !== null;
  }

  get studentAnswers() {
    return this.#studentAnswers;
  }

  get allStudentAnswers() {
    return this.#allStudentAnswers;
  }

  get questionData() {
    return this.#questionData;
  }

  set currentViewString(value: string) {
    this.#currentView = Number.parseInt(value.toString(), 10);
  }

  get currentViewString() {
    return this.#currentView.toString();
  }

  get currentView(): currentView {
    return this.#currentView;
  }

  get question(): Question | undefined {
    return this.#question;
  }

  get hasWhiteboard() {
    return this.#currentStudentQuestionResult?.hasWhiteboard;
  }

  get showWhiteboard() {
    return this.#showWhiteboard;
  }

  get backgroundImage() {
    return this.#backgroundImage;
  }

  get whiteboardGuidKey() {
    const activityGuid = this.studentData?.activityGuid;
    const pageGuid = this.#currentStudentQuestionResult?.questionGuid;

    return activityGuid && pageGuid ? { activityGuid: activityGuid, pageGuid: pageGuid } : undefined;
  }

  get whiteboardQuestionId() {
    return this.showWhiteboard ? this.#question?.questionId : undefined;
  }

  @Input({ required: true }) backgroundColor = '';

  @Input({ required: true }) set activity(value: ExploreActivity | undefined) {
    this.#activity = value;
    if (value) {
      this.#questionData = { id: value.questionIds[0], index: 0 };
      this.#loadQuestion();
    } else {
      this.#questionData = undefined;
    }
  }

  get activity() {
    return this.#activity;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) allowAddSkillbuilder = false;
  @Input({ required: true }) activityIdsAlreadySelected: readonly number[] = [];

  @Input({ required: true }) set studentData(studentData: StudentActivityData | undefined) {
    this.#studentData = studentData;
    if (studentData) {
      this.#subscriptions.push(
        this.#exploreService.getResults(studentData.lessonActivityPlanId).subscribe(results => {
          this.#studentQuestionResultsMapByQuestionId = new Map(results.map(result => [result.questionId, result]));
          this.#updateQuestion();
          this.#updateNavigation();
        }));
    } else {
      this.#studentQuestionResultsMapByQuestionId.clear();
    }
  }

  get studentData() {
    return this.#studentData;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) soundRegionId = RegionId.Australia;
  @Input({ required: true }) regionId = RegionId.Australia;
  @Input({ required: true }) allowJira = true;

  @Output() readonly addSkillbuilder = new EventEmitter<LessonPlanActivity>();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  addSkillbuilderToPlan() {
    if (this.#currentStudentQuestionResult?.skillbuilder) {
      this.addSkillbuilder.emit(
        {
          activityId: this.#currentStudentQuestionResult.skillbuilder.activityId,
          name: this.#currentStudentQuestionResult.skillbuilder.name,
          associationType: AssociationType.Manual,
          description: this.#currentStudentQuestionResult.skillbuilder.description,
          isManual: false,
          internalNote: ''
        }
      );
    }
  }

  displayAnswer() {
    this.#studentAnswers = undefined;
  }

  displayStudentAnswer(answers: readonly AnswerType[]) {
    this.#studentAnswers = answers;
  }

  toggleWhiteboard() {
    if (!this.#showWhiteboard) {
      this.displayWhiteboard();
    } else {
      this.hideWhiteboard();
    }
  }

  hideWhiteboard() {
    this.#showWhiteboard = false;
  }

  displayWhiteboard() {
    if (this.#studentData && this.question) {
      const lessonGuid = this.#studentData.lessonGuid;
      const whiteboardGuidKey = this.whiteboardGuidKey;
      const question = this.#question;
      const studentData = this.#studentData;

      if (studentData && question && whiteboardGuidKey) {
        this.#showWhiteboard = true;
        this.#whiteboardDataService.getWhiteboardImage(whiteboardGuidKey.pageGuid).then(dataUrl => {
          this.#backgroundImage = dataUrl;
          this.#subscriptions.push(
            this.#whiteboardDataService
              .getWhiteboard(lessonGuid, whiteboardGuidKey)
              .subscribe(state => {
                this.#whiteboardService.load(
                  {
                    whiteboardGuidKey: whiteboardGuidKey,
                    gridType: state.gridType,
                    events: state.events,
                    eventsArchivedOnUtc: state.eventsArchivedOnUtc,
                    pageTitle: ''
                  });
              }));
        });
      }
    }
  }

  onNavigate(item: QuestionNavigatorSelected) {
    this.#questionData = item;
    this.#loadQuestion(item.whiteboard);
    if (this.#question?.questionId === item.id && item.whiteboard) {
      this.toggleWhiteboard();
    }
  }

  #updateNavigation() {
    if (this.#activity) {
      this.#navigation = this.#activity.questionIds.map(current => {
        const studentQuestionResult = this.#studentQuestionResultsMapByQuestionId.get(current);
        const result: QuestionNavigatorItem = {
          id: current,
          icon: this.#questionData?.id === current ? Icons.preview.regular : undefined,
          visited: studentQuestionResult?.visited ?? false,
          correct: studentQuestionResult?.correct ?? false,
          hasWhiteboard: studentQuestionResult?.hasWhiteboard ?? false,
          attempts: studentQuestionResult?.attempts ?? 0,
          skipped: false
        };

        return result;
      });
    }
  }

  #updateQuestion() {
    this.#currentView = currentView.Question;

    this.#currentStudentQuestionResult = this.#studentQuestionResultsMapByQuestionId.get(this.#questionData?.id ?? 0);
    const studentQuestionResult = this.#currentStudentQuestionResult;
    if (studentQuestionResult && studentQuestionResult.attempts > 0) {
      const allStudentAnswers = studentQuestionResult?.studentAnswers;
      if (allStudentAnswers) {
        this.#allStudentAnswers = allStudentAnswers.map(
          (s, index) => ({
            correct: studentQuestionResult.correct && index === allStudentAnswers.length - 1,
            answers: s
          })
        );
        this.#studentAnswers = this.#allStudentAnswers[this.#allStudentAnswers.length - 1].answers;
      }
    } else {
      this.#allStudentAnswers = undefined;
      this.#studentAnswers = undefined;
    }

    this.#introduction = this.#question?.introduction ? this.#domSanitizer.bypassSecurityTrustHtml(currencySwapFunction(this.#question.introduction, this.regionId)) : null;

    this.#workedSolution = this.#question?.workedSolution ? this.#domSanitizer.bypassSecurityTrustHtml(currencySwapFunction(this.#question.workedSolution, this.regionId)) : null;
    this.#additionalData = this.#activity?.additionalData ? this.#domSanitizer.bypassSecurityTrustHtml(this.#activity.additionalData) : null;

    this.#changeDetectorRef.markForCheck();
  }

  #loadQuestion(showWhiteboard = false) {
    if (this.#questionData?.id) {
      this.#subscriptions.push(this.#exploreService
        .getQuestion(this.#questionData.id, this.regionId)
        .subscribe(question => {
          this.#question = question;
          this.questionSummary = {
            text: question.text,
            type: question.type,
            parameters: question.parameters,
            answers: [...question.answers]
          };
          this.#showWhiteboard = showWhiteboard;
          this.#updateQuestion();
        }));
    }

    this.#updateNavigation();

  }
}
