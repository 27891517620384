import { Pipe, PipeTransform } from '@angular/core';

import { UserName } from '../models';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {

  transform(value: UserName | undefined, none = ''): string {
    return value ? `${value.givenName} ${value.familyName}` : none;
  }
}
