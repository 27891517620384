import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-blast-off',
  templateUrl: './blast-off.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBlastOffComponent implements IconComponent {

}
