<ng-template #content>
  <div class="modal-header">
    <h5 class="modal-title">
      <fa-icon class="me-2" [icon]="icons.alert.solid" />
      <span>{{title}}</span>
    </h5>
  </div>
  <div class="modal-body">
    <span>{{message}}</span>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="this.close()">{{buttonText}}</button>
  </div>
</ng-template>