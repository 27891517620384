import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

import { LessonActivityType } from '../../shared';

@Pipe({
  name: 'activityIcon'
})
export class ActivityIconPipe implements PipeTransform {

  transform(lessonActivityType: LessonActivityType | undefined): IconDefinition {
    switch (lessonActivityType) {
      case LessonActivityType.Computer:
        return Icons.activityType.computer;
      case LessonActivityType.Manual:
        return Icons.activityType.manual;
      case LessonActivityType.AdHoc:
        return Icons.activityType.adhoc;
      case LessonActivityType.TimedComputer:
        return Icons.activityType.computer;
      default:
        return Icons.activityType.unknown;
    }
  }
}
