import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService, REPORT_PROGRESS_OPTION } from 'service-lib';
import { LessonActivityPlanFile } from 'ui-common-lib';

export interface ActivitySummary {
  readonly tutor: string;
  readonly student: string;
  readonly subject: string;
  readonly activity: string;
}

@Injectable({ providedIn: 'root' })
export class FilesService extends HttpWithAbsoluteService {

  getActivitySummary(lessonId: number, activityGuid: string): Observable<ActivitySummary> {
    return this.get<ActivitySummary>(`adhoc/activity/${lessonId}/${activityGuid}`);
  }

  uploadActivityFiles(lessonId: number, activityGuid: string, activityFiles: readonly File[]) {
    const formData = new FormData();
    for (const file of activityFiles) {
      formData.append('activityFiles', file, file.name);
    }
    return this.put<HttpProgressEvent | HttpResponse<LessonActivityPlanFile[]>>(
      `assets/lessons/${lessonId}/activities/${activityGuid}/files`,
      formData,
      undefined,
      REPORT_PROGRESS_OPTION
    );
  }

  getActivityFile(lessonId: number, activityGuid: string, fileId: number) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/lessons/${lessonId}/activities/${activityGuid}/files/${fileId}`, ErrorBlobPath.Image);
  }
}
