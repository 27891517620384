@if (resultType && resultType.id !== assessmentResultTypeBundles) {
<div>
  <span class="h1 text-primary d-inline-block pb-3">{{ studentName }}'s Results</span>
  <div class="bg-light rounded-lg text-center p-3">
    <p class="h2 pb-2">{{ resultType.title }}</p>
    @if (resultType.id === assessmentResultTypeGoal) {
    <div class="rounded-lg bg-white d-inline-block p-3 w-100" style="max-width:550px;">
      {{ goal }}
    </div>
    }
    @if (result && resultType.id !== 0) {
    <div class="rounded-lg bg-white d-inline-block p-3 w-100" style="max-width:550px;">
      <div class="d-flex border-bottom pb-3 mb-3">
        <div class="flex-fill text-start ps-2">Activity</div>
        @if (!resultType.ageRequired) {
        <div class="flex-fill text-end pe-2">Score</div>
        }
        @if (resultType.ageRequired) {
        <div class="flex-fill text-end pe-2">{{ resultType.title }} Age</div>
        }
      </div>
      <div class="d-flex mb-3">
        <div class="flex-fill text-start ps-2">
          <fa-icon class="me-1" [icon]="activityType | activityIcon" /> {{ activityName }}
        </div>
        @if (!resultType.ageRequired) {
        <div class="flex-fill text-end pe-2 ps-4">{{ math.round(result.score /
          result.total * 100) }}%</div>
        }
        @if (resultType.ageRequired) {
        <div class="flex-fill text-end pe-2 ps-4">{{ result.ageResult | ageResult:
          result.age : null : false }}</div>
        }
      </div>
      @if (isTutor) {
      <div class="position-alert-for-tutor">
        <b>Note for Assessor:</b>
        {{ studentName }} has assessed at {{ resultType.title }}
        @if (resultType.gradeRequired) {
        <div class="d-inline">
          level {{ result.gradeId | gradeLevel : grades }}
          ({{ result.gradeId | gradeDifference : gradeId }})
        </div>
        }
        @if (resultType.ageRequired && result.ageResult !== 1) {
        <div class="d-inline">
          age which is
          {{ (result.ageResult | ageResult : result.age) | lowercase }}
        </div>
        }
        @if (resultType.ageRequired && result.ageResult === 1) {
        <div class="d-inline">
          age which is
          {{ result.ageResult | ageDifference : result.age : age }}
        </div>
        }
      </div>
      }
    </div>
    }
  </div>
</div>
}

@if (resultType && resultType.id === assessmentResultTypeBundles) {
<kip-enrolment-view-bundles [bundleSelected]="bundleSelected" [centreId]="centreId" [selectView]="false" />
}