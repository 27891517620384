export enum AcceptedFileType {
  Gif = 'image/gif',
  Png = 'image/png',
  Jpg = 'image/jpg',
  Jpeg = 'image/jpeg',
  Bmp = 'image/bmp',
  Pdf = 'application/pdf',
  MsWord = 'application/msword',
  WordOpen = 'application/vnd.openxmlformats',
  Word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Webp = 'image/webp',
  Svg = 'image/svg+xml',
  Excel = 'application/vnd.ms-excel',
  ExcelOpen = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
