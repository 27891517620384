/* eslint @typescript-eslint/naming-convention : 0 */
/* eslint @typescript-eslint/restrict-template-expressions : 0 */

import { createSelector } from '@ngrx/store';
import {
  EventType, LessonLog, LessonLogActivityClosed, LessonLogActivityCompleted, LessonLogActivityOpened, LessonLogActivityOpenRequested,
  LessonLogAuthor, LessonLogBroadcastingPublishFailure, LessonLogCaptionsError, LessonLogOnlineToggled, LessonLogQuestionIntroductionViewed, LessonLogQuestionWorkedSolutionViewed,
  LessonLogStudentPublishFailure, LessonLogStudentScreenSharePublishFailure, LessonLogStudentSubscribeFailure,
  LessonLogTutorPublishFailure, LessonLogTutorScreenSharePublishFailure, LessonLogTutorSubscribeFailure,
  LessonLogUpdateLessonDoItYourself,
  Message, MessageSubType, RecordingStatus
} from 'message-lib';
import { currencySwapFunction } from 'questions-lib';

import { EventsLoad, EventsRequest, SessionLessonLogInfo } from '../../models';
import { selectSessionLessonLogInfos } from '../lesson';
import { selectLogEntities, selectLogEntityIds } from '../state';

type MessageFormatter = {
  readonly [eventType in EventType]?: (lesson: SessionLessonLogInfo, log?: LessonLog) => string;
};

export const loadEvents = false;

const tutorFormatters: MessageFormatter = {
  'OnlineToggled': (lesson, log: LessonLogOnlineToggled | undefined) => {
    let deliveryMethod = '(Unknown)';
    if (log?.values) {
      deliveryMethod = log.values.isOnline ? 'Online' : 'In Centre';
    }
    return `You updated the delivery method for ${lesson.studentUniqueName} to ${deliveryMethod}`;
  },
  'TutorPublishFailure': (_lesson, log: LessonLogTutorPublishFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `You have video issues ${log.values.name} : ${log.values.message}`;
    }
    return 'You have video issues';
  },
  'BroadcastingPublishFailure': (_lesson, log: LessonLogBroadcastingPublishFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `You have broadcasting issues ${log.values.name} : ${log.values.message}`;
    }
    return 'You have broadcasting issues';
  },
  'TutorScreenSharePublishFailure': (_lesson, log: LessonLogTutorScreenSharePublishFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `You have screen sharing issues ${log.values.name} : ${log.values.message}`;
    }
    return 'You have screen sharing issues';
  },
  'ResetPassword': lesson => `You reset password of ${lesson.studentUniqueName}.`,
  'LessonDefer': lesson => `You marked ${lesson.studentUniqueName} lesson as deferred.`,
  'UpdateLessonDoItYourself': (lesson, log: LessonLogUpdateLessonDoItYourself) => `You changed ${lesson.studentUniqueName} lesson to ${log.values?.isOnline ? 'normal' : 'do it yourself'}.`,
  'LessonAttend': lesson => `You marked ${lesson.studentUniqueName} lesson as attended.`,
  'SessionOpened': () => 'You joined the lesson',
  'TutorPublishDenied': () => 'You denied access to video',
  'StartBroadcastingObservers': () => 'You started broadcasting video',
  'StopBroadcastingObservers': () => 'You stopped broadcasting video',
  'BroadcastingPublishDenied': () => 'You denied access to broadcasting video',
  'TutorScreenSharePublishAllowed': () => 'You started screen sharing',
  'TutorScreenShareStreamDestroyed': () => 'You finished screen sharing',
  'TutorScreenSharePublishDenied': () => 'You denied access to screen share',
  'SessionDisconnected': () => 'You left the lesson',
  'LessonPlanUpdated': lesson => `You updated the lesson plan for ${lesson.studentUniqueName}`,
  'TutorSubscribeFailure': (lesson, log: LessonLogTutorSubscribeFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `You have issues subscribing to ${lesson.studentUniqueName} video ${log.values.name} : ${log.values.message}`;
    }
    return `You have issues subscribing to ${lesson.studentUniqueName} video`;
  },
  'HelpAcknowledged': lesson => `You acknowledged a help request from ${lesson.studentUniqueName}`,
  'HelpCleared': lesson => `You cleared a help request from ${lesson.studentUniqueName}`,
  'LessonFocused': lesson => `You started teaching ${lesson.studentUniqueName}`,
  'LessonUnfocused': lesson => `You stopped teaching ${lesson.studentUniqueName}`,
  'LessonStartObserving': lesson => `You started observing ${lesson.studentUniqueName}`,
  'LessonStopObserving': lesson => `You stopped observing ${lesson.studentUniqueName}`,
  'ActivityOpenRequested': (lesson, log: LessonLogActivityOpenRequested | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid) {
      const activity = lesson.activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `You requested activity '${currencySwapFunction(activityName)}' for ${lesson.studentUniqueName}`;
  },
  'TutorAutoLoggedOutOnIdle': () => 'You were auto logged out due to becoming idle after the session ended',
  'CaptionsError': (_lesson, log: LessonLogCaptionsError) => `The open tok captions api failed with code ${log.values?.statusCode}`
};

const studentFormatters: MessageFormatter = {
  'LessonOpened': lesson => `${lesson.studentUniqueName} joined the lesson`,
  'HelpRequested': lesson => `${lesson.studentUniqueName} requested help`,
  'StartedLookingTab': lesson => `${lesson.studentUniqueName} came back to the lesson tab`,
  'StoppedLookingTab': lesson => `${lesson.studentUniqueName}  looking at another tab in their browser`,
  'StartedInteracting': lesson => `${lesson.studentUniqueName} started interacting in the lesson again`,
  'StoppedInteracting': lesson => `${lesson.studentUniqueName} stopped interacting in the lesson for 2 minutes or more`,
  'LobbyOpened': lesson => `${lesson.studentUniqueName} joined the lesson lobby`,
  'LobbyClosed': lesson => `${lesson.studentUniqueName} left the lesson lobby`,
  'LessonDisconnected': lesson => `${lesson.studentUniqueName} left the lesson`,
  'StudentScreenSharePublishDenied': lesson => `${lesson.studentUniqueName} denied access to screen share`,
  'StudentScreenSharePublishFailure': (lesson, log: LessonLogStudentScreenSharePublishFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `${lesson.studentUniqueName} has screen share issues ${log.values.name} : ${log.values.message}`;
    }
    return `${lesson.studentUniqueName} has screen share issues`;
  },
  'StudentScreenSharePublishAllowed': lesson => `${lesson.studentUniqueName} started screen sharing`,
  'StudentScreenShareStreamDestroyed': lesson => `${lesson.studentUniqueName} finished screen sharing`,
  'StudentPublishDenied': lesson => `${lesson.studentUniqueName} denied access to video`,
  'StudentPublishFailure': (lesson, log: LessonLogStudentPublishFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `${lesson.studentUniqueName} has video issues ${log.values.name} : ${log.values.message}`;
    }
    return `${lesson.studentUniqueName} has video issues`;
  },
  'StudentSubscribeFailure': (lesson, log: LessonLogStudentSubscribeFailure | undefined) => {
    if (log?.values?.name && log?.values?.message) {
      return `${lesson.studentUniqueName} has video subscription issues ${log.values.name} : ${log.values.message}`;
    }
    return `${lesson.studentUniqueName} has video subscription issues`;
  },
  'ActivityCompleted': (lesson, log: LessonLogActivityCompleted | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid && lesson.activities) {
      const activity = lesson.activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `${lesson.studentUniqueName} marked activity '${currencySwapFunction(activityName)}' as ${log?.values?.completedOn ? 'completed' : 'not completed'}`;
  },
  'ActivityOpened': (lesson, log: LessonLogActivityOpened | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid) {
      const activity = lesson.activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    let recordingText = '';
    const recordingStatus = log?.values?.recordingStatus;
    switch (recordingStatus) {
      case RecordingStatus.RecordingRequiredAndStarted:
        recordingText = ' - video recording started';
        break;
      case RecordingStatus.RecordingRequiredAndFailed:
        recordingText = ' - VIDEO RECORDING FAILED TO START';
        break;
      case RecordingStatus.RecordingRequiredAndBroadcasting:
        recordingText = ' - video recording not started as tutor broadcasting';
        break;
      default:
        recordingText = '';
        break;
    }

    return `${lesson.studentUniqueName} opened activity '${currencySwapFunction(activityName)}'${recordingText}`;
  },
  'ActivityClosed': (lesson, log: LessonLogActivityClosed | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid) {
      const activity = lesson.activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `${lesson.studentUniqueName} closed activity '${currencySwapFunction(activityName)}'`;
  },
  'QuestionIntroductionViewed': (lesson, log: LessonLogQuestionIntroductionViewed | undefined) => {
    let activityName = '';
    let index = -1;
    if (log?.values?.questionGuid) {
      for (const activity of lesson.activities) {
        index = activity.questions.findIndex(s => s.questionGuid === log?.values?.questionGuid);
        if (index !== -1) {
          activityName = activity.name;
          break;
        }
      }
    }
    return `${lesson.studentUniqueName} Viewed Introduction ${currencySwapFunction(activityName)} - Q${index + 1}`;
  },
  'QuestionWorkedSolutionViewed': (lesson, log: LessonLogQuestionWorkedSolutionViewed | undefined) => {
    let activityName = '';
    let index = -1;
    const questionGuid = log?.values?.questionGuid;
    if (questionGuid) {
      for (const activity of lesson.activities) {
        index = activity.questions.findIndex(s => s.questionGuid === questionGuid);
        if (index !== -1) {
          activityName = activity.name;
          break;
        }
      }
    }
    return `${lesson.studentUniqueName} Viewed Worked Solution ${currencySwapFunction(activityName)} - Q${index + 1}`;
  },
  'StudentAutoLoggedOutOnIdle': lesson => `${lesson.studentUniqueName} was auto logged out due to becoming idle after the session ended`
};

// IMPORTANT NOTE - This selector uses a very limited subset of the session lesson data
// as otherwise it repaints whenever anything changes in SessionLesson

export const selectSessionMessages = createSelector(
  selectLogEntityIds, selectLogEntities, selectSessionLessonLogInfos, (ids, entities, lessons) => {

    // Map the logs for the lesson log ids
    let messages: (Message | undefined)[] = [];

    if (lessons) {
      let logGroups: string[] = [];

      for (const lesson of lessons) {
        const logIds = ids[lesson.lessonGuid];

        if (logIds) {
          const formatted = logIds.map(logId => {
            const entity = entities[logId];

            // Multiples of the same log will be defined for the tutor - one for each lesson
            // We therefore need to filter them by event group to show a single instance
            if (entity && !logGroups.includes(entity.eventGroup)) {

              // Find the event type formatter and build the message object
              const formatters = entity.author === LessonLogAuthor.Student ? studentFormatters : tutorFormatters;
              const formatter = formatters[entity.eventType];

              if (formatter) {
                const text = formatter(lesson, entity);

                if (text) {

                  // Track the log group for filtering
                  logGroups = [...logGroups, entity.eventGroup];

                  return {
                    id: entity.eventId,
                    type: entity.eventType,
                    subType: entity.eventType !== 'Chat' ? MessageSubType.System :
                      entity.author === LessonLogAuthor.Student ? MessageSubType.ChatIncoming : MessageSubType.ChatOutgoing,
                    timestamp: entity.timestamp,
                    text: text,
                    warning: false,
                    lessonGuid: lesson.lessonGuid,
                    sender: entity.author === LessonLogAuthor.Student ? lesson.studentUniqueName : entity.author === LessonLogAuthor.AI ? 'Monty AI' : 'Tutor (me)'
                  };
                }
              }
            }

            return undefined;
          });

          messages = [...messages, ...formatted];
        }
      }
    }

    return messages.filter((message): message is Message => message !== undefined);
  });

export function getEventsRequest(): EventsRequest {

  if (!loadEvents) {
    return { load: EventsLoad.Never, eventTypes: null };
  } else {

    // only load events that are actually going to be displayed
    const events: string[] = [];
    for (const event in tutorFormatters) {
      events.push(`${event}Event`);
    }

    for (const event in studentFormatters) {
      events.push(`${event}Event`);
    }
    return { load: EventsLoad.Always, eventTypes: events };
  }
}
