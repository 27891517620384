import { ChangeDetectionStrategy, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

import {
  AnswerType, KeyboardType, MultipleChoiceOption, QuestionLayoutType, QuestionMultipleAnswerMultipleChoice,
  QuestionParametersMultipleChoice, QuestionSingleAnswerMultipleChoice, ValidationResult
} from '../../models';
import { determineScreenSize, ScreenSize } from '../../utilities';
import { QuestionLayout } from '../question-layout';

@Component({
  selector: 'kip-question-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceComponent extends QuestionLayout {

  #columns = 1;
  #answers: (AnswerType | undefined)[] = [];
  #options: MultipleChoiceOption[] = [];

  keyboardType = KeyboardType;

  override question: QuestionMultipleAnswerMultipleChoice | QuestionSingleAnswerMultipleChoice | undefined;

  get singleAnswer(): boolean {
    if (this.question) {
      return this.question.type === QuestionLayoutType.SingleAnswerMultipleChoice;
    }

    return false;
  }

  get optionsType(): string {
    return this.singleAnswer ? 'radio' : 'checkbox';
  }

  get optionsName(): string | undefined {
    return this.singleAnswer ? `options_${this.question?.questionId ?? 0}` : undefined;
  }

  get optionsWidth(): number {
    return Math.round(12 / this.#columns);
  }

  get options(): MultipleChoiceOption[] {
    return this.#options;
  }

  get answers(): AnswerType[] {
    return this.#answers.filter((answer): answer is AnswerType => answer !== undefined);
  }

  @ViewChildren('input') inputs: QueryList<ElementRef> | undefined;

  override initialize() {
    const parameters: QuestionParametersMultipleChoice = this.question?.parameters ?? {
      columns: 1,
      options: []
    };

    this.#columns = parameters.columns;

    if (determineScreenSize() === ScreenSize.Sm && parameters.columnsSmall) {
      this.#columns = parameters.columnsSmall;
    }

    this.#options = parameters.options.map((option, index) =>

    // Calculate a key for input/label binding
    // The checked state is also needed for state changes between answer selection
    ({
      key: `options_${this.question?.questionId ?? 0}_${index}`,
      index: index,
      text: option.text,
      image: option.image,
      value: option.value,
      valid: ValidationResult.NotKnown
    })
    );

    this.displayAnswer();
  }

  optionChecked(option: MultipleChoiceOption): boolean {
    return this.#answers[option.index] !== undefined;
  }

  updateAnswer(event: Event, option: MultipleChoiceOption) {
    if (!this.readonly) {
      const target = event.target as HTMLInputElement;
      const value = target.checked ? option.value : undefined;
      // Clear the other answers if it is single answer mode
      if (this.singleAnswer) {
        this.#answers = [];
      }
      this.#answers[option.index] = value;
      this.answered();
      this.sendUpdates();
    }
  }

  displayAnswer() {
    if (this.readonly && this.question) {
      const correctAnswers = this.question.answers.map(s => s.values[0]);
      let answers: readonly AnswerType[] = correctAnswers;
      if (this.studentAnswers !== undefined) {
        answers = this.studentAnswers;
      }
      for (const option of this.#options) {
        this.#answers[option.index] = answers.includes(option.value) ? option.value : undefined;
        option.valid = correctAnswers.includes(option.value) ? ValidationResult.Correct : ValidationResult.Incorrect;
      }
    }
  }
}
