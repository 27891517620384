import { QuestionParametersSorting, QuestionSummary } from '../../models';

export function convertSorting(question: QuestionSummary<QuestionParametersSorting>,
  genericInput: string, render: (input: string) => string, showAnswerOnly: boolean) {
  let correct = '';
  for (const answer of question.answers) {
    correct += `<span class="kip-success-tutor-answer">${render(answer.values[0].toString())}</span>`;
  }
  let random = '';
  for (const parameter of question.parameters.options) {
    random += ` ${render((parameter.value ?? '').toString())}`;
  }
  return showAnswerOnly ?
    genericInput.replace('&nbsp;', correct.slice(0, correct.length - 1)) :
    `${question.text + genericInput.replace('&nbsp;', random)}>${genericInput.replace('&nbsp;', correct)}`;
}
