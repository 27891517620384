import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-stopwatch-timer',
  templateUrl: './stopwatch-timer.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconStopwatchTimerComponent implements IconComponent {

}
