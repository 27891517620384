export enum ToolbarSvg {
  Clear = 'clear',
  Eraser = 'eraser',
  Grid = 'grids',
  Highlighter = 'highlighter',
  Lasso = 'select',
  Pen = 'pen',
  Print = 'print',
  Redo = 'redo',
  Shape = 'shapes & lines',
  Graph = 'graph',
  Text = 'text-box',
  Undo = 'undo'
}
