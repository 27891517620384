<div class="col-12 mt-3">
  <label class="small">{{ title }}</label>
</div>
<div #dropAreaPdf class="image-uploader-drop-area-small">
  <form class="image-uploader-form">
    <input class="image-uploader-file" type="file" [accept]="validFileTypes" [id]="id"
      (change)="fileUploadChangePdf($event)">
    <label [for]="id">{{ error? error:
      (solution?'Click here or drag & drop solution PDF/Word Doc':
      'Click here or drag & drop main PDF/Word Doc') }}</label>
  </form>
  {{ invalidFileTypePdf }}
</div>
<button class="btn btn-outline-secondary btn-sm" type="button" [disabled]="pdfFile === '' || pdfFile === null"
  (click)="clear()">Remove file</button>
<iframe #frame class="w-100 my-3" scrolling="no" src="" style="height:700px"
  [class.d-none]="pdfFile === '' || pdfFile === null"></iframe>