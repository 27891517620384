<kip-base-page [breadcrumbs]="breadcrumbs" [title]="'Student - ' + (studentDetail && student ? (student | name) : '')">
  @if (!studentDetail) {
  <kip-spinner />
  }
  @else {
  <section class="mb-3">
    <div class="kip-summary">
      <div class="kip-summary__col kip-summary__col--1/3">
        <kip-student-summary [ranking]="studentRanking" [student]="student" (edit)="edit()" />
      </div>
      <div class="kip-summary__col kip-summary__col--1/3">
        <kip-student-assessment-result [assessmentResults]="results" />
      </div>
      <div class="kip-summary__col kip-summary__col--1/3">
        <kip-student-account-summary [accounts]="accounts" />
      </div>
    </div>
  </section>
  <section class="mb-3">
    <kip-student-detail-tabs [studentDetail]="studentSummary" />
  </section>
  }
</kip-base-page>