import { QuestionParametersFillInTheBlank, QuestionSummary } from '../../models';

export function convertFillInTheBlank(question: QuestionSummary<QuestionParametersFillInTheBlank>, genericInput: string, showAnswerOnly: boolean) {

  let text = '';

  if (!showAnswerOnly) {
    const controlRegex = new RegExp(/{answer}/g);
    let i = 0;
    text += question.parameters.target.replace(controlRegex, (_substring: string) => {
      const value = genericInput.replace('&nbsp;', question.answers[i].values[0].toString());
      i++;
      return value;
    });

    return `${question.text} : ${text}`;
  }

  for (const answer of question.answers) {
    text += genericInput.replace('&nbsp;', `<span class="kip-success-tutor-answer">${answer.values[0].toString()}</span>`);
  }

  return text;
}
