import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, input, OnDestroy } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kip-form-control',
  templateUrl: './form-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlComponent implements OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  #subscription: Subscription | undefined;

  readonly control = input.required<FormArray | FormControl>();
  readonly label = input.required<string>();
  readonly class = input<string | undefined>(undefined);
  readonly range = input('');
  readonly maxLength = input<number | null>(null);
  readonly minLength = input<number | null>(null);
  readonly errorMessage = input('');

  constructor() {
    effect(() => {
      const control = this.control();

      if (this.#subscription) {
        this.#subscription.unsubscribe();
      }

      this.#subscription = control.valueChanges.subscribe(() => {
        this.#changeDetectorRef.markForCheck();
      });
    });
  }

  ngOnDestroy() {
    if (this.#subscription) {
      this.#subscription.unsubscribe();
      this.#subscription = undefined;
    }
  }
}
