import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExploreLibModule } from 'explore-lib';
import { IconLibModule } from 'icon-lib';
import { MarkdownLibModule } from 'markdown-lib';
import { QuestionsLibModule } from 'questions-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { OrganisationActivitiesListComponent } from './organisation-activities/organisation-activities-list/organisation-activities-list.component';
import { OrganisationActivityAIComponent } from './organisation-activities/organisation-activity-ai/organisation-activity-ai.component';
import { OrganisationActivityEditComponent } from './organisation-activities/organisation-activity-edit/organisation-activity-edit.component';
import { OrganisationActivityPdfComponent } from './organisation-activities/organisation-activity-pdf/organisation-activity-pdf.component';
import { OrganisationActivityIconPipe } from './organisation-activities/pipes';
import { OrganisationGoalEditComponent } from './organisation-goals/organisation-goal-edit/organisation-goal-edit.component';
import { OrganisationGoalPickerComponent } from './organisation-goals/organisation-goal-picker/organisation-goal-picker.component';
import { OrganisationGoalNodeComponent } from './organisation-goals/organisation-goal-tree/organisation-goal-node/organisation-goal-node.component';
import { OrganisationGoalTreeComponent } from './organisation-goals/organisation-goal-tree/organisation-goal-tree.component';
import { OrganisationGoalsListComponent } from './organisation-goals/organisation-goals-list/organisation-goals-list.component';
import { QuestionSearchComponent } from './question-search/question-search.component';

@NgModule({
  imports: [
    CommonModule,
    ExploreLibModule,
    FormsModule,
    IconLibModule,
    MarkdownLibModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgSelectModule,
    QuestionsLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  declarations: [
    OrganisationActivitiesListComponent,
    OrganisationActivityAIComponent,
    OrganisationActivityEditComponent,
    OrganisationActivityIconPipe,
    OrganisationActivityPdfComponent,
    OrganisationGoalEditComponent,
    OrganisationGoalNodeComponent,
    OrganisationGoalPickerComponent,
    OrganisationGoalsListComponent,
    OrganisationGoalTreeComponent,
    QuestionSearchComponent
  ],
  exports: [
    OrganisationActivitiesListComponent,
    OrganisationActivityAIComponent,
    OrganisationActivityEditComponent,
    OrganisationActivityPdfComponent,
    OrganisationGoalEditComponent,
    OrganisationGoalNodeComponent,
    OrganisationGoalPickerComponent,
    OrganisationGoalsListComponent,
    OrganisationGoalTreeComponent,
    QuestionSearchComponent
  ]
})

export class OrganisationLibModule { }
