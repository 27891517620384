export enum PointsTarget {
  BronzeMedal = 1,
  SilverMedal = 2,
  GoldMedal = 3,
  BronzeStar = 4,
  SilverStar = 5,
  GoldStar = 6,
  RubyTrophy = 7,
  PlatinumTrophy = 8,
  DiamondTrophy = 9
}
