/* eslint @typescript-eslint/restrict-template-expressions : 0 */

import { createSelector } from '@ngrx/store';
import { chatConverter, ChatState } from 'message-lib';

import { selectSessionLessonChatInfos } from '../lesson';
import { selectChatEntities, selectChatEntityIds, selectChatState } from '../state';

// IMPORTANT NOTE - This selector uses a very limited subset of the session lesson data
// as otherwise it repaints whenever anything changes in SessionLesson

export const selectSessionMessages = createSelector(
  selectChatEntityIds, selectChatEntities, selectSessionLessonChatInfos, (ids, entities, lessons) => {
    return chatConverter(ids, entities, lessons);
  });

export const selectRecipientsTyping = createSelector(selectChatState, (state: ChatState) => state.recipientsTyping);
