import { Injectable } from '@angular/core';
import { from, Observable, timer, zip } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SpellingTimerService {

  createTimer(interval: number, letters: string[]): Observable<string> {
    return zip(
      from(letters),
      timer(0, interval)
    )
      .pipe(
        map(([letter]) => letter)
      );
  }
}
