import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

@Pipe({
  name: 'starts'
})
export class StartsPipe implements PipeTransform {

  transform(value: DateStruct): string {

    const today = moment();
    const date = moment().toMoment(value, '00:00:00');
    if (date.isSameOrBefore(today)) {
      return 'Started On';
    }

    return 'Starts On';
  }
}
