import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ContentStatsNode } from '../../models';

@Component({
  selector: 'kip-stats',
  templateUrl: './stats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsComponent {
  get statsNodePercentage() {
    if (!this.stats || this.stats.completeCount === 0) {
      return 0;
    }
    return this.stats.percentage / this.stats.completeCount;
  }

  @Input({ required: true }) stats: ContentStatsNode | undefined = undefined;
}
