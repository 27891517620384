/* eslint-disable @angular-eslint/use-component-selector,  @angular-eslint/prefer-on-push-component-change-detection */

import { Component, input } from '@angular/core';

@Component({ template: '' })
export abstract class BaseFormControlComponent {
  readonly id = input.required<string>();
  readonly label = input.required<string>();
  readonly errorMessage = input('');
  readonly class = input<string | undefined>(undefined);
  readonly labelClass = input<string | undefined>(undefined);
}
