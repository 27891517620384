/* eslint-disable angular-file-naming/directive-filename-suffix */

import { Directive, Input } from '@angular/core';

import { WhiteboardIcon } from '../models';

@Directive()
export class WhiteboardIconBase implements WhiteboardIcon {

  readonly circleColor = '#00549F';
  get backgroundColor() {
    return this.selected ? '#0656A1' : '#E6E7E8';
  }

  get color1() {
    return this.selected ? '#FFFFFF' : '#636466';
  }

  get color2() {
    return this.selected ? '#91D9F8' : '#00549F';
  }

  get color3() {
    return this.selected ? '#636466' : '#FFFFFF';
  }

  get color4() {
    return this.selected ? '#84C5E2' : '#004383';
  }

  @Input() selected = false;
}
