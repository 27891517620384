import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Icons } from 'icon-lib';
import * as MarkdownIt from 'markdown-it';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { MarkDownConfig, MarkdownOutput } from '../models';
import { markdownItAttrsLeftDelimiter, markdownItAttrsRightDelimiter } from './markdown-attrs';

@Component({
  selector: 'kip-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownEditorComponent implements OnInit, OnDestroy {

  readonly #domSanitizer = inject(DomSanitizer);

  #textLabel = 'Content';
  #text = '';
  #textHTML: SafeHtml | null = null;
  readonly #md: MarkdownIt;
  readonly icons = Icons;

  isCollapsed = true;

  text$ = new Observable<string>();
  textSubject = new Subject<string>();
  textSubscription: Subscription | undefined;

  get textHTML() {
    return this.#textHTML;
  }

  @Input({ required: true }) set textLabel(value: string) {
    this.#textLabel = value;
  }

  get textLabel() {
    return this.#textLabel;
  }

  @Input({ required: true }) set text(value: string) {
    if (this.#text !== value) {
      this.#text = value ?? ''; // make sure it handles nulls
      const html = this.render(this.#text);
      this.#textHTML = this.#domSanitizer.bypassSecurityTrustHtml(html);
    }
  }

  get text() {
    return this.#text;
  }

  @ViewChild('input', { static: true }) textArea: ElementRef<HTMLTextAreaElement> | undefined;

  @Output() readonly update = new EventEmitter<MarkdownOutput>();

  constructor() {
    this.#md = (window as any).markdownit({ breaks: true });
    this.#md.use((window as any).markdownitSup);
    this.#md.use((window as any).markdownitSub);
    this.#md.use((window as any).markdownItAttrs, {
      leftDelimiter: markdownItAttrsLeftDelimiter,
      rightDelimiter: markdownItAttrsRightDelimiter,
      allowedAttributes: ['class']
    });
  }

  ngOnInit() {
    this.text$ = this.textSubject
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged());

    this.textSubscription = this.text$
      .subscribe(text => {
        this.isCollapsed = false;
        this.update.emit({ markdown: text, html: this.render(text) });
      });
  }

  ngOnDestroy() {
    if (this.textSubscription) {
      this.textSubscription.unsubscribe();
      this.textSubscription = undefined;
    }
  }

  change(markdown?: MarkDownConfig) {
    if (markdown && this.textArea) {
      const start = this.textArea.nativeElement.selectionStart || 0;
      const end = this.textArea.nativeElement.selectionEnd || 0;
      const selection = this.text.slice(start, end) || '';
      const replacement = markdown.prepend
        + (selection === '' ? markdown.placeholder : selection)
        + markdown.append;

      this.text = this.text.slice(0, start)
        + this.text.slice(start, end).replace(selection, replacement)
        + this.text.slice(end);

      this.textArea.nativeElement.focus();
    }
    this.textSubject.next(this.text);
  }

  render(input: string) {

    // want to make sure any hyperlinks generate always open in new window
    // otherwise they will lose their lesson

    return this.#md.render(input).replace(new RegExp('<a href', 'g'), '<a target=\'blank\' href');
  }

}
