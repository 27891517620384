import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-holiday',
  templateUrl: './monty-holiday.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyHolidayComponent implements MontyGraphic { }
