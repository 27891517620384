import { Injectable } from '@angular/core';
import { getLearningRangeFromPercentage, LearningRange } from 'pipes-directives-lib';
import { RegionId } from 'questions-lib';
import { HttpWithAbsoluteService } from 'service-lib';

import { SoundOptionGroup } from '../models';

export enum SoundKey {
  Correct = 'correct',
  Incorrect = 'incorrect',
  ActivityComplete = 'activityComplete',
  ActivityGreatJob = 'activityGreatJob',
  ActivityPerfectScore = 'activityPerfectScore',
  ActivityWellDone = 'activityWellDone'
}

@Injectable({ providedIn: 'root' })
export class StudentSoundService extends HttpWithAbsoluteService {

  getSoundKeyFromPercentage(percentage: number | null | undefined): SoundKey {
    if (percentage) {
      const learningRange = getLearningRangeFromPercentage(percentage);

      switch (learningRange) {
        case LearningRange.Perfect:
          return SoundKey.ActivityPerfectScore;
        case LearningRange.Excellent:
          return SoundKey.ActivityGreatJob;
        case LearningRange.Pass:
          return SoundKey.ActivityWellDone;
        case LearningRange.NeedsImprovement:
          return SoundKey.ActivityComplete;
        case LearningRange.Unknown:
          return SoundKey.ActivityComplete;
      }
    }

    return SoundKey.ActivityComplete;
  }

  getSoundOptionGroups(regionId: RegionId) {
    return this.get<SoundOptionGroup[]>(`assets/sound-option-groups/${regionId}`);
  }

  playSoundOptionGroup(id: number, soundOptionGroups: readonly SoundOptionGroup[], key: SoundKey) {
    const soundOptionGroup = soundOptionGroups.find(s => s.id === id);

    if (soundOptionGroup) {

      const soundChoices = soundOptionGroup.option[key];
      const randomIndex = Math.floor(Math.random() * soundChoices.length);
      const file = soundChoices[randomIndex];

      this.getSoundCached(file).then(response => {
        const audio = new Audio(response);
        audio.play();
      });
    }
  }
}
