<kip-expandable-setting header="Tutor Proficiencies" hint="Tutor Proficiencies" [expanded]="showTutorProficiencies"
  (toggled)="openProficiencies()">
  @for (proficiency of allProficiencies; track proficiency) {
  <div class="row g-0">
    <div class="col-12 custom-control custom-checkbox px-1 mb-3">
      <div class="form-check">
        <input #proficiencyCheckbox class="form-check-input" type="checkbox" [id]="'proficiency-' + proficiency.id"
          [name]="'proficiency-' + proficiency.id" [value]="proficiency.id" (click)="setProficiency(proficiency)">
        <label class="custom-control-label form-check-label form-check-label--append"
          [for]="'proficiency-' + proficiency.id">
          {{proficiency.description}}
        </label>
      </div>
    </div>
  </div>
  }
</kip-expandable-setting>