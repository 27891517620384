/* eslint-disable unicorn/no-typeof-undefined */

import { inject, Injectable } from '@angular/core';

import { DeviceDetectorService, DeviceInfo } from './device-detector.service';

declare let browserList: string[] | undefined;
declare let browserRegex: RegExp | undefined;

export type SupportedDeviceInfo = DeviceInfo & {
  supportedBrowsers: string[];
  supported: boolean;
  outOfDate: boolean;
  browserOutOfDateList: string[];
};

enum BrowserVendor {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  EDGE = 'MS-Edge'
}

export enum DeviceType {
  IPHONE = 'iPhone',
  IPAD = 'iPad',
  UNKNOWN = 'Unknown'
}

@Injectable({ providedIn: 'root' })
export class SupportedDeviceService {

  readonly #deviceDetectorService = inject(DeviceDetectorService);

  #browserList: string[] = [];
  #browserRegex: RegExp | undefined;

  constructor() {
    this.#browserRegex = typeof browserRegex === 'undefined' ? undefined : browserRegex;
    this.#browserList = typeof browserList === 'undefined' ? ['Unknown'] : browserList;
  }

  getCurrentDeviceInfo(): SupportedDeviceInfo {
    const deviceInfo = this.#deviceDetectorService.getDeviceInfo();
    const supportedBrowsers = this.#getSupportedBrowsers(deviceInfo);

    let browserOutOfDate = true;

    try {
      if (this.#browserRegex === undefined) {
        browserOutOfDate = false; // we don't want this showing up constantly for localhost
        console.log('No browser regex found');
      } else if (this.#browserRegex.test(navigator.userAgent)) {
        console.log('Browser is supported');
        browserOutOfDate = false;
      } else {
        console.log('Browser out ot date or not supported');
      }
    }
    catch {
      console.log('Unable to run regex check, blocked');
    }

    const supported = supportedBrowsers
      .map(o => o.toLowerCase())
      .includes(deviceInfo.browser.toLowerCase());

    return {
      ...deviceInfo,
      supported: supported,
      outOfDate: browserOutOfDate,
      supportedBrowsers: supportedBrowsers,
      browserOutOfDateList: this.#browserList
    };
  }

  #getSupportedBrowsers(deviceInfo: DeviceInfo): string[] {
    if (deviceInfo) {
      const { browser, device } = deviceInfo;
      if ((device === DeviceType.IPAD || device === DeviceType.IPHONE) && browser !== BrowserVendor.SAFARI) {
        return [BrowserVendor.SAFARI, BrowserVendor.CHROME, BrowserVendor.FIREFOX, BrowserVendor.EDGE];
      }
    }

    return [deviceInfo.browser];
  }
}
