export function getDistNumber() {
  let result: string | null = null;
  const queryResult = document.querySelector('meta[property=\'kiplearn:dist\']');
  if (queryResult) {
    result = queryResult.getAttribute('content');
  }

  if (result === null) {
    result = 'Unknown';
  }

  return result;
}
