<ng-template #diyContent>
  @if (diyRule) {
  @if (diyRule.activityRuleActivityCount || diyRule.activityRuleHomeworkActivityCount) {
  <div class="mb-2 fw-bold">DIY Activity Rule</div>
  @if (!diyRule.activityRuleActivities) {
  <ul class="ps-3 small text-start">
    <li class="mb-2">Triggers when the student has an activity in their last lesson plan for this topic</li>
    @if (diyRule.isManual && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look back at the last 10 activities and randomly pick {{ diyRule.activityRuleActivityCount }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look back at the last 10 activities and randomly pick {{ diyRule.activityRuleQuestionCount }}
      questions and
      create {{ diyRule.activityRuleActivityCount }} new computer activities
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look back at the last 10 activities's questions, determine the skillbuilder activities for the their
      questions
      and randomly pick {{ diyRule.activityRuleQuestionCount }} skillbuilder questions and create
      {{ diyRule.activityRuleActivityCount }} new computer activities
    </li>
    }
    @if (diyRule.isManual && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look back at the last 10 activities and randomly pick {{ diyRule.activityRuleHomeworkActivityCount
      }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look back at the last 10 activities and randomly pick {{ diyRule.activityRuleHomeworkQuestionCount
      }} questions and
      create {{ diyRule.activityRuleHomeworkActivityCount }} new computer activities
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look back at the last 10 activities's questions, determine the skillbuilder activities for the
      their
      questions
      and randomly pick {{ diyRule.activityRuleHomeworkQuestionCount }} skillbuilder questions and create
      {{ diyRule.activityRuleHomeworkActivityCount }} new computer activities
    </li>
    }
    @if (diyRule.activityRuleUsePreviousNode) {
    <li>
      Note: When picking last 10 activities, if there are not enough activities in the current topic, look back
      at the previous topic in the same parent topic.
    </li>
    }
    @if (!diyRule.activityRuleUsePreviousNode) {
    <li>
      Note: When picking last 10 activities, if there are not enough activities in the current topic, it will just
      use the activities available and not look back at the previous topic in the same parent topic.
    </li>
    }
  </ul>
  }
  @if (diyRule.activityRuleActivities) {
  <ul class="ps-3 small text-start">
    <li class="mb-2">Triggers when the student has an activity in their last lesson plan for this topic</li>
    @if (diyRule.isManual && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} and randomly pick {{
      diyRule.activityRuleActivityCount }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} and randomly pick {{
      diyRule.activityRuleQuestionCount }} questions and
      create {{ diyRule.activityRuleActivityCount }} new computer activities
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.activityRuleActivityCount) {
    <li class="mb-2">
      Lesson : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} questions, determine the skillbuilder
      activities for the their
      questions
      and randomly pick {{ diyRule.activityRuleQuestionCount }} skillbuilder questions and create
      {{ diyRule.activityRuleActivityCount }} new computer activities
    </li>
    }
    @if (diyRule.isManual && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} and randomly pick {{
      diyRule.activityRuleHomeworkActivityCount }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} and randomly pick {{
      diyRule.activityRuleHomeworkQuestionCount }} questions and
      create {{ diyRule.activityRuleHomeworkActivityCount }} new computer activities
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.activityRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework : Look at {{ diyRule.activityRuleActivities | diyActivityNames }} questions, determine the
      skillbuilder
      activities for the their
      questions
      and randomly pick {{ diyRule.activityRuleHomeworkQuestionCount }} skillbuilder questions and create
      {{ diyRule.activityRuleHomeworkActivityCount }} new computer activities
    </li>
    }
  </ul>
  }
  }
  @if (diyRule.subjectRuleActivityCount || diyRule.subjectRuleHomeworkActivityCount) {
  <div class="mb-2 fw-bold">DIY Subject Rule</div>
  <ul class="ps-3 small text-start">
    <li class="mb-2">Triggers when the student's last lesson plan subject is {{ diyRule.subjectRuleSubject }}</li>
    @if (diyRule.isManual && diyRule.subjectRuleActivityCount) {
    <li class="mb-2">
      Lesson: Look at the activities in this topic and randomly pick {{ diyRule.subjectRuleActivityCount }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.subjectRuleActivityCount) {
    <li class="mb-2">
      Lesson: Look at the activities in this topic and randomly pick {{ diyRule.subjectRuleQuestionCount }}
      questions and
      create a single new computer activity containing all of them.
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.subjectRuleActivityCount) {
    <li class="mb-2">
      Lesson: Look at the activities in this topic, determine the skillbuilder activities for the their questions
      and randomly pick {{ diyRule.subjectRuleQuestionCount }} skillbuilder questions and create
      a single new computer activity containing all of them.
    </li>
    }
    @if (diyRule.isManual && diyRule.subjectRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework: Look at the activities in this topic and randomly pick {{ diyRule.subjectRuleHomeworkActivityCount
      }} manual
      activities
    </li>
    }
    @if (!diyRule.isManual && !diyRule.isSkillbuilder && diyRule.subjectRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework: Look at the activities in this topic and randomly pick {{ diyRule.subjectRuleHomeworkQuestionCount
      }} questions and
      create a single new computer activity containing all of them.
    </li>
    }
    @if (!diyRule.isManual && diyRule.isSkillbuilder && diyRule.subjectRuleHomeworkActivityCount) {
    <li class="mb-2">
      Homework: Look at the activities in this topic, determine the skillbuilder activities for the their questions
      and randomly pick {{ diyRule.subjectRuleHomeworkQuestionCount }} skillbuilder questions and create
      a single new computer activity containing all of them.
    </li>
    }
  </ul>
  }
  }
</ng-template>

@if (diyRule && (diyRule.activityRuleActivityCount || diyRule.subjectRuleActivityCount ||
diyRule.activityRuleHomeworkActivityCount || diyRule.subjectRuleHomeworkActivityCount)) {
<span class="me-2 kip-badge kip-badge--outline kip-badge--small" placement="right top bottom" [ngbTooltip]="diyContent">
  <fa-icon [icon]="icons.diyRule" />
  DIY
</span>
}