/* eslint @typescript-eslint/no-useless-constructor : 0 */

import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'device-information-lib';
import { HttpService, REPORT_PROGRESS_OPTION } from 'service-lib';

import { JiraRequest, JiraResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class JiraService extends HttpService {

  readonly #deviceService = inject(DeviceDetectorService);

  send(files: readonly File[], request: JiraRequest) {
    const formData = new FormData();

    const deviceInfo = this.#deviceService.getDeviceInfo();

    request.metaData['Browser'] = deviceInfo.browser;
    request.metaData['Browser Version'] = deviceInfo.browser_version;
    request.metaData['Device'] = deviceInfo.device;
    request.metaData['Device Type'] = deviceInfo.deviceType;
    request.metaData['Os'] = deviceInfo.os;
    request.metaData['Os Version'] = deviceInfo.os_version;
    request.metaData['Screen Height'] = window.screen.height.toString();
    request.metaData['Screen Width'] = window.screen.width.toString();
    request.metaData['Window Height'] = window.innerHeight.toString();
    request.metaData['Window Width'] = window.innerWidth.toString();

    formData.append('jiraRequest', new Blob([JSON.stringify(request)], {
      type: 'application/json'
    }));
    let fileIndex = 1;
    for (const file of files) {
      formData.append(`file${fileIndex}`, file);
      fileIndex++;
    }
    return this.post<HttpProgressEvent | HttpResponse<JiraResponse>>('tutor/jira/send', formData, undefined, REPORT_PROGRESS_OPTION);
  }
}
