@if (organisationGoalNode && organisationGoalNode.hasChildren) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between border-top py-2 px-3"
  (click)="expand = !expand">
  <div class="flex-column text-start">
    {{organisationGoalNode.name }}
  </div>
  <div class="flex-column text-end">
    <fa-icon [icon]="expand ? icons.chevrons.down : icons.chevrons.right" />
  </div>
</div>
}

@if (organisationGoalNode && !organisationGoalNode.hasChildren) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between border-top py-2 px-3"
  [id]="'CN' + organisationGoalNode.id.toString()" (click)="selectNode(organisationGoalNode, $event)">
  <div class="flex-column text-start">
    {{organisationGoalNode.name }}
    @if (organisationGoalNode.description) {
    <span class="text-muted"> - {{organisationGoalNode.description }}</span>
    }
  </div>
  <div class="flex-column text-end">
    <fa-icon class="me-3" [icon]="icons.preview.solid" />
  </div>
</div>
}

@if (expand) {
<div>
  @if (organisationGoalNode && organisationGoalNode.hasChildren) {
  <div>
    @for (node of subNodes; track node) {
    <div class="ps-4">
      <kip-organisation-goal-node [organisationGoalNode]="node" (selected)="selected.emit($event)" />
    </div>
    }
  </div>
  }
</div>
}