import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

import { BlockedEmojis } from '../emojis/blocked-emojis';

interface EmojiMessage {
  readonly text?: string;
  readonly emoji?: { shortName: string, skin: 1 | 2 | 3 | 4 | 5 | 6 };
}

@Component({
  selector: 'kip-emoji-message',
  templateUrl: './emoji-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiMessageComponent {

  #message = '';
  readonly #shortNames: string[] = [];
  #emojiMessages: EmojiMessage[] = [];

  get emojiMessages() {
    return this.#emojiMessages;
  }

  @Input() warning = false;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set message(value: string) {
    if (this.#message !== value) {
      this.#emojiMessages = this.#determineEmojiMessages(value);
      this.#message = value;
    }
  }

  get message() {
    return this.#message;
  }

  /* eslint-enable kip/no-unused-public-members */

  constructor(private readonly emojiSearch: EmojiSearch) {
    const shortNames: string[] = [];
    for (const key in this.emojiSearch.emojisList) {
      const shortName = this.emojiSearch.emojisList[key].shortName;

      if (BlockedEmojis.some(bm => bm.shortName === shortName)) {
        continue;
      }

      shortNames.push(shortName);
    }

    this.#shortNames = shortNames;
  }

  #determineEmojiMessages(searchValue: string) {
    const emojisFound: { shortName: string, emojiName: string, skin: 1 | 2 | 3 | 4 | 5 | 6, index: number }[] = [];
    const emojiMessages: EmojiMessage[] = [];

    // find all emojis in the search value
    for (const shortName of this.#shortNames) {
      for (let skin = 0; skin < 7; skin++) {
        const skinValue = skin === 0 ? '' : `_st${skin}`;
        const emojiName = `:${shortName}${skinValue}:`;

        // the same emoji could appear multiple times in the string
        let startPosition = 0;
        let exitLoop = false;
        while (!exitLoop) {
          const index = searchValue.indexOf(emojiName, startPosition);
          if (index !== -1) {
            emojisFound.push({ shortName: shortName, emojiName: emojiName, skin: skin === 0 ? 1 : skin as any, index: index });
            startPosition = index + emojiName.length;
          } else {
            exitLoop = true;
          }
        }
      }
    }

    // sort by index
    emojisFound.sort((a, b) => a.index - b.index);

    // if no emojis found, return the search value as a text message
    if (emojisFound.length === 0) {
      emojiMessages.push({ text: searchValue });
    } else {

      // otherwise split up the message as required
      let startPosition = 0;
      for (const emojiFound of emojisFound) {
        if (emojiFound.index !== 1) {
          emojiMessages.push({ text: searchValue.slice(startPosition, emojiFound.index) });
          startPosition = emojiFound.index + emojiFound.emojiName.length;
        }
        emojiMessages.push({ emoji: { shortName: emojiFound.shortName, skin: emojiFound.skin } });
      }
      if (startPosition > 0) {
        emojiMessages.push({ text: searchValue.slice(Math.max(0, startPosition)) });
      }
    }

    return emojiMessages;
  }

}
