/* eslint  @typescript-eslint/naming-convention : 0 */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EventType, LessonLog, LessonLogActivityOpened, LessonLogBroadcastingPublishFailure, RecordingStatus, Severity } from 'message-lib';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { TutorSettings } from 'ui-common-lib';

import { Alert, AlertActionName, AlertSource, SessionLesson } from '../../models';
import * as fromAlert from '../alerts';
import * as fromLesson from '../lesson';
import * as fromLogs from '../log';
import { TutorState } from '../state';
import * as fromState from '../state';

type AlertHandler = {
  readonly [eventType in EventType]?: (lesson: SessionLesson, store: Store<TutorState>,
    tutorSettings: TutorSettings | undefined, log: LessonLog) => void;
};

const clearStatusAlerts = (lesson: SessionLesson, store: Store<TutorState>) => {
  store.dispatch(fromAlert.removeMatchingAlertsAction({
    shouldBeRemoved: alert => alert.lessonId === lesson.lessonId && alert.source === AlertSource.StudentStatus
  }));
};

const clearAlerts = (lesson: SessionLesson, store: Store<TutorState>, alertSource: AlertSource) => {
  store.dispatch(fromAlert.removeMatchingAlertsAction({
    shouldBeRemoved: alert => alert.lessonId === lesson.lessonId && alert.source === alertSource
  }));
};

// Note
// to add new actions use <action name="">Display Text</action>
// If you need new names, ensure you add them to AlertActionName enum

const handlers: AlertHandler = {
  'StoppedLookingTab': (lesson, store, tutorSettings) => {
    if (tutorSettings?.showStoppedLookingAlerts) {
      clearStatusAlerts(lesson, store);
      const alert = new Alert(Severity.Warning,
        `${lesson.studentUniqueName} stopped looking at KipLearn. Switch to <action name="${AlertActionName.Teach}">Teach mode</action> to investigate.`,
        new Date(), AlertSource.StudentStatus, lesson.lessonId, true);
      store.dispatch(fromAlert.addAlertAction(
        {
          alert: alert
        }));
    }
  },
  'ActivityOpened': (lesson, store, _tutorSettings, log: LessonLogActivityOpened) => {
    if (log.values?.recordingStatus === RecordingStatus.RecordingRequiredAndFailed) {
      clearStatusAlerts(lesson, store);
      const alert = new Alert(Severity.Warning,
        `${lesson.studentUniqueName} started an activity with automatic video recording but it failed to start, please get them to close and reopen activity.`,
        new Date(), AlertSource.RecordingStatus, lesson.lessonId, true);
      store.dispatch(fromAlert.addAlertAction(
        {
          alert: alert
        }));
    }
  },
  'StopBroadcastingObservers': (lesson, store, _tutorSettings) => {
    clearAlerts(lesson, store, AlertSource.StopBroadcastingObservers);
    const alert = new Alert(Severity.Warning,
      `Broadcasting stopped - this will appear in lesson logs <action name="${AlertActionName.StartBroadcasting}">Start broadcasting again</action>.`,
      new Date(), AlertSource.StopBroadcastingObservers, lesson.lessonId, true);
    store.dispatch(fromAlert.addAlertAction(
      {
        alert: alert
      }));
  },
  'BroadcastingPublishDenied': (lesson, store, _tutorSettings) => {
    clearAlerts(lesson, store, AlertSource.BroadcastingPublishDenied);
    const alert = new Alert(Severity.Warning,
      `Broadcasting denied - this will appear in lesson logs <action name="${AlertActionName.StartBroadcasting}">Start broadcasting again</action>.`,
      new Date(), AlertSource.BroadcastingPublishDenied, lesson.lessonId, true);
    store.dispatch(fromAlert.addAlertAction(
      {
        alert: alert
      }));
  },
  'BroadcastingPublishFailure': (lesson, store, _tutorSettings, log: LessonLogBroadcastingPublishFailure) => {
    clearAlerts(lesson, store, AlertSource.BroadcastingPublishFailure);
    const alert = new Alert(Severity.Warning,
      `Broadcasting failed - this will appear in lesson logs (${log.values?.name} - ${log.values?.message}) <action name="${AlertActionName.StartBroadcasting}">Start broadcasting again</action>.`,
      new Date(), AlertSource.BroadcastingPublishFailure, lesson.lessonId, true);
    store.dispatch(fromAlert.addAlertAction(
      {
        alert: alert
      }));
  },
  'StoppedInteracting': (lesson, store, tutorSettings) => {
    if (tutorSettings?.showStoppedInteractingAlerts) {
      clearStatusAlerts(lesson, store);
      const alert = new Alert(Severity.Warning,
        `${lesson.studentUniqueName} stopped interacting with KipLearn for more than 2 minutes. Switch to <action name="${AlertActionName.Teach}">Teach mode</action> to investigate.`,
        new Date(), AlertSource.StudentStatus, lesson.lessonId, true);
      store.dispatch(fromAlert.addAlertAction(
        { alert: alert }));
    }
  },
  'LessonDisconnected': (lesson, store, tutorSettings) => {
    if (tutorSettings?.showDisconnectedAlerts) {
      clearStatusAlerts(lesson, store);
      const alert = new Alert(Severity.Danger,
        `${lesson.studentUniqueName} disconnected from the lesson. Switch to <action name="${AlertActionName.Teach}">Teach mode</action> to investigate.`,
        new Date(), AlertSource.StudentStatus, lesson.lessonId, true);
      store.dispatch(fromAlert.addAlertAction(
        { alert: alert }));
    }
  },
  'StartedInteracting': (lesson, store) => clearStatusAlerts(lesson, store),
  'StartedLookingTab': (lesson, store) => clearStatusAlerts(lesson, store),
  'LessonOpened': (lesson, store) => clearStatusAlerts(lesson, store)
};

@Injectable()
export class LogEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<TutorState>);

  logAdded$: Observable<void> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLogs.addAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSessionLessons),
        this.#store.select(fromState.selectTutorSettings)
      ),
      map(([action, lessons, tutorSettings]) => {
        const handler = handlers[action.log.eventType];
        if (handler) {
          const lesson = lessons.find(l => l && l.lessonGuid === action.lessonGuid);
          if (lesson) {
            handler(lesson, this.#store as Store<TutorState>, tutorSettings, action.log);
          }
        }
      })
    );
  },
    { dispatch: false }
  );
}
