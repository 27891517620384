import { createAction, props } from '@ngrx/store';

import { LessonPlan, LessonPlanActivity, LessonPlanOrderUpdate } from '../../models';

enum PlanActionTypes {
  Accept = 'Tutor > Plan > Accept',
  Accepted = 'Tutor > Plan > Accepted',
  CopyAll = 'Tutor > Plan > Copy All',
  AddHomework = 'Tutor > Plan > Add Homework',
  AddLesson = 'Tutor > Plan > Add Lesson',
  ClearActivities = 'Tutor > Plan > Clear Activities',
  DeleteActivity = 'Tutor > Plan > Delete Activity',
  Get = 'Tutor > Plan > Get',
  CopyAllCompleted = 'Tutor > Plan > Copy All Completed',
  Load = 'Tutor > Plan > Load',
  OrderActivities = 'Tutor > Plan > Order Activities',
  ReplaceActivity = 'Tutor > Plan > Replace Activity',
  UpdateMarkdown = 'Tutor > Plan > Update Markdown',
  Update = 'Tutor > Plan > Update',
  UpdateSkillbuilders = 'Tutor > Plan > Update Skillbuilders',
  Error = 'Tutor > Plan > Error'
}

export const orderActivitiesAction = createAction(
  PlanActionTypes.OrderActivities,
  props<{
    readonly lessonPlanOrder: LessonPlanOrderUpdate[];
    readonly isHomework: boolean;
    readonly lessonPlanId: number;
  }>()
);

export const loadAction = createAction(
  PlanActionTypes.Load,
  props<{
    readonly lessonPlanId: number;
    readonly lessonPlan: LessonPlan;
  }>()
);

export const getAction = createAction(
  PlanActionTypes.Get,
  props<{
    readonly lessonPlanId: number;
  }>()
);

export const updateAction = createAction(
  PlanActionTypes.Update,
  props<{
    readonly lessonPlanId: number;
  }>()
);

export const errorAction = createAction(
  PlanActionTypes.Error,
  props<{
    readonly lessonPlanId: number;
    readonly error: string;
  }>()
);

export const updateSkillbuildersAction = createAction(
  PlanActionTypes.UpdateSkillbuilders,
  props<{
    readonly lessonPlanId: number;
    readonly lessonPlan: LessonPlan;
  }>()
);

export const clearActivitiesAction = createAction(
  PlanActionTypes.ClearActivities,
  props<{
    readonly lessonPlanId: number;
    readonly isHomework: boolean;
  }>()
);

export const deleteActivityAction = createAction(
  PlanActionTypes.DeleteActivity,
  props<{
    readonly lessonPlanId: number;
    readonly activityId: number;
    readonly isHomework: boolean;
    readonly lessonActivityPlanId: number | undefined;
  }>()
);

export const replaceActivityAction = createAction(
  PlanActionTypes.ReplaceActivity,
  props<{
    readonly activityId: number;
    readonly isHomework: boolean;
    readonly lessonPlanId: number;
    readonly newActivity: LessonPlanActivity;
    readonly originalActivityId?: number;
  }>()
);

export const updateMarkdownAction = createAction(
  PlanActionTypes.UpdateMarkdown,
  props<{
    readonly lessonPlanId: number;
    readonly activityId: number;
    readonly isHomework: boolean;
    readonly markdown: string;
    readonly html: string;
  }>()
);

export const addLessonAction = createAction(
  PlanActionTypes.AddLesson,
  props<{
    readonly lessonPlanId: number;
    readonly activities: readonly LessonPlanActivity[];
  }>()
);

export const addHomeworkAction = createAction(
  PlanActionTypes.AddHomework,
  props<{
    readonly lessonPlanId: number;
    readonly activities: readonly LessonPlanActivity[];
  }>()
);

export const acceptAction = createAction(
  PlanActionTypes.Accept,
  props<{
    readonly lessonPlanId: number;
    readonly notifyChanges: boolean;
  }>()
);

export const acceptedAction = createAction(
  PlanActionTypes.Accepted,
  props<{
    readonly lessonPlanId: number;
    readonly isDIYCompatible: boolean;
    readonly hasAssessmentActivities: boolean;
  }>()
);

export const copyAllAction = createAction(
  PlanActionTypes.CopyAll,
  props<{
    readonly sourceLessonPlanId: number;
    readonly destinationLessonPlanIds: number[];
    readonly notifyChanges: boolean;
  }>()
);

export const copyAllCompletedAction = createAction(
  PlanActionTypes.CopyAllCompleted,
  props<{
    readonly lessonPlanIds: number[];
  }>()
);
