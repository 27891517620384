/* eslint-disable @typescript-eslint/naming-convention */

import { LessonChat, LessonLogAuthor, Message, MessageSubType, SessionLessonChatInfo } from './models';
import { EventType } from './services';

type MessageFormatter = {
  readonly [eventType in EventType]?: (log: LessonChat) => string;
};

const tutorFormatters: MessageFormatter = {
  'Chat': chat => chat.values.message
};

const studentFormatters: MessageFormatter = {
  'Chat': chat => chat.values.message,
  'SystemChat': chat => chat.values.message
};

export interface ChatState {
  readonly entityIds: {
    readonly [lessonGuid: string]: readonly string[];
  };
  readonly entities: {
    readonly [eventId: string]: LessonChat;
  };
  readonly chatGroups: readonly string[];
  readonly recipientsTyping: string[];
}

interface ChatAction {
  readonly lessonGuid: string;
  readonly chatMessages: readonly LessonChat[];
}

export function loadChat(state: ChatState, action: ChatAction) {
  let entityIds = state.entityIds;
  let entities = state.entities;
  const chatGroups = state.chatGroups;

  for (const chat of action.chatMessages) {
    const eventIds = entityIds[action.lessonGuid] || [];

    // Only add logs not already in the store
    entityIds = { ...entityIds, [action.lessonGuid]: [...eventIds, chat.eventId] };
    entities = { ...entities, [chat.eventId]: chat };
  }

  return {
    entityIds: entityIds,
    entities: entities,
    chatGroups: chatGroups,
    recipientsTyping: state.recipientsTyping
  };
}

export function chatConverter(ids: {
  readonly [lessonGuid: string]: readonly string[];
}, entities: {
  readonly [eventId: string]: LessonChat;
},
  lessons: SessionLessonChatInfo[]) {
  // Map the logs for the lesson log ids
  let messages: (Message | undefined)[] = [];

  if (lessons) {
    let chatGroups: string[] = [];

    for (const lesson of lessons) {
      const chatIds = ids[lesson.lessonGuid];

      if (chatIds) {
        const formatted = chatIds.map(chatId => {
          const entity = entities[chatId];

          // Multiples of the same log will be defined for the tutor - one for each lesson
          // We therefore need to filter them by event group to show a single instance
          if (entity && !chatGroups.includes(entity.chatGroup)) {

            // Find the event type formatter and build the message object
            const formatters = entity.author === LessonLogAuthor.Student ? studentFormatters : tutorFormatters;
            const formatter = formatters[entity.eventType];

            if (formatter) {
              const text = formatter(entity);

              if (text) {

                // Track the log group for filtering
                chatGroups = [...chatGroups, entity.chatGroup.toUpperCase()];

                return {
                  id: entity.eventId,
                  type: entity.eventType,
                  subType: entity.author === LessonLogAuthor.Student ? MessageSubType.ChatIncoming : MessageSubType.ChatOutgoing,
                  timestamp: entity.timestamp,
                  text: text,
                  warning: entity.values.warning,
                  lessonGuid: lesson.lessonGuid,
                  sender: entity.author === LessonLogAuthor.Student ? lesson.studentUniqueName : entity.author === LessonLogAuthor.AI ? 'Monty AI' : 'Tutor (me)'
                };
              }
            }
          }

          return undefined;
        });

        messages = [...messages, ...formatted];
      }
    }
  }

  return messages.filter((message): message is Message => message !== undefined);
}
