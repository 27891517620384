/* eslint  @typescript-eslint/require-array-sort-compare : 0 */

import { sortBy } from '../sort';

interface IterateByOptions<T> {
  ignore?: (item: T) => boolean;
  sort?: {
    direction: 'asc' | 'desc';
    pluck?: keyof T;
  };
  start?: T;
}

export function iterateBy<T>(options: IterateByOptions<T>) {

  return function* createCustomGenerator(items: T[]) {
    let source = [...items];

    if (options.sort) {
      const { direction, pluck } = options.sort;

      if (pluck) {
        const compareFn = sortBy(pluck, direction);
        source = source.sort(compareFn);
      } else if (direction === 'asc') {
        source = source.sort();
      } else {
        source = source.sort().reverse();
      }
    }

    const ignore = options.ignore ?? (() => false);
    const length = items.length;
    const startOrdinal = options.start ? items.indexOf(options.start) : 0;
    let ordinal = startOrdinal;

    do {
      const item = source[ordinal === length ? 0 : ordinal];

      ordinal = ordinal === length ? 0 : ordinal + 1;

      if (ordinal === startOrdinal) {
        return ignore(item) ? null : item;
      }

      if (!ignore(item)) {
        yield item;
      }

    } while (ordinal !== startOrdinal);

    return undefined;
  };
}
