import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ContentHistory } from '../../models';

@Component({
  selector: 'kip-activity-history',
  templateUrl: './activity-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityHistoryComponent {

  @Input({ required: true }) contentHistory: ContentHistory | undefined = undefined;

}
