@if (isVisible) {
<div class="d-flex align-items-baseline justify-content-end mb-3 ms-auto">
  <span class="small">Ratings filter</span>
  @if (ranking !== null) {
  <span class="small ms-1 text-underline cursor-pointer" (click)="changeRanking(null)">(clear)</span>
  }
  <div class="kip-star-rating">
    <fa-icon class="kip-star-rating__star s1" [fixedWidth]="true" [icon]="icons.points.star.solid"
      [ngClass]="ranking && (ranking === 5 || ranking > 5)  ? 'text-gold' : ''" (click)="changeRanking(5)" />
    <fa-icon class="kip-star-rating__star s2" [fixedWidth]="true" [icon]="icons.points.star.solid"
      [ngClass]="ranking && (ranking === 4 || ranking > 4)  ? 'text-gold' : ''" (click)="changeRanking(4)" />
    <fa-icon class="kip-star-rating__star s3" [fixedWidth]="true" [icon]="icons.points.star.solid"
      [ngClass]="ranking && (ranking === 3 || ranking > 3)  ? 'text-gold' : ''" (click)="changeRanking(3)" />
    <fa-icon class="kip-star-rating__star s4" [fixedWidth]="true" [icon]="icons.points.star.solid"
      [ngClass]="ranking && (ranking === 2 || ranking > 2)  ? 'text-gold' : ''" (click)="changeRanking(2)" />
    <fa-icon class="kip-star-rating__star s5" [fixedWidth]="true" [icon]="icons.points.star.solid"
      [ngClass]="ranking && (ranking === 1 || ranking > 1)  ? 'text-gold' : ''" (click)="changeRanking(1)" />
  </div>
</div>
}