import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JiraRequestType, JiraStyleType } from 'jira-lib';
import { RegionId } from 'questions-lib';

import { ExploreActivity } from '../../models';

@Component({
  selector: 'kip-explore-manual',
  templateUrl: './explore-manual.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreManualComponent {

  readonly #jiraMetaData: { [prop: string]: string } = {};

  readonly jiraStyleTypeContent = JiraStyleType.Content;

  get allowToggleAnswers() {
    return this.activity?.pdfSolutionFile !== undefined && this.activity?.pdfSolutionFile !== null;
  }

  get pdfToView() {
    if (this.activity) {
      if (this.answers && this.activity.pdfSolutionFile) {
        return this.activity.pdfSolutionFile;
      }
      return this.activity.pdfFile;
    }
    return '';
  }

  get jiraMetaData() {
    return this.#jiraMetaData;
  }

  get jiraRequestType() {
    return JiraRequestType.Activity;
  }

  get jiraUId() {
    return this.activity?.contentGuid;
  }

  answers = false;

  @Input({ required: true }) activity: ExploreActivity | undefined;
  @Input({ required: true }) allowJira = true;
  @Input({ required: true }) regionId = RegionId.Australia;

  toggleAnswers() {
    this.answers = !this.answers;
  }
}
