import { LearningRange } from '../models';
import { getLearningRangeFromPercentage } from './get-learning-range-from-percentage';

export function getTextColorFromPercentage(percentage: number | undefined) {

  if (percentage === undefined) {
    return '';
  }

  const learningRange = getLearningRangeFromPercentage(percentage);

  switch (learningRange) {
    case LearningRange.Perfect:
      return '#18773E';
    case LearningRange.Excellent:
      return '#18773E';
    case LearningRange.Pass:
      return '#97690D';
    case LearningRange.NeedsImprovement:
      return '#A03535';
    default:
      return '';
  }
}
