/* eslint @typescript-eslint/no-dynamic-delete : 0 */

import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { QuestionSoundService } from '../services';
import { QuestionBehaviour } from './question-behaviour';
import { QuestionBehaviourAction } from './question-behaviour-action';
import { QuestionBehaviourGroup } from './question-behaviour-group';
import { QuestionBehaviourSequence } from './question-behaviour-sequence';
import { RegionId } from './region-id';

export enum SoundState {
  Unknown = 0,
  Playing = 1,
  Finished = 2,
  Error = 3
}

type Disposer = () => void;

interface ActionMap {
  [id: string]: QuestionBehaviourAction;
}

interface SequenceMap {
  [id: string]: QuestionBehaviourSequence;
}

interface DisposerMap {
  [id: string]: Disposer;
}

export class QuestionBehaviourController {

  #subscriptions: Subscription[] = [];
  #audio: HTMLAudioElement | undefined;

  private readonly invokerFactories = {
    sound: (action: QuestionBehaviourAction, callback: (error: any) => void) => this.#createSoundInvoker(action, callback)
  };

  private readonly actions: ActionMap = {};
  private readonly sequences: SequenceMap = {};
  private readonly disposers: DisposerMap = {};

  private readonly _actionStart$ = new Subject<string>();
  private readonly _actionEnd$ = new Subject<string>();
  private readonly _sequenceStart$ = new Subject<string>();
  private readonly _sequenceEnd$ = new Subject<string>();
  private readonly _soundState = new Subject<SoundState>();

  regionId = RegionId.Australia;

  get soundState$(): Observable<SoundState> {
    return this._soundState;
  }

  get actionStart$(): Observable<string> {
    return this._actionStart$;
  }

  get actionEnd$(): Observable<string> {
    return this._actionEnd$;
  }

  get sequenceStart$(): Observable<string> {
    return this._sequenceStart$;
  }

  get sequenceEnd$(): Observable<string> {
    return this._sequenceEnd$;
  }

  constructor(behaviour: QuestionBehaviour, private readonly soundService: QuestionSoundService) {

    // Extract the pipeline groups and actions
    if (behaviour) {
      if (behaviour.actions) {
        this.actions = behaviour.actions.reduce((map: { [key: string]: QuestionBehaviourAction }, action) => {
          map[action.id] = action;
          return map;
        }, {});
      }

      if (behaviour.sequences) {
        this.sequences = behaviour.sequences.reduce((map: { [key: string]: QuestionBehaviourSequence }, sequence) => {
          map[sequence.id] = sequence;
          return map;
        }, {});
      }
    }
  }

  onDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  hasAction(id: string): boolean {
    return !!this.actions[id];
  }

  hasSequence(id: string): boolean {
    return !!this.sequences[id];
  }

  invokeActions(...ids: string[]): Observable<void> {

    // Ensure all previous sounds are disposed
    this.dispose();

    // Create a dynamic group to invoke the actions
    return this.#invokeGroup(0, [ids]);
  }

  invokeSequence(id: string): Observable<void> {

    // Ensure all previous sounds are disposed
    this.dispose();

    // Find and invoke the sequence groups
    const sequence = this.sequences[id];

    if (sequence) {
      this._sequenceStart$.next(id);

      // Invoke the sequences groups start at index 0
      // Tap into the observable so other observables can be triggered
      return this.#invokeGroup(0, sequence.groups).pipe(
        tap(() => {
          this._sequenceEnd$.next(id);
        })
      );
    }

    return of(undefined);
  }

  dispose() {

    this.#stopSound();

    // Get all the currently registered disposers and invoke them
    const ids = Object.keys(this.disposers);

    for (const id of ids) {
      const disposer = this.disposers[id];

      if (disposer) {
        disposer();
      }
    }
  }

  #invokeGroup(index: number, groups: readonly QuestionBehaviourGroup[]): Observable<void> {
    const subject = new Subject<void>();

    if (index < groups.length) {

      // Resolve the group ids as their associated actions
      const group = groups[index];
      const actions = group
        .map(id => this.actions[id])
        .filter(action => !!action);

      // Create a subject observable for each action and forkJoin to wait for them all to complete
      const subjects = actions.map(action => new BehaviorSubject<QuestionBehaviourAction>(action));

      this.#subscriptions.push(
        forkJoin(subjects).subscribe(
          {
            next: args => {
              // Emit a message for the actions invoked
              for (const action of args) {
                this._actionEnd$.next(action.id);
              }

              // Move into the next group (if there is one)
              // Else ensure the current observable emits
              if (index + 1 < groups.length) {
                this.#subscriptions.push(
                  this
                    .#invokeGroup(index + 1, groups)
                    .subscribe(() => subject.next()));
              } else {
                subject.next();
              }
            },
            error: (error: any) => subject.error(error)
          }));

      // Invoke the actions using the created subjects
      this.#invokeGroupActions(subjects, actions);
    }

    return subject;
  }

  #invokeGroupActions(subjects: BehaviorSubject<QuestionBehaviourAction>[], actions: QuestionBehaviourAction[]) {

    // For each subject invoke the associated action and complete the observable
    for (const [index, subject] of subjects.entries()) {
      const action = actions[index];
      const invoker = this.invokerFactories[action.type];

      this._actionStart$.next(action.id);

      if (invoker) {
        const disposer = invoker(action, (error: any) => {

          // Remove the disposer as the action has completed
          delete this.disposers[action.id];

          // Resolve the subject based on the callback response
          if (error) {
            subject.error(error);
          } else {
            subject.complete();
          }
        });

        // Cache the disposer so the action can be disposed when the controller is
        if (disposer) {
          this.disposers[action.id] = () => {
            disposer();
          };
        }
      } else {

        // If no invoker is found, simply complete
        subject.complete();
      }
    }
  }

  #stopSound() {
    if (this.#audio) {
      this.#audio.pause();
      this.#audio = undefined;
    }
  }

  #playSound(audioUrl: string, callback: (error?: any) => void) {
    const audio = new Audio(audioUrl);
    this.#audio = audio;
    const handler = (error?: any) => {
      callback(error);
    };

    // do not enable no-unnecessary-callback-wrapper
    // breaks the playing of sounds for some reason

    audio.addEventListener('pause', () => handler());
    audio.addEventListener('ended', () => handler());
    audio.addEventListener('error', error => handler(error));

    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted

    this._soundState.next(SoundState.Playing);

    const playPromise = this.#audio.play();

    if (playPromise !== undefined) {
      playPromise.then(() => () => {
        audio.pause();
        this._soundState.next(SoundState.Finished);
      })
        .catch((error: unknown) => {
          this._soundState.next(SoundState.Error);
          callback(error);
        });
    }
  }

  #createSoundInvoker(action: QuestionBehaviourAction, callback: (error?: any) => void): Disposer {

    // Create the audio object
    // Handle the end/error event so subject completion can be propagated
    // The pause of bound for when the sound is disposed and so the callback can be invoked

    let url = '';

    if (action.soundRegions) {
      const regionId = this.regionId;

      // Try and find sound file for region

      let soundForRegion = action.soundRegions.find(s => s.regionId === regionId && s.url);

      // If not found try looking elsewhere

      if (!soundForRegion && regionId === RegionId.SouthAfrica) {
        soundForRegion = action.soundRegions.find(s => s.regionId === RegionId.UnitedKingdom && s.url);
      }

      // still not found

      if (!soundForRegion) {
        soundForRegion = action.soundRegions.find(s => s.regionId === RegionId.Australia && s.url);
      }

      if (soundForRegion) {
        url = soundForRegion.url;
      }
    }

    if (url) {
      this.soundService.getSoundFile(url).then(response => {
        this.#playSound(response, callback);
      });
    } else {
      this.#playSound('assets/sounds/missing-sound-file.mp3', callback);
    }

    return () => {
      // ignore
    };
  }
}
