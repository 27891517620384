@if (assessmentResults) {
<div class="h-100 kip-card kip-card--summary">
  <div class="kip-card__header">
    <fa-icon class="me-2" size="lg" [fixedWidth]="true" [icon]="icons.assessment" />
    <h2 class="fw-light">Assessment Results</h2>
  </div>
  <div class="kip-card__body">
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading col">Reading Age</div>
        <div class="kip-info__entry">{{ readingAge?.result || '-' }}</div>
        @if (readingAge && readingAge.dateTime) {
        <div class="kip-info__subtitle">{{ readingAge.dateTime | dateTime }}
        </div>
        }
      </div>
      <div class="col kip-info">
        <div class="kip-info__heading col">Comprehension</div>
        <div class="kip-info__entry">{{ comprehension?.result || '-' }}</div>
        @if (comprehension && comprehension.dateTime) {
        <div class="kip-info__subtitle">{{ comprehension.dateTime |
          dateTime }}</div>
        }
      </div>
    </div>
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading col">Spelling</div>
        <div class="kip-info__entry">{{ spelling?.result || '-' }}</div>
        @if (spelling && spelling.dateTime) {
        <div class="kip-info__subtitle">{{ spelling.dateTime | dateTime }}</div>
        }
      </div>
      <div class="col kip-info">
        <div class="kip-info__heading col">Maths EOG</div>
        <div class="kip-info__entry">{{ mathsEog?.result || '-' }}</div>
        @if (mathsEog && mathsEog.dateTime) {
        <div class="kip-info__subtitle">{{ mathsEog.dateTime | dateTime }}</div>
        }
      </div>
    </div>
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading col">Early Reading</div>
        <div class="kip-info__entry">{{ earlyReading?.result || '-' }}</div>
        @if (earlyReading && earlyReading.dateTime) {
        <div class="kip-info__subtitle">{{ earlyReading.dateTime |
          dateTime }}</div>
        }
      </div>
    </div>
  </div>
</div>
}