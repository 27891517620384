<div class="kip-form-group" [ngClass]="{'form-group-invalid' : stripeElement | isFormControlInvalid }">
  <label for="bank-account-info">Account Information</label>
  <div class="kip-stripe">
    <div #bankAccountInfo id="bank-account-info"></div>
  </div>
  @if (bankName) {
  <span class="p-1 small">{{bankName}}</span>
  }
  <span class="form-control-invalid">
    {{stripeElement.getError(bankAccountValidationError) || 'Please provide valid account details.' }}
  </span>
</div>
<kip-form-control-text id="bankAccountName" label="Account Name" [control]="bankAccountName" />
<div class="kip-form-group" [ngClass]="{'form-group-invalid' : emailAddress | isFormControlInvalid }">
  <div class="kip-choice">
    <div class="form-check">
      <input class="form-check-input" id="useAlternateEmailAddress" name="useAlternateEmailAddress" type="checkbox"
        [checked]="useAlternateEmailAddress" (change)="changeUseAlternateEmailAddress()">
      <label class="form-check-label form-check-label--append" for="useAlternateEmailAddress">
        Use Alternate Email Address
      </label>
    </div>
  </div>
  @if (useAlternateEmailAddress) {
  <kip-form-control-text id="bankAccountEmail" label="Email" type="email" [control]="emailAddress" />
  }
  <kip-control-error clientSideErrorMessage="Please provide a valid account email address" [control]="emailAddress" />
</div>
<div class="kip-form-group">
  <div class="kip-choice">
    <div class="form-check">
      <input class="form-check-input" id="agree-to-ddr" name="agree-to-ddr" type="checkbox"
        [checked]="agreeToDirectDebit" (change)="agreeToDirectDebit = !agreeToDirectDebit">
      <label class="form-check-label form-check-label--append" for="agree-to-ddr">
        I agree to this Direct Debit Request
      </label>
    </div>
  </div>
</div>
<!-- Display mandate acceptance text. -->
<div class="kip-form-group small" id="mandate-acceptance">
  By checking this box, you agree to this Direct Debit Request and the
  <a href="https://stripe.com/au-becs-dd-service-agreement/legal">Direct Debit Request service agreement</a>, and
  authorise Stripe
  Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID
  number 507156 (“Stripe”) to debit your account through the Bulk
  Electronic Clearing System (BECS) on behalf of Kip McGrath Education Australia Pty Ltd (the "Merchant")
  for any amounts separately communicated to you by the Merchant. You
  certify that you are either an account holder or an authorised
  signatory on the account listed above.
</div>
@if (!submitExternal) {
<div class="kip-form-group">
  @if (!directDebitSummaryForm.hasError(directDebitSetupError) ) {
  <button class="btn btn-lg w-100 btn-outline-primary btn-letter-spacing" type="button" [disabled]="!agreeToDirectDebit"
    (click)="validatePaymentDetails()">Validate
    Payment Details</button>
  } @else {
  <div class="alert alert-danger w-100 d-flex flex-row" role="alert">
    <div class="fw-bold me-2">
      <fa-icon [icon]="icons.alert.solid" />
    </div>
    <div>
      <b>Failed!</b>
      {{ directDebitSummaryForm.getError(directDebitSetupError) || 'Can not initialize payment gateway.' }}
    </div>
  </div>
  }
</div>
}