<div class="kip-form-group">
  <input class="form-check-input form-check-inline" id="available" type="checkbox" [ngModelOptions]="{standalone: true}"
    [(ngModel)]="available" />
  <label for="available">Address <span class="ms-2 small text-uppercase">Optional</span></label>
</div>
@if (available) {
<div class="kip-form-group" [ngClass]="{'form-group-invalid' : addressForm | isFormControlInvalid }">
  <kip-address-picker name="address" [addressForm]="addressForm" />
  <kip-control-error clientSideErrorMessage="Please provide a valid address" [control]="addressForm" />
</div>
}