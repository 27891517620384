import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { StripeData } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StripeService extends HttpService {

  initialize(): Observable<StripeData> {
    return this.get<StripeData>('stripe/initialize');
  }

}
