import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

@Component({
  selector: 'kip-error-modal',
  templateUrl: './error-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorModalComponent {

  readonly #modalService = inject(NgbModal);
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);

  readonly icons = Icons;
  @Input({ required: true }) title: string | undefined;
  @Input({ required: true }) message: string | undefined;
  @Input({ required: true }) buttonText: string | undefined;

  @ViewChild('content') modalContent: TemplateRef<any> | undefined;

  /* eslint-disable kip/no-unused-public-members */

  open() {
    this.#modalService.open(this.modalContent, { centered: true });
  }

  /* eslint-enable kip/no-unused-public-members */

  close() {
    this.#modalService.dismissAll();
    this.#router.navigate(['../'], { relativeTo: this.#route });
  }
}
