import { EmojisList } from './emojis-list';

export const Emojis: EmojisList = {
  middleFinger: {
    shortName: 'middle_finger',
    unified: '1F595'
  },
  faceWithSymbolsOnMouth: {
    shortName: 'face_with_symbols_on_mouth',
    unified: '1F92C'
  },
  poop: {
    shortName: 'hankey',
    unified: '1F4A9'
  }
};
