import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-graph-bar-chart',
  templateUrl: './graph-bar-chart.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconGraphBarChartComponent implements IconComponent {

}
