import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'kip-awards-badge',
  templateUrl: './kip-awards-badge.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KipAwardsBadgeComponent {

}
