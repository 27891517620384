import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-construction',
  templateUrl: './monty-construction.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyConstructionComponent implements MontyGraphic { }
