import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

export class ParagraphValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {

    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);

    if (studentAnswers.length === question.answers.length) {

      // For each provided answer check the corresponding question answer matches
      for (let index = 0; index < question.answers.length; index++) {
        const studentAnswer = studentAnswers[index];
        const answer = question.answers[index];

        // Split the entered answer into words
        const actualAnswersSplit = this.#splitWords(studentAnswer.toString());

        // Each question answer has a set of alternatives, so check all of them
        let matched = false;

        for (const expected of answer.values) {

          // Split the alternative into words
          const expectedAnswersSplit = this.#splitWords(expected.toString());
          const expectedCount = expectedAnswersSplit.length;
          let matchedCount = 0;

          for (const expectedAnswer of expectedAnswersSplit) {
            if (actualAnswersSplit.includes(expectedAnswer)) {
              matchedCount++;
            }
          }

          matched = matchedCount === expectedCount;

          if (matched) {
            validationResults[index] = ValidationResult.Correct;
          }
        }
      }
    }

    return validationResults;
  }

  #sanitizeValue(value: AnswerType): string {

    let returnValue = value;

    if (returnValue !== undefined) {
      returnValue = returnValue
        .toString()
        .trim();
    }

    return returnValue;
  }

  // Replace !,.;? with spaces. Strip out and multiple words
  #splitWords(sentence: string): string[] {
    let words: string[] = [];
    if (sentence !== undefined) {
      words = this.#sanitizeValue(sentence).toLowerCase().replace(/[.,!?;]/g, ' ').split(' ');
    }

    return words;
  }
}
