import { fabric } from 'fabric';

import { WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardTextMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly obj: fabric.IText;
  private readonly text: string;
  private readonly textBeforeEdit: string;

  constructor(canvas: fabric.Canvas, obj: fabric.Object) {
    super();

    // Need to cast to any to access some hidden properties
    const textObj: any = obj;

    this.obj = obj as fabric.IText;
    this.canvas = canvas;

    // fabricjs can crash if this is somehow undefined

    this.textBeforeEdit = textObj._textBeforeEdit ?? '';
    this.text = textObj.text ?? '';
  }

  apply() {
    this.invoke(
      () => {
        this.obj.text = this.text;
        if (this.obj.canvas) {
          this.obj.canvas.fire('text:editing:exited', { target: this.obj });
        }
        this.canvas.renderAll();
      },
      this.obj);
  }

  revert() {
    this.invoke(
      () => {
        this.obj.text = this.textBeforeEdit;
        if (this.obj.canvas) {
          this.obj.canvas.fire('text:editing:exited', { target: this.obj });
        }
        this.canvas.renderAll();
      },
      this.obj);
  }
}
