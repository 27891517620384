import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { PdfImage, PdfImageService } from 'pdf-lib';
import { RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';
import { WhiteboardDataService, WhiteboardEvent, WhiteboardGuidKey, WhiteboardService } from 'whiteboard-lib';

import { ActivityData, ExploreActivity, LessonPlanActivity, StudentActivityData, TutorWhiteboardActivity } from '../models';
import { ExploreService } from '../services';

@Component({
  selector: 'kip-explore',
  templateUrl: './explore.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreComponent implements OnDestroy {

  readonly #exploreService = inject(ExploreService);
  readonly #pdfImageService = inject(PdfImageService);
  readonly #whiteboardDataService = inject(WhiteboardDataService);
  readonly #whiteboardService = inject(WhiteboardService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #whiteboard = false;
  #pdfImage: PdfImage | undefined;
  #activity: ExploreActivity | undefined;
  #activityData: ActivityData | undefined;
  #page = 1;
  #studentData: StudentActivityData | undefined;
  #backgroundColor = '';
  #subscriptions: Subscription[] = [];
  #whiteboardBackgroundImage = '';
  #whiteboardGuidKey: WhiteboardGuidKey | undefined;

  get activity() {
    return this.#activity;
  }

  get whiteboard() {
    return this.#whiteboard;
  }

  get whiteboardGuidKey() {
    return this.#whiteboardGuidKey;
  }

  get whiteboardBackgroundImage() {
    return this.#whiteboardBackgroundImage;
  }

  get pages() {
    return this.#pdfImage?.pages ?? [];
  }

  set page(value: number) {
    if (this.#page !== value) {
      this.#page = value;
      this.#recalcData();
    }
  }

  get page() {
    return this.#page;
  }

  get showBlank() {
    return this.#activity === undefined && this.#studentData === undefined;
  }

  @Input() allowJira = true;
  @Input() allowAddSkillbuilder = false;
  @Input() regionId = RegionId.Australia;
  @Input() soundRegionId = RegionId.Australia;
  @Input() activityIdsAlreadySelected: readonly number[] = [];
  @Input() isTutor = false;

  @Input() set backgroundColor(value: string) {
    this.#backgroundColor = value;
  }

  get backgroundColor() {
    return this.#backgroundColor;
  }

  @Input()
  set activityData(value: ActivityData | undefined) {
    if (this.#activityData !== value) {
      this.#activityData = value;
      if (value) {
        if (value.activityId) {
          this.#subscriptions.push(
            this.#exploreService.getExploreActivityById(value.activityId).subscribe(result => {
              this.#activity = result;
              this.#changeDetectorRef.markForCheck();
            }));
        }
        if (value.treeActivityId) {
          this.#subscriptions.push(
            this.#exploreService.getExploreNewActivityById(value.treeActivityId).subscribe(result => {
              this.#activity = result;
              this.#changeDetectorRef.markForCheck();
            }));
        }
      } else {
        this.#activity = undefined;
      }
      this.#whiteboard = false;
      this.#changeDetectorRef.markForCheck();
    }
  }

  get activityData() {
    return this.#activityData;
  }

  @Input() set studentData(value: StudentActivityData | undefined) {
    if (this.#studentData !== value) {
      this.#studentData = value;
      this.#changeDetectorRef.markForCheck();
      this.#recalcData(true);
    }
  }

  get studentData() {
    return this.#studentData;
  }

  @Output() readonly addSkillbuilder = new EventEmitter<LessonPlanActivity>();
  @Output() readonly whiteboardEvent = new EventEmitter<TutorWhiteboardActivity>();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onWhiteboardEvent(event: WhiteboardEvent) {
    if (this.whiteboardGuidKey && this.isTutor) {
      this.whiteboardEvent.emit({ whiteboardEvent: event, whiteboardGuidKey: this.whiteboardGuidKey });
    }
  }

  #recalcData(newActivity = false) {
    if (this.#studentData) {
      if ((this.#studentData?.whiteboardPages && this.#studentData.whiteboardPages.length > 0 || this.#studentData?.files && this.#studentData?.files.length > 0) && this.#studentData.pdfFile) {
        this.#activity = undefined;
        const pdfUrl = newActivity ? '' : this.#pdfImage?.url ?? '';
        this.#pdfImageService.loadPdf(this.#studentData.pdfFile, this.#studentData.activityGuid, this.#studentData.files ?? [],
          pdfUrl, this.#page, this.#studentData.whiteboardPages).then(
            image => {
              if (this.#studentData) {
                this.#pdfImage = image;
                this.#whiteboardGuidKey = image.whiteboardGuidKey;
                this.#whiteboardBackgroundImage = image.dataUrl;
                this.#whiteboard = true;
                this.#changeDetectorRef.markForCheck();
                this.#subscriptions.push(
                  this.#whiteboardDataService
                    .getWhiteboardAll(this.#studentData.lessonGuid, image.whiteboardGuidKey)
                    .subscribe(state => {
                      this.#whiteboardService.load(
                        {
                          whiteboardGuidKey: image.whiteboardGuidKey,
                          gridType: state.gridType,
                          events: state.events,
                          eventsArchivedOnUtc: state.eventsArchivedOnUtc,
                          pageTitle: ''
                        });
                      this.#changeDetectorRef.markForCheck();
                    }));
              }
            });
      } else if (this.#studentData.activityId === 0) {
        const pageGuid = this.#studentData.activityGuid.slice(0, Math.max(0, this.#studentData.activityGuid.length - 2)) + '1'.padStart(2, '0');
        const whiteboardGuidKey = { activityGuid: this.#studentData.activityGuid, pageGuid: pageGuid };
        this.#whiteboard = true;
        this.#whiteboardBackgroundImage = '';
        this.#pdfImage = undefined;
        this.#whiteboardGuidKey = whiteboardGuidKey;
        this.#changeDetectorRef.markForCheck();

        if (this.#studentData.adHocPdfFile) {
          this.#subscriptions.push(
            this.#exploreService.getAdhocImage(this.#studentData.adHocPdfFile).subscribe(response => {
              this.#whiteboardBackgroundImage = response.body ? window.URL.createObjectURL(response.body) : '';
              this.#changeDetectorRef.markForCheck();
            }));
        }

        this.#subscriptions.push(
          this.#whiteboardDataService
            .getWhiteboardAll(this.#studentData.lessonGuid, whiteboardGuidKey)
            .subscribe(state => {
              this.#whiteboardService.load(
                {
                  whiteboardGuidKey: whiteboardGuidKey,
                  gridType: state.gridType,
                  events: state.events,
                  eventsArchivedOnUtc: state.eventsArchivedOnUtc,
                  pageTitle: ''
                });
              this.#changeDetectorRef.markForCheck();
            }));
      } else if (this.#studentData.activityId) {
        this.#subscriptions.push(
          this.#exploreService.getExploreActivityById(this.#studentData.activityId).subscribe(result => {
            this.#activity = result;
            this.#whiteboard = false;
            this.#changeDetectorRef.markForCheck();
          }));
      }
    }
  }
}
