
import { Pipe, PipeTransform } from '@angular/core';

import { EnrolmentCategory } from '../models';

@Pipe({
  name: 'enrolmentCategory'
})
export class EnrolmentCategoryPipe implements PipeTransform {

  transform(type: EnrolmentCategory) {
    let returnValue = '';
    switch (type) {
      case EnrolmentCategory.CatchUp:
        returnValue = 'Catchup';
        break;
      case EnrolmentCategory.KeepUp:
        returnValue = 'Keepup';
        break;
    }

    return returnValue;
  }
}
