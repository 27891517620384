<kip-loading-state [loadingState]="loadingState" (cancel)="cancel()" (retry)="retry()" />
@if (!editing) {
@if (isLoadingComplete && !hasResults && ranking === null) {
<kip-no-results text="Lets add our first." title="Looks like you don't have any notes for this student yet!"
  [addButtons]="buttons" [canEdit]="canEdit" />
} @else {
<div class="kip-tab">
  <div class="d-flex justify-content-between">
    <h2 class="kip-tab__header">{{ pinnedStudentNotes.length > 0 && currentNoteViewingMode ===
      noteViewingMode.General ? 'Pinned Notes' : 'Notes' }}</h2>
    <kip-buttons [buttons]="buttons" />
  </div>
  @if (!isParent) {
  <div class="kip-form-group mx-auto mb-1">
    <div class="kip-button-group btn-group btn-group-toggle">
      <input class="btn-check" id="noteViewingModeInternal" name="noteViewingMode" type="radio"
        [value]="noteViewingMode.Internal" [(ngModel)]="currentNoteViewingMode">
      <label class="kip-button-group__radio btn col-3" for="noteViewingModeInternal">
        Internal Notes
      </label>
      <input class="btn-check" id="noteViewingModeParent" name="noteViewingMode" type="radio"
        [value]="noteViewingMode.Parent" [(ngModel)]="currentNoteViewingMode">
      <label class="kip-button-group__radio btn col-3" for="noteViewingModeParent">
        Parent Notes
      </label>
      <input class="btn-check" id="noteViewingModeGeneral" name="noteViewingMode" type="radio"
        [value]="noteViewingMode.General" [(ngModel)]="currentNoteViewingMode">
      <label class="kip-button-group__radio btn col-3" for="noteViewingModeGeneral">
        General
      </label>
    </div>
  </div>
  }
  <kip-rankings-filter [studentNotes]="allNotes" [(ranking)]="ranking" />
  @if (pinnedStudentNotes.length > 0 && currentNoteViewingMode === noteViewingMode.General) {
  <div class="kip-tab__article-list mb-2">
    @for (studentNote of pinnedStudentNotes; track studentNote) {
    <div class="kip-tab__article" (click)="edit(studentNote.note)">
      <kip-student-note-pinned [studentNote]="studentNote.note" />
    </div>
    }
  </div>
  }
  @if (studentNotes) {
  <div class="w-100 d-flex justify-content-between align-items-end">
    @if (pinnedStudentNotes.length > 0 && currentNoteViewingMode === noteViewingMode.General) {
    <h2 class="kip-tab__header">Notes</h2>
    }
  </div>
  <ul class="kip-note-timeline">
    @for (studentNote of visibleNotes; track studentNote) {
    <li class="kip-note-timeline__entry d-flex">
      <div class="kip-note-timeline__date me-3 py-3">{{ studentNote.date | dateTime: 'DD MMM YYYY' }}
      </div>
      @if (studentNote.lessonNote) {
      <div class="w-100 kip-note-timeline__notes kip-note-timeline__notes--lesson">
        <div class="me-3">
          <div class="kip-note-timeline__icon ms-n1">
            <fa-icon class="text-primary" [fixedWidth]="true" [icon]="'lesson' | noteCategoryIcon" />
          </div>
        </div>
        <div class="w-100 ms-n1">
          <div class="fw-light mb-3">
            {{ studentNote.date | dateTime: 'h:mm a' }} - {{ studentNote.lessonNote.subject}} - {{
            studentNote.lessonNote.tutor }}
          </div>
          <div class="d-flex w-100">
            @if (studentNote.lessonNote.internalSessionNote && showInternalNotes) {
            <div class="kip-note-timeline__description" (click)="edit(studentNote.lessonNote.internalSessionNote)">
              <div class="text-uppercase text-secondary text-overline mb-2">Session summary <kip-ranking
                  [ranking]="studentNote.lessonNote.internalSessionNote.note.ranking" /></div>
              <kip-emoji-message [message]="studentNote.lessonNote.internalSessionNote.note.description" />
            </div>
            }
            @if (studentNote.lessonNote.internalLessonNote && showInternalNotes) {
            <div class="kip-note-timeline__description" (click)="edit(studentNote.lessonNote.internalLessonNote)">
              <div class="text-uppercase text-secondary text-overline mb-2">Lesson summary <kip-ranking
                  [ranking]="studentNote.lessonNote.internalLessonNote.note.ranking" /></div>
              <kip-emoji-message [message]="studentNote.lessonNote.internalLessonNote.note.description" />
            </div>
            }
            @if (studentNote.lessonNote.lessonFocusNote && showInternalNotes) {
            <div class="kip-note-timeline__description" (click)="edit(studentNote.lessonNote.lessonFocusNote)">
              <div class="text-uppercase text-secondary text-overline mb-2">Lesson focus </div>
              <kip-emoji-message [message]="studentNote.lessonNote.lessonFocusNote.note.description" />
            </div>
            }
            @if (studentNote.lessonNote.lessonAINote && showInternalNotes) {
            <div class="kip-note-timeline__description">
              <div class="text-uppercase text-secondary text-overline mb-2">AI Prompt </div>
              <kip-emoji-message [message]="studentNote.lessonNote.lessonAINote.note.description" />
            </div>
            }
          </div>
          @if (studentNote.lessonNote.parentSessionNote && showParentNotes) {
          <div class="kip-note-timeline__description" (click)="edit(studentNote.lessonNote.parentSessionNote)">
            <div class="text-uppercase text-secondary text-overline mb-2">Session summary <kip-ranking
                [ranking]="studentNote.lessonNote.parentSessionNote.note.ranking" /></div>
            <kip-emoji-message [message]="studentNote.lessonNote.parentSessionNote.note.description" />
          </div>
          }
          @if (studentNote.lessonNote.startLessonNote) {
          <div class="kip-note-timeline__description">
            <div class="text-uppercase text-secondary text-overline mb-2">Student Start Lesson Rating
              <kip-ranking [ranking]="studentNote.lessonNote.startLessonNote.note.ranking" />
            </div>
            <kip-emoji-message [message]="studentNote.lessonNote.startLessonNote.note.description" />
          </div>
          }
          @if (studentNote.lessonNote.finishLessonNote) {
          <div class="kip-note-timeline__description">
            <div class="text-uppercase text-secondary text-overline mb-2">Student Finish Lesson
              Rating<kip-ranking [ranking]="studentNote.lessonNote.finishLessonNote.note.ranking" /></div>
            <kip-emoji-message [message]="studentNote.lessonNote.finishLessonNote.note.description" />
          </div>
          }
          @if (studentNote.lessonNote.parentLessonNote && showParentNotes) {
          <div class="kip-note-timeline__description"
            [ngClass]="{'text-danger': !studentNote.lessonNote.parentLessonNote.note.approved}"
            (click)="edit(studentNote.lessonNote.parentLessonNote)">
            <div class="text-uppercase text-secondary text-overline mb-2">Lesson summary<kip-ranking
                [ranking]="studentNote.lessonNote.parentLessonNote.note.ranking" /></div>
            <kip-emoji-message [message]="studentNote.lessonNote.parentLessonNote.note.description" />
          </div>
          }
          @if (studentNote.lessonNote.internalActivityNotes.length > 0 && showInternalNotes) {
          <div class="kip-note-timeline__description--no-background mb-3">
            <div class="kip-note-timeline__accordion text-secondary text-overline mb-2"
              (click)="studentNote.lessonNote.expanded = !studentNote.lessonNote.expanded">
              <div class="user-select-none">
                <span>Activity Notes</span>
                <fa-icon class="ms-2 kip-note-timeline__accordion--hover" [icon]="'activity' | noteCategoryIcon" />
                <span class=" kip-note-timeline__accordion--hover ms-1">{{
                  studentNote.lessonNote.internalActivityNotes.length }}</span>
              </div>
              <fa-icon size="2x"
                [icon]="studentNote.lessonNote.expanded?icons.angle.up.regular:icons.angle.down.regular" />
            </div>
            @if (studentNote.lessonNote.expanded) {
            <div>
              @for (activityNote of studentNote.lessonNote.internalActivityNotes; track activityNote) {
              <div class="kip-note-timeline__description kip-note-timeline__description--activity"
                (click)="edit(activityNote.note)">
                <div class="text-uppercase text-primary text-overline mb-2">
                  {{ activityNote.activityName}}
                </div>
                <kip-emoji-message [message]="activityNote.note.note.description" />
              </div>
              }
            </div>
            }
          </div>
          }
        </div>
      </div>
      }
      @if (studentNote.note) {
      <div class="w-100 kip-note-timeline__notes kip-note-timeline__notes--system">
        <div class="kip-note-timeline__icon-wrapper me-3">
          <div class="kip-note-timeline__icon kip-note-timeline__icon--border">
            <fa-icon class="text-primary" [fixedWidth]="true"
              [icon]="studentNote.note.note.noteCategory.name | noteCategoryIcon" />
          </div>
        </div>
        <div class="w-100 border-bottom mb-2" (click)="edit(studentNote.note)">
          <div class="kip-note-timeline__description mb-2">
            <div class="kip-note-timeline__event-type mb-2">{{ studentNote.note.note.noteCategory.name }}
            </div>
            <div class="mb-2">
              <kip-emoji-message [message]="studentNote.note.note.description" />
            </div>
            <div class="d-flex w-100 mt-2">
              <span class="me-3 small text-muted">
                <fa-icon class="me-1" [icon]="icons.tutor.solid" />
                {{ studentNote.note.createdByUser }}
              </span>
            </div>
          </div>
        </div>
      </div>
      }
      @if (studentNote.assessmentNote) {
      <div class="w-100 kip-note-timeline__notes kip-note-timeline__notes--lesson">
        <div class="me-3">
          <div class="kip-note-timeline__icon ms-n1">
            <fa-icon class="text-primary" [fixedWidth]="true" [icon]="'assessment' | noteCategoryIcon" />
          </div>
        </div>
        <div class="w-100 ms-n1">
          <div class="fw-light mb-3">
            {{ studentNote.date | dateTime: 'h:mm a' }} - Assessment
          </div>
          <div class="d-flex w-100">
            <div class="kip-note-timeline__description--no-background mb-3 w-100">
              <div class="kip-note-timeline__accordion text-secondary text-overline mb-2"
                (click)="studentNote.assessmentNote.expanded = !studentNote.assessmentNote.expanded">
                <div class="user-select-none">
                  <span>Results Notes</span>
                  <fa-icon class="ms-2 kip-note-timeline__accordion--hover" [icon]="'assessment' | noteCategoryIcon" />
                  <span class=" kip-note-timeline__accordion--hover ms-1">
                    {{ assessmentNoteCount(studentNote.assessmentNote) }}
                  </span>
                </div>
                <fa-icon size="2x"
                  [icon]="studentNote.assessmentNote.expanded?icons.angle.up.regular:icons.angle.down.regular" />
              </div>
              @if (studentNote.assessmentNote.expanded) {
              <div>
                @for (resultNote of studentNote.assessmentNote.resultNotes; track resultNote) {
                @if ((showInternalNotes && resultNote.internalNote) || (showParentNotes && resultNote.parentNote)) {
                <div class="kip-note-timeline__description kip-note-timeline__description--activity"
                  (click)="edit(showInternalNotes ? resultNote.internalNote : resultNote.parentNote)">
                  <div class="text-uppercase text-primary text-overline mb-2">
                    {{ resultNote.resultType }}
                  </div>
                  @if (showInternalNotes) {
                  <kip-emoji-message [message]="resultNote.internalNote.note.description" />
                  }
                  @if (!showInternalNotes) {
                  <kip-emoji-message [message]="resultNote.parentNote.note.description" />
                  }
                </div>
                }
                }
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </li>
    }
  </ul>
  }
</div>
}
} @else {
<div class="kip-tab kip-tab--is-edit">
  <kip-student-note-edit class="col-12 col-lg-12 col-xl-6" [studentId]="studentId" [studentNote]="studentNoteToEdit"
    (cancelled)="cancelled()" (submitted)="changesMade()" />
</div>
}