import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

import { Subjects } from '../models';

@Pipe({
  name: 'subjectIcon'
})
export class SubjectIconPipe implements PipeTransform {
  transform(subject: Subjects): IconDefinition {
    switch (subject) {
      case Subjects.Maths:
        return Icons.subject.maths;
      case Subjects.SeniorMaths:
        return Icons.subject.maths;
      case Subjects.English:
        return Icons.subject.english;
      case Subjects.SeniorEnglish:
        return Icons.subject.english;
      case Subjects.EarlyReading:
        return Icons.subject.earlyReading;
      case Subjects.Reading:
        return Icons.subject.reading;
      case Subjects.Comprehension:
        return Icons.subject.comprehension;
      default:
        return Icons.subject.other;
    }
  }
}
