import { Age, AgeResult, NullableAge } from '../models';
import { convertAgeToString } from './convert-age-to-string';
import { determineAgeDifference } from './determine-age-difference';

export function ageResult(ageResultValue?: AgeResult | null, age?: NullableAge, studentAge?: Age | null, short = false): string {

  const years = short ? 'Y' : ' years ';
  const months = short ? 'M' : ' months ';

  switch (ageResultValue) {
    case AgeResult.TooLowToBeAssessed:
      return short ? 'TLA' : 'Too low to be assessed';
    case AgeResult.MuchTooLowToBeAssessed:
      return short ? 'MTLA' : 'Much too low to be assessed';
    case AgeResult.Assessed:
      if (age) {
        const returnedAge = convertAgeToString(age, years, months);

        if (studentAge) {
          return `${returnedAge} - ${determineAgeDifference(age, studentAge, years, months)}`;
        }

        return returnedAge;
      }
      return '';
    default:
      return '';
  }
}
