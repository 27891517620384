
import { DateStruct } from 'moment-extensions-lib';

import { Age } from '../models';
import { calculateAge } from './calculate-age';

export function ageObject(date: DateStruct | null | undefined, effectiveDate?: Date | DateStruct | string | null  ): Age | undefined {
  if (date) {
    return calculateAge(date, effectiveDate);
  }
  return undefined;
}
