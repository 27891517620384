import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { HelpNode } from '../../models';

@Component({
  selector: 'kip-help-node',
  templateUrl: './help-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HelpNodeComponent {

  readonly icons = Icons;

  get selected() {
    if (this.helpNode) {
      return this.helpNode.uId.toLocaleLowerCase() === this.selectedUId.toLocaleLowerCase();
    }

    return false;
  }

  @Input({ required: true }) selectedUId = '';
  @Input({ required: true }) helpNode: HelpNode | undefined = undefined;
  @Output() readonly selectHelpNode = new EventEmitter<HelpNode>();

  nodeSelected() {
    if (this.helpNode) {
      if (this.helpNode.children.length > 0) {
        this.helpNode.expanded = !this.helpNode.expanded;
      }
      this.selectHelpNode.emit(this.helpNode);
    }
  }

}
