import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
  selector: 'kip-timezone-picker',
  templateUrl: './timezone-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeZonePickerComponent {

  timeZones: string[] = moment.tz.names();

  @Input({ required: true }) label = '';
  @Input({ required: true }) name = 'timezone';
  @Input({ required: true }) timezone: FormControl<string | null> | undefined;
  @Input() labelClass: string | undefined;
}
