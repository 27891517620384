import { createReducer, on } from '@ngrx/store';
import { LessonLog } from 'message-lib';

import * as LogActions from './log.actions';

export interface LogState {
  readonly entityIds: {
    readonly [lessonGuid: string]: readonly string[];
  };
  readonly entities: {
    readonly [eventId: string]: LessonLog;
  };
}

const initialState: LogState = {
  entityIds: {},
  entities: {}
};

export const logReducer = createReducer(initialState,
  on(LogActions.loadManyAction, (state, action) => {
    let entityIds = state.entityIds;
    let entities = state.entities;

    for (const log of action.logs) {
      const eventIds = entityIds[action.lessonGuid] || [];

      // Only add logs not already in the store
      if (!eventIds.includes(log.eventId)) {
        entityIds = { ...entityIds, [action.lessonGuid]: [...eventIds, log.eventId] };
        entities = { ...entities, [log.eventId]: log };
      }
    }

    return {
      entityIds: entityIds,
      entities: entities
    };
  }),
  on(LogActions.addAction, (state, action) => {
    const eventIds = state.entityIds[action.lessonGuid] || [];

    // Only add logs not already in the store
    if (!eventIds.includes(action.log.eventId)) {
      return {
        entityIds: { ...state.entityIds, [action.lessonGuid]: [...eventIds, action.log.eventId] },
        entities: { ...state.entities, [action.log.eventId]: action.log }
      };
    }

    return state;
  })
);
