import { install } from 'chart-js-fabric';
import Decimal from 'decimal.js';
import { fabric } from 'fabric';
import { Observable, Subscription } from 'rxjs';

import { GraphToolService } from '../../tool-dialog/services';
import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardGraphOptions } from '../whiteboard-graph-options';
import { WhiteboardTool } from '../whiteboard-tool';
import { WhiteboardToolOption } from '../whiteboard-tool-option';

enum WhiteboardGraphName {
  CartesianGraph = 'cartesian-graph'
}

interface WhiteboardShape {
  obj: fabric.Object;
  update: (startX: number, startY: number, updateX: number, updateY: number) => void;
  complete: ((startX: number, startY: number, updateX: number, updateY: number) => void) | undefined;
}

export class WhiteboardGraphTool extends WhiteboardTool {

  #subscriptions: Subscription[] = [];
  #canvas: fabric.Canvas | undefined;

  override get options(): WhiteboardToolOption[] {
    // We'll be able to add additional graph types later but will
    // default the cartesian graph for now
    return [];
  }

  constructor(
    private readonly graphToolService: GraphToolService
  ) {
    super(ToolbarSvg.Graph);
    // sets up the chart-js-fabric api into the fabric api
    install(fabric);
    this.selectedOption = WhiteboardGraphName.CartesianGraph;

  }

  //addShapeToCanvas(shape: WhiteboardShape | undefined) {
  addShapeToCanvas(shape: WhiteboardShape | undefined, canvas: fabric.Canvas | undefined) {
    // as the graph tool has temporarily changed to be in with the shapes collection,
    // the canvas is being passed through to this method as well to gain the same reference
    // used in the setup of the whiteboard-shape-tool. Once the graph tools are separated
    // into their own collection of tools, the private #canvas variable can be used again.
    const targetCanvas = canvas ?? this.#canvas;

    if (!shape || !targetCanvas) {
      return;
    }

    targetCanvas.remove(shape.obj);
    shape.obj.selectable = false;
    shape.obj.data = {
      chart: true
    };
    targetCanvas.add(shape.obj);

    // Ensure the shape is updated on the canvas
    targetCanvas.renderAll();
  }

  setup(_color: WhiteboardColor, canvas: fabric.Canvas) {
    this.#canvas = canvas;

    this.resetCanvas(canvas, false, false);

    canvas.on('mouse:down', _event => {
      this.openGraphFormModal();
    });

    const graphOptionsSubmitted$ = this.createGraphFormSubmittedObservable();

    this.#subscriptions.push(graphOptionsSubmitted$.subscribe(graphOption => {
      const shape = this.createGraph(graphOption.xMax, graphOption.yMax, graphOption.xMin, graphOption.yMin);
      this.addShapeToCanvas(shape, canvas);
    }));
  }

  teardown(canvas: fabric.Canvas) {
    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');

    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  createGraphFormSubmittedObservable(): Observable<WhiteboardGraphOptions> {
    return this.graphToolService.whiteboardGraphOptionsSubmitted.asObservable();
  }

  openGraphFormModal() {
    this.graphToolService.emitOpenWhiteboardGraphOptionsModal();
  }

  createGraph(xMax: number, yMax: number, xMin: number, yMin: number): WhiteboardShape {
    const dataPoints = [yMin, yMax];

    const chart = new fabric.Chart({
      originX: 'left',
      originY: 'top',
      left: 50,
      top: 50,
      width: 500,
      height: 500,
      chart: {
        type: 'line',
        data: {
          labels: this.#getGraphLabels(xMax, xMin, this.#getGraphLabelInterval(xMax, xMin)),
          datasets: [
            {
              // These data points are added to set the max and min on the y axis
              // with full transparency to hide the markers
              data: dataPoints,
              label: '',
              backgroundColor: 'rgba(0, 123, 255, 0)',
              borderColor: 'rgba(0, 84, 159, 0)',
              borderWidth: 0
            }
          ]
        },
        options: {}
      }
    });

    return {
      obj: chart,
      update:
        (startX, startY, updateX, updateY) => {
          if (!chart) {
            return;
          }

          if (startX > updateX) {
            chart.set({
              left: Math.abs(updateX)
            });
          }

          if (startY > updateY) {
            chart.set({
              top: Math.abs(updateY)
            });
          }

          chart.set({
            width: Math.abs(startX - updateX),
            height: Math.abs(startY - updateY)
          });
        },
      complete: undefined
    };
  }

  #getRange(max: number, min: number): number {
    return new Decimal(max).minus(min).toNumber();
  }

  #getGraphLabelInterval(xMax: number, xMin: number, minIncrementValue = 0.001): number {
    // preferred number of axis'. This has been
    // set at 10 as nice flat number.
    const preferredAxisCount = 10;
    const range = this.#getRange(xMax, xMin);

    let prevValueIntervalCount = 0;
    let nextValueIntervalCount = 0;
    let nextIncrementValue = 0;
    let result: number | undefined = undefined;
    let prevIncrementValue = minIncrementValue;

    // Iterate through the intervals and find the most suitable interval.
    // This method was originally created to be a recursive method instead of using
    // a loop but maximum call stack exceptions could occur depending values
    // provided.
    do {
      // increment by 10x
      nextIncrementValue = prevIncrementValue * 10;

      // using decimal here to cover any floating point problems that
      // could occur
      const decimalRange = new Decimal(range);

      prevValueIntervalCount = decimalRange.dividedBy(prevIncrementValue).toNumber();
      nextValueIntervalCount = decimalRange.dividedBy(nextIncrementValue).toNumber();

      if (prevValueIntervalCount >= preferredAxisCount && nextValueIntervalCount <= preferredAxisCount) {

        // negate the the preferred interval and check which value
        // has the closest amount of axis' to the preffered amount
        const nv = new Decimal(nextValueIntervalCount).minus(preferredAxisCount).toNumber();
        const pv = new Decimal(prevValueIntervalCount).minus(preferredAxisCount).toNumber();

        const diff1 = Math.abs(nv);
        const diff2 = Math.abs(pv);

        if (diff1 < diff2) {
          result = nextIncrementValue;
          break;
        } else if (diff2 < diff1) {
          result = prevIncrementValue;
          break;
        } else {
          // If both diffs are the same distance from the preferred,
          // count, either value can be returned but defaulting to
          // nextIncrementValue.
          result = nextIncrementValue;
          break;
        }
      }
      prevIncrementValue = nextIncrementValue;
    }
    while (nextIncrementValue < range);

    if (!result) {
      throw new Error('Failed to set axis interval for graph');
    }

    return result;
  }

  // This method will get the first axis position relative to 0 using the interval
  #floorFirstAxisStartingPosition(number: number, interval: number): number {
    // eslint-disable-next-line spellcheck/spell-checker
    return new Decimal(number).div(interval).floor().mul(interval).toNumber();
  }

  #getGraphLabels(xMax: number, xMin: number, interval: number) {
    const labels: string[] = [];
    const startingXPosition = this.#getStartingXPosition(xMax, xMin, interval);

    labels.push(startingXPosition.toString());
    let currentInterval = startingXPosition;

    do {
      currentInterval = new Decimal(currentInterval).plus(interval).toNumber();
      labels.push(currentInterval.toString());

      // if the current interval is equal to the max value,
      // the loop can be exited to allow the last axis to match the xMax
      if (currentInterval === xMax) {
        break;
      }
    }
    while (currentInterval <= xMax);

    return labels;
  }

  #getStartingXPosition(_xMax: number, xMin: number, interval: number): number {

    // can default to 0 if the xMin is greater than 0 but xMin - interval is lower than 0
    if (xMin > 0 && xMin - interval < 0) {
      return 0;
    }

    // if the number is divisible by the interval number,
    // the xAxis can start from the xMin otherwise the xAxis
    // will need to start one interval length before the xMin
    return new Decimal(Math.abs(xMin)).mod(interval).toNumber() === 0 ? xMin : this.#floorFirstAxisStartingPosition(xMin, interval);
  }
}
