<kip-form-control-text
  errorMessage="Password must be a least 6 characters long and contain at least one letter and number." id="password"
  label="Password" [control]="password" [type]="showPassword ? 'text' : 'password'" />
<kip-form-control-text errorMessage="Confirm password needs to match password." id="confirmPassword"
  label="Confirm Password" [control]="confirmPassword" [type]="showPassword ? 'text' : 'password'" />
<div class="kip-form-group">
  <label for="showPassword">Show Password</label>
  <div>
    <input class="form-check-input" id="showPassword" type="checkbox" (change)="toggleShowPassword($event)">
  </div>
</div>