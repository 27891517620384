<div class="d-flex justify-content-between align-items-center kip-tcc pe-1 py-1 border-bottom" ngbAutofocus>
  <div class="modal-title h3 p-1 ms-3" id="modal-basic-title">
    <fa-icon class="me-1" size="1x" [icon]="icons.reportJira.light" />
    {{ requestText }}
  </div>
  <button aria-label="Close" class="btn btn-link btn--icon" type="button" [disabled]="isUploadingImages"
    (click)="close()">
    <fa-icon [icon]="icons.close.light" />
  </button>
</div>
<div class="modal-body">
  @if (!isSuccessReport) {
  <form [formGroup]="jiraForm" (ngSubmit)="submit()">
    @if (jiraRequestTypeVisible) {
    <kip-form-control-select id="jiraRequestType" label="Issue Type" [control]="jiraRequestType"
      [values]="requestTypes" />
    }
    <ng-template #tipContent>
      @for (keyValuePair of metaData | keyvalue; track keyValuePair) {
      <p class="text-break">
        {{ keyValuePair.key | titlecase }} :<br />
        {{ keyValuePair.value}}
      </p>
      }
    </ng-template>
    <kip-form-control-long-text id="description" label="Issue Description" [control]="description" [rows]="6">
      <fa-icon triggers="click:blur" [icon]="icons.help.regular" [ngbTooltip]="tipContent" />
    </kip-form-control-long-text>
    <kip-file-uploader [classes]="'tutor-uploader'" [isUploading]="isUploadingImages" [multiple]="true"
      [progress]="progress$ | async" (fileList)="images = $event" />
    <kip-form-error [formGroup]="jiraForm" />
    <div class="mt-3">
      <button class="btn btn-primary me-2" type="submit" [disabled]="disableSend || isUploadingImages">Send
        Report</button>
      @if (!disableSend) {
      <button class="btn btn-link" type="button" [disabled]="isUploadingImages" (click)="close()">Cancel</button>
      }
    </div>
    @if (disableSend) {
    <label>{{ result }}</label>
    }
  </form>
  }

  @if (isSuccessReport) {
  <kip-success-request [descriptionText]="'A member of support will be in touch soon.'"
    [successTitle]="'Success! Report sent. ' + result" (close)="close()" />
  }
</div>