import { QuestionSummary } from '../../models';

export function convertWordPicker(question: QuestionSummary<string[]>, genericInput: string, showAnswerOnly: boolean) {
  let text = '';
  for (let index = 0, lastIndex = question.parameters.length; index < lastIndex; index++) {
    let match = false;
    for (const answer of question.answers) {
      if (answer.values[0] === index) {
        match = true;
        break;
      }
    }

    if (!showAnswerOnly) {
      text += match ? ` <b>${question.parameters[index]}</b>` : ` ${question.parameters[index]}`;
      continue;
    }

    if (match && showAnswerOnly) {
      text += `<span class="kip-success-tutor-answer">${question.parameters[index]}</span>`;
    }
  }
  const answer = `<div class="d-flex-inline"> ${genericInput.replace('&nbsp;', text.slice(0, text.length - 2))}</div>`;
  return showAnswerOnly ? answer : question.text + answer;
}
