import { IconDefinition, Icons } from 'icon-lib';
import { Severity } from 'message-lib';
import { Observable, Subscriber } from 'rxjs';

export enum AlertSource {
  StudentStatus = 'student-status',
  ActivityStatus = 'activity-status',
  BirthdayStatus = 'birthday-status',
  SkipStatus = 'skip-status',
  SkillbuilderStatus = 'skillbuilder-status',
  RecordingStatus = 'recording-status',
  BroadcastingPublishFailure = 'broadcasting-publish-failure',
  BroadcastingPublishDenied = 'broadcasting-publish-denied',
  StopBroadcastingObservers = 'stop-broadcasting-observers'
}

export enum AlertActionName {
  Teach = 'teach-mode',
  StartBroadcasting = 'start-broadcasting'
}

export class AlertActionEvent {
  constructor(readonly alert: Alert, readonly action: AlertActionName) { }
}

export class AlertMessagePart {
  constructor(readonly text: string, readonly action: AlertActionName | null) { }
}

export class Alert {
  #subscriber: Subscriber<string> | undefined;

  readonly result: Observable<string>;
  readonly icon: IconDefinition;

  constructor(readonly type: Severity,
    readonly message: string,
    readonly created: Date,
    readonly source: AlertSource,
    readonly lessonId: number | undefined,
    readonly includeTimer = false) {
    switch (type) {
      case Severity.Primary:
        this.icon = Icons.information.solid;
        break;
      case Severity.Success:
        this.icon = Icons.complete.regular;
        break;
      case Severity.Danger:
      case Severity.Warning:
        this.icon = Icons.warning.solid;
        break;
      default:
        this.icon = Icons.information.solid;
        break;
    }

    this.result = new Observable<string>(subscriber => { this.#subscriber = subscriber; });
  }

  sendAction(action: string) {
    this.#subscriber?.next(action);
    this.#subscriber?.complete();
  }

  extractMessage(): AlertMessagePart[] {
    const parts: AlertMessagePart[] = [];
    const htmlElement = document.createElement('div');
    htmlElement.innerHTML = this.message;
    for (const node of htmlElement.childNodes) {
      if (node.nodeType === 1) {
        const htmlNode = node as HTMLElement;
        if (htmlNode.tagName.toLowerCase() === 'action') {
          const alertActionText = htmlNode.getAttribute('name');
          if (Object.values(AlertActionName as object).includes(alertActionText)) {
            parts.push(new AlertMessagePart(htmlNode.innerHTML, alertActionText as AlertActionName));
          }
        } else {
          parts.push(new AlertMessagePart(htmlNode.outerHTML, null));
        }
      } else if (node.nodeValue) {
        parts.push(new AlertMessagePart(node.nodeValue, null));
      }
    }
    return parts;
  }
}
