/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable, of } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { ConfigId } from './get-open-id-configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly #oidcSecurityService = inject(OidcSecurityService);

  readonly #logoutMessageKey = 'LOGOUT_MESSAGE';
  readonly #lastLoginConfigIdKey = 'LAST_LOGIN_CONFIG_ID';

  userData: any;

  authDisabled = false;

  set logoutMessage(value: string) {
    localStorage.setItem(this.#logoutMessageKey, value);
  }

  get logoutMessage() {
    return localStorage.getItem(this.#logoutMessageKey) ?? '';
  }

  clearLogoutMessage() {
    localStorage.removeItem(this.#logoutMessageKey);
  }

  login(configId: ConfigId) {
    localStorage.setItem(this.#lastLoginConfigIdKey, configId);
    this.#oidcSecurityService.authorize(configId, { customParams: { 'prompt': 'login' }});
  }

  logout() {

    const configIds = this.#oidcSecurityService.getConfigurations().map(s => s.configId ?? '');

    for (const configId of configIds) {
      this.#oidcSecurityService.logoff(configId).subscribe(() => {
        console.log(`Logged off ${configId}`);
      });
    }

    localStorage.removeItem(this.#lastLoginConfigIdKey);
  }

  checkAuth() {
    return this.#oidcSecurityService.checkAuthMultiple()
      .pipe(map(result => result.find(r => r.isAuthenticated)?.isAuthenticated ?? false));
  }

  get isLoggedIn(): Observable<boolean> {
    return this.#oidcSecurityService.isAuthenticated$.pipe(
      map(result => result.allConfigsAuthenticated.find(r => r.isAuthenticated)?.isAuthenticated ?? false));
  }

  getToken(): Observable<string> {
    const configId = localStorage.getItem(this.#lastLoginConfigIdKey);

    if (!ServiceEnvironment.value.api.endpoint || this.authDisabled || !configId) {
      return of('');
    }

    return this.#oidcSecurityService.getAccessToken(configId);
  }
}
