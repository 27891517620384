import { fabric } from 'fabric';

import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardTool } from '../whiteboard-tool';

export class WhiteboardEraserTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Eraser);
  }

  override selectable(_obj: fabric.Object): boolean {
    return true;
  }

  setup(_color: WhiteboardColor, canvas: fabric.Canvas) {
    this.resetCanvas(canvas, false, false);
    this.setSelectableState(canvas, true);

    // Listen for mouse up events to remove the target object
    canvas.on('mouse:up', () => {
      const object = canvas.getActiveObject();

      if (object) {
        canvas.remove(object);
      }
    });
  }

  teardown(canvas: fabric.Canvas) {
    canvas.off('mouse:up');

    // Reset the selectable state of all user objects
    this.setSelectableState(canvas, false);
  }
}
