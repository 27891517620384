/* eslint-disable max-len */

export const protractor180 = `<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="670" height="350">
<g transform="translate(-82.454 -519.91)">
    <circle style="fill:#fff;fill-rule:evenodd;stroke:#000;stroke-width:.89700001;stroke-linecap:round" cx="420" cy="844.362" r="13.575"/>
    <path style="fill:none;stroke:#000" d="M416.75 844.36h6.5M420 847.61v-6.5"/>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="M102.9 844.36h36.8"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" transform="rotate(-90)" xml:space="preserve" y="156.236" x="-844.302"><tspan y="156.236" x="-844.302">0</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m107.72 789.3 36.24 6.39"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-80.001)" y="296.472" x="-758.543"><tspan x="-758.543" y="296.472">10</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m122.03 735.91 34.58 12.59"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-70)" y="419.696" x="-649.732"><tspan x="-649.732" y="419.696">20</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m145.39 685.81 31.87 18.4"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-60.001)" y="522.143" x="-521.182"><tspan x="-521.182" y="522.143">30</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m177.09 640.54 28.19 23.65"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-50)" y="600.72" x="-376.788"><tspan x="-376.788" y="600.72">40</tspan></text>
    <path d="m216.17 601.45 23.66 28.19" style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-40)" y="653.024" x="-220.948"><tspan x="-220.948" y="653.024">50</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m261.45 569.75 18.4 31.87"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-30)" y="677.475" x="-58.39"><tspan x="-58.39" y="677.475">60</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m311.55 546.39 12.58 34.58"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-20)" y="673.325" x="105.942"><tspan x="105.942" y="673.325">70</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m364.94 532.08 6.39 36.25"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(-10)" y="640.702" x="267.057"><tspan x="267.057" y="640.702">80</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="M420 527.26v36.81"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" y="580.598" x="420.06"><tspan x="420.06" y="580.598">90</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m475.06 532.08-6.39 36.25"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(10)" y="494.838" x="560.301"><tspan x="560.301" y="494.838">100</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m528.45 546.39-12.58 34.58"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(20)" y="386.028" x="683.52"><tspan x="683.52" y="386.028">110</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m578.55 569.75-18.4 31.87"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(30)" y="257.475" x="785.972"><tspan x="785.972" y="257.475">120</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m623.83 601.45-23.66 28.19"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(40)" y="113.084" x="864.544"><tspan x="864.544" y="113.084">130</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m662.91 640.54-28.19 23.65"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(50)" y="-42.758" x="916.85"><tspan x="916.85" y="-42.758">140</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m694.61 685.81-31.87 18.4"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(60.001)" y="-205.319" x="941.302"><tspan x="941.302" y="-205.319">150</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m717.97 735.91-34.58 12.59"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(70)" y="-369.647" x="937.149"><tspan x="937.149" y="-369.647">160</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="m732.28 789.3-36.24 6.39"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(80.001)" y="-530.767" x="904.527"><tspan x="904.527" y="-530.767">170</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="M737.1 844.36h-36.8"/>
    <text style="font-size:17.60000038px;line-height:100%;font-family:'Bitstream Vera Sans';text-align:center;text-anchor:middle;fill:#000" xml:space="preserve" transform="rotate(90)" y="-683.764" x="844.422"><tspan x="844.422" y="-683.764">180</tspan></text>
    <path style="fill:none;stroke:#000;stroke-width:.89700001;stroke-linecap:round" d="M102.903 844.36H139.7"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m102.819 838.83 18.401.32M102.964 833.29l18.386.64M103.206 827.76l18.374.96M103.544 822.23l18.356 1.29M104.509 811.2l18.301 1.93M105.136 805.7l18.264 2.24M105.858 800.21l18.222 2.56M106.677 794.74l18.173 2.88"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m104.11 816.73 25.98 2.27"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m108.6 783.83 18.06 3.51M109.703 778.41l17.997 3.82M110.902 773l17.928 4.14M112.194 767.62l17.856 4.45M115.06 756.92l17.69 5.07M116.633 751.61l17.597 5.38M118.297 746.33l17.503 5.69M120.05 741.08l17.4 5.99"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m113.708 762.29 25.192 6.75"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m123.84 730.68 17.18 6.59M125.87 725.53l17.06 6.89M127.99 720.41l16.94 7.19M130.2 715.33l16.81 7.49M134.88 705.3l16.54 8.06M137.35 700.34l16.39 8.36M139.9 695.43l16.25 8.64M142.55 690.57l16.09 8.92"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m132.61 710.35 23.64 11.02"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m148.08 680.98 15.77 9.47M150.97 676.26l15.61 9.75M153.95 671.59l15.43 10.02M157.01 666.97l15.25 10.29M163.36 657.9l14.88 10.82M166.65 653.45l14.7 11.07M170.02 649.06l14.5 11.33M173.47 644.72l14.3 11.58"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m160.25 662.48 21.37 14.96"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m180.58 636.24 13.89 12.07M184.25 632.09l13.68 12.32M187.99 628.01l13.46 12.55M191.8 624l13.24 12.78M199.63 616.17l12.79 13.23M203.65 612.36l12.55 13.45M207.73 608.62l12.32 13.67M211.88 604.95l12.07 13.88"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m195.78 620.14 18.44 18.44"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m220.36 597.83 11.58 14.3M224.69 594.38l11.33 14.5M229.09 591.01l11.07 14.7M233.54 587.72l10.81 14.89M242.61 581.37l10.29 15.25M247.22 578.31l10.03 15.43M251.89 575.34l9.76 15.6M256.62 572.44l9.47 15.78"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m238.12 584.61 14.96 21.37"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m266.2 566.91 8.93 16.09M271.07 564.27l8.64 16.24M275.98 561.71l8.36 16.39M280.94 559.24l8.06 16.54M290.97 554.56l7.49 16.81M296.05 552.35l7.19 16.94M301.16 550.23l6.9 17.06M306.32 548.2l6.59 17.18"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m285.99 556.98 11.02 23.63"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m316.72 544.42 5.99 17.39M321.97 542.66l5.69 17.5M327.25 540.99l5.38 17.6M332.56 539.42l5.07 17.69M343.26 536.56l4.45 17.85M348.64 535.26l4.14 17.93M354.04 534.07l3.83 17.99M359.47 532.96l3.51 18.06"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m337.93 538.07 6.75 25.19"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m370.37 531.04 2.88 18.17M375.85 530.22l2.56 18.22M381.34 529.5l2.24 18.26M386.84 528.87l1.92 18.3M397.87 527.91l1.28 18.35M403.4 527.57l.96 18.37M408.93 527.33l.64 18.39M414.46 527.18l.32 18.4"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m392.36 528.47 2.28 25.99"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m425.54 527.18-.32 18.4M431.07 527.33l-.64 18.39M436.6 527.57l-.96 18.37M442.13 527.91l-1.28 18.35M453.16 528.87l-1.92 18.3M458.66 529.5l-2.24 18.26M464.15 530.22l-2.56 18.22M469.63 531.04l-2.88 18.17"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m447.64 528.47-2.28 25.99"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m480.53 532.96-3.51 18.06M485.96 534.07l-3.83 17.99M491.36 535.26l-4.14 17.93M496.74 536.56l-4.45 17.85M507.44 539.42l-5.07 17.69M512.75 540.99l-5.38 17.6M518.03 542.66l-5.69 17.5M523.28 544.42l-5.99 17.39"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m502.07 538.07-6.75 25.19"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m533.68 548.2-6.59 17.18M538.84 550.23l-6.9 17.06M543.95 552.35l-7.19 16.94M549.03 554.56l-7.49 16.81M559.06 559.24 551 575.78M564.02 561.71l-8.36 16.39M568.93 564.27l-8.64 16.24M573.8 566.91 564.87 583"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m554.01 556.98-11.02 23.63"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m583.38 572.44-9.47 15.78M588.11 575.34l-9.76 15.6M592.78 578.31l-10.03 15.43M597.39 581.37l-10.29 15.25M606.46 587.72l-10.81 14.89M610.91 591.01l-11.07 14.7M615.31 594.38l-11.33 14.5M619.64 597.83l-11.58 14.3"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m601.88 584.61-14.96 21.37"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m628.12 604.95-12.07 13.88M632.27 608.62l-12.32 13.67M636.35 612.36l-12.55 13.45M640.37 616.17l-12.79 13.23M648.2 624l-13.24 12.78M652.01 628.01l-13.46 12.55M655.75 632.09l-13.68 12.32M659.42 636.24l-13.89 12.07"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m644.22 620.14-18.44 18.44"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m666.53 644.72-14.3 11.58M669.98 649.06l-14.5 11.33M673.35 653.45l-14.7 11.07M676.64 657.9l-14.88 10.82M682.99 666.97l-15.25 10.29M686.05 671.59l-15.43 10.02M689.03 676.26l-15.61 9.75M691.92 680.98l-15.77 9.47"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m679.75 662.48-21.37 14.96"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m697.45 690.57-16.09 8.92M700.1 695.43l-16.25 8.64M702.65 700.34l-16.39 8.36M705.12 705.3l-16.54 8.06M709.8 715.33l-16.81 7.49M712.01 720.41l-16.94 7.19M714.13 725.53l-17.06 6.89M716.16 730.68l-17.18 6.59"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m707.39 710.35-23.64 11.02"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m719.95 741.08-17.4 5.99M721.7 746.33l-17.5 5.69M723.37 751.61l-17.6 5.38M724.94 756.92l-17.69 5.07M727.81 767.62l-17.86 4.45M729.1 773l-17.93 4.14M730.3 778.41l-18 3.82M731.4 783.83l-18.06 3.51"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="m726.29 762.29-25.19 6.75"/>
    <path style="fill:none;stroke:#000;stroke-width:.63427001;stroke-linecap:round" d="m733.32 794.74-18.17 2.88M734.14 800.21l-18.22 2.56M734.86 805.7l-18.26 2.24M735.49 811.2l-18.3 1.93M736.46 822.23l-18.36 1.29M736.79 827.76l-18.37.96M737.04 833.29l-18.39.64M737.18 838.83l-18.4.32"/>
    <path style="fill:none;stroke:#000;stroke-width:.89906001;stroke-linecap:round" d="M735.89 816.73 709.91 819"/>
    <path style="fill:none;fill-rule:evenodd;stroke:#000;stroke-width:5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M101.663 843.763c21.556-414.658 609.439-431.613 635.501 0"/>
    <path style="fill:none;fill-rule:evenodd;stroke:#000;stroke-width:5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m101.708 843.554-.534 20.67 635.594.675v-21.764"/>
</g>
</svg>
`;
