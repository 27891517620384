
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { IconLibModule } from 'icon-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { AudioTestComponent } from './audio-test/audio-test.component';
import { DevicesComponent } from './devices/devices.component';
import { VideoEffectPickerComponent } from './effects/video-effect-picker.component';
import { MicrophoneTestComponent } from './microphone-test/microphone-test.component';
import { ObserverComponent } from './observer/observer.component';
import { OpenTokClassPipe } from './pipes';
import { PublisherComponent } from './publisher/publisher.component';
import { QualityIndicatorComponent } from './quality-indicator/quality-indicator.component';
import { ScreenShareComponent } from './screen-share/screen-share.component';
import { SubscriberComponent } from './subscriber/subscriber.component';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    IconLibModule,
    NgbModalModule,
    NgbProgressbarModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  declarations: [
    AudioTestComponent,
    DevicesComponent,
    MicrophoneTestComponent,
    ObserverComponent,
    OpenTokClassPipe,
    PublisherComponent,
    QualityIndicatorComponent,
    ScreenShareComponent,
    SubscriberComponent,
    VideoEffectPickerComponent
  ],
  exports: [
    AudioTestComponent,
    DevicesComponent,
    MicrophoneTestComponent,
    ObserverComponent,
    OpenTokClassPipe,
    PublisherComponent,
    QualityIndicatorComponent,
    ScreenShareComponent,
    SubscriberComponent,
    VideoEffectPickerComponent
  ]
})
export class TokboxModule { }
