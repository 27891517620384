import { Injectable } from '@angular/core';
import * as htmlToImage from 'html-to-image';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

import { WhiteboardGuidKey, WhiteboardState } from './models';

@Injectable({ providedIn: 'root' })
export class WhiteboardDataService extends HttpWithAbsoluteService {

  generateWhiteboardImage(elementId: string): Promise<string> {
    const promise: Promise<string> = new Promise<string>(resolve => {
      const element = document.querySelector<HTMLElement>(`#${elementId}`);
      if (element) {
        htmlToImage.toPng(element, {
          quality: 0.8, backgroundColor: 'transparent'
        }).then(dataUrl => {
          resolve(dataUrl);
        });
      } else {
        resolve('');
      }
    });

    return promise;
  }

  getWhiteboardImage(pageGuid: string): Promise<string> {
    const promise: Promise<string> = new Promise<string>(resolve => {
      return this.getUrlAndDownloadWithErrorBlob(`whiteboard/whiteboard-background-url/${pageGuid}`, ErrorBlobPath.Image).subscribe(response =>
        resolve(response.body ? window.URL.createObjectURL(response.body) : '')
      );
    });

    return promise;
  }

  updateWhiteboard(lessonGuid: string, whiteboardGuidKey: WhiteboardGuidKey, dataUrl: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.post<string>('whiteboard/upload-whiteboard-background',
      { lessonGuid: lessonGuid, activityGuid: whiteboardGuidKey.activityGuid, pageGuid: whiteboardGuidKey.pageGuid, dataUrl: dataUrl, previousLessonGuid: previousLessonGuid });
  }

  getWhiteboardAll(lessonGuid: string, whiteboardGuidKey: WhiteboardGuidKey): Observable<WhiteboardState> {
    return this.get<WhiteboardState>(`whiteboard/all/${lessonGuid}/${whiteboardGuidKey.activityGuid}/${whiteboardGuidKey.pageGuid}`);
  }

  getWhiteboard(lessonGuid: string, whiteboardGuidKey: WhiteboardGuidKey): Observable<WhiteboardState> {
    return this.get<WhiteboardState>(`whiteboard/${lessonGuid}/${whiteboardGuidKey.activityGuid}/${whiteboardGuidKey.pageGuid}`);
  }
}
