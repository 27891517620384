import { AwardGroupType, GroupIcon } from '../models';
import { Icon } from './icon';

export const iconGroup: readonly GroupIcon[]
  = [
    {
      group: 'Academia', groupTypeId: AwardGroupType.Academia, icons: [

        Icon.AbacusMaths,
        Icon.Alphabet,
        Icon.BooksReading,
        Icon.ExpertAddition,
        Icon.ExpertDivision,
        Icon.ExpertMultiplication,
        Icon.ExpertSubtraction,
        Icon.GraphBarChart,
        Icon.GraphLineChart,
        Icon.LearningMathsFemale,
        Icon.LearningMathsMale,
        Icon.Numeracy,
        Icon.ReadingBook,
        Icon.RulerMeasurement,
        Icon.Time,
        Icon.TriangleMeasurement,
        Icon.WritingHand
      ]
    },
    {
      group: 'Achievement', groupTypeId: AwardGroupType.Achievement, icons: [
        Icon.BrainSmart,
        Icon.CheckCorrect,
        Icon.ClapHands,
        Icon.CrownKingQueen,
        Icon.DiamondJewel,
        Icon.LightBulbBright,
        Icon.Medal1stFirst,
        Icon.Medal2ndSecond,
        Icon.Medal3rdThird,
        Icon.MedalAward,
        Icon.MedalBadge,
        Icon.StarGold,
        Icon.StarSilver,
        Icon.StarBronze,
        Icon.ThumbsUp,
        Icon.TrophyAward,
        Icon.RubyTrophy,
        Icon.PlatinumTrophy,
        Icon.DiamondTrophy,
        Icon.UnlockedLevel
      ]
    },
    {
      group: 'Events', groupTypeId: AwardGroupType.Events, icons: [
        Icon.BirthdayBalloon,
        Icon.BirthdayCake,
        Icon.BirthdayRibbon,
        Icon.CelebrateParty,
        Icon.ChristmasTree,
        Icon.GraduatedBoy,
        Icon.GraduatedGirl,
        Icon.GraduationAcademic,
        Icon.PresentGift,
        Icon.SantaClaus,
        Icon.Halloween,
        Icon.Snowman
      ]
    },
    {
      group: 'Performance', groupTypeId: AwardGroupType.Performance, icons: [
        Icon.BlastOff,
        Icon.BullseyeTarget,
        Icon.ClimbingSkill,
        Icon.ComputerWhizBoy,
        Icon.ComputerWhizGirl,
        Icon.ElectricLightning,
        Icon.ExplosiveBang,
        Icon.ExplosiveBomb,
        Icon.ExplosiveTnt,
        Icon.FinishFlag,
        Icon.FireForce,
        Icon.GraphWhizBoy,
        Icon.GraphWhizGirl,
        Icon.HomeworkMaster,
        Icon.MagicWand,
        Icon.OneHundred,
        Icon.RacecarSpeed,
        Icon.RunningSpeedBoy,
        Icon.RunningSpeedGirl,
        Icon.ScienceWhizBoy,
        Icon.ScienceWhizGirl,
        Icon.SpaceCadetBoy,
        Icon.SpaceCadetGirl,
        Icon.StopwatchTimer,
        Icon.StrongMuscles,
        Icon.SuperheroPower,
        Icon.WizardMagic
      ]
    },
    {
      group: 'DIY', groupTypeId: AwardGroupType.Diy, icons: [
        Icon.DiySet11,
        Icon.DiySet12,
        Icon.DiySet13,
        Icon.DiySet14,
        Icon.DiySet21,
        Icon.DiySet22,
        Icon.DiySet23,
        Icon.DiySet24,
        Icon.DiySet31,
        Icon.DiySet32,
        Icon.DiySet33,
        Icon.DiySet34
      ]
    }
  ];
