import * as moment from 'moment';
import { DateTimeOffsetIanaTimeZoneName } from 'moment-extensions-lib';
import { BehaviorSubject } from 'rxjs';

import { IdleStatus } from '../../shared';

export const idleThresholdTimeInSeconds = 60;
export const idleWarnTimeInSeconds = 90;
export const idleLogoutTimeInSeconds = 120;
export const timeAllowedAfterLessonEndInMinutes = 58;

export abstract class IdleService<T, T2> {

  readonly #idleSecondsCounter$ = new BehaviorSubject(0);
  #idleInterval = 0;
  #initialized = false;
  readonly #targetEvents = ['click', 'keypress', 'mousemove'];

  protected id: T | undefined;
  protected messageId: T2 | undefined;
  protected dateTimeOffsetIanaTimeZoneName: DateTimeOffsetIanaTimeZoneName | undefined;
  protected duration = 0;
  protected disabled = false;
  protected previousIdleStatus: IdleStatus | undefined;

  constructor() {
    // rebind this method, so we can call it directly from DOM event listener
    this.reset = this.reset.bind(this);
  }

  get idleSecondsCounter$() {
    return this.#idleSecondsCounter$;
  }

  get now() {
    if (this.dateTimeOffsetIanaTimeZoneName) {
      return moment.tz(moment(), this.dateTimeOffsetIanaTimeZoneName.ianaTimeZone).startOf('minute');
    }

    return undefined;
  }

  get sessionEndTime() {
    if (this.dateTimeOffsetIanaTimeZoneName) {
      return moment.tz(this.dateTimeOffsetIanaTimeZoneName.dateTimeOffset, this.dateTimeOffsetIanaTimeZoneName.ianaTimeZone)
        .startOf('minute')
        .add(this.duration, 'minute');
    }

    return undefined;
  }

  enable() {
    this.disabled = false;
    this.checkIdleTime();
  }

  disable() {
    this.disabled = true;
    this.checkIdleTime();
  }

  logout(showMessage = false) {
    const logoutMessage = 'You have been logged out due to inactivity.';
    this.performLogout(showMessage, logoutMessage);
  }

  resetIdle(disabled: boolean) {
    this.#idleSecondsCounter$.next(0);
    this.disabled = disabled;
    this.previousIdleStatus = undefined;
  }

  start() {
    if (!this.#initialized) {
      this.dateTimeOffsetIanaTimeZoneName = undefined;
      this.resetIdle(false);
      /*eslint-disable @typescript-eslint/unbound-method */
      for (const o of this.#targetEvents) {
        document.addEventListener(o, this.reset);
      }
      /*eslint-enable @typescript-eslint/unbound-method */
      this.#idleInterval = window.setInterval(() => this.checkIdleTime(), 1000);
      this.#initialized = true;
    }
  }

  stop() {
    if (this.#initialized) {
      /*eslint-disable @typescript-eslint/unbound-method */
      for (const o of this.#targetEvents) {
        document.removeEventListener(o, this.reset);
      }
      /*eslint-enable @typescript-eslint/unbound-method */
      this.dateTimeOffsetIanaTimeZoneName = undefined;
      window.clearInterval(this.#idleInterval);
      this.#initialized = false;
    }
  }

  reset() {
    this.#idleSecondsCounter$.next(0);
    this.checkIdleTime();
  }

  abstract updateIdleStatus(id: T, idleStatus: IdleStatus): void;

  abstract updateMessage(messageId: T2): void;

  abstract performLogout(showMessage: boolean, logoutMessage: string): void;

  protected checkIdleTime() {
    if (this.dateTimeOffsetIanaTimeZoneName && this.id && this.messageId) {
      this.#idleSecondsCounter$.next(this.#idleSecondsCounter$.value + 1);
      const idleStatus = this.#determineIdleStatus();

      if (this.previousIdleStatus !== idleStatus) {
        this.updateIdleStatus(this.id, idleStatus);
        this.previousIdleStatus = idleStatus;
      }

      if (idleStatus === IdleStatus.IdleLessonOverLogoutNow) {
        console.log('User Kicked because idle');
        this.updateMessage(this.messageId);
        this.logout(true);
      }
    }
  }

  #determineIdleStatus() {
    const sessionEndTime = this.sessionEndTime;
    const now = this.now;
    let idleStatus = IdleStatus.Active;

    const timeAllowedAfterLessonEnd = timeAllowedAfterLessonEndInMinutes * 60 * 1000;

    if (sessionEndTime !== undefined && now !== undefined && !this.disabled) {
      const idleSecondsCounter = this.#idleSecondsCounter$.value;
      const millisecondsAfterSessionEnd = now.diff(sessionEndTime, 'millisecond');
      if (millisecondsAfterSessionEnd < timeAllowedAfterLessonEnd) {
        idleStatus = idleSecondsCounter >= idleThresholdTimeInSeconds ? IdleStatus.Idle : IdleStatus.Active;
      } else {
        if (idleSecondsCounter >= idleLogoutTimeInSeconds) {
          idleStatus = IdleStatus.IdleLessonOverLogoutNow;
        } else if (idleSecondsCounter >= idleWarnTimeInSeconds) {
          idleStatus = IdleStatus.IdleLessonOverLogoutSoon;
        } else if (idleSecondsCounter >= idleThresholdTimeInSeconds) {
          idleStatus = IdleStatus.IdleLessonOver;
        } else {
          idleStatus = IdleStatus.ActiveLessonOver;
        }
      }
    }

    return idleStatus;
  }

}
