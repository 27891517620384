export enum Subjects {
  English = 1,
  Maths = 2,
  Other = 3,
  EarlyReading = 4,
  Reading = 5,
  Comprehension = 6,
  SeniorMaths = 7,
  SeniorEnglish = 8
}
