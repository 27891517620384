<div class="kip-drill-layout">
  <kip-question-text [question]="question" />
  @if (!isDivision) {
  <table>
    @for (valueBlock of valuesBlock; track valueBlock) {
    <tr>
      @for (value of valueBlock; track value) {
      <td>
        {{ value }}
      </td>
      }
    </tr>
    }
    <tr>
      @for (answerBoxEntry of answerBoxEntries; track answerBoxEntry; let index = $index) {
      <td class="drill-total-line-bottom">
        <kip-drill-answer [answerBoxEntry]="answerBoxEntry" [readonly]="readonly" (change)="onChange()"
          (keyUpIndex)="onKeyUpIndex($event)" (submit)="onSubmit()" />
      </td>
      }
    </tr>
  </table>
  }
  @if (isDivision) {
  <table>
    <tr>
      <td></td>
      @for (answerBoxEntry of answerBoxEntries; track answerBoxEntry; let index = $index) {
      <td class="drill-total-line-top">
        <kip-drill-answer [answerBoxEntry]="answerBoxEntry" [readonly]="readonly" (change)="onChange()"
          (keyUpIndex)="onKeyUpIndex($event)" (submit)="onSubmit()" />
      </td>
      }
    </tr>
    <tr>
      <td>@for (value of valuesBlock[1]; track value) {
        <span>{{ value }}</span>
        }
      </td>
      @for (value of valuesBlock[0]; track value; let index = $index) {
      <td [ngClass]="{'drill-left':index === 0}">
        {{ value }}
      </td>
      }
    </tr>
  </table>
  }
</div>