import { Injectable } from '@angular/core';
import { HttpService } from 'service-lib';

@Injectable({
  providedIn: 'root'
})
export class UpdatePaymentDetailsService extends HttpService {
  readonly paymentDetailsUrl = 'student/payment-details';

  generateVerificationCode() {
    return this.post<string>(`${this.paymentDetailsUrl}/generate-verification-code`, null);
  }

  verifyCode(code: string) {
    return this.post<string>(`${this.paymentDetailsUrl}/verify-code`, { code: code });
  }

  getMobileLastFour() {
    return this.get<string>(`${this.paymentDetailsUrl}/mobile-last-four`);
  }
}
