import { createSelector } from '@ngrx/store';

import { selectSelectedSession } from '../session';
import { selectDropInState } from '../state';
import { DropInState } from './drop-in.reducer';

const selectDropInRequests = createSelector(selectDropInState, (state: DropInState) => state.requests);
export const selectSessionDropInRequests = createSelector(selectDropInRequests, selectSelectedSession, (requests, session) => {
  if (!session) {
    return [];
  }
  return requests.filter(r => r.sessionId === session.sessionId);
});
