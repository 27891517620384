/* eslint-disable @typescript-eslint/no-useless-constructor, @typescript-eslint/no-empty-function, no-param-reassign, unicorn/prefer-logical-operator-over-ternary */

/**
 *
 * This has been copied from the retree.ts file in the ngx-device-detector package
 * on 09/01/23 from commit: b55b52c
 * https://github.com/KoderLabs/ngx-device-detector/blob/master/projects/ngx-device-detector/src/lib/retree.ts
 *
 */

export class ReTree {
  constructor() { }

  public test(stringValue: string, regex: any): any {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.test(stringValue);
    } else if (regex && Array.isArray(regex.and)) {
      return regex.and.every((item: any) => {
        return this.test(stringValue, item);
      });
    } else if (regex && Array.isArray(regex.or)) {
      return regex.or.some((item: any) => {
        return this.test(stringValue, item);
      });
    } else if (regex && regex.not) {
      return !this.test(stringValue, regex.not);
    } else {
      return false;
    }
  }

  public exec(stringValue: string, regex: any): any {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.exec(stringValue);
    } else if (regex && Array.isArray(regex)) {
      return regex.reduce((res: any, item: any) => {
        return res ? res : this.exec(stringValue, item);
      }, null);
    } else {
      return null;
    }
  }
}
