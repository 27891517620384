export enum SpecialNoteCategories {
  Activity = 12,
  Session = 10,
  Lesson = 7,
  Strengths = 13,
  Weaknesses = 14,
  Assessment = 15,
  StartLesson = 16,
  FinishLesson = 17
}
