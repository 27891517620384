import { Pipe, PipeTransform } from '@angular/core';

import { determineAgeDifference } from '../helpers';
import { Age, AgeResult, NullableAge } from '../models';

@Pipe({
  name: 'ageDifference'
})
export class AgeDifferencePipe implements PipeTransform {
  transform(ageResult?: AgeResult | null, age?: NullableAge, studentAge?: Age | null, short = false): string {

    const years = short ? 'Y' : ' years ';
    const months = short ? 'M' : ' months ';

    if (ageResult === AgeResult.Assessed && studentAge && age) {
      return determineAgeDifference(age, studentAge, years, months);
    }

    return '';
  }
}
