<div class="d-flex flex-column h-100 kip-no-select">
  @if (!filteredContentNodes) {
  <div class="d-flex justify-content-center align-items-center h-100 py-3">
    <kip-spinner />
  </div>
  }
  @if (filteredContentNodes) {
  <div class="kip-activity-selector container-fluid d-flex flex-column flex-fill">
    @if (showSearch) {
    <div class="row mt-2 mx-0 px-0 py-2">
      <div class="col px-0">
        <div class="input-group">
          @if (showRegion) {
          <div class="input-group-prepend" ngbDropdown>
            <span class="input-group-text me-1 py-0 h-100" ngbDropdownToggle ngbTooltip="Change Region"
              placement="bottom">
              <kip-region-flag [regionId]="regionId" />
            </span>
            <div aria-labelledby="regionImage" class="kip-dropdown__menu kip-dropdown__menu--top-left ms-n1"
              ngbDropdownMenu>
              @for (region of regions; track region) {
              <button class="btn kip-dropdown__entry" ngbDropdownItem type="button" (click)="regionId = region.id">
                <kip-region-flag class="me-2" [regionId]="region.id" />{{ region.name }}
              </button>
              }
            </div>
          </div>
          }
          <span class="input-group-text rounded-start">
            <fa-icon [icon]="icons.search" />
          </span>
          <input class="form-control" placeholder="Search" type="text" [(ngModel)]="searchText"
            (keyup)="change($event)">
          @if (searchText) {
          <button class="btn btn-outline-secondary" type="button" (click)="resetAll()">
            <fa-icon [icon]="icons.close.solid" />
          </button>
          }
        </div>
      </div>
    </div>
    }
    @if (!filteredContentNodes.length && !filteredCurriculumNodes.length && searchText.length) {
    <div class="text-center m-auto pb-5">
      <div class="kip-lesson-plan__monty text-center pb-5">
        <kip-monty-empty class="d-block m-2 w-75 mx-auto" style="max-width: 180px;" />
        <p class="h4 fw-normal mt-4 lh-2">No activities found matching</p>
        <p class="h4 fw-normal mt-2">"{{ searchText }}"</p>
        <button class="btn kip-btn my-4" type="button" (click)="resetAll()">
          Clear
        </button>
      </div>
    </div>
    }
    @if (!filteredContentNodes.length && !filteredCurriculumNodes.length && !searchText.length) {
    <kip-spinner class="d-block p-5 text-center" />
    }
    @if (filteredContentNodes && filteredContentNodes.length !== 0) {
    <div class="kip-explore-menu overflow-hidden mb-3">
      @for (node of filteredContentNodes; track node) {
      <kip-activity class="kip-activity-block d-block" [allowPreview]="allowPreview" [regionId]="regionId"
        [resetTime]="resetTime" [rootNode]="node" [showStatsAndHistory]="contentHistory !== undefined"
        [singleSelection]="singleSelection" (showPreview)="showPreview.emit($event)" />
      }
    </div>
    }
    @if (displayCurriculumNodes) {
    <kip-curriculum-nodes [allowPreview]="allowPreview" [contentHistory]="contentHistory"
      [curriculumNodes]="filteredCurriculumNodes" [regionId]="regionId" [resetTime]="resetTime"
      [singleSelection]="singleSelection" (showPreview)="showPreview.emit($event)" />
    }
  </div>
  }
</div>