import { createReducer, on } from '@ngrx/store';

import * as SetRefreshActions from './routing.actions';

export interface RoutingState {
  readonly isWindowUnloading: boolean;
}

const initialState: RoutingState = {
  isWindowUnloading: false
};

export const routingReducer = createReducer(initialState,
  on(SetRefreshActions.setWindowUnloadingAction, (state, action): RoutingState => {
    return {
      ...state,
      ...action,
      isWindowUnloading: action.isWindowUnloading
    };
  })
);
