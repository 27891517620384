/* eslint-disable rxjs/no-ignored-subscription */

import { EventEmitter, Injectable } from '@angular/core';
import * as OT from '@opentok/client';
import { BehaviorSubject } from 'rxjs';
import { HttpService, ServiceEnvironment } from 'service-lib';

import { CurrentDevices } from '.';
import { Devices, OpenTokData, OpenTokSession, OpenTokStream, UserDetails, UserRole } from './models';

enum OpentokDeviceKeys {
  AudioDevice = 'audioDevice',
  VideoDevice = 'videoDevice'
}

enum OpentokStorageKeys {
  Id = 'id',
  Label = 'label'
}

export enum OpentokNames {
  StudentScreenShare = 'student',
  ObserverScreenShare = 'observer-screen-share',
  ObserverVideo = 'observer-video'
}

@Injectable({ providedIn: 'root' })
export class OpentokService extends HttpService {

  #videoProviderSessionKey = '';
  #session: OT.Session | undefined;
  readonly #streams = new BehaviorSubject<OpenTokStream[]>([]);
  readonly #peerStreams = new BehaviorSubject<OpenTokStream[]>([]);
  #videoDevices: OT.Device[] = [];
  #audioDevices: OT.Device[] = [];
  #sessionConnected = false;
  #captionsSuccess: boolean | null = null;
  #captionsStatusCode: number | null = null;

  readonly streamConnected = new EventEmitter();
  readonly refreshPublisherRequested = new EventEmitter();

  getOT() {
    return OT;
  }

  get videoProviderSessionKey() {
    return this.#videoProviderSessionKey;
  }

  get captionsSuccess() {
    return this.#captionsSuccess;
  }

  get captionsStatusCode() {
    return this.#captionsStatusCode;
  }

  get videoDevices() {
    return this.#videoDevices;
  }

  get audioDevices() {
    return this.#audioDevices;
  }

  get streams() {
    return this.#streams.asObservable();
  }

  get peerStreams() {
    return this.#peerStreams.asObservable();
  }

  initSessionForTutor(sessionId: number, sessionCaptions: boolean) {
    return this.#initSessionforPath(`video/tutor/${sessionId}?sessionCaptions=${sessionCaptions}`, [UserRole.Student, UserRole.Observer]);
  }

  initSessionForStudent(lessonId: number) {
    return this.#initSessionforPath(`video/student/${lessonId}`, [UserRole.Tutor]);
  }

  initSessionForObserver(sessionId: number) {
    return this.#initSessionforPath(`video/observer/${sessionId}`, [UserRole.Tutor, UserRole.Student]);
  }

  disconnectSession() {
    this.#streams.next([]);
    this.#peerStreams.next([]);
    if (this.#session) {
      this.#session.off();
      if (this.#sessionConnected) {
        this.#session.disconnect();
      }
      this.#session = undefined;
    }
  }

  getDevices() {
    return new Promise<Devices>((resolve, reject) => {
      const audioInput = 'audioInput';
      const videoInput = 'videoInput';
      const checkInterval = setInterval(() => {
        OT.getDevices((err, devices) => {
          if (err) {
            clearInterval(checkInterval);
            reject(err);
            return;
          }
          const devicesAll = devices ?? [];
          if (devicesAll.length === 0 || devicesAll.some(device => device.label)) {
            this.#audioDevices = devicesAll.filter(s => s.kind === audioInput);
            this.#videoDevices = devicesAll.filter(s => s.kind === videoInput);

            clearInterval(checkInterval);
            resolve({ audio: this.#audioDevices, video: this.#videoDevices });
          }
        });
      }, 250);
    });
  }

  setCurrentVideo(deviceId: string) {
    this.getDevices().then(devices => {
      const device = devices.video.find(d => d.deviceId === deviceId);
      if (device) {
        this.#setStorage(OpentokDeviceKeys.VideoDevice, OpentokStorageKeys.Id, device.deviceId);
        this.#setStorage(OpentokDeviceKeys.VideoDevice, OpentokStorageKeys.Label, device.label);
      }
    });
  }

  setCurrentAudio(deviceId: string) {
    this.getDevices().then(devices => {
      const device = devices.audio.find(d => d.deviceId === deviceId);
      if (device) {
        this.#setStorage(OpentokDeviceKeys.AudioDevice, OpentokStorageKeys.Id, device.deviceId);
        this.#setStorage(OpentokDeviceKeys.AudioDevice, OpentokStorageKeys.Label, device.label);
      }
    });
  }

  getCurrentDevices() {
    return new Promise<CurrentDevices>((resolve, reject) => {
      this.getDevices().then(devices => {
        resolve({
          audio: this.#getCurrentDevice(devices, OpentokDeviceKeys.AudioDevice),
          video: this.#getCurrentDevice(devices, OpentokDeviceKeys.VideoDevice)
        });
      })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(reject);
    });
  }

  refreshPublisher() {
    this.refreshPublisherRequested.emit();
  }

  #getStorage(key: OpentokDeviceKeys, storageKey: OpentokStorageKeys) {
    return localStorage.getItem(this.#getKey(key, storageKey));
  }

  #setStorage(key: OpentokDeviceKeys, storageKey: OpentokStorageKeys, value: string) {
    localStorage.setItem(this.#getKey(key, storageKey), value);
  }

  #getKey(key: OpentokDeviceKeys, storageKey: OpentokStorageKeys) {
    return `${key}.${storageKey}`;
  }

  #getCurrentDevice(devices: Devices, key: OpentokDeviceKeys) {
    const relevantDevices = key === OpentokDeviceKeys.VideoDevice ? devices.video : devices.audio;
    let device = relevantDevices.find(d => d.deviceId === this.#getStorage(key, OpentokStorageKeys.Id));
    if (!device) {
      const label = this.#getStorage(key, OpentokStorageKeys.Label);
      device = this.#getDeviceByLabel(relevantDevices, label);
    }
    return device;
  }

  #getDeviceByLabel(devices: readonly OT.Device[], label: string | null): OT.Device | undefined {
    if (label) {
      const matches = devices.map(d => ({ device: d, score: this.#scoreDeviceMatch(d.label, label) }))
        .filter(d => d.score > 0).sort((a, b) => b.score - a.score);
      if (matches.length > 0) {
        return matches[0].device;
      }
    }
    return undefined;
  }

  #scoreDeviceMatch(source: string, search: string) {
    let score = 0;
    const sourceLower = source.toLowerCase();
    const searchParts = search.toLowerCase().split(' ');
    for (const searchPart of searchParts) {
      if (sourceLower.includes(searchPart)) {
        score++;
      }
    }
    return score;
  }

  #initSessionforPath(path: string, watchStreamUserRoles: UserRole[]) {
    return new Promise<OpenTokSession>((resolve, reject) => {
      this.get<OpenTokData>(path).subscribe(
        {
          next: value => {
            const streams: OpenTokStream[] = [];
            const peerStreams: OpenTokStream[] = [];

            this.#videoProviderSessionKey = value.videoProviderSessionKey;
            this.#captionsSuccess = value.captionsSuccess;
            this.#captionsStatusCode = value.captionsStatusCode;

            if (!this.#session) {
              this.#session = this.getOT().initSession(ServiceEnvironment.value.openTok?.apiKey ?? '', value.videoProviderSessionKey);

              // tutors want to watch students and students want to watch tutors
              // may want to have other people watching eventually
              // for example - centre managers

              this.#session.on({
                streamCreated: (event: { stream: OT.Stream }) => {
                  const userDetails = JSON.parse(event.stream.connection.data) as UserDetails;
                  if (watchStreamUserRoles.includes(userDetails.userRole)) {
                    streams.push({ stream: event.stream, userDetails: userDetails });
                    this.#streams.next([...streams]);
                  } else {
                    peerStreams.push({ stream: event.stream, userDetails: userDetails });
                    this.#peerStreams.next([...peerStreams]);
                  }
                },
                sessionConnected: () => {
                  this.#sessionConnected = true;
                  this.streamConnected.emit();
                },
                sessionDisconnected: () => this.#sessionConnected = false,
                streamDestroyed: (event: { stream: OT.Stream }) => {
                  const index = streams.findIndex(s => s.stream === event.stream);
                  if (index > -1) {
                    streams.splice(index, 1);
                    this.#streams.next([...streams]);
                  }

                  const peerIndex = peerStreams.findIndex(s => s.stream === event.stream);
                  if (peerIndex > -1) {
                    peerStreams.splice(peerIndex, 1);
                    this.#peerStreams.next([...peerStreams]);
                  }
                }
              });

              this.#session.connect(value.userAccessToken, err => {
                if (err || !this.#session) {
                  reject(err);
                } else {
                  resolve({ name: value.fullName, session: this.#session });
                }
              });
            } else {
              resolve({ name: value.fullName, session: this.#session });
            }
          },
          error: err => { reject(err); }
        });
    });
  }
}
