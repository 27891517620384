<kip-expandable-setting header="Tutor Subjects" hint="Tutor Subjects" [expanded]="showTutorSubjects"
  (toggled)="openSubjects()">
  @for (subject of allSubjects; track subject) {
  <div class="row g-0">
    <div class="col-12 custom-control custom-checkbox px-1 mb-3">
      <div class="form-check">
        <input #subjectCheckbox class="form-check-input" type="checkbox" [id]="'subject-' + subject.shortCode"
          [name]="'subject-' + subject.shortCode" [value]="subject.id" (click)="setSubject(subject)">
        <label class="custom-control-label form-check-label form-check-label--append"
          [for]="'subject-' + subject.shortCode">
          {{subject?.title}}
        </label>
      </div>
    </div>
  </div>
  }
</kip-expandable-setting>