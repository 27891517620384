<ng-template #content>
  <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
    <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icons.file" />
      Edit Activity Note
    </h4>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="noteForm" (ngSubmit)="submit()">
      @if (note(); as note) {
      <div class="text-overline small-2 mb-2">
        {{ note.activityName }}
      </div>
      }
      <kip-emoji-edit label="Internal" name="sessionInternal" ngDefaultControl subLabel="PRIVATE" [edit]="true"
        [formControl]="internalNote" />
      @if (showParent) {
      <hr class="my-3">
      }
      @if (showParent) {
      <kip-emoji-edit label="Parent" name="sessionParent" ngDefaultControl subLabel="SHARED" [edit]="true"
        [formControl]="parentNote" [initFocus]="false" />
      }
      @if (showParent) {
      <div class="py-3 d-flex align-items-center">
        <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
        <div class="pe-3">Summary notes entered here will appear in the Parent Portal. Be sure to write clean, concise
          and respectful notes
          as this will be visible to the parent.</div>
      </div>
      }
      <kip-form-error [formGroup]="noteForm" />
      <div class="mt-3">
        <button class="btn btn-primary" type="submit">Submit Activity Summary</button>
        <button class="btn btn-link" type="button" (click)="close()">Cancel</button>
      </div>
    </form>
  </div>
</ng-template>