import { ChangeDetectionStrategy, Component, effect, inject, model, OnDestroy, OnInit, output, TemplateRef, viewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { ActivityNote, ActivityNoteUpdate, NotesForSession } from '../models';
import { NoteService } from '../services';

@Component({
  selector: 'kip-activity-note',
  templateUrl: './activity-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityNoteComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #modalService = inject(NgbModal);
  readonly #noteService = inject(NoteService);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly showParent = false;

  noteForm: FormGroupTypeSafe<NotesForSession>;

  get internalNote() {
    return this.noteForm.getSafe(s => s.internalNote);
  }

  get parentNote() {
    return this.noteForm.getSafe(s => s.parentNote);
  }

  readonly note = model.required<ActivityNote | undefined>();

  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly noteSaved = output<NotesForSession>();

  constructor() {
    this.noteForm = this.#fb.group<NotesForSession>({
      internalNote: new FormControl<string | null>(null),
      parentNote: new FormControl<string | null>(null),
      internalNoteRanking: new FormControl<number | null>(null),
      parentNoteRanking: new FormControl<number | null>(null)
    });

    effect(() => {
      const note = this.note();
      const modalContent = this.modalContent();

      if (note && modalContent) {
        this.#patch();
        this.#modalService.open(modalContent, { size: 'lg', centered: true });
      } else {
        this.#modalService.dismissAll();
      }
    });
  }

  ngOnInit() {
    this.#patch();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    const lessonNoteUpdate: ActivityNoteUpdate = {
      lessonActivityPlanId: this.note()?.lessonActivityPlanId ?? 0,
      internalNote: this.noteForm.value.internalNote,
      parentNote: this.noteForm.value.parentNote
    };
    this.#subscriptions.push(
      this.#noteService.updateActivityNote(lessonNoteUpdate).subscribe(
        {
          next: value => {
            this.note.set(value);
            this.noteSaved.emit(this.noteForm.value);
            this.close();
          },
          error: (error: unknown) => {
            this.noteForm.handleServerErrors(error);
          }
        }));
  }

  close() {
    this.#modalService.dismissAll();
  }

  #patch() {
    const note = this.note();
    if (this.noteForm && note) {
      this.noteForm.patchSafe({
        internalNote: note.internalNote,
        parentNote: note.parentNote
      });
    }
  }
}
