
import { MarkdownOption } from '../models';
import { imageClassWidth } from './markdown-attrs';

export const MarkdownOptions: MarkdownOption = {
  bold: {
    prepend: '**',
    append: '**',
    placeholder: 'text'
  },
  italic: {
    prepend: '*',
    append: '*',
    placeholder: 'text'
  },
  h1: {
    prepend: '\n# ',
    append: '',
    placeholder: 'text'
  },
  h2: {
    prepend: '\n## ',
    append: '',
    placeholder: 'text'
  },
  h3: {
    prepend: '\n### ',
    append: '',
    placeholder: 'text'
  },
  sup: {
    prepend: '^',
    append: '^',
    placeholder: 'text'
  },
  sub: {
    prepend: '~',
    append: '~',
    placeholder: 'text'
  },
  unorderedList: {
    prepend: '\n+ ',
    append: '',
    placeholder: 'text'
  },
  orderedList: {
    prepend: '\n1. ',
    append: '',
    placeholder: 'text'
  },
  image: {
    prepend: '![Image]',
    append: '',
    placeholder: ''
  },
  imageWidth20: {
    prepend: '',
    append: imageClassWidth(20),
    placeholder: ''
  },
  imageWidth40: {
    prepend: '',
    append: imageClassWidth(40),
    placeholder: ''
  },
  imageWidth60: {
    prepend: '',
    append: imageClassWidth(60),
    placeholder: ''
  },
  imageWidth80: {
    prepend: '',
    append: imageClassWidth(80),
    placeholder: ''
  },
  imageWidth100: {
    prepend: '',
    append: imageClassWidth(100),
    placeholder: ''
  }
};
