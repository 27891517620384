import { fabric } from 'fabric';

import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardTool } from '../whiteboard-tool';

export class WhiteboardLassoTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Lasso);
  }

  override selectable(_obj: fabric.Object): boolean {
    return true;
  }

  setup(_color: WhiteboardColor, canvas: fabric.Canvas) {
    this.resetCanvas(canvas, false, true);
    this.setSelectableState(canvas, true);

    canvas.on('selection:created', (event: any) => {
      if (event.selected && event.selected.length > 1) {

        // Deselect the group and activate selection on the first object in the group
        // This is to prevent complications with group rotation and rehydration
        canvas.discardActiveObject();

        // Then select the first object in the selection
        const obj = event.selected[0];
        canvas.setActiveObject(obj);
      }
    });

    canvas.on('mouse:up', () => {
      const selected: fabric.Object | null = canvas.getActiveObject();
      canvas.fire('object:moved', { target: selected });
    });
  }

  teardown(canvas: fabric.Canvas) {
    canvas.selection = false;
    canvas.off('selection:created');
    canvas.off('text:editing:entered');

    // Reset the selectable state of all objects
    this.setSelectableState(canvas, false);
  }
}
