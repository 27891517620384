/* eslint-disable guard-for-in, rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import * as Filter from 'leo-profanity';
import * as moment from 'moment';
import { Observable, withLatestFrom } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LessonActivity, LessonActivityType, LessonQuestion, SessionActivityType } from '../../../shared';
import { Lesson } from '../../models';
import { SessionService } from '../../services';
import * as fromActivity from '../activity';
import * as fromChat from '../chat';
import * as fromLesson from '../lesson';
import * as fromLog from '../log';
import { getEventsRequest } from '../log';
import * as fromQuestion from '../question';
import * as fromSession from '../session';
import { TutorState } from '../state';

@Injectable()
export class SessionEffects {

  readonly #actions$ = inject(Actions);
  readonly #sessionService = inject(SessionService);
  readonly #store = inject(Store<TutorState>);
  readonly #authService = inject(AuthService);

  changeToAssessment$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.changeToAssessmentAction),
      tap(action => {
        this.#sessionService.changeToAssessment(action.sessionId).subscribe(() => {
          window.location.reload();
        });
      }));
  },
    { dispatch: false }
  );

  addCaption$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.logCaptionAction),
      withLatestFrom(
        this.#store.select(fromSession.selectCaptions)
      ),
      switchMap(([action, captions]) => {

        const actions: Action[] = [];
        const last10 = captions.slice(-10);

        // block duplicates
        if (!last10.some(s => s.caption === action.caption)) {
          const warning = Filter.check(action.caption.toLowerCase());
          this.#sessionService.addCaption(action.sessionId, action.studentId, action.observerUserId, action.tutorId, action.caption, warning).subscribe(() => {
            // do nothing
          });
          actions.push(fromSession.addCaptionAction({
            caption: action.caption,
            name: action.name,
            warning: warning,
            sent: action.tutorId !== null,
            timestamp: moment.utc().toDate()
          }));
        }

        return actions;
      }));
  }
  );

  updateGeneralNotes$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.updateGeneralNotesAction),
      switchMap(action => {
        return this.#sessionService.getSessionNotesUpdate(action.sessionId)
          .pipe(
            switchMap(result => {
              const actions: Action[] = [
                fromSession.updateNotesAction({
                  sessionId: result.sessionId,
                  internalNote: result.internalNote,
                  internalNoteRanking: result.internalNoteRanking,
                  parentNote: result.parentNote,
                  parentNoteRanking: result.parentNoteRanking
                })
              ];
              for (const lessonNote of result.lessonNotes) {
                actions.push(fromLesson.updateNotesAction({
                  lessonGuid: lessonNote.lessonGuid,
                  internalNote: lessonNote.internalNote,
                  internalNoteRanking: lessonNote.internalNoteRanking,
                  parentNote: lessonNote.parentNote,
                  parentNoteRanking: lessonNote.parentNoteRanking,
                  aiNote: lessonNote.aiNote,
                  focusNote: lessonNote.focusNote,
                  parentNoteApproved: lessonNote.parentNoteApproved
                }));
                for (const activityNote of lessonNote.activityNotes) {
                  actions.push(fromActivity.updateNotesAction({
                    activityGuid: activityNote.activityGuid,
                    internalNote: activityNote.internalNote
                  }));
                }
              }

              return actions;
            }));
      }));
  });

  changeDropInSessionAvailability$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.updateDropInSessionAvailabilityAction),
      tap(action => this.#sessionService.setDropInSessionAvailability(action.sessionId, action.isAvailable).subscribe()));
  },
    { dispatch: false }
  );

  tutorPhotoLoad$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.tutorPhotoLoadAction),
      switchMap(action => this.#sessionService.getTutorPhoto(action.tutorId)
        .pipe(
          switchMap(response =>
            [fromSession.tutorPhotoUpdateAction(
              {
                sessionId: action.sessionId,
                tutorPhotoUrl: response.body ? window.URL.createObjectURL(response.body) : '',
                tutorPhotoDefault: response.headers.get('image-default') === 'true'
              })]
          )
        )
      )
    );
  });

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.openAction),
      switchMap(action => {

        return this.#sessionService
          .getSession(action.sessionId, getEventsRequest())
          .pipe(
            switchMap(session => {
              const lessons: Lesson[] = [];
              const activities: fromActivity.ActivityContext[] = [];
              const questions: fromQuestion.QuestionContext[] = [];

              // Get the lessons, activities, questions and logs
              let logActions: Action[] = [];
              let chatActions: Action[] = [];
              const studentPhotoActions: Action[] = [];

              for (const sessionLesson of session.sessionLessons) {
                studentPhotoActions.push(fromLesson.studentPhotoLoadAction(
                  {
                    lessonGuid:
                      sessionLesson.lesson.lessonGuid,
                    studentId: sessionLesson.studentId
                  }));
                lessons.push(sessionLesson);

                if (sessionLesson.lesson.activities) {
                  for (const activity of sessionLesson.lesson.activities) {

                    activities.push(this.getLessonActivity(sessionLesson.lesson.lessonGuid, activity, SessionActivityType.Activity));

                    if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.computer.questions));
                    }

                    if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.timedComputer.questions));
                    }
                  }
                }

                if (sessionLesson.lesson.homework) {
                  for (const activity of sessionLesson.lesson.homework) {

                    activities.push(this.getLessonActivity(sessionLesson.lesson.lessonGuid, activity, SessionActivityType.Homework));

                    if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.computer.questions));
                    }

                    if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.timedComputer.questions));
                    }
                  }
                }

                if (sessionLesson.lesson.previousHomework) {
                  for (const activity of sessionLesson.lesson.previousHomework) {

                    activities.push(this.getLessonActivity(sessionLesson.lesson.previousLessonGuid, activity, SessionActivityType.PreviousHomework));

                    if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.previousLessonGuid, activity.computer.questions));
                    }

                    if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                      questions.push(...this.getLessonQuestions(sessionLesson.lesson.previousLessonGuid, activity.timedComputer.questions));
                    }
                  }
                }

                // Add multiple logs loading actions so we can tie them to their lesson
                const logAction = fromLog.loadManyAction(
                  {
                    lessonGuid: sessionLesson.lesson.lessonGuid,
                    logs: sessionLesson.lesson.logs ?? []
                  });
                logActions = [...logActions, logAction];

                const chatAction = fromChat.loadManyAction(
                  {
                    lessonGuid: sessionLesson.lesson.lessonGuid,
                    chatMessages: sessionLesson.lesson.chat ?? []
                  });
                chatActions = [...chatActions, chatAction];
              }

              // Build the actions to load the lesson data
              const actions: Action[] = [
                fromSession.loadAction({ session: session }),
                fromLesson.loadManyAction({ sessionLessons: lessons }),
                fromActivity.loadManyAction({ contexts: activities }),
                fromQuestion.loadManyAction({ contexts: questions }),
                fromSession.tutorPhotoLoadAction({ sessionId: session.sessionId, tutorId: session.tutorId }),
                ...studentPhotoActions,
                ...logActions,
                ...chatActions
              ];

              return actions;
            }),
            catchError(() => {
              const actions: Action[] = [fromSession.errorAction()];
              return actions;
            })

          );
      })
    );
  });

  addDropInLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.addDropInLessonAction),
      switchMap(action => {

        return this.#sessionService
          .getSessionLesson(action.sessionId, action.lessonId, getEventsRequest())
          .pipe(
            switchMap(sessionLesson => {
              const activities: fromActivity.ActivityContext[] = [];
              const questions: fromQuestion.QuestionContext[] = [];

              // Get the lessons, activities, questions and logs
              let logActions: Action[] = [];
              let chatActions: Action[] = [];
              const studentPhotoActions: Action[] = [];

              studentPhotoActions.push(fromLesson.studentPhotoLoadAction(
                {
                  lessonGuid:
                    sessionLesson.lesson.lessonGuid,
                  studentId: sessionLesson.studentId
                }));

              if (sessionLesson.lesson.activities) {
                for (const activity of sessionLesson.lesson.activities) {
                  activities.push(this.getLessonActivity(sessionLesson.lesson.lessonGuid, activity, SessionActivityType.Activity));

                  if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.computer.questions));
                  }

                  if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.timedComputer.questions));
                  }
                }
              }

              if (sessionLesson.lesson.homework) {
                for (const activity of sessionLesson.lesson.homework) {
                  activities.push(this.getLessonActivity(sessionLesson.lesson.lessonGuid, activity, SessionActivityType.Homework));

                  if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.computer.questions));
                  }

                  if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.lessonGuid, activity.timedComputer.questions));
                  }
                }
              }

              if (sessionLesson.lesson.previousHomework) {
                for (const activity of sessionLesson.lesson.previousHomework) {
                  activities.push(this.getLessonActivity(sessionLesson.lesson.previousLessonGuid, activity, SessionActivityType.PreviousHomework));

                  if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.previousLessonGuid, activity.computer.questions));
                  }

                  if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                    questions.push(...this.getLessonQuestions(sessionLesson.lesson.previousLessonGuid, activity.timedComputer.questions));
                  }
                }
              }

              // Add multiple logs loading actions so we can tie them to their lesson
              const logAction = fromLog.loadManyAction(
                {
                  lessonGuid: sessionLesson.lesson.lessonGuid,
                  logs: sessionLesson.lesson.logs ?? []
                });
              logActions = [...logActions, logAction];

              const chatAction = fromChat.loadManyAction(
                {
                  lessonGuid: sessionLesson.lesson.lessonGuid,
                  chatMessages: sessionLesson.lesson.chat ?? []
                });
              chatActions = [...chatActions, chatAction];

              // Build the actions to load the lesson data
              const actions: Action[] = [
                fromLesson.loadAction({ sessionLesson: sessionLesson }),
                fromActivity.loadManyAction({ contexts: activities }),
                fromQuestion.loadManyAction({ contexts: questions }),
                ...studentPhotoActions,
                ...logActions,
                ...chatActions
              ];

              return actions;
            }),
            catchError(() => {
              const actions: Action[] = [fromSession.errorAction()];
              return actions;
            })

          );
      })
    );
  });

  sessionDisconnect$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.sessionDisconnectAction),
      withLatestFrom(
        this.#store.select(fromSession.selectSelectedSession),
        this.#store.select(fromLesson.selectSessionLessons)
      ),
      switchMap(([action, selectedSession, sessionLessons]) => {
        const actions: Action[] = [];

        for (const sessionLesson of sessionLessons) {
          if (sessionLesson.observe?.observed) {
            actions.push(fromLesson.stopObservingAction({ lessonGuid: sessionLesson.lessonGuid }));
          }

          if (sessionLesson.focus?.focused) {
            actions.push(fromLesson.stopTeachingAction({ lessonGuid: sessionLesson.lessonGuid }));
          }
        }

        if (selectedSession) {
          actions.push(fromSession.closeAction({ sessionId: selectedSession.sessionId }));
          if (selectedSession.isDropIn) {
            actions.push(fromSession.updateDropInSessionAvailabilityAction({ sessionId: selectedSession.sessionId, isAvailable: false }));
          }
        }

        if (action.logoutUser) {
          actions.push(fromSession.tutorLogoutAction({
            showLogoutMessage: action.showLogoutMessage,
            logoutMessage: action.logoutMessage
          }));
        }

        return actions;
      }));
  });

  logout$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSession.tutorLogoutAction),
      tap(action => {
        this.#authService.logout();
        if (action.showLogoutMessage && action.logoutMessage) {
          this.#authService.logoutMessage = action.logoutMessage;
        }
      }));
  },
    {
      dispatch: false
    });

  getLessonActivity(lessonGuid: string, activity: LessonActivity, activityType: SessionActivityType): fromActivity.ActivityContext {
    return {
      lessonGuid: lessonGuid,
      sessionActivityType: activityType,
      activity: activity
    };
  }

  getLessonQuestions(lessonGuid: string, questions: readonly LessonQuestion[]) {
    return questions.map(question => ({
      lessonGuid: lessonGuid,
      question: question
    }));
  }
}
