import { createReducer, on } from '@ngrx/store';
import { ChatState, LessonLogAuthor, loadChat } from 'message-lib';

import * as ChatActions from './chat.actions';

const initialState: ChatState = {
  entityIds: {},
  entities: {},
  chatGroups: [],
  recipientsTyping: []
};

export const chatReducer = createReducer(initialState,
  on(ChatActions.loadManyAction, (state, action): ChatState => {
    return loadChat(state, action);
  }),
  on(ChatActions.addAction, (state, action) => {
    const eventIds = state.entityIds[action.lessonGuid] || [];
    const chatIds = state.chatGroups || [];
    let recipientsTyping = state.recipientsTyping;

    if (action.chatMessages.author === LessonLogAuthor.Student) {
      recipientsTyping = recipientsTyping.filter(x => x !== action.lessonGuid);
    }

    // Only add logs not already in the store
    if (!chatIds.includes(action.chatMessages.chatGroup)) {
      return {
        entityIds: { ...state.entityIds, [action.lessonGuid]: [...eventIds, action.chatMessages.eventId] },
        entities: { ...state.entities, [action.chatMessages.eventId]: action.chatMessages },
        chatGroups: [...state.chatGroups, action.chatMessages.chatGroup],
        recipientsTyping: recipientsTyping
      };
    }

    return state;
  }),
  on(ChatActions.updateTypingAction, (state, action) => {
    let recipientsTyping: string[] | undefined;

    if (action.isTyping && !state.recipientsTyping.includes(action.lessonGuid)) {
      recipientsTyping = [...state.recipientsTyping, action.lessonGuid];
    } else if (!action.isTyping && state.recipientsTyping.includes(action.lessonGuid)) {
      recipientsTyping = state.recipientsTyping.filter(x => x !== action.lessonGuid);
    }

    if (recipientsTyping) {
      return {
        entityIds: state.entityIds,
        entities: state.entities,
        chatGroups: state.chatGroups,
        recipientsTyping: recipientsTyping
      };
    }

    return state;
  }),
  on(ChatActions.refreshChatAction, (state): ChatState => {
    return {
      entityIds: state.entityIds,
      entities: { ...state.entities },
      chatGroups: state.chatGroups,
      recipientsTyping: [...state.recipientsTyping]
    };
  })
);
