import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

import { IdleStatus } from '../../shared';

@Pipe({
  name: 'idleIcon'
})
export class IdleStatusIconPipe implements PipeTransform {

  transform(idleStatus: IdleStatus): IconDefinition {
    switch (idleStatus) {
      case IdleStatus.Active:
        return Icons.idleStatus.active;
      case IdleStatus.Idle:
        return Icons.idleStatus.idle;
      case IdleStatus.ActiveLessonOver:
        return Icons.idleStatus.activeLessonOver;
      case IdleStatus.IdleLessonOver:
        return Icons.idleStatus.idleLessonOver;
      case IdleStatus.IdleLessonOverLogoutNow:
        return Icons.idleStatus.idleLessonOverLogoutNow;
      default:
        return Icons.idleStatus.idleLessonOverLogoutSoon;
    }
  }
}
