import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from 'message-lib';
import { TutorSettings } from 'ui-common-lib';

import { ActivityState } from './activity/activity.reducer';
import { AlertsState } from './alerts/alerts.reducer';
import { DeviceState } from './device/device.reducer';
import { DropInState } from './drop-in/drop-in.reducer';
import { LessonState } from './lesson/lesson.reducer';
import { LogState } from './log/log.reducer';
import { PlanState } from './plan/plan.reducers';
import { QuestionState } from './question/question.reducer';
import { SessionState } from './session/session.reducer';

export interface TutorData {

  readonly session: SessionState;
  readonly lessons: LessonState;
  readonly activities: ActivityState;
  readonly questions: QuestionState;
  readonly logs: LogState;
  readonly plans: PlanState;
  readonly chat: ChatState;
  readonly alerts: AlertsState;
  readonly devices: DeviceState;
  readonly tutorSettings: TutorSettings | undefined;
  readonly dropIn: DropInState;
}

export interface TutorState {
  readonly tutor: TutorData;
}

export const tutorFeatureName = 'tutor';

export const selectTutorState = createFeatureSelector<TutorData>(tutorFeatureName);

export const selectAlertsState = createSelector(selectTutorState, (state: TutorData) => state.alerts);

export const selectSessionState = createSelector(selectTutorState, (state: TutorData) => state.session);
export const selectSessionEntity = createSelector(selectSessionState, (state: SessionState) => state.entity);

const selectLessonState = createSelector(selectTutorState, (state: TutorData) => state.lessons);
export const selectLessonEntityIds = createSelector(selectLessonState, (state: LessonState) => state.entityIds);
export const selectLessonEntities = createSelector(selectLessonState, (state: LessonState) => state.entities);
export const selectLessonEntityState = createSelector(selectLessonState, (state: LessonState) => state.entityState);

const selectActivityState = createSelector(selectTutorState, (state: TutorData) => state.activities);
export const selectActivityEntities = createSelector(selectActivityState, (state: ActivityState) => state.entities);
export const selectActivityEntityState = createSelector(selectActivityState, (state: ActivityState) => state.entityState);

const selectQuestionState = createSelector(selectTutorState, (state: TutorData) => state.questions);
export const selectQuestionEntities = createSelector(selectQuestionState, (state: QuestionState) => state.entities);
export const selectQuestionEntityState = createSelector(selectQuestionState, (state: QuestionState) => state.entityState);

const selectLogState = createSelector(selectTutorState, (state: TutorData) => state.logs);
export const selectLogEntityIds = createSelector(selectLogState, (state: LogState) => state.entityIds);
export const selectLogEntities = createSelector(selectLogState, (state: LogState) => state.entities);

export const selectChatState = createSelector(selectTutorState, (state: TutorData) => state.chat);
export const selectChatEntityIds = createSelector(selectChatState, (state: ChatState) => state.entityIds);
export const selectChatEntities = createSelector(selectChatState, (state: ChatState) => state.entities);

export const selectDeviceState = createSelector(selectTutorState, (state: TutorData) => state.devices);

export const selectTutorSettings = createSelector(selectTutorState, (state: TutorData) => state.tutorSettings);

export const selectDropInState = createSelector(selectTutorState, (state: TutorData) => state.dropIn);
