import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-number',
  templateUrl: './form-control-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlNumberComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | null>>();
  readonly labelExtra = input('');
  readonly readonly = input(false);
  readonly min = input<number | null>(null);
  readonly max = input<number | null>(null);
  readonly range = computed(() => {
    const min = this.min();
    const max = this.max();

    if (min !== null && max !== null) {
      return `between ${min} and ${max}`;
    }

    if (min !== null && max === null) {
      return `>= ${min}`;
    }

    if (min === null && max !== null) {
      return `<= ${min}`;
    }

    return '';
  });

}
