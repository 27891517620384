<div class="kip-card kip-card--summary">
  <div class="kip-card__header">
    <fa-icon class="me-2" size="lg" [fixedWidth]="true" [icon]="icons.accounts" />
    <h2 class="fw-light">{{ isSingleAccount?'Account':'Accounts' }}</h2>
    @if (isSingleAccount && canViewAccount) {
    <a class="btn btn-link btn-link--small text-primary ms-auto"
      [routerLink]="'/accounts/' + accounts[0].accountId">View</a>
    }
  </div>
  @if (accounts) {
  <div class="kip-card__body">
    @if (isSingleAccount) {
    <kip-student-account [account]="accounts[0]" />
    } @else {
    <kip-student-account-list [accounts]="accounts" />
    }
  </div>
  }
  @if (accounts && accounts.length > 0) {
  <div class="kip-card__body"></div>
  }
</div>