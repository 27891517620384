<div class="kip-card-paged">
  @for (item of items | slice: start : end; track item) {
  <div class="kip-card-paged__entry">
    <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{item:item}" />
  </div>
  }
  @if (numberOfPages > 1) {
  <div class="mt-auto col text-center kip-card-paged__pagination pt-0">
    <button class="btn" type="button" [disabled]="isPreviousDisabled" (click)="previousPage()">
      <fa-icon [fixedWidth]="true" [icon]="icons.chevrons.left" />
    </button>
    <span><span class="fw-bold">{{pageNumber}}</span> {{' of ' + numberOfPages}} </span>
    <button class="btn" type="button" [disabled]="isNextDisabled" (click)="nextPage()">
      <fa-icon [fixedWidth]="true" [icon]="icons.chevrons.right" />
    </button>
  </div>
  }
</div>