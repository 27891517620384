/* eslint-disable angular-file-naming/directive-filename-suffix */

import { Directive, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';

import { AnswerType, Question, QuestionBehaviourController, QuestionSpeed, Region, ValidationResult } from '../models';

@Directive()
export abstract class QuestionLayout implements OnInit, OnDestroy {

  #change: Subject<void> | undefined;
  #changeSubscription: Subscription | undefined;

  protected _validationResults: ValidationResult[] = [];
  protected _region: Region | undefined;

  protected autoReady = true;

  autoFocus = true;
  readonly = false;

  replayable = false;
  speed: QuestionSpeed | undefined;

  studentAnswers: readonly AnswerType[] | undefined;
  question: Question | undefined;
  behaviour: QuestionBehaviourController | undefined;

  answered: () => void = () => {
    // ignore
  };

  updated: () => void = () => {
    // ignore
  };

  ready: (ready: boolean) => void = (_ready: boolean) => {
    // ignore
  };
  submit: () => void = () => {
    // ignore
  };

  abstract get answers(): AnswerType[];

  initialize() {
    // ignore
  }

  destroy() {
    // ignore
  }

  incomplete() {
    // ignore
  }

  focusControl() {
    // ignore
  }

  set region(value: Region | undefined) {
    this._region = value;
  }

  get region() {
    return this._region;
  }

  set validationResults(value: ValidationResult[]) {
    this._validationResults = value;
  }

  get validationResults() {
    return this._validationResults;
  }

  sendUpdates() {
    if (!this.#change) {
      this.#change = new Subject();
      this.#changeSubscription = this.#change.pipe(debounceTime(500)).subscribe(this.updated);
    }

    if (this.#change) {
      this.#change.next();
    }
  }

  replay() {
    if (this.autoReady) {
      this.triggerReady(true);
    }
  }

  ngOnInit() {
    this.initialize();

    if (this.autoReady) {
      this.triggerReady(true);
    }
  }

  ngOnDestroy() {
    if (this.#changeSubscription) {
      this.#changeSubscription.unsubscribe();
    }

    this.destroy();
  }

  protected triggerReady(ready: boolean) {

    // Add a guard to ensure the ready is only triggered if a handler is supplied
    if (this.ready) {
      this.ready(ready);
    }
  }
}
