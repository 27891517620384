import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: Date, format = 'D MMM YYYY [at] h:mm a'): string {
    const utc = moment.utc(value);
    const local = utc.local();

    return local.format(format);
  }
}
