import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from './icon.component';

@Component({
  selector: 'kip-icon-points-trade',
  templateUrl: './points-trade.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPointsTradeComponent implements IconComponent {

  @Input({ required: true }) width = 32;
}
