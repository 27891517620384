import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

@Injectable({
  providedIn: 'root'
})
export class TermsService extends HttpService {

  getTerms(centreId: number): Observable<{ html: string }> {
    return this.get<{ html: string }>(`terms/detail/${centreId}`);
  }
}
