@if (formControl) {
<div [style.display]="edit?'':'none'">
  <div class="w-100 d-flex justify-content-between align-items-end">
    <label class="small" [for]="name">{{ label }} &nbsp; {{ subLabel }}</label>
    <button class="text-center p-1 btn btn-sm kip-btn border-0 rounded-0 mb-1" type="button"
      [disabled]="!allowEmojis" (click)="toggleEmojiPicker()">
      <fa-icon class="ng-fa-icon text-secondary" size="1x" [fixedWidth]="true" [icon]="icons.emoji" />
    </button>
  </div>
  <textarea #input class="kip-chat-input w-100 form-control" rows="5" [formControl]="formControl" [id]="name"
    [maxlength]="maxLength" [name]="name" [placeholder]="placeholder" (focus)="focus.emit()"
    (keyup.enter)="enter.emit()"></textarea>
  <div>
    @if (showEmojiPicker) {
    <kip-emoji-picker class="position-absolute" style="right: 0;" [skin]="skin"
      (emojiSelect)="emojiSelect(input, $event)" (skinChange)="skinChange.emit($event)" />
    }
  </div>
</div>
@if (!edit) {
<kip-emoji-message [message]="formControl.value ?? ''" />
}
}