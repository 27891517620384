<style>
  .kip-bundle-select td {
    padding: 12px 2px 12px 6px;
  }

  .kip-bundle-select tbody>tr:hover {
    background-color: #F5F5F5;
  }
</style>

@if (selectView) {
<div class="kip-bundle-select">
  <table class="w-100 ">
    <thead>
      <tr class="border-bottom pb-2">
        <td class="col-2" colspan="2">Bundle</td>
        <td class="col-2">Currency</td>
        @for (bundleType of bundleSelector.bundleTypes; track bundleType) {
        <td class="col">
          {{ bundleType.name}}
        </td>
        }
      </tr>
    </thead>
    <tbody>
      @for (bundleGrade of bundleSelector.bundleGrades; track bundleGrade) {
      @if (bundleGrade.currencies.length > 0) {
      <tr>
        <td [attr.colspan]="3 + bundleSelector.bundleTypes.length">
          {{ bundleGrade.name}}
        </td>
      </tr>
      @for (currency of bundleGrade.currencies; track currency) {
      <tr class="cursor-pointer" (click)="selectCurrency(bundleGrade, currency)">
        <td class="icon-width">
          <fa-icon class="text-primary" [icon]="currency.selected ? icons.radio.on : icons.radio.off" />
        </td>
        <td>Standard</td>
        <td>{{ currency.code}}</td>
        @for (price of currency.prices; track price) {
        <td>
          {{ currency.symbol }}
          {{ price.price === null ? 'N/A' : (price.price | decimalSwap : currency.decimal) }}
        </td>
        }
      </tr>
      }
      }
      }
      @if (bundleSelector.specials.length) {
      <tr>
        <td [attr.colspan]="2 + bundleSelector.bundleTypes.length">
          Specials
        </td>
      </tr>
      }
      @for (special of bundleSelector.specials; track special) {
      <tr class="cursor-pointer" (click)="selectSpecial(special)">
        <td class="icon-width">
          <fa-icon class="text-primary" [icon]="special.selected ? icons.radio.on : icons.radio.off" />
        </td>
        <td>{{ special.name }}</td>
        <td>{{ special.currencyCode}}</td>
        @for (price of special.prices; track price) {
        <td>
          {{ special.symbol }}
          {{ price === null ? 'N/A' : (price | decimalSwap : special.decimal) }}
        </td>
        }
      </tr>
      }
    </tbody>
  </table>
</div>
}

@if (!selectView) {
<h1 class="h1 text-primary mb-4">Our bundles</h1>
<p class="mb-4 h4 fw-normal">We have solutions to address your student's learning needs.</p>
<div class="row">
  @for (bundleType of bundleSelector.bundleTypes; track bundleType) {
  @if (bundleType.price !== 0) {
  <div class="col-12 col-md-6 col-xl-3 mb-3">
    <div class="d-flex flex-column justify-content-between h-100 border border-primary rounded-lg p-3">
      <div class="d-flex justify-content-end">
        @if (!bundleType.badge) {
        <div class="small mb-3 mt-n1 me-n1">&nbsp;</div>
        }
        @if (bundleType.badge) {
        <div [class]="bundleType.badgeClass + ' rounded small px-2 mb-3 mt-n1 me-n1'">{{
          bundleType.badge }}
        </div>
        }
      </div>
      <div>
        <fa-icon class="text-primary me-2" size="lg" [icon]="icons.lesson" />
        <span class="fw-bold">{{bundleType.name }}</span>
      </div>
      <div class="my-3">
        <span class="text-muted fw-light" style="font-size: 48px;">{{ bundleType.currencySymbol }}</span>
        <span class="fw-light" style="font-size: 48px;">{{ bundleType.price | decimalSwap :
          bundleType.currencyDecimal
          }}</span>
        <span class="mt-auto text-secondary small ms-2">{{ bundleType.currencyCode }} /lesson</span>
      </div>
      <div class="w-100 border-top border-primary mb-4"></div>
      <div class="text-overline text-secondary mb-3 w-100">Includes:</div>
      @if (bundleType.inclusion1) {
      <div class="d-flex w-100 mb-3">
        <fa-icon class="text-success ms-1 me-2" size="lg" [icon]="icons.check" />
        <div>
          {{ bundleType.inclusion1 }}
          @if (bundleType.inclusion1Explainer) {
          <div class="small text-secondary"> {{
            bundleType.inclusion1Explainer
            }}
          </div>
          }
        </div>
      </div>
      }
      @if (bundleType.inclusion2) {
      <div class="d-flex w-100 mb-3">
        <fa-icon class="text-success ms-1 me-2" size="lg" [icon]="icons.check" />
        <div>
          {{ bundleType.inclusion2 }}
          @if (bundleType.inclusion2Explainer) {
          <div class="small text-secondary"> {{
            bundleType.inclusion2Explainer
            }}
          </div>
          }
        </div>
      </div>
      }
      <div class="mt-auto text-secondary small">@if (bundleType.showAnnualPrice) {
        <span>Annual cost {{
          bundleType.currencySymbol }}{{ (bundleType.price *
          bundleType.lessons) | decimalSwap : bundleType.currencyDecimal }}</span>
        }
      </div>
    </div>
  </div>
  }
  }
</div>
}