import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { SupportedDeviceInfo, SupportedDeviceService } from 'device-information-lib';

@Component({
  selector: 'kip-supported-browser',
  templateUrl: './supported-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportedBrowserComponent implements OnInit {

  readonly #supportedDeviceService = inject(SupportedDeviceService);

  #deviceInfo: SupportedDeviceInfo | undefined;
  #okClicked = false;

  get display() {
    return !this.#okClicked && (this.#deviceInfo?.outOfDate ?? false);
  }

  get device() {
    return navigator.userAgent;
  }

  get supportedDevices() {
    return this.#deviceInfo?.browserOutOfDateList ?? [];
  }

  @Input() showSupported = window.location.origin.endsWith('.dev') || window.location.origin.includes('localhost');

  ngOnInit() {
    this.#deviceInfo = this.#supportedDeviceService.getCurrentDeviceInfo();
  }

  okClicked() {
    this.#okClicked = true;
  }
}
