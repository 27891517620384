<nav>
  <ul [class]="tabClass()" [class.shadow-tight]="shadowTight()">
    @for (tab of tabs(); track tab) {
    @if (tab.visible)
    {
    <li [class]="tabSheetClass()" [class.kip-tab-sheet__tab--active]="tabIndex() === tab.index"
      (click)="tabIndex.set(tab.index)">
      @if (tab.icon)
      {
      <fa-icon [class]="iconClass()" [icon]="tab.icon" />
      }
      {{ tab.title }}
    </li>
    }
    }
  </ul>
</nav>