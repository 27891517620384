/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error*/

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Chart, ChartDataset, ChartOptions, ChartType } from 'chart.js';
// @ts-ignore
import annotationPlugin from 'chartjs-plugin-annotation';
import { convertAgeToNumber, convertAgeToString, convertNumberToAge } from 'pipes-directives-lib';
import { Age, ComprehensionTest } from 'ui-common-lib';

interface ComprehensionTestStudentData {
  age: Age;
  form: string;
  score: number;
}

@Component({
  selector: 'kip-comprehension-assessment-chart',
  templateUrl: './comprehension-assessment-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComprehensionAssessmentChartComponent {

  #comprehensionTest: readonly ComprehensionTest[] = [];
  #comprehensionTestStudentData: ComprehensionTestStudentData = { age: { year: 0, month: 0 }, score: 0, form: 'A' };

  lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        stacked: false,
        title: {
          display: true,
          text: 'Age'
        }
      },
      y: {
        stacked: false,
        title: {
          display: true,
          text: 'Score'
        }
      }
    },
    plugins: {
      annotation: {
        annotations: [
          {
            type: 'line',
            xMin: '6Y10M',
            xMax: '6Y10M',
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              display: true,
              color: 'orange',
              content: 'Line'
            }
          }
        ]
      },
      legend: {
        position: 'right'
      }
    }
  };
  lineChartLabels: string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  lineChartType: ChartType = 'line';
  lineChartLegend = false;

  lineChartData: ChartDataset[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set comprehensionTestStudentData(value: ComprehensionTestStudentData) {
    if (this.#comprehensionTestStudentData !== value) {
      this.#comprehensionTestStudentData = value;
      this.#rebuildChartData();
    }
  }

  get comprehensionTestStudentData() {
    return this.#comprehensionTestStudentData;
  }

  @Input({ required: true }) set comprehensionTest(value: readonly ComprehensionTest[]) {
    if (this.#comprehensionTest !== value) {
      this.#comprehensionTest = value;
      this.#rebuildChartData();
    }
  }

  get comprehensionTest() {
    return this.#comprehensionTest;
  }

  /* eslint-enable kip/no-unused-public-members */

  #rebuildChartData() {
    Chart.register(annotationPlugin);
    if (this.#comprehensionTest.length > 0) {
      const comprehensionTestWithAges = this.#comprehensionTest.filter(s => s.age !== null &&
        s.form === this.#comprehensionTestStudentData.form);
      const ages = comprehensionTestWithAges.map(s => s.age!);

      const studentAge = convertAgeToNumber(this.#comprehensionTestStudentData.age);
      const studentLabel = convertAgeToString(this.#comprehensionTestStudentData.age, 'Y', 'M');
      const minAge = Math.min(...ages, studentAge);
      const maxAge = Math.max(...ages, studentAge);

      const labels: string[] = [];

      for (let age = minAge; age <= maxAge; age++) {
        labels.push(convertAgeToString(convertNumberToAge(age), 'Y', 'M'));
      }

      const line: { x: string, y: number }[] = comprehensionTestWithAges.filter(a => a.age).map(a => {
        let label = '';
        if (a.age) {
          label = convertAgeToString(convertNumberToAge(a.age), 'Y', 'M');
        }
        return { x: label, y: a.score };
      });

      this.lineChartOptions = {
        responsive: true,
        scales: {
          x: {
            stacked: false,
            type: 'category',
            title: {
              display: true,
              text: 'Age'
            }
          },
          y: {
            stacked: false,
            title: {
              display: true,
              text: 'Score'
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: 'line',
                xMin: studentLabel,
                xMax: studentLabel,
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                  display: true,
                  color: 'orange',
                  content: 'Student'
                }
              },
              {
                type: 'line',
                yMin: this.#comprehensionTestStudentData.score,
                yMax: this.#comprehensionTestStudentData.score,
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                  display: false,
                  color: 'orange',
                  content: 'Student'
                }
              }
            ]
          }
        }
      };

      this.lineChartLabels = labels;
      this.lineChartData = [
        {
          data: line as any, label: 'Standard Words', fill: false, pointRadius: 0
        }
      ];
    }
  }
}
