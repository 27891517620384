import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

export function DateValidator(weekday: number[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date: DateStruct = control.value;
    if (date === null) {
      return { invalidDate: true };
    }
    const momentDate = moment({ year: date.year, month: date.month - 1, date: date.day });
    if (!weekday.includes(momentDate.weekday())) {
      return { invalidDate: true };
    }
    return null;
  };
}
