@if (history(); as history) {
<div class="row g-0 bg-white border border-bottom-0 mb-3">
  <div class="col-12 d-flex justify-content px-3 py-2 border-bottom" [ngClass]="{'kip-section-toggle': hasActivities()}"
    (click)="toggleOrHideHistory()">
    <div class="align-self-center me-3">
      <fa-icon size="lg" [icon]="history.isOnline?icons.lessonDelivery.online:icons.lessonDelivery.inCentre" />
    </div>
    <div class="d-flex flex-column">
      <div class="fw-bold">
        {{history.dateTime | dateFromOffset: 'MMMM DD, YYYY h:mm a' }}@if (isMakeUp()) {
        <span class="kip-badge kip-badge--primary ms-3">DIY</span>
        }
      </div>
      <div class="small fw-bold">
        <span [ngClass]="history.lessonStatus() | lessonStatusIndicator">{{ lessonStatus() }}</span> {{ history.subject
        }} <span class="fw-normal"> with </span> {{ history.tutor }}
      </div>
    </div>
    <div class="d-flex flex-column">
      <kip-ranking [comment]="history.startLessonRankingNote" [ranking]="history.startLessonRanking" />
      <kip-ranking [comment]="history.finishLessonRankingNote" [ranking]="history.finishLessonRanking" />
    </div>
    <div class="d-flex flex-grow-1 justify-content-end me-3 align-self-center">
      <div class="p-0 d-inline-block" container="body" ngbDropdown placement="bottom-right"
        (click)="$event.stopPropagation()">
        <div class="btn btn-link kip-calendar-event__menu" id="123" ngbDropdownToggle>
          <fa-icon id="345" size="1x" [icon]="icons.moreActions" />
        </div>
        <div class="kip-dropdown__menu kip-dropdown__menu--top-right dropdown-menu me-n3" ngbDropdownMenu>
          <a class="kip-dropdown__entry" ngbDropdownItem (click)="editSessionNote.emit(history)">
            <span>
              {{ history.sessionInternalNote() | noteText : history.sessionParentNote() : '' : 'Session Summary' }}
            </span>
          </a>
          <a class="kip-dropdown__entry" ngbDropdownItem (click)="editLessonNote.emit(history)">
            <span>{{ history.internalNote() | noteText : history.parentNote() : history.focusNote() : 'Lesson Summary'
              }}</span>
          </a>
          @if (!isAttended()) {
          <a class="kip-dropdown__entry" ngbDropdownItem (click)="markAttended()">
            <span>Mark attended</span>
          </a>
          }
          @if (!isDeferred()) {
          <a class="kip-dropdown__entry" ngbDropdownItem (click)="markDeferred()">
            <span>Mark deferred</span>
          </a>
          }
          @if (!isMakeUp() && !isAttended() && isDIYCompatible()) {
          <a class="kip-dropdown__entry" ngbDropdownItem (click)="convertToDiy()">
            <span>Convert to DIY</span>
          </a>
          }
        </div>
      </div>
    </div>
    @if (hasActivities() && !singleMode()) {
    <div class="ms-auto align-self-center">
      <fa-icon size="lg" [icon]="collapse() ? icons.chevrons.up : icons.chevrons.down" />
    </div>
    }
    @if (singleMode()) {
    <div class="ms-auto align-self-center">
      <fa-icon size="lg" [icon]="icons.close.regular" />
    </div>
    }
  </div>
  @if (collapse()) {
  <div class="col-12 d-flex flex-column">
    @if (history.activities && history.activities.length > 0) {
    <div>
      @if (history.sessionInternalNote()) {
      <div class="py-2 bg-light border-bottom">
        <fa-icon class="ms-2" size="lg" [icon]="icons.home.regular" />
        <kip-emoji-message [message]="history.sessionInternalNote()" />
      </div>
      }
      @if (history.sessionParentNote()) {
      <div class="py-2 bg-light border-bottom">
        <fa-icon class="ms-2" size="lg" [icon]="icons.contacts" />
        <kip-emoji-message [message]="history.sessionParentNote()" />
      </div>
      }
      @if (history.internalNote()) {
      <div class="py-2 bg-light border-bottom">
        <fa-icon class="ms-2" size="lg" [icon]="icons.home.regular" />
        <kip-emoji-message [message]="history.internalNote()" />
      </div>
      }
      @if (history.parentNote()) {
      <div class="py-2 bg-light border-bottom">
        <fa-icon class="ms-2" size="lg" [icon]="icons.contacts" />
        <kip-emoji-message [message]="history.parentNote()" />
      </div>
      }
      <div class="text-overline--secondary text-center py-2 bg-light border-bottom text-uppercase">
        Lesson
      </div>
      <kip-lesson-history-lesson-detail [plannedActivities]="history.activities" [regionId]="regionId()"
        [showPreview]="!singleMode()" (editActivityNote)="editActivityNote.emit($event)"
        (previewActivity)="outputHistory($event, false)" (showHtml)="showHtml.emit($event)"
        (showVideo)="showVideo.emit($event)" />
    </div>
    }
    @if (history.homework && history.homework.length > 0) {
    <div>
      <div class="text-overline--secondary text-center py-2 bg-light border-bottom text-uppercase">
        Homework
      </div>
      <kip-lesson-history-lesson-detail [plannedActivities]="history.homework" [showPreview]="!singleMode()"
        (editActivityNote)="editActivityNote.emit($event)" (previewActivity)="outputHistory($event, true)"
        (showHtml)="showHtml.emit($event)" (showVideo)="showVideo.emit($event)" />
    </div>
    }
  </div>
  }
</div>
}