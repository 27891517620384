import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

@Pipe({
  name: 'noteCategoryIcon'
})
export class NoteCategoryIconPipe implements PipeTransform {

  transform(value: string): IconDefinition {

    const key = `${value.charAt(0).toLowerCase()}${value.slice(1)}`;

    return (Icons.noteCategory as { [key: string]: IconDefinition })[key] ?? Icons.noteCategory.general;
  }
}
