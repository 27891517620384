/* eslint  @typescript-eslint/require-array-sort-compare : 0 */

import { Answer, AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

const numRegex = new RegExp(/^\w+(?=[(|[])/);
const splitRegex = new RegExp(/(?=[-|+])/gi);
const afterSlashRegex = new RegExp(/[^/]*$/gi);
const beforeSlashRegex = new RegExp(/^.*(?=\/)/gi);

interface Answers {
  numPart: string | undefined;
  valueInsideBrackets: string[];
  possibleAnswers: string[];
}

export class AlgebraValidator implements AnswerValidator {

  region: Region | undefined;

  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {
    if (studentAnswers.length === question.answers.length) {
      return studentAnswers.map((studentAnswer, index) => {
        const answer = question.answers[index];
        return this.#validateAnswer(answer, studentAnswer);
      });
    }
    return Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);
  }

  #validateAnswer(answer: Answer, studentAnswer: AnswerType): ValidationResult {
    const actualValues = this.#getAnswers(studentAnswer);
    const studentAnswers = this.#getAnswerEntries(actualValues);
    for (const expected of answer.values) {
      let expectedValues: string[] = [];
      expectedValues = this.#getAnswers(expected);
      const expectedAnswers = this.#getAnswerEntries(expectedValues);
      if (studentAnswers.length === expectedAnswers.length) {
        return this.#checkAnswer(expectedAnswers, studentAnswers);
      }
    }
    return ValidationResult.Incorrect;
  }

  #checkAnswer(expectedAnswers: Answers[], studentAnswers: Answers[]): ValidationResult {
    let validationResult: ValidationResult = ValidationResult.Incorrect;
    for (let s = 0; s < studentAnswers.length; s++) {
      if (expectedAnswers[s].valueInsideBrackets && expectedAnswers[s].valueInsideBrackets.length > 1
        && expectedAnswers[s].possibleAnswers.length === 0) {
        if (expectedAnswers[s].valueInsideBrackets.length === studentAnswers[s].valueInsideBrackets.length) {
          validationResult = expectedAnswers[s].valueInsideBrackets.sort().every((element: string, i: number) =>
            element === studentAnswers[s].valueInsideBrackets.sort()[i]) && expectedAnswers[s].numPart === studentAnswers[s].numPart ?
            ValidationResult.Correct : ValidationResult.Incorrect;
          if (validationResult === ValidationResult.Incorrect) {
            return validationResult;
          }
        } else {
          validationResult = ValidationResult.Incorrect;
          return validationResult;
        }

      }
      if (expectedAnswers[s].possibleAnswers.length > 0) {
        if (expectedAnswers[s].possibleAnswers.length === studentAnswers[s].possibleAnswers.length) {
          validationResult = expectedAnswers[s].possibleAnswers.sort().every((element: string, i: number) =>
            element === studentAnswers[s].possibleAnswers.sort()[i]) && expectedAnswers[s].numPart === studentAnswers[s].numPart ?
            ValidationResult.Correct : ValidationResult.Incorrect;
          if (validationResult === ValidationResult.Incorrect) {
            return validationResult;
          }
        } else {
          validationResult = ValidationResult.Incorrect;
          return validationResult;
        }
      }
    }
    return validationResult;
  }

  #getAnswerEntries(values: string[]): Answers[] {
    const answers: Answers[] = [];
    for (const actualValue of values) {
      let answersInsideBraces: string[] = [];
      let answerValues: string[] = [];
      const actualNumberPart = new RegExp(numRegex).exec(actualValue)?.filter(elem => elem)[0];
      if (this.#isBracesPresent(actualValue)) {
        answersInsideBraces = [...answersInsideBraces, ...this.#getValuesInsideBrackets(actualValue)];
      }
      if (answersInsideBraces && answersInsideBraces.length > 1) {
        for (const answerInside of answersInsideBraces) {
          answerValues = [...answerValues, ...this.#getAnswerValues(answerInside)];
        }
      } else {
        answerValues = this.#getAnswerValues(actualValue);
      }
      if (answerValues.length > 1) {
        let mergedAnswerValues: string[] = [];
        for (const val of answerValues) {
          mergedAnswerValues = [...mergedAnswerValues, val];
        }
        answerValues = mergedAnswerValues;
      }
      answers.push({ numPart: actualNumberPart, valueInsideBrackets: answersInsideBraces, possibleAnswers: answerValues });
    }
    return answers;
  }

  #getAnswers(value: AnswerType): string[] {
    const answers: string[] = [];
    const answerValue = this.#sanitizeValue(value);
    const answerSlashPresent = answerValue.includes('/');
    if (answerSlashPresent) {
      const answerAfterSlash = new RegExp(afterSlashRegex).exec(answerValue)?.filter(elem => elem)[0];
      const answerBeforeSlash = new RegExp(beforeSlashRegex).exec(answerValue)?.filter(elem => elem)[0];
      if (answerBeforeSlash && answerAfterSlash) {
        answers.push(answerBeforeSlash, answerAfterSlash);
      }
    } else {
      answers.push(answerValue);
    }
    return answers;
  }

  #getValuesInsideBrackets(value: string): string[] {
    let innerGroups: string[] = [];
    const outerGroups = this.#parseParentheses(value, '[', ']');
    if (outerGroups.length > 0) {
      for (const item of outerGroups) {
        innerGroups = this.#parseParentheses(item, '(', ')');
      }
    } else {
      innerGroups = this.#parseParentheses(value, '(', ')');
    }
    return innerGroups;
  }

  #getAnswerValues(value: string): string[] {
    let answers: string[] = [];
    const answer = value.toString();
    if (answer.includes('+') || answer.includes('-') && answer.length > 1) {
      if (!this.#isBracesPresent(answer)) {
        answers = answer.split(splitRegex);
        answers = this.#getPossibleAnswers(answers);
      } else {
        answers = this.#parseParentheses(answer, '(', ')')[0].split(splitRegex);
        answers = this.#getPossibleAnswers(answers);
      }
    } else {
      answers.push(answer);
    }

    return answers;
  }

  #isBracesPresent(value: string): boolean {
    return value.includes('(') && value.includes(')');
  }

  #getPossibleAnswers(values: string[]): string[] {
    const plusSignValues = values.filter(value => value.includes('+'));
    const neutralValues = values.filter(value => !value.includes('+') && !value.includes('-'));
    const alteredPlusValues = plusSignValues.map(value => value.replace('+', ''));
    const alteredNeutralValues = neutralValues.map(value => `+${value}`);
    return [...values, ...alteredPlusValues, ...alteredNeutralValues];
  }

  #parseParentheses(answer: string, startMatch: string, endMatch: string): string[] {
    return answer.split(startMatch).filter(v => v.includes(endMatch)).map(value => value.split(endMatch)[0]);
  }

  #sanitizeValue(value: AnswerType): string {
    let returnValue = '';
    if (value !== undefined) {
      returnValue = value.toString().trim().replace(/ /g, '');
    }
    return returnValue;
  }

}
