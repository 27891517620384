import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

export class TimeMinutesHoursValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {
    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);
    if (studentAnswers.length === question.answers.length) {
      for (let index = 0; index < question.answers.length; index++) {
        const studentAnswer = studentAnswers[index];
        const answer = question.answers[index];
        for (const expected of answer.values) {
          const actualValue = this.#sanitizeValue(studentAnswer);
          const expectedValue = this.#sanitizeValue(expected);
          if (actualValue === expectedValue) {
            validationResults[index] = ValidationResult.Correct;
          }
        }
      }
    }
    return validationResults;
  }
  #sanitizeValue(value: AnswerType): string {

    // allow : or . for time component
    // ignore starting zero

    if (value !== undefined) {
      let returnValue = value
        .toString()
        .replace(/ /g, '')
        .replace(/:/g, '.');

      if (returnValue.startsWith('0')) {
        returnValue = returnValue.slice(1);
      }

      return returnValue;
    }
    return value;
  }
}
