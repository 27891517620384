import { LearningRange } from '../models';
import { lowestExcellentMark, lowestPassMark, perfectMark } from './cutoff-marks';

export function getLearningRangeFromPercentage(percentage: number | undefined): LearningRange {

  if (percentage !== undefined) {
    if (percentage === perfectMark) {
      return LearningRange.Perfect;
    }

    if (percentage >= lowestExcellentMark && percentage < perfectMark) {
      return LearningRange.Excellent;
    }

    if (percentage >= lowestPassMark && percentage < lowestExcellentMark) {
      return LearningRange.Pass;
    }

    return LearningRange.NeedsImprovement;
  }

  return LearningRange.Unknown;
}
