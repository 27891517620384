import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-3-3',
  templateUrl: './diy-set3-3.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet33Component implements IconComponent {

}
