import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { Button, LoadingState, ProfileService } from 'ui-common-lib';

import { AssessmentNote, GeneralNote, StudentNote } from '../../../models';
import { StudentService } from '../../../services';

enum NoteViewingMode {
  Parent = 1,
  Internal = 2,
  General = 3
}

@Component({
  selector: 'kip-student-note',
  templateUrl: './student-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentNoteComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #buttons: Button[] = [];
  #studentId: number | undefined;
  #editing = false;
  #loadingSubscription: Subscription | undefined;
  #studentNoteToEdit: StudentNote | undefined;
  #studentNotes: GeneralNote[] = [];
  #loadingState: LoadingState = LoadingState.Loading;
  #canEdit = false;
  #isParent = false;
  #subscriptions: Subscription[] = [];
  #previousNoteViewingMode = NoteViewingMode.Parent;

  readonly noteViewingMode = NoteViewingMode;
  readonly icons = Icons;

  ranking: number | null = null;

  currentNoteViewingMode = NoteViewingMode.Parent;

  get buttons() {
    return this.#buttons;
  }

  get canEdit() {
    return this.#canEdit;
  }

  get loadingState() {
    return this.#loadingState;
  }

  get studentNotes() {
    return this.ranking === null ? this.#studentNotes : this.#studentNotes.filter(sn =>
      sn.lessonNote?.internalSessionNote?.note.ranking === this.ranking ||
      sn.lessonNote?.internalLessonNote?.note.ranking === this.ranking ||
      sn.lessonNote?.startLessonNote?.note.ranking === this.ranking ||
      sn.lessonNote?.finishLessonNote?.note.ranking === this.ranking
    );
  }

  get allNotes() {
    return this.studentNotes;
  }

  get isParent() {
    return this.#isParent;
  }

  get isLoadingComplete() {
    return this.loadingState === LoadingState.Complete;
  }

  get hasResults() {
    return this.studentNotes && this.studentNotes.length > 0;
  }

  get studentNoteToEdit() {
    return this.#studentNoteToEdit;
  }

  get showInternalNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.Internal;
  }

  get showParentNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.Parent;
  }

  get visibleNotes() {
    if (this.studentNotes) {
      switch (this.currentNoteViewingMode) {
        case NoteViewingMode.Parent:
          return this.studentNotes.filter(x => x.lessonNote?.parentLessonNote ?? x.lessonNote?.parentSessionNote ??
            x.assessmentNote?.resultNotes.some(note => note.parentNote));
        case NoteViewingMode.Internal:
          return this.studentNotes.filter(x => x.lessonNote?.internalLessonNote ?? x.lessonNote?.internalSessionNote ??
            x.lessonNote?.internalActivityNotes ?? x.assessmentNote?.resultNotes.some(note => note.internalNote));
        case NoteViewingMode.General:
          return this.studentNotes.filter(x => x.note);
      }
    }
    return [];
  }

  get pinnedStudentNotes() {
    if (this.studentNotes) {
      return this.studentNotes.filter(x => x.note?.note?.pinned);
    }
    return [];
  }

  get editing() {
    return this.#editing;
  }

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#init();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @Output() readonly generalNoteUpdated = new EventEmitter();

  ngOnInit() {
    this.#init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  add() {
    this.#editing = true;
    this.#previousNoteViewingMode = this.noteViewingMode.General;
  }

  edit(studentNote?: StudentNote) {
    if (this.canEdit) {
      this.#editing = true;
      this.#studentNoteToEdit = studentNote;
      this.#previousNoteViewingMode = this.currentNoteViewingMode;
    }
  }

  cancelled() {
    this.#studentNoteToEdit = undefined;
    this.#editing = false;
  }

  changesMade() {
    this.#init();
    this.currentNoteViewingMode = this.#previousNoteViewingMode;
    this.generalNoteUpdated.emit();
  }

  cancel() {
    if (this.#loadingSubscription) {
      this.#loadingSubscription.unsubscribe();
      this.#loadingSubscription = undefined;
    }
  }

  retry() {
    this.cancel();
    this.#init();
  }

  assessmentNoteCount(assessmentNote: AssessmentNote) {
    return this.currentNoteViewingMode === this.noteViewingMode.Internal ?
      assessmentNote.resultNotes.filter(n => n.internalNote).length :
      assessmentNote.resultNotes.filter(n => n.parentNote).length;
  }

  #init() {
    this.#subscriptions.push(
      this.#profileService.getIsParent().subscribe(value => {
        this.#isParent = value;
        this.currentNoteViewingMode = this.isParent ? NoteViewingMode.Parent : NoteViewingMode.Internal;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        const buttons: Button[] = [];
        if (value) {
          buttons.push({ text: 'Add Note', action: () => { this.add(); } });
        }
        this.#buttons = buttons;
        this.#changeDetectorRef.markForCheck();
      }));
    this.#studentNoteToEdit = undefined;
    this.#editing = false;
    if (this.#studentId) {
      this.#loadingState = LoadingState.Loading;
      this.#subscriptions.push(
        this.#studentService.getNotesByStudentId(this.#studentId).subscribe(
          {
            next: studentNotes => {
              this.#loadingState = LoadingState.Complete;
              this.#studentNotes = studentNotes;
              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              this.#loadingState = LoadingState.Error;
              this.#changeDetectorRef.markForCheck();
            }
          }));
    }
  }
}
