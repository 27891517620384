import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentAccount } from '../../../models';

@Component({
  selector: 'kip-student-account',
  templateUrl: './student-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentAccountComponent {

  readonly icons = Icons;

  @Input({ required: true }) account: StudentAccount | undefined;

  @Input() showAdditionalInfo = true;
}
