import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { Icons } from 'icon-lib';

import { DashboardLesson } from '../models';

@Component({
  selector: 'kip-diy-lesson-card',
  templateUrl: './diy-lesson-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiyLessonCardComponent {

  readonly icons = Icons;

  readonly lesson = input.required<DashboardLesson | undefined>();
  readonly displayOpenButtons = input(true);
  readonly openLesson = output();

}
