<div class="kip-monty">
  <kip-monty-new class="kip-monty__asset" />
  <p class="kip-monty__headline">{{ title() }}</p>
  @if (canEdit()) {
  @if (text(); as text)
  {
  <p class="kip-monty__text"> {{ text }}</p>
  }
  <kip-buttons [buttons]="addButtons()" />
  }
</div>