<ng-template #content>
  @if (showHeader) {
  <div class="d-flex justify-content-between align-items-center kip-tcc pe-1 py-1 border-bottom" ngbAutofocus
    [class.kip-student]="!showHeader">
    <div class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icon" />
      {{ title }}
    </div>
    <button aria-label="Close" class="btn btn-link btn--icon" type="button" (click)="cancel()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  }
  <form [formGroup]="resetForm" (ngSubmit)="submit()">
    <div class="modal-body">
      <ng-content />
      <kip-password-form-partial [form]="resetForm" />
    </div>
    <div class="w-100 p-2 d-flex justify-content-between border-top">
      <button class="btn btn-link me-auto" type="button" (click)="cancel()">Cancel</button>
      <button class="btn text-btn btn-primary" type="button" [disabled]="!resetForm.valid"
        (click)="submit()">Change</button>
    </div>
  </form>
</ng-template>