import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthService, ConfigId } from 'auth-lib';

@Component({
  selector: 'kip-classlink-instant-login-redirect',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClasslinkInstantLoginRedirectComponent implements OnInit {

  readonly #authService = inject(AuthService);

  ngOnInit() {
    this.#authService.login(ConfigId.ClassLink);
  }

}
