import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbDropdownConfig, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { AccountContactLibModule } from 'account-common-lib';
import { EmojiLibModule } from 'emoji-lib';
import { EnrolmentLibModule } from 'enrolment-lib';
import {
  ControlErrorComponent, FormBuilderTypeSafe, FormControlInvalidPipe,
  FormErrorComponent, FormHasErrorPipe,
  FormsLibModule
} from 'forms-lib';
import { HelpLibModule } from 'help-lib';
import { IconLibModule } from 'icon-lib';
import { JiraLibModule } from 'jira-lib';
import { MessageLibModule } from 'message-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { ServiceLibModule } from 'service-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { SharedDirectives } from './directives';
import { NgbDateFormatter } from './helpers/ngb-date-formatter';
import { SharedComponents } from './manifest';
import { SharedPipes } from './pipes';

@NgModule({
  imports: [
    AccountContactLibModule,
    CommonModule,
    DragDropModule,
    EmojiLibModule,
    EnrolmentLibModule,
    FormsLibModule,
    FormsModule,
    HelpLibModule,
    IconLibModule,
    JiraLibModule,
    MessageLibModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    ServiceLibModule,
    StoreModule,
    UiCommonLibModule
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFormatter },
    FormBuilderTypeSafe,
    NgbDropdownConfig
  ],
  declarations: [
    ...SharedDirectives,
    ...SharedPipes,
    ...SharedComponents
  ],
  exports: [
    ControlErrorComponent,
    FormControlInvalidPipe,
    FormErrorComponent,
    FormHasErrorPipe,
    ...SharedDirectives,
    ...SharedPipes,
    ...SharedComponents
  ]
})
export class SharedModule { }
