import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'kip-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressIndicatorComponent {

  readonly percentage = input(0);

}
