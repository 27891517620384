import { ChangeDetectionStrategy, Component, inject, input, model, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconDefinition } from 'icon-lib';
import { Subscription } from 'rxjs';

import { Breadcrumb } from '../models';

@Component({
  selector: 'kip-base-page',
  templateUrl: './base-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasePageComponent implements OnInit, OnDestroy {

  readonly #activatedRoute = inject(ActivatedRoute);
  #subscriptions: Subscription[] = [];

  readonly breadcrumbs = input<Breadcrumb[]>([]);
  readonly title = model('');
  readonly multipleSection = input(false);
  readonly icon = model<IconDefinition | undefined>(undefined);

  ngOnInit() {
    this.#subscriptions.push(this.#activatedRoute.data.subscribe(data => {
      if (data.title) {
        this.title.set(data.title);
      }
      if (data.icon) {
        this.icon.set(data.icon);
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }
}
