/* eslint-disable rxjs/no-ignored-subscription */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

@Injectable({ providedIn: 'root' })
export class AvatarService extends HttpWithAbsoluteService {

  #myAvatar = '';
  #myPhoto = '';
  readonly #myAvatarSubject = new BehaviorSubject<string | undefined>(undefined);

  getAvatar(userId: number) {
    return this.getUrlAndDownloadWithErrorBlobCached(`avatar/url/${userId}`, ErrorBlobPath.Avatar);
  }

  getNewAvatar(): Observable<boolean> {
    return this.get<boolean>('avatar/using-new');
  }

  updateMyAvatar(avatar: string) {
    if (this.#myAvatar) {
      window.URL.revokeObjectURL(this.#myAvatar);
    }
    if (avatar.startsWith('https://models.readyplayer.me')) {
      this.#myAvatar = avatar;
    } else {
      this.#myAvatar = avatar.startsWith('data:') ? avatar : window.URL.createObjectURL(new Blob([avatar], { type: 'image/svg+xml' }));
    }
    this.#myAvatarSubject.next(this.#myAvatar);
  }

  trackMyAvatar() {
    this.myAvatar();
    return this.#myAvatarSubject;
  }

  myAvatar() {
    const promise: Promise<string> = new Promise<string>(resolve => {
      if (this.#myAvatar) {
        this.#myAvatarSubject.next(this.#myAvatar);
        resolve(this.#myAvatar);
      } else {
        this.getUrlAndDownloadOptionalWithErrorBlob('avatar/my-avatar', url => {
          return url.includes('readyplayer');
        }, ErrorBlobPath.Avatar).subscribe(response => {
          if (typeof response === 'string') {
            this.#myAvatar = response;
          } else {
            this.#myAvatar = response.body ? window.URL.createObjectURL(response.body) : '';
          }
          this.#myAvatarSubject.next(this.#myAvatar);
          resolve(this.#myAvatar);
        });
      }
    });

    return promise;
  }

  myPhoto() {
    const promise: Promise<string> = new Promise<string>(resolve => {
      if (this.#myPhoto) {
        resolve(this.#myPhoto);
      } else {
        this.getBlob('avatar/my-photo').subscribe(response => {
          this.#myPhoto = response.body ? window.URL.createObjectURL(response.body) : '';
          resolve(this.#myPhoto);
        });
      }
    });

    return promise;
  }

  getPhoto(userId: number) {
    return this.getBlobCached(`avatar/download-photo/${userId}`);
  }
}
