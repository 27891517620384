<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()"
  [maxLength]="maxLength()">
  <label [class]="labelClass()??''" [for]="id()">{{ label() }} @if (optional)
    {
    <span class="ms-2 small text-uppercase">Optional</span>
    }
    @if (labelExtra(); as labelExtra)
    {
    <span class="ms-2 small text-uppercase">{{ labelExtra }}</span>
    }
    <ng-content />
  </label>
  <textarea class="form-control" [attr.maxLength]="maxLength() ?? null" [formControl]="control()" [id]="id()"
    [readonly]="disabled()" [rows]="rows()"></textarea>
</kip-form-control>