import { AnswerType, AnswerValidator, Question, Region, ValidationResult } from '../models';

export class EqualityValidator implements AnswerValidator {

  region: Region | undefined;
  validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {

    const validationResults = Array.from<ValidationResult>({ length: studentAnswers.length }).fill(ValidationResult.Incorrect);

    if (studentAnswers.length === question.answers.length) {

      // For each provided answer check the corresponding question answer matches
      for (let index = 0; index < studentAnswers.length; index++) {
        const studentAnswer = studentAnswers[index];
        const answer = question.answers[index];

        // Each question answer has a set of alternatives, so check all of them

        for (const expected of answer.values) {
          const actualValue = this.#sanitizeValue(studentAnswer);
          const expectedValue = this.#sanitizeValue(expected);

          if (actualValue === expectedValue) {
            validationResults[index] = ValidationResult.Correct;
          }
        }
      }
    }

    return validationResults;
  }

  #sanitizeValue(value: AnswerType): string {
    if (value !== undefined) {
      return value
        .toString()
        .trim();
    }

    return value;
  }
}
