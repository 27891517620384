<ng-template #content>
  <div class="d-flex justify-content-between align-items-center kip-tcc pe-1 py-1 border-bottom" ngbAutofocus>
    <div class="modal-title h3 p-1 ms-3" id="modal-basic-title">
      <fa-icon class="me-1" size="1x" [icon]="icon()" />
      {{ title() }}
    </div>
    <button aria-label="Close" class="btn btn-link btn--icon" type="button" (click)="answerOk()">
      <fa-icon [icon]="icons.close.regular" />
    </button>
  </div>
  <div class="modal-body">
    <ng-content />
  </div>
  <div class="w-100 p-2 d-flex justify-content-end border-top">
    <button class="btn text-btn btn-primary" type="button" (click)="answerOk()">{{ okText() }}</button>
  </div>
</ng-template>