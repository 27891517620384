import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { WhiteboardGraphOptions } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class GraphToolService {

  readonly whiteboardGraphOptionsSubmitted = new Subject<WhiteboardGraphOptions>();
  readonly openWhiteboardGraphOptionModal = new Subject<void>();

  emitWhiteboardGraphOptionsSubmitted(options: WhiteboardGraphOptions) {
    this.whiteboardGraphOptionsSubmitted.next(options);
  }

  emitOpenWhiteboardGraphOptionsModal() {
    this.openWhiteboardGraphOptionModal.next();
  }

}
