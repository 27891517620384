@if (rootNode) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between border-top py-2 px-2"
  (click)="expand = !expand">
  <div class="flex-column text-start">
    <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
        [icon]="expand ? icons.folder.open : icons.calendar.expand" /></span>
    @if (rootNode.subject) {
    {{ rootNode.subject.name}}
    }
    @if (rootNode.grade) {
    {{ rootNode.grade.name}}
    }
    @if (rootNode.grade2) {
    to {{ rootNode.grade2.name}}
    }
    @if (rootNode.region) {
    <kip-region-flag class="me-2" [regionId]="rootNode.region.id" />
    {{ rootNode.region.name}}
    }
    @if (rootNode.topic) {
    @if (rootNode.topic.code) {
    {{ rootNode.topic.code }} - <span class="text-muted">{{ rootNode.topic.description }}</span>
    }
    @if (!rootNode.topic.code && rootNode.topic.description) {
    {{ rootNode.topic.description }}
    }
    }
  </div>
  <div class="flex-column text-end">
    @if (showStatsAndHistory) {
    <kip-stats [stats]="rootNode.stats" />
    }
    <fa-icon [icon]="expand ? icons.chevrons.down : icons.chevrons.right" />
  </div>
</div>
}
@if (rootNode && rootNode.topic && expand) {
@if (rootNode.topic.activities) {
@for (regionalActivity of rootNode.topic.activities; track regionalActivity) {
@if (regionalActivity.visible) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between border-top py-2 px-3" kipStopPropagation
  (click)="selectActivity(regionalActivity)">
  <div class="flex-column text-start">
    <fa-icon [icon]="regionalActivity.isManual ? icons.activityType.manual : icons.activityType.computer" />
    {{(regionalActivity.name ?? '') | currencySwap : regionId }}
    @if (regionalActivity.description) {
    <span class="text-muted"> -
      {{regionalActivity.description | currencySwap : regionId}}</span>
    }
  </div>
  <div class="flex-column text-end">
    <kip-activity-history [contentHistory]="regionalActivity.history" />
    @if (!singleSelection&&allowPreview) {
    <fa-icon class="me-3" kipStopPropagation [icon]="icons.preview.solid" (click)="previewActivity(regionalActivity)" />
    }
    @if (!singleSelection) {
    <input class="form-check-input mt-0" type="checkbox" [(ngModel)]="regionalActivity.selected">
    }
  </div>
</div>
}
}
}
@if (rootNode.topic.skillbuilderActivities) {
@for (regionalActivity of rootNode.topic.skillbuilderActivities; track regionalActivity) {
@if (regionalActivity.visible) {
<div class="kip-activity cursor-pointer d-flex flex-row justify-content-between border-top py-2 px-3" kipStopPropagation
  (click)="selectActivity(regionalActivity)">
  <div class="flex-column text-start">
    <fa-icon [icon]="icons.skillBuilder" />
    {{(regionalActivity.name ?? '') | currencySwap : regionId }}
    @if (regionalActivity.description) {
    <span class="text-muted"> -
      {{regionalActivity.description | currencySwap : regionId}}</span>
    }
  </div>
  <div class="flex-column text-end">
    <kip-activity-history [contentHistory]="regionalActivity.history" />
    @if (!singleSelection&&allowPreview) {
    <fa-icon class="me-3" kipStopPropagation [icon]="icons.preview.solid" (click)="previewActivity(regionalActivity)" />
    }
    @if (!singleSelection) {
    <input class="form-check-input mt-0" type="checkbox" [(ngModel)]="regionalActivity.selected">
    }
  </div>
</div>
}
}
}
}

@if (rootNode && rootNode.children && expand) {
@for (node of rootNode.children; track node) {
<div class="ps-4">
  <kip-curriculum-node [allowPreview]="allowPreview" [regionId]="regionId" [resetTime]="resetTime" [rootNode]="node"
    [showStatsAndHistory]="showStatsAndHistory" [singleSelection]="singleSelection"
    (showPreview)="showPreview.emit($event)" />
</div>
}
}