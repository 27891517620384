@if (studentGoal) {
<article class="kip-note kip-note--pinned">
  <header class="d-flex align-items-center mb-3">
    <fa-icon class="me-2" [icon]="icons.goals.solid" />
    <p class=" small mb-0">{{ studentGoal.goal.reviewDate | dateFromDateStruct}}</p>
  </header>
  <div class="d-flex flex-column">
    <div class="kip-info">
      <div class="kip-info__entry mb-3">{{ studentGoal.goal.description }}</div>
    </div>
  </div>
  <div class="mt-auto d-flex">
    <div class="kip-note__user">
      <span class="small">{{ studentGoal.createdOn | dateTime: 'DD MMM YYYY' }}</span>
    </div>
    <div class="ms-auto kip-note__user small">
      <fa-icon class="me-2" [icon]="icons.tutor.solid" />
      <span>{{ studentGoal.createdByUser }}</span>
    </div>
  </div>
</article>
}