import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService, Switchable, SwitchableUser } from 'ui-common-lib';

enum SwitchUserIndex {
  Student = 0,
  Tutor = 1
}

@Component({
  selector: 'kip-switch-user',
  templateUrl: './switch-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchUserComponent implements OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #modalService = inject(NgbModal);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #open = false;
  #switchable: Switchable = { tutors: [], students: [] };
  #subscriptions: Subscription[] = [];

  #filter = '';
  #students: readonly SwitchableUser[] = [];
  #tutors: readonly SwitchableUser[] = [];

  readonly icons = Icons;
  readonly switchUserIndex = SwitchUserIndex;

  currentTabIndex = SwitchUserIndex.Student;

  set filter(value: string) {
    if (this.#filter !== value) {
      if (value !== '') {
        const filter = value.toLowerCase();
        this.#students = this.#switchable.students.filter(s => s.name.toLowerCase().includes(filter));
        this.#tutors = this.#switchable.tutors.filter(s => s.name.toLowerCase().includes(filter));
      }
      else {
        this.#students = this.#switchable.students;
        this.#tutors = this.#switchable.tutors;
      }
      this.#filter = value;
    }
  }

  get filter() {
    return this.#filter;
  }

  get students() {
    return this.#students;
  }

  get tutors() {
    return this.#tutors;
  }

  @ViewChild('idleWarning') idleWarning: TemplateRef<any> | undefined;

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  trackBy(_index: number, user: SwitchableUser) {
    return user.id;
  }

  /* eslint-disable kip/no-unused-public-members */

  open() {
    if (!this.#open) {
      this.#modalService.open(this.idleWarning, { size: 'lg', centered: true, modalDialogClass: 'kip-modal-themed theme-aurora bg-none' });
      this.#open = true;
      this.#subscriptions.push(
        this.#profileService.getSwitchable().subscribe(value => {
          this.#switchable = value;
          this.#students = value.students;
          this.#tutors = value.tutors;
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  /* eslint-enable kip/no-unused-public-members */

  startActing(userId: number) {
    this.#subscriptions.push(
      this.#profileService.startActing(userId).subscribe(() => {
        this.close();
        window.location.reload();
      }));
  }

  close() {
    this.#modalService.dismissAll();
    this.#open = false;
  }

}
