import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IconCheeseComponent } from './cheese.component';
import { IconsListComponent } from './icons-list/icons-list.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [
    IconCheeseComponent,
    IconsListComponent
  ],
  exports: [
    FontAwesomeModule,
    IconCheeseComponent,
    IconsListComponent
  ]
})
export class IconLibModule { }
