import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IdleService, IdleStatus } from '../../shared';
import { SelectedSession } from '../models';
import { fromSession, TutorState } from '../store';
import { MessagingService } from './messaging.service';

@Injectable({ providedIn: 'root' })
export class TutorIdleService extends IdleService<number, number> {

  readonly #store = inject(Store<TutorState>);
  readonly #messagingService = inject(MessagingService);

  performLogout(showMessage: boolean, logoutMessage: string) {
    this.#store.dispatch(fromSession.sessionDisconnectAction({
      logoutUser: true,
      showLogoutMessage: showMessage,
      logoutMessage: logoutMessage
    }));
  }

  updateIdleStatus(id: number, idleStatus: IdleStatus) {
    this.#store.dispatch(
      fromSession.updateIdleStatusAction({
        sessionId: id,
        idleStatus: idleStatus
      })
    );
  }

  updateMessage(id: number) {
    this.#messagingService.autoLoggedOutOnIdle(id);
  }

  updateSession(session: SelectedSession | undefined) {
    let checkIdle = false;
    // reset idle time if session changes
    if (session) {
      this.dateTimeOffsetIanaTimeZoneName = session.dateTimeOffsetIanaTimeZoneName;
      this.duration = session.duration;
      if (this.id !== session.sessionId) {
        this.id = session.sessionId;
        this.messageId = session.sessionId;
        this.resetIdle(session.isAssessment || session.isDropIn);
        checkIdle = true;
      }
    }

    if (checkIdle) {
      this.checkIdleTime();
    }
  }

}
