import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { IconDefinition } from 'icon-lib';

export interface Tab<T> {
  index: T;
  title: string;
  icon?: IconDefinition;
  visible: boolean;
}

@Component({
  selector: 'kip-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent<T> {

  readonly tabIndex = model.required<T>();
  readonly tabClass = input.required<string>();
  readonly tabSheetClass = input<string>('kip-tab-sheet__tab');
  readonly iconClass = input<string>('d-none d-lg-inline me-2');
  readonly tabs = input.required<Tab<T>[]>();
  readonly shadowTight = input(false);

}
