<form [formGroup]="assessmentForm" (ngSubmit)="submit()">
  <h2 class="fw-light mb-2">{{ action }} Assessment</h2>
  <kip-assessment-result [age]="dateOfBirth | ageObject : assessmentDate" [assessmentResults]="assessmentResults"
    [comprehensionTest]="comprehensionTest" [grade]="gradeId" [lastAssessmentResults]="lastAssessmentResults"
    [readingTest]="readingTest" (statusChange)="onStatusChange()" />
  @if (assessmentForm | formHasError : atLeastOneResultError) {
  <div class="kip-form-group form-group-invalid">
    <span class="form-control-invalid">There must be at least one assessment result entered.</span>
  </div>
  }
  <div class="mt-3">
    <button class="btn btn-primary me-2" type="submit" [disabled]="!assessmentForm.valid">{{ buttonText
      }}</button>

    @if (initialLessonPlanResult) {
    <div class="d-inline-block me-2" ngbDropdown placement="top-right">
      <button class="btn btn-outline-primary" id="initial-lesson-plan-dropdown" ngbDropdownToggle type="button">
        Show Initial Lesson Plan
      </button>
      <div aria-labelledby="initial-lesson-plan-dropdown" ngbDropdownMenu>
        @if (initialLessonPlanResult.toLesson.length === 0 && initialLessonPlanResult.toHomework.length === 0) {
        <div class="text-overline" ngbDropdownItem>
          No lesson plan for lesson or homework
        </div>
        }
        @if (initialLessonPlanResult.toLesson.length > 0) {
        <div class="text-overline" ngbDropdownItem>Lesson ({{
          initialLessonPlanResult.toLesson.length }})</div>
        }
        @for (activity of initialLessonPlanResult.toLesson; track activity) {
        <div ngbDropdownItem>
          <fa-icon [fixedWidth]="true"
            [icon]="!activity.isManual? icons.activityType.computer: icons.activityType.manual" />
          {{ activity.name }}
          @if (activity.description) {
          <span class="text-muted"> - {{ activity.description }} </span>
          }
        </div>
        }
        @if (initialLessonPlanResult.toHomework.length > 0) {
        <div class="text-overline" ngbDropdownItem>Homework ({{
          initialLessonPlanResult.toHomework.length }})</div>
        }
        @for (activity of initialLessonPlanResult.toHomework; track activity) {
        <div ngbDropdownItem>
          <fa-icon [fixedWidth]="true"
            [icon]="!activity.isManual?icons.activityType.computer: icons.activityType.manual" />
          {{ activity.name }}
          @if (activity.description) {
          <span class="text-muted"> - {{ activity.description }} </span>
          }
        </div>
        }
      </div>
    </div>
    }
    <button class="btn btn-link" type="button" (click)="cancel()">Cancel</button>
    {{ initialLessonPlanWarning }}
  </div>
</form>