<kip-form-group-wrapper [formGroup]="contactForm" [showCancel]="true" [submitText]="buttonText"
  (cancelled)="cancelled.emit()" (submittedAndValidated)="submit()">
  <h2 class="fw-light mb-2">{{ action }} Contact</h2>
  <kip-form-control-text id="givenName" label="Given Name" [control]="givenName" />
  <kip-form-control-text id="familyName" label="Family Name" [control]="familyName" />
  <kip-form-control-relationship id="relationship" label="Relationship to Student" [control]="relationshipId" />
  <kip-form-control-text id="email" label="Email" type="email" [control]="email" />
  <kip-form-control-text id="phone" label="Phone" type="tel" [control]="phone" />
  <kip-form-control-contact-type [isEmergency]="isEmergency" [isPrimary]="isPrimary" />
  <kip-address-picker-optional [available]="hasAddress" [parentForm]="contact" />
</kip-form-group-wrapper>