import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-diy-set-2-4',
  templateUrl: './diy-set2-4.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconDiySet24Component implements IconComponent {

}
