
export function openTokStyleOverrides(tutorPhotoUrl: string, students: { studentId: number, studentPhotoUrl: string }[]) {
  // hack to override the background image for tok-box video when no video publishing

  let cssHack = '.OT_audio-only .OT_video-poster {display:block !important}';
  cssHack += '\n .open-tok-observer { width: 250px !important; height: 165px !important; }';

  if (tutorPhotoUrl) {
    cssHack += `\n.open-tok-tutor .OT_video-poster {background-image: url(${tutorPhotoUrl}) !important; background-size: auto 100% !important;  }`;
  }
  for (const student of students) {
    if (student.studentPhotoUrl) {
      cssHack += `\n.open-tok-student-${student.studentId} .OT_video-poster {background-image: url(${student.studentPhotoUrl}) !important; background-size: auto 100% !important;  }`;
    }
  }

  const cssElem = document.querySelector('#open-tok-style-overrides');
  if (cssElem && cssElem.innerHTML !== cssHack) {
    cssElem.innerHTML = cssHack;
  }
}
