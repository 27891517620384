import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { OrganisationGoalNode, SelectedGoal } from '../models';
import { OrganisationGoalService } from '../services';

@Component({
  selector: 'kip-organisation-goal-tree',
  templateUrl: './organisation-goal-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationGoalTreeComponent implements OnInit, OnDestroy {

  readonly #organisationGoalService = inject(OrganisationGoalService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  organisationGoalNodes: OrganisationGoalNode[] = [];

  @Output() readonly selected = new EventEmitter<SelectedGoal>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#organisationGoalService.getOrganisationGoalTree().subscribe(value => {
        this.organisationGoalNodes = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }
}
