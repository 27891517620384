import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { TermsAndConditionTemplate } from '../../models';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { SharedService } from '../../services';

@Component({
  selector: 'kip-terms-and-conditions-picker',
  templateUrl: './terms-and-conditions-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsAndConditionsPickerComponent implements OnInit, OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  title = '';
  html = '';
  termsAndConditionsTemplates: TermsAndConditionTemplate[] = [];

  @Input({ required: true }) termsAndConditionsTemplateId: FormControl<number | null | undefined> | undefined;

  @Input({ required: true }) label = '';
  @Input({ required: true }) name = '';
  @Input() allowNone = false;
  @Input() labelClass: string | undefined;

  @ViewChild('ok', { static: true }) ok: OkDialogComponent | undefined;

  @Output() readonly termsAndConditionsTemplateChange = new EventEmitter<number>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#sharedService.getTermsAndConditionsTemplates()
        .subscribe(termsAndConditionsTemplates => {
          this.termsAndConditionsTemplates = this.allowNone ? [{ id: 0, name: 'None', html: '' }, ...termsAndConditionsTemplates] : termsAndConditionsTemplates;
          this.#changeDetectorRef.markForCheck();
        }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  display() {
    const termsAndConditionsTemplate = this.termsAndConditionsTemplates.find(s => s.id === this.termsAndConditionsTemplateId?.value);

    if (termsAndConditionsTemplate) {
      this.title = termsAndConditionsTemplate.name;
      this.html = termsAndConditionsTemplate.html;
      this.ok?.open('lg');
    }
  }
}
