/* eslint-disable spellcheck/spell-checker */
// these have been serialized to database so don't want to fix spelling
export enum WhiteboardEventType {
  Added = 'added',
  Updated = 'updated',
  Removed = 'removed',
  Cleared = 'cleared',
  PartiallyAdded = 'partiallyadded',
  PartiallyUpdated = 'partiallyupdated'
}
