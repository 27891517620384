import { fabric } from 'fabric';
import { Icons } from 'icon-lib';
import { Observable, Subscription } from 'rxjs';

import { protractor180, protractor360 } from '../../special-svg';
import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardGraphOptions } from '../whiteboard-graph-options';
import { WhiteboardTool } from '../whiteboard-tool';
import { WhiteboardToolOption } from '../whiteboard-tool-option';
import { WhiteboardGraphTool } from './whiteboard-graph-tool';

enum WhiteboardShapeName {
  Circle = 'circle',
  Rectangle = 'rectangle',
  Triangle = 'triangle',
  Line = 'line',
  DashedLine = 'dashed-line',
  DottedLine = 'dotted-line',
  LineArrow = 'line-arrow',
  LineArrowBoth = 'line-arrow-both',
  Clock = 'clock',
  Protractor180 = 'protractor180',
  Protractor360 = 'protractor360',
  // eslint-disable-next-line spellcheck/spell-checker
  CartesianGraph = 'cartesiangraph'
}

interface WhiteboardShape {
  obj: fabric.Object;
  update: (startX: number, startY: number, updateX: number, updateY: number) => void;
  complete: ((startX: number, startY: number, updateX: number, updateY: number) => void) | undefined;
}

export class WhiteboardShapeTool extends WhiteboardTool {

  #subscriptions: Subscription[] = [];

  constructor(private readonly singleCreation: () => void,
    private readonly whiteboardGraphTool: WhiteboardGraphTool) {
    super(ToolbarSvg.Shape);

    this.selectedOption = WhiteboardShapeName.Circle;
  }

  override get options(): WhiteboardToolOption[] {
    return [
      {
        name: WhiteboardShapeName.Circle,
        icon: Icons.whiteboardToolbar.circle,
        visible: true
      },
      {
        name: WhiteboardShapeName.Rectangle,
        icon: Icons.whiteboardToolbar.rectangle,
        visible: true
      },
      {
        name: WhiteboardShapeName.Triangle,
        icon: Icons.whiteboardToolbar.triangle,
        visible: true
      },
      {
        name: WhiteboardShapeName.Line,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <line x1="0" y1="5" x2="30" y2="5" stroke="black" />
      </svg>`,
        icon: Icons.whiteboardToolbar.line,
        visible: true
      },
      {
        name: WhiteboardShapeName.DashedLine,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <line x1="0" y1="5" x2="30" y2="5" stroke="black" stroke-dasharray="4" />
      </svg>`,
        icon: Icons.whiteboardToolbar.line,
        visible: true
      },
      {
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <line x1="0" y1="5" x2="30" y2="5" stroke="black" stroke-dasharray="1" />
      </svg>`,
        name: WhiteboardShapeName.DottedLine,
        icon: Icons.whiteboardToolbar.line,
        visible: true
      },
      {
        name: WhiteboardShapeName.LineArrow,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <line x1="25" y1="0" x2="30" y2="5" stroke="black" />
        <line x1="0" y1="5" x2="30" y2="5" stroke="black" />
        <line x1="25" y1="10" x2="30" y2="5" stroke="black" />
      </svg>`,
        icon: Icons.whiteboardToolbar.line,
        visible: true
      },
      {
        name: WhiteboardShapeName.LineArrowBoth,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <line x1="5" y1="0" x2="0" y2="5" stroke="black" />
        <line x1="25" y1="0" x2="30" y2="5" stroke="black" />
        <line x1="0" y1="5" x2="30" y2="5" stroke="black" />
        <line x1="25" y1="10" x2="30" y2="5" stroke="black" />
        <line x1="5" y1="10" x2="0" y2="5" stroke="black" />
      </svg>`,
        icon: Icons.whiteboardToolbar.line,
        visible: true
      },
      {
        name: WhiteboardShapeName.Clock,
        icon: Icons.whiteboardToolbar.clock,
        visible: true
      },
      {
        name: WhiteboardShapeName.Protractor180,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <path d="M 0 15
           A 15 15 0 0 1 30 15
           L 0 15
           Z" /
      </svg>`,
        icon: Icons.whiteboardToolbar.clock,
        visible: true
      },
      {
        name: WhiteboardShapeName.Protractor360,
        svg: `<svg viewBox="0 0 30 10" xmlns="http://www.w3.org/2000/svg" style='width:20px'>
        <circle cx='15' cy='8' r='14'>
      </svg>`,
        icon: Icons.whiteboardToolbar.clock,
        visible: true
      },
      {
        name: WhiteboardShapeName.CartesianGraph,
        // eslint-disable-next-line max-len
        svg: '<svg style=\'width:20px\' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>',
        icon: Icons.whiteboardToolbar.clock,
        visible: false
      }
    ];
  }

  setup(color: WhiteboardColor, canvas: fabric.Canvas) {
    let x = 0;
    let y = 0;
    let shape: WhiteboardShape | undefined;

    this.resetCanvas(canvas, false, false);

    canvas.on('mouse:down', event => {

      // Setup the event state
      const pointer = canvas.getPointer(event.e);
      x = pointer.x;
      y = pointer.y;

      // Setup the shape
      this.#resolveShape(x, y, color, value => {
        shape = value;
        canvas.add(shape.obj);
      });
    });

    canvas.on('mouse:move', event => {
      if (shape) {
        const pointer = canvas.getPointer(event.e);
        shape.update(x, y, pointer.x, pointer.y);

        canvas.renderAll();
      }
    });

    canvas.on('mouse:up', event => {

      // Update the shape config for processing by the canvas
      // Need to explicitly set selectable to avoid shape dragging when drawing a new one
      if (shape) {
        canvas.remove(shape.obj);
        if (shape.complete) {
          const pointer = canvas.getPointer(event.e);
          shape.complete(x, y, pointer.x, pointer.y);
        }
        shape.obj.selectable = false;
        shape.obj.data = {
          shape: true
        };
        canvas.add(shape.obj);
      }

      // Ensure the shape is updated on the canvas
      canvas.renderAll();

      if (this.selectedOption === WhiteboardShapeName.Protractor180 ||
        this.selectedOption === WhiteboardShapeName.Protractor360) {
        this.singleCreation();
      }

      shape = undefined;
    });

    const graphOptionsSubmitted$ = this.#createFormSubmittedSubscription();
    this.#subscriptions.push(graphOptionsSubmitted$.subscribe(graphOptions => {
      const graphShape = this.#createCartesianGraph(graphOptions.xMax, graphOptions.yMax, graphOptions.xMin, graphOptions.yMin);
      this.#addGraphToCanvas(graphShape, canvas);
    }));
  }

  teardown(canvas: fabric.Canvas) {
    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');

    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #resolveShape(x: number, y: number, color: WhiteboardColor, resolve: (shape: WhiteboardShape) => void) {
    switch (this.selectedOption) {
      case WhiteboardShapeName.Circle:
        resolve(this.#createCircleShape(x, y, color));
        break;
      case WhiteboardShapeName.Rectangle:
        resolve(this.#createRectangleShape(x, y, color));
        break;
      case WhiteboardShapeName.Triangle:
        resolve(this.#createTriangleShape(x, y, color));
        break;
      case WhiteboardShapeName.Line:
        resolve(this.#createLine(x, y, color));
        break;
      case WhiteboardShapeName.DashedLine:
        resolve(this.#createLine(x, y, color, [7, 7]));
        break;
      case WhiteboardShapeName.DottedLine:
        resolve(this.#createLine(x, y, color, [2, 2]));
        break;
      case WhiteboardShapeName.LineArrow:
        resolve(this.#createArrowLine(x, y, color));
        break;
      case WhiteboardShapeName.LineArrowBoth:
        resolve(this.#createDoubleArrowLine(x, y, color));
        break;
      case WhiteboardShapeName.Clock:
        resolve(this.#createClockShape(x, y, color));
        break;
      case WhiteboardShapeName.Protractor180:
        this.#createSvg(protractor180, x, y, 670, 350, resolve);
        break;
      case WhiteboardShapeName.Protractor360:
        this.#createSvg(protractor360, x, y, 635, 635, resolve);
        break;
      case WhiteboardShapeName.CartesianGraph:
        this.#openGraphFormModal();
        break;
      default:
        throw new Error(`Whiteboard shape '${this.selectedOption}' is not supported.`);
    }
  }

  /*-----------------------------------------------------*/
  // These methods are temporary whilst the graph tool
  // is being left in with the shape tools. This can all be
  // removed once the WhiteboardGraphTool is separated
  // out into it's own toolbar collection to allow for
  // multiple graphing types
  #openGraphFormModal() {
    this.whiteboardGraphTool.openGraphFormModal();
  }

  #createFormSubmittedSubscription(): Observable<WhiteboardGraphOptions> {
    return this.whiteboardGraphTool.createGraphFormSubmittedObservable();
  }

  #createCartesianGraph(xMax: number, yMax: number, xMin: number, yMin: number) {
    return this.whiteboardGraphTool.createGraph(xMax, yMax, xMin, yMin);
  }

  #addGraphToCanvas(graph: WhiteboardShape, canvas: fabric.Canvas) {
    this.whiteboardGraphTool.addShapeToCanvas(graph, canvas);
  }
  /*-----------------------------------------------------*/

  #drawWedges(left: number, top: number, radius: number, color: WhiteboardColor, pieSlices: number, emptySlices: number) {
    const wedges: fabric.Path[] = [];

    // calculate center
    const cx = left + radius;
    const cy = top + radius;

    // calculate radians per wedge
    const radians = Math.PI * 2 / pieSlices;

    let lastX = cx;
    let lastY = top;

    for (let i = 0; i < pieSlices; i++) {
      // calculate next point on circle
      const x = cx + Math.sin(radians * (i + 1)) * -radius;
      const y = cy + Math.cos(radians * (i + 1)) * -radius;

      // move to center, line to last point, arc to next point, close path
      // arc: (rx ry x-axis-rotation large-arc-flag sweep-flag x y)
      const p = `M${cx},${cy} L${lastX},${lastY} A${radius},${radius} 0 0 0 ${x},${y} z`;

      const path = new fabric.Path(p, {
        stroke: color.definition,
        strokeWidth: 2,
        fill: i < emptySlices ? 'transparent' : 'red'
      });
      wedges.push(path);

      lastX = x;
      lastY = y;
    }

    return wedges;
  }

  #createClockShape(_x: number, _y: number, color: WhiteboardColor): WhiteboardShape {
    const groupObject = new fabric.Group([]);

    return {
      obj: groupObject,
      update: (startX, startY, updateX, updateY) => {

        const radius = Math.sqrt(Math.pow(Math.abs(startX - updateX), 2) + Math.pow(Math.abs(startY - updateY), 2));

        const objects: fabric.Object[] = [];

        objects.push(...this.#drawWedges(startX - radius, startY - radius, radius, color, 12, 12));

        const radiusNew = radius * 0.9;
        objects.push(new fabric.Circle({
          left: startX - radiusNew,
          top: startY - radiusNew,
          radius: radiusNew,
          originX: 'left',
          originY: 'top',
          stroke: color.definition,
          strokeWidth: 2,
          fill: 'white'
        }));

        const oldObjects = groupObject.getObjects();

        for (const object of oldObjects) {
          groupObject.remove(object);
        }

        for (const object of objects) {
          groupObject.addWithUpdate(object);
        }
      },
      complete: undefined
    };
  }

  #createSvg(svgString: string, x: number, y: number, width: number, height: number, resolve: (shape: WhiteboardShape) => void) {
    fabric.loadSVGFromString(svgString, (objects, options) => {

      const groupObject = fabric.util.groupSVGElements(objects, options) as fabric.Group;
      groupObject.set({
        left: x,
        top: y,
        width: width,
        height: height
      });
      groupObject.scale(0.6);
      groupObject.add(...objects);

      resolve({
        obj: groupObject,
        update: (_startX, _startY, _updateX, _updateY) => {
          // do nothing
        },
        complete: undefined
      });
    });
  }

  #createCircleShape(x: number, y: number, color: WhiteboardColor): WhiteboardShape {
    const circle = new fabric.Circle({
      originX: 'center',
      originY: 'center',
      left: x,
      top: y,
      radius: 1,
      stroke: color.definition,
      strokeWidth: 2,
      fill: 'transparent'
    });

    return {
      obj: circle,
      update: (startX, startY, updateX, updateY) => {
        circle.set({
          radius: Math.sqrt(Math.pow(Math.abs(startX - updateX), 2) + Math.pow(Math.abs(startY - updateY), 2))
        });
      },
      complete: undefined
    };
  }

  #createLine(x: number, y: number, color: WhiteboardColor, strokeDashArray?: number[]  ): WhiteboardShape {
    const line = new fabric.Line([x, y, x, y], {
      originX: 'left',
      originY: 'top',
      width: 1,
      height: 1,
      stroke: color.definition,
      strokeWidth: 2,
      strokeDashArray: strokeDashArray,
      fill: 'transparent'
    });

    return {
      obj: line,
      update: (startX, startY, updateX, updateY) => {
        line.set({
          x1: startX,
          y1: startY,
          x2: updateX,
          y2: updateY
        });
      },
      complete: undefined
    };

  }

  #calculatePointsSingleArrow(fromX: number, fromY: number, toXInitial: number, toYInitial: number) {
    const angle = Math.atan2(toYInitial - fromY, toXInitial - fromX);

    const headLength = 10;  // arrow head size

    // bring the line end back some to account for arrow head.
    const toX = toXInitial - headLength * Math.cos(angle);
    const toY = toYInitial - headLength * Math.sin(angle);

    return [
      {
        x: fromX,  // start mid point of line
        y: fromY
      }, { // outer end of line
        x: fromX - headLength * Math.cos(angle - Math.PI / 2) / 4,
        y: fromY - headLength * Math.sin(angle - Math.PI / 2) / 4
      }, { // to inner arrow head
        x: toX - headLength * Math.cos(angle - Math.PI / 2) / 4,
        y: toY - headLength * Math.sin(angle - Math.PI / 2) / 4
      }, { // to outer arrow head
        x: toX - headLength * Math.cos(angle - Math.PI / 2),
        y: toY - headLength * Math.sin(angle - Math.PI / 2)
      }, { // to arrow point
        x: toX + headLength * Math.cos(angle),  // tip
        y: toY + headLength * Math.sin(angle)
      }, { // to outer arrow head
        x: toX - headLength * Math.cos(angle + Math.PI / 2),
        y: toY - headLength * Math.sin(angle + Math.PI / 2)
      }, { // to inner arrow head
        x: toX - headLength * Math.cos(angle + Math.PI / 2) / 4,
        y: toY - headLength * Math.sin(angle + Math.PI / 2) / 4
      }, { // outer end of line
        x: fromX - headLength * Math.cos(angle + Math.PI / 2) / 4,
        y: fromY - headLength * Math.sin(angle + Math.PI / 2) / 4
      }, { // back to mid point of line
        x: fromX,
        y: fromY
      }
    ];
  }

  #calculatePointsDoubleArrow(fromX: number, fromY: number, toXInitial: number, toYInitial: number) {
    const angle = Math.atan2(toYInitial - fromY, toXInitial - fromX);

    const headLength = 10;  // arrow head size

    // bring the line end back some to account for arrow head.
    const toX = toXInitial - headLength * Math.cos(angle);
    const toY = toYInitial - headLength * Math.sin(angle);

    return [
      {
        x: fromX,  // start mid point of line
        y: fromY
      },
      { // to outer arrow head
        x: fromX - headLength * Math.cos(angle - Math.PI / 2) + headLength * Math.cos(angle),
        y: fromY - headLength * Math.sin(angle - Math.PI / 2) + headLength * Math.sin(angle)
      },
      { // to inner arrow head
        x: fromX - headLength * Math.cos(angle - Math.PI / 2) / 4 + headLength * Math.cos(angle),
        y: fromY - headLength * Math.sin(angle - Math.PI / 2) / 4 + headLength * Math.sin(angle)
      }, { // to inner arrow head
        x: toX - headLength * Math.cos(angle - Math.PI / 2) / 4,
        y: toY - headLength * Math.sin(angle - Math.PI / 2) / 4
      }, { // to outer arrow head
        x: toX - headLength * Math.cos(angle - Math.PI / 2),
        y: toY - headLength * Math.sin(angle - Math.PI / 2)
      }, { // to arrow point
        x: toX + headLength * Math.cos(angle),  // tip
        y: toY + headLength * Math.sin(angle)
      }, { // to outer arrow head
        x: toX - headLength * Math.cos(angle + Math.PI / 2),
        y: toY - headLength * Math.sin(angle + Math.PI / 2)
      }, { // to inner arrow head
        x: toX - headLength * Math.cos(angle + Math.PI / 2) / 4,
        y: toY - headLength * Math.sin(angle + Math.PI / 2) / 4
      },
      { // to inner arrow head
        x: fromX + headLength * Math.cos(angle - Math.PI / 2) / 4 + headLength * Math.cos(angle),
        y: fromY + headLength * Math.sin(angle - Math.PI / 2) / 4 + headLength * Math.sin(angle)
      },
      { // to outer arrow head
        x: fromX + headLength * Math.cos(angle - Math.PI / 2) + headLength * Math.cos(angle),
        y: fromY + headLength * Math.sin(angle - Math.PI / 2) + headLength * Math.sin(angle)
      }, {
        x: fromX,  // start mid point of line
        y: fromY
      },
      { // to outer arrow head
        x: fromX - headLength * Math.cos(angle - Math.PI / 2) + headLength * Math.cos(angle),
        y: fromY - headLength * Math.sin(angle - Math.PI / 2) + headLength * Math.sin(angle)
      }
    ];
  }

  #createDoubleArrowLine(x: number, y: number, color: WhiteboardColor): WhiteboardShape {

    const polyLine = new fabric.Polyline([{ x: x, y: y }], {
      originX: 'left',
      originY: 'top',
      objectCaching: false,
      stroke: color.definition,
      strokeWidth: 1,
      fill: 'transparent'
    });

    return {
      obj: polyLine,
      update: (startX, startY, updateX, updateY) => {
        const polyLineNew = new fabric.Polyline(this.#calculatePointsDoubleArrow(startX, startY, updateX, updateY));
        polyLine.set({
          points: polyLineNew.points,
          dirty: true
        });
      },
      complete: (startX, startY, updateX, updateY) => {
        if (updateX < startX) {
          polyLine.left = updateX;
        }
        if (updateY < startY) {
          polyLine.top = updateY;
        }
        const offsetX = startX - (startX - updateX) / 2;
        const offsetY = startY - (startY - updateY) / 2;
        polyLine.width = Math.abs(startX - updateX);
        polyLine.height = Math.abs(startY - updateY);
        polyLine.pathOffset = new fabric.Point(offsetX, offsetY);
      }
    };
  }

  #createArrowLine(x: number, y: number, color: WhiteboardColor): WhiteboardShape {

    const polyLine = new fabric.Polyline([{ x: x, y: y }], {
      originX: 'left',
      originY: 'top',
      objectCaching: false,
      stroke: color.definition,
      strokeWidth: 1,
      fill: 'transparent'
    });

    return {
      obj: polyLine,
      update: (startX, startY, updateX, updateY) => {
        const polyLineNew = new fabric.Polyline(this.#calculatePointsSingleArrow(startX, startY, updateX, updateY));
        polyLine.set({
          points: polyLineNew.points,
          dirty: true
        });
      },
      complete: (startX, startY, updateX, updateY) => {
        if (updateX < startX) {
          polyLine.left = updateX;
        }
        if (updateY < startY) {
          polyLine.top = updateY;
        }
        const offsetX = startX - (startX - updateX) / 2;
        const offsetY = startY - (startY - updateY) / 2;
        polyLine.width = Math.abs(startX - updateX);
        polyLine.height = Math.abs(startY - updateY);
        polyLine.pathOffset = new fabric.Point(offsetX, offsetY);
      }
    };
  }

  #createRectangleShape(x: number, y: number, color: WhiteboardColor): WhiteboardShape {
    const rectangle = new fabric.Rect({
      originX: 'left',
      originY: 'top',
      left: x,
      top: y,
      width: 1,
      height: 1,
      stroke: color.definition,
      strokeWidth: 2,
      fill: 'transparent'
    });

    return {
      obj: rectangle,
      update: (startX, startY, updateX, updateY) => {
        if (startX > updateX) {
          rectangle.set({
            left: Math.abs(updateX)
          });
        }

        if (startY > updateY) {
          rectangle.set({
            top: Math.abs(updateY)
          });
        }

        rectangle.set({
          width: Math.abs(startX - updateX),
          height: Math.abs(startY - updateY)
        });
      },
      complete: undefined
    };
  }
  #createTriangleShape(x: number, y: number, color: WhiteboardColor): WhiteboardShape {
    const triangle = new fabric.Triangle({
      originX: 'left',
      originY: 'top',
      left: x,
      top: y,
      width: 1,
      height: 1,
      stroke: color.definition,
      strokeWidth: 2,
      fill: 'transparent'
    });

    return {
      obj: triangle,
      update: (startX, startY, updateX, updateY) => {
        if (startX > updateX) {
          triangle.set({
            left: Math.abs(updateX)
          });
        }

        if (startY > updateY) {
          triangle.set({
            top: Math.abs(updateY)
          });
        }

        triangle.set({
          width: Math.abs(startX - updateX),
          height: Math.abs(startY - updateY)
        });
      },
      complete: undefined
    };
  }
}
