import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-check-correct',
  templateUrl: './check-correct.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCheckCorrectComponent implements IconComponent {

}
