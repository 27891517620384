import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SessionScheduleSummary } from '../../models';

@Component({
  selector: 'kip-session-picker-list',
  templateUrl: './session-picker-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionPickerListComponent {

  @Input({ required: true }) sessionSchedules: readonly SessionScheduleSummary[] = [];
  @Input({ required: true }) selectedSessionSchedule: SessionScheduleSummary | undefined;

  @Output() readonly selectSessionSchedule = new EventEmitter<SessionScheduleSummary>();

}
