<div class="row g-3 kip-student-my-day">
  @if (lesson) {
  <div class="mt-3 position-relative col-12 col-md-6"
    [style.opacity]="isUpcoming(lesson) && displayOpenButtons ? 0.6 : 1">
    <div class="h-100">
      <div
        class="kip-student-my-day__lesson-card kip-myday__lesson_card--lesson-bg d-flex border border-primary rounded-lg h-100"
        [class.cursor-pointer]="lesson.available && isPlanned"
        (click)="lesson.available && isPlanned && openLesson.emit()">
        <div class="w-25 kip-myday__subject-icon--lesson alert-primary h-100 rounded-0">
          <div
            [class]="'align-items-center d-flex h-100 justify-content-center kip-myday__subject-icon kip-myday__subject-icon--' + (lesson.subject.title | lowercase ) + ' text-center px-2 py-1'">
            <span style="font-size: 14px;"
              [innerHtml]="lesson.dateTime.dateTimeOffset | startsInFromOffset : lesson.durationMinutes : lesson.isAssessment : true : true | async"></span>
          </div>
        </div>
        <div class="col d-flex flex-column w-75 ps-3 py-2 justify-content-around">
          <div class="h4 d-inline-block mb-0 me-2">{{ lesson.dateTime | dateFromOffset : 'h:mma' }} <span
              class="small">{{ lesson.dateTime | dateFromOffset : 'z' }}</span>
          </div>
          <div class="w-100">
            <div class="d-flex small mt-1">
              <fa-icon class="me-2 text-primary" size="1x" [fixedWidth]="true"
                [icon]="lesson.subject.id | subjectIcon" />
              {{ lesson.isAssessment ? 'Assessment' : lesson.subject.title }}
            </div>
            <div class="d-flex small mt-1">
              <fa-icon class="me-2 text-primary" size="1x" [fixedWidth]="true" [icon]="icons.lesson" />
              {{ lesson.tutor | name }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center pe-3" style="max-width: 110px;">
          @if (lesson.available && !isPlanned) {
          <div class="small text-center text-muted">Waiting for&nbsp;<br>the
            tutor...</div>
          }
          @if (lesson.available && isPlanned && displayOpenButtons) {
          <div class="btn btn-primary btn-circle d-flex justify-content-center align-items-center border-0 shadow-btn">
            <fa-icon class="text-white" size="xl" [fixedWidth]="true" [icon]="icons.arrows.right.regular" />
          </div>
          }
        </div>
        <div class="d-flex align-items-start">
          @if (additionalActions && additionalActions.length > 1) {
          <div container="body" ngbDropdown placement="bottom-right auto">
            <button class="btn kip-btn btn-outline-muted border-0 rounded-top-0" id="additionalActions"
              ngbDropdownToggle type="button">
              <fa-icon size="sm" [icon]="icons.settings" />
            </button>
            <div aria-labelledby="additionalActions"
              class="kip-dropdown__menu kip-dropdown__menu--top-right dropdown-menu" ngbDropdownMenu>
              @for (action of additionalActions; track action) {
              <a class="kip-dropdown__entry px-3" ngbDropdownItem [disabled]="!action.enabled(lesson)"
                (click)="action.action(lesson)">
                <fa-icon class="me-2" size="sm" [fixedWidth]="true" [icon]="action.icon" /><span>{{ action.title
                  }}</span>
              </a>
              }
            </div>
          </div>
          }

          @if (additionalActions && additionalActions.length === 1) {
            <div class="btn kip-btn btn-outline-muted text-muted border-0 mt-auto btn-sm rounded-top-0" [class.d-none]="!additionalActions[0].enabled(lesson)"
              (click)="additionalActions[0].action(lesson)">
              <fa-icon class="me-1" size="sm" [fixedWidth]="true"
                [icon]="additionalActions[0].icon"/> {{ additionalActions[0].title }}
            </div>
            }

        </div>
      </div>
    </div>
  </div>
  }

  @if (!lesson) {
  <div class="d-none d-md-block mt-3 position-relative col-12 col-md-6">
    <div class="h-100">
      <div
        class="d-flex border border-dashed border-muted text-muted text-center small align-items-center justify-content-center rounded-lg px-3 h-100">
        No upcoming lesson
      </div>
    </div>
  </div>
  }

  @if (!homework) {
  <div class="d-none d-md-block mt-3 position-relative col-12 col-md-6">
    <div class="h-100">
      <div
        class="d-flex border border-dashed border-muted text-muted text-center small align-items-center justify-content-center rounded-lg px-3 h-100">
        No homework for this lesson
      </div>
    </div>
  </div>
  }

  @if (homework) {
  <div class="mt-3 position-relative col-12 col-md-6" (click)="openHomework.emit()">
    <div class="h-100">
      <div class="kip-student-my-day__lesson-card d-flex border border-primary rounded-lg h-100 cursor-pointer"
        style="border-style: dashed !important;">
        <div class="kip-myday__subject-icon--homework bg-gradient-50 m-3 me-0 rounded-lg d-none d-sm-block"
          style="min-width: 70px;">
          <div
            class="kip-myday__subject-icon kip-myday__subject-icon--maths h-100 d-flex justify-content-center align-items-center">
            <fa-icon class="text-primary" size="2xl"
              [icon]="homework.isComplete ? icons.complete.regular : icons.home.regular" />
          </div>
        </div>
        <div class="col d-flex flex-column ps-3 py-3">
          <div class="text-overline text-secondary small mb-1">Homework</div>
          <div class="d-flex small mt-1">
            <fa-icon class="text-primary me-2" size="1x" [fixedWidth]="true"
              [icon]="homework.subject.id | subjectIcon" />
            {{ homework.subject.title }}
          </div>
          <div class="d-flex small mt-1">
            <fa-icon class="text-primary me-2" size="1x" [fixedWidth]="true" [icon]="icons.calendar.main" />
            {{ homework.date | dateFromDateStruct: 'Do MMM' }} - {{ homework.date | homeworkDueInDate : homework.time |
            async }}
          </div>
        </div>
        @if (displayOpenButtons) {
        <div
          class="btn btn-circle kip-homework-entry-btn d-flex justify-content-center align-items-center border-0 shadow-btn me-3 my-auto">
          <fa-icon class="z-index-20" size="lg" [fixedWidth]="true" [icon]="icons.arrows.right.regular" />
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>