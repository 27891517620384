@if (label()) {
<label [class]="labelClass()??''" [for]="name()">{{ label() }}</label>
}
<select class="form-control" [formControl]="centreId()" [id]="name()"
  (change)="centreChange.emit(centreId().value ?? 0)">
  @for (centre of centres(); track centre) {
  <option [ngValue]="centre.id">
    {{centre.title }}
  </option>
  }
</select>