import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { GeneralNote } from '../../../models';

@Component({
  selector: 'kip-rankings-filter',
  templateUrl: './rankings-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingsFilterComponent {

  readonly icons = Icons;

  get isVisible() {
    return this.studentNotes.some(sn =>
      sn.lessonNote?.internalSessionNote?.note.ranking ??
      sn.lessonNote?.internalLessonNote?.note.ranking ??
      sn.lessonNote?.startLessonNote?.note.ranking ??
      sn.lessonNote?.finishLessonNote?.note.ranking
    );
  }

  @Input({ required: true }) ranking: number | null = null;
  @Input({ required: true }) studentNotes: GeneralNote[] = [];

  @Output() readonly rankingChange = new EventEmitter<number | null>();

  changeRanking(value: number | null) {
    this.ranking = value;
    this.rankingChange.emit(value);
  }

}
