<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Day</th>
      <th scope="col">Time</th>
      <th scope="col">Date</th>
      <th scope="col">Tutor</th>
      <th scope="col">Centre</th>
      <th scope="col">Status</th>
    </tr>
  </thead>
  <tbody>
    @for (enrolmentSession of enrolmentSessions; track enrolmentSession; let index = $index) {
    <tr [ngClass]="{'cursor-pointer' : canUpdateStatus(enrolmentSession)}" (click)="change(enrolmentSession)">
      <td>
        <div class="custom-control custom-checkbox form-check" [hidden]="listOnly">
          <input class="form-check-input" type="checkbox" [checked]="enrolmentSession.checked"
            [disabled]="!canUpdateStatus(enrolmentSession)" [id]="index" (change)="toggle($event)">
          <label class="custom-control-label" [for]="index"></label>
        </div>
      </td>
      <td>
        {{enrolmentSession.date | weekday }}
      </td>
      <td>
        {{enrolmentSession.time | timeOfDay }}
      </td>
      <td>
        {{enrolmentSession.date | day }}
      </td>
      <td>
        {{enrolmentSession.tutor | name }}
      </td>
      <td>
        {{enrolmentSession.centre.title }}
      </td>
      <td>
        {{enrolmentSession.displayStatus}}
        @if (enrolmentSession.lessonType === lessonType.HomeMakeUp) {
        <span class="kip-badge kip-badge--primary me-1 text-uppercase">DIY</span>
        }
      </td>
    </tr>
    }
  </tbody>
</table>