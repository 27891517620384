import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

export enum Feature {
  DropIn = 'drop-in'
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  isFeatureEnabled(featureName: Feature): Observable<boolean> {
    return featureName === Feature.DropIn ? of(environment.dropInEnabled) : of(true);
  }
}
