import { QuestionSpeed } from './question-speed';

export enum QuestionSettingsKey {
  Speed = 0,
  Replayable = 1
}

export interface QuestionSettings {
  speed: QuestionSpeed;
  replayable?: boolean;
}
