@if (!editing) {
@if (!studentContacts) {
<div class="d-flex justify-content-center py-5">
  <kip-spinner />
</div>
}
@if (studentContacts) {
<section class="kip-tab">
  <div class="kip-tab__article-list">
    @for (studentContact of studentContacts; track studentContact) {
    <div class="kip-tab__article">
      <kip-contact-card [canEdit]="canEdit" [contact]="studentContact.contact"
        [relationship]="studentContact.relationship" (edit)="edit(studentContact)" />
    </div>
    }
    @if (canEdit) {
    <div class="kip-tab__article">
      <button class="kip-contact kip-contact--add" type="button" (click)="add()">
        <fa-icon class="mb-3" size="3x" [icon]="icons.add.regular" />
        <div class="kip-contact__text--add">Add Contact</div>
      </button>
    </div>
    }
  </div>
</section>
}
} @else {
@if (studentDetail) {
<div class="kip-tab kip-tab--is-edit">
  <kip-student-contact-edit class="col-12 col-lg-12 col-xl-6" [studentContact]="studentContact"
    [studentId]="studentDetail.id" (cancelled)="reset()" (submitted)="init()" />
</div>
}
}