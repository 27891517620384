import { Pipe, PipeTransform } from '@angular/core';

import { PercentageResultPipe } from './percentage-result.pipe';

@Pipe({
  name: 'percentageResultTextClass'
})
export class PercentageResultTextClassPipe implements PipeTransform {

  transform(percentage: number | null | undefined) {
    return PercentageResultPipe.prototype.transform(percentage, 'text-success', 'text-warning', 'text-danger');
  }
}
