import { fabric } from 'fabric';

import { WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardAddMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly obj: fabric.Object;

  constructor(canvas: fabric.Canvas, obj: fabric.Object) {
    super();

    this.canvas = canvas;
    this.obj = obj;
  }

  apply() {
    this.invoke(
      () => this.canvas.add(this.obj),
      this.obj);
  }

  revert() {
    this.invoke(
      () => this.canvas.remove(this.obj),
      this.obj);
  }
}
