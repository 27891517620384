import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { OrganisationGoalNode, SelectedGoal } from '../../models';

@Component({
  selector: 'kip-organisation-goal-node',
  templateUrl: './organisation-goal-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationGoalNodeComponent {

  readonly icons = Icons;

  set expand(expand: boolean) {
    if (this.organisationGoalNode) {
      this.organisationGoalNode.expanded = expand;
    }
  }

  get expand() {
    if (this.organisationGoalNode) {
      return this.organisationGoalNode.expanded === undefined ? false : this.organisationGoalNode.expanded;
    }
    return false;
  }

  get subNodes() {
    return this.organisationGoalNode?.hasChildren ? this.organisationGoalNode.children : [];
  }

  @Input({ required: true }) organisationGoalNode: OrganisationGoalNode | undefined;
  @Output() readonly selected = new EventEmitter<SelectedGoal>();

  selectNode(node: OrganisationGoalNode, ev: Event) {
    this.selected.emit({ id: node.id, name: node.name, description: node.description });
    ev.stopPropagation();
  }

}
