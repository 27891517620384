import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';

interface Notification {
  title: string;
  body: string;
}
import { Subscription } from 'rxjs';

import { NotificationsService } from '../services';

@Component({
  selector: 'kip-send-notification',
  templateUrl: './send-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendNotificationComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #modalService = inject(NgbModal);
  readonly #notificationsService = inject(NotificationsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #result = '';
  #userIds: number[] | undefined;

  readonly icons = Icons;

  get result() {
    return this.#result;
  }

  get title() {
    return this.notificationForm.getSafe(s => s.title);
  }

  get body() {
    return this.notificationForm.getSafe(s => s.body);
  }

  notificationForm!: FormGroupTypeSafe<Notification>;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set userIds(userIds: number[] | undefined) {
    if (userIds !== this.#userIds) {
      this.#userIds = userIds;
      if (userIds) {
        this.#changeDetectorRef.markForCheck();
        setTimeout(() => {
          this.#modalService.open(this.modalContent, { size: 'lg', centered: true });
          this.#changeDetectorRef.markForCheck();
        }, 100);
      } else {
        this.close();
        this.#changeDetectorRef.markForCheck();
      }
    }
  }

  get userIds() {
    return this.#userIds;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

  ngOnInit() {
    /*eslint-disable @typescript-eslint/unbound-method */
    this.notificationForm = this.#fb.group<Notification>({
      title: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(200)]),
      body: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(1000)])
    });
    /*eslint-enable @typescript-eslint/unbound-method */

    this.#changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (this.userIds) {
      this.#subscriptions.push(
        this.#notificationsService.sendMessage(this.userIds, this.notificationForm.value.title,
          this.notificationForm.value.body).subscribe(
            {
              next: value => {
                this.#result = `${value} messages sent`;
                this.#changeDetectorRef.markForCheck();
              },
              error: (error: unknown) => {
                this.#result = '';
                this.notificationForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              }
            }));
    }
  }

  close() {
    this.#userIds = undefined;
    this.#result = '';
    this.title.setValue('');
    this.body.setValue('');
    this.#modalService.dismissAll();
  }
}
