import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-empty',
  templateUrl: './monty-empty.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyEmptyComponent implements MontyGraphic { }
