<kip-loading-state [loadingState]="loadingState" (cancel)="cancel()" (retry)="retry()" />
@if (!trading && !editing) {
<div class="kip-tab">
  <div class="d-flex justify-content-between">
    <h2 class="kip-tab__header">Student's Awards</h2>
    @if (canEdit) {
    <div>
      <button class="btn btn-outline-primary btn-letter-spacing me-2" type="button" (click)="trade()">Trade
        Points</button>
      <button class="btn btn-outline-primary btn-letter-spacing" type="button" (click)="add()">Give Award</button>
    </div>
    }
  </div>
  <kip-student-awards-list [studentId]="studentId" />
</div>
}
@if (editing) {
<div class="kip-tab">
  <kip-student-awards-add [lessonId]="lessonId" [studentId]="studentId" (cancel)="cancelled()"
    (chatAlert)="chatAlert.emit($event)" />
</div>
}
@if (trading) {
<div class="kip-tab">
  <kip-student-awards-trade [studentId]="studentId" (cancel)="cancelled()" (chatAlert)="chatAlert.emit($event)" />
</div>
}