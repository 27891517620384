<div class="table-responsive">
  <table class="kip-table table table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th></th>
        <th scope="col">
          Day
        </th>
        <th scope="col">
          Time
        </th>
        <th scope="col">
          Tutor
        </th>
        <th scope="col">
          Centre
        </th>
        <th scope="col">Students</th>
      </tr>
    </thead>
    <tbody>
      @for (sessionSchedule of sessionSchedules; track sessionSchedule) {
      <tr class="cursor-pointer" (click)="selectSessionSchedule.emit(sessionSchedule)">
        <td class="kip-table__cell kip-table__cell--choice">
          <input type="radio" [checked]=" selectedSessionSchedule && selectedSessionSchedule.id === sessionSchedule.id"
            (click)="selectSessionSchedule.emit(sessionSchedule)">
        </td>
        <td>{{ sessionSchedule.weekday }}</td>
        <td>{{ sessionSchedule.startTime | timeOfDay }}</td>
        <td>{{ sessionSchedule.tutor | name }}</td>
        <td>{{ sessionSchedule.centre.title }}</td>
        <td>{{ sessionSchedule.enrolments }}</td>
      </tr>
      }
    </tbody>
  </table>
</div>