export enum RegistrationResultStatus {
  Succeeded = 0,
  UserNameAlreadyTaken = 1,
  ContactNotFound = 2,
  UserAlreadyExists = 3,
  PasswordInvalid = 4,
  UnexpectedFailure = 5
}

export interface AccountContactRegistrationResponse {
  readonly status: RegistrationResultStatus;
}
