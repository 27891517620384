import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-learning-maths-male-d',
  templateUrl: './learning-maths-male-d.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconLearningMathsMaleDComponent implements IconComponent {

}
