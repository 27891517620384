/* eslint  @typescript-eslint/naming-convention : 0 */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Severity } from 'message-lib';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { Alert, AlertActionName, AlertSource, SessionActivity, SessionLesson } from '../../models';
import * as fromActivity from '../activity';
import * as fromAlerts from '../alerts';
import * as fromLesson from '../lesson';
import { TutorState } from '../state';
import * as fromState from '../state';

const clearActivityAlerts = (lesson: SessionLesson, activity: SessionActivity, store: Store<TutorState>) => {
  store.dispatch(fromAlerts.removeMatchingAlertsAction(
    {
      shouldBeRemoved:
        alert => alert.lessonId === lesson.lessonId && alert.source === AlertSource.ActivityStatus && alert.message.includes(`'${activity.name}'`)
    })
  );
};

@Injectable()
export class ActivityEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<TutorState>);

  timeAlerted$: Observable<void> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.timeAlertedAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSessionLessons),
        this.#store.select(fromState.selectTutorSettings)
      ),
      map(([action, lessons, tutorSettings]) => {
        const lesson = lessons.find(l => l && l.lessonGuid === action.lessonGuid);

        if (lesson) {
          const activity = lesson.activities.find(a => a && a.activityGuid === action.activityGuid);
          if (activity) {
            clearActivityAlerts(lesson, activity, this.#store as Store<TutorState>);
            if (action.isWarning && tutorSettings?.showActivityWarningTimeAlerts) {
              if (action.minutesRemaining) {
                this.#store.dispatch(fromAlerts.addAlertAction(
                  {
                    alert:
                      new Alert(Severity.Info,
                        `${lesson.studentUniqueName} has only ${action.minutesRemaining} minutes remaining on activity '${activity.name}'.`,
                        new Date(), AlertSource.ActivityStatus, lesson.lessonId, false)
                  }));
              } else {
                if (action.helpRequested) {
                  this.#store.dispatch(fromAlerts.addAlertAction(
                    {
                      alert:
                        new Alert(Severity.Warning,
                          `${lesson.studentUniqueName} is taking longer than anticipated on activity '${activity.name}' and ` +
                          `has requested help. Switch to <action name="${AlertActionName.Teach}">Teach mode</action> to investigate`,
                          new Date(), AlertSource.ActivityStatus, lesson.lessonId, false)
                    }));
                } else {
                  this.#store.dispatch(fromAlerts.addAlertAction(
                    {
                      alert:
                        new Alert(Severity.Info,
                          `${lesson.studentUniqueName} is taking longer than anticipated on activity '${activity.name}'.`,
                          new Date(), AlertSource.ActivityStatus, lesson.lessonId, false)
                    }));
                }
              }
            } else if (!action.isWarning && tutorSettings?.showActivityMaxAllowedTimeAlerts) {
              this.#store.dispatch(fromAlerts.addAlertAction(
                {
                  alert:
                    new Alert(Severity.Danger,
                      `${lesson.studentUniqueName} has run out of time on activity '${activity.name}' and it was closed automatically.`,
                      new Date(), AlertSource.ActivityStatus, lesson.lessonId, false)
                }));
            }
          }
        }
      })
    );
  },
    { dispatch: false }
  );
}
