<label [class]="labelClass??''">{{ label }} @if (optional) {
  <span class="ms-2 small text-uppercase">Optional</span>
  }</label>
@for (group of grouped; track group; let iGroup = $index) {
<div class="mb-2">
  <label>{{ group.group }}</label>
  @for (multipleValue of group.children; track multipleValue; let i = $index) {
  <div class="custom-control custom-checkbox kip-choice">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" [disabled]="disabled"
        [id]="name + '_' + iGroup + '_' + multipleValue.id" [value]="multipleValue.id"
        [(ngModel)]="multipleValue.checked" (change)="onCheckChange()">
      <label class="custom-control-label form-check-label form-check-label--append"
        [for]="name + '_' + iGroup + '_' + multipleValue.id">
        <div>{{ multipleValue.name }}</div>
        @if (multipleValue.subGroups)
        {
        @for (subGroup of multipleValue.subGroups; track subGroup; let last = $last) {
        <span class="small">{{ subGroup + (!last ? ' |': '') }}</span>
        }
        }
      </label>
    </div>
  </div>
  }
</div>
}