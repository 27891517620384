<div class="kip-form-group">
  @if (label) {
  <label [for]="name">{{label}}</label>
  }
  <div class="input-group">
    <fa-icon class="input-group-text" [icon]="icons.address" />
    <input #search class="form-control" name="address" placeholder="Start typing address..." type="text"
      [formControl]="inputAddress">
    <button class="btn  btn-outline-secondary" id="button-addon2" type="button" (click)="toggleExpandedAddress()">
      <fa-icon [icon]="icons.chevrons.down" />
    </button>
  </div>
</div>
<div [hidden]="!expandAddress">
  @if (addressNotFound) {
  <span class="text-warning">Address not found. Please use below fields to provide full
    address.</span>
  }
  <div class="row">
    <div class="kip-form-group col-12">
      <label>Address Line 1</label>
      <input class="form-control" type="text" [formControl]="addressLine1">
    </div>
    <div class="kip-form-group col-12">
      <label>Address Line 2</label>
      <input class="form-control" type="text" [formControl]="addressLine2">
    </div>
    <div class="kip-form-group col-md-6 col-12">
      <label>City</label>
      <input class="form-control" type="text" [formControl]="dependentLocality">
    </div>
    <div class="kip-form-group col-md-6 col-12">
      <label>State</label>
      <input class="form-control" type="text" [formControl]="locality">
    </div>
    <div class="kip-form-group col-md-6 col-12">
      <label>Postcode</label>
      <input class="form-control" type="text" [formControl]="postalCode">
    </div>
    <div class="kip-form-group col-md-6 col-12">
      <label>Country</label>
      <input class="form-control" type="text" [formControl]="country">
    </div>
  </div>
</div>