import { FilterValue } from './filter-value';

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

function convertDayOfWeek(status: DayOfWeek) {
  const value = DayOfWeek[status];
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return '';
}

function createIdTitle(status: DayOfWeek) {
  return {
    id: status,
    title: convertDayOfWeek(status)
  };
}

export const daysOfWeek: FilterValue<DayOfWeek>[] = [
  createIdTitle(DayOfWeek.Sunday),
  createIdTitle(DayOfWeek.Monday),
  createIdTitle(DayOfWeek.Tuesday),
  createIdTitle(DayOfWeek.Wednesday),
  createIdTitle(DayOfWeek.Thursday),
  createIdTitle(DayOfWeek.Friday),
  createIdTitle(DayOfWeek.Saturday)
];
