import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeOfDay'
})
export class TimeOfDayPipe implements PipeTransform {

  transform(value: string): string {
    const time = moment(value, 'HH:mm:ss');
    return time.format('h:mm a');
  }
}
