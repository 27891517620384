import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { ActivityNote, ActivityNoteUpdate, AIResponse, LessonNote, LessonNoteUpdate, SessionNote, SessionNoteUpdate } from '../models';

@Injectable({ providedIn: 'root' })
export class NoteService extends HttpService {

  getAIResponse(lessonId: number, aiModelId: number, lessonFocus: string): Observable<AIResponse> {
    return this.post<AIResponse>(`ai/lesson-summary/${lessonId}`, { aiModelId: aiModelId, lessonFocus: lessonFocus });
  }

  updateActivityNote(activityNoteUpdate: ActivityNoteUpdate): Observable<ActivityNote> {
    return this.post<ActivityNote>('tutor/lesson-plan/update-activity-note', activityNoteUpdate);
  }

  updateLessonNote(lessonNoteUpdate: LessonNoteUpdate): Observable<LessonNote> {
    return this.post<LessonNote>('tutor/lesson-plan/update-lesson-note', lessonNoteUpdate);
  }

  updateSessionNote(sessionNoteUpdate: SessionNoteUpdate): Observable<SessionNote> {
    return this.post<SessionNote>('tutor/lesson-plan/update-session-note', sessionNoteUpdate);
  }
}
