
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { ToolbarSvg, WhiteboardIcon } from '../models';
import { WhiteboardIconClearComponent } from './clear.component';
import { WhiteboardIconEraserComponent } from './eraser.component';
import { WhiteboardIconGraphComponent } from './graph.component';
import { WhiteboardIconGridComponent } from './grid.component';
import { WhiteboardIconHighlighterComponent } from './highlighter.component';
import { WhiteboardIconLassoComponent } from './lasso.component';
import { WhiteboardIconPenComponent } from './pen.component';
import { WhiteboardIconPrintComponent } from './print.component';
import { WhiteboardIconRedoComponent } from './redo.component';
import { WhiteboardIconShapeComponent } from './shape.component';
import { WhiteboardIconTextComponent } from './text.component';
import { WhiteboardIconUndoComponent } from './undo.component';

@Component({
  selector: 'kip-whiteboard-icon',
  templateUrl: './whiteboard-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardIconComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #toolbarSvg: ToolbarSvg = ToolbarSvg.Clear;
  #selected = false;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set toolbarSvg(value: ToolbarSvg) {
    if (this.#toolbarSvg !== value) {
      this.#toolbarSvg = value;
      this.#rebuildIcon();
    }
  }

  get toolbarSvg() {
    return this.#toolbarSvg;
  }

  @Input({ required: true }) set selected(value: boolean) {
    if (this.#selected !== value) {
      this.#selected = value;
      this.#rebuildIcon();
    }
  }

  get selected() {
    return this.#selected;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef | undefined;

  ngOnInit() {
    this.#rebuildIcon();
  }

  #rebuildIcon() {
    if (this.content) {
      this.content.clear();
      let componentRef: ComponentRef<WhiteboardIcon>;
      switch (this.toolbarSvg) {
        case ToolbarSvg.Clear:
          componentRef = this.content.createComponent(WhiteboardIconClearComponent);
          break;
        case ToolbarSvg.Eraser:
          componentRef = this.content.createComponent(WhiteboardIconEraserComponent);
          break;
        case ToolbarSvg.Graph:
          componentRef = this.content.createComponent(WhiteboardIconGraphComponent);
          break;
        case ToolbarSvg.Grid:
          componentRef = this.content.createComponent(WhiteboardIconGridComponent);
          break;
        case ToolbarSvg.Highlighter:
          componentRef = this.content.createComponent(WhiteboardIconHighlighterComponent);
          break;
        case ToolbarSvg.Lasso:
          componentRef = this.content.createComponent(WhiteboardIconLassoComponent);
          break;
        case ToolbarSvg.Pen:
          componentRef = this.content.createComponent(WhiteboardIconPenComponent);
          break;
        case ToolbarSvg.Print:
          componentRef = this.content.createComponent(WhiteboardIconPrintComponent);
          break;
        case ToolbarSvg.Redo:
          componentRef = this.content.createComponent(WhiteboardIconRedoComponent);
          break;
        case ToolbarSvg.Shape:
          componentRef = this.content.createComponent(WhiteboardIconShapeComponent);
          break;
        case ToolbarSvg.Text:
          componentRef = this.content.createComponent(WhiteboardIconTextComponent);
          break;
        case ToolbarSvg.Undo:
          componentRef = this.content.createComponent(WhiteboardIconUndoComponent);
          break;
      }
      componentRef.instance.selected = this.#selected;
      this.#changeDetectorRef.markForCheck();
    }
  }

}
