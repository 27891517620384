@if (errors(); as errors)
{
<span class="form-control-invalid">
  @if (showIcon() && errors.length === 1 && errors[0]) {
  <fa-icon class="text-danger d-inline-block me-2" [icon]="icons.circleExclamation" />
  }
  @if (errors.length === 1) {
  <span>{{ errors[0] }} </span>
  }
  @if (errors.length > 1) {
  <ul>
    @for (error of errors; track error) {
    <li>
      @if (showIcon() && error) {
      <fa-icon class="text-danger d-inline-block me-2" [icon]="icons.circleExclamation" />
      }
      {{error}}
    </li>
    }
  </ul>
  }
</span>
}