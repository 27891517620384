import { Injectable } from '@angular/core';
import * as uuid from 'uuid';

@Injectable({ providedIn: 'root' })
export class CustomActivityService {
  
  getCustomActivityPageGuid(whiteboardActivityGuid: string, pageNumber: number) {
    const guid = whiteboardActivityGuid.slice(0, Math.max(0, whiteboardActivityGuid.length - 2)) + pageNumber.toString().padStart(2, '0');
    if (!uuid.validate(guid)) {
      throw new Error(`Created invalid guid '${guid}'. Seed data was '${whiteboardActivityGuid}' and '${pageNumber}'.`);
    }
    return guid;
  }

}
