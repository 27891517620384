import * as moment from 'moment';
import { unitOfTime } from 'moment';
import * as momentRange from 'moment-range';

import { DateStruct, DateTimeOffsetIanaTimeZoneName } from './models';

declare module 'moment' {
  export interface Moment {
    toDateStruct: () => DateStruct;
    toMoment: (date: DateStruct, time?: string) => Moment;
    toMomentTimezone: (ianaTimeZoneName: string, date: DateStruct, time?: string) => Moment;
    offsetBeforeStartOfDay: (dateTime: DateTimeOffsetIanaTimeZoneName) => boolean;
    offsetInPast: (dateTime: DateTimeOffsetIanaTimeZoneName) => boolean;
    fromOffsetToTime: (dateTime: DateTimeOffsetIanaTimeZoneName) => string;
    offsetsEqual: (dateTime: DateTimeOffsetIanaTimeZoneName, dateTime2: DateTimeOffsetIanaTimeZoneName) => boolean;
    fromOffsetToDateStruct: (dateTime: DateTimeOffsetIanaTimeZoneName) => DateStruct;
    overlap: (dateTime: DateTimeOffsetIanaTimeZoneName, duration: number,
      dateTime2: DateTimeOffsetIanaTimeZoneName, duration2: number) => boolean;
    toStartOfDay: () => Moment;
    toEndOfDay: () => Moment;
    toStartOfWeek: () => Moment;
    toEndOfWeek: () => Moment;
    time: (time: string) => Moment;
    toTime: () => string;
    getDiffFromCurrentTime: (timeUnit: unitOfTime.Diff) => number;
  }
}

(moment.fn as any).time = function (time: string): moment.Moment {
  const _self = this as moment.Moment;
  const momentTime = moment(time ?? '00:00:00', 'HH:mm:ss');
  _self.hour(momentTime.get('hour'));
  _self.minute(momentTime.get('minute'));
  _self.second(momentTime.get('second'));
  _self.millisecond(0);
  return _self;
};

(moment.fn as any).toDateStruct = function (): DateStruct {
  const _self = this as moment.Moment;
  return {
    year: _self.year(),
    month: _self.month() + 1,
    day: _self.date()
  };
};

(moment.fn as any).toMoment = (date: DateStruct, time?: string): moment.Moment => {
  const momentTime = moment(time ?? '00:00:00', 'HH:mm:ss');
  return moment(
    {
      year: date.year,
      month: date.month - 1,
      day: date.day,
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute'),
      seconds: momentTime.get('second')
    });
};

(moment.fn as any).toMomentTimezone = (ianaTimeZoneName: string, date: DateStruct, time?: string): moment.Moment => {
  const momentTime = moment.tz(time ?? '00:00:00', 'HH:mm:ss', ianaTimeZoneName);
  return moment.tz([
    date.year,
    date.month - 1,
    date.day,
    momentTime.get('hour'),
    momentTime.get('minute'),
    momentTime.get('second')
  ],
    ianaTimeZoneName);
};

(moment.fn as any).offsetBeforeStartOfDay = function (dateTime: DateTimeOffsetIanaTimeZoneName): boolean {
  const today = moment().startOf('day');
  return moment(dateTime.dateTimeOffset).diff(today) < 0;
};

(moment.fn as any).offsetsEqual = function (dateTime: DateTimeOffsetIanaTimeZoneName, dateTime2: DateTimeOffsetIanaTimeZoneName): boolean {
  return moment(dateTime.dateTimeOffset).diff(moment(dateTime2.dateTimeOffset)) === 0;
};

(moment.fn as any).offsetInPast = function (dateTime: DateTimeOffsetIanaTimeZoneName): boolean {
  const today = moment();
  return moment(dateTime.dateTimeOffset).diff(today) < 0;
};

(moment.fn as any).fromOffsetToDateStruct = function (dateTime: DateTimeOffsetIanaTimeZoneName): DateStruct {
  return moment(dateTime.dateTimeOffset).toDateStruct();
};

(moment.fn as any).overlap = function (dateTime: DateTimeOffsetIanaTimeZoneName, duration: number,
  dateTime2: DateTimeOffsetIanaTimeZoneName, duration2: number): boolean {
  const dateTimeStart = moment(dateTime.dateTimeOffset).toDate();
  const dateTimeFinish = moment(dateTime.dateTimeOffset).add(duration, 'minute').toDate();

  const dateTimeStart2 = moment(dateTime2.dateTimeOffset).toDate();
  const dateTimeFinish2 = moment(dateTime2.dateTimeOffset).add(duration2, 'minute').toDate();

  const range1 = new momentRange.DateRange(dateTimeStart, dateTimeFinish);
  const range2 = new momentRange.DateRange(dateTimeStart2, dateTimeFinish2);
  return range1.overlaps(range2);
};

(moment.fn as any).fromOffsetToTime = function (dateTime: DateTimeOffsetIanaTimeZoneName): string {
  return moment(dateTime.dateTimeOffset).format('HH:mm:ss');
};

(moment.fn as any).toTime = function (): string {
  const _self = this as moment.Moment;
  return _self.format('HH:mm:ss');
};

(moment.fn as any).toStartOfDay = function (): moment.Moment {
  const _self = this as moment.Moment;
  return _self.startOf('day');
};

(moment.fn as any).toEndOfDay = function (): moment.Moment {
  const _self = this as moment.Moment;
  return _self.endOf('day');
};

(moment.fn as any).toStartOfWeek = function (): moment.Moment {
  const _self = this as moment.Moment;
  return _self.startOf('week');
};

(moment.fn as any).toEndOfWeek = function (): moment.Moment {
  const _self = this as moment.Moment;
  return _self.endOf('week');
};

(moment.fn as any).getDiffFromCurrentTime = function (timeUnit: unitOfTime.Diff): number {
  const _self = this as moment.Moment;
  return moment().diff(_self, timeUnit);
};
