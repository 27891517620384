<div class="kip-form-group" [ngClass]="{'form-group-invalid' : stripeElement | isFormControlInvalid }">
  <label for="card-info">Card Information</label>
  <div class="kip-stripe">
    <div #cardInfo id="card-info"></div>
  </div>
  <span class="form-control-invalid">
    {{stripeElement.getError(cardValidationError) || 'Please provide valid card details.' }}
  </span>
</div>
<kip-form-control-text id="givenName" label="Name on Card" [control]="nameOnCard" />
<div class="kip-form-group" [ngClass]="{'form-group-invalid' : billingAddress | isFormControlInvalid }">
  <div class="kip-choice">
    <div class="form-check">
      <input class="form-check-input" id="useAlternateBillingAddress" name="useAlternateBillingAddress" type="checkbox"
        [checked]="useAlternateBillingAddress" (change)="changeUseAlternateBillingAddress()">
      <label class="form-check-label form-check-label--append" for="useAlternateBillingAddress">
        Use Alternate Billing Address
      </label>
    </div>
  </div>
  @if (useAlternateBillingAddress) {
  <div class="kip-form-group">
    <kip-address-picker name="alternate-billing-address" [addressForm]="billingAddress" />
  </div>
  }
  <kip-control-error clientSideErrorMessage="Please provide a valid account address or billing address"
    [control]="billingAddress" />
</div>
@if (!submitExternal) {
<div class="kip-form-group">
  @if (!creditCardForm.hasError(cardSetupError) ) {
  <button class="btn btn-lg w-100 btn-outline-primary btn-letter-spacing" type="button"
    (click)="validatePaymentDetails()">Validate Payment
    Details</button>
  } @else {
  <div class="alert alert-danger w-100 d-flex flex-row" role="alert">
    <div class="fw-bold me-2">
      <fa-icon [icon]="icons.alert.solid" />
    </div>
    <div>
      <b>Failed!</b>
      {{ creditCardForm.getError(cardSetupError) || 'Can not initialize payment gateway.' }}
    </div>
  </div>
  }
</div>
}