import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OpentokService } from 'open-tok-lib';
import { Observable, tap } from 'rxjs';

import { TutorState } from '..';
import * as fromDevice from '../device';

@Injectable()
export class DeviceEffects {

  readonly #actions$ = inject(Actions);
  readonly #opentokService = inject(OpentokService);
  readonly #store = inject(Store<TutorState>);

  videoSet$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromDevice.setVideoDeviceAction),
      tap(action => {
        this.#opentokService.setCurrentVideo(action.deviceId);
      }));
  },
    { dispatch: false }
  );

  audioSet$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromDevice.setAudioDeviceAction),
      tap(action => {
        this.#opentokService.setCurrentAudio(action.deviceId);
      }));
  },
    { dispatch: false }
  );

  constructor() {
    this.#opentokService.getCurrentDevices().then(currentDevices => {
      this.#store.dispatch(fromDevice.loadDevicesAction(
        {
          videoDeviceId: currentDevices.video?.deviceId,
          audioDeviceId: currentDevices.audio?.deviceId
        }));
    });
  }
}
