import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Gender } from '../../models';
import { genders } from './gender';

@Component({
  selector: 'kip-gender-picker',
  templateUrl: './gender-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenderPickerComponent {

  readonly genders = genders;
  readonly gender = input.required<FormControl<Gender | null>>();
  readonly optional = input(false);
  readonly label = input.required<string>();
  readonly name = input.required<string>();
  readonly labelClass = input<string | undefined>(undefined);

}
