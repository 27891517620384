import { LearningRange } from '../models';
import { getLearningRangeFromPercentage } from './get-learning-range-from-percentage';

export function getBackgroundColorFromPercentage(percentage: number | undefined) {

  if (percentage === undefined) {
    return '';
  }

  const learningRange = getLearningRangeFromPercentage(percentage);

  switch (learningRange) {
    case LearningRange.Perfect:
      return '#E7FBE7';
    case LearningRange.Excellent:
      return '#E7FBE7';
    case LearningRange.Pass:
      return '#FFF1D6';
    case LearningRange.NeedsImprovement:
      return '#FEDBDB';
    default:
      return '';
  }
}
