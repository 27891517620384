export enum Monty {
  Body = 1,
  Empty = 2,
  Error = 3,
  New = 4,
  SlowRequest = 5,
  Head = 6,
  Loading = 7,
  Construction = 8,
  Holiday = 9,
  OnlineBody = 10,
  Success = 11,
  SuccessBody = 12
}
