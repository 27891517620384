import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-online-body',
  templateUrl: './monty-online-body.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyOnlineBodyComponent implements MontyGraphic { }
