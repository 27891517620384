/* eslint-disable @angular-eslint/no-forward-ref */

import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DeliveryMethodType } from '../../models';
import { BaseMultiplePickerComponent } from '../base-multiple-picker/base-multiple-picker.component';

@Component({
  selector: 'kip-delivery-method-multiple-picker',
  templateUrl: '../base-multiple-picker/base-multiple-picker.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DeliveryMethodMultiplePickerComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryMethodMultiplePickerComponent extends BaseMultiplePickerComponent implements OnInit {

  ngOnInit() {
    this.multipleValues = [
      { id: DeliveryMethodType.InCentre, name: 'In Centre', checked: false },
      { id: DeliveryMethodType.Online, name: 'Online', checked: false }];
  }

}
