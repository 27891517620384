<div class="kip-multiple-choice-layout">
  <kip-question-text [question]="question" />
  <div class="row">
    @for (option of options; track option) {
    <div [class]="'kip-option col-' + optionsWidth">
      <div class="custom-control" [class.custom-checkbox]="!singleAnswer" [class.custom-radio]="singleAnswer">
        <input class="kip-input kip-bring-to-front custom-control-input"
          [attr.checked]="optionChecked(option) ? 'checked' : undefined" [attr.data-keyboard]="keyboardType.Radio"
          [attr.disabled]="readonly ? 'disabled' : undefined" [attr.id]="option.key" [attr.name]="optionsName"
          [attr.type]="optionsType" (change)="updateAnswer($event, option)" />
        <label class="kip-label kip-bring-to-front custom-control-label" [attr.for]="option.key"
          [ngClass]="option.valid | answerValidation: 'valid-choice' : 'invalid-choice'">
          <kip-question-option [behaviour]="behaviour" [option]="option" />
        </label>
      </div>
    </div>
    }
  </div>
</div>