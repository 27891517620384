<form [class]="class" [formGroup]="formGroup" (ngSubmit)="submit()">
  <ng-content />
  <kip-form-error [formGroup]="formGroup" />
  @if (submitted && !formGroup.valid) {
  <div class="alert alert-danger">
    <span>{{invalidText}}</span>
  </div>
  }
  <div class="d-flex py-3">
    @if (submitVisible)
    {
    <button type="submit" [class]="saveDate?saveClass:submitClass" [class.w-100]="submitFullWidth"
      [disabled]="submitDisabled">

      @if (saveDate)
      {
      <fa-icon class="me-2" [icon]="icons.check" />
      Changes saved
      }
      @else {
      {{submitText}}
      }</button>
    }
    <ng-content select="[buttons]" />
    @if (showCancel)
    {
    @if (showCancel === true)
    {
    <button type="button" [class]="cancelClass" (click)="cancelled.emit()">Cancel</button>
    }
    @else
    {
    <a buttons [class]="cancelClass" [routerLink]="showCancel">Cancel</a>
    }
    }
  </div>
</form>