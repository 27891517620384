/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfileService, TutorSettings } from 'ui-common-lib';

import { TutorState } from '..';
import { loadTutorSettingsAction } from '../tutor-settings/tutor-settings.actions';

@Injectable()
export class TutorSettingsEffects {

  readonly #profileService = inject(ProfileService);
  readonly #store = inject(Store<TutorState>);

  constructor() {
    this.#profileService.trackUserProfile().subscribe(profile => {
      if (profile) {
        this.#store.dispatch(loadTutorSettingsAction({
          tutorSettings: profile.settings as TutorSettings
        }));
      }
    });
  }
}
