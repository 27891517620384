import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-strong-muscles-a',
  templateUrl: './strong-muscles-a.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconStrongMusclesAComponent implements IconComponent {

}
