import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { convertNumberToAge } from 'pipes-directives-lib';
import { Subscription } from 'rxjs';
import {
  Age, AgeResult, AssessmentResult, AssessmentResultType,
  AssessmentResultTypeEnum, ComprehensionForm, ComprehensionTest, ReadingTest
} from 'ui-common-lib';

@Component({
  selector: 'kip-result',
  templateUrl: './result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly ageError = 'ageError';
  readonly scoreError = 'scoreError';
  readonly scoreGradeError = 'scoreGradeError';
  readonly scoreTotalError = 'scoreTotalError';
  readonly assessmentResultType = AssessmentResultTypeEnum;
  readonly ageResultValues = AgeResult;

  readonly ageResults: { id: number, title: string }[] = [
    { id: AgeResult.Assessed, title: 'Assessed' },
    { id: AgeResult.TooLowToBeAssessed, title: 'Too low to be Assessed' },
    { id: AgeResult.MuchTooLowToBeAssessed, title: 'Much too low to be assessed' }];

  readonly forms: { id: string, title: string }[] = [
    { id: ComprehensionForm.FormA, title: ComprehensionForm.FormA },
    { id: ComprehensionForm.FormB, title: ComprehensionForm.FormB }
  ];

  result: FormGroupTypeSafe<AssessmentResult>;
  expand = false;

  get subjects() {
    return this.resultType.subjects.map(s => s.name).join(', ');
  }

  get resultType(): AssessmentResultType {
    return this.result.getSafe(x => x.type).value;
  }

  get calculatedAge() {
    return { year: this.year.value, month: this.month.value };
  }

  get year() {
    return this.result.getSafe(x => x.age.year);
  }

  get month() {
    return this.result.getSafe(x => x.age.month);
  }

  get ageResult() {
    return this.result.getSafe(x => x.ageResult);
  }

  get comprehensionResult() {
    return this.result.getSafe(x => x.comprehensionResult);
  }

  get comprehensionForm() {
    return this.result.getSafe(x => x.comprehensionForm);
  }

  get gradeId() {
    return this.result.getSafe(x => x.gradeId);
  }

  get score() {
    return this.result.getSafe(x => x.score);
  }

  get words() {
    return this.result.getSafe(x => x.words);
  }

  get total() {
    return this.result.getSafe(x => x.total);
  }

  get entered() {
    return ResultComponent.isEntered(this.result);
  }

  get internalNote() {
    return this.result.getSafe(x => x.internalNote);
  }

  get parentNote() {
    return this.result.getSafe(x => x.parentNote);
  }

  @Input({ required: true }) grade: number | null | undefined;
  @Input({ required: true }) age: Age | undefined;
  @Input({ required: true }) readingTest: readonly ReadingTest[] = [];
  @Input({ required: true }) comprehensionTest: readonly ComprehensionTest[] = [];
  @Input({ required: true }) allowNotes = true;

  @Input({ required: true }) set type(value: AssessmentResultType) {
    this.result.getSafe(x => x.type).setValue(value);
  }

  get type() {
    return this.result.getSafe(x => x.type).value;
  }

  @Output() readonly statusChange = new EventEmitter();

  constructor() {
    this.result = this.#fb.group<AssessmentResult>({
      id: new FormControl<number>(0),
      type: new FormControl<AssessmentResultType | null>(null),
      gradeId: new FormControl<number | null>(null),
      ageResult: new FormControl<number | null>(null),
      age: this.#fb.group<Age>({
        year: new FormControl<number | null>(null, [Validators.min(0), Validators.max(200)]),
        month: new FormControl<number | null>(null, [Validators.min(0), Validators.max(11)])
      }),
      comprehensionResult: new FormControl<number | null>(null, [Validators.min(0), Validators.max(49)]),
      comprehensionForm: new FormControl(null),
      words: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
      score: new FormControl<number | null>(null, [Validators.min(0), Validators.max(10_000)]),
      total: new FormControl<number | null>(null, [Validators.min(1), Validators.max(10_000)]),
      expand: new FormControl<boolean>(false),
      internalNote: new FormControl<string | null>(null),
      parentNote: new FormControl<string | null>(null),
      lessonActivityPlanId: new FormControl<number | null>(null)
    }, {
      validators: [this.#ageRequiredValidator, this.#scoreRequiredValidator, this.#scoreTotalValidator, this.#scoreGradeRequiredValidator]
    });

    this.#subscriptions.push(
      this.result.getSafe(x => x.words).valueChanges.subscribe(() => {
        this.#recalcReading();
      }),
      this.result.getSafe(x => x.comprehensionResult).valueChanges.subscribe(() => {
        this.#recalcComprehension();
      }),
      this.result.getSafe(x => x.comprehensionForm).valueChanges.subscribe(() => {
        this.#recalcComprehension();
      }),
      this.result.statusChanges.subscribe(() => {
        this.statusChange.emit();
        this.#changeDetectorRef.markForCheck();
      }));
  }

  static isAssessmentResultEntered(assessmentResult: AssessmentResult) {
    const ageProvided = assessmentResult.type.ageRequired ?
      assessmentResult.age.year !== null && assessmentResult.age.month !== null
      && assessmentResult.ageResult === AgeResult.Assessed ||
      assessmentResult.ageResult === AgeResult.MuchTooLowToBeAssessed ||
      assessmentResult.ageResult === AgeResult.TooLowToBeAssessed
      : true;
    const gradeProvided = assessmentResult.type.gradeRequired ? assessmentResult.gradeId : true;
    const scoreProvided = assessmentResult.type.scoreRequired ?
      assessmentResult.score !== null && assessmentResult.total !== null
      : true;
    return ageProvided && gradeProvided && scoreProvided;
  }

  static isEntered(formGroup: FormGroupTypeSafe<AssessmentResult>) {
    const resultType = formGroup.getSafe(s => s.type).value;
    const ageResult = formGroup.getSafe(s => s.ageResult).value;
    const year = formGroup.getSafe(s => s.age.year).value;
    const month = formGroup.getSafe(s => s.age.month).value;
    const gradeId = formGroup.getSafe(s => s.gradeId).value;
    const score = formGroup.getSafe(s => s.score).value;
    const total = formGroup.getSafe(s => s.total).value;

    const ageProvided = resultType.ageRequired ? year !== null && month !== null
      && ageResult === AgeResult.Assessed ||
      ageResult === AgeResult.MuchTooLowToBeAssessed ||
      ageResult === AgeResult.TooLowToBeAssessed
      : true;

    // TODO: Figure out what this null check actually does

    const gradeProvided = resultType.gradeRequired ? gradeId && (gradeId as any) !== 'null' : true;
    const scoreProvided = resultType.scoreRequired ? score !== null && total !== null : true;
    return ageProvided && gradeProvided && scoreProvided;
  }

  static loadData(formGroup: FormGroupTypeSafe<AssessmentResult>, data: AssessmentResult) {

    // note - the order these are applied is important

    formGroup.getSafe(s => s.id).setValue(data.id);
    formGroup.getSafe(s => s.comprehensionForm).setValue(data.comprehensionForm);
    formGroup.getSafe(s => s.comprehensionResult).setValue(data.comprehensionResult);
    formGroup.getSafe(s => s.words).setValue(data.words);
    formGroup.getSafe(s => s.score).setValue(data.score);
    formGroup.getSafe(s => s.total).setValue(data.total);
    formGroup.getSafe(s => s.gradeId).setValue(data.gradeId);
    formGroup.getSafe(s => s.ageResult).setValue(data.ageResult);
    formGroup.getSafe(s => s.age.month).setValue(data.age.month);
    formGroup.getSafe(s => s.age.year).setValue(data.age.year);
    formGroup.getSafe(s => s.internalNote).setValue(data.internalNote);
    formGroup.getSafe(s => s.parentNote).setValue(data.parentNote);
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  readonly #ageRequiredValidator: ValidatorFn = () => {
    if (this.result && this.resultType && this.resultType.ageRequired && (this.month.value === null || this.year.value === null) && (this.month.value !== null || this.year.value !== null)) {
      return { [this.ageError]: true };
    }
    return null;
  };

  readonly #scoreRequiredValidator: ValidatorFn = () => {
    if (this.result && this.resultType && this.resultType.scoreRequired && (this.score.value === null || this.total.value === null) && (this.score.value !== null || this.total.value !== null)) {
      return { [this.scoreError]: true };
    }
    return null;
  };

  readonly #scoreGradeRequiredValidator: ValidatorFn = () => {
    if (this.result && this.resultType && this.resultType.scoreRequired && this.resultType.gradeRequired) {

      // TODO: Figure out what this null check actually does

      if ((this.gradeId.value as any) === 'null') {
        this.gradeId.setValue(null);
      }

      if ((this.score.value === null || this.total.value === null || this.gradeId.value === null)
        && (this.score.value !== null || this.total.value !== null || this.gradeId.value !== null)) {
        return { [this.scoreGradeError]: true };
      }
    }
    return null;
  };

  readonly #scoreTotalValidator: ValidatorFn = () => {
    if (this.result && this.resultType && this.resultType.scoreRequired && this.score.value !== null && this.total.value !== null && this.score.value > this.total.value) {
      return { [this.scoreTotalError]: true };
    }
    return null;
  };

  #recalcComprehension() {
    const comprehensionResult = this.comprehensionResult.value;
    const comprehensionForm = this.comprehensionForm.value;
    const comprehensionTest = this.comprehensionTest.find(c => c.score === comprehensionResult && c.form === comprehensionForm);
    if (comprehensionTest) {
      this.result.getSafe(s => s.ageResult).setValue(comprehensionTest.ageResult);
      this.result.getSafe(s => s.age.month).setValue(null);
      this.result.getSafe(s => s.age.year).setValue(null);
      if (comprehensionTest.age) {
        const age = convertNumberToAge(comprehensionTest.age);
        this.result.getSafe(s => s.age.month).setValue(age.month);
        this.result.getSafe(s => s.age.year).setValue(age.year);
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  #recalcReading() {
    const words = this.words.value;
    const readingTest = this.readingTest.find(r => r.wordsMin <= words && words <= r.wordsMax);
    if (readingTest) {
      this.result.getSafe(s => s.ageResult).setValue(readingTest.ageResult);
      this.result.getSafe(s => s.age.month).setValue(null);
      this.result.getSafe(s => s.age.year).setValue(null);
      if (readingTest.age) {
        const age = convertNumberToAge(readingTest.age);
        this.result.getSafe(s => s.age.month).setValue(age.month);
        this.result.getSafe(s => s.age.year).setValue(age.year);
      }
      this.#changeDetectorRef.markForCheck();
    }
  }
}
