<div [class]="'image-uploader-body ' + classes">
  <label class="image-uploader-drop-area" for="imageUploaderFile" kipDropZone [class.pointer-events-none]="isUploading"
    (dropped)="handleFiles($event)">
    <input class="image-uploader-file" id="imageUploaderFile" type="file" [accept]="validFileTypes"
      [disabled]="disabled" [multiple]="multiple" (change)="fileUploadChange($event)">
    <span class="label-title">{{ title }}</span>
  </label>

  <div class="available-file-info">
    File types accepted: {{ validFileTypesText }}
    <br>
    Maximum size {{maxSize}}mb
  </div>

  @if (isFileListActive) {
  <kip-uploaded-file-list [fileList]="files" [hasError]="hasError" [isUploaded]="isUploaded" [isUploading]="isUploading"
    [progress]="progress" (delete)="delete($event)" (edit)="emitFileList()" />
  }

  @if (uploadErrorMessage) {
  <div class="text-danger">
    {{uploadErrorMessage}}
  </div>
  }
</div>