import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-adjustable-header-picker',
  templateUrl: './adjustable-header-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdjustableHeaderPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly adjustableHeaders = toSignal(this.#sharedService.getAdjustableHeaders(), { initialValue: [] });

  readonly adjustableHeaderId = input.required<FormControl<number>>();
  readonly label = input('');
  readonly name = input.required<string>();
  readonly labelClass = input<string | undefined>(undefined);

}
