import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentStrength } from '../../../../models';

@Component({
  selector: 'kip-strength-card',
  templateUrl: './strength-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrengthCardComponent {

  readonly icons = Icons;

  @Input({ required: true })
  studentStrength: StudentStrength | undefined;
}
