import { Icons } from 'icon-lib';

import { Icon } from '../icons';
import { PointsData } from './points-data';
import { PointsTarget } from './points-target';

type PointsLevel = { [key in PointsTarget]: PointsData };

export const pointsLevel: PointsLevel = {
  [PointsTarget.BronzeMedal]: {
    points: 1000, title: 'Bronze Medal', ribbonColor: '#D55BA1', iconColor: '#C88568', icon: Icons.points.medal,
    award: Icon.Medal3rdThird
  },
  [PointsTarget.SilverMedal]: {
    points: 2500, title: 'Silver Medal', ribbonColor: '#37B3A9', iconColor: '#BEC0C2', icon: Icons.points.medal,
    award: Icon.Medal2ndSecond
  },
  [PointsTarget.GoldMedal]: {
    points: 4000, title: 'Gold Medal', ribbonColor: '#D91A5D', iconColor: '#E8BB67', icon: Icons.points.medal,
    award: Icon.Medal1stFirst
  },
  [PointsTarget.BronzeStar]: {
    points: 5500, title: 'Bronze Star', ribbonColor: '#2BA7D5', iconColor: '#C88568', icon: Icons.points.star.solid,
    award: Icon.StarBronze
  },
  [PointsTarget.SilverStar]: {
    points: 7000, title: 'Silver Star', ribbonColor: '#F48020', iconColor: '#BEC0C2', icon: Icons.points.star.solid,
    award: Icon.StarSilver
  },
  [PointsTarget.GoldStar]: {
    points: 8500, title: 'Gold Star', ribbonColor: '#5352A3', iconColor: '#E8BB67', icon: Icons.points.star.solid,
    award: Icon.StarGold
  },
  [PointsTarget.RubyTrophy]: {
    points: 10_000, title: 'Ruby Trophy', ribbonColor: '#924198', iconColor: '#DD4B61', icon: Icons.points.trophy,
    award: Icon.RubyTrophy
  },
  [PointsTarget.PlatinumTrophy]: {
    points: 11_500, title: 'Platinum Trophy', ribbonColor: '#FCC116', iconColor: '#BEC0C2', icon: Icons.points.trophy,
    award: Icon.PlatinumTrophy
  },
  [PointsTarget.DiamondTrophy]: {
    points: 13_000, title: 'Diamond Trophy', ribbonColor: '#B8D335', iconColor: '#0071BC', icon: Icons.points.trophy,
    award: Icon.DiamondTrophy
  }
};
