import { fabric } from 'fabric';

import { TransformExtended, WhiteboardMemento } from '../whiteboard-memento';

export class WhiteboardRotateMemento extends WhiteboardMemento {

  private readonly canvas: fabric.Canvas;
  private readonly obj: fabric.Object;

  private readonly currentTop: number | undefined;
  private readonly currentLeft: number | undefined;
  private readonly currentAngle: number | undefined;

  private readonly previousTop: number;
  private readonly previousLeft: number;
  private readonly previousAngle: number;

  constructor(canvas: fabric.Canvas, obj: fabric.Object, transform: TransformExtended) {
    super();

    this.canvas = canvas;
    this.obj = obj;

    this.currentTop = obj.top;
    this.currentLeft = obj.left;
    this.currentAngle = obj.angle;

    this.previousTop = transform.original.top;
    this.previousLeft = transform.original.left;
    this.previousAngle = transform.theta * 180 / Math.PI;
  }

  apply() {
    this.invoke(
      () => {
        this.obj.top = this.currentTop;
        this.obj.left = this.currentLeft;
        this.obj.angle = this.currentAngle;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:rotated', this.obj, this.canvas);
      },
      this.obj);
  }

  revert() {
    this.invoke(
      () => {
        this.obj.top = this.previousTop;
        this.obj.left = this.previousLeft;
        this.obj.angle = this.previousAngle;
        this.obj.setCoords();

        this.canvas.renderAll();

        this.emitTransformation('object:rotated', this.obj, this.canvas);
      },
      this.obj);
  }
}
