import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';

import { KeyboardType, ValidationResult } from '../../../../models';

export interface AnswerNavigation {
  index: number;
  next: boolean;
  eventKey: string;
}

export interface AnswerBoxEntry {
  correctValue: number | null;
  displayAnswer: number | null;
  displayText: string;
  index: number;
  returnText: string;
  tabIndex?: number;
}

@Component({
  selector: 'kip-drill-answer',
  templateUrl: './answer-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerBoxComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #valid = ValidationResult.NotKnown;

  keyboardType = KeyboardType.NumberRow;

  get valid() {
    return this.#valid;
  }

  get value(): string {
    if (this.answerBoxEntry) {
      if (this.answerBoxEntry.correctValue !== null) {
        return this.element ? this.element.nativeElement.value : '';
      }

      return this.answerBoxEntry.returnText;
    }
    return '';
  }

  @Input({ required: true })
  readonly = false;

  @Input({ required: true })
  answerBoxEntry: AnswerBoxEntry | undefined;

  @ViewChild('element', { static: false }) element: ElementRef<HTMLInputElement> | undefined;

  @Output()
  readonly submit = new EventEmitter();

  @Output()
  readonly change = new EventEmitter();

  @Output()
  readonly keyUpIndex = new EventEmitter<AnswerNavigation>();

  @Output()
  readonly firstKeyUp = new EventEmitter<AnswerBoxEntry>();

  setValue(value: string) {
    if (this.element) {
      this.element.nativeElement.value = value;
    }
  }

  focus() {
    if (this.element) {
      this.element.nativeElement.focus();
      this.element.nativeElement.select();
    }
  }

  onChange() {
    this.#valid = ValidationResult.NotKnown;
  }

  onInput() {
    if (this.answerBoxEntry) {
      this.change.emit();
    }
  }

  setValidationResult() {
    if (this.answerBoxEntry) {
      if (this.answerBoxEntry.correctValue !== null) {
        if (!this.readonly) {
          if (this.value === '') {
            this.#valid = ValidationResult.NotKnown;
          } else {
            this.#valid = this.value === this.answerBoxEntry.correctValue.toString() ? ValidationResult.Correct :
              ValidationResult.Incorrect;
          }
        } else {
          this.#valid = this.answerBoxEntry.displayAnswer === this.answerBoxEntry.correctValue ? ValidationResult.Correct :
            ValidationResult.Incorrect;
        }
      } else {
        this.#valid = ValidationResult.NotKnown;
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  onKeyup(event: KeyboardEvent) {

    // on number key, tab to the next input element

    if (this.answerBoxEntry) {

      const previousActions = ['LeftArrow'];

      if (previousActions.includes(event.key)) {
        this.keyUpIndex.emit({ index: this.answerBoxEntry.index, next: false, eventKey: event.key });
      }

      const nextActions = ['RightArrow', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6',
        'Numpad7', 'Numpad8', 'Numpad9'];

      if (nextActions.includes(event.key)) {
        this.keyUpIndex.emit({ index: this.answerBoxEntry.index, next: false, eventKey: event.key });
      }
    }
  }

  onKeydown(event: KeyboardEvent) {

    // Allow enter

    if (event.key === 'Enter') {
      this.submit.emit();
      event.preventDefault();
      return;
    }

    // Allow tab, backspace, left, right

    const allowedKeyActions = ['Tab', 'Backspace', 'ArrowLeft', 'ArrowRight'];

    if (allowedKeyActions.includes(event.key)) {
      return;
    }

    // Block non numeric

    const allowedNumberActions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6',
      'Numpad7', 'Numpad8', 'Numpad9'];

    if (!allowedNumberActions.includes(event.key)) {
      event.preventDefault();
    }
  }
}
