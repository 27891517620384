import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-expert-addition',
  templateUrl: './expert-addition.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconExpertAdditionComponent implements IconComponent {

}
