import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentNote } from '../../../../models';

@Component({
  selector: 'kip-student-note-pinned',
  templateUrl: './student-note-pinned.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentNotePinnedComponent {

  readonly icons = Icons;

  @Input({ required: true })
  studentNote: StudentNote | undefined;
}
