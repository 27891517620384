@if (studentNote) {
<div class="kip-note-dropdown__entry">
  <div class="kip-note-dropdown__header kip-note-dropdown__header--system">
    @if (studentNote.note.pinned) {
    <fa-icon class="kip-note-dropdown__header-icon text-danger" size="lg" [fixedWidth]="true"
      [icon]="icons.noteCategory.pinned" />
    }
    @if (!studentNote.note.pinned) {
    <fa-icon class="kip-note-dropdown__header-icon" size="lg" [fixedWidth]="true"
      [icon]="studentNote.note.noteCategory.name | noteCategoryIcon" />
    }
    <span class="kip-note-dropdown__headline">
      {{ studentNote.note.noteCategory.name }}
      {{ studentNote.note.pinned ? ' (Pinned)' : ''}}
    </span>
    <span class="small text-nowrap text-dark">
      {{ studentNote.createdOn | dateTime: 'ddd, D MMMM YYYY' }}
    </span>
  </div>
  <div class="w-100">
    <div class="kip-note-dropdown__description">
      <kip-emoji-message [message]="studentNote.note.description" />
    </div>
  </div>
  <div class="kip-note-dropdown__footer">
    <fa-icon class="me-1" [icon]="icons.tutor.solid" />
    {{ studentNote.createdByUser }}
  </div>
</div>
}