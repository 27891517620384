@if (!hasHistory()) {
@if (isLoadingHistory())
{
<div class="d-flex flex-row flex-fill justify-content-center p-5">
    <kip-spinner />
</div>
}
@else {
<kip-no-results [title]="noResultsText()" />
}
}
@else {
<ng-content />

@if (video()) {
<kip-video [video]="video()" (videoChange)="video.set($event)" />
}
}