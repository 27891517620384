export interface Environment {
  readonly api: { endpoint: string };
  readonly openTok?: { apiKey: string };
  readonly stripePublishableApiKey: string;
  readonly tillPaymentsScriptUrl: string;
  readonly slowRequestTime: number;
  readonly buildGuid: string;
}

export class ServiceEnvironment {
  static value: Environment = { api: { endpoint: '' }, openTok: { apiKey: '' }, stripePublishableApiKey: '', tillPaymentsScriptUrl: '', slowRequestTime: 0, buildGuid: '' };
}
