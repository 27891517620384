/* eslint-disable rxjs/no-ignored-subscription */

import { HttpBackend, HttpClient, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, EMPTY, Observable, ObservableInput, of, switchMap } from 'rxjs';

import { HttpService } from './http.service';

export enum ErrorBlobPath {
  Image = 'assets/404/image',
  Avatar = 'avatar/404',
  Pdf = 'assets/404/pdf',
  Sound = 'assets/404/sound'
}

/**
 * This class allows for making Http requests to external URLs
 * Where the base HttpService makes Http requests only to the API host with relative paths and configured interceptors,
 * such as adding auth headers, this class adds the ability to call absolute paths without configured interceptors.
 */
export abstract class HttpWithAbsoluteService extends HttpService {

  readonly #cachedBlobs = new Map<string, string>();

  httpClientWithoutInterceptors = new HttpClient(inject(HttpBackend));

  override getBlob(path: string, query?: HttpParams | { [param: string]: string[] | any }): Observable<HttpResponse<Blob>> {
    return this.#internalOrExternal(path, super.getBlob(path, query), this.getBlobAbsolute(path, query));
  }

  protected getBlobAbsolute(url: string, query?: HttpParams | { [param: string]: string[] | any }): Observable<HttpResponse<Blob>> {
    return this.httpClientWithoutInterceptors.get(url, { responseType: 'blob', observe: 'response', params: query });
  }

  protected getBlobWithProgress(path: string, query?: HttpParams | { [param: string]: string[] | any }): Observable<HttpEvent<Blob>> {
    return this.httpClientWithoutInterceptors.get(path, { responseType: 'blob', observe: 'events', reportProgress: true, params: query });
  }

  protected getSoundCached(file: string) {
    return this.getUrlAndDownloadWithErrorBlobCached(`assets/sound-url/${file}`, ErrorBlobPath.Sound, false, 'assets/sounds/missing-sound-file.mp3');
  }

  protected getUrlAndDownloadWithErrorBlobCached(path: string, errorBlobPath: ErrorBlobPath, invalidPathDownloadErrorBlob = false, blankUrl = '') {
    const promise: Promise<string> = new Promise<string>(resolve => {
      const cachedUrl = this.#cachedBlobs.get(path);
      if (cachedUrl) {
        resolve(cachedUrl);
      } else {
        this.getUrlAndDownloadWithErrorBlob(path, errorBlobPath, invalidPathDownloadErrorBlob).subscribe(
          {
            next: response => {
              const url = response.body ? window.URL.createObjectURL(response.body) : blankUrl;
              this.#cachedBlobs.set(path, url);
              resolve(url);
            },
            error: () => {
              resolve(blankUrl);
            }
          });
      }
    });

    return promise;
  }

  protected getUrlAndDownloadWithErrorBlobProgress(path: string, errorBlobPath: ErrorBlobPath, invalidPathDownloadErrorBlob = false) {
    if (invalidPathDownloadErrorBlob) {
      return this.getBlobWithProgress(errorBlobPath);
    }
    return this.#getUrlAndDownloadWithProgress(path, () => this.getBlobWithProgress(errorBlobPath));
  }

  protected getUrlAndDownloadOptionalWithErrorBlob(path: string, optional: (url: string) => boolean, errorBlobPath: ErrorBlobPath,
    invalidPathDownloadErrorBlob = false): Observable<HttpResponse<Blob> | string> {
    if (invalidPathDownloadErrorBlob) {
      return this.getBlob(errorBlobPath);
    }
    return this.#getUrlAndDownloadOptional(path, optional, () => {
      return this.getBlob(errorBlobPath);
    });
  }

  protected getUrlAndDownloadWithErrorBlob(path: string, errorBlobPath: ErrorBlobPath, invalidPathDownloadErrorBlob = false) {
    if (invalidPathDownloadErrorBlob) {
      return this.getBlob(errorBlobPath);
    }
    return this.#getUrlAndDownload(path, () => this.getBlob(errorBlobPath));
  }

  #getUrlAndDownloadWithProgress(path: string, errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<any>): Observable<HttpEvent<Blob>> {
    const emptyErrorHandler = () => EMPTY;

    return this.get<string>(path)
      .pipe(
        switchMap(url => this.getBlobWithProgress(url)),
        catchError(errorHandler ?? emptyErrorHandler));
  }

  #getUrlAndDownloadOptional(path: string, optional: (url: string) => boolean, errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<any>): Observable<HttpResponse<Blob> | string> {
    const emptyErrorHandler = () => EMPTY;
    return this.get<string>(path)
      .pipe(
        switchMap(url => {
          if (optional(url)) {
            return of(url);
          }
          return this.getBlob(url);
        }),
        catchError(errorHandler ?? emptyErrorHandler));
  }

  #getUrlAndDownload(path: string, errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<any>): Observable<HttpResponse<Blob>> {
    const emptyErrorHandler = () => EMPTY;
    return this.get<string>(path)
      .pipe(
        switchMap(url => this.getBlob(url)),
        catchError(errorHandler ?? emptyErrorHandler));
  }

  /**
   * Attempt to use the right HttpClient (and auth) based on {@link pathOrUrl} being a relative path or a full Url
   * and if that full Url is to the internal API or external
   * @param {string} pathOrUrl The relative path or absolute url of a file
   * @param {Observable<TResponse>} internal The request using the HttpClient set up to access the API server
   * @param {Observable<TResponse>} external The request using the HttpClient set up to access external servers
   * @returns {Observable<TResponse>} Either {@link internal} or {@link external}
   */
  #internalOrExternal<TResponse>(pathOrUrl: string, internal: Observable<TResponse>, external: Observable<TResponse>): Observable<TResponse> {
    if (!pathOrUrl.startsWith('http')) {
      return internal;
    }

    const url = new URL(pathOrUrl);
    return url.origin === this.endpoint ? internal : external;
  }
}
