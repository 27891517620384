import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// Function Validators
export const noWhitespaceValidator = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export const confirmedValidator = (controlName: string, matchingControlName: string): ValidatorFn | ValidatorFn[] | null | undefined => {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmedValidator
    ) {
      return null;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }

    return null;
  };
};
export const REQUIRED_VALIDATOR_FN = Validators.required.bind(null);

// RegExp
export const NUMBER = /^[0-9]+$/;
export const STRING_REQUIRED_VALIDATOR = [REQUIRED_VALIDATOR_FN, noWhitespaceValidator];
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_VALIDATOR = [REQUIRED_VALIDATOR_FN, noWhitespaceValidator, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%*?&])[A-Za-z\d@$!%*?&]{8,}$/).bind(null)];
export const EMAIL_VALIDATOR = [
  REQUIRED_VALIDATOR_FN,
  noWhitespaceValidator,
  Validators.pattern(EMAIL_REGEXP).bind(null)
];
