<div class="science-calculator-positioning">
  <div cdkDrag cdkDragBoundary=".kip-tab-sheet__content" class="science-calculator">
    <div>
      <div class="science-calculator-output" [innerHTML]="result"></div>
    </div>
    @if (!showScienceCalculator) {
    <div class="science-calculator-padding d-flex">
      <span class="button-function" (click)="performAction(calculatorAction.MemoryPlus)">M+</span>
      <span class="button-function" (click)="performAction(calculatorAction.MemoryMinus)">M-</span>
      <span class="button-function" (click)="performAction(calculatorAction.MemoryRecall)">MR</span>
      <span class="button-function" (click)="performAction(calculatorAction.MemoryClear)">MC</span>
      <span class="button-function" (click)="showScienceCalculator = true">
        <fa-icon [fixedWidth]="true" [icon]="icons.angles.down" />
      </span>
    </div>
    }
    <div class="science-calculator-padding" [class.d-none]="!showScienceCalculator">
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.MemoryPlus)">M+</span>
        <span class="button-function" (click)="performAction(calculatorAction.MemoryMinus)">M-</span>
        <span class="button-function" (click)="performAction(calculatorAction.MemoryRecall)">MR</span>
        <span class="button-function" (click)="performAction(calculatorAction.MemoryClear)">MC</span>
        <span class="button-function" (click)="performAction(calculatorAction.Exp)">EXP</span>
      </div>
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.Sin)">sin</span>
        <span class="button-function" (click)="performAction(calculatorAction.Cos)">cos</span>
        <span class="button-function" (click)="performAction(calculatorAction.Tan)">tan</span>
        <span class="button-function button-radian-degree"
          [ngClass]="{selected:degreeRadians === degreeOrRadians.Degree}"
          (click)="degreeRadians = degreeOrRadians.Degree">deg</span>
        <span class="button-function button-radian-degree"
          [ngClass]="{selected:degreeRadians === degreeOrRadians.Radians}"
          (click)="degreeRadians = degreeOrRadians.Radians">rad</span>
      </div>
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.Asin)">sin<sup>-1</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.Acos)">cos<sup>-1</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.Atan)">tan<sup>-1</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.Pi)">&#960;</span>
        <span class="button-function" (click)="performAction(calculatorAction.E)">e</span>
      </div>
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.Pow)">x<sup>y</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.XCubed)">x<sup>3</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.XSquared)">x<sup>2</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.EToX)">e<sup>x</sup></span>
        <span class="button-function" (click)="performAction(calculatorAction.TenToX)">10<sup>x</sup></span>
      </div>
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.APow)"><sup>y</sup>&#8730;x</span>
        <span class="button-function" (click)="performAction(calculatorAction.CubeRoot)"><sup>3</sup>&#8730;x</span>
        <span class="button-function" (click)="performAction(calculatorAction.SquareRoot)">&#8730;x</span>
        <span class="button-function" (click)="performAction(calculatorAction.Ln)">ln</span>
        <span class="button-function" (click)="performAction(calculatorAction.Log)">log</span>
      </div>
      <div>
        <span class="button-function" (click)="performAction(calculatorAction.OpenBracket)">(</span>
        <span class="button-function" (click)="performAction(calculatorAction.CloseBracket)">)</span>
        <span class="button-function" (click)="performAction(calculatorAction.OneOverX)">1/x</span>
        <span class="button-function" (click)="performAction(calculatorAction.Random)">RND</span>
        <span class="button-function" (click)="performAction(calculatorAction.N)">n!</span>
      </div>
    </div>
    <div class="regular-calculator-padding">
      <div>
        <span class="button-number-secondary" (click)="performAction(calculatorAction.Clear)">C</span>
        <span class="button-number-secondary" (click)="performAction(calculatorAction.PlusMinus)">&#177;</span>
        <span class="button-number-secondary" (click)="performAction(calculatorAction.Percent)">%</span>
        <span class="button-operation" (click)="performAction(calculatorAction.Divide)">&#247;</span>
      </div>
      <div>
        <span class="button-number" (click)="performAction(calculatorAction.Number7)">7</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number8)">8</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number9)">9</span>
        <span class="button-operation" (click)="performAction(calculatorAction.Multiply)">&#215;</span>
      </div>
      <div>
        <span class="button-number" (click)="performAction(calculatorAction.Number4)">4</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number5)">5</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number6)">6</span>
        <span class="button-operation" (click)="performAction(calculatorAction.Minus)">&ndash;</span>
      </div>
      <div>
        <span class="button-number" (click)="performAction(calculatorAction.Number1)">1</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number2)">2</span>
        <span class="button-number" (click)="performAction(calculatorAction.Number3)">3</span>
        <span class="button-operation" (click)="performAction(calculatorAction.Plus)">+</span>
      </div>
      <div>
        <span class="button-number button-zero" (click)="performAction(calculatorAction.Number0)">0</span>
        <span class="button-number" (click)="performAction(calculatorAction.Decimal)">.</span>
        <span class="button-equals" (click)="performAction(calculatorAction.Equals)">=</span>
      </div>
    </div>
  </div>
</div>