<div class="kip-form-group">
  <label [for]="id">{{ label }}</label>
  <select class="form-control" [id]="id" [ngModelOptions]="{standalone: true}" [(ngModel)]="value"
    (ngModelChange)="onChange($event)">
    @for (value of values; track value) {
    <option [ngValue]="value.id">
      {{value.name }}
    </option>
    }
  </select>
</div>