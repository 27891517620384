import { createAction, props } from '@ngrx/store';

import { JoinRequest } from '../../models';

enum DropInActionTypes {
  DropInSessionJoinRequestedAction = 'Tutor > Drop-In > Join Requested',
  DropInSessionJoinAcceptedAction = 'Tutor > Drop-In > Join Request Accepted',
  DropInSessionRequestCancelledAction = 'Tutor > Drop-In > Join Request Cancelled',
  DropInSessionJoinDeniedAction = 'Tutor > Drop-In > Join Request Denied'
}

export const dropInSessionJoinRequestedAction = createAction(
  DropInActionTypes.DropInSessionJoinRequestedAction,
  props<{
    readonly request: JoinRequest;
  }>()
);

export const dropInSessionJoinAcceptedAction = createAction(
  DropInActionTypes.DropInSessionJoinAcceptedAction,
  props<{
    readonly request: JoinRequest;
  }>()
);

export const dropInSessionJoinDeniedAction = createAction(
  DropInActionTypes.DropInSessionJoinDeniedAction,
  props<{
    readonly request: JoinRequest;
  }>()
);

export const dropInSessionRequestCancelledAction = createAction(
  DropInActionTypes.DropInSessionRequestCancelledAction,
  props<{
    readonly request: JoinRequest;
  }>()
);
