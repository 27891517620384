import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';
import { Age, AssessmentResult, AssessmentResultType, ComprehensionTest, ReadingTest, SharedService } from 'ui-common-lib';

import { ResultComponent } from './result.component';

@Component({
  selector: 'kip-assessment-result',
  templateUrl: './assessment-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentResultComponent implements OnInit, OnDestroy {

  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #lastAssessmentResults: readonly AssessmentResult[] = [];
  #assessmentResultTypes: AssessmentResultType[] = [];
  #subjectId: number | undefined;
  #subscriptions: Subscription[] = [];

  get assessmentResultTypes() {
    return this.#assessmentResultTypes;
  }

  @Input() set lastAssessmentResults(values: readonly AssessmentResult[]) {
    if (this.#lastAssessmentResults !== values) {
      this.#lastAssessmentResults = values;
      this.#loadLastAssessmentResults();
    }
  }

  get lastAssessmentResults() {
    return this.#lastAssessmentResults;
  }

  @Input({ required: true }) age: Age | undefined;
  @Input() grade: number | null | undefined;
  @Input({ required: true }) assessmentResults: FormArray | undefined;
  @Input({ required: true }) readingTest: readonly ReadingTest[] = [];
  @Input({ required: true }) comprehensionTest: readonly ComprehensionTest[] = [];
  @Input() allowNotes = false;

  /* eslint-disable kip/no-unused-public-members */

  @Input() set subjectId(value: number | undefined) {
    if (this.#subjectId !== value) {
      this.#subjectId = value;
      this.load();
    }
  }

  get subjectId() {
    return this.#subjectId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChildren(ResultComponent) results: QueryList<ResultComponent> | undefined;

  @Output() readonly statusChange = new EventEmitter();

  ngOnInit() {
    if (this.assessmentResults && this.assessmentResults.controls.length === 0) {
      this.load();
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onStatusChange() {
    this.statusChange.emit();
    this.#changeDetectorRef.markForCheck();
  }

  // eslint-disable=next-line kip/no-unused-public-members
  load() {
    this.#subscriptions.push(
      this.#sharedService.getAssessmentResultTypes()
        .subscribe(
          assessmentResultTypes => {
            this.#assessmentResultTypes = this.subjectId ? assessmentResultTypes.filter(s => s.subjects.find(su => su.id === this.subjectId)) : assessmentResultTypes;
            this.#changeDetectorRef.markForCheck();
            setTimeout(() => {
              if (this.results) {
                for (const result of this.results) {
                  if (this.assessmentResults) {
                    this.assessmentResults.push(result.result);
                  }
                }
              }
              this.#loadLastAssessmentResults();
            }, 100);
          }));
  }

  #loadLastAssessmentResults() {
    if (this.assessmentResults) {
      for (const lastAssessmentResult of this.lastAssessmentResults) {
        for (const assessmentResult of this.assessmentResults.controls) {
          const formGroup = assessmentResult as FormGroupTypeSafe<AssessmentResult>;
          if (formGroup.getSafe(s => s.type).value.id === lastAssessmentResult.type.id) {
            ResultComponent.loadData(formGroup, lastAssessmentResult);
          }
        }
      }
    }
    this.#changeDetectorRef.markForCheck();
  }

}
