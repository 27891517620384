import { FormArray, FormControl, Validators } from '@angular/forms';

/*eslint-disable @typescript-eslint/unbound-method */
export function determineOptional(control: FormArray | FormControl) {
  if (control instanceof FormControl) {

    if (control.hasValidator(Validators.required)) {
      return false;
    }
  }
  else if (control instanceof FormArray) {
    const controls = Object.values(control.controls);
    for (const childControl of controls) {
      if (childControl.hasValidator(Validators.required)) {
        return false;
      }
    }
  }
  return true;
}
/*eslint-enable @typescript-eslint/unbound-method */
