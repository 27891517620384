<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()">
  @if (headerLabel(); as headerLabel)
  {
  <label [class]="labelClass()??''" [for]="id()">{{ headerLabel }}</label>
  }
  <div class="custom-control custom-checkbox kip-choice mb-3">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" [formControl]="control()" [id]="id()"
        [kipDisableControl]="disabled()">
      <label class="custom-control-label form-check-label form-check-label--append" [for]="id()">
        {{ label() }}
      </label>
    </div>
  </div>
</kip-form-control>