import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Icons } from 'icon-lib';

import { QuestionBehaviourController, QuestionOption } from '../../models';

@Component({
  selector: 'kip-question-option',
  templateUrl: './question-option-component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionOptionComponent {

  readonly #sanitizer = inject(DomSanitizer);

  #option: QuestionOption | undefined;
  #behaviour: QuestionBehaviourController | undefined;
  #sound: string | undefined;

  readonly icons = Icons;

  get html(): SafeHtml | undefined {
    if (this.option?.text) {
      return this.#sanitizer.bypassSecurityTrustHtml(this.option.text);
    }

    return undefined;
  }

  get sound() {
    return this.#sound;
  }

  @Input({ required: true })
  set option(value: QuestionOption | undefined) {
    this.#option = value;
    this.#resolveSound();
  }

  get option() {
    return this.#option;
  }

  @Input()
  set behaviour(value: QuestionBehaviourController | undefined) {
    this.#behaviour = value;
    this.#resolveSound();
  }

  get behaviour() {
    return this.#behaviour;
  }

  playSound() {
    if (this.sound && this.behaviour) {
      this.behaviour.invokeActions(this.sound);
    }
  }

  #resolveSound() {
    this.#sound = this.option && this.behaviour && this.option.text && this.behaviour.hasAction(this.option.text)
      ? this.option.text
      : undefined;
  }
}
