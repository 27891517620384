import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';
@Component({
  selector: 'kip-monty-head',
  templateUrl: './monty-head.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MontyHeadComponent implements MontyGraphic { }
