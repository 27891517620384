import { Pipe, PipeTransform } from '@angular/core';
import { AgeResult } from 'ui-common-lib';

@Pipe({
  name: 'ageResultTextClass'
})
export class AgeResultTextClassPipe implements PipeTransform {

  transform(ageResult: AgeResult) {
    return ageResult === AgeResult.Assessed ? 'text-success' : 'text-danger';
  }
}
