import { fabric } from 'fabric';
import { Icons } from 'icon-lib';

import { FabricObjectExtended } from '..';
import { ToolbarSvg } from '../toolbar-svg';
import { WhiteboardColor } from '../whiteboard-color';
import { WhiteboardGridType } from '../whiteboard-grid-type';
import { WhiteboardGridTypeName } from '../whiteboard-grid-type-name';
import { WhiteboardTool } from '../whiteboard-tool';
import { WhiteboardToolOption } from '../whiteboard-tool-option';

export class WhiteboardGridTool extends WhiteboardTool {

  constructor() {
    super(ToolbarSvg.Grid);

    this.selectedOption = WhiteboardGridTypeName.Blank;
  }

  override get options(): WhiteboardToolOption[] {
    return [
      {
        name: WhiteboardGridTypeName.Blank,
        icon: Icons.whiteboardGrid.blank,
        visible: true
      },
      {
        name: WhiteboardGridTypeName.Small,
        icon: Icons.whiteboardGrid.small,
        visible: true
      },
      {
        name: WhiteboardGridTypeName.Large,
        icon: Icons.whiteboardGrid.large,
        visible: true
      },
      {
        name: WhiteboardGridTypeName.Horizontal,
        icon: Icons.whiteboardGrid.horizontal,
        visible: true
      }
    ];
  }

  static drawGrid(gridType: WhiteboardGridType, canvas: fabric.Canvas) {

    // Clear the current grid
    for (const obj of canvas.getObjects('line')) {
      const objExtended = obj as FabricObjectExtended;
      if (objExtended?.data?.grid) {
        canvas.remove(obj);
      }
    }

    canvas.renderAll();

    // Build the grid
    const height = canvas.getHeight();
    const width = canvas.getWidth();

    switch (gridType) {
      case WhiteboardGridType.Blank:
        break;
      case WhiteboardGridType.Small:
        WhiteboardGridTool.drawHorizontalLines(25, height, width, canvas);
        WhiteboardGridTool.drawVerticalLines(25, width, canvas);
        break;
      case WhiteboardGridType.Large:
        WhiteboardGridTool.drawHorizontalLines(50, height, width, canvas);
        WhiteboardGridTool.drawVerticalLines(50, width, canvas);
        break;
      case WhiteboardGridType.Horizontal:
        WhiteboardGridTool.drawHorizontalLines(50, width, width, canvas);
        break;
      default:
        throw new Error(`Whiteboard grid '${gridType}' is not supported.`);
    }

    canvas.renderAll();
  }

  private static drawHorizontalLines(interval: number, height: number, width: number, canvas: fabric.Canvas) {
    const count = height / interval;

    for (let index = 0; index < count; index++) {
      WhiteboardGridTool.drawLine(0, index * interval, width, index * interval, canvas);
    }
  }

  private static drawVerticalLines(interval: number, width: number, canvas: fabric.Canvas) {
    const count = width / interval;

    for (let index = 0; index < count; index++) {
      WhiteboardGridTool.drawLine(index * interval, 0, index * interval, width, canvas);
    }
  }

  private static drawLine(x1: number, y1: number, x2: number, y2: number, canvas: fabric.Canvas) {
    const line = new fabric.Line([x1, y1, x2, y2], {
      selectable: false,
      stroke: '#acd5ff',
      opacity: 0.5,
      data: {
        grid: true,
        asset: true
      }
    });

    canvas.add(line);
  }

  setup(_color: WhiteboardColor, canvas: fabric.Canvas) {

    let gridType: WhiteboardGridType = WhiteboardGridType.Blank;
    switch (this.selectedOption) {
      case WhiteboardGridTypeName.Small:
        gridType = WhiteboardGridType.Small;
        break;
      case WhiteboardGridTypeName.Large:
        gridType = WhiteboardGridType.Large;
        break;
      case WhiteboardGridTypeName.Horizontal:
        gridType = WhiteboardGridType.Horizontal;
        break;
    }

    WhiteboardGridTool.drawGrid(gridType, canvas);
  }

  teardown(_canvas: fabric.Canvas) {
    // Grids are persistent between tool selection
  }
}
