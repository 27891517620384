/* eslint-disable @angular-eslint/no-forward-ref */
import { ChangeDetectionStrategy, Component, forwardRef, inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CentreProfile } from '../../models';
import { ProfileService } from '../../services';
import { BaseMultiple, BaseMultiplePickerComponent } from '../base-multiple-picker/base-multiple-picker.component';

interface GroupedBaseMultiple {
  readonly group: string;
  readonly children: BaseMultiple[];
}

@Component({
  selector: 'kip-centre-multiple-picker',
  templateUrl: './centre-multiple-picker.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CentreMultiplePickerComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentreMultiplePickerComponent extends BaseMultiplePickerComponent implements OnInit {

  readonly #profileService = inject(ProfileService);

  grouped: GroupedBaseMultiple[] = [];

  ngOnInit() {
    this.subscriptions.push(
      this.#profileService.getCentres()
        .subscribe(centres => {

          // group by organisation name

          const grouped = centres.reduce(
            (result: { [key: string]: CentreProfile[] }, currentValue: CentreProfile) => {
              (result[currentValue.organisationName] = result[currentValue.organisationName] || []).push(currentValue);
              return result;
            }, {});

          const groupedMultipleValues: GroupedBaseMultiple[] = [];
          const multipleValues: BaseMultiple[] = [];

          // loop through organisation names

          for (const group in grouped) {

            // get multiple values and sort

            const currentMultipleValues = grouped[group].map<BaseMultiple>(s => ({
              id: s.id, name: s.centreName, checked: this.ids.includes(s.id)
            }));

            currentMultipleValues.sort((a, b) => {
              if (a.name === b.name) {
                return 0;
              }

              return a.name > b.name ? 1 : -1;
            });

            multipleValues.push(...currentMultipleValues);
            groupedMultipleValues.push({ group: group, children: currentMultipleValues });
          }

          // sort by organisation name

          groupedMultipleValues.sort((a, b) => {
            if (a.group === b.group) {
              return 0;
            }

            return a.group > b.group ? 1 : -1;
          });

          this.grouped = groupedMultipleValues;
          this.multipleValues = multipleValues;
          this.changeDetectorRef.markForCheck();
        }));
  }
}
