import { QuestionParametersMatching, QuestionSummary } from '../../models';

export function convertMatching(question: QuestionSummary<QuestionParametersMatching>,
  genericInput: string, render: (input: string) => string, showAnswerOnly: boolean) {
  let result = '';
  let index = 0;
  for (const source of question.parameters.source) {
    result += genericInput.replace('&nbsp;', `<span class="kip-success-tutor-answer">${render(source.text)} (${question.answers[index].values[0]})</span>`);
    index++;
  }

  return showAnswerOnly ? result : question.text + result;
}
