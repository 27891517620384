import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Icons } from 'icon-lib';

import { AnswerType, IndexedOption, QuestionLayoutType, QuestionOption, QuestionSelection, QuestionSorting, ValidationResult } from '../../models';
import { QuestionLayout } from '../question-layout';

interface ValidIndexedOption extends IndexedOption {
  valid: ValidationResult;
}

@Component({
  selector: 'kip-question-sorting',
  templateUrl: './sorting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortingComponent extends QuestionLayout {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly icons = Icons;

  override question: QuestionSelection | QuestionSorting | undefined;

  override set validationResults(values: ValidationResult[]) {
    if (this.question) {
      this._validationResults = values;
      const correctAnswers = this.question.answers.map(s => s.values[0]);
      if (this.question.type === QuestionLayoutType.Selection) {
        for (const selection of this.selections) {
          selection.valid = ValidationResult.NotKnown;
          if (this.answers.includes(selection.value)) {
            selection.valid = correctAnswers.includes(selection.value) ? ValidationResult.Correct : ValidationResult.Incorrect;
          }
        }
      } else {
        let index = 0;
        for (const option of this.options) {
          option.valid = correctAnswers.indexOf(option.value) === index ? ValidationResult.Correct : ValidationResult.Incorrect;
          index++;
        }
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  get selectionAnswer(): boolean {
    if (this.question) {
      return this.question.type === QuestionLayoutType.Selection;
    }

    return false;
  }

  horizontal = false;
  options: ValidIndexedOption[] = [];
  selections: ValidIndexedOption[] = [];

  get answers(): AnswerType[] {
    const answers: AnswerType[] = [];
    if (this.selectionAnswer) {
      const selectionsClone = [...this.selections];
      selectionsClone.sort((a, b) => a.index - b.index);
      for (const selection of selectionsClone) {
        answers.push(selection.value);
      }
      return answers;
    }
    for (const option of this.options) {
      answers.push(option.value);
    }

    return answers;
  }

  override initialize() {

    let options: readonly QuestionOption[] = [];
    let horizontal = false;

    if (this.question?.parameters) {
      if (this.question?.type === QuestionLayoutType.Selection) {
        options = this.question.parameters;
      } else {
        options = this.question.parameters.options;
        horizontal = this.question.parameters.horizontal;
      }
    }

    this.horizontal = horizontal;
    this.options = options.map((option, index) => ({
      index: index,
      text: option.text,
      image: option.image,
      value: option.value,
      valid: ValidationResult.NotKnown
    }));
    this.selections = [];

    this.displayAnswer();

    this.#changeDetectorRef.markForCheck();
  }

  drop(event: CdkDragDrop<ValidIndexedOption[]>) {
    if (this.readonly) {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onChange() {
    this.sendUpdates();
  }

  clearValidation() {
    if (this.question) {
      if (this.question.type === QuestionLayoutType.Selection) {
        for (const selection of this.selections) {
          selection.valid = ValidationResult.NotKnown;
        }
      } else {
        for (const option of this.options) {
          option.valid = ValidationResult.NotKnown;
        }
      }
    }
  }

  displayAnswer() {
    if (this.readonly && this.question) {
      const correctAnswers = this.question.answers.map(s => s.values[0]);
      let answers: readonly AnswerType[] = correctAnswers;
      if (this.studentAnswers !== undefined) {
        answers = this.studentAnswers;
      }
      if (this.question.type === QuestionLayoutType.Selection) {
        for (const option of this.options) {
          option.valid = ValidationResult.NotKnown;
          if (answers.includes(option.value)) {
            option.valid = correctAnswers.includes(option.value) ? ValidationResult.Correct : ValidationResult.Incorrect;
          }
        }
        this.selections = this.options.filter(s => answers.includes(s.value));
        this.options = this.options.filter(s => !answers.includes(s.value));
      } else {
        this.options.sort((a, b) => answers.indexOf(a.value) - answers.indexOf(b.value));
        let index = 0;
        for (const option of this.options) {
          option.valid = correctAnswers.indexOf(option.value) === index ? ValidationResult.Correct : ValidationResult.Incorrect;
          index++;
        }
      }
    }
  }
}
