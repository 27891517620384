<kip-form-group-wrapper [formGroup]="strengthForm" [submitText]="buttonText" (submittedAndValidated)="submit()">
  <h2 class="kip-tab__header">{{ action }} Strength</h2>
  <kip-form-control-long-text id="description" label="Description" [control]="description" [rows]="3" />
  <kip-form-control-date id="reviewData" label="Target Date for Review" [control]="reviewDate" [minDate]="minDate" />

  @if (!isNew) {
  <kip-form-control-checkbox headerLabel="Completion" id="completed" label="Goal Target Met" [control]="completed" />
  }

  <div buttons>
    @if (id.value > 0) {
    <button class="btn btn-outline-danger ms-2" type="button" (click)="delete()">
      <fa-icon [icon]="icons.delete" />
      Delete
    </button>
    }
    <button class="btn btn-link" type="button" (click)="cancelled.emit()">Cancel</button>
  </div>
</kip-form-group-wrapper>