<div class="kip-dashboard kip-xl-container mx-auto p-3">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <kip-certificate-node [certificateNode]="certificateNode" (select)="select($event)" />
        </div>
        <div class="col-6">
          <iframe #frame class="w-100 mt-3" scrolling="no" src="" style="height:700px"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>