import { Pipe, PipeTransform } from '@angular/core';

import { RegionId } from '../models';
import { currencySwapFunction } from '../utilities/regions/currency-swap-function';

@Pipe({
  name: 'currencySwap'
})
export class CurrencySwapPipe implements PipeTransform {

  transform(value: string, regionId: number = RegionId.Australia): string {
    return currencySwapFunction(value, regionId);
  }
}
