<div class="kip-question-navigator d-flex flex-column h-100 user-select-none" [class.kip-scrollable]="scrollable">
  <div class="kip-scroller kip-btn text-center cursor-pointer border-0 z-index-20"
    [ngClass]="{'disabled':isUpScrollDisabled}" (click)="scrollUp()">
    <fa-icon [icon]="icons.carets.up" />
  </div>
  <ul #container class="kip-items z-index-20" [class.kip-items--rounded]="!scrollable">
    @for (item of items; track trackBy(index, item); let index = $index) {
    @if (scrollable || index > 0) {
    <li class="kip-anchor"></li>
    }
    <li class="kip-item position-relative d-flex flex-row" [class.kip-active]="activeQuestionIndex === index">
      <div [class]="'kip-item-inner text-center kip-attempts-' + (showCorrect? item.attempts: 0)"
        [class.kip-correct]="item.correct && showCorrect" [class.kip-visited]="item.visited"
        (click)="click(item, false)">
        @if (item.icon && activeQuestionIndex !== index) {
        <fa-icon [icon]="item.icon" />
        }
        @if (activeQuestionIndex === index) {
        <fa-icon [icon]="icons.preview.solid" />
        }
        @if (!item.icon && activeQuestionIndex !== index) {
        <span>
          {{ index + 1 }}
        </span>
        }
      </div>
      @if (isTutor && item.hasWhiteboard) {
      <fa-icon class="me-1 cursor-pointer position-absolute bottom-0 right-0" size="xs" [fixedWidth]="true"
        [icon]="whiteboardQuestionId === item.id ? icons.whiteboardOpen : icons.hasWhiteboard"
        (click)="click(item, true)" />
      }
    </li>
    @if (index === (items.length - 1)) {
    <li class="kip-anchor"></li>
    }
    }
  </ul>
  <div class="kip-scroller kip-btn text-center rounded-right-0 border-0 z-index-20"
    [ngClass]="{'disabled':isDownScrollDisabled}" (click)="scrollDown()">
    <fa-icon [icon]="icons.carets.down" />
  </div>
</div>