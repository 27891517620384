<ng-container>
  <div class="kip-tab">
    <div class="d-flex justify-content-between">
      <h2 class="kip-tab__header">Point Trade in</h2>
    </div>
  </div>
</ng-container>
<ng-container>
  <div class="d-flex ms-5 my-5">
    <kip-icon-points-trade [width]="90" />
    <div class="d-flex flex-column ms-3">
      <h5>Total points:</h5>
      <h1>{{ maxPoints }}</h1>
    </div>
  </div>
</ng-container>
@if (hasPointsToRedeem) {
<kip-form-group-wrapper submitText="Trade Points" [formGroup]="pointsRedeemedForm" (submittedAndValidated)="submit()">
  <kip-form-control-number id="points" label="Points" [control]="points" [max]="maxPoints" [min]="1" />
  <a buttons class="btn btn-link" (click)="cancel.emit()">Cancel</a>
</kip-form-group-wrapper>
}