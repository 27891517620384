import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-electric-lightning',
  templateUrl: './electric-lightning.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconElectricLightningComponent implements IconComponent {

}
