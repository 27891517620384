import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';

import { ContentHistory, ContentStatsNode, CurriculumNode, PreviewActivity } from '../../models';

@Component({
  selector: 'kip-curriculum-nodes',
  templateUrl: './curriculum-nodes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurriculumNodesComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #contentHistory: Map<number, ContentHistory> | undefined;
  #curriculumNodes: CurriculumNode[] | undefined;

  readonly icons = Icons;

  @Input({ required: true }) regionId = RegionId.Australia;
  @Input() expanded = true;
  @Input({ required: true }) resetTime: Date | undefined;
  @Input({ required: true }) allowPreview = true;
  @Input({ required: true }) singleSelection = false;
  @Output() readonly showPreview = new EventEmitter<PreviewActivity>();

  @Input({ required: true }) set curriculumNodes(curriculumNodes: CurriculumNode[] | undefined) {
    this.#curriculumNodes = curriculumNodes;
    this.#matchHistory();
    this.#changeDetectorRef.markForCheck();
  }

  get curriculumNodes() {
    return this.#curriculumNodes;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set contentHistory(value: Map<number, ContentHistory> | undefined) {
    if (this.#contentHistory !== value) {
      this.#contentHistory = value;
      this.#matchHistory();
      this.#changeDetectorRef.markForCheck();
    }
  }

  get contentHistory() {
    return this.#contentHistory;
  }

  /* eslint-enable kip/no-unused-public-members */

  #calculateStats(contentNode: CurriculumNode): ContentStatsNode {
    contentNode.stats = { activityCount: 0, completeCount: 0, percentage: 0 };

    if (contentNode.topic) {
      if (contentNode.topic.activities) {
        for (const activity of contentNode.topic.activities) {
          contentNode.stats.activityCount++;
          activity.history = this.#contentHistory ? this.#contentHistory.get(activity.id) : undefined;
          if (activity.history) {
            contentNode.stats.completeCount += 1;
            contentNode.stats.percentage += activity.history.percentage;
          }
        }
      }

      if (contentNode.topic.skillbuilderActivities) {
        for (const activity of contentNode.topic.skillbuilderActivities) {
          contentNode.stats.activityCount++;
          activity.history = this.#contentHistory ? this.#contentHistory.get(activity.id) : undefined;
          if (activity.history) {
            contentNode.stats.completeCount += 1;
            contentNode.stats.percentage += activity.history.percentage;
          }
        }
      }
    }

    if (contentNode.children) {
      for (const childNode of contentNode.children) {
        const childStats = this.#calculateStats(childNode);
        contentNode.stats.activityCount += childStats.activityCount;
        contentNode.stats.completeCount += childStats.completeCount;
        contentNode.stats.percentage += childStats.percentage;
      }
    }

    return contentNode.stats;
  }

  #matchHistory() {
    if (this.#contentHistory && this.#curriculumNodes) {
      for (const curriculum of this.#curriculumNodes) {
        this.#calculateStats(curriculum);
      }
    }
  }
}
