import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[kipStopPropagation]'
})
export class ClickStopPropagationDirective {

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
  }
}
