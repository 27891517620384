@if (hideSingle() && options().length !== 1 || !hideSingle()) {
<div class="kip-form-group" [ngClass]="{'form-group-invalid' : control() | isFormControlInvalid }">
  <div>
    <label [for]="name()">{{ label() }}</label>
    <select class="form-control" [formControl]="control()" [id]="name()">
      @for (option of options(); track option) {
      <option [ngValue]="option.id">
        {{option.title }}
      </option>
      }
    </select>
  </div>
  <kip-control-error [clientSideErrorMessage]="clientSideErrorMessage()" [control]="control()" />
</div>
}