import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ProfileService, Role } from '../services';

export enum Feature {
  Accounts = 'accounts',
  Students = 'students',
  Leads = 'leads',
  Assessments = 'assessments',
  AssessmentAvailabilities = 'assessment-availabilities',
  AssessmentReport = 'assessment-report',
  AttendanceReport = 'attendance-report',
  Calendar = 'calendar',
  Dashboard = 'dashboard',
  Prototype = 'prototype',
  QrGenerator = 'qr-generator',
  Admin = 'admin',
  Bundles = 'bundles',
  Centres = 'centres',
  Connections = 'connections',
  Organisations = 'organisations',
  ChangeLog = 'change-log',
  OrganisationActivities = 'organisation-activities',
  OrganisationGoals = 'organisation-goals',
  Employees = 'employees',
  Tutors = 'tutors',
  Sessions = 'sessions',
  Awards = 'awards',
  Rewards = 'rewards',
  Reports = 'reports',
  RankingReport = 'ranking-report',
  ActivitiesReport = 'activities-report',
  LessonReport = 'lesson-report',
  ProblemUsersReport = 'problem-users',
  IssuesReport = 'issues-report',
  Quizzes = 'quizzes',
  DropIn = 'drop-in',
  AccountMerge = 'account-merge',
  Maintenance = 'maintenance',
  NewCalendar = 'new-calendar',
  ExclusionSets = 'ExclusionSets',
  Videos = 'videos',
  BadWords = 'bad-words',
  Settings = 'settings',
  Animations = 'animations',
  AvatarAssets = 'avatar-assets',
  SchoolMaintenance = 'school'
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  readonly #profileService = inject(ProfileService);

  isFeatureAuthorized(featureName: Feature, writerOnly = false): Observable<boolean> {
    return this.#profileService.getUserProfile().pipe(
      map(s => {
        const roles = s.roles;
        if (roles.some(r => r === Role.CentreManager || r === Role.SuperUser)) {
          return true;
        }
        switch (featureName) {
          case Feature.Dashboard:
          case Feature.DropIn:
            return true;
          case Feature.Rewards:
            return roles.includes(Role.SupportRewards);
          case Feature.Maintenance:
          case Feature.ExclusionSets:
            return roles.includes(Role.SuperUser);
          case Feature.Reports:
          case Feature.Connections:
          case Feature.IssuesReport:
          case Feature.LessonReport:
          case Feature.AssessmentReport:
          case Feature.AttendanceReport:
          case Feature.RankingReport:
            return roles.includes(Role.SupportReport);
          case Feature.Animations:
            return roles.includes(Role.SuperUser);
          case Feature.AvatarAssets:
            return roles.includes(Role.SuperUser);
          case Feature.Admin:
            return roles.some(r => r.startsWith(Role.SupportAdmin));
          case Feature.Quizzes:
            return roles.includes(Role.SupportQuizzes);
          case Feature.AccountMerge:
            return writerOnly
              ? roles.includes(Role.SupportAccountMergeWriter)
              : roles.some(r => r === Role.SupportAccountMergeReader || r === Role.SupportAccountMergeWriter);
          case Feature.Videos:
            return writerOnly
              ? roles.includes(Role.SupportVideoWriter)
              : roles.some(r => r === Role.SupportVideoReader || r === Role.SupportVideoWriter);
          case Feature.Awards:
            return writerOnly
              ? roles.includes(Role.SupportAwardWriter)
              : roles.some(r => r === Role.SupportAwardReader || r === Role.SupportAwardWriter);
          case Feature.Settings:
            return roles.includes(Role.SuperUser);
          case Feature.SchoolMaintenance:
            return roles.includes(Role.SuperUser);
          case Feature.Sessions:
          case Feature.Calendar:
          case Feature.NewCalendar:
            return writerOnly
              ? roles.includes(Role.SupportSessionWriter)
              : roles.some(r => r === Role.SupportSessionReader || r === Role.SupportSessionWriter);
          case Feature.Tutors:
            return writerOnly
              ? roles.includes(Role.SupportTutorWriter)
              : roles.some(r => r === Role.SupportTutorReader || r === Role.SupportTutorWriter);
          case Feature.Employees:
            return writerOnly
              ? roles.includes(Role.SupportEmployeeWriter)
              : roles.some(r => r === Role.SupportEmployeeReader || r === Role.SupportEmployeeWriter);
          case Feature.Centres:
            return writerOnly
              ? roles.includes(Role.SupportCentreWriter)
              : roles.some(r => r === Role.SupportCentreReader || r === Role.SupportCentreWriter);
          case Feature.ChangeLog:
            return writerOnly
              ? roles.includes(Role.SupportChangeLogWriter)
              : roles.some(r => r === Role.SupportChangeLogReader || r === Role.SupportChangeLogWriter);
          case Feature.Organisations:
            return writerOnly
              ? roles.includes(Role.SupportOrganisationWriter)
              : roles.some(r => r === Role.SupportOrganisationReader || r === Role.SupportOrganisationWriter);
          case Feature.OrganisationGoals:
            return writerOnly
              ? roles.includes(Role.SupportOrganisationGoalsWriter)
              : roles.some(r => r === Role.SupportOrganisationGoalsReader || r === Role.SupportOrganisationGoalsWriter);
          case Feature.OrganisationActivities:
            return writerOnly
              ? roles.includes(Role.SupportOrganisationActivitiesWriter)
              : roles.some(r => r === Role.SupportOrganisationActivitiesReader || r === Role.SupportOrganisationActivitiesWriter);
          case Feature.Bundles:
            return writerOnly
              ? roles.includes(Role.SupportBundleWriter)
              : roles.some(r => r === Role.SupportBundleReader || r === Role.SupportBundleWriter);
          case Feature.Assessments:
            return writerOnly
              ? roles.includes(Role.SupportAssessmentsWriter)
              : roles.some(r => r === Role.SupportAssessmentsReader || r === Role.SupportAssessmentsWriter);
          case Feature.AssessmentAvailabilities:
            return writerOnly
              ? roles.includes(Role.SupportAssessmentAvailabilityWriter)
              : roles.some(r => r === Role.SupportAssessmentAvailabilityReader || r === Role.SupportAssessmentAvailabilityWriter);
          case Feature.Accounts:
            return roles.some(r => r === Role.SupportAccountReader || r === Role.SupportAccountWriter);
          case Feature.Students:
            return roles.some(r => r === Role.SupportStudentReader || r === Role.SupportStudentWriter);
          case Feature.Leads:
            return roles.some(r => r === Role.SupportLeadsReader || r === Role.SupportLeadsWriter);
          default:
            return false;
        }
      }));
  }

  isFeatureEnabled(featureName: Feature, writerOnly = false, dropInEnabled = false): Observable<boolean> {
    return this.#profileService.getUserProfile().pipe(
      mergeMap(s => {
        switch (featureName) {
          case Feature.Calendar:
            return s.calendarModes.old ? this.isFeatureAuthorized(featureName, writerOnly) : of(false);
          case Feature.NewCalendar:
          case Feature.ExclusionSets:
            return s.calendarModes.new ? this.isFeatureAuthorized(featureName, writerOnly) : of(false);
          case Feature.DropIn:
            return this.isFeatureAuthorized(featureName, writerOnly).pipe(map(authorized => dropInEnabled && authorized));
          default:
            return this.isFeatureAuthorized(featureName, writerOnly);
        }
      })
    );
  }
}
