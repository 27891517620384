<ng-template #graphToolModal>
  <div class="modal-header kip-student d-flex justify-content-between border-0 ps-4">
    <p class="h3 text-secondary fw-normal mb-0">Graph settings</p>
    <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
      <fa-icon [icon]="icons.close.solid" />
    </button>
  </div>

  <div class="modal-body kip-student ps-4">
    <form class="w-100 row" [formGroup]="toolbarGraphForm">
      <label class="h5 text-secondary mb-3">Y axis</label>

      <div class="kip-form-group col-6" [ngClass]="{'form-group-invalid' : yMin | isFormControlInvalid }">
        <label for="graph-y-min">Lower Range</label>
        <input class="form-control" id="graph-y-min" type="number" [formControl]="yMin" (keyup)="resetValidity(yMax)" />
        @if (yMin.errors?.['invalidRange']) {
        <span class="form-control-invalid">Y lower range must be lower than the Y
          upper range.</span>
        }
        @if (yMin.errors?.['required']) {
        <span class="form-control-invalid">Y lower range is required.</span>
        }
        @if (yMin.errors?.['precisionInvalid']) {
        <span class="form-control-invalid">Value can be no longer than 2
          decimal places.</span>
        }
      </div>

      <div class="kip-form-group col-6 pe-0" [ngClass]="{'form-group-invalid' : yMax | isFormControlInvalid }">
        <label for="graph-y-max">Upper Range</label>
        <input class="form-control" id="graph-y-max" type="number" [formControl]="yMax" (keyup)="resetValidity(yMin)" />
        @if (yMax.errors?.['invalidRange']) {
        <span class="form-control-invalid">Y upper range must be lower than the Y
          lower range.</span>
        }
        @if (yMax.errors?.['required']) {
        <span class="form-control-invalid">Y upper range is required.</span>
        }
        @if (yMax.errors?.['precisionInvalid']) {
        <span class="form-control-invalid">Value can be no longer than 2
          decimal places.</span>
        }
      </div>

      <label class="h5 text-secondary mb-3 pt-4">X axis</label>

      <div class="kip-form-group col-6" [ngClass]="{'form-group-invalid' : xMin | isFormControlInvalid }">
        <label for="graph-x-min">Lower Range</label>
        <input class="form-control" id="graph-x-min" type="number" [formControl]="xMin" (keyup)="resetValidity(xMax)" />
        @if (xMin.errors?.['invalidRange']) {
        <span class="form-control-invalid">X lower range must be lower than the X
          upper range.</span>
        }
        @if (xMin.errors?.['required']) {
        <span class="form-control-invalid">X lower range is required.</span>
        }
        @if (xMin.errors?.['precisionInvalid']) {
        <span class="form-control-invalid">Value can be no longer than 2
          decimal places.</span>
        }
      </div>

      <div class="kip-form-group col-6 pe-0" [ngClass]="{'form-group-invalid' : xMax | isFormControlInvalid }">
        <label for="graph-x-max">Upper Range</label>
        <input class="form-control" id="graph-x-max" type="number" [formControl]="xMax" (keyup)="resetValidity(xMin)" />
        @if (xMax.errors?.['invalidRange']) {
        <span class="form-control-invalid">X upper range must be lower than the X
          lower range.</span>
        }
        @if (xMax.errors?.['required']) {
        <span class="form-control-invalid">X upper range is required.</span>
        }
        @if (xMax.errors?.['precisionInvalid']) {
        <span class="form-control-invalid">Value can be no longer than 2
          decimal places.</span>
        }
      </div>

      <div class="modal-footer border-0 kip-student px-0">
        <button class="btn btn-outline-danger border-0 text-btn shadow-btn" type="button"
          (click)="close()">Cancel</button>
        <button class="btn btn-primary ms-2" type="button" (click)="submit()">Create</button>
      </div>
    </form>
  </div>
</ng-template>