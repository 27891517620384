import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BundleSelected } from 'enrolment-lib';
import { Age, AssessmentResult, AssessmentResultType, Grade } from 'ui-common-lib';

import { LessonActivityType } from '..';

export const AssessmentResultTypeGoal = 0;
export const AssessmentResultTypeBundles = -1;

@Component({
  selector: 'kip-assessment-result-review',
  templateUrl: './assessment-result-review.component.html',
  styles: '.position-alert-for-tutor { position: absolute; text-align: center !important; width: 100%; bottom: -5em; left: 0; margin-top: 2em; }',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssessmentResultReviewComponent {

  math = Math;

  readonly assessmentResultTypeGoal = AssessmentResultTypeGoal;
  readonly assessmentResultTypeBundles = AssessmentResultTypeBundles;

  @Input({ required: true }) studentName = '';
  @Input({ required: true }) activityName = '';
  @Input({ required: true }) activityType: LessonActivityType | undefined;
  @Input({ required: true }) age: Age | undefined;
  @Input({ required: true }) result: AssessmentResult | undefined;
  @Input({ required: true }) resultType: AssessmentResultType | undefined;
  @Input({ required: true }) goal: string | undefined;
  @Input({ required: true }) grades: Map<number, Grade> | undefined;
  @Input({ required: true }) gradeId: number | null | undefined;
  @Input() isTutor = false;

  @Input({ required: true }) centreId = 1;
  @Input({ required: true }) bundleSelected: BundleSelected = {};
}
