@if (errors.length> 0) {
<div class="alert alert-danger">
  @if (errors.length === 1) {
  <span>{{ errors[0] }} </span>
  }
  @if (errors.length > 1) {
  <ul>
    @for (error of errors; track error) {
    <li>{{error}}</li>
    }
  </ul>
  }
</div>
}