import { Age, NullableAge } from '../models';
import { convertAgeToString } from './convert-age-to-string';
import { convertNumberToAge } from './convert-number-to-age';

export function determineAgeDifference(age: NullableAge, studentAge: Age, years = ' years ', months = ' months') {
  const ageDifference = (age.year ?? 0) * 12 + (age.month ?? 0) - studentAge.year * 12 - studentAge.month;
  const ageDifferenceAbs = Math.abs(ageDifference);
  const ageDifferenceTest = convertAgeToString(convertNumberToAge(ageDifferenceAbs), years, months);

  return ageDifference < 0 ? `${ageDifferenceTest} behind` : `${ageDifferenceTest} ahead`;
}
