import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Severity } from 'message-lib';
import { uniqueStudentName } from 'pipes-directives-lib';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { UserName } from '../../../shared';
import { Alert, AlertSource } from '../../models';
import { fromAlerts, fromState } from '..';
import * as fromQuestion from '../question';
import { TutorState } from '../state';

@Injectable()
export class QuestionEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<TutorState>);

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.openAction),
      switchMap(action => of(
        fromQuestion.startTimerAction({ questionGuid: action.questionGuid })
      ))
    );
  });

  close$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.closeAction),
      switchMap(action => of(
        fromQuestion.endTimerAction({ questionGuid: action.questionGuid })
      ))
    );
  });

  checkForExcessiveSkipsOrSkillbuilderAdditions$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.skipAction),
      withLatestFrom(
        this.#store.select(fromState.selectTutorSettings),
        this.#store.select(fromState.selectLessonEntities),
        this.#store.select(fromState.selectActivityEntityState),
        this.#store.select(fromState.selectActivityEntities),
        this.#store.select(fromState.selectQuestionEntityState)
      ),
      switchMap(([action, tutorSettings, lessonEntities, activityEntityStates, activityEntities, questionEntityStates]) => {

        const lessonEntity = lessonEntities[action.lessonId];
        if (lessonEntity) {
          const student = lessonEntity.student;
          const allStudents: UserName[] = [];
          for (const lessonId in lessonEntities) {
            allStudents.push(lessonEntities[lessonId].student);
          }

          const activityEntityState = activityEntityStates[action.activityGuid];
          const questionGuids = activityEntityState.questionGuids;

          const questionNumber = questionGuids.indexOf(action.questionGuid) + 1;

          let skipCount = 0;
          const activityName = activityEntities[activityEntityState.lessonActivityPlanId].name;
          for (const questionGuid of questionGuids) {
            if (questionEntityStates[questionGuid].skipped) {
              skipCount++;
            }
          }

          if (tutorSettings?.showSkillbuilderAddedAlerts && action.skillBuilderAdded) {
            const name = uniqueStudentName(student, allStudents);
            const message = `${name} has skipped question ${questionNumber} in ${activityName} and a skill builder has been added for you to review with them!`;
            this.#store.dispatch(fromAlerts.addAlertAction(
              {
                alert:
                  new Alert(Severity.Danger, message, new Date(), AlertSource.SkillbuilderStatus, action.lessonId, false)
              }));
          }

          if (tutorSettings?.showExcessiveSkippingAlerts && skipCount > 3) {

            const name = uniqueStudentName(student, allStudents);
            const message = `${name} has skipped ${skipCount} questions in ${activityName}!`;
            this.#store.dispatch(fromAlerts.addAlertAction(
              {
                alert:
                  new Alert(Severity.Danger, message, new Date(), AlertSource.SkipStatus, action.lessonId, false)
              }));
          }
        }

        return EMPTY;
      }
      )
    );
  }, { dispatch: false });
}
