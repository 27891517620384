import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ProfileService } from '../services';
import { Route } from './routes';

@Component({
  selector: 'kip-feature-grid',
  templateUrl: './feature-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureGridComponent {

  readonly #profileService = inject(ProfileService);

  readonly isLegacy = toSignal(this.#profileService.getIsLegacy(), { initialValue: false });
  readonly routes = input<{ [key: string]: Route }>({});

}
