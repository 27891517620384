import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { HelpData, HelpNode, HelpNodeName, HelpNodeSelected } from '../models';
import { HelpService } from '../services';

@Component({
  selector: 'kip-help-tree',
  templateUrl: './help-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HelpTreeComponent implements OnInit, OnDestroy {

  readonly #helpService = inject(HelpService);
  readonly #domSanitizer = inject(DomSanitizer);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #uIdMap = new Map<string, HelpNode>();
  readonly #parentMap = new Map<string, string>();
  #uId: string = HelpNodeName.UsingKipLearn;
  #helpNode: HelpNode | undefined = undefined;
  readonly #htmlKeys = new Map<HelpNodeName, HelpData>();
  #safeHtml: SafeHtml | undefined = undefined;
  #subscriptions: Subscription[] = [];

  get helpNode() {
    return this.#helpNode;
  }

  @Input({ required: true }) selectedUId = '';

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set uId(value: string) {
    if (this.#uId !== value) {
      this.#uId = value;
      this.#selectByUId();
    }
  }

  get uId() {
    return this.#uId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly nodeSelected = new EventEmitter<HelpNodeSelected>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#helpService.getTree().subscribe(value => {
        this.#helpNode = value;
        this.#createMaps(value, '');
        this.#selectByUId();
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  select(helpNode: HelpNode) {
    const data = this.#htmlKeys.get(helpNode.uId);

    this.#uId = helpNode.uId;
    if (data) {
      this.#safeHtml = this.#domSanitizer.bypassSecurityTrustHtml(data.html);
      this.nodeSelected.emit({ uId: helpNode.uId, name: helpNode.name, pdfFile: helpNode.pdfFile, safeHtml: this.#safeHtml, activities: data.activities });
    } else {
      this.#loadNode(helpNode);
    }
  }

  /* eslint-disable kip/no-unused-public-members*/

  reload() {
    if (this.#uId) {
      const helpNode = this.#uIdMap.get(this.#uId);
      if (helpNode) {
        this.#loadNode(helpNode);
      }
    }
  }

  /* eslint-enable kip/no-unused-public-members*/

  #loadNode(helpNode: HelpNode) {
    this.#subscriptions.push(
      this.#helpService.getHtml(helpNode.uId).subscribe(value => {
        const fixedValue = { activities: value.activities, pdfFile: value.pdfFile, html: value.html.split('"/help/').join('"/tutor/help/') };
        this.#htmlKeys.set(helpNode.uId, fixedValue);
        this.#safeHtml = this.#domSanitizer.bypassSecurityTrustHtml(fixedValue.html);
        this.nodeSelected.emit({ uId: helpNode.uId, name: helpNode.name, pdfFile: value.pdfFile, safeHtml: this.#safeHtml, activities: value.activities });
        this.#changeDetectorRef.markForCheck();
      }));
  }

  #selectByUId() {
    if (this.#helpNode) {
      if (this.#uId) {
        const helpNode = this.#uIdMap.get(this.#uId);
        this.#expandParentNodes(this.#uId);
        if (helpNode) {
          this.select(helpNode);
          return;
        }
      }

      this.select(this.#helpNode);
    }
  }

  #expandParentNodes(uId: string) {
    const parent = this.#parentMap.get(uId);
    if (parent) {
      const parentHelpNode = this.#uIdMap.get(parent);
      if (parentHelpNode) {
        parentHelpNode.expanded = true;
      }

      this.#expandParentNodes(parent);
    }
  }

  #createMaps(helpNode: HelpNode, parentUId: string) {
    const uId = helpNode.uId.toLocaleLowerCase();
    this.#uIdMap.set(uId, helpNode);
    this.#parentMap.set(uId, parentUId);

    for (const child of helpNode.children) {
      this.#createMaps(child, uId);
    }
  }
}
