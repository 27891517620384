import { ChangeDetectionStrategy, Component, inject, OnInit, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilderTypeSafe } from 'forms-lib';
import { NotesForSession, SessionNote, SessionNoteUpdate } from 'tutor-notes-lib';

import { EditNotesBaseComponent } from '../shared/edit-notes-base.component';

@Component({
  selector: 'kip-tutor-edit-session-notes',
  templateUrl: './edit-session-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSessionNotesComponent extends EditNotesBaseComponent<SessionNote, NotesForSession> implements OnInit {

  readonly #fb = inject(FormBuilderTypeSafe);

  readonly cancelledEditing = output();

  constructor() {
    super();
    this.noteForm = this.#fb.group<NotesForSession>({
      internalNote: new FormControl<string | null>(null),
      parentNote: new FormControl<string | null>(null),
      parentNoteRanking: new FormControl<string | null>(null),
      internalNoteRanking: new FormControl<string | null>(null)
    });
  }

  override patch() {
    const note = this.note();
    if (this.noteForm && note) {
      this.noteForm.patchSafe({
        internalNote: note.internalNote,
        parentNote: note.parentNote,
        parentNoteRanking: note.parentNoteRanking,
        internalNoteRanking: note.internalNoteRanking
      });
    }
  }

  override submit() {
    const sessionNoteUpdate: SessionNoteUpdate = {
      sessionId: this.note()?.sessionId ?? 0,
      internalNote: this.noteForm.value.internalNote,
      parentNote: this.noteForm.value.parentNote,
      internalNoteRanking: this.noteForm.value.internalNoteRanking,
      parentNoteRanking: this.noteForm.value.parentNoteRanking
    };

    this.subscriptions.push(
      this.noteService.updateSessionNote(sessionNoteUpdate).subscribe({
        next: value => {
          this.note.set(value);
          this.save.emit(this.noteForm.value);
          this.cancelledEditing.emit();
        },
        error: (error: unknown) => {
          this.noteForm.handleServerErrors(error);
          this.changeDetectorRef.markForCheck();
        }
      }));
  }

}
