import { LearningRange } from '../models';
import { getLearningRangeFromPercentage } from './get-learning-range-from-percentage';

export enum CompletionGraphic {
  Complete = 'v1-activity-complete',
  PerfectScore = 'v1-perfect-score',
  GreatJob = 'v1-great-job',
  WellDone = 'v1-well-done'
}

export function getCompletionGraphic(percentage: number | null | undefined, isAssessment: boolean | undefined) {
  const defaultGraphic = CompletionGraphic.Complete;

  if (!isAssessment && percentage !== undefined && percentage !== null) {
    const learningRange = getLearningRangeFromPercentage(percentage);

    switch (learningRange) {

      case LearningRange.Perfect:
        return CompletionGraphic.PerfectScore;
      case LearningRange.Excellent:
        return CompletionGraphic.GreatJob;
      case LearningRange.Pass:
        return CompletionGraphic.WellDone;
      case LearningRange.NeedsImprovement:
        return defaultGraphic;
      case LearningRange.Unknown:
        return defaultGraphic;
    }
  }

  return defaultGraphic;
}
