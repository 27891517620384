import { Scoring } from './scoring';
import { ScoringStrategy } from './scoring-strategy';

export class ScoringStrategyDefault implements ScoringStrategy {
  shouldCalculatePercentage(scoring: Scoring): boolean {
    return scoring.inProgress;
  }

  shouldMarkInvalid(scoring: Scoring): boolean {
    return scoring.inProgress ? false : scoring.isDirty;
  }
}
