import { AnswerType, Question, ValidationResult } from '../models';
import { EqualityUnorderedValidator } from './equality-unordered.validator';

export class MathsUnorderedValidator extends EqualityUnorderedValidator {

  override validate(question: Question, studentAnswers: AnswerType[]): ValidationResult[] {

    // Remove all spaces before allowing the equality validator do its thing
    // Clone the question so the answer updates don't mutate the instance
    const questionClone = Object.assign({}, question, {
      answers: question.answers.map(alternates => ({
        values: this.#sanitizeAnswers(alternates.values)
      }))
    });

    const studentAnswersClone = this.#sanitizeAnswers(studentAnswers);

    return super.validate(questionClone, studentAnswersClone);
  }

  #sanitizeAnswers(answers: readonly AnswerType[]): AnswerType[] {
    return answers.map(answer => {
      if (answer !== undefined) {
        return answer
          .toString()
          .toLowerCase()
          .replace(/ /g, '');
      }

      return answer;
    });
  }
}
