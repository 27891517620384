@if (account) {
<div class="row">
  <div class="col kip-info">
    <div class="kip-info__heading"> Name</div>
    <div class="kip-info__entry">{{ account.accountName }}</div>
    @if (!showAdditionalInfo) {
    <div class="kip-info__subtitle">{{ account.contactPhone }}</div>
    }
  </div>
  <div class="col kip-info">
    <div class="kip-info__heading">Balance</div>
    <div class="kip-info__entry">
      {{ account.balance | currency }}
    </div>
  </div>
  @if (!showAdditionalInfo) {
  <div class="col-auto kip-info">
    <div class="kip-info__entry">
      <a class="btn btn-link" [routerLink]="'/accounts/' + account.accountId">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.chevrons.right" />
      </a>
    </div>
  </div>
  }
</div>
}
@if (showAdditionalInfo && account) {
<div class="row">
  <div class="col kip-info">
    <div class="kip-info__heading">Contact</div>
    <div class="kip-info__entry">{{ account.contactPhone }}</div>
  </div>
  <div class="col kip-info">
    <div class="kip-info__heading">Bundle Name</div>
    <div class="kip-info__entry">{{ account.bundleOfferName }}</div>
  </div>
</div>
}
@if (showAdditionalInfo && account && account.accountUserName) {
<div class="row">
  <div class="col kip-info">
    <div class="kip-info__heading">Username</div>
    <div class="kip-info__entry">{{ account.accountUserName }}</div>
  </div>
</div>
}