import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconLibModule } from 'icon-lib';
import { PdfLibModule } from 'pdf-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { QuestionsLibModule } from 'questions-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { HelpModalComponent } from './modal/help-modal.component';
import { HelpQuizComponent } from './quiz/help-quiz.component';
import { HelpTreeComponent } from './tree/help-tree.component';
import { HelpNodeComponent } from './tree/node/help-node.component';
import { HelpViewComponent } from './view/help-view.component';

@NgModule({
  declarations: [
    HelpModalComponent,
    HelpNodeComponent,
    HelpQuizComponent,
    HelpTreeComponent,
    HelpViewComponent
  ],
  imports: [
    CommonModule,
    IconLibModule,
    PdfLibModule,
    PipesDirectivesLibModule,
    QuestionsLibModule,
    UiCommonLibModule
  ],
  exports: [
    HelpModalComponent,
    HelpNodeComponent,
    HelpQuizComponent,
    HelpTreeComponent,
    HelpViewComponent
  ]
})
export class HelpLibModule { }
