/* eslint @typescript-eslint/no-dynamic-delete : 0 */

import { createReducer, on } from '@ngrx/store';
import { unknownPdf } from 'pdf-lib';
import { ScoringStatus, SimpleScore } from 'pipes-directives-lib';

import { LessonActivity, LessonActivityType, SessionActivityType } from '../../../shared';
import { ActivityEntity, ActivityEntityState } from '../models';
import * as ActivityActions from './activity.actions';

export interface ActivityState {
  readonly entityIds: readonly number[];
  readonly entities: {
    readonly [lessonActivityPlanId: number]: ActivityEntity;
  };
  readonly entityState: {
    [activityGuid: string]: ActivityEntityState;
  };
  readonly entityStateGuids: {
    readonly [lessonGuid: string]: readonly string[];
  };
}

interface EntityContext {
  readonly entity: ActivityEntity;
  readonly entityState: ActivityEntityState;
}

const initialState: ActivityState = {
  entityIds: [],
  entities: {},
  entityState: {},
  entityStateGuids: {}
};

function getActivityEntityContext(sessionActivityType: SessionActivityType, activity: LessonActivity): EntityContext {

  let activityId: number | undefined;
  switch (activity.lessonActivityType) {
    case LessonActivityType.Computer:
      activityId = activity.computer?.activityId;
      break;
    case LessonActivityType.TimedComputer:
      activityId = activity.timedComputer?.activityId;
      break;
    case LessonActivityType.Manual:
      activityId = activity.manual?.activityId;
      break;
    case LessonActivityType.AdHoc:
      activityId = undefined;
      break;
  }

  let questionGuids: string[] = [];

  if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
    questionGuids = activity.computer.questions.map(question => question.questionGuid);
  }

  const manualScoring = {
    correct: 0,
    total: 0,
    inProgress: false,
    simpleScore: SimpleScore.None,
    completed: false,
    noScoringRequired: false,
    scoringStatus: ScoringStatus.Scoring,
    timedActivityFirstAttempt: undefined as number | undefined,
    timedActivityFirstAttemptStartTime: undefined as Date | undefined,
    timedActivitySecondAttempt: undefined as number | undefined,
    timedActivitySecondAttemptStartTime: undefined as Date | undefined
  };

  let adjustableHeader: boolean | undefined;
  let pdfFile: string | undefined;
  let pdfSolutionFile: string | undefined;
  let soundFile: string | undefined;
  let isSimpleScoring = false;

  let warningTimeMinutes: number | null = null;
  let maxAllowedTimeMinutes: number | null = null;
  let warningTimeTutorOnly = false;

  if (activity.lessonActivityType === LessonActivityType.Manual && activity.manual) {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    pdfFile = activity.manual.pdfFile || unknownPdf;
    pdfSolutionFile = activity.manual.pdfSolutionFile ?? '';
    soundFile = activity.manual.soundFile ?? '';
    isSimpleScoring = activity.manual.isSimpleScoring ?? false;
    warningTimeMinutes = activity.manual.warningTimeMinutes ?? null;
    warningTimeTutorOnly = activity.manual.warningTimeTutorOnly ?? false;
    maxAllowedTimeMinutes = activity.manual.maxAllowedTimeMinutes ?? null;
    adjustableHeader = activity.manual.adjustableHeader;

    let scoringStatus: ScoringStatus = ScoringStatus.Scoring;

    if (activity.manual.inProgress)
      scoringStatus = ScoringStatus.InProgress;
    else if (activity.manual.noScoringRequired)
      scoringStatus = ScoringStatus.NoScoringRequired;

    manualScoring.correct = activity.manual.correct;
    manualScoring.total = activity.manual.total;
    manualScoring.inProgress = activity.manual.inProgress || false;
    manualScoring.simpleScore = activity.manual.simpleScore;
    manualScoring.completed = !!activity.completedOn || (activity.manual.inProgress ?? false);
    manualScoring.scoringStatus = scoringStatus;
  }

  if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
    warningTimeMinutes = activity.computer.warningTimeMinutes ?? null;
    warningTimeTutorOnly = activity.computer.warningTimeTutorOnly ?? false;
    maxAllowedTimeMinutes = activity.computer.maxAllowedTimeMinutes ?? null;
  }

  if (activity.lessonActivityType === LessonActivityType.AdHoc && activity.adHoc) {
    pdfFile = activity.adHoc.file;
    isSimpleScoring = false;

    manualScoring.correct = activity.adHoc.correct;
    manualScoring.total = activity.adHoc.total;
    manualScoring.inProgress = false;
    manualScoring.simpleScore = SimpleScore.None;
  }

  if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
    questionGuids = activity.timedComputer.questions.map(question => question.questionGuid);
    manualScoring.timedActivityFirstAttempt = activity.timedComputer.firstAttemptResult;
    manualScoring.timedActivitySecondAttempt = activity.timedComputer.secondAttemptResult;
    manualScoring.timedActivityFirstAttemptStartTime = activity.timedComputer.firstAttemptStartTime;
    manualScoring.timedActivitySecondAttemptStartTime = activity.timedComputer.secondAttemptStartTime;
  }

  // Create the base entity object
  const entity: ActivityEntity = {
    counterId: activity.counterId,
    activityId: activityId,
    name: activity.name,
    description: activity.description ?? '',
    isHiddenStudent: activity.isHiddenStudent ?? false,
    contentGuid: activity.contentGuid,
    unit: activity.unit ?? '',
    unitId: activity.unitId ?? 0,
    html: activity.manual?.html ?? '',
    isSimpleScoring: isSimpleScoring,
    pdfFile: pdfFile,
    pdfSolutionFile: pdfSolutionFile,
    adjustableHeader: adjustableHeader,
    soundFile: soundFile,
    sessionActivityType: sessionActivityType,
    hasSkillbuilders: activity.hasSkillbuilders ?? false,
    isJiraReportingBlocked: activity.isJiraReportingBlocked ?? false,
    assessmentFormName: activity.assessmentFormName ?? null,
    assessmentGradeId: activity.assessmentGradeId ?? null,
    assessmentResultTypeId: activity.assessmentResultTypeId ?? null,
    maxAllowedTimeMinutes: maxAllowedTimeMinutes,
    warningTimeMinutes: warningTimeMinutes,
    warningTimeTutorOnly: warningTimeTutorOnly,
    continuedFromPrevious: activity.continuedFromPrevious ?? false
  };

  // Separate the entity state for easier updating - via guid lookup
  const entityState: ActivityEntityState = {
    lessonActivityPlanId: activity.lessonActivityPlanId,
    questionGuids: questionGuids,
    percentage: activity.percentage ?? null,
    ageResult: activity.ageResult ?? null,
    age: activity.age ?? null,
    hasWhiteboard: activity.hasWhiteboard ?? false,
    lessonActivityType: activity.lessonActivityType,
    internalNote: activity.internalNote ?? '',
    manualScoring: manualScoring,
    completionTime: activity.completionTime ?? 0,
    completedOn: activity.completedOn,
    pdfFormFields: activity.pdfFormFields ?? null,
    pdfFormCalculations: activity.pdfFormCalculations ?? null,
    videos: activity.videos ?? [],
    files: activity.files ?? []
  };

  return {
    entity: entity,
    entityState: entityState
  };
}

export const activityReducer = createReducer(initialState,
  on(ActivityActions.loadManyAction, (state, action) => {
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;
    let entityStateGuids = state.entityStateGuids;

    for (const context of action.contexts) {
      const activityGuids = entityStateGuids[context.lessonGuid] || [];
      const entityContext = getActivityEntityContext(context.sessionActivityType, context.activity);

      // Only add the activity if it is not already in the store
      if (!entityIds.includes(context.activity.lessonActivityPlanId)) {
        entityIds = [...entityIds, context.activity.lessonActivityPlanId];
        entities = { ...entities, [context.activity.lessonActivityPlanId]: entityContext.entity };
      }

      // Add the activity state
      entityState = { ...entityState, [context.activity.activityGuid]: entityContext.entityState };

      // The activity guids are also tracked for convenience during updates
      entityStateGuids = { ...entityStateGuids, [context.lessonGuid]: [...activityGuids, context.activity.activityGuid] };
    }

    return {
      entityIds: entityIds,
      entities: entities,
      entityState: entityState,
      entityStateGuids: entityStateGuids
    };
  }),
  on(ActivityActions.updateAction, (state, action) => {

    // Update the entity state
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;

    for (const activityContext of action.activityContexts) {
      const activity = activityContext.activity;
      const entityContext = getActivityEntityContext(activityContext.sessionActivityType, activity);

      // If the activity is not in the store, add it
      if (!entityIds.includes(activity.lessonActivityPlanId)) {
        entityIds = [...entityIds, activity.lessonActivityPlanId];
        entities = { ...entities, [activity.lessonActivityPlanId]: entityContext.entity };
      }

      // Update the activity state
      let activityState = entityState[activity.activityGuid];
      activityState = Object.assign({}, activityState, entityContext.entityState);

      entityState = { ...entityState, [activity.activityGuid]: activityState };
    }

    // Remove the state for any activities no longer part of the lesson
    const stateGuids = state.entityStateGuids[action.lessonGuid] || [];
    const activityGuids = action.activityContexts.map(activityContext => activityContext.activity.activityGuid);

    for (const stateGuid of stateGuids) {
      if (!activityGuids.includes(stateGuid)) {
        delete entityState[stateGuid];
      }
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: entityState,
      entityStateGuids: { ...state.entityStateGuids, [action.lessonGuid]: activityGuids }
    };
  }),
  on(ActivityActions.activityFilesAddedAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const existingFiles = [...activityState.files];

      for (const file of action.files) {
        const index = existingFiles.findIndex(existingFile => existingFile.fileName === file.fileName);
        if (index !== -1) {
          existingFiles[index] = file;
        } else {
          existingFiles.push(file);
        }
      }

      activityState = Object.assign({}, activityState, {
        files: existingFiles
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.activityFileRenamedAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const existingFiles = [...activityState.files];
      const index = existingFiles.findIndex(existingFile => existingFile.id === action.fileId);

      if (index !== -1) {
        existingFiles[index] = Object.assign({}, existingFiles[index], {
          fileName: action.fileName
        });

        activityState = Object.assign({}, activityState, {
          files: existingFiles
        });
      }

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.activityFileRemovedAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const existingFiles = [...activityState.files];
      const index = existingFiles.findIndex(existingFile => existingFile.id === action.fileId);

      if (index !== -1) {
        existingFiles.splice(index, 1);
        activityState = Object.assign({}, activityState, {
          files: existingFiles
        });
      }

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.updateCompletionTimeAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        completionTime: action.completionTime
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.activityCompletedAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {

      activityState = Object.assign({}, activityState, {
        completedOn: action.completedOn,
        completionTime: action.completionTime,
        percentage: activityState.lessonActivityType === LessonActivityType.Computer || activityState.lessonActivityType === LessonActivityType.TimedComputer ? activityState.percentage : null,
        manualScoring: Object.assign({}, activityState.manualScoring, {
          completed: !!action.completedOn,
          inProgress: false,
          noScoringRequired: false,
          scoringStatus: ScoringStatus.Scoring
        })
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.percentageAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        percentage: action.percentage,
        ageResult: action.ageResult,
        age: action.age
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.manualScoreAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        percentage: action.percentage,
        manualScoring: {
          correct: action.correct,
          total: action.total,
          inProgress: action.inProgress,
          simpleScore: action.simpleScore,
          completed: true,
          scoringStatus: action.scoringStatus
        },
        ageResult: action.ageResult,
        age: action.age
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.updateFormFieldsAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        pdfFormFields: action.pdfFormFields,
        pdfFormCalculations: action.pdfFormCalculations
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.videoArchivedAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        videos: [...activityState.videos, action.videoGuid]
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.updateNotesAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        internalNote: action.internalNote
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.whiteboardDrawnAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      activityState = Object.assign({}, activityState, {
        hasWhiteboard: true
      });

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState },
        entityStateGuids: state.entityStateGuids
      };
    }

    return state;
  }),
  on(ActivityActions.timedActivityStartFirstAttempt, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const manualScoring = Object.assign({}, activityState.manualScoring, {
        timedActivityFirstAttemptStartTime: action.startDateTime
      });

      activityState = Object.assign({}, activityState, {
        manualScoring: manualScoring
      });
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: { ...state.entityState, [action.activityGuid]: activityState },
      entityStateGuids: state.entityStateGuids
    };
  }),
  on(ActivityActions.timedActivityFirstAttempt, (state, action) => {
    let activityState = state.entityState[action.activityGuid];
    if (activityState) {
      const manualScoring = Object.assign({}, activityState.manualScoring, {
        timedActivityFirstAttempt: action.correct
      });

      activityState = Object.assign({}, activityState, {
        manualScoring: manualScoring
      });
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: { ...state.entityState, [action.activityGuid]: activityState },
      entityStateGuids: state.entityStateGuids
    };
  }),
  on(ActivityActions.timedActivityStartSecondAttempt, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const manualScoring = Object.assign({}, activityState.manualScoring, {
        timedActivitySecondAttemptStartTime: action.startDateTime
      });

      activityState = Object.assign({}, activityState, {
        manualScoring: manualScoring
      });
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: { ...state.entityState, [action.activityGuid]: activityState },
      entityStateGuids: state.entityStateGuids
    };
  }),
  on(ActivityActions.timedActivitySecondAttempt, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    if (activityState) {
      const manualScoring = Object.assign({}, activityState.manualScoring, {
        timedActivitySecondAttempt: action.correct
      });

      activityState = Object.assign({}, activityState, {
        manualScoring: manualScoring
      });
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: { ...state.entityState, [action.activityGuid]: activityState },
      entityStateGuids: state.entityStateGuids
    };
  })
);
