import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CommonValidators {
  static hasUniqueCharacter(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const characters = [...control.value];
      for (const character of characters) {
        if (characters.filter(c => c === character).length === 1) {
          return null;
        }
      }
    }
    return { hasUniqueCharacter: { valid: false } };
  }
}
