@if (student) {
<div class="kip-card kip-card--summary">
  <div class="kip-card__header">
    <fa-icon class="me-2" size="lg" [fixedWidth]="true" [icon]="icons.studentDetailsTab" />
    <h2 class="fw-light">Summary</h2>
    @if (canEdit) {
    <a class="btn btn-link btn-link--small text-primary ms-auto" (click)="edit.emit()">Edit</a>
    }
  </div>
  <div class="kip-card__body">
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading">Student Name</div>
        <div class="kip-info__entry">{{ student | name }}</div>
      </div>
      <div class="col kip-info">
        <div class="kip-info__heading">Username</div>
        <div class="kip-info__entry">{{ student.userName }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading">Date of Birth</div>
        <div class="kip-info__entry">{{ (student.dateOfBirth | day) || '-' }}</div>
        <div class="kip-info__subtitle">{{ student.dateOfBirth | age }}</div>
      </div>
      <div class="col kip-info">
        <div class="kip-info__heading">School Grade</div>
        <div class="kip-info__entry">{{ student.grade }}</div>
        @if (student.school) {
        <div class="kip-info__subtitle">{{ student.school }}</div>
        }
      </div>
    </div>
    <div class="row">
      <div class="col kip-info">
        <div class="kip-info__heading">Timezone</div>
        <div class="kip-info__entry">{{ student.timeZone }}</div>
      </div>
      @if (student.region) {
      <div class="col kip-info">
        <div class="kip-info__heading">Curriculum Region</div>
        <div class="kip-info__entry">{{ student.region }} @if (student.region !== student.soundRegion) {
          /
          {{ student.soundRegion}}
          }</div>
      </div>
      }
    </div>
    @if (ranking; as ranking) {
    <div class="row">
      @if (ranking.startLessonRanking) {
      <div class="col kip-info">
        <div class="kip-info__heading">Start Lesson Rating</div>
        <div class="kip-info__entry"><kip-ranking [ranking]="ranking.startLessonRanking" /></div>
      </div>
      }
      @if (ranking.finishLessonRanking) {
      <div class="col kip-info">
        <div class="kip-info__heading">Finish Lesson Rating</div>
        <div class="kip-info__entry"><kip-ranking [ranking]="ranking.finishLessonRanking" /></div>
      </div>
      }
    </div>
    }
    @if (ranking; as ranking) {
    <div class="row">
      @if (ranking.tutorInternalRanking) {
      <div class="col kip-info">
        <div class="kip-info__heading">Tutor Internal Rating</div>
        <div class="kip-info__entry"><kip-ranking [ranking]="ranking.tutorInternalRanking" /></div>
      </div>
      }
    </div>
    }
  </div>
</div>
}