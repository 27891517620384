import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-subject-picker',
  templateUrl: './subject-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly subjects = toSignal(this.#sharedService.getSubjects(), { initialValue: [] });
  readonly name = input('');
  readonly label = input('');
  readonly subjectId = input.required<FormControl<number | null>>();
  readonly labelClass = input<string | undefined>(undefined);

}
