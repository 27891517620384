import { QuestionParametersGeneric, QuestionSummary } from '../../models';

export function convertGeneric(question: QuestionSummary<QuestionParametersGeneric>, genericInput: string, showAnswerOnly: boolean) {

  const propertyNames = Object.getOwnPropertyNames(question.parameters);
  let text = '';

  for (const propertyName of propertyNames) {

    const controlName = `<control control-id="${propertyName}"></control> +sdfsdfdfsdfsdfsdfsd`;
    const index = propertyNames.indexOf(propertyName);
    let value = '??';
    if (index !== -1) {
      const answer = question.answers[index];
      if (answer) {
        value = genericInput.replace('&nbsp;', answer.values[0].toString());
      }
    }

    if (!showAnswerOnly) {
      text = question.text;
      text = text.replace(controlName, value);
    } else {
      text += `<span class='kip-success-tutor-answer'>${value}</span>`;
    }
  }

  return text;
}
