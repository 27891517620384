import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { finalize, Subject, Subscription, tap } from 'rxjs';
import {
  fileUploadCompletionHandler,
  fileUploadingProgressHandler
} from 'service-lib';
import { getUIVersion } from 'ui-common-lib';

import { JiraRequest, JiraRequestType } from '../models';
import { JiraService } from '../services';

interface JiraData {
  description: string;
  requestType: JiraRequestType;
}

@Component({
  selector: 'kip-jira',
  templateUrl: './jira.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JiraComponent implements OnInit, OnDestroy {

  readonly #activeModal = inject(NgbActiveModal);
  readonly #jiraService = inject(JiraService);
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #requestType: JiraRequestType = JiraRequestType.Bug;
  #isSuccessReport = false;
  readonly #progress$ = new Subject<number>();
  #isUploadingFinished = false;
  #isUploadingImages = false;
  #result = '';
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get isSuccessReport() {
    return this.#isSuccessReport;
  }

  get jiraRequestType() {
    return this.jiraForm.getSafe(x => x.requestType);
  }

  get description() {
    return this.jiraForm.getSafe(x => x.description);
  }

  jiraForm!: FormGroupTypeSafe<JiraData>;

  get result() {
    return this.#result;
  }

  get isUploadingImages() {
    return this.#isUploadingImages;
  }

  get progress$(): Subject<number> {
    return this.#progress$;
  }

  get isUploadingFinished() {
    return this.#isUploadingFinished;
  }

  get uiVersion() {
    return getUIVersion();
  }

  requestTypes: { id: JiraRequestType, name: string }[] = [
    { id: JiraRequestType.Bug, name: 'Bug' },
    { id: JiraRequestType.NewFeature, name: 'New Feature' },
    { id: JiraRequestType.Problem, name: 'Problem' },
    { id: JiraRequestType.Suggestion, name: 'Suggestion' }
  ];
  invalidFileType = '';
  images: readonly File[] = [];
  disableSend = false;
  userTime = '';

  @Input({ required: true }) requestText = 'Report Issue';

  @Input({ required: true }) initialDescription = '';

  @Input({ required: true }) set requestType(value: JiraRequestType) {
    this.#requestType = value;
    if (this.jiraForm) {
      this.jiraRequestType.setValue(value);
    }
  }

  get requestType() {
    return this.#requestType;
  }

  @Input({ required: true }) uId = '';
  @Input({ required: true }) metaData: { [prop: string]: string } = {};

  @Input({ required: true }) jiraRequestTypeVisible = false;

  ngOnInit() {

    /*eslint-disable @typescript-eslint/unbound-method */
    this.jiraForm = this.#fb.group<JiraData>({
      requestType: new FormControl<JiraRequestType>(this.#requestType, Validators.required),
      description: new FormControl<string | null>(null, Validators.required)
    });
    /*eslint-enable @typescript-eslint/unbound-method */

    this.description.setValue(this.initialDescription);
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  close() {
    this.#activeModal.close();
  }

  submit() {
    if (this.jiraForm.invalid) {
      markControlsAsTouched(this.jiraForm);
      return;
    }

    const request: JiraRequest = {
      requestType: this.jiraRequestType.value,
      description: this.description.value,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      metaData: Object.assign(this.metaData, { 'UI Version': this.uiVersion }),
      uId: this.uId === '' ? null : this.uId,
      url: window.location.href
    };

    this.#isUploadingImages = true;
    this.#subscriptions.push(
      this.#jiraService.send(this.images, request)
        .pipe(
          finalize(() => {
            this.#isUploadingImages = false;
            this.#isUploadingFinished = false;
            this.#changeDetectorRef.markForCheck();
          }),
          tap(response => this.#progress$.next(fileUploadingProgressHandler(response as HttpProgressEvent))))
        .subscribe({
          next: response => {
            this.#isUploadingFinished = fileUploadCompletionHandler(response as HttpProgressEvent, this.#isUploadingFinished);

            if (response.type === HttpEventType.Response) {
              if (response.body) {
                this.#isSuccessReport = response.body.jiraId !== '';
                this.#result = this.#isSuccessReport ? response.body.jiraId : response.body.error;
              }
              const utc = moment.utc();
              const local = utc.local();
              this.disableSend = true;
              this.userTime = local.format('h:mm a');
              this.#changeDetectorRef.markForCheck();
            }
          },
          error: (error: unknown) => {
            this.jiraForm.handleServerErrors(error);
            this.#changeDetectorRef.markForCheck();
          }
        }));
  }
}
