import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, REPORT_PROGRESS_OPTION } from 'service-lib';

export interface AdHocUploadSummary {
  readonly tutor: string;
  readonly student: string;
  readonly subject: string;
}

@Injectable({ providedIn: 'root' })
export class AdHocActivityService extends HttpService {

  getAdHocUploadSummary(lessonGuid: string): Observable<AdHocUploadSummary> {
    return this.get<AdHocUploadSummary>(`adhoc/lesson/${lessonGuid}`);
  }

  createAdHocActivity(activityName: string, lessonGuid: string, file: File, isHomework: boolean): Observable<HttpProgressEvent | HttpResponse<string>> {
    const formData = new FormData();
    formData.append('lessonGuid', lessonGuid);
    formData.append('file', file);
    return this.post<HttpProgressEvent | HttpResponse<string>>(
      `adhoc/upload-image/${lessonGuid}/${activityName === '' ? 'none' : activityName}?isHomework=${isHomework}`,
      formData,
      undefined,
      REPORT_PROGRESS_OPTION
    );
  }
}
