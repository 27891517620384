import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  ElementRef, EventEmitter, inject,
  Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { videoEffectImage, VideoEffectType } from 'ui-common-lib';

import { Caption, OpenTokErrorCodes, SessionExtended, StatsQuality, TestStats } from '../models';
import { OpentokService } from '../opentok.service';

@Component({
  selector: 'kip-opentok-video-publisher',
  templateUrl: './publisher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PublisherComponent implements AfterViewInit, OnDestroy {

  readonly #opentokService = inject(OpentokService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #unableToPublishLog: string[] = [];
  #testInterval: number | undefined;
  #accessAllowedSent = false;
  #accessDeniedSent = false;
  #audio = false;
  #video = false;
  #audioSource: string | undefined;
  #videoSource: string | undefined;
  #session: OT.Session | undefined;
  #prevStats: OT.SubscriberStats | undefined;
  #movingAvgAudioLevel: number | null = null;
  #online = true;
  #testPerformance = false;
  #subscriptions: Subscription[] = [];
  readonly #kilobyte = 1024;
  #publishing = false;
  #attemptingPublish = false;
  #videoEffectType: VideoEffectType | undefined;

  publisher: OT.Publisher | undefined;

  @Input() observerId: number | undefined;
  @Input() tutorId: number | undefined;
  @Input() studentId: number | undefined;
  @Input() publisherName: string | undefined;
  @Input() publishCaptions = false;

  /* eslint-disable kip/no-unused-public-members */

  @Input() set testPerformance(value: boolean) {
    this.#testPerformance = value;
    if (!value) {
      this.#stopTest();
    }
  }

  get testPerformance() {
    return this.#testPerformance;
  }

  @Input() set online(value: boolean) {
    if (this.#online !== value) {
      this.#online = value;
      if (!value) {
        this.#stopPublishing();
      } else {
        this.#tryPublish();
      }
    }
  }

  get online() {
    return this.#online;
  }

  @Input({ required: true }) set session(value: OT.Session | undefined) {
    if (this.#session !== value) {
      this.#session = value;
      this.#tryPublish();
    }
  }

  get session() {
    return this.#session;
  }

  @Input({ required: true }) set audio(value: boolean) {
    this.#audio = value;
    if (this.publisher) {
      OT.getDevices((_error, devices) => {
        if (devices?.some(s => s.kind === 'audioInput')) {
          if (this.publisher) {
            this.publisher.publishAudio(value);
          }
        } else {
          if (this.publisher) {
            this.publisher.publishAudio(false);
          }
          this.#audio = value;
          console.log(`Cannot set audio ${value} - no audio source`);
        }
        this.#changeDetectorRef.detectChanges();
      });
    }
  }

  get audio() {
    return this.#audio;
  }

  @Input({ required: true }) set video(value: boolean) {
    this.#video = value;
    if (this.publisher) {
      OT.getDevices((_error, devices) => {
        if (devices?.some(s => s.kind === 'videoInput')) {
          if (this.publisher) {
            this.publisher.publishVideo(value);
          }
        } else {
          if (this.publisher) {
            this.publisher.publishVideo(false);
          }
          this.#video = value;
          console.log(`Cannot set video ${value} - no video source`);
        }
        this.#changeDetectorRef.detectChanges();
      });
    }
  }

  get video() {
    return this.#video;
  }

  @Input({ required: true }) set videoDevice(id: string | undefined) {
    if (id && id !== this.#videoSource) {
      OT.getDevices((_error, devices) => {
        if (devices?.find(s => s.kind === 'videoInput' && s.deviceId === id)) {
          this.#videoSource = id;
          if (this.publisher) {
            this.publisher.setVideoSource(id);
          }
        }
        this.#changeDetectorRef.detectChanges();
      });
    }
  }

  get videoDevice() {
    return this.#videoSource;
  }

  @Input({ required: true }) set audioDevice(id: string | undefined) {
    if (id && id !== this.#audioSource) {
      OT.getDevices((_error, devices) => {
        if (devices?.find(s => s.kind === 'audioInput' && s.deviceId === id)) {
          this.#audioSource = id;
          if (this.publisher) {
            this.publisher.setAudioSource(id);
          }
        }
        this.#changeDetectorRef.detectChanges();
      });
    }
  }

  get audioDevice() {
    return this.publisher?.getAudioSource().id;
  }

  @Input() mirrorStream = false;

  @Input() set effectType(effectType: VideoEffectType | undefined) {
    if (this.#videoEffectType !== effectType) {
      this.#videoEffectType = effectType;
      this.#applyVideoEffect();
    }
  }

  get effectType() {
    return this.#videoEffectType;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('publisherDiv', { static: true }) publisherDiv: ElementRef<HTMLDivElement> | undefined;

  @Output() readonly audioDeviceChange = new EventEmitter<string | undefined>();
  @Output() readonly videoDeviceChange = new EventEmitter<string | undefined>();
  @Output() readonly accessAllowed = new EventEmitter<void>();
  @Output() readonly accessDenied = new EventEmitter<void>();
  @Output() readonly unableToPublish = new EventEmitter<{ name: string, message: string }>();
  @Output() readonly testSubscribeFailure = new EventEmitter<{ name: string, message: string }>();
  @Output() readonly streamDestroyed = new EventEmitter<string>();
  @Output() readonly statsChanged = new EventEmitter<TestStats>();
  @Output() readonly audioLevelChange = new EventEmitter<number>();
  @Output() readonly imageData = new EventEmitter<string>();
  @Output() readonly captionReceive = new EventEmitter<Caption>;

  constructor() {
    this.#publishing = false;
    this.#subscriptions.push(this.#opentokService.refreshPublisherRequested.subscribe(() => this.#refresh()));
  }

  ngAfterViewInit() {
    this.#tryPublish();
  }

  ngOnDestroy() {
    this.#stopPublishing();
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  getImageData() {
    if (this.publisher) {
      const imgData = this.publisher.getImgData();
      if (imgData) {
        this.imageData.emit(imgData);
      }
    }
  }

  #applyVideoEffect() {
    if (this.publisher) {
      let videoFilter: OT.VideoFilter | undefined;
      /* eslint-disable @typescript-eslint/switch-exhaustiveness-check */
      switch (this.#videoEffectType) {
        case VideoEffectType.Blur:
          videoFilter = {
            type: 'backgroundBlur',
            blurStrength: 'low'
          };
          break;
        case VideoEffectType.Image1:
        case VideoEffectType.Image2:
        case VideoEffectType.Image3:
        case VideoEffectType.Image4:
        case VideoEffectType.Image5:
          videoFilter = {
            type: 'backgroundReplacement',
            backgroundImgUrl: videoEffectImage[this.#videoEffectType]
          };
          break;
        case VideoEffectType.Pixelate:
          videoFilter = {
            type: 'backgroundBlur',
            blurStrength: 'high'
          };
          break;
      }
      /* eslint-enable @typescript-eslint/switch-exhaustiveness-check */
      if (videoFilter) {
        this.publisher.applyVideoFilter(videoFilter);
      } else {
        this.publisher.clearVideoFilter();
      }
    }
  }

  #refresh() {
    this.#stopPublishing(false);
    this.#accessAllowedSent = false;
    this.#accessDeniedSent = false;
    this.#tryPublish();
  }

  #updateAudioLevel(event: { audioLevel: number }) {
    this.#movingAvgAudioLevel = this.#movingAvgAudioLevel === null || this.#movingAvgAudioLevel <= event.audioLevel ? event.audioLevel : 0.7 * this.#movingAvgAudioLevel + 0.3 * event.audioLevel;

    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    let logLevel = Math.log10(this.#movingAvgAudioLevel) / Math.LN10 / 1.5 + 1;
    logLevel = Math.min(Math.max(logLevel, 0), 1);
    this.audioLevelChange.emit(logLevel);
  }

  // quality calculations from here
  // https://github.com/opentok/opentok-network-test

  #determineVideoQualityAndResolution(videoBitRate: number, videoPacketLossRatio: number) {
    let videoQuality = StatsQuality.Bad;
    let resolution = '???';
    if (videoBitRate > 150 * this.#kilobyte && videoPacketLossRatio < 3) {
      videoQuality = StatsQuality.Acceptable;
      resolution = '320x240 @ 30';
    } else if (videoBitRate > 200 * this.#kilobyte && videoPacketLossRatio < 3) {
      videoQuality = StatsQuality.Acceptable;
      resolution = '352x288 @ 30';
    } else if (videoBitRate > 250 * this.#kilobyte && videoPacketLossRatio < 3) {
      videoQuality = StatsQuality.Acceptable;
      resolution = '640x480 @ 30';
    } else if (videoBitRate > 350 * this.#kilobyte && videoPacketLossRatio < 3) {
      videoQuality = StatsQuality.Acceptable;
      resolution = '1280x720 @ 30';
    } else if (videoBitRate > 300 * this.#kilobyte && videoPacketLossRatio < 0.5) {
      videoQuality = StatsQuality.Excellent;
      resolution = '320x240 @ 30';
    } else if (videoBitRate > 400 * this.#kilobyte && videoPacketLossRatio < 0.5) {
      videoQuality = StatsQuality.Excellent;
      resolution = '352x288 @ 30';
    } else if (videoBitRate > 600 * this.#kilobyte && videoPacketLossRatio < 0.5) {
      videoQuality = StatsQuality.Excellent;
      resolution = '640x480 @ 30';
    } else if (videoBitRate > 1000 * this.#kilobyte && videoPacketLossRatio < 0.5) {
      videoQuality = StatsQuality.Excellent;
      resolution = '1280x720 @ 30';
    }

    return { quality: videoQuality, resolution: resolution };
  }

  #determineAudioQuality(audioBitRate: number, audioPacketLossRatio: number) {
    let audioQuality = StatsQuality.Bad;
    if (audioBitRate > 25 * this.#kilobyte && audioPacketLossRatio < 5) {
      audioQuality = StatsQuality.Acceptable;
    } else if (audioBitRate > 30 * this.#kilobyte && audioPacketLossRatio < 0.5) {
      audioQuality = StatsQuality.Excellent;
    }

    return audioQuality;
  }

  /**
   * Stop publishing
   * @param reset True to reset one way bound data
   */
  #stopPublishing(reset = true) {
    if (this.session && this.publisher) {
      if (reset && this.publisher.accessAllowed) {
        this.audio = false;
        this.video = false;
      }
      if (this.#publishing) {
        this.session.unpublish(this.publisher);
      }
      this.publisher.off();
      this.publisher.destroy();
      this.#publishing = false;
      this.publisher = undefined;
      if (reset) {
        this.session = undefined;
      }
    }
  }

  #sendUnableToPublish(name: string, message: string) {
    // block sending errors more than once as open tok can sometime spam
    if (!this.#unableToPublishLog.includes(name)) {
      this.unableToPublish.emit({ name: name, message: message });
      this.#unableToPublishLog.push(name);
    }
  }

  #tryPublish() {
    if (this.session && this.publisherDiv && this.#online && !this.#publishing && !this.#attemptingPublish) {
      const OT = this.#opentokService.getOT();

      this.publisher = OT.initPublisher(this.publisherDiv.nativeElement,
        {
          insertMode: 'append',
          resolution: '320x180',
          frameRate: 15,
          width: '100%',
          height: '100%',
          showControls: false,
          audioSource: this.#audioSource,
          videoSource: this.#videoSource,
          mirror: this.mirrorStream,
          publishCaptions: true,
          name: this.publisherName
        }, error => {
          if (error && // don't send these as already covered by denied

            error.name !== OpenTokErrorCodes.AccessDenied) {
            this.#sendUnableToPublish(error.name, error.message);
          }
        });

      this.publisher.on({
        streamCreated: (event: { stream: OT.Stream }) => {
          if (this.testPerformance) {
            this.#test(event.stream);
          }
          if (this.publishCaptions) {
            const subscriber = this.session?.subscribe(event.stream,
              document.createElement('div'),
              {
                audioVolume: 0,
                testNetwork: true
              });
            if (subscriber) {
              subscriber.subscribeToCaptions(true).then(() => {
                // do nothing
              });
              subscriber.on({
                captionReceived: (caption: Caption) => {
                  this.captionReceive.emit(caption);
                }
              });
            }
          }
          if (this.publisher) {
            const videoSource = this.publisher.getVideoSource();
            if (videoSource?.deviceId) {
              this.#videoSource = videoSource.deviceId;
              this.videoDeviceChange.emit(videoSource.deviceId);
            }
            const audioSource = this.publisher.getAudioSource();
            if (audioSource) {
              const audioTrackSettings = audioSource.getSettings();
              this.#audioSource = audioTrackSettings.deviceId;
              this.audioDeviceChange.emit(audioTrackSettings.deviceId);
            }
          }
          this.#applyVideoEffect();
          this.#changeDetectorRef.detectChanges();
        },
        streamDestroyed: (err: { reason: string | undefined }) => {
          this.#stopTest();
          this.streamDestroyed.emit(err.reason);
          this.#changeDetectorRef.detectChanges();
        },
        accessAllowed: () => {

          // Prevent spamming messages
          // This has occurred sometimes

          if (!this.#accessAllowedSent) {
            this.accessAllowed.emit();
            this.#accessAllowedSent = true;
          }
          this.#changeDetectorRef.detectChanges();
        },
        accessDenied: () => {

          // Prevent spamming messages
          // This has occurred sometimes

          if (!this.#accessDeniedSent) {
            this.accessDenied.emit();
            this.#accessDeniedSent = true;
          }

          this.#changeDetectorRef.detectChanges();
        },
        audioLevelUpdated: (event: { audioLevel: number }) => {
          this.#updateAudioLevel(event);
          this.#changeDetectorRef.detectChanges();
        }
      });

      if ((this.session as SessionExtended).isConnected()) {
        this.#publish();
      }
      this.session.on({ sessionConnected: () => this.#publish() });
    }

  }

  #stopTest() {
    window.clearInterval(this.#testInterval);
    this.#testInterval = undefined;
  }

  #test(stream: OT.Stream) {
    if (this.session && this.publisherDiv) {
      const subscriber = this.session.subscribe(stream, this.publisherDiv.nativeElement,
        {
          insertMode: 'append',
          height: '0',
          width: '0',
          showControls: false,
          testNetwork: true,
          subscribeToAudio: stream.hasAudio && this.#audio,
          subscribeToVideo: stream.hasVideo && this.#video
        }, err => {
          if (err) {
            this.testSubscribeFailure.emit({ name: err.name, message: err.message });
          }
        });
      subscriber.subscribeToCaptions(true).then(() => {
        // do nothing
      });
      this.#testInterval = window.setInterval(() => {
        if (subscriber) {
          subscriber.getStats((error, stats) => {
            if (error) {
              this.testSubscribeFailure.emit({ name: error.name, message: error.message });
              return;
            }
            if (this.#prevStats && stats) {

              // Determine video quality

              let videoPacketLossRatio = 0;
              let videoBitRate = 0;
              let videoQualityAndResolution: { quality: StatsQuality, resolution: string }
                = { quality: StatsQuality.Unavailable, resolution: '???' };
              if (stream.hasVideo && this.#video) {
                videoPacketLossRatio = stats.video.packetsLost /
                  (stats.video.packetsLost + stats.video.packetsReceived);
                videoBitRate = 8 * (stats.video.bytesReceived - this.#prevStats.video.bytesReceived);
                videoQualityAndResolution = this.#determineVideoQualityAndResolution(videoBitRate, videoPacketLossRatio);
              }

              // Determine Audio Quality

              let audioQuality = StatsQuality.Unavailable;
              let audioPacketLossRatio = 0;
              let audioBitRate = 0;
              if (stream.hasAudio && this.#audio) {
                audioPacketLossRatio = stats.audio.packetsLost /
                  (stats.audio.packetsLost + stats.audio.packetsReceived);
                audioBitRate = 8 * (stats.audio.bytesReceived - this.#prevStats.audio.bytesReceived);
                audioQuality = this.#determineAudioQuality(audioBitRate, audioPacketLossRatio);
              }

              const statsEmit: TestStats = {
                audioQuality: audioQuality,
                videoQuality: videoQualityAndResolution.quality,
                supportedResolution: videoQualityAndResolution.resolution,
                videoPacketLossRatio: videoPacketLossRatio,
                videoBitRate: videoBitRate,
                audioPacketLossRatio: audioPacketLossRatio,
                audioBitRate: audioBitRate
              };

              this.statsChanged.emit(statsEmit);
            }
            this.#prevStats = stats;
          });
        } else {
          this.#stopTest();
        }
      }, 3000);
    }
  }

  #publish() {
    if (this.session && this.publisher) {
      if (this.session.capabilities.publish === 1) {
        this.#attemptingPublish = true;
        this.session.publish(this.publisher, (err: OT.OTError | undefined) => {
          this.#attemptingPublish = false;
          if (err) {
            // don't send these as already covered by denied

            if (err.name !== OpenTokErrorCodes.AccessDenied) {
              this.#sendUnableToPublish(err.name, err.message);
            }
          } else {
            this.audio = this.#audio;
            this.video = this.#video;
            this.#publishing = true;
          }
        });
      } else {
        this.#sendUnableToPublish('NOT_CAPABLE', 'Cannot publish - not capable');
      }
    }
  }

}
