import { createAction, props } from '@ngrx/store';

import { IdleStatus } from '../../../shared';
import { Session } from '../../models';

enum SessionActionTypes {
  AddCaption = 'Tutor > Lesson > Add Caption',
  AddDropInLessonAction = 'Tutor > Session > Drop In Lesson Accepted',
  BroadcastingPublishDenied = 'Tutor > Session > Broadcasting Publish Denied',
  BroadcastingPublishFailure = 'Tutor > Session > Broadcasting Publish Failure',
  Close = 'Tutor > Session > Close',
  DisableCaptions = 'Tutor > Session > Disable Captions',
  Load = 'Tutor > Session > Load',
  Error = 'Tutor > Session > Error',
  LogCaption = 'Tutor > Lesson > Log Caption',
  Open = 'Tutor > Session > Open',
  PublishAllowed = 'Tutor > Session > Publish Allowed',
  PublishDenied = 'Tutor > Session > Publish Denied',
  PublishFailure = 'Tutor > Session > Publish Failure',
  TogglePublishVideo = 'Tutor > Session > Toggle Publish Video',
  TogglePublishAudio = 'Tutor > Session > Toggle Publish Audio',
  PublishAudioOn = 'Tutor > Session > Publish Audio On',
  PublishVideoOn = 'Tutor > Session > Publish Video On',
  ScreenSharePublishAllowed = 'Tutor > Session > Screen Share Publish Allowed',
  ScreenSharePublishDenied = 'Tutor > Session > Screen Share Publish Denied',
  ScreenSharePublishFailure = 'Tutor > Session > Screen Share Publish Failure',
  SessionDisconnect = 'Tutor > Session > Disconnect',
  StartTeachingClass = 'Tutor > Session > Start Teaching Class',
  StopTeachingClass = 'Tutor > Session > Stop Teaching Class',
  StartBroadcastingObservers = 'Tutor > Session > Start Broadcasting Observers',
  StopBroadcastingObservers = 'Tutor > Session > Stop Broadcasting Observers',
  Reconnect = 'Tutor > Session > Reconnect',
  StreamDestroyed = 'Tutor > Session > Stream Destroyed',
  ScreenShareStreamDestroyed = 'Tutor > Session > Screen Share Stream Destroyed',
  UpdateDropInSessionAvailability = 'Tutor > Session > Update Drop in Availability',
  UpdateIdleStatus = 'Tutor > Session > Update Idle Status',
  TutorLogout = 'Tutor > Session > Tutor Logout',
  TutorImageData = 'Tutor > Session > Image Data',
  TutorPhotoLoad = 'Tutor > Session > Tutor Photo Load',
  TutorPhotoUpdate = 'Tutor > Session > Tutor Photo Update',
  ChangeToAssessment = 'Tutor > Session > Change To Assessment',
  UpdateGeneralNotes = 'Tutor > Session > Update General Notes',
  UpdateNotes = 'Tutor > Session > Update Notes',
  UpdateSignalRConnectionState = 'Tutor > Session > SignalR > Connection'
}

export const errorAction = createAction(SessionActionTypes.Error);

export const disableCaptionsAction = createAction(
  SessionActionTypes.DisableCaptions,
  props<{
    readonly sessionId: number;
    readonly statusCode: number;
  }>()
);

export const logCaptionAction = createAction(
  SessionActionTypes.LogCaption,
  props<{
    readonly sessionId: number;
    readonly studentId: number | null;
    readonly observerUserId: number | null;
    readonly tutorId: number | null;
    readonly name: string;
    readonly caption: string;
  }>()
);

export const addCaptionAction = createAction(
  SessionActionTypes.AddCaption,
  props<{
    readonly name: string;
    readonly caption: string;
    readonly warning: boolean;
    readonly timestamp: Date;
    readonly sent: boolean;
  }>()
);

export const updateGeneralNotesAction = createAction(
  SessionActionTypes.UpdateGeneralNotes,
  props<{
    readonly sessionId: number;
  }>()
);

export const togglePublishVideoAction = createAction(
  SessionActionTypes.TogglePublishVideo,
  props<{
    readonly sessionId: number;
  }>()
);

export const togglePublishAudioAction = createAction(
  SessionActionTypes.TogglePublishAudio,
  props<{
    readonly sessionId: number;
  }>()
);

export const publishAudioOnAction = createAction(
  SessionActionTypes.PublishAudioOn,
  props<{
    readonly sessionId: number;
  }>()
);

export const publishVideoOnAction = createAction(
  SessionActionTypes.PublishVideoOn,
  props<{
    readonly sessionId: number;
  }>()
);

export const loadAction = createAction(
  SessionActionTypes.Load,
  props<{
    readonly session: Session;
  }>()
);

export const openAction = createAction(
  SessionActionTypes.Open,
  props<{
    readonly sessionId: number;
  }>()
);

export const addDropInLessonAction = createAction(
  SessionActionTypes.AddDropInLessonAction,
  props<{
    readonly sessionId: number;
    readonly lessonId: number;
  }>()
);

export const changeToAssessmentAction = createAction(
  SessionActionTypes.ChangeToAssessment,
  props<{
    readonly sessionId: number;
  }>()
);

export const sessionDisconnectAction = createAction(SessionActionTypes.SessionDisconnect,
  props<{
    readonly logoutUser: boolean;
    readonly showLogoutMessage: boolean | false;
    readonly logoutMessage: string | undefined;
  }>());

export const tutorLogoutAction = createAction(SessionActionTypes.TutorLogout,
  props<{
    readonly showLogoutMessage: boolean | false;
    readonly logoutMessage: string | undefined;
  }>());

export const tutorPhotoLoadAction = createAction(
  SessionActionTypes.TutorPhotoLoad,
  props<{
    readonly sessionId: number;
    readonly tutorId: number;
  }>()
);

export const tutorPhotoUpdateAction = createAction(
  SessionActionTypes.TutorPhotoUpdate,
  props<{
    readonly sessionId: number;
    readonly tutorPhotoUrl: string;
    readonly tutorPhotoDefault: boolean;
  }>()
);

export const updateNotesAction = createAction(
  SessionActionTypes.UpdateNotes,
  props<{
    readonly sessionId: number;
    readonly internalNote: string;
    readonly parentNote: string;
    readonly internalNoteRanking: number | null;
    readonly parentNoteRanking: number | null;
  }>()
);

export const screenSharePublishFailureAction = createAction(
  SessionActionTypes.ScreenSharePublishFailure,
  props<{
    readonly sessionId: number;
    readonly name: string;
    readonly message: string;
  }>()
);

export const screenShareStreamDestroyedAction = createAction(
  SessionActionTypes.ScreenShareStreamDestroyed,
  props<{
    readonly sessionId: number;
    readonly reason: string;
  }>()
);

export const publishFailureAction = createAction(
  SessionActionTypes.PublishFailure,
  props<{
    readonly sessionId: number;
    readonly name: string;
    readonly message: string;
  }>()
);

export const streamDestroyedAction = createAction(
  SessionActionTypes.StreamDestroyed,
  props<{
    readonly sessionId: number;
    readonly reason: string;
  }>()
);

export const tutorImageDataAction = createAction(
  SessionActionTypes.TutorImageData,
  props<{
    readonly sessionId: number;
    readonly imageData: string;
  }>()
);

export const screenSharePublishAllowedAction = createAction(
  SessionActionTypes.ScreenSharePublishAllowed,
  props<{
    readonly sessionId: number;
  }>()
);

export const screenSharePublishDeniedAction = createAction(
  SessionActionTypes.ScreenSharePublishDenied,
  props<{
    readonly sessionId: number;
  }>()
);

export const publishAllowedAction = createAction(
  SessionActionTypes.PublishAllowed,
  props<{
    readonly sessionId: number;
  }>()
);

export const publishDeniedAction = createAction(
  SessionActionTypes.PublishDenied,
  props<{
    readonly sessionId: number;
  }>()
);

export const reconnectAction = createAction(SessionActionTypes.Reconnect);

export const updateSignalRConnectionStateAction = createAction(
  SessionActionTypes.UpdateSignalRConnectionState,
  props<{
    readonly connected: boolean;
  }>()
);

export const closeAction = createAction(
  SessionActionTypes.Close,
  props<{
    readonly sessionId: number;
  }>()
);

export const updateIdleStatusAction = createAction(
  SessionActionTypes.UpdateIdleStatus,
  props<{
    readonly sessionId: number;
    readonly idleStatus: IdleStatus;
  }>()
);

export const updateDropInSessionAvailabilityAction = createAction(
  SessionActionTypes.UpdateDropInSessionAvailability,
  props<{
    readonly sessionId: number;
    readonly isAvailable: boolean;
  }>()
);

export const startTeachingClassAction = createAction(
  SessionActionTypes.StartTeachingClass,
  props<{
    readonly sessionId: number;
  }>()
);

export const stopTeachingClassAction = createAction(
  SessionActionTypes.StopTeachingClass,
  props<{
    readonly sessionId: number;
  }>()
);

export const broadcastingPublishDeniedAction = createAction(
  SessionActionTypes.BroadcastingPublishDenied,
  props<{
    readonly sessionId: number;
  }>()
);

export const broadcastingPublishFailureAction = createAction(
  SessionActionTypes.BroadcastingPublishFailure,
  props<{
    readonly sessionId: number;
    readonly name: string;
    readonly message: string;
  }>()
);

export const startBroadcastingObserversAction = createAction(
  SessionActionTypes.StartBroadcastingObservers,
  props<{
    readonly streamId: string;
    readonly sessionId: number;
  }>()
);

export const stopBroadcastingObserversAction = createAction(
  SessionActionTypes.StopBroadcastingObservers,
  props<{
    readonly sessionId: number;
  }>()
);

