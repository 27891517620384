import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

@Component({
  selector: 'kip-html-modal',
  templateUrl: './html-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlModalComponent implements OnInit, OnDestroy {

  readonly #activeModal = inject(NgbActiveModal);

  readonly icons = Icons;

  @Input({ required: true }) title = '';
  @Input({ required: true }) html = '';
  @Input({ required: true }) showPrint = false;
  @Input({ required: true }) fixedHeight = true;

  ngOnInit() {
    if (this.showPrint) {
      document.body.className += ' modal-printing';
    }
  }

  ngOnDestroy() {
    document.body.className = document.body.className.replace(/modal-printing/g, '');
  }

  close() {
    this.#activeModal.close();
  }

  print() {
    window.print();
  }
}
