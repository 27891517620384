<kip-paged-list listType="session schedules" [initialPageSize]="resultsPerPage" [isSearchable]="true"
  [listHeaderTemplate]="listHeaderTemplate" [listOptionButtonTemplate]="listOptionButtonTemplate"
  [pageLoading]="currentLoad" [sortBy]="sortBy" [(filter)]="filter" (pageSelected)="loadPage($event)">
  <ng-template #listHeaderTemplate>
    <legend class="mb-2 mb-lg-0">{{ sessionSelectTitle }}</legend>
  </ng-template>
  <ng-template #listOptionButtonTemplate>
    <button class="ms-2 btn btn-outline-secondary kip-view-mode-button" type="button"
      [class.kip-view-mode-button--is-selected]="viewType === viewTypes.List" (click)="viewTypeChanged(viewTypes.List)">
      <fa-icon [icon]="icons.view.list" />
    </button>
    <button class="ms-2 btn btn-outline-secondary kip-view-mode-button" type="button"
      [class.kip-view-mode-button--is-selected]="viewType === viewTypes.Grid" (click)="viewTypeChanged(viewTypes.Grid)">
      <fa-icon [icon]="icons.view.grid" />
    </button>

    <div class="ms-2 btn btn-outline-secondary kip-view-mode-button">
      <kip-paged-filter columnName="Day" id="dayGroupOptions" [sortColumnKey]="sortColumnKey.Day"
        [value]="filter.weekday" [values]="daysOfWeek" [(sortBy)]="sortBy"
        (outputValue)="updateWeekDayFilter($event)" />
    </div>

    <div class="ms-2 btn btn-outline-secondary kip-view-mode-button">
      <kip-paged-filter columnName="Time" id="timeGroupOptions" [sortColumnKey]="sortColumnKey.Time"
        [(sortBy)]="sortBy" />
    </div>

    <div class="ms-2 btn btn-outline-secondary kip-view-mode-button">
      <kip-paged-filter columnName="Centre" id="centreGroupOptions" [sortColumnKey]="sortColumnKey.Centre"
        [value]="filter.centreId" [values]="centres" [(sortBy)]="sortBy" (outputValue)="updateCentreFilter($event)" />
    </div>

    <div class="ms-2 btn btn-outline-secondary kip-view-mode-button">
      <kip-paged-filter columnName="Tutor" id="tutorGroupOptions" [sortColumnKey]="sortColumnKey.Tutor"
        [value]="filter.tutorId" [values]="tutors" [(sortBy)]="sortBy" (outputValue)="updateTutorFilter($event)" />
    </div>

  </ng-template>
  @if (sessionSchedules) {
  @if (viewType === viewTypes.List) {
  <kip-session-picker-list [selectedSessionSchedule]="selectedSessionSchedule"
    [sessionSchedules]="sessionSchedules.items" (selectSessionSchedule)="selectSessionSchedule.emit($event)" />
  } @else {
  <kip-session-picker-grid [selectedSessionSchedule]="selectedSessionSchedule"
    [sessionSchedules]="sessionSchedules.items" [studentName]="studentName"
    (selectSessionSchedule)="selectSessionSchedule.emit($event)" />
  }
  }
</kip-paged-list>