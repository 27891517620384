import { Pipe, PipeTransform } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';

import { ageObject } from '../helpers/age-object';
import { Age } from '../models';

@Pipe({
  name: 'ageObject'
})
export class AgeObjectPipe implements PipeTransform {
  transform(date: DateStruct | null | undefined, effectiveDate?: Date | DateStruct | string | null  ): Age | undefined {
    return ageObject(date, effectiveDate);
  }
}
