@if (helpActivity && question) {
<div class="h-100 mh-100 d-flex flex-column flex-fill">
  <div class="card flex-fill h-100">
    <div class="card-body d-flex flex-row p-0 h-100">
      <kip-question-navigator [activeQuestionIndex]="questionData?.index" [items]="navigation"
        (navigate)="onNavigate($event)" />
      <div class="position-relative d-flex flex-column flex-fill">
        <div class="d-flex flex-row flex-fill p-2">
          <kip-question class="flex-fill" [allowQuit]="true" [question]="question" [readonly]="false"
            [regionId]="regionId" (quit)="quitActivity()" (validated)="updated($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
}