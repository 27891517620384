import { Pipe, PipeTransform } from '@angular/core';

import { idleLogoutTimeInSeconds, IdleStatus, idleThresholdTimeInSeconds, idleWarnTimeInSeconds, timeAllowedAfterLessonEndInMinutes } from '../../shared';

@Pipe({
  name: 'idleText'
})
export class IdleStatusTextPipe implements PipeTransform {

  transform(idleStatus: IdleStatus): string {
    switch (idleStatus) {
      case IdleStatus.Active:
        return 'Active - tutor working';
      case IdleStatus.Idle:
        return `Idle - tutor has not interacted with mouse or keyboard for ${idleThresholdTimeInSeconds}s`;
      case IdleStatus.ActiveLessonOver:
        return `Active - tutor working - lesson over by ${timeAllowedAfterLessonEndInMinutes} minutes`;
      case IdleStatus.IdleLessonOver:
        return `Idle - tutor has not interacted with mouse or keyboard for ${idleThresholdTimeInSeconds}s - lesson over by ${timeAllowedAfterLessonEndInMinutes} minutes`;
      case IdleStatus.IdleLessonOverLogoutNow:
        return `Idle - tutor has not interacted with mouse or keyboard for ${idleLogoutTimeInSeconds}s - lesson over by ${timeAllowedAfterLessonEndInMinutes} minutes`;
      default:
        return `Idle - tutor has not interacted with mouse or keyboard for ${idleWarnTimeInSeconds}s - lesson over by ${timeAllowedAfterLessonEndInMinutes} minutes`;
    }
  }
}
