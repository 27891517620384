<div class="card border-0">
  <div class="card-header cursor-pointer d-flex flex-row justify-content-between bg-light border-0 py-3"
    (click)="expand = !expand">
    <div class="flex-column text-start">
      {{resultType.title}}

      <fa-icon [icon]="icons.information.solid" [ngbTooltip]="subjects" />
    </div>
    <div class="flex-column text-end">
      @if (entered) {
      <fa-icon class="text-success" [fixedWidth]="true" [icon]="icons.accepted" />
      }
      @if (!entered) {
      <fa-icon [fixedWidth]="true" [icon]="expand ? icons.chevrons.up : icons.chevrons.down" />
      }
    </div>
  </div>
  @if (expand) {
  <div class="card-body">
    <div class="row">
      <div [ngClass]="allowNotes?'col-xl-6':'col-12'">
        @if (resultType.gradeRequired) {
        <div class="kip-form-group mb-0">
          <label for="gradeId">Level <span class="ms-2 small">{{ gradeId.value | gradeDifference
              :grade }}</span></label>
          <kip-grade-picker id="gradeId" [gradeId]="gradeId" />
        </div>
        }
        @if (resultType.ageRequired) {
        <div class="kip-form-group mb-0">
          @if (resultType.id === assessmentResultType.Reading) {
          <kip-form-control-number id="words" label="Words" [control]="words" [max]="100" [min]="0" />

          }
          @if (resultType.id === assessmentResultType.Reading && age) {
          <kip-reading-assessment-chart [readingTest]="readingTest"
            [readingTestStudentData]="{age: {year: age.year, month: age.month}, words: words.value}" />
          }
          @if (resultType.id === assessmentResultType.Comprehension) {
          <div class="d-flex flex-row align-items-center">
            <div class="px-2">
              Form
            </div>
            <select class="form-control pe-5 w-auto" id="comprehensionForm" [formControl]="comprehensionForm">
              @for (form of forms; track form) {
              <option [ngValue]="form.id">
                {{ form.title }}
              </option>
              }
            </select>
            <div class="px-2">
              Correct
            </div>
            <kip-form-control-number id="comprehensionResult" label="" [control]="comprehensionResult" [max]="49"
              [min]="0" />
          </div>
          }
          @if (resultType.id === assessmentResultType.Comprehension && age) {
          <kip-comprehension-assessment-chart [comprehensionTest]="comprehensionTest"
            [comprehensionTestStudentData]="{age: {year: age.year, month: age.month}, score: comprehensionResult.value, form: comprehensionForm.value}" />
          }
          <kip-generic-picker clientSideErrorMessage="Please select a result" label="Result" name="centre"
            [control]="ageResult" [hideSingle]="true" [options]="ageResults" />
          @if (ageResult.value === ageResultValues.Assessed) {
          <label>Age <span class="ms-2 small">{{
              ageResult.value | ageDifference
              :calculatedAge :age }}</span></label>
          }
          @if (ageResult.value === ageResultValues.Assessed) {
          <div class="d-flex flex-row align-items-center">
            <kip-form-control-number id="year" label="" [control]="year" [max]="200" [min]="0" />
            <div class="px-2">
              years
            </div>
            <kip-form-control-number id="month" label="" [control]="month" [max]="11" [min]="0" />
            <div class="px-2">
              months
            </div>
          </div>
          }
          @if (result | formHasError : ageError) {
          <div class="kip-form-group form-group-invalid">
            <span class="form-control-invalid">
              Either both year and month have to be entered or neither.
            </span>
          </div>
          }
        </div>
        }
        @if (resultType.scoreRequired) {
        <div class="kip-form-group mb-0">
          <label>Score</label>
          <div class="d-flex flex-row align-items-center">
            <kip-form-control-number id="score" label="" [control]="score" [max]="10000" [min]="0" />
            <div class="px-2">
              out of
            </div>
            <kip-form-control-number id="scoreTotal" label="" [control]="total" [max]="10000" [min]="1" />
          </div>
          <div class="w-100">
            @if (result | formHasError : scoreTotalError) {
            <div class="kip-form-group form-group-invalid">
              <span class="form-control-invalid">
                Score must be less than or equal to total.
              </span>
            </div>
            }
            @if (result | formHasError : scoreGradeError) {
            <div class="kip-form-group form-group-invalid">
              <span class="form-control-invalid">
                Either score, grade and total have to be entered or none of them.
              </span>
            </div>
            }
            @if (result | formHasError : scoreError) {
            <div class="kip-form-group form-group-invalid">
              <span class="form-control-invalid">
                Either both score and total have to be entered or neither.
              </span>
            </div>
            }
          </div>
        </div>
        }
      </div>
      @if (allowNotes) {
      <div class="col-xl-6">
        <kip-emoji-edit class="mb-3 d-block" label="Internal" name="sessionInternal" ngDefaultControl subLabel="PRIVATE"
          [edit]="true" [formControl]="internalNote" />
        <kip-emoji-edit label="Parent" name="sessionParent" ngDefaultControl subLabel="SHARED" [edit]="true"
          [formControl]="parentNote" />
        <div class="py-3 d-flex align-items-center">
          <fa-icon class="me-3 text-warning" size="1x" [icon]="icons.warning.solid" />
          <div class="pe-3">Assessment notes entered here will appear in the Parent Portal. Be sure to write clean,
            concise
            and respectful notes
            as this will be visible to the parent.</div>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>