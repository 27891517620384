<div class="kip-sorting-layout" [class.kip-selection]="selectionAnswer" [class.kip-sorting-horizontal]="horizontal">
  <kip-question-text [question]="question" />
  @if (!selectionAnswer) {
  <div class="d-flex flex-row mt-2">
    <div #singleList="cdkDropList" cdkDropList class="kip-column p-2" [cdkDropListData]="options"
      [cdkDropListDisabled]="readonly" [cdkDropListOrientation]="horizontal ? 'horizontal' : 'vertical'"
      [class.text-center]="horizontal" [class.text-nowrap]="horizontal" (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="clearValidation()" (cdkDropListExited)="clearValidation()"
      (cdkDropListSorted)="clearValidation()">
      @for (option of options; track option) {
      <div cdkDrag class="kip-option kip-bring-to-front fs-3 rounded p-3 text-center" [class.mb-2]="!horizontal"
        [class.me-2]="horizontal"
        [ngClass]="!selectionAnswer ? 'kip-option ' + (option.valid | answerValidation: 'alert-success' : 'alert-danger'):''">
        <kip-question-option [option]="option" />
      </div>
      }
    </div>
  </div>
  }
  @if (selectionAnswer) {
  <div class="d-flex flex-row mt-2">
    <div #fromList="cdkDropList" cdkDropList class="kip-column p-2" [cdkDropListConnectedTo]="[toList]"
      [cdkDropListData]="options" [cdkDropListDisabled]="readonly" (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="clearValidation()" (cdkDropListExited)="clearValidation()"
      (cdkDropListSorted)="clearValidation()">
      @for (option of options; track option) {
      <div cdkDrag class="kip-option kip-bring-to-front fs-3 rounded p-3 text-center" [class.mb-2]="!horizontal"
        [class.me-2]="horizontal"
        [ngClass]="!selectionAnswer ? 'kip-option ' + (option.valid | answerValidation: 'alert-success' : 'alert-danger'):''">
        <kip-question-option [option]="option" />
      </div>
      }
    </div>
    <div class="kip-column kip-spacer text-primary d-flex flex-column align-items-center justify-content-center">
      <fa-icon class="kip-icon" [icon]="icons.arrowsAltCircle.right" />
    </div>
    <div #toList="cdkDropList" cdkDropList class="kip-column kip-target kip-bring-to-front"
      [cdkDropListConnectedTo]="[fromList]" [cdkDropListData]="selections" [cdkDropListDisabled]="readonly"
      (cdkDropListDropped)="drop($event)" (cdkDropListEntered)="clearValidation()"
      (cdkDropListExited)="clearValidation()" (cdkDropListSorted)="clearValidation()">
      @for (selection of selections; track selection) {
      <div cdkDrag class="kip-option-selected fs-3 rounded p-3 text-center" [class.mb-2]="!horizontal"
        [ngClass]="(selection.valid | answerValidation: 'alert-success' : 'alert-danger')">
        <kip-question-option [option]="selection" />
      </div>
      }
    </div>
  </div>
  }
</div>