export enum OrganisationGoalsRoutes {
  List = 'list',
  Edit = 'edit',
  New = 'new'
}

export enum OrganisationGoalRouteParams {
  GoalId = 'goalId'
}

export function gotoOrganisationGoalsEdit(goalId: number) {
  return `/${goalId}/${OrganisationGoalsRoutes.Edit}`;
}

export function gotoOrganisationGoalsNew() {
  return `/${OrganisationGoalsRoutes.New}`;
}

export function gotoOrganisationGoalsList() {
  return `/${OrganisationGoalsRoutes.List}`;
}
