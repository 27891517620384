<div class="d-flex justify-content-between align-items-center kip-tcc pe-1 py-1 border-bottom" ngbAutofocus>
  <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
    <fa-icon class="me-2" size="1x" [icon]="icons.addStudent" />
    @if (!editingContact) {
    <span>Create portal account</span>
    }
    @if (editingContact) {
    <span>Create account for {{ editingContact.contact.givenName }} {{
      editingContact.contact.familyName }}</span>
    }
  </h4>
  <button aria-label="Close" class="btn btn-link btn--icon" type="button" (click)="currentModal.close()">
    <fa-icon [icon]="icons.close.regular" />
  </button>
</div>
<div class="modal-body">
  @if (!editingContact) {
  <p>Who would you like to create the account for?</p>
  <ul class="list-group">
    @for (primaryContact of primaryContacts; track primaryContact) {
    <li class="list-group-item list-group-item-action text-dark cursor-pointer" (click)="selectContact(primaryContact)">
      {{ primaryContact.contact.givenName }} {{ primaryContact.contact.familyName }}
    </li>
    }
  </ul>
  }
  @if (editingContact) {
  <div>
    <kip-account-contact-edit-login [accountContactToEdit]="editingContact" [accountId]="editingContact.accountId"
      [isInline]="true" (cancelled)="cancelEdit()" (submitted)="completeEdit()" />
  </div>
  }
</div>