import { ChangeDetectorRef, Directive, effect, inject, input, model, OnDestroy, OnInit, output } from '@angular/core';
import { FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { NoteService, NotesForSession } from 'tutor-notes-lib';

import { Note } from '../models';

@Directive()
export abstract class EditNotesBaseComponent<T extends Note, T2 extends NotesForSession> implements OnInit, OnDestroy {

  protected subscriptions: Subscription[] = [];
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly noteService = inject(NoteService);

  readonly icons = Icons;

  readonly note = model.required<T | undefined>();
  readonly allowInternalRanking = input.required<boolean | undefined>();
  readonly allowParentRanking = input.required<boolean | undefined>();
  readonly save = output<T2>();

  get internalNoteRanking() {
    return this.noteForm.getSafe(s => s.internalNoteRanking);
  }

  get parentNoteRanking() {
    return this.noteForm.getSafe(s => s.parentNoteRanking);
  }

  get internalNote() {
    return this.noteForm.getSafe(s => s.internalNote);
  }

  get parentNote() {
    return this.noteForm.getSafe(s => s.parentNote);
  }

  noteForm!: FormGroupTypeSafe<T2>;

  constructor() {
    effect(() => {
      const note = this.note();
      console.debug(note);
      this.patch();
    });
  }

  ngOnInit() {
    this.patch();
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  abstract patch(): void;

  baseSubmit() {
    this.submit();
    this.save.emit(this.noteForm.value);
  }

  abstract submit(): void;
}
