import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

import {
  AccessKey, AdjustableHeader, AIModel, AssessmentResultType, BundleOfferSummary, BundleOfferType, Centre, ComprehensionTest,
  Currency, DayOfWeek, DocumentType, Grade,
  NoteCategory, Organisation, PagedList, PageRequest, ReadingTest,
  Region, Relationship, SessionScheduleFilter, SessionScheduleSummary, Subject, TermsAndConditionTemplate, TreeName, Tutor,
  VideoRecordOption
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends HttpWithAbsoluteService {

  static blobToBase64(blob: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  getVideo(video: string) {
    return this.getUrlAndDownloadWithErrorBlobProgress(`assets/video-url/${video}`, ErrorBlobPath.Image);
  }

  querySessions(page: PageRequest<SessionScheduleFilter>): Observable<PagedList<SessionScheduleSummary>> {
    return this.post<PagedList<SessionScheduleSummary>>('session-schedules/query', page);
  }

  searchSessions(dayOfWeek: DayOfWeek, centreId: number, lessonId: number): Observable<SessionScheduleSummary[]> {
    return this.post<SessionScheduleSummary[]>('session-schedules/search',
      { dayOfWeek: dayOfWeek, centreId: centreId, lessonId: lessonId });
  }

  getTutors(centreId: number): Observable<Tutor[]> {
    return this.get<Tutor[]>(`tutors/centre/${centreId}`);
  }

  getAiModels(): Observable<AIModel[]> {
    return this.get<AIModel[]>('ai/models');
  }

  getAdjustableHeaders(): Observable<AdjustableHeader[]> {
    return this.get<AdjustableHeader[]>('assets/adjustable-headers');
  }

  getGrades(): Observable<Grade[]> {
    return this.get<Grade[]>('grades');
  }

  getTreeNames(regionId: number): Observable<TreeName[]> {
    return this.get<TreeName[]>(`tree/names/${regionId}`);
  }

  getRegions(): Observable<Region[]> {
    return this.get<Region[]>('regions');
  }

  getRelationships(): Observable<Relationship[]> {
    return this.get<Relationship[]>('relationships');
  }

  getCentres(): Observable<Centre[]> {
    return this.get<Centre[]>('centres');
  }

  getOrganisations(): Observable<Organisation[]> {
    return this.get<Organisation[]>('organisations');
  }

  getTermsAndConditionsTemplates(): Observable<TermsAndConditionTemplate[]> {
    return this.get<TermsAndConditionTemplate[]>('terms/list');
  }

  getVideoRecordOptions(): Observable<VideoRecordOption[]> {
    return this.get<VideoRecordOption[]>('video-record-options');
  }

  getReadingTests(): Observable<ReadingTest[]> {
    return this.get<ReadingTest[]>('assessments/reading-tests');
  }

  getComprehensionTests(): Observable<ComprehensionTest[]> {
    return this.get<ComprehensionTest[]>('assessments/comprehension-tests');
  }

  getSubjects(showHidden = true): Observable<Subject[]> {
    return this.get<Subject[]>(`subjects?showHidden=${showHidden}`);
  }

  getAssessmentResultTypes(): Observable<AssessmentResultType[]> {
    return this.get<AssessmentResultType[]>('assessments/result/type');
  }

  getBundleOffers(): Observable<BundleOfferSummary[]> {
    return this.get<BundleOfferSummary[]>('bundle-offer');
  }

  getBundleOfferTypes(): Observable<BundleOfferType[]> {
    return this.get<BundleOfferType[]>('bundle-offer/types');
  }

  getCurrencies(): Observable<Currency[]> {
    return this.get<Currency[]>('bundle-offer/currencies');
  }

  getAllCurrencies(): Observable<Currency[]> {
    return this.get<Currency[]>('organisations/currencies');
  }

  getAllAccessKeys(): Observable<AccessKey[]> {
    return this.get<AccessKey[]>('organisations/access-keys');
  }

  getNoteCategories(): Observable<NoteCategory[]> {
    return this.get<BundleOfferSummary[]>('note/category');
  }

  getDocumentTypes(): Observable<DocumentType[]> {
    return this.get<DocumentType[]>('employee-document-type');
  }

  getTutorPhoto(tutorId: number): Observable<HttpResponse<Blob>> {
    return this.getBlob(`avatar/download-tutor-photo/${tutorId}`);
  }

  clearTutorPhoto(tutorId: number): Observable<string> {
    return this.get<string>(`assets/clear-tutor-photo/${tutorId}`, {});
  }

  uploadTutorPhoto(file: File, tutorId: number): Observable<string> {
    const formData = new FormData();
    formData.append('tutorId', tutorId.toString());
    formData.append('file', file);
    return this.post<string>(`assets/upload-tutor-photo/${tutorId}`, formData);
  }
}
