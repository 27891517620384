import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { EnrolmentSessionSummary } from '../models';
import { EnrolmentSessionService } from '../services';

@Component({
  selector: 'kip-enrolment-session-picker',
  templateUrl: './enrolment-session-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrolmentSessionPickerComponent implements OnInit, OnDestroy {

  readonly #enrolmentSessionService = inject(EnrolmentSessionService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  enrolmentSessions: EnrolmentSessionSummary[] = [];

  // eslint-disable-next-line kip/no-unused-public-members
  @Input({ required: true }) enrolmentId: number | undefined;

  @Output() readonly selectEnrolmentSession = new EventEmitter<EnrolmentSessionSummary[]>();

  ngOnInit() {
    if (this.enrolmentId) {
      this.#subscriptions.push(
        this.#enrolmentSessionService
          .getEnrolmentSessionsByEnrolmentId(this.enrolmentId)
          .subscribe(enrolmentSessions => {
            this.enrolmentSessions = enrolmentSessions;
            this.#changeDetectorRef.markForCheck();
          }));
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }
}
