@if (skinToneId) {
<div class="kip-form-group">
  @if (label) {
  <label>{{ label }}</label>
  }
  <div class="kip-skin-tone-picker kip-button-group btn-group btn-group-toggle">
    @for (skinTone of skinTones; track skinTone; let i = $index) {
    <input class="btn-check" type="radio" [disabled]="disabled" [formControl]="skinToneId" [id]="name + '_' + skinTone"
      [name]="name" [value]="skinTone">
    <label class="kip-button-group__radio btn px-lg-2" [for]="name + '_' + skinTone">
      <kip-icon [displayIcon]="icon" [skinTone]="skinTone" />
    </label>
    }
  </div>
</div>
}