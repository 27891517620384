export enum CommonRoutes {
  Avatar = 'avatar',
  Forbidden = '403-forbidden',
  Upload = 'upload',
  UploadActivityFiles = 'upload-activity-files',
  CreateActivity = 'create-activity',
  CreditCard = 'credit-card',
  ClientErrors = 'errors/client',
  ServerErrors = 'errors/server',
  CleverInstantLoginRedirect = 'clever',
  ClasslinkInstantLoginRedirect = 'classlink',
  ReadyPlayerMe = 'ready-player-me'
}

export function gotoAvatar() {
  return `/${CommonRoutes.Avatar}`;
}

export function gotoReadyPlayerMeInteract() {
  return `/${CommonRoutes.ReadyPlayerMe}`;
}
