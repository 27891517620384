/* eslint @typescript-eslint/no-useless-constructor : 0 */

import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { QuestionControlParameterItem } from '../../../../models';
import { QuestionControl } from '../question-control';

@Component({
  selector: 'kip-generic-dropdown',
  templateUrl: './dropdown.component.html',
  styles: '::ng-deep .ng-select .ng-select-container { background: none; color: inherit; }',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends QuestionControl implements OnInit {

  #items: QuestionControlParameterItem[] = [];
  #width: number | undefined;

  get items(): QuestionControlParameterItem[] {
    return this.#items || [];
  }

  get width() {
    return this.#width;
  }

  get values(): any[] {
    return this.element ? this.element.selectedValues.map(s => (s as QuestionControlParameterItem).value) : [];
  }

  @ViewChild('element', { static: true }) element: NgSelectComponent | undefined;

  ngOnInit() {
    this.#items = [];

    if (this.parameters) {
      const items = this.parameters.items ?? [];

      // used to be string - changing to id/label combination so we can support katex and the like

      for (const item of items) {
        if (this.isIdVersion(item)) {
          this.#items.push({ value: item.value, text: item.text });
        } else {
          this.items.push({ value: item, text: item });
        }
      }
      this.#width = this.parameters.width;
    }
  }

  onChangeAndUpdate() {
    this.onChange();
    this.onUpdate();
  }

  isIdVersion(value: QuestionControlParameterItem | string): value is QuestionControlParameterItem {
    return (value as QuestionControlParameterItem).value !== undefined;
  }
}
