import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';
import { PagedList, PageRequest } from 'ui-common-lib';

import { GenericName } from '../../models';
import { OrganisationGoal, OrganisationGoalContent, OrganisationGoalFilter, OrganisationGoalFull, OrganisationGoalNode } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrganisationGoalService extends HttpService {

  readonly goalEndpoint = 'tutor/goals';

  getOrganisationGoalTypes(): Observable<GenericName[]> {
    return this.get<GenericName[]>(`${this.goalEndpoint}/organisation-goal-types`);
  }

  getOrganisations(): Observable<OrganisationGoalContent> {
    return this.get<OrganisationGoalContent>(`${this.goalEndpoint}/organisations`);
  }

  getOrganisationGoal(goalId: number): Observable<OrganisationGoalFull> {
    return this.get<OrganisationGoalFull>(`${this.goalEndpoint}/organisation-goal/${goalId}`);
  }

  queryOrganisationGoals(page: PageRequest<OrganisationGoalFilter>): Observable<PagedList<OrganisationGoalFull>> {
    return this.post<PagedList<OrganisationGoalFull>>(`${this.goalEndpoint}/organisation-goals/query`, page);
  }

  getOrganisationGoalTree(): Observable<OrganisationGoalNode[]> {
    return this.get<OrganisationGoalNode[]>(`${this.goalEndpoint}/organisation-goals-tree`);
  }

  deleteGoal(goalId: number): Observable<string> {
    return this.delete<string>(`${this.goalEndpoint}/organisation-goal/${goalId}`);
  }

  newGoal(organisationGoal: OrganisationGoal): Observable<OrganisationGoalFull> {
    return this.put<OrganisationGoalFull>(`${this.goalEndpoint}/create-organisation-goal`, organisationGoal);
  }

  updateGoal(goalId: number, organisationGoal: OrganisationGoal): Observable<OrganisationGoalFull> {
    return this.post<OrganisationGoalFull>(`${this.goalEndpoint}/update-organisation-goal/${goalId}`, organisationGoal);
  }

}
