import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

  transform(value: DateStruct, length = 0): string {
    if (value) {
      const weekday = moment().set(
        {
          years: value.year,
          months: value.month - 1, // Moment months are 0 to 11, C# and Nb bootstrap 1 to 12
          date: value.day         // so changed the month value to align with C# and bootstrap.
        })
        .format('dddd');
      return length > 0 ? weekday.slice(0, Math.max(0, length)) : weekday;
    }
    return '';
  }
}
