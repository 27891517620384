import { Injectable } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

import { ManualActivity, ManualActivityRequest, ManualSessionSummary, PrintRequest } from '../models';

@Injectable({ providedIn: 'root' })
export class PdfService extends HttpWithAbsoluteService {

  getManualActivity(request: ManualActivityRequest) {
    if (request.parentLessonId) {
      return this.get<ManualActivity>('assets/activity-previous-lesson',
        {
          lessonId: request.parentLessonId,
          previousLessonId: request.lessonId,
          activityId: request.activityId
        });
    }
    return this.get<ManualActivity>('assets/activity', request);
  }

  getManualSessionSummaries(from: DateStruct, fromPeriod: number, toPeriod: number) {
    return this.get<ManualSessionSummary[]>('assets/sessions', {
      year: from.year,
      month: from.month,
      day: from.day,
      fromPeriod: fromPeriod,
      toPeriod: toPeriod
    });
  }

  getPdfAnnotatedFiles(request: PrintRequest) {
    return this.getBlobParamsWithComplexParams('assets/download-annotated-pdfs', request);
  }

  getPdfAnnotatedFile(request: ManualActivityRequest) {
    if (request.parentLessonId) {
      return this.getBlob('assets/download-annotated-pdf-previous-lesson', {
        lessonId: request.parentLessonId,
        previousLessonId: request.lessonId,
        activityId: request.activityId
      });

    }
    return this.getBlob('assets/download-annotated-pdf', request);
  }

  getPdfFile(file: string) {
    if (file === null) {
      return this.#get404Pdf();
    }

    return this.getUrlAndDownloadWithErrorBlob(`assets/activity-pdf-url/${file}`, ErrorBlobPath.Pdf);
  }

  getSoundFile(file: string) {
    return this.getSoundCached(file);
  }

  #get404Pdf() {
    return this.getBlob('assets/404/pdf');
  }
}
