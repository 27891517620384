import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
  selector: 'kip-icon-superhero-power-d',
  templateUrl: './superhero-power-d.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSuperheroPowerDComponent implements IconComponent {

}
